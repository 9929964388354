import React, { useState } from 'react';
import { cn } from '@/helpers/util';
import { Button, Checkbox, Form } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { teams } from '@/api';
import Feedback from './feedback';
import { LearnVideos } from './feedback/learn-videos';

const whyClientZen = [
  {
    id: 1,
    value: 'Feedback classification',
  },
  {
    id: 2,
    value: 'Understanding customer sentiment',
  },
  {
    id: 3,
    value: 'Revealing customer painpoints',
  },
  {
    id: 4,
    value: 'Revealing customer requests and bugs',
  },
  {
    id: 5,
    value: 'Mapping customer journey',
  },
  {
    id: 6,
    value: 'Understanding new feature adoption',
  },
];

const currentRole = [
  {
    id: 1,
    value: 'Customer insights manager',
  },
  {
    id: 2,
    value: 'Customer experience mananger',
  },
  {
    id: 3,
    value: 'UX researcher/designer',
  },
  {
    id: 4,
    value: 'Product manager',
  },
  {
    id: 5,
    value: 'Customer voice analyst',
  },
  {
    id: 6,
    value: 'Customer support manager',
  },
];

function FeedbackForm() {
  const [step, setStep] = useState(1);
  const [data, setData] = useState({
    whyus: [],
    roles: [],
  });
  const [form] = Form.useForm();

  const onboarding = useMutation({
    mutationKey: ['onboarding'],
    mutationFn: async (values) => {
      await teams.putOnboardingQuestions(values);
    },
  });

  const handleUpdate = () => {
    if (step < 3) {
      setStep((prevStep) => prevStep + 1);
    }

    const values = form.getFieldsValue();
    setData((prevData) => ({ ...prevData, ...values }));

    form.submit();
  };

  const handleSubmit = () => {
    onboarding.mutate(data);
  };

  let render = null;
  switch (step) {
    case 1:
      render = (
        <>
          <Feedback.Title className="px-2" />
          <Form.Item name="whyus">
            <Checkbox.Group className="!mt-6 flex flex-col gap-4">
              {whyClientZen.map((item) => (
                <div key={item.id} className="flex items-center gap-2.5">
                  <Checkbox
                    value={item.value}
                    className="!m-0 [&_.ant-checkbox-checked::after]:rounded [&_.ant-checkbox-inner]:rounded [&_.ant-checkbox-inner]:border-[#CEDBE4] [&_.ant-checkbox-inner]:h-5 [&_.ant-checkbox-inner]:w-5"
                  />
                  <span className="text-sm text-[#44596C]">{item.value}</span>
                </div>
              ))}
            </Checkbox.Group>
          </Form.Item>
          <div className="mt-6 inline-grid space-y-3 w-1/3">
            <Button onClick={handleUpdate} type="primary" size="large">
              Done
            </Button>
            <Button onClick={() => setStep(2)} type="text" className="!text-[#75899B] !font-[Gordita-Regular]">Skip</Button>
          </div>
        </>
      );
      break;
    case 2:
      render = (
        <>
          <Feedback.Title value="What is your role?" className="px-2" />
          <Form.Item name="roles">
            <Checkbox.Group className="!mt-6 flex flex-col gap-4">
              {currentRole.map((item) => (
                <div key={item.id} className="flex items-center gap-2.5">
                  <Checkbox
                    value={item.value}
                    className="!m-0 [&_.ant-checkbox-checked::after]:rounded [&_.ant-checkbox-inner]:rounded [&_.ant-checkbox-inner]:border-[#CEDBE4] [&_.ant-checkbox-inner]:h-5 [&_.ant-checkbox-inner]:w-5"
                  />
                  <span className="text-sm text-[#44596C]">{item.value}</span>
                </div>
              ))}
            </Checkbox.Group>
          </Form.Item>
          <div className="mt-6 inline-grid space-y-3 w-1/3">
            <Button onClick={handleUpdate} type="primary" size="large">
              Done
            </Button>
            <Button onClick={() => setStep(3)} type="text" className="!text-[#75899B] !font-[Gordita-Regular]">Skip</Button>
          </div>
        </>
      );
      break;
    case 3:
      render = <LearnVideos />;
      break;
    default:
      render = '';
  }

  return (
    <Feedback className={cn('mt-14 text-center md:min-w-[24rem]', step === 3 && 'md:min-w-[42rem]')}>
      <Form
        form={form}
        initialValues={{
          whyus: [],
          roles: [],
        }}
        preserve
        onFinish={handleSubmit}
      >
        {render}
      </Form>
    </Feedback>
  );
}

export default FeedbackForm;
