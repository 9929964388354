import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Button, Form, Modal, message,
} from 'antd';
import React from 'react';
import { subtopics, topics } from '@/api';
import { displayOrCatchMessage } from '@/helpers';
import { useHistory, useParams } from 'react-router-dom';
import { transformCustomFieldToPost } from '@/api/conversations';
import { TopicForm } from '../discovery/TopicForm';
import { useFilters } from './context';

export default function SaveModal({ children, setVisible, ...props }) {
  const history = useHistory();
  const { topicId, subtopicId } = useParams();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { filters } = useFilters();
  const { custom_fields: customField, ...restFilters } = filters;

  // TODO: optimistic update subtopics
  const mutation = useMutation({
    mutationKey: [topicId ? 'subtopic_create' : 'topic_create'],
    mutationFn: async (values) => {
      if (topicId) {
        const { data: response } = await subtopics.createSubtopic({
          name: values.name,
          topics: [topicId],
          custom_fields: transformCustomFieldToPost(customField),
          follow_exclusions: !!values.follow_exclusions,
          ...restFilters,
        });

        return response.data;
      }

      const { data: response } = await topics.createTopic({
        name: values.name,
        groups: [values.groups],
        custom_fields: transformCustomFieldToPost(customField),
        follow_exclusions: !!values.follow_exclusions,
        ...restFilters,
      });

      return response.data;
    },
    // eslint-disable-next-line no-shadow
    onSuccess: (data) => {
      form.resetFields();
      setVisible(false);
      message.success(`${topicId ? 'Subtopic' : 'Segment'} created successfully`, 1.5);
      if (!topicId) {
        history.push(`/topic/overview/${data.id}/${data.groups[0].id}`);
      } else {
        history.push(`${history.location.pathname}/${data.id}`);
      }
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ['subtopics'] });
    },
    onError: (err) => {
      displayOrCatchMessage(err);
    },
  });

  const onCreate = (values) => {
    mutation.mutate(values);
  };

  return (
    <Modal
      title={topicId ? 'Save Subtopic' : 'Save Segment'}
      footer={[
        <Button
          key="submit"
          type="primary"
          loading={mutation.isLoading}
          disabled={mutation.isLoading}
          onClick={() => {
            form.validateFields().then((values) => {
              onCreate(values);
            });
          }}
        >
          Save
        </Button>,
      ]}
      {...props}
    >
      <TopicForm form={form} hasGroups={topicId === undefined || (topicId && !!subtopicId)} />
    </Modal>
  );
}
