import React, { useCallback, useState } from 'react';
import { Button, Select } from 'antd';
import { teams, auth } from '@/api';
import { displayOrCatchMessage } from '@/helpers';
import { connect } from 'react-redux';
import userAuthenticatedAction from '@/store/actions/userAuthenticated';

const { Option } = Select;

const SentimentLanguageTab = () => {
  const [language, setLanguage] = useState(auth.team().comprehend_language);
  const [loading, setLoading] = useState(false);
  const onSave = useCallback(() => {
    setLoading(true);
    teams
      .setComprehendLanguage(language)
      .then(() => {
        localStorage.setItem('teams', JSON.stringify([{
          ...auth.team(),
          comprehend_language: language,
        }]));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        displayOrCatchMessage(error);
      });
  }, [language]);

  return (
    <div className="settings-tab notifications-tab container-sm">
      <h2>Sentiment language preferences</h2>
      <p>Update your sentiment language preferences here.</p>
      <ul className="mt-8 max-w-xl p-0">
        <li className="p-2.5 mt-3.5 border rounded-md border-solid border-[#E8E8E8] text-[#595959] flex justify-between cursor-pointer">
          <Select value={language} onChange={setLanguage} className="w-1/2">
            <Option value="en">English</Option>
            <Option value="es">Spanish</Option>
            <Option value="de">German</Option>
            <Option value="it">Italian</Option>
            <Option value="pt">Portuguese</Option>
            <Option value="fr">French</Option>
            <Option value="ja">Japanese</Option>
            <Option value="ko">Korean</Option>
            <Option value="hi">Hindi</Option>
            <Option value="ar">Arabic</Option>
            <Option value="zh">Chinese (simplified)</Option>
            <Option value="zh-TW">Chinese (traditional)</Option>
          </Select>
        </li>
      </ul>
      <div className="flex justify-end max-w-xl">
        <Button type="primary" onClick={onSave} loading={loading}>Save</Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  userAuthenticated: (data) => dispatch(userAuthenticatedAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SentimentLanguageTab);
