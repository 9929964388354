import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import LoadingTable from './LoadingTable';

const shouldRedirect = (node) => {
  if (!node || !node.classList || node.classList.contains('clickable-row')) {
    return true;
  } if (node.classList.contains('ant-btn') || node.classList.contains('ant-modal-root') || node.classList.contains('ant-select-dropdown') || node.classList.contains('ant-dropdown')) {
    return false;
  }

  return shouldRedirect(node.parentNode);
};

const onAdjustedRow = (setId, onRow) => (record, rowIndex) => {
  const baseOnRow = onRow ? onRow(record, rowIndex) : {};
  return ({
    ...baseOnRow,
    onClick: (event) => {
      if (!record.loading && shouldRedirect(event.target)) {
        setId(record.key);
      }

      if (baseOnRow.onClick) {
        baseOnRow.onClick(event);
      }
    },
  });
};

const getRowClass = (additional) => (record, index) => {
  const base = !record.loading ? 'clickable-row' : 'disabled-row';
  if (additional) {
    return `${base} ${additional(record, index)}`;
  }

  return base;
};

const ClickableTable = ({
  resourceName, rowClassName, onRow, ...props
}) => {
  const [id, setId] = useState(null);

  if (id) {
    return <Redirect push to={`/${resourceName}/${id}`} />;
  }

  return (
    <LoadingTable
      showHeader={false}
      rowClassName={getRowClass(rowClassName)}
      onRow={onAdjustedRow(setId, onRow)}
      {...props}
    />
  );
};

export default ClickableTable;
