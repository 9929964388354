import React, { useMemo, useState, useEffect } from 'react';
import { DatePicker, Button, Popover } from 'antd';
import { DownOutlined, CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';

import useURLState from '@/hooks/useUrlState';
import { useSentimentFilterOptions } from './hooks/useSentimentFilterOptions';
import DatePickerPanel from './DatePickerPanel';

const { RangePicker } = DatePicker;

export const DATEPICKER_LOCALSTORAGE_KEY = 'default-datepicker-value';

export const DATEPICKER_LOCALSTORAGE_KEY_CUSTOM_START = 'default-datepicker-value-custom-start';

export const DATEPICKER_LOCALSTORAGE_KEY_CUSTOM_END = 'default-datepicker-value-custom-end';

/**
 * @deprecated
 */
const SentimentDateFilter = ({
  onChange, initialValue, useUrlState, style = {}, popoverProps = {}, ...props
}) => {
  const { options, defaultValue } = useSentimentFilterOptions(initialValue);
  const [isOpen, setIsOpen] = useState(false);
  const [dateRangeInternal, setDateRangeInternal] = useState(
    [moment(defaultValue.start), moment(defaultValue.end)],
  );
  const [timeText, setTimeText] = useState(defaultValue.name);

  const { urlQueryState, setUrlQueryParams } = useURLState();
  const dateRangeFromUrl = [moment(urlQueryState.start), moment(urlQueryState.end)];
  const setDateRangeFromUrl = (value) => setUrlQueryParams({ start: value[0].format('YYYY-MM-DD'), end: value[1].format('YYYY-MM-DD') });

  const dateRange = useMemo(() => (useUrlState ? dateRangeFromUrl : dateRangeInternal));
  const setDateRange = useMemo(() => (useUrlState ? setDateRangeFromUrl : setDateRangeInternal));

  const handleChange = (value) => {
    setDateRange(value);
    setTimeText('');
  };

  const handleApply = (value) => {
    const range = value ?? dateRange;

    if (value) setDateRange(value);
    if (onChange) {
      onChange({ start: range[0].format('YYYY-MM-DD'), end: range[1].format('YYYY-MM-DD') });
    }

    const timeOption = options.find(
      (item) => (
        item.start === range[0].format('YYYY-MM-DD') && item.end === range[1].format('YYYY-MM-DD')
      ),
    );
    const btnText = timeOption
      ? timeOption.name
      : `${range[0].format('YYYY-MM-DD')} - ${range[1].format('YYYY-MM-DD')}`;
    setTimeText(btnText);
    setIsOpen(false);

    localStorage.setItem(
      DATEPICKER_LOCALSTORAGE_KEY, timeOption
        ? timeOption.name
        : JSON.stringify({
          start: range[0].format('YYYY-MM-DD'),
          end: range[1].format('YYYY-MM-DD'),
        }),
    );
  };

  const onVisibleChange = (visible) => setIsOpen(visible);

  useEffect(() => {
    if (defaultValue.start && defaultValue.end) {
      handleApply([moment(defaultValue.start), moment(defaultValue.end)]);
    }
  }, [urlQueryState.start, urlQueryState.end]);

  const content = useMemo(
    () => (
      <div className="clientzen-range-picker-popover">
        <DatePickerPanel
          panelNode={(
            <RangePicker
              autoFocus
              size="small"
              bordered={false}
              value={dateRange}
              onChange={handleChange}
              getPopupContainer={(node) => node.parentNode}
              open={isOpen}
            />
    )}
          onApply={handleApply}
          onClose={() => setIsOpen(false)}
          setDateRange={setDateRange}
          timeText={timeText}
        />
      </div>
    ),
    [isOpen, handleApply, setIsOpen, setDateRange, timeText, dateRange, handleChange],
  );

  return (
    <div style={{ ...style }} {...props}>
      <Popover
        overlayClassName="clientzen-range-picker-popover-overlay"
        visible={isOpen}
        onVisibleChange={onVisibleChange}
        content={content}
        trigger="click"
        placement="bottomRight"
        destroyTooltipOnHide
        {...popoverProps}
      >
        <Button className="clientzen-range-picker-button">
          <CalendarOutlined />
          {timeText.toLowerCase()}
          <DownOutlined className="icon-down" />
        </Button>
      </Popover>
    </div>
  );
};

/**
 * @deprecated
 */
export default SentimentDateFilter;
