import React, { useState } from 'react';
import { Empty, Tag } from 'antd';
import TopicPreviewHighlightedText from './TopicPreviewHighlightedText';

const PER_PAGE = 10;

const TopicPreviewData = ({ preview }) => {
  const [max, setMax] = useState(PER_PAGE);

  return (
    <div className="preview-data">
      <div>
        {preview.count ? preview.data.slice(0, max).map((props, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <TopicPreviewHighlightedText key={idx} {...props} />
        )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </div>
      {
        (preview.data.length > max) && <Tag className="preview-load-more-tag" onClick={() => setMax(max + PER_PAGE)}>load more</Tag>
      }
    </div>
  );
};

export default TopicPreviewData;
