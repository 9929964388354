import React from 'react';
import {
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { platforms as platformsApi } from '@/api';
import useIntegrationsStore from '../store/useIntegrationsStore';
import DebounceSelect from './DebounceSelect';

const fetchForms = (search) => platformsApi.getTypeformAvailableForms(search)
  .then(({ data }) => data.map((d) => ({
    label: d.title,
    value: d.id,
  }))).catch(() => []);

const OnboardingTypeformConnect = () => {
  const {
    state, actions: {
      updateConnectedPlatforms,
    },
  } = useIntegrationsStore();
  const [value, setValue] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const platform = state.connectedPlatforms.find((p) => p.type === 'typeform');
  const onConnect = () => {
    setLoading(true);
    const data = value.map((item) => item.split('-'));
    const ids = data.map((d) => d[0]);
    const names = data.map((d) => d[1]);

    platformsApi.setTypeformForms(ids, names).then(() => {
      updateConnectedPlatforms();
    }).catch(() => {
      setLoading(false);
    });
  };

  return platform && platform.status === 'not_ready' ? (
    <>
      <h4>Select forms</h4>
      <div className="new-onboarding-typeform">
        <DebounceSelect
          mode="multiple"
          size="large"
          value={value}
          placeholder="Select forms"
          fetchOptions={fetchForms}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          style={{
            width: '100%',
          }}
          suffixIcon={<SearchOutlined />}
          showArrow
          itemRenderer={(o) => (
            <div className="typeform_connect__option new-onboarding-select">
              <div className="typeform_connect__option__label">{o.label}</div>
              <div className="typeform_connect__option__value">{o.value}</div>
            </div>
          )}
        />
        {
          value.length ? (
            <Button
              onClick={onConnect}
              size="large"
              type="primary"
              loading={loading}
            >
              Continue
            </Button>
          ) : null
        }
      </div>
    </>
  ) : (
    <div className="new-onboarding-loading">
      <Spin size="large" />
    </div>
  );
};

export default OnboardingTypeformConnect;
