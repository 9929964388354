import React from 'react';
import classNames from 'classnames';
import Tag from '../../base-components/Tag';

const PainpointCellName = (props) => {
  const { name, groups = [] } = props;
  return (
    <div>
      <div className={classNames('painpoint-title', { 'mb-xxs': groups?.length > 0 })}>
        {name}
      </div>
      <div>
        {groups.map((group) => (<Tag style={{ display: 'none' }} color={group.color} key={group.id}>{group.name}</Tag>))}
      </div>
    </div>
  );
};

export default PainpointCellName;
