import moment from 'moment';
import { getMaxValue } from '../sentiment';

export const SET_LOADING_ACTION = 'SET_LOADING_ACTION';

export const SET_DATA = 'SET_DATA';

export const SET_HOVERED = 'SET_HOVERED';

export const SET_SELECTED = 'SET_SELECTED';

const BAR_RADIUS = 12;

const getAnnotation = ({ point, annotations }) => {
  const idx = annotations.findIndex(
    ({ date }) => point === date,
  );

  return idx !== -1 ? annotations[idx].label : null;
};

const getBarsRadius = ({
  negative, neutral, positive,
}) => {
  const values = [negative, neutral, positive];

  const first = values.findIndex((value) => value !== 0);
  const last = 2 - values.reverse().findIndex((value) => value !== 0);

  if (first === last) {
    return [{ id: first, radius: BAR_RADIUS }];
  }

  return [
    { id: first, radius: [0, 0, BAR_RADIUS, BAR_RADIUS] },
    { id: last, radius: [BAR_RADIUS, BAR_RADIUS, 0, 0] },
  ];
};

const mapChartData = (data) => {
  const max = Math.max(...data.chart.map(({ total }) => total));

  return data.chart.map(({
    point, positive, negative, neutral, total, ...rest
  }) => {
    const values = { negative, neutral, positive };

    const radiuses = getBarsRadius(values);

    const avgPositive = positive * max;
    const avgNeutral = neutral * max;
    const angNegative = negative * max;
    const { color } = getMaxValue({ positive, negative, total });

    return {
      point, // unique id
      date: moment(point).format('DD MMM'),
      total,
      sentimentColor: color,
      positive: avgPositive,
      neutral: avgNeutral,
      negative: angNegative,
      ratio: {
        positive: (positive * 100).toFixed(0),
        neutral: (neutral * 100).toFixed(0),
        negative: (negative * 100).toFixed(0),
      },
      radius: Object.keys(values)
        .reduce(
          (acc, key, idx) => ({ ...acc, [key]: radiuses.find(({ id }) => id === idx)?.radius }),
          {},
        ),
      annotation: getAnnotation({ point, annotations: data.annotations }),
      hide: total === 0,
      ...rest,
    };
  });
};

const replaceChartItem = (chart, item, newItem) => {
  const idx = chart.findIndex((entry) => entry === item);

  if (idx !== -1) {
    return [...chart.slice(0, idx), newItem, ...chart.slice(idx + 1)];
  }

  return chart;
};

export const initialState = {
  data: { chart: [], annotations: [] },
  loading: false,
};

export const sentimentChartReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING_ACTION: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case SET_DATA: {
      const { payload } = action;
      const chart = mapChartData(payload);

      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          chart,
          annotations: payload.annotations,
        },
      };
    }
    case SET_HOVERED: {
      const { payload: { item, hovered } } = action;
      return {
        ...state,
        data: {
          ...state.data,
          chart: replaceChartItem(state.data.chart, item, {
            ...item,
            hovered,
          }),
        },
      };
    }
    case SET_SELECTED: {
      const { payload: { item } } = action;
      const chart = replaceChartItem(state.data.chart, item, {
        ...item,
        selected: !item.selected,
      });
      return {
        ...state,
        data: {
          ...state.data,
          chart,
        },
      };
    }
    default:
      return state;
  }
};
