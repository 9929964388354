import {
  ChevronRight, MoreVertical, PenLine, Trash2, Star, StarOff, Brain,
} from 'lucide-react';
import React, { useState } from 'react';
import {
  Dropdown, Menu, Breadcrumb, Modal, message,
} from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { subtopics, topics } from '@/api';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useModalStore } from '@/store/zustand/useModalStore';
import { getAiVersion } from '@/components/summary/SummaryResults';
import { connect } from 'react-redux';

function BucketActionsComponent({
  group, topic, subtopic, isFavoriteTopic, user,
}) {
  const { topicId, groupId, subtopicId } = useParams();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [isFavorite, setIsFavorite] = useState(isFavoriteTopic);

  const { setModal } = useModalStore();

  const trimmedGroup = group.length > 20 ? `${group.slice(0, 20)}...` : group;

  const deleteTopic = useMutation({
    mutationKey: 'topic_delete',
    mutationFn: async () => {
      await topics.deleteTopic(topicId);
    },
    onSuccess: () => {
      message.success('Topic deleted successfully');
      history.push('/taxonomy');
    },
    onError: () => {
      message.error('Failed deleting topic');
    },
  });

  const deleteSubtopic = useMutation({
    mutationKey: 'subtopic_delete',
    mutationFn: async () => {
      await subtopics.deleteSubtopic(subtopicId, false);
    },
    onSuccess: () => {
      message.success('Subtopic deleted successfully');
      history.push(`/topic/overview/${topicId}/${groupId}`);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ['subtopics'] });
    },
    onError: () => {
      message.error('Failed deleting subtopic');
    },
  });

  const handleDeleteModal = () => {
    Modal.confirm({
      title: `Are you sure you want to delete ${subtopicId ? 'subtopic' : 'topic'}?`,
      okText: 'Delete',
      cancelText: 'Cancel',
      okType: 'primary',
      cancelType: 'default',
      onOk: () => {
        if (subtopicId) {
          message.loading('Subtopic is deleting');
          deleteSubtopic.mutate();
          return;
        }

        message.loading('Topic is deleting');
        deleteTopic.mutate();
      },
      okButtonProps: {
        danger: true,
      },
    });
  };

  const handleAddFavorite = async () => {
    await topics.addFavoriteTopic(topicId);
    setIsFavorite(true);
  };

  const handleRemoveFavorite = async () => {
    await topics.removeFavoriteTopic(topicId);
    setIsFavorite(false);
  };

  return (
    <Breadcrumb className="[&_.ant-breadcrumb-separator]:!mx-2 !m-0 !leading-[64px] [&_.ant-breadcrumb-separator]:align-middle" separator={<ChevronRight className="stroke-[#74899B]" size={16} strokeWidth={1.5} />}>
      {!topic && !subtopicId && (
        <Breadcrumb.Item className="!text-[#75899B] !text-base !font-[Gordita-Regular]">
          {trimmedGroup}
        </Breadcrumb.Item>
      )}
      {subtopicId ? (
        <Breadcrumb.Item className="!text-[#75899B] !text-base !font-[Gordita-Regular]">
          <Link to={`/topic/overview/${topicId}/${groupId}`} className="redirect-link underline font-[Gordita-Medium]">
            <span>{topic}</span>
          </Link>
        </Breadcrumb.Item>
      ) : null}
      <Breadcrumb.Item className="!text-grey-800 !inline-flex items-center !text-base">
        {' '}
        {subtopic || topic}
        <Dropdown
          trigger={['click']}
          overlay={(
            <Menu className="!rounded-md shadow-[0px_1px_12px_#CAD7E180] border border-solid border-[#DFE9F1] font-[Gordita-Regular]">
              <Menu.Item className="flex items-center gap-2 hover:rounded-[3px] hover:!bg-[#F6F9FB]" onClick={() => setModal('edit_modal')}>
                <PenLine size={16} strokeWidth={2} />
                <span>
                  Edit
                  {' '}
                  {subtopicId ? 'subsegment' : 'segment'}
                </span>
              </Menu.Item>
              <Menu.Item className="flex items-center gap-2 hover:rounded-[3px] hover:!bg-[#F6F9FB]" onClick={() => setModal('edit_releases_modal')}>
                <PenLine size={16} strokeWidth={2} />
                <span>
                  Edit Annotations
                </span>
              </Menu.Item>
              {
                (getAiVersion() === 'v2' && user && user.email && (user.email.endsWith('@clientzen.io') || user.email.endsWith('@vertuoza.com'))) ? (
                  <Menu.Item className="flex items-center gap-2 hover:rounded-[3px] hover:!bg-[#F6F9FB]" onClick={() => setModal('create_taxonomy_ai_subtopic')}>
                    <Brain size={16} strokeWidth={2} />
                    <span>
                      Create AI Subtopic
                    </span>
                  </Menu.Item>
                ) : null
              }
              { !subtopicId && (
              <Menu.Item className="flex items-center gap-2 hover:rounded-[3px] hover:!bg-[#F6F9FB]" onClick={isFavorite ? handleRemoveFavorite : handleAddFavorite}>
                {isFavorite ? <StarOff size={16} strokeWidth={2} /> : <Star size={16} strokeWidth={2} />}
                <span>
                  {isFavorite ? 'Remove favorite' : 'Add favorite'}
                </span>
              </Menu.Item>
              )}
              <Menu.Item className="flex items-center gap-2 hover:rounded-[3px] hover:!bg-[#F6F9FB]" onClick={handleDeleteModal}>
                <Trash2 size={16} strokeWidth={2} />
                <span>Delete</span>
              </Menu.Item>
            </Menu>
          )}
        >
          <MoreVertical className="ml-2 rounded-sm cursor-pointer stroke-[#74899B] hover:stroke-[#2D323D]" size={20} strokeWidth={2} />
        </Dropdown>
      </Breadcrumb.Item>
    </Breadcrumb>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export const BucketActions = connect(mapStateToProps, null)(BucketActionsComponent);
