import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Select, Input, Button, Tooltip,
} from 'antd';
import './SelectCustomDropdown.less';
import classNames from 'classnames';
import { CheckOutlined } from '@ant-design/icons';
import isEqual from 'lodash/isEqual';
import { uniqueId } from 'lodash';
import AddIcon from '../../../components/svgs/icons/Add';

const colors = [
  '#839ED0',
  '#83C3D0',
  '#A7D083',
  '#D0BB83',
  '#BE83D0',
  '#9283D0',
  '#B3B195',
  '#95B3A2',
  '#9596B3',
  '#B395A4',
  '#789FAF',
  '#A0AE7E',
  '#C9CF90',
  '#BF91A4',
  '#95B396',
  '#959595',
];

const randomColor = () => colors[Math.floor(Math.random() * colors.length)];

const SelectCustomDropdown = (props) => {
  const {
    rounded,
    className,
    options = [],
    valueProp = 'id',
    textProp = 'name',
    newOptionBtnText = 'Create topic group',
    noOptionsText = 'Please create an option',
    allowClear = true,
    onChange,
    ...rest
  } = props;

  const [open, setOpen] = useState(false);
  const [dropdownNewOptionVisible, setDropdownNewOptionVisible] = useState(false);
  const newOptionInputRef = useRef();
  const [localOptions, setLocalOptions] = useState(options);

  useEffect(() => {
    if (!isEqual(options, localOptions)) {
      setLocalOptions(options);
    }
  }, [options]);

  const showInputNewOption = () => {
    setDropdownNewOptionVisible(true);
    setTimeout(() => {
      newOptionInputRef.current?.focus();
    }, 500);
  };

  const onDropdownVisibleChange = (visible) => {
    setOpen(visible);
    if (visible) {
      setDropdownNewOptionVisible(false);
    }
  };

  const addNewOption = () => {
    const { value } = newOptionInputRef.current.input;
    const isUniqueValue = !localOptions.filter((option) => (
      option[textProp] === value || option[valueProp] === value
    ))[0];

    if (value && isUniqueValue) {
      setLocalOptions([...localOptions, { [valueProp]: uniqueId('_new_option_'), [textProp]: value, color: randomColor() }]);
    }

    setDropdownNewOptionVisible(false);

    if (value && onChange) {
      onChange(value);
      setOpen(false);
    }
  };

  const onSelectChange = (value) => {
    // eslint-disable-next-line no-unused-expressions
    onChange && onChange(value);
  };

  return (
    <Select
      open={open}
      className={classNames('select-custom-dropdown', className, { 'rounded-select': rounded })}
      onDropdownVisibleChange={onDropdownVisibleChange}
      getPopupContainer={(el) => el}
      allowClear={allowClear}
      onChange={onSelectChange}
      {...rest}
      dropdownRender={(menu) => (
        <div className="select-custom-dropdown__dropdown">
          <div className="select-custom-dropdown__header">
            {!dropdownNewOptionVisible && (
              <Button
                size="middle"
                icon={<AddIcon className="topics__header__btn-icon" />}
                onClick={showInputNewOption}
              >
                {newOptionBtnText}
              </Button>
            )}

            {dropdownNewOptionVisible && (
              <div className="add-new-option">
                <Input ref={newOptionInputRef} onKeyUp={(e) => e.key === 'Enter' && addNewOption()} onBlur={() => addNewOption()} />
                <Tooltip title="Create topic group" placement="bottom">
                  <button type="button" onClick={addNewOption}>
                    <CheckOutlined />
                  </button>
                </Tooltip>
              </div>
            )}
          </div>
          {localOptions.length === 0 && <div className="no-options-available">{noOptionsText}</div>}
          {menu}
        </div>
      )}
    >
      {localOptions.map((option) => (
        <Select.Option
          key={option[valueProp]}
          value={option[valueProp]}
        >
          <span className="category-option" style={{ backgroundColor: option.color }}>{option[textProp]}</span>
        </Select.Option>
      ))}
    </Select>
  );
};

export default SelectCustomDropdown;
