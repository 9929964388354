import React, { useEffect, useReducer, useMemo } from 'react';
import { message } from 'antd';
import { conversations as conversationsApi } from '@/api';

import {
  conversationsReducer, initialState, SET_CONVERSATIONS,
  SET_ACTION_LOADING, SET_TEXT_COLLAPSE, UPDATE_TOPIC,
} from './conversationsReducer';

export const ConversationsContext = React.createContext();

const ConversationsProvider = ({
  perPage, filters, children, dataConversations,
}) => {
  const [state, dispatch] = useReducer(conversationsReducer, initialState);
  const { pagination } = state;

  const getConversations = async () => {
    dispatch({
      type: SET_CONVERSATIONS,
      payload: {
        conversations: dataConversations.data,
        pagination: {
          current: dataConversations.pager.current_page,
          pageSize: dataConversations.pager.per_page,
          total: dataConversations.pager.total,
        },
      },
    });
  };

  useEffect(() => {
    getConversations(1);
  }, [filters, perPage]);

  const updateTopic = async (conversationId, topicIds) => {
    try {
      dispatch({ type: SET_ACTION_LOADING, payload: true });
      const response = await conversationsApi.updateTopic(conversationId, topicIds);
      dispatch({
        type: UPDATE_TOPIC,
        payload: { conversationId, conversation: response.data.data },
      });
      message.success('Conversation updated successfully');

      return true;
    } catch (err) {
      dispatch({ type: SET_ACTION_LOADING, payload: false });
      message.error('Failed updating conversation');
    }

    return false;
  };

  const deleteConversation = async (id) => {
    dispatch({ type: SET_ACTION_LOADING, payload: true });
    try {
      await conversationsApi.deleteConversation(id);
      getConversations(pagination.current);
      message.success('Conversation deleted successfully');
    } catch (err) {
      dispatch({ type: SET_ACTION_LOADING, payload: false });
      message.error('Failed deleting conversation');
    }
  };

  const changeTextCollapse = (id) => {
    dispatch({ type: SET_TEXT_COLLAPSE, payload: id });
  };

  const contextValue = useMemo(() => ({
    state: { ...state, perPage, filters },
    actions: {
      getConversations, updateTopic, deleteConversation, changeTextCollapse,
    },
  }),
  [state, perPage, filters, dispatch, getConversations,
    updateTopic, deleteConversation, changeTextCollapse]);

  return (
    <ConversationsContext.Provider
      value={contextValue}
    >
      {children}
    </ConversationsContext.Provider>
  );
};

export default ConversationsProvider;
