import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const useTemplateStore = create(
  devtools((set) => ({
    template: null,
    setTemplate: (template) => set({ template }),
  })), {
    name: 'useTemplateStore',
  },
);
