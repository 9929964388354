import React, { useState, useMemo, useRef } from 'react';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';

const { Option } = Select;

const DebounceSelect = ({
  fetchOptions, debounceTimeout = 800, itemRenderer, ...props
}) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      filterOption={false}
      onSearch={debounceFetcher}
      onDropdownVisibleChange={(open) => {
        if (open && !options.length) {
          debounceFetcher();
        }
      }}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
    >
      {options.map((o) => (
        <Option key={o.value} value={`${o.value}-${o.label}`}>
          {
            itemRenderer ? itemRenderer(o) : o.label
          }
        </Option>
      ))}
    </Select>
  );
};

export default DebounceSelect;
