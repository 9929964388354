import * as React from 'react';
import { memo } from 'react';

const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={112.435}
    height={22.486}
    {...props}
  >
    <defs>
      <style>{'.logoc-a{fill:#0baa6b}.logoc-b{stroke:#000;stroke-width:.1px}'}</style>
    </defs>
    <path d="M34.603 20.706a6.1 6.1 0 0 1-3.91 1.458 5.927 5.927 0 1 1 3.692-10.581.923.923 0 0 1 .031 1.427.938.938 0 0 1-1.179.062 4.308 4.308 0 0 0-2.544-.869 3.77 3.77 0 0 0-3.755 4 3.77 3.77 0 0 0 3.755 4 4.031 4.031 0 0 0 2.7-.993.951.951 0 0 1 1.272.062 1.021 1.021 0 0 1-.062 1.434ZM38.492 6.862a1.033 1.033 0 0 1 1.024 1.024v12.939a1.024 1.024 0 1 1-2.048 0V7.886a1.013 1.013 0 0 1 1.024-1.024ZM42.875 6.831a1.311 1.311 0 0 1 1.707 1.707 1.338 1.338 0 0 1-.714.714 1.311 1.311 0 0 1-1.707-1.707 1.244 1.244 0 0 1 .714-.714Zm.5 4a1.033 1.033 0 0 1 1.024 1.024v8.967a1.024 1.024 0 0 1-2.048 0v-8.968a1.033 1.033 0 0 1 1.016-1.02ZM57.928 15.922a1.046 1.046 0 0 1-1.055 1.055h-8.037a3.786 3.786 0 0 0 3.879 3.289 4.309 4.309 0 0 0 2.793-1.024.951.951 0 0 1 1.272.062.912.912 0 0 1-.062 1.334 6.078 6.078 0 0 1-4 1.52 5.741 5.741 0 0 1-5.864-5.927 5.6 5.6 0 0 1 5.616-5.927 5.447 5.447 0 0 1 5.458 5.618Zm-1.986-.652a3.365 3.365 0 0 0-3.475-3.041 3.461 3.461 0 0 0-3.568 3.041ZM69.731 14.619v6.206a1.024 1.024 0 1 1-2.048 0V14.96a2.5 2.5 0 0 0-2.7-2.762 3.494 3.494 0 0 0-2.793 1.614v6.982a1.024 1.024 0 0 1-2.048 0v-9.247a.962.962 0 0 1 1.924 0 4.5 4.5 0 0 1 3.444-1.241 4.1 4.1 0 0 1 4.221 4.313Z" />
    <path
      className="logoc-a"
      d="M101.072 15.922a1.046 1.046 0 0 1-1.055 1.055H91.98a3.786 3.786 0 0 0 3.879 3.289 4.309 4.309 0 0 0 2.793-1.024.951.951 0 0 1 1.272.062.912.912 0 0 1-.062 1.334 6.078 6.078 0 0 1-4 1.52 5.741 5.741 0 0 1-5.865-5.927 5.6 5.6 0 0 1 5.616-5.927 5.447 5.447 0 0 1 5.459 5.618Zm-1.986-.652a3.365 3.365 0 0 0-3.475-3.041 3.461 3.461 0 0 0-3.568 3.041ZM112.429 14.619v6.206a1.024 1.024 0 1 1-2.048 0V14.96a2.5 2.5 0 0 0-2.7-2.762 3.494 3.494 0 0 0-2.793 1.614v6.982a1.024 1.024 0 1 1-2.048 0v-9.247a.962.962 0 0 1 1.924 0 4.5 4.5 0 0 1 3.444-1.241 4.112 4.112 0 0 1 4.221 4.313Z"
    />
    <path d="M72.36 10.617V7.886a1.024 1.024 0 0 1 2.048 0v2.731h2.452a.931.931 0 0 1 0 1.862h-2.451v6.051a1.517 1.517 0 0 0 1.643 1.732 1.757 1.757 0 0 0 .559-.093.9.9 0 0 1 1.117.5.921.921 0 0 1-.5 1.241 3.879 3.879 0 0 1-1.458.279 3.291 3.291 0 0 1-3.382-3.537v-6.205" />
    <path
      className="logoc-a"
      d="m88.01 12.044-5.585 8.037h5.244a.962.962 0 1 1 0 1.924h-7.044a.932.932 0 0 1-.776-1.458l5.554-8.037h-4.841a.962.962 0 1 1 0-1.924h6.671a.942.942 0 0 1 .777 1.458Z"
    />
    <path
      className="logoc-b"
      d="M10.325 6.938a3.444 3.444 0 1 1 3.444-3.444 3.444 3.444 0 0 1-3.444 3.444Zm0-5.089a1.645 1.645 0 1 0 1.645 1.645 1.645 1.645 0 0 0-1.645-1.644ZM19.851 16.23a3.589 3.589 0 0 0-2.886-1.3c-2.3 0-4.437 1.831-6.02 3.2l-.652.559c-.217-.155-.434-.372-.652-.559-1.582-1.334-3.723-3.2-6.02-3.2a3.589 3.589 0 0 0-2.884 1.3 3.182 3.182 0 0 0-.59 2.7 4.5 4.5 0 0 0 3.32 3.227 7.643 7.643 0 0 0 2.079.279 7.95 7.95 0 0 0 4.747-1.52 8.165 8.165 0 0 0 4.747 1.52 7.81 7.81 0 0 0 2.079-.279 4.5 4.5 0 0 0 3.322-3.227 3.126 3.126 0 0 0-.59-2.7ZM3.995 20.423a2.766 2.766 0 0 1-2.079-1.892 1.334 1.334 0 0 1 .248-1.179 1.809 1.809 0 0 1 1.49-.621c1.614 0 3.475 1.582 4.841 2.762.124.093.217.186.341.279a6.306 6.306 0 0 1-4.841.651Zm14.708-1.892a2.712 2.712 0 0 1-2.079 1.892 6.348 6.348 0 0 1-4.809-.652c.124-.093.217-.186.341-.279 1.365-1.148 3.227-2.762 4.841-2.762a1.851 1.851 0 0 1 1.489.621 1.548 1.548 0 0 1 .217 1.179ZM19.696 10.684a.907.907 0 0 0-1.21.372 1.811 1.811 0 0 1-1.676.807 4.136 4.136 0 0 1-2.389-1.272 6.139 6.139 0 0 0-4.1-1.893 6.139 6.139 0 0 0-4.1 1.893 4.049 4.049 0 0 1-2.39 1.272 1.811 1.811 0 0 1-1.676-.807.907.907 0 0 0-1.21-.372.918.918 0 0 0-.4 1.21 3.659 3.659 0 0 0 3.286 1.769 5.556 5.556 0 0 0 3.537-1.707 4.745 4.745 0 0 1 2.948-1.489 4.594 4.594 0 0 1 2.948 1.489 5.556 5.556 0 0 0 3.537 1.707 3.659 3.659 0 0 0 3.294-1.765.948.948 0 0 0-.4-1.214Z"
    />
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
