import React from 'react';
import { Button } from 'antd';
import { UsergroupAddOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';

const AddAllButton = ({ recentInteractionAddAll, items, loadedItems }) => (
  <div className="add-all-button">
    <Button
      size="large"
      type="primary"
      onClick={() => recentInteractionAddAll(loadedItems)}
      disabled={!items[0].items.length}
    >
      <UsergroupAddOutlined />
      Add All
    </Button>
  </div>
);

const mapStateToProps = (state) => ({
  items: state.preparedRecentInteractions.items,
  loadedItems: state.preparedRecentInteractions.loadedItems,
});

export default connect(mapStateToProps, null)(AddAllButton);
