import React from 'react';
import { IntegrationIcons } from '@/base-components/conversations-table/components/ConversationSource';
import IconCustom from '@/images/integrations/icon-custom.svg';

export function getPlatformIcon(platform) {
  return (
    <span role="img" className="anticon">
      <img
        className="w-5 h-5"
        src={IntegrationIcons[platform.type] ? IntegrationIcons[platform.type] : IconCustom}
        alt={platform.type}
      />
    </span>
  );
}
