import React from 'react';
import { Typography } from 'antd';
import { RiseOutlined, SettingOutlined } from '@ant-design/icons';
import ColleagueDropdown from './ColleagueDropdown';
import ClickableTable from '../ClickableTable';
import ScoreAvatar from '../clients/ScoreAvatar';
import NoDataGathered from '../clients/NoDataGathered';

const { Text } = Typography;

const columns = (loadList) => [
  {
    title: 'Avatar',
    dataIndex: 'avatar',
    key: 'avatar',
    width: 46,
    render: (image, client) => <ScoreAvatar client={client} disableBreakpoints />,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <h4 className="table-text-bold">{text}</h4>,
  },
  {
    title: 'Info',
    dataIndex: 'info',
    key: 'info',
    // eslint-disable-next-line no-nested-ternary
    render: (info, client) => (!client.loading ? (
      <div className={`info info--${client.prediction}`}>
        <RiseOutlined className={`info__svg info__svg--${client.foregroundColor}`} />
        <Text className={`info__text info__text--${client.backgroundColor}`}>{info}</Text>
      </div>
    ) : client.errored ? (
      <NoDataGathered client={client} loadList={loadList} />
    ) : (
      <>
        <SettingOutlined spin />
        {' '}
        Gathering data
      </>
    )),
    className: 'hide-tablet-v',
  },
  {
    title: 'Actions',
    render: (client) => (!client.loading
      ? <ColleagueDropdown loadList={loadList} client={client} />
      : null),
  },
];

const ColleagueTable = ({
  clients, loadList, ...props
}) => (
  <ClickableTable
    resourceName="team-members"
    columns={columns(loadList)}
    dataSource={clients}
    {...props}
  />
);
export default ColleagueTable;
