import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import auth from '@/api/auth';
import userAuthenticatedAction from '@/store/actions/userAuthenticated';
import { platforms } from '@/api';
import { displayOrCatchMessage } from '@/helpers';
import googlePlatformDisconnectedAction from '@/store/actions/googlePlatformDisconnected';
import microsoftPlatformDisconnectedAction from '@/store/actions/microsoftPlatformDisconnected';
import googlePlatformReconnectedAction from '@/store/actions/googlePlatformReconnected';
import microsoftPlatformReconnectedAction from '@/store/actions/microsoftPlatformReconnected';
import { useQueryClient } from '@tanstack/react-query';

const UserInfoProvider = ({
  user,
  userAuthenticated,
  children,
  googlePlatformDisconnected,
  microsoftPlatformDisconnected,
  googlePlatformReconnected,
  microsoftPlatformReconnected,
}) => {
  const [loading, setLoading] = useState(true);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!user) {
      setLoading(false);
    } else {
      auth
        .check(queryClient)
        .then((response) => {
          const { data } = response.data;
          userAuthenticated(data.user);
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (!loading && user) {
      platforms
        .index()
        .then((r) => {
          r.data.data.forEach((platform) => {
            if (platform.type === 'google' && platform.healthcheck === 'bad') {
              googlePlatformDisconnected();
            } else if (
              platform.type === 'microsoft'
              && platform.healthcheck === 'bad'
            ) {
              microsoftPlatformDisconnected();
            } else if (
              platform.type === 'google'
              && platform.healthcheck === 'ok'
            ) {
              googlePlatformReconnected();
            } else if (
              platform.type === 'microsoft'
              && platform.healthcheck === 'ok'
            ) {
              microsoftPlatformReconnected();
            }
          });
        })
        .catch((e) => {
          displayOrCatchMessage(e);
        });
    }
  }, [user, loading]);

  if (loading) {
    return (
      <div style={{ marginTop: 50, textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  return children;
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  userAuthenticated: (data) => dispatch(userAuthenticatedAction(data)),
  googlePlatformDisconnected: () => dispatch(googlePlatformDisconnectedAction()),
  microsoftPlatformDisconnected: () => dispatch(microsoftPlatformDisconnectedAction()),
  googlePlatformReconnected: () => dispatch(googlePlatformReconnectedAction()),
  microsoftPlatformReconnected: () => dispatch(microsoftPlatformReconnectedAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserInfoProvider);
