import React from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { Empty } from 'antd';

const GatheringDataEmpty = (props) => (
  <Empty
    className="empty-gathering"
    image={<SettingOutlined style={{ fontSize: '34px' }} spin />}
    description="Gathering data"
    {...props}
  />
);

export default GatheringDataEmpty;
