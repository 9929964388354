import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import AuthBG from '@/images/bg-auth.png';
import AuthBG2x from '@/images/bg-auth@2x.png';
import Logo from '@/components/ui/logo';
import ResetPasswordForm from '@/components/auth/ResetPasswordForm';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export default function ResetPasswordPage() {
  const { token } = useParams();
  return (
    <section className="relative min-h-screen flex justify-center">
      <div className="mt-8 md:mt-24 md:min-w-[400px] md:max-w-[400px]">
        <motion.div
          className="flex justify-center"
          initial={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.5 }}
          animate={{
            opacity: [0, 1],
            y: 0,
          }}
        >
          <Link className="flex" to="/">
            <Logo />
          </Link>
        </motion.div>
        <motion.div
          className="mt-7"
          initial={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          layout
          animate={{
            opacity: [0, 1],
            y: 0,
          }}
          exit={{ opacity: 0, y: 20 }}
        >
          <h1 className="text-[18px] text-center m-0 p-0 text-[#44596C] font-[Gordita-Medium]">Reset your password</h1>
          <ResetPasswordForm token={token} />
          <div className="mt-4 px-4 md:px-0 font-[Gordita-Regular] flex justify-between text-">
            <Link className="text-[#44596C]" to="/login">Remember your password?</Link>
          </div>
        </motion.div>
        <motion.img
          initial={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          layout
          animate={{
            opacity: [0, 1],
            y: 0,
          }}
          exit={{ opacity: 0, y: 20 }}
          src={AuthBG}
          srcSet={`${AuthBG2x} 2x`}
          alt="bg-auth"
          className="absolute bottom-0 left-0 w-full -z-10"
        />
      </div>
    </section>
  );
}
