/* eslint-disable react/jsx-filename-extension, max-len */
import React from 'react';

const getClientIsEvanghelistData = (row) => ({
  text: `${row.client.name} is very happy`,
  description: '',
  recommended_step1: `Ask ${row.client.name} for a testimonial.`,
  recommended_step2: `Ask ${row.client.name} for another review.`,
  recommended_step3: 'Enjoy. You are creating value for your clients.',
  recommended_step1_description: (
    <>
    </>
  ),
  recommended_step2_description: (
    <>
    </>
  ),
  hasRecommendation: false,
});

export default getClientIsEvanghelistData;
