import React, { useCallback, useMemo, useState } from 'react';
import { Button, Modal } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import './index.style.less';
import { useParams } from 'react-router-dom';
import { exportData } from '@/api';
import { useFiltersStore } from '@/store/zustand/useFiltersStore';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import { displayOrCatchMessage } from '@/helpers';
import { transformCustomFieldToPost } from '@/api/conversations';

const ExportButton = ({ filename, type = 'discovery', searchValue = '' }) => {
  const [loading, setLoading] = useState(false);
  const { topicId, subtopicId } = useParams();
  const { filters } = useFiltersStore((state) => state);
  const { defaultDate } = useDefaultDateStore();
  const appliedFilters = useMemo(() => ({
    topic_id: topicId,
    start: defaultDate.start,
    end: defaultDate.end,
    custom_fields: transformCustomFieldToPost(filters.custom_fields),
    subtopic_id: subtopicId,
    included_keywords: filters.included_keywords,
    sentiment: filters.sentiment,
    labels: filters.labels,
    excluded_keywords: filters.excluded_keywords,
    sources: filters.sources,
    nps: filters.nps,
    topic_summary_request_id: filters.topic_summary_request_id,
    segments: filters.segments,
    search: searchValue,
    filename,
  }), [
    filters.custom_fields,
    filters.included_keywords,
    filters.sentiment,
    filters.labels,
    filters.excluded_keywords,
    filters.sources,
    filters.nps,
    filters.topic_summary_request_id,
    filters.segments,
    defaultDate.start,
    defaultDate.end,
    topicId,
    subtopicId,
    filename,
    searchValue,
  ]);
  const onGenerate = useCallback(() => {
    setLoading(true);
    const exporter = type === 'discovery' ? exportData.discovery : exportData.clients;
    exporter(appliedFilters).then(() => {
      setLoading(false);
    }).catch((e) => {
      if (e?.response?.status === 400) {
        Modal.confirm({
          className: 'pro-feature-modal',
          title: (
            <div className="text-[#202324] flex items-center justify-center">
              <span className="text-[24px] mr-2">
                <CloudDownloadOutlined />
              </span>
              <span>You discovered a PRO feature!</span>
            </div>
          ),
          icon: null,
          content: (
            <div className="text-sm text-[#75899B] font-[Gordita-Regular] text-center">
              Please contact us if you would like to activate it for your account. Thank you.
            </div>
          ),
          okText: 'Contact us to activate it',
          cancelText: 'Cancel',
          onOk: () => {
            window.open('https://www.clientzen.io/contact', '_blank');
          },
        });
      } else {
        displayOrCatchMessage(e);
      }

      setLoading(false);
    });
  }, [appliedFilters]);

  return (
    <Button loading={loading} onClick={onGenerate} icon={<CloudDownloadOutlined />} className="export-button" type="secondary">Export</Button>
  );
};

export default ExportButton;
