import axiosBase from 'axios';
import config from '@/config';
import deleteAccessTokenAndUserData from './deleteAccessTokenAndUserData';

const axios = axiosBase.create({
  baseURL: config.apiBaseUrl,
  withCredentials: true,
  headers: { Accept: 'application/json' },
  transformRequest: [function addCredentialsToRequest(data, headers) {
    const accessToken = localStorage.getItem('access_token');

    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      headers.Authorization = `Bearer ${accessToken}`;
    }
    return data;
  }, ...axiosBase.defaults.transformRequest],
  transformResponse: [function transformResponse(data) {
    if (typeof data === 'string') {
      try {
        // eslint-disable-next-line no-param-reassign
        data = JSON.parse(data);

        try {
          if (data.errors.general[0] === 'Unauthenticated') {
            deleteAccessTokenAndUserData();
            window.location.reload();
          }
        } catch (e) { /* Ignore */ }
      } catch (e) { /* Ignore */ }
    }
    return data;
  }],
});

export default axios;
