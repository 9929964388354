const showInsight = (data, onClientList, isColleague) => (dispatch) => {
  dispatch({
    type: 'SHOW_INSIGHT',
    item: data,
    onClientList,
    isColleague,
  });
};

export default showInsight;
