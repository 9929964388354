import { subtopics } from '@/api';
import { useQuery } from '@tanstack/react-query';

export default function useSubtopic(id, filters, options) {
  return useQuery({
    queryKey: ['subtopic', id, { filters }],
    queryFn: async () => {
      const { data: response } = await subtopics.getSubtopic(id, filters);
      return response;
    },
    enabled: !!id,
    ...options,
  });
}
