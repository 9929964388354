const getTotal = (plan) => {
  switch (plan) {
    case 'starter_monthly': return 120;
    case 'starter_annually': return 100 * 12;
    case 'world_class_monthly': return 588;
    case 'world_class_annually': return 490 * 12;
    default: return 0;
  }
};

const getName = (plan) => {
  switch (plan) {
    case 'starter_monthly':
    case 'starter_annually': return 'Essential';
    case 'world_class_monthly':
    case 'world_class_annually': return 'Pro';
    default: return null;
  }
};

export {
  getTotal,
  getName,
};
