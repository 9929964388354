/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo } from 'react';
import { cn } from '@/helpers/util';
import { Plus } from 'lucide-react';
import useSources from '@/data/useSources';
import { Link } from 'react-router-dom';
import { Spin, message } from 'antd';
import { captureException } from '@sentry/react';
import { StatsCard } from '@/components/ui/stats-card';
import Progress from '../ui/progress';

const defaultColors = [
  '#BA97DC', // 0
  '#8B96D9', // 1
  '#70C2BA', // 2
  '#DBA8A8', // 3
  '#E7C279', // 4
  '#9AD98B', // 5
  '#DC97C0', // 6
  '#89BDE9', // 7
];

export function SourcesStats() {
  const {
    data, isLoading, isError, error,
  } = useSources();

  const sources = useMemo(() => {
    const list = [];
    if (!data) return [];

    Object.keys(data).forEach((key) => {
      const label = key[0].toUpperCase() + key.slice(1);
      const count = data[key].reduce((acc, item) => acc + item.conversations_count, 0);

      if (count === 0) return;
      list.push({
        key, label, count,
      });
    });
    return list.sort((a, b) => b.count - a.count).map((item, index) => ({
      ...item,
      color: defaultColors[index],
    }));
  }, [data]);

  const bars = useMemo(() => {
    const list = [];
    const total = sources.reduce((acc, item) => acc + item.count, 0);
    sources.forEach((source) => {
      const percent = (source.count / total) * 100;
      list.push({
        value: percent < 2 ? (percent + 6) / 100 : percent / 100,
        color: source.color,
      });
    });
    list.push({ value: 2, color: '#EFF4F8' });

    return list;
  }, [sources]);

  useEffect(() => {
    if (isError) {
      message.error('Something went wrong. Please try again later.');
      captureException(error);
    }
  }, [isError]);

  if (isLoading) {
    <div className="flex justify-center items-center">
      <Spin />
    </div>;
  }

  return (
    <StatsCard title="Sources">
      <div className="text-xs text-[#75899B] flex justify-between mt-1">
        <div className="flex flex-wrap gap-4">
          {sources.map((source) => (
            <div key={source.key} className="flex items-center gap-1 text-xs">
              <span style={{ color: source.color }}>&bull;</span>
              <span className="text-[#44596C]">
                {source.label}
              </span>
            </div>
          ))}
        </div>
        <Link to="/integrations" className="text-[#75899B] font-[Gordita-Medium] flex gap-1">
          <Plus size={16} />
          Add source
        </Link>
      </div>
      <Progress.Bars
        bars={bars}
        className="!h-1 mt-1"
      />
    </StatsCard>
  );
}
