import React from 'react';
import HighlightedText from '../../../../../../components/HighlightedText';

const ConversationWithHighlightedKeywords = (
  {
    text, keywords, isCollapsed, className, ...props
  },
) => {
  const textClassName = isCollapsed ? `collapsed-conversation ${className || ''}` : className;

  return <HighlightedText text={text} keywords={keywords} {...props} className={textClassName} />;
};

export default ConversationWithHighlightedKeywords;
