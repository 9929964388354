export default (state = {
  google: false,
  microsoft: false,
  intercom: false,
}, action) => {
  switch (action.type) {
    case 'GOOGLE_PLATFORM_DISCONNECTED':
      return {
        ...state,
        google: true,
      };
    case 'GOOGLE_PLATFORM_RECONNECTED':
      return {
        ...state,
        google: false,
      };
    case 'MICROSOFT_PLATFORM_DISCONNECTED':
      return {
        ...state,
        microsoft: true,
      };
    case 'MICROSOFT_PLATFORM_RECONNECTED':
      return {
        ...state,
        microsoft: false,
      };
    case 'INTERCOM_PLATFORM_DISCONNECTED':
      return {
        ...state,
        intercom: true,
      };
    case 'INTERCOM_PLATFORM_RECONNECTED':
      return {
        ...state,
        intercom: false,
      };
    default:
      return state;
  }
};
