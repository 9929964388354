import { platforms } from '@/api';
import { useQuery } from '@tanstack/react-query';

export function usePlatforms() {
  return useQuery({
    queryKey: ['platforms'],
    queryFn: async () => {
      const { data: response } = await platforms.index();
      return response.data;
    },
    refetchInterval: 15 * 1000,
  });
}
