import React, { useEffect, useState } from 'react';
import { AutoComplete } from 'antd';
import { companies } from '@/api';
import { displayOrCatchMessage } from '../helpers';

const autocompleteFilter = (inputValue, option) => {
  const prepared = option.value.toUpperCase();
  return prepared.indexOf(inputValue.toUpperCase()) !== -1;
};

const CompanyAutocomplete = ({ nonce, ...props }) => {
  const [autocompleteLoading, setAutocompleteLoading] = useState(true);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);

  useEffect(() => {
    companies.index().then((response) => {
      setAutocompleteLoading(false);
      setAutocompleteOptions(response.data.data.map((company) => ({
        value: company.name,
      })));
    }).catch((e) => {
      displayOrCatchMessage(e);
    });
  }, [nonce]);

  return (
    <AutoComplete
      loading={autocompleteLoading}
      options={autocompleteOptions}
      size="large"
      placeholder="Client's Company"
      filterOption={autocompleteFilter}
      {...props}
    />
  );
};

export default CompanyAutocomplete;
