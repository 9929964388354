import React, { useCallback, useState } from 'react';
import { topics } from '@/api';
import { displayOrCatchMessage } from '@/helpers';
import { Switch } from 'antd';

function AnomalySubscribeSwitch({ topicId, topic }) {
  const [checked, setChecked] = useState(topic ? topic.anomaly_subscribed : false);
  const [loading, setLoading] = useState(false);
  const onChange = useCallback(() => {
    setLoading(true);
    setChecked(!checked);

    topics.updateAnomalySubscription(topicId, !checked ? 'subscribe' : 'unsubscribe').then(() => {
      setLoading(false);
    }).catch((e) => {
      setLoading(false);
      displayOrCatchMessage(e);
    });
  }, [topicId, checked]);

  return topic ? (
    <div className="flex pb-3 gap-1">
      <h3 className="m-0 mr-1.5 pb-1 text-[12px] text-[#44596C]">Send anomaly emails</h3>
      <div>
        <Switch size="small" disabled={loading} checked={checked} onChange={onChange} />
      </div>
    </div>
  ) : null;
}

export default AnomalySubscribeSwitch;
