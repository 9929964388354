import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button } from 'antd';
import {
  LockOutlined,
  MailOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { auth } from '@/api';
import useResetGlobal from '@/store/zustand/useResetGlobal';
import rules from '../../helpers/rules';
import { displayOrCatch, useErrorState } from '../../helpers';
import userAuthenticatedAction from '../../store/actions/userAuthenticated';
import PopoutForm from '../forms/PopoutForm';
import userSubscribedStatusLoading from '../../store/actions/userSubscribedStatusLoading';
import fetchSubscription from '../../helpers/fetchSubscription';
import mixpanel, { mixpanelEvents } from '../../mixpanel';

const onFinish = (
  userAuthenticated,
  userSubscribeStatusLoading,
  fetchUserSubscription,
  setError,
  setLoading,
  token,
  requestedFeatures,
  resetStore,
) => (values) => {
  setLoading(true);
  auth
    .register({
      ...values,
      invitation_code: token,
      requested_features: requestedFeatures,
    })
    .then((response) => {
      resetStore();
      userSubscribeStatusLoading();
      userAuthenticated(response.data.data.user);
      fetchUserSubscription();
      mixpanel.track(mixpanelEvents.USER_CREATED);
    })
    .catch((e) => {
      displayOrCatch(e, setError);
      setLoading(false);
    });
};

const getRequestedFeatures = (location) => {
  if (location.pathname.startsWith('/register/team-and-clients')) {
    return 'all';
  }

  if (location.pathname.startsWith('/register/team')) {
    return 'team';
  }

  return undefined;
};

const RegisterForm = ({
  userAuthenticated,
  userSubscribeStatusLoading,
  fetchUserSubscription,
  data,
  token,
}) => {
  const [error, setError] = useErrorState();
  const [loading, setLoading] = useState();
  const location = useLocation();
  const { resetStore } = useResetGlobal();

  return (
    <PopoutForm
      className="!mt-6 space-y-3 !px-4 md:!px-0"
      popoutError={error}
      onFinish={onFinish(
        userAuthenticated,
        userSubscribeStatusLoading,
        fetchUserSubscription,
        setError,
        setLoading,
        token,
        getRequestedFeatures(location),
        resetStore,
      )}
    >
      <div className="!space-y-3">
        <Form.Item
          className="!m-0"
          name="name"
          rules={rules.name('Name is required')}
          initialValue={data ? data.name : ''}
        >
          <Input
            size="large"
            placeholder="Your name"
            prefix={<UserOutlined className="form-item-icon" />}
          />
        </Form.Item>
        <Form.Item
          className="!m-0"
          name="email"
          rules={rules.email}
          initialValue={data ? data.email : ''}
        >
          <Input
            size="large"
            placeholder="Business email"
            disabled={data && data.email}
            prefix={<MailOutlined className="form-item-icon" />}
          />
        </Form.Item>
        <Form.Item className="!m-0" name="password" rules={rules.password}>
          <Input.Password
            size="large"
            placeholder="Password"
            prefix={<LockOutlined className="form-item-icon" />}
          />
        </Form.Item>
      </div>
      <div className="!space-y-4">
        <p className="m-0 text-[#75899B] [&>a]:text-[#75899B] font-[Gordita-Regular] text-xs">
          By creating an account, you agree to our
          <a
            className="mx-1"
            rel="noreferrer"
            target="_blank"
            href="https://clientzen.io/privacy-policy"
          >
            Privacy Policy
          </a>
          and
          <a
            className="ml-1"
            rel="noreferrer"
            target="_blank"
            href="https://clientzen.io/terms-and-conditions"
          >
            Terms of Service
          </a>
          .
        </p>
        <Button
          loading={loading}
          size="large"
          className="cta"
          type="primary"
          htmlType="submit"
        >
          Try for free
        </Button>
      </div>
    </PopoutForm>
  );
};

const mapDispatchToProps = (dispatch) => ({
  userAuthenticated: (data) => dispatch(userAuthenticatedAction(data)),
  userSubscribeStatusLoading: () => dispatch(userSubscribedStatusLoading()),
  fetchUserSubscription: () => fetchSubscription(dispatch),
});

export default connect(null, mapDispatchToProps)(RegisterForm);
