import { useEffect } from 'react';
import { connect } from 'react-redux';
import fetchSubscription from '../../helpers/fetchSubscription';

const UserSubscriptionProvider = ({ user, fetchUserSubscription, children }) => {
  useEffect(() => {
    if (user) {
      fetchUserSubscription();
    }
  }, []);

  return children;
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserSubscription: () => fetchSubscription(dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSubscriptionProvider);
