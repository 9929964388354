/* eslint-disable react/jsx-filename-extension, max-len */
import React from 'react';

const getClientNoFeedbackData = (row, data) => ({
  text: data && (typeof data.days !== 'undefined')
    ? `No feedback since ${data.days} day${data.days !== 1 ? 's' : ''}`
    : 'No feedback',
  description: '',
  recommended_step1: `Get in touch with ${row.client.name}`,
  recommended_step2: 'Here are some extra tips to consider',
  recommended_step3: (
    <>
      Check out
      {' '}
      <a rel="noreferrer" target="_blank" href="https://www.practiceignition.com/us/blog/follow-up-emails-after-no-response-from-client">this article</a>
      {' '}
      for more useful information.
    </>
  ),
  recommended_step1_description: (
    <>
      Use the communication platform that worked best in past (email, call, etc.)
    </>
  ),
  recommended_step2_description: (
    <ul>
      <li>Be persistent, but not annoying</li>
      <li>{`Always give ${row.client.name} a call-to-action`}</li>
      <li>Follow up. Recommended times are 3 days, 1 week, 2 weeks, 1 month</li>
    </ul>
  ),
  messages: [],
  hasMessages: false,
});

export default getClientNoFeedbackData;
