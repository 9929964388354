import * as Sentry from '@sentry/react';
import { inProduction } from '@/flags';

export default {
  identify: ({ id, ...data }) => {
    if (inProduction() && window.hj) {
      try {
        window.hj('identify', id, data);
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  },
};
