import { topics } from '@/api';
import { useQuery } from '@tanstack/react-query';

export default function useTopics(groupId = undefined, filters, params, options) {
  return useQuery({
    queryKey: ['topics', groupId, filters, params],
    queryFn: async () => {
      const { data } = await topics.getTopics(groupId, filters, params);
      return data;
    },
    ...options,
  });
}
