/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';

const OnboardingDemoVideo = () => (
  <div
    style={{
      position: 'relative',
      paddingBottom: 'calc(54.66012613875263% + 41px)',
      height: 0,
      width: '100%',
      marginTop: 80,
    }}
  >
    <iframe
      src="https://demo.arcade.software/lOLhOc7j2x8lDWXgmQHP?embed"
      frameBorder="0"
      loading="lazy"
      webkitallowfullscreen
      mozallowfullscreen
      allowFullScreen
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        colorScheme: 'light',
      }}
    />
  </div>
);

export default OnboardingDemoVideo;
