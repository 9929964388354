import React, { useState, useEffect, useRef } from 'react';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import Collapse from '@kunukn/react-collapse';
import { Card, Tooltip, Typography } from 'antd';
import Gmail from '../svgs/Gmail';
import GhostButton from '../GhostButton';
import InsightPartHelpfulMark from './InsightPartHelpfulMark';
import InsightAnatomyCardRecipients from './InsightAnatomyCardRecipients';

const { Text } = Typography;

const toggle = (open, setIsOpen) => () => setIsOpen(!open);

const renderKeywords = (text, keywords) => {
  const keys = Object.keys(keywords);
  let parsed = text;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    parsed = parsed.replaceAll(key, `<span class="keyword-${keywords[key]}">${key}</span>`);
  }

  return parsed;
};

const InsightAnatomyCard = ({
  id,
  text,
  created,
  keywords,
  maxHeight = 70,
  helpful,
  insight,
  inaccessible,
  to,
  cc,
  from,
  isColleague,
}) => {
  const [realHeight, setRealHeight] = useState(null);
  const [height, setHeight] = useState(null);
  const [open, setIsOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        if (ref.current) {
          setRealHeight(ref.current.clientHeight);

          if (ref.current.clientHeight < maxHeight) {
            setHeight(ref.current.clientHeight);
          } else {
            setHeight(maxHeight);
          }
        }
      });
    }
  }, []);

  return (
    <Card className="insight-anatomy-card">
      {
        height ? (
          <div className={`${realHeight > maxHeight ? 'faded' : ''} ${open ? 'opened' : ''} ${inaccessible ? 'blurry-text' : ''}`}>
            <Collapse isOpen={open} collapseHeight={height}>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: renderKeywords(text, keywords) }} />
            </Collapse>
          </div>
        ) : (
          // eslint-disable-next-line react/no-danger
          <div className={inaccessible ? 'blurry-text' : ''} ref={ref} dangerouslySetInnerHTML={{ __html: renderKeywords(text, keywords) }} />
        )
      }
      <div className="actions">
        <div className="platform-details">
          <Gmail style={{ width: 16 }} />
          <Text type="secondary">{created}</Text>
          <InsightAnatomyCardRecipients
            to={to}
            from={from}
            cc={cc}
          />
        </div>
        <div>
          {realHeight > maxHeight ? (
            <Tooltip className="mx-sm" title="expand">
              <GhostButton type="primary" onClick={toggle(open, setIsOpen)}>
                {
                  open ? <ArrowUpOutlined /> : <ArrowDownOutlined />
                }
              </GhostButton>
            </Tooltip>
          ) : null}
          {
            !inaccessible ? (
              <InsightPartHelpfulMark
                helpful={helpful}
                insight={insight}
                id={id}
                isColleague={isColleague}
              />
            ) : null
          }
        </div>
      </div>
    </Card>
  );
};

export default InsightAnatomyCard;
