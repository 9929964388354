import React from 'react';
import { Tag } from 'antd';
import HighlightedText from '@/components/HighlightedText';

const ConversationWithHighlightedKeywords = (
  {
    text, keywords, isCollapsed, isCollapsable, changeTextCollapse, className, ...props
  },
) => {
  const textClassName = isCollapsed ? `collapsed-conversation ${className || ''}` : className;

  return (
    <>
      <HighlightedText
        text={text}
        keywords={keywords}
        {...props}
        className={textClassName}
        style={{ display: 'inline' }}
      />
      {isCollapsable
      && (
        <Tag
          onClick={changeTextCollapse}
          style={{ marginLeft: 8, cursor: 'pointer' }}
          className="conversation-text-collapse-button"
        >
          {isCollapsed ? 'more' : 'less'}
        </Tag>
      )}
    </>
  );
};

export default ConversationWithHighlightedKeywords;
