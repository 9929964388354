/* eslint-disable react/jsx-filename-extension, max-len */
import moment from 'moment';
import React from 'react';
import { getClientData } from '../../clients';
import getClientAddedToClientzenData from './getClientAddedToClientzenData';
import getClientIsHappyData from './getClientIsHappyData';
import getClientNeedsAttentionData from './getClientNeedsAttentionData';
import getClientIsUnhappyData from './getClientIsUnhappyData';
import getClientNoFeedbackData from './getClientNoFeedbackData';
import getClientNoFeedbackThreeDaysData from './getClientNoFeedbackThreeDaysData';
import getClientNoFeedbackOneWeekData from './getClientNoFeedbackOneWeekData';
import getClientNoFeedbackTwoWeeksData from './getClientNoFeedbackTwoWeeksData';
import getClientNoFeedbackOneMonthData from './getClientNoFeedbackOneMonthData';
import getClientAskTestimonial from './getClientAskTestimonial';
import getClientIsRealEvanghelistData from './getClientIsRealEvanghelistData';
import getClientAskReviewClutch from './getClientAskReviewClutch';
import getClientAskReviewGoodfirms from './getClientAskReviewGoodfirms';
import getClientAskReviewGoogle from './getClientAskReviewGoogle';
import getClientIsEvanghelistData from './getClientIsEvanghelistData';
import getClientSentMessageAtUnusualHour from './getClientSentMessageAtUnusualHour';

function ucfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const getUnknownInsightData = (row) => ({
  text: ucfirst(row.type).replace(/_/gi, ' '),
  description: '',
  recommended_step1: '',
  recommended_step2: '',
  recommended_step3: '',
  recommended_step1_description: (
    <>
    </>
  ),
  recommended_step2_description: (
    <>
    </>
  ),
  hasMessages: true,
  hasRecommendation: false,
});

const getInsightMessageBody = (message) => {
  if (message.body) {
    return message.body;
  }

  if (message.html_body) {
    return message.html_body;
  }

  return message.snippet;
};

const getInsightMessage = (message) => ({
  key: message.id,
  text: message.inaccessible ? 'Sorry, you do not have access to see this message. Contact the client to request it.' : getInsightMessageBody(message),
  created: moment(message.external_date).from(moment()),
  keywords: {},
  helpful: message.helpful,
  inaccessible: message.inaccessible,
  to: message.to,
  from: message.from,
  cc: message.cc,
});

const computeInsightData = (statusDataHandler, row) => ({
  key: row.id,
  client: getClientData(row.client),
  status: row.status,
  created: moment(row.created_at).from(moment()),
  messages: row.messages ? row.messages.map(getInsightMessage) : [],
  marked_as_helpful: row.marked_as_helpful,
  marked_as_helpful_by: row.marked_as_helpful_by,
  marked_as_helpful_at: row.marked_as_helpful_at,
  background_color: row.background_color,
  foreground_color: row.foreground_color,
  feedback_type: row.feedback_type,
  shown_on_dashboard: row.shown_on_dashboard,
  can_be_resolved: row.can_be_resolved,
  can_be_unuseful: row.can_be_unuseful,
  previous_insight_id: row.previous_insight_id,
  next_insight_id: row.next_insight_id,
  previous_per_client_insight_id: row.previous_per_client_insight_id,
  next_per_client_insight_id: row.next_per_client_insight_id,
  hasMessages: true,
  hasRecommendation: true,
  ...statusDataHandler(row, JSON.parse(row.data)),
});

const getClientInsightData = (row) => {
  switch (row.type) {
    case 'client_added_to_clientzen': return computeInsightData(getClientAddedToClientzenData, row);
    case 'client_is_evangelist': return computeInsightData(getClientIsEvanghelistData, row);
    case 'client_is_happy': return computeInsightData(getClientIsHappyData, row);
    case 'client_needs_attention': return computeInsightData(getClientNeedsAttentionData, row);
    case 'client_is_unhappy': return computeInsightData(getClientIsUnhappyData, row);
    case 'client_no_feedback': return computeInsightData(getClientNoFeedbackData, row);
    case 'client_no_feedback_three_days': return computeInsightData(getClientNoFeedbackThreeDaysData, row);
    case 'client_no_feedback_one_week': return computeInsightData(getClientNoFeedbackOneWeekData, row);
    case 'client_no_feedback_two_weeks': return computeInsightData(getClientNoFeedbackTwoWeeksData, row);
    case 'client_no_feedback_one_month': return computeInsightData(getClientNoFeedbackOneMonthData, row);
    case 'client_sent_message_at_unusual_hour': return computeInsightData(getClientSentMessageAtUnusualHour, row);
    case 'client_ask_testimonial': return computeInsightData(getClientAskTestimonial, row);
    case 'client_is_real_evangelist': return computeInsightData(getClientIsRealEvanghelistData, row);
    case 'client_ask_review_clutch': return computeInsightData(getClientAskReviewClutch, row);
    case 'client_ask_review_goodfirms': return computeInsightData(getClientAskReviewGoodfirms, row);
    case 'client_ask_review_google': return computeInsightData(getClientAskReviewGoogle, row);
    default: return computeInsightData(getUnknownInsightData, row);
  }
};

export default getClientInsightData;
