import React, { useState } from 'react';
import {
  Button, Col, Form, Input, Row, message,
} from 'antd';
import { connect } from 'react-redux';
import { auth } from '@/api';
import rules from '../../helpers/rules';
import PopoutForm from '../forms/PopoutForm';
import { displayOrCatch, useErrorState } from '../../helpers';
import EmailChangeAlert from './EmailChangeAlert';
import userAuthenticatedAction from '../../store/actions/userAuthenticated';

const onFinish = ({
  email,
  setError,
  setLoading,
  setClosed,
  setNewEmail,
  userAuthenticated,
}) => (values) => {
  setLoading(true);

  const newEmail = values.email.toLowerCase();

  auth
    .updateProfile({
      ...values,
      email: newEmail,
    })
    .then((userData) => {
      userAuthenticated(userData);

      setNewEmail(newEmail);
      if (newEmail !== email) {
        message.success(
          'Please check your email to confirm your new address',
        );
        setClosed(false);
      } else {
        message.success('Your account was updated');
        setClosed(true);
      }

      setLoading(false);
    })
    .catch((e) => {
      displayOrCatch(e, setError);
      setLoading(false);
    });
};

const BasicInfoForm = ({ user, userAuthenticated }) => {
  const [error, setError] = useErrorState();
  const [loading, setLoading] = useState();
  const [closed, setClosed] = useState(true);
  const [newEmail, setNewEmail] = useState(null);

  return (
    <PopoutForm
      popoutError={error}
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish({
        email: user.email,
        setError,
        setLoading,
        setClosed,
        setNewEmail,
        userAuthenticated,
      })}
    >
      <div className="mb-md">
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              className="mb-sm"
              name="name"
              rules={rules.name('Full Name is required')}
              label="Full Name"
              initialValue={user.name}
            >
              <Input size="large" placeholder="Full Name" autoComplete="off" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              className="mb-sm"
              name="email"
              rules={rules.email}
              label="Email Address"
              initialValue={user.email}
            >
              <Input
                size="large"
                placeholder="Email Address"
                readOnly
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
      <EmailChangeAlert
        email={newEmail}
        closed={closed}
        setClosed={setClosed}
      />
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item className="mb-sm">
            <Button
              loading={loading}
              size="large"
              type="primary"
              htmlType="submit"
            >
              Save Changes
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </PopoutForm>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  userAuthenticated: (data) => dispatch(userAuthenticatedAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicInfoForm);
