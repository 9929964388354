import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import auth from '@/api/auth';
import ProtectedRoute from './ProtectedRoute';

const DemoRoute = ({ children, user, ...props }) => {
  if (user && !auth.hasDemo()) {
    return (
      <Redirect push to="/" />
    );
  }

  return (
    <ProtectedRoute {...props}>
      {children}
    </ProtectedRoute>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(DemoRoute);
