import React, { useEffect, useRef } from 'react';
import Column from 'antd/lib/table/Column';
import TablePagination from '@/base-components/table-pagination';
import LoadingTable from '@/components/LoadingTable';
import useURLState from '@/hooks/useUrlState';
import { useLabelsOld } from '@/components/settings/LabelsTab';
import ConversationContent from './ConversationContent';
import ConversationActions from './ConversationActions';
import useConversationsStore from '../store/useConversationsStore';

const ConversationsTable = ({
  hasPagination,
  showKeywords,
  isDiscovery,
  showTopicName,
  showSmallSubtopic,
  forTopicId,
  forSubtopicId,
  anchor,
  disableUrlQueryState,
}) => {
  const {
    state: {
      conversations, loading, pagination,
    },
    actions,
  } = useConversationsStore();

  const labels = useLabelsOld();

  const isMounted = useRef(false);

  const { urlQueryState, setUrlQueryParam, deleteUrlQueryParam } = useURLState();

  const handlePageChange = (page) => {
    if (!disableUrlQueryState) {
      if (page === 1) {
        deleteUrlQueryParam('page');
      } else {
        setUrlQueryParam('page', page);
      }
    } else {
      actions.getConversations(page);
    }

    try {
      if (anchor && anchor.current) {
        anchor.current.scrollIntoView();
      }
    } catch {
      // do nothing
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      if (!disableUrlQueryState) {
        actions.getConversations(urlQueryState.page ? urlQueryState.page : 1);
      }
    } else {
      isMounted.current = true;
    }
  }, [urlQueryState.page]);

  return (
    <LoadingTable
      resourceName="conversations"
      className="conversations-table"
      dataSource={conversations}
      loading={loading}
      showHeader={false}
      pagination={false}
      rowClassName="conversations-table-row group"
      footer={(hasPagination && conversations.length) ? () => (
        <TablePagination
          type="conversations"
          pagination={pagination}
          onPageChange={handlePageChange}
        />
      ) : undefined}
      scroll={{ x: true }}
    >
      <Column
        dataIndex="conversation-content"
        key="conversation-content"
        className="conversation-content"
        render={(_, conversation) => (
          <ConversationContent
            labels={labels}
            conversation={conversation}
            showKeywords={showKeywords}
            showTopicName={showTopicName}
            showSmallSubtopic={showSmallSubtopic}
          />
        )}
      />
      <Column
        dataIndex="conversation-actions"
        key="conversation-actions"
        fixed="right"
        className="conversation-actions"
        render={(_, conversation) => (
          <ConversationActions
            labels={labels}
            isDiscovery={isDiscovery}
            conversation={conversation}
            showConversationStatus
            forTopicId={forTopicId}
            forSubtopicId={forSubtopicId}
          />
        )}
      />
    </LoadingTable>
  );
};

export default ConversationsTable;
