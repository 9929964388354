import React, { useEffect, useState } from 'react';
import { Avatar as AntdAvatar } from 'antd';

const colorList = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae'];

const getClientStyle = (client, style) => {
  if (client.name) {
    const backgroundColor = client.color ? client.color
      : colorList[client.name.charCodeAt(0) % colorList.length];

    return {
      ...style,
      backgroundColor,
    };
  }

  return style;
};

const Avatar = ({ client, style, ...props }) => {
  const [currentStyle, setCurrentStyle] = useState(style);

  useEffect(() => {
    if (!client.avatar) {
      setCurrentStyle(getClientStyle(client, style));
    }
  }, [client, style]);

  return (
    <AntdAvatar
      {...props}
      src={client.avatar ? client.avatar : undefined}
      style={currentStyle}
      onError={() => {
        setCurrentStyle(getClientStyle(client, style));
      }}
    >
      { client.name ? client.name.split(' ').map((n) => n[0].toUpperCase()).join('') : '' }
    </AntdAvatar>
  );
};

export default Avatar;
