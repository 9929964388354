/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Button } from 'antd';
import { Trash2Icon } from 'lucide-react';
import { useMutation } from '@tanstack/react-query';
import { members } from '@/api';
import { displayOrCatchMessage } from '@/helpers';

export function DeleteInviteButton({ loadList, member }) {
  const deleteInvite = useMutation({
    mutationKey: 'deleteInvite',
    mutationFn: () => members.destroy(member),
    onSuccess: () => {
      loadList();
    },
    onError: (err) => {
      displayOrCatchMessage(err);
    },
  });

  return (
    <Button
      type="link"
      className="opacity-0 group-hover:opacity-100 !text-red-400 hover:!text-red-500 !bg-transparent !border-none disabled:hover:!text-red-300"
      onClick={deleteInvite.mutate}
      disabled={deleteInvite.isLoading}
    >
      <Trash2Icon width={18} />
    </Button>
  );
}
