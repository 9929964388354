import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '@/components/Layout';
import PeopleDashboardPage from '@/components/people-dashboard';

const PeopleDashboard = () => (
  <Layout>
    <Helmet>
      <title>ClientZen - People Dashboard</title>
    </Helmet>
    <PeopleDashboardPage />
  </Layout>
);

export default PeopleDashboard;
