import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const useModalStore = create(devtools((set) => ({
  type: null,
  payload: null,
  setModal: (type, data = null) => set({ type, payload: data }),
  reset: () => set({
    type: null,
    payload: null,
  }),
}), {
  name: 'useModalStore',
}));
