import React, { useCallback } from 'react';
import { Modal } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

const CsvModal = ({
  children, open, setOpen, ...props
}) => {
  const closeModal = useCallback(() => {
    Modal.confirm({
      title: 'Cancel import',
      content: (
        <>
          <p>Are you sure you want to exit? Any unsaved changes will be lost.</p>
        </>
      ),
      cancelText: 'No',
      okText: 'Yes',
      okButtonProps: { danger: true },
      icon: <WarningOutlined style={{ color: 'rgb(255, 77, 79)' }} />,
      onOk: () => {
        setOpen(false);
      },
      onCancel: () => {
        // do nothing
      },
    });
  }, [setOpen]);

  return (
    <Modal
      width={1240}
      title="Import CSV"
      visible={open}
      onOk={closeModal}
      onCancel={closeModal}
      destroyOnClose
      footer={null}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default CsvModal;
