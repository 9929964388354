import React from 'react';
import { Form, Input } from 'antd';
import PopoutForm from '../forms/PopoutForm';

const { Item } = Form;

const CategoryForm = ({
  form, defaultValues, error, onSubmit,
}) => (
  <PopoutForm
    layout="vertical"
    form={form}
    popoutError={error}
    onFinish={onSubmit}
    initialValues={defaultValues}
  >
    <Item
      key="name"
      name="name"
      label="Product category name"
      rules={[{ required: true, message: 'Product category name is required' }]}
    >
      <Input
        size="large"
        placeholder="e.g. Product Features"
      />
    </Item>
  </PopoutForm>
);

export default CategoryForm;
