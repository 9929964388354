import React, { useEffect } from 'react';
import {
  Empty, List, Tooltip, message,
} from 'antd';
import { ArrowUp, ArrowDown } from 'lucide-react';
import useSubtopics from '@/components/bucket/hooks/useSubtopics';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import { useHistory, useParams } from 'react-router-dom';
import { cn } from '@/helpers/util';

function SegmentItem({ item }) {
  const history = useHistory();
  const { topicId, groupId, subtopicId } = useParams();

  const handleClick = () => {
    if (parseInt(subtopicId, 10) !== item.id) history.push(`/topic/research/${topicId}/${groupId}/${item.id}`);
    else history.push(`/topic/research/${topicId}/${groupId}`);
  };

  return (
    <List.Item
      onClick={handleClick}
      className={cn('cz-saved-filters cursor-pointer hover:bg-[#F6F9FB] flex', parseInt(subtopicId, 10) === item.id && 'bg-[#F6F9FB]', {
        'selected-segment': parseInt(subtopicId, 10) === item.id,
      })}
    >
      <div className="flex gap-2 items-center">
        <div className={cn('flex flex-col gap-1 min-w-full p-2 rounded-lg')}>
          <div className="flex flex-col gap-1">
            <span className="text-sm font-[Gordita-Medium] text-[#202324]">
              {item.name.length >= 36 ? (
                <Tooltip title={item.name} placement="left">
                  {item.name.slice(0, 36)}
                  ...
                </Tooltip>
              ) : item.name}
            </span>
          </div>
          <div className="flex items-center text-[10px] text-[#75899B] gap-1">
            <span>{item.count.value}</span>
            {
            item.count.trend === 'ascending'
              ? <ArrowUp size={16} strokeWidth={2.5} color="#EDA2B4" />
              : <ArrowDown size={16} strokeWidth={2.5} color="#A9BCCF" />
          }
          </div>
        </div>
      </div>
    </List.Item>
  );
}

export default function SegmentHorizontalList() {
  const { topicId } = useParams();
  const { defaultDate } = useDefaultDateStore();
  const {
    data: subtopics, isLoading, isError, error,
  } = useSubtopics(topicId, {
    start: defaultDate.start,
    end: defaultDate.end,
  });

  const total = subtopics && subtopics.length > 0
    ? parseInt(subtopics[0].count.value, 10)
    : 0;

  useEffect(() => {
    if (!isError) return;
    message.error(error.message);
  }, [error]);

  return (subtopics && subtopics.length) ? (
    <div className="border-0 border-b border-solid border-[#E5ECF3]">
      <List
        className="[&_.ant-list-header]:p-0 [&_.ant-list-header]:!border-0 segment-horizontal-list"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No data"
            />
          ),
        }}
        loading={isLoading}
        dataSource={subtopics}
        renderItem={(item) => <SegmentItem item={item} total={total} />}
      />
    </div>
  ) : <div className="border-0 border-t border-solid border-[#E5ECF3]" />;
}
