import React, { useCallback, useRef } from 'react';
import { Button } from 'antd';
import canvasConfetti from 'canvas-confetti';

const ConfettiButton = ({ onClick, ...props }) => {
  const buttonRef = useRef(null);
  const setButtonRef = useCallback((ref) => { buttonRef.current = ref; }, [buttonRef]);
  const pop = useCallback((...params) => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      canvasConfetti({
        useWorker: true,
        angle: 45,
        particleCount: 100,
        ticks: 100,
        zIndex: 10000,
        spread: 70,
        origin: {
          y: (rect.top + rect.height) / window.innerHeight,
          x: rect.left / window.innerWidth,
        },
        colors: ['#0BAA6B', '#6DCCA6', '#CEEEE1'],
      });
    }
    onClick(...params);
  }, []);
  return (
    <Button
      onClick={pop}
      ref={setButtonRef}
      {...props}
    />
  );
};

export default ConfettiButton;
