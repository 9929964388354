/* eslint-disable */
import React from 'react';
import { Input } from 'antd';
import debounce from 'lodash/debounce';
import {SearchIcon} from "lucide-react";

const SearchInput = ({ search, setSearch, ...rest }) => {
	const searchProps = {
		className: 'people__header__search !bg-[#EDF4F8] [&_.ant-input-prefix]:!mr-1.5 [&>input]:!bg-[#EDF4F8] !rounded-md [&>input]:!placeholder-[#A9BCCF] [&>input]:placeholder:!opacity-100 !border-0 font-[Gordita-Regular] hover:border hover:border-[#A9BCCF] focus:border focus:border-[#A9BCCF] focus:ring-0',
		placeholder: 'Search People',
		prefix: <SearchIcon className="pr-0.5" size={16} strokeWidth={2} color="#44596C" />,
		onChange: debounce((e) => {
			setSearch(e.target.value);
		}, 400),
		defaultValue: search,
		...rest
	};

	return <Input {...searchProps} />;
};

export default SearchInput;
