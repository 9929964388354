import { buildQuery } from '@/helpers/filters';
import axios from './axios';
import auth from './auth';

const getAnnotations = (filters, perPage, page = 1) => axios.get(
  buildQuery(
    '/api/annotations', filters, {
      team_id: auth.team().id,
      per_page: perPage,
      page,
    },
  ),
)
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const createAnnotation = (values) => axios.post(
  '/api/annotations',
  values, {
    params: {
      team_id: auth.team().id,
    },
  },
)
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const deleteAnnotation = (id) => axios.delete(
  `/api/annotations/${id}?team_id=${auth.team().id}`,
)
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const annotations = {
  getAnnotations,
  createAnnotation,
  deleteAnnotation,
};

export default annotations;
