import React, { useEffect, useState } from 'react';
import {
  Button, Input, Tabs,
} from 'antd';
import debounce from 'lodash/debounce';
import {
  UserOutlined, UsergroupAddOutlined, FilterOutlined, UserAddOutlined,
} from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import {
  Link, useRouteMatch,
} from 'react-router-dom';
import Layout from '@/components/Layout';
import ClientTable from '@/components/clients/ClientTable';
import { clients } from '@/api';
import { displayOrCatchMessage } from '@/helpers';
import ClientAddModal from '@/components/clients/ClientAddModal';
import {
  getClientData,
} from '@/transformers/clients';
import config from '@/config';

const { TabPane } = Tabs;
export const load = (setLoadedClients, setLoading, setPager) => (
  company, name, page, needAttention,
) => {
  setLoading(true);
  clients.index({
    name,
    company,
    need_attention: needAttention ? 1 : undefined,
  }, page, needAttention ? 'score,-id' : undefined).then((response) => {
    setLoadedClients(response.data.data.map(getClientData));
    setPager(response.data.pager);
    setLoading(false);
  }).catch((e) => {
    displayOrCatchMessage(e);
    setLoading(false);
  });
};

const onBeginAdd = (setIsAddVisible) => () => {
  setIsAddVisible(true);
};

const Clients = () => {
  const [loadedClients, setLoadedClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pager, setPager] = useState(null);
  const [needAttentionCount, setNeedAttentionCount] = useState(null);
  const [allCount, setAllCount] = useState(null);
  const loadClientList = debounce(load(setLoadedClients, setLoading, setPager), 300);
  const [isAddVisible, setIsAddVisible] = useState(false);
  const [companyFilter, setCompanyFilter] = useState();
  const [nameFilter, setNameFilter] = useState();
  const { path } = useRouteMatch();
  const needAttention = path.endsWith('need_attention');
  const loadClientFilteredList = (dataPage) => loadClientList(
    companyFilter, nameFilter, dataPage, needAttention,
  );

  useEffect(() => {
    loadClientFilteredList(1);
  }, [companyFilter, nameFilter, needAttention]);

  useEffect(() => {
    clients.countNeedAttention().then((value) => {
      setNeedAttentionCount(value);
    }).catch((e) => {
      displayOrCatchMessage(e);
    });
    clients.countAll().then((value) => {
      setAllCount(value);
    }).catch((e) => {
      displayOrCatchMessage(e);
    });
  }, []);

  const handleChange = (pagination, filters, sorter, { action }) => {
    if (action === 'paginate') {
      setLoading(true);
      loadClientFilteredList(pagination.current);
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>ClientZen - Clients</title>
      </Helmet>
      <div className="datatable-header mb-lg">
        <h4 className="page-header">
          <UsergroupAddOutlined />
          {` My Clients${allCount !== null ? ` (${allCount})` : ''}`}
        </h4>
        <Button onClick={onBeginAdd(setIsAddVisible)} className="btn-only-icon-mobile" type="primary">
          <UserAddOutlined />
          {' '}
          New Client
        </Button>
      </div>
      <div className="datatable-header mx-md clients-tabs">
        <Tabs defaultActiveKey={needAttention ? '2' : '1'}>
          <TabPane tab={<Link to="/clients">All</Link>} key="1" />
          <TabPane tab={<Link to="/clients/need_attention">{`Need attention${needAttentionCount !== null ? ` (${needAttentionCount})` : ''}`}</Link>} key="2" />
        </Tabs>
        <div className="datatable-actions">
          <Input onChange={(event) => setCompanyFilter(event.target.value)} value={companyFilter} placeholder="Filter by Company" prefix={<FilterOutlined />} />
          <Input onChange={(event) => setNameFilter(event.target.value)} value={nameFilter} placeholder="Search Client" className="ml-sm" prefix={<UserOutlined />} />
          <ClientAddModal
            loadList={loadClientFilteredList}
            setVisible={setIsAddVisible}
            visible={isAddVisible}
          />
        </div>
      </div>
      <ClientTable
        loadList={loadClientFilteredList}
        clients={loadedClients}
        loading={loading}
        onChange={handleChange}
        pagination={{
          pageSize: config.pageSize,
          total: pager?.total ?? 0,
          current: pager?.current_page ?? 1,
          showSizeChanger: false,
        }}
      />
    </Layout>
  );
};

export default Clients;
