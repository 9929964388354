function buildParamsQuery({ sort, ...params }) {
  let query = Object.keys(params).map((key) => {
    if (params[key]) {
      return `${key}=${encodeURIComponent(params[key])}`;
    }

    return null;
  }).filter((item) => item !== null).join('&');

  if (sort) {
    query += `${query}&${sort}`;
  }

  return query;
}

function buildFilterQuery(filters) {
  if (!filters) {
    return '';
  }

  return Object.keys(filters).map((key) => {
    if (filters[key] instanceof Array) {
      let filtersString = '';
      filters[key].forEach(
        (value) => { filtersString += `&filter[${key}][]=${encodeURIComponent(value)}`; },
      );
      return filtersString.slice(1);
    }

    if (filters[key] !== undefined && filters[key] !== null && filters[key] !== '') {
      return `filter[${key}]=${encodeURIComponent(filters[key])}`;
    }

    return null;
  }).filter((item) => item !== null).join('&');
}

// Utils for AntD Sorter
function buildSorterQuery(sorter) {
  if (Array.isArray(sorter)) {
    const lastSorter = sorter[sorter.length - 1];

    if (lastSorter?.column && lastSorter?.order) {
      return `sort=${lastSorter.order === 'ascend' ? '' : '-'}${lastSorter.columnKey}`;
    }
  }

  if (sorter?.column && sorter?.order) {
    return `sort=${sorter.order === 'ascend' ? '' : '-'}${sorter.columnKey}`;
  }

  return null;
}

function buildQuery(base, filters, params = {}) {
  const filterQuery = buildFilterQuery(filters);
  const paramsQuery = buildParamsQuery(params);

  let query = '';
  if (filterQuery) {
    query += filterQuery;
  }

  if (paramsQuery) {
    query += `&${paramsQuery}`;
  }

  return query ? `${base}?${query}` : base;
}

export {
  buildFilterQuery,
  buildSorterQuery,
  buildParamsQuery,
  buildQuery,
};
