import axios from './axios';
import auth from './auth';

const all = () => axios.get('/api/colleague-dashboard', {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response).catch((e) => Promise.reject(e));

const ready = () => axios.get('/api/colleague-dashboard-ready', {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response.data.data).catch((e) => Promise.reject(e));

const highlighted = () => axios.get('/api/colleague-dashboard-highlighted', {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response.data.data).catch((e) => Promise.reject(e));

const trends = () => axios.get('/api/colleague-dashboard-trends', {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response.data.data).catch((e) => Promise.reject(e));

const last7DaysScores = () => axios.get('/api/colleague-dashboard-last-7-days-scores', {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response.data.data).catch((e) => Promise.reject(e));

const last30DaysScores = () => axios.get('/api/colleague-dashboard-last-30-days-scores', {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response.data.data).catch((e) => Promise.reject(e));

const colleaguesDashboard = {
  all,
  ready,
  highlighted,
  trends,
  last7DaysScores,
  last30DaysScores,
};

export default colleaguesDashboard;
