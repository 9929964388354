import * as Sentry from '@sentry/react';
import axios from './axios';
import deleteAccessTokenAndUserData from './deleteAccessTokenAndUserData';

const saveUserData = (data) => {
  localStorage.setItem('user', JSON.stringify(data));
};

const saveTeamsData = (data) => {
  localStorage.setItem('teams', JSON.stringify(data));
};

function saveAccessTokenAndUserData(data) {
  if (data.access_token) {
    localStorage.setItem('access_token', data.access_token);
  }

  if (data.expires_at) {
    localStorage.setItem('access_token_expires_at', data.expires_at);
  }

  if (data.user) {
    saveUserData(data.user);
  }

  if (data.teams) {
    saveTeamsData(data.teams);
  }
}

const register = (values) => axios.post('/api/register', {
  ...values,
  password_confirmation: values.password,
  choosen_plan: localStorage.getItem('choosen_plan'),
}).then((response) => {
  saveAccessTokenAndUserData(response.data.data);
  return response;
}).catch((e) => Promise.reject(e));

const preempt = (values) => axios.post('/api/preempt', {
  ...values,
}).then((response) => response).catch((e) => Promise.reject(e));

const login = (values) => axios.post('/api/login', values).then((response) => {
  saveAccessTokenAndUserData(response.data.data);
  return response;
}).catch((e) => Promise.reject(e));

const forgotPassword = (values) => axios.post('/api/forgot-password', values)
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const resetPassword = (token, values) => axios.post(`/api/password-reset/${token}`, {
  ...values,
  password_confirmation: values.password,
}).then((response) => response).catch((e) => Promise.reject(e));

const check = (queryClient) => axios.get('/api/me').then((response) => {
  const { data } = response.data;
  saveUserData(data.user);
  saveTeamsData(data.teams);
  return response;
}).catch((e) => {
  deleteAccessTokenAndUserData();
  try {
    if (queryClient) {
      queryClient.getQueryCache().clear();
    }
  } catch (err) {
    Sentry.captureException(err);
  }
  return Promise.reject(e);
});

const logout = () => axios.post('/api/logout').then(() => {
  deleteAccessTokenAndUserData();
}).catch((e) => {
  deleteAccessTokenAndUserData();
  return Promise.reject(e);
});

const me = () => JSON.parse(localStorage.getItem('user'));

const team = () => {
  try {
    const theTeam = JSON.parse(localStorage.getItem('teams')).find((t) => t.demo === window.location.pathname.startsWith('/demo'));
    if (!theTeam) {
      return JSON.parse(localStorage.getItem('teams'))[0];
    }

    return theTeam;
  } catch (e) {
    deleteAccessTokenAndUserData();
    window.location.reload();
    return null;
  }
};

const getCompanyName = () => {
  try {
    const theTeam = JSON.parse(localStorage.getItem('teams')).find((t) => t.demo === false);
    return theTeam.company_name;
  } catch (e) {
    return null;
  }
};

const hasDemo = () => !!JSON.parse(localStorage.getItem('teams')).find((t) => t.demo === true);

const updateProfile = (values) => axios.post('/api/update-profile', values)
  .then(() => {
    const { email, ...updatedValues } = values;
    const userData = {
      ...me(),
      ...updatedValues,
    };

    saveUserData(userData);

    return userData;
  })
  .catch((e) => Promise.reject(e));

const verifyEmail = (token) => axios.post(`/api/verify-email/${token}`)
  .then(() => {
    if (me()) {
      return check();
    }

    return null;
  })
  .catch((e) => Promise.reject(e));

const resendVerifyEmail = (values) => axios.post('/api/resend-verify-email', values)
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const updatePassword = (values) => axios.post('/api/update-password', values)
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const invitationData = (token) => axios.get('/api/invitation-data', {
  params: {
    token,
  },
}).then((response) => response)
  .catch((e) => Promise.reject(e));

const updateCompany = (values) => axios.post('/api/update-company', values)
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const auth = {
  saveUserData,
  preempt,
  register,
  login,
  forgotPassword,
  resetPassword,
  logout,
  check,
  me,
  team,
  hasDemo,
  updateProfile,
  verifyEmail,
  resendVerifyEmail,
  updatePassword,
  invitationData,
  getCompanyName,
  updateCompany,
};

export default auth;
