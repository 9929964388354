/* eslint-disable no-unused-vars */
import React from 'react';
import TaxonomyTable from './components/Table';

const TaxonomyContainer = () => (
  <div className="mx-6 2xl:mx-auto 2xl:max-w-[1200px]">
    <TaxonomyTable className="mt-4" />
  </div>
);

export default TaxonomyContainer;
