import { useState } from 'react';

export default function useLabels(initialState = []) {
  const [labels, setLabels] = useState(initialState);

  const addLabel = ({ id, name, color }) => {
    const alreadyExisting = labels.find((item) => item.id === id
        && item.name === name && item.color === color);
    if (!alreadyExisting) {
      setLabels([
        ...labels,
        {
          id, name, color,
        },
      ]);
    }
  };

  const removeLabel = (id) => {
    setLabels(labels.filter((field) => field.id !== id));
  };

  const removeAllLabels = () => {
    setLabels([]);
  };

  return {
    labels,
    addLabel,
    removeLabel,
    removeAllLabels,
  };
}
