import React, {
  useEffect, useState, useCallback, useRef,
} from 'react';
import {
  CheckCircleOutlined,
  ThunderboltOutlined,
  CompassOutlined,
  DislikeOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import {
  Modal, Tabs, Button, Card, Empty, Spin, Popover,
} from 'antd';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { connect } from 'react-redux';
import axios from 'axios';
import undoInsightAction from '@/store/actions/undoInsight';
import { insightsEnpointPick } from '@/api';
import InsightAnatomyCard from '../InsightAnatomyCard';
import ConfettiButton from '../ConfettiButton';
import { getInsightData } from '../../../transformers/insights';
import { displayOrCatchMessage } from '../../../helpers';
import reloadableStartAction from '../../../store/actions/reloadableStart';
import markInsightAsResolvedAction from '../../../store/actions/markInsightAsResolved';
import markInsightAsNotHelpfulAction from '../../../store/actions/markInsightAsNotHelpful';
import { HIGHLIGHTED_INSIGHTS_SECTION_COMPONENT } from '../../../store/reducers/insight';

const { TabPane } = Tabs;

const BUTTON_TEXT = {
  RESOLVED: 'Mark as Resolved',
  NOT_USEFUL: 'Mark as Unuseful',
};
const FOOTER_MESSAGE = {
  RESOLVED: 'Resolved',
  NOT_USEFUL: 'Not Useful',
};
const TAB_NAME = {
  RECOMMENDATION: 'Recommendation',
  INSIGHT_ANATOMY: 'Insight Anatomy',
};

const insightListItemTitle = (item) => (
  <>
    <span className="timeline-item__modal-title">
      <>{item.text}</>
    </span>
  </>
);

const recommendationText = (item) => (
  <div className="recommendedText">
    <ul>
      <li>
        <span className="title">
          <span className="marker">1</span>
          <span>{item.recommended_step1}</span>
        </span>
        <span className="description">
          <span className="description--content">
            {item.recommended_step1_description}
          </span>
        </span>
      </li>
      <li>
        <span className="title">
          <span className="marker">2</span>
          <span>{item.recommended_step2}</span>
        </span>
        <span className="description">
          <span className="description--content">
            {item.recommended_step2_description}
          </span>
        </span>
      </li>
      <li>
        <span className="title">Bonus</span>
        <span className="description last">{item.recommended_step3}</span>
      </li>
    </ul>
  </div>
);

const messages = (insightItem, isColleague) => {
  if (!insightItem || insightItem.messages.length === 0) {
    return (
      <Card>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Card>
    );
  }

  return insightItem.messages.map((message) => (
    <InsightAnatomyCard
      {...message}
      insight={insightItem}
      id={message.key}
      isColleague={isColleague}
    />
  ));
};

const showAnatomyInsightTab = (item) => item.hasMessages;

const showRecommendationTab = (item) => item.hasRecommendation;

const insightListItemTabs = (item, insight, isLoading, isColleague) => (
  <>
    <Tabs>
      {showAnatomyInsightTab(item) && (
        <TabPane
          tab={(
            <span>
              <CompassOutlined />
              {TAB_NAME.INSIGHT_ANATOMY}
            </span>
          )}
          key="1"
        >
          {isLoading ? (
            <div className="insightListItemTabsPreloader">
              <Spin size="large" />
            </div>
          ) : (
            messages(insight, isColleague)
          )}
        </TabPane>
      )}
      {showRecommendationTab(item) && (
        <TabPane
          tab={(
            <span>
              <ThunderboltOutlined />
              {TAB_NAME.RECOMMENDATION}
            </span>
          )}
          key="2"
        >
          {recommendationText(item)}
        </TabPane>
      )}
    </Tabs>
  </>
);

const InsightModal = ({
  item,
  visibility,
  handleCancel,
  loadList,
  children,
  afterClose,
  reloadHighlightedInsights,
  markInsightAsResolved,
  markInsightAsNotHelpful,
  undoInsight,
  isColleague,
}) => {
  const [isResolved, setIsResolved] = useState(item.marked_as_helpful === true);
  const [isNotUseful, setIsNotUseful] = useState(
    item.marked_as_helpful === false,
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isMarkAsResolvedLoading, setIsMarkAsResolvedLoading] = useState(false);
  const [isMarkAsNotUsefulLoading, setMarkAsNotUsefulLoading] = useState(false);
  const [insightItem, setInsightItem] = useState(null);
  const handleMarkResolved = useCallback(() => {
    setIsMarkAsResolvedLoading(true);
    insightsEnpointPick(isColleague)
      .mark('positive', item.key)
      .then(() => {
        setIsMarkAsResolvedLoading(false);
        setIsResolved(true);
        if (loadList) {
          loadList();
        }
        reloadHighlightedInsights();
        markInsightAsResolved(item);
      })
      .catch((e) => {
        displayOrCatchMessage(e);
        setIsMarkAsResolvedLoading(false);
      });
  }, [item, loadList, isColleague]);
  const handleNotUseful = useCallback(() => {
    setMarkAsNotUsefulLoading(true);
    insightsEnpointPick(isColleague)
      .mark('negative', item.key)
      .then(() => {
        setMarkAsNotUsefulLoading(false);
        setIsNotUseful(true);
        if (loadList) {
          loadList();
        }
        reloadHighlightedInsights();
        markInsightAsNotHelpful(item);
      })
      .catch((e) => {
        displayOrCatchMessage(e);
        setMarkAsNotUsefulLoading(false);
      });
  }, [item, loadList, isColleague]);
  const cancelTokenRef = useRef(null);

  useEffect(() => {
    if (visibility) {
      setIsLoading(true);
      if (cancelTokenRef.current) {
        cancelTokenRef.current.cancel();
      }
      cancelTokenRef.current = axios.CancelToken.source();
      insightsEnpointPick(isColleague)
        .show(item.key, cancelTokenRef.current.token)
        .then((response) => {
          setIsLoading(false);
          setInsightItem(getInsightData(response.data.data));
        })
        .catch((e) => {
          if (!axios.isCancel(e)) {
            displayOrCatchMessage(e);
            setIsLoading(false);
          }
        });
    }
  }, [visibility, item, isColleague]);

  useEffect(() => {
    setIsResolved(item.marked_as_helpful === true);
    setIsNotUseful(item.marked_as_helpful === false);
  }, [item]);

  const handleUndoAction = async () => {
    await insightsEnpointPick(isColleague).undo(item.key);

    reloadHighlightedInsights();
    setIsResolved(false);
    setIsNotUseful(false);
    undoInsight(item);
  };

  const popoverContent = (
    <div aria-hidden="true" onClick={handleUndoAction} className="popoverUndo">
      <UndoOutlined />
      <span>Undo</span>
    </div>
  );

  const itemMarked = () => (
    <div className="actionOwner">
      {`by ${item?.marked_as_helpful_by?.name}  ${formatDistanceToNow(
        new Date(item?.marked_as_helpful_at),
        { addSuffix: true },
      )}`}
    </div>
  );

  return (
    <Modal
      width={700}
      wrapClassName={`modalInsightClientFeedback isResolved-${isResolved} isNotUseful-${isNotUseful} headerColor--${item.background_color}`}
      title={insightListItemTitle(item)}
      visible={visibility}
      onCancel={handleCancel}
      destroyOnClose
      afterClose={afterClose}
      footer={null}
    >
      <div className="insightModalActions">
        {!isResolved && !isNotUseful && (
          <div>
            {item.can_be_resolved && (
              <ConfettiButton
                type="primary"
                onClick={handleMarkResolved}
                loading={!!isMarkAsResolvedLoading}
                disabled={!!isMarkAsNotUsefulLoading}
              >
                <CheckCircleOutlined />
                {BUTTON_TEXT.RESOLVED}
              </ConfettiButton>
            )}
            {item.can_be_unuseful && (
              <Button
                onClick={handleNotUseful}
                loading={!!isMarkAsNotUsefulLoading}
                disabled={!!isMarkAsResolvedLoading}
                className="dislikeButton"
              >
                <DislikeOutlined />
                {BUTTON_TEXT.NOT_USEFUL}
              </Button>
            )}
          </div>
        )}
        {isResolved && (
          <div className="messageWrapper">
            <div className="messageResolved">
              <CheckCircleOutlined />
              <Popover
                content={popoverContent}
                placement="bottom"
                overlayClassName="popoverStyle"
              >
                {FOOTER_MESSAGE.RESOLVED}
              </Popover>
            </div>
            {item.marked_as_helpful_by && itemMarked()}
          </div>
        )}
        {isNotUseful && (
          <div className="messageWrapper">
            <div className="messageUnuseful">
              <DislikeOutlined />
              <Popover
                content={popoverContent}
                placement="bottom"
                overlayClassName="popoverStyle"
              >
                {FOOTER_MESSAGE.NOT_USEFUL}
              </Popover>
            </div>
            {item.marked_as_helpful_by && itemMarked()}
          </div>
        )}
      </div>
      {insightListItemTabs(item, insightItem, isLoading, isColleague)}
      {children}
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  reloadHighlightedInsights: () => dispatch(reloadableStartAction(HIGHLIGHTED_INSIGHTS_SECTION_COMPONENT)),
  markInsightAsResolved: (item) => dispatch(markInsightAsResolvedAction(item)),
  markInsightAsNotHelpful: (item) => dispatch(markInsightAsNotHelpfulAction(item)),
  undoInsight: (item) => dispatch(undoInsightAction(item)),
});

export default connect(null, mapDispatchToProps)(InsightModal);
