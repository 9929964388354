import React from 'react';
import {
  AreaChart, Area, Tooltip, ResponsiveContainer, XAxis, YAxis,
} from 'recharts';
import { Spin, Typography } from 'antd';
import moment from 'moment';
import dashboard from '../../../data/dashboard';
import GatheringDataEmpty from '../../GatheringDataEmpty';
import './ClientHappinessChart.less';

const getParsedDate = (date) => moment(date).format('MMM D, YYYY');

const { Text } = Typography;
const renderTooltip = (item) => (
  <div className="tooltip-box">
    <Text className="tooltip-text date" type="secondary">{(item.payload && item.payload.length && item.payload[0].payload.uv) ? getParsedDate(item.payload[0].payload.uv) : getParsedDate(new Date())}</Text>
    <div>
      <span className="tooltip-text ">
        <Text className="percentage">{(item.payload && item.payload.length && item.payload[0].payload.amt) ? `${parseFloat(item.payload[0].payload.amt).toFixed(0)}% ` : '88%'}</Text>
      </span>
    </div>
  </div>
);

const ClientHappinessChart = ({ data, loading, isColleague }) => {
  const shownData = data || dashboard.overallClientHappiness.happinessChart;
  return (
    <div className="happiness-chart">
      <ResponsiveContainer width="100%">
        {
          // eslint-disable-next-line no-nested-ternary
          loading ? (
            <div
              style={{
                justifyContent: 'center',
                display: 'flex',
                paddingTop: '40px',
              }}
            >
              <Spin size="large" />
            </div>
          ) : (
            shownData.length ? (
              <AreaChart data={shownData}>
                <defs>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="30%" stopColor={isColleague ? '#c1d2ef' : '#E7F7F0'} stopOpacity={0.8} />
                    <stop offset="100%" stopColor={isColleague ? '#c1d2ef' : '#E7F7F0'} stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="date" stroke="#C1C1C1" tickSize={4} tick={{ fontSize: 11 }} />
                <YAxis unit="%" stroke="#C1C1C1" tick={{ fontSize: 11 }} />
                <Tooltip active content={renderTooltip} />
                <Area
                  type="monotone"
                  dataKey="pv"
                  stroke={isColleague ? '#597EF7' : '#3CBB89'}
                  fill="url(#colorPv)"
                  dot
                />
              </AreaChart>
            ) : (
              <GatheringDataEmpty />
            )
          )
        }
      </ResponsiveContainer>
    </div>
  );
};

export default ClientHappinessChart;
