import React, { useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import GhostButton from '@/components/GhostButton';
import { conversations, subtopics } from '@/api';
import { displayOrCatchMessage } from '@/helpers';
import useConversationsStore from '../../store/useConversationsStore';
import { ReactComponent as MoveToSubtopicIcon } from './icon-move-to-subtopic.svg';
import FilteredSelect from './FilteredSelect';

const MoveToAnotherSubtopic = ({
  conversation,
  forSubtopicId,
}) => {
  const {
    actions: { updateSubtopic },
  } = useConversationsStore();

  const [loadedTopics, setLoadedTopics] = useState(
    conversation.subtopics ? conversation.subtopics : [],
    [],
  );

  const [visible, setVisible] = useState(false);

  const [moveTopics, setMoveTopics] = useState(
    conversation.subtopics ? conversation.subtopics.map((item) => item.id) : [],
    [],
  );

  const [loading, setLoading] = useState(false);
  const handleUpdateTopic = async () => {
    setLoading(true);
    const updated = await updateSubtopic(conversation.id, moveTopics, forSubtopicId);
    setLoading(false);
    if (updated) {
      setVisible(false);
    }
  };

  const handleCancelUpdate = () => {
    setVisible(false);
  };

  const handleBeginUpdate = () => {
    setLoading(true);
    setVisible(true);

    Promise.all([
      conversations.getConversation(conversation.id).then((response) => {
        setMoveTopics(response.data.subtopics
          ? response.data.subtopics.map((item) => item.id) : []);
      }).catch((e) => displayOrCatchMessage(e)),
      subtopics.getTopicTree().then((res) => {
        setLoadedTopics(res.data.data);
      }).catch((e) => displayOrCatchMessage(e)),
    ]).then(() => {
      setLoading(false);
    });
  };

  return (
    <>
      <GhostButton onClick={handleBeginUpdate} type="primary" className="conversation-actions-menu-button">
        <span className="anticon">
          <MoveToSubtopicIcon />
        </span>
        Move to subtopic
      </GhostButton>
      <Modal
        title="Move to subtopic"
        onOk={handleUpdateTopic}
        onCancel={handleCancelUpdate}
        footer={[
          <Button key="back" onClick={handleCancelUpdate}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleUpdateTopic}>
            Save
          </Button>,
        ]}
        visible={visible}
      >
        {
          loading ? (
            <div style={{ textAlign: 'center' }}>
              <Spin size="large" />
            </div>
          ) : (
            <FilteredSelect
              style={{ width: '100%' }}
              mode="multiple"
              size="large"
              placeholder="Subtopic"
              value={moveTopics}
              onChange={(value) => setMoveTopics(value)}
              loading={loading}
              options={loadedTopics}
            />
          )
        }
      </Modal>
    </>
  );
};

export default MoveToAnotherSubtopic;
