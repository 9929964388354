/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { FEEDBACK_TYPE_ACTION, FEEDBACK_TYPE_INFO, hasBgColor } from '../constants';

const getBgColorClassName = (bgColor) => (hasBgColor(bgColor) ? `InsightDescription--${bgColor}` : '');
const getFeedbackTypeClassName = (feedbackType) => (feedbackType !== FEEDBACK_TYPE_INFO ? `InsightDescription--${feedbackType}` : '');

const InsightDescription = ({
  id, text, bgColor, feedbackType, className, style, onClick,
}) => {
  const history = useHistory();
  const handleClick = useCallback((...params) => {
    if (feedbackType === FEEDBACK_TYPE_ACTION) {
      if (onClick) {
        onClick(...params);
      } else {
        history.push(`/insights/${id}`);
      }
    }
  }, [feedbackType, onClick]);

  return (
    <div
      className={`InsightDescription ${getBgColorClassName(bgColor)} ${getFeedbackTypeClassName(feedbackType)} ${className ?? ''}`}
      style={style}
      onClick={handleClick}
    >
      <h4 className="InsightDescription__Text">{text}</h4>
      {hasBgColor(bgColor) && <RightOutlined style={{ paddingLeft: '10px' }} />}
    </div>
  );
};

export default InsightDescription;
