import React, { useEffect, useState } from 'react';
import {
  Button, Input, Tabs,
} from 'antd';
import debounce from 'lodash/debounce';
import {
  UserOutlined, UsergroupAddOutlined, UserAddOutlined,
} from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import {
  Link, useRouteMatch,
} from 'react-router-dom';
import Layout from '@/components/Layout';
import ColleagueTable from '@/components/colleagues/ColleagueTable';
import { colleagues } from '@/api';
import { displayOrCatchMessage } from '@/helpers';
import ColleagueAddModal from '@/components/colleagues/ColleagueAddModal';
import {
  getClientData,
} from '@/transformers/clients';
import config from '@/config';

const { TabPane } = Tabs;
export const load = (setLoadedClients, setLoading, setPager) => (
  name, page, needAttention,
) => {
  setLoading(true);
  colleagues.index({
    name,
    need_attention: needAttention ? 1 : undefined,
  }, page, needAttention ? 'score,-id' : undefined).then((response) => {
    setLoadedClients(response.data.data.map(getClientData));
    setPager(response.data.pager);
    setLoading(false);
  }).catch((e) => {
    displayOrCatchMessage(e);
    setLoading(false);
  });
};

const onBeginAdd = (setIsAddVisible) => () => {
  setIsAddVisible(true);
};

const Colleagues = () => {
  const [loadedClients, setLoadedClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pager, setPager] = useState(null);
  const [needAttentionCount, setNeedAttentionCount] = useState(null);
  const [allCount, setAllCount] = useState(null);
  const loadClientList = debounce(load(setLoadedClients, setLoading, setPager), 300);
  const [isAddVisible, setIsAddVisible] = useState(false);
  const [nameFilter, setNameFilter] = useState();
  const { path } = useRouteMatch();
  const needAttention = path.endsWith('need_attention');
  const loadClientFilteredList = (dataPage) => loadClientList(
    nameFilter, dataPage, needAttention,
  );

  useEffect(() => {
    loadClientFilteredList(1);
  }, [nameFilter, needAttention]);

  useEffect(() => {
    colleagues.countNeedAttention().then((value) => {
      setNeedAttentionCount(value);
    }).catch((e) => {
      displayOrCatchMessage(e);
    });
    colleagues.countAll().then((value) => {
      setAllCount(value);
    }).catch((e) => {
      displayOrCatchMessage(e);
    });
  }, []);

  const handleChange = (pagination, filters, sorter, { action }) => {
    if (action === 'paginate') {
      setLoading(true);
      loadClientFilteredList(pagination.current);
    }
  };

  return (
    <Layout>
      <Helmet>
        <title>ClientZen - Team Members</title>
      </Helmet>
      <div className="team-side">
        <div className="datatable-header mb-lg">
          <h4 className="page-header">
            <UsergroupAddOutlined />
            {` Team Members${allCount !== null ? ` (${allCount})` : ''}`}
          </h4>
          <Button onClick={onBeginAdd(setIsAddVisible)} className="btn-only-icon-mobile" type="primary">
            <UserAddOutlined />
            {' '}
            New Team Member
          </Button>
        </div>
        <div className="datatable-header mx-md clients-tabs">
          <Tabs defaultActiveKey={needAttention ? '2' : '1'}>
            <TabPane tab={<Link to="/team-members">All</Link>} key="1" />
            <TabPane tab={<Link to="/team-members/need_attention">{`Need attention${needAttentionCount !== null ? ` (${needAttentionCount})` : ''}`}</Link>} key="2" />
          </Tabs>
          <div className="datatable-actions">
            <Input onChange={(event) => setNameFilter(event.target.value)} value={nameFilter} placeholder="Search Team Member" className="ml-sm" prefix={<UserOutlined />} />
            <ColleagueAddModal
              loadList={loadClientFilteredList}
              setVisible={setIsAddVisible}
              visible={isAddVisible}
            />
          </div>
        </div>
        <ColleagueTable
          loadList={loadClientFilteredList}
          clients={loadedClients}
          loading={loading}
          onChange={handleChange}
          pagination={{
            pageSize: config.pageSize,
            total: pager?.total ?? 0,
            current: pager?.current_page ?? 1,
            showSizeChanger: false,
          }}
        />
      </div>
    </Layout>
  );
};

export default Colleagues;
