import React, {
  useState, useCallback, useRef, useEffect,
} from 'react';
import { Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import axios from 'axios';
import { insightsEnpointPick } from '@/api';
import InsightModal from './InsightModal';
import { getInsightData } from '../../../transformers/insights';
import { displayOrCatchMessage } from '../../../helpers';

const getPreviousKey = (onClientList) => (onClientList ? 'previous_per_client_insight_id' : 'previous_insight_id');

const getNextKey = (onClientList) => (onClientList ? 'next_per_client_insight_id' : 'next_insight_id');

const InsightModalWithNavigation = ({
  item, onClientList, isColleague, ...props
}) => {
  const previousKey = getPreviousKey(onClientList);
  const nextKey = getNextKey(onClientList);

  const [currentItem, setCurrentItem] = useState(item);
  const [loading, setLoading] = useState({
    [previousKey]: false,
    [nextKey]: false,
  });
  const cancelTokenRef = useRef(null);
  const goTo = useCallback((key) => () => {
    if (currentItem[key]) {
      setLoading({
        ...loading,
        [key]: true,
      });
      cancelTokenRef.current = axios.CancelToken.source();
      insightsEnpointPick(isColleague)
        .show(currentItem[key], cancelTokenRef.current.token).then((response) => {
          setLoading({
            ...loading,
            [key]: false,
          });
          setCurrentItem(getInsightData(response.data.data));
        }).catch((e) => {
          if (!axios.isCancel(e)) {
            setLoading({
              ...loading,
              [key]: false,
            });
            displayOrCatchMessage(e);
          }
        });
    }
  }, [currentItem, isColleague]);
  const reset = useCallback(() => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel();
    }
    setCurrentItem(item);
    setLoading({
      [previousKey]: false,
      [nextKey]: false,
    });
  }, []);

  useEffect(() => {
    setCurrentItem(item);
  }, [item]);

  return (
    <InsightModal item={currentItem} {...props} afterClose={reset} isColleague={isColleague}>
      {
        (currentItem[previousKey] || currentItem[nextKey]) && (
          <div className="modalInsightNavigation">
            <Button
              onClick={goTo(previousKey)}
              disabled={!currentItem[previousKey] || loading[nextKey]}
              loading={loading[previousKey]}
              className="modalInsightNavigationPrevious"
            >
              <LeftOutlined />
            </Button>
            <Button
              onClick={goTo(nextKey)}
              disabled={!currentItem[nextKey] || loading[previousKey]}
              loading={loading[nextKey]}
              className="modalInsightNavigationNext"
            >
              <RightOutlined />
            </Button>
          </div>
        )
      }
    </InsightModal>
  );
};

export default InsightModalWithNavigation;
