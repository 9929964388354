import React, { useState } from 'react';
import { Button } from 'antd';
import { Redirect } from 'react-router-dom';
import { insights } from '@/api';
import { displayOrCatchMessage, loadListIfAvailable } from '../../helpers';

const onDone = (loadList, onClick, setRedirect, setLoading, insight) => (...params) => {
  setLoading(true);
  insights.done(insight.key).then(() => {
    loadList();
    setRedirect(true);
    setLoading(false);
  }).catch((e) => {
    displayOrCatchMessage(e);
    setLoading(false);
  });

  if (onClick) {
    onClick(...params);
  }
};

const setShouldRedirect = (redirectsToInsights, setValue) => (value) => {
  if (redirectsToInsights) {
    setValue(value);
  }
};

const InsightDone = ({
  loadList, style, onClick, children, redirectsToInsights, insight, ...props
}) => {
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const onPreparedClick = onDone(
    loadListIfAvailable(loadList),
    onClick,
    setShouldRedirect(redirectsToInsights, setRedirect),
    setLoading,
    insight,
  );

  return (
    <>
      {
        redirect ? (
          <Redirect push to="/insights" />
        ) : null
      }
      <Button
        type="primary"
        {...props}
        onClick={onPreparedClick}
        loading={loading}
      >
        {
          children || (
            <>
              Done
            </>
          )
        }
      </Button>
    </>
  );
};

export default InsightDone;
