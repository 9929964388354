import { combineReducers } from 'redux';
import user from './user';
import subscription from './subscription';
import firstClients from './firstClients';
import firstColleagues from './firstColleagues';
import recentInteractions from './recentInteractions';
import preparedRecentInteractions from './preparedRecentInteractions';
import teamMembers from './teamMembers';
import disconnectedPlatforms from './disconnectedPlatforms';
import reloadable from './reloadable';
import insight from './insight';
import { topicStepsFormReducer } from '../../components/TopicStepsForm/reducer';
import { topicFormReducer } from '../../components/TopicForm/reducer';
import { topicDashboardReducer } from './topic';

export default combineReducers({
  user,
  subscription,
  firstClients,
  firstColleagues,
  recentInteractions,
  preparedRecentInteractions,
  teamMembers,
  disconnectedPlatforms,
  reloadable,
  insight,
  topicSteps: topicStepsFormReducer,
  topicForm: topicFormReducer,
  topic: topicDashboardReducer,
});
