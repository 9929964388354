import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '@/components/Layout';
import { SettingsPage } from '@/components/settings/SettingsPage';

const Settings = () => (
  <Layout>
    <Helmet>
      <title>ClientZen - Settings</title>
    </Helmet>
    <SettingsPage />
  </Layout>
);

export default Settings;
