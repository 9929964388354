import { Button, Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import { Helmet } from 'react-helmet';
import TrackedLink from './TrackedLink';
import { mixpanelEvents } from '../mixpanel';
import LogoBeta from './svgs/LogoBeta';
import ErrorBackground from './svgs/ErrorBackground';

const ErrorContainer = ({
  title,
  message,
}) => (
  <div className="full-height-view error-container">
    <div className="content">
      <Helmet>
        <title>
          ClientZen -
          {' '}
          {title}
        </title>
      </Helmet>

      <Row>
        <Col span={24}>
          <div className="logo">
            <TrackedLink to="/" event={mixpanelEvents.LOGO_CLICKED}>
              <LogoBeta />
            </TrackedLink>
          </div>
        </Col>
        <Col span={24}>
          <h3>{title}</h3>
        </Col>
        <Col span={24}>
          <h5>{message}</h5>
        </Col>
        <Col span={24}>
          <Link to="/">
            <Button size="middle">Go to Homepage</Button>
          </Link>
        </Col>
      </Row>

    </div>
    <div className="background">
      <ErrorBackground />
    </div>
  </div>
);

export default ErrorContainer;
