import React from 'react';
import { Route } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';

const ErrorBoundaryRoute = ({ children, ...props }) => (
  <ErrorBoundary>
    <Route {...props}>{children}</Route>
  </ErrorBoundary>
);

export default ErrorBoundaryRoute;
