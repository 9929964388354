/* eslint-disable no-unused-vars */
import React from 'react';
import { Empty, ConfigProvider } from 'antd';
import LoadingTable from '../LoadingTable';
import ScoreAvatar from '../clients/ScoreAvatar';
import TimelineItem from './InsightsTimelineItem';
import config from '../../config';

const DOT_COLORS = {
  red: '#F5222D',
  yellow: 'orange',
  green: '#0BAA6B',
  grey: '#E8E8E8',
};
const dotColors = Object.keys(DOT_COLORS);

const BG_COLORS = {
  red: '#FFEBE9',
  yellow: '#FEF4DC',
  green: '#E7F7F0',
  transparent: 'none',
};
const bgColors = Object.keys(BG_COLORS);

const columns = (loadList, hasClientAvatar, isColleague) => [
  ...(hasClientAvatar ? [{
    title: 'Avatar',
    dataIndex: 'avatar',
    key: 'avatar',
    width: 46,
    render: (image, insight) => <ScoreAvatar client={insight.client} disableBreakpoints />,
  }] : []),
  {
    title: 'TimelineItem',
    dataIndex: 'status',
    key: 'status',
    className: 'TableInsightItem',
    render: (text, insight) => {
      const item = {
        id: insight.key,
        text: insight.text,
        created: insight.created,
        background_color: insight.background_color,
        foreground_color: insight.foreground_color,
        feedback_type: insight.feedback_type,
        shown_on_dashboard: insight.shown_on_dashboard,
        marked_as_resolved: insight.marked_as_helpful === true,
        marked_as_not_useful: insight.marked_as_helpful === false,
        dotColor: dotColors[insight.foreground_color],
        bgColor: bgColors[insight.background_color],
        item: insight,
      };

      return (
        <TimelineItem {...item} loadList={loadList} isColleague={isColleague} />
      );
    },
  },
];

const InsightTable = ({
  insights, loadList, hasClientAvatar, total, current, loading, isColleague, ...props
}) => (
  <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
    <LoadingTable
      showHeader={false}
      loading={loading}
      resourceName="insights"
      className="InsightsTable margin-all-xl"
      columns={columns(loadList, hasClientAvatar, isColleague)}
      dataSource={insights}
      pagination={{
        pageSize: config.pageSize,
        total,
        current,
        showSizeChanger: false,
      }}
      {...props}
    />
  </ConfigProvider>
);
export default InsightTable;
