import React, { useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
  Button, Card, Col, Row,
} from 'antd';
import { connect } from 'react-redux';
import { billing } from '@/api';
import ValidatedForm from '../forms/ValidatedForm';
import { displayOrCatchMessage } from '../../helpers';
import BillingForm from './BillingForm';
import fetchSubscription from '../../helpers/fetchSubscription';
import CurrentBillingPlan from '../billingPlans/CurrentBillingPlan';
import { getTotal, getName } from '../../helpers/subscriptions';

const CheckoutForm = ({
  setupIntentSecret, setShouldRedirect, choosenPlan, fetchUserSubscription,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    const { setupIntent, error } = await stripe.confirmCardSetup(
      setupIntentSecret,
      {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: values.name,
            address: {
              city: values.city,
              country: values.country,
              line1: values.address_line_1,
              line2: values.address_line_2,
              postal_code: values.postal_code,
            },
          },
        },
      },
    );

    if (error) {
      displayOrCatchMessage(error);
      setLoading(false);
    } else {
      billing.subscribe(setupIntent.payment_method, choosenPlan).then(() => {
        setShouldRedirect(`/subscribed/${choosenPlan.replaceAll('_', '-')}`);
        fetchUserSubscription();
      }).catch((e) => {
        displayOrCatchMessage(e);
        setLoading(false);
      });
    }
  };

  const options = {
    hidePostalCode: true,
    style: {
      base: {
        color: 'rgba(0, 0, 0, 0.85)',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#4d4d4d',
        },
      },
      invalid: {
        color: '#ff4d4f',
        iconColor: '#ff4d4f',
      },
    },
  };

  return (
    <ValidatedForm
      onFinish={handleSubmit}
      layout="vertical"
      requiredMark={false}
    >
      <Row gutter={36} className="billing-container">
        <Col span={14}>
          <Card className="card-padding-lg container-card-medium" style={{ marginBottom: 15 }}>
            <BillingForm />
          </Card>
          <Card className="card-padding-lg container-card-medium" style={{ marginBottom: 15 }}>
            <Row gutter={16} style={{ marginBottom: 15 }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <h3 style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Payment info</h3>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginBottom: 15 }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <CardElement options={options} />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={10}>
          <CurrentBillingPlan plan={choosenPlan} />
          <Row gutter={16} style={{ marginBottom: 15 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <h3>
                Total due today: $
                {getTotal(choosenPlan)}
                .00
              </h3>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Button
                loading={loading}
                size="large"
                type="primary"
                htmlType="submit"
                disabled={!stripe}
              >
                Upgrade to
                {' '}
                {getName(choosenPlan)}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </ValidatedForm>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchUserSubscription: () => fetchSubscription(dispatch),
});

export default connect(null, mapDispatchToProps)(CheckoutForm);
