export default (state = {}, action) => {
  switch (action.type) {
    case 'RELOADABLE_START': return {
      ...state,
      [action.component]: true,
    };
    case 'RELOADABLE_END': return {
      ...state,
      [action.component]: false,
    };
    default:
      return state;
  }
};
