import React from 'react';
import { Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import GhostButton from '@/components/GhostButton';

export const getColumns = (onDelete) => [
  {
    title: 'Annotation Name',
    dataIndex: 'label',
    key: 'label',
    align: 'left',
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    align: 'left',
    render: (value) => moment(value).format('DD MMM YYYY'),
  },
  {
    title: '',
    dataIndex: 'actions',
    align: 'center',
    width: 100,
    fixed: 'right',
    render: (_, { id }) => (
      <div className="annotations-table__action-icons">
        <Popconfirm
          placement="topRight"
          title="Are you sure you want to delete release?"
          okText="Delete"
          cancelText="Cancel"
          okType="primary"
          cancelType="default"
          okButtonProps={{
            danger: true,
          }}
          onConfirm={() => onDelete(id)}
        >
          <GhostButton type="primary">
            <DeleteOutlined />
          </GhostButton>
        </Popconfirm>
      </div>
    ),
  },
];
