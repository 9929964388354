import React from 'react';
import { Steps as AntdSteps } from 'antd';
import auth from '@/api/auth';

const { Step } = AntdSteps;

const Steps = (props) => {
  const team = auth.team();

  return (
    <AntdSteps className="onboarding-steps" progressDot {...props}>
      <Step title="Connect Platforms" />
      {
        team.owner ? (
          <>
            <Step title="Add Clients" />
            <Step title="Invite Team" />
          </>
        ) : null
      }
      <Step title="Go to Dashboard" />
    </AntdSteps>
  );
};

export default Steps;
