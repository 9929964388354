/* eslint-disable no-restricted-properties */
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Button, Progress } from 'antd';
import { Clock } from 'lucide-react';
import mixpanel, { mixpanelEvents } from '@/mixpanel';
import { auth } from '@/api';
import { useHistory } from 'react-router-dom';
import confetti from 'canvas-confetti';
import { usePlatforms } from '../hooks/usePlatforms';
import { PlatformContext } from '../context';
import InsightsContainer from './insights';
import FeedbackForm from './FeedbackForm';
import useCsvImport from '../hooks/useCsvImport';

export function ProcessingInsights() {
  const history = useHistory();
  const interval = useRef(null);
  const { platform, file, mappings } = useContext(PlatformContext);
  const [progress, setProgress] = useState(0);
  const [done, setDone] = useState(false);

  const { data } = usePlatforms();

  const onComplete = () => {
    clearInterval(interval.current);
    setProgress(100);
    setDone(true);

    localStorage.setItem('teams', JSON.stringify([{
      ...auth.team(),
      dashboard_ready: true,
    }]));
  };
  const { startCsvImport } = useCsvImport(file, mappings ?? {}, onComplete, 'chat_feedback_upload');

  useEffect(() => {
    if (platform.toLowerCase() !== 'csv') return;
    startCsvImport();
  }, []);

  useEffect(() => {
    if (!data) return;

    const readyPlatforms = data.filter((p) => p.status === 'ready');
    const isReady = readyPlatforms.find((p) => p.type === platform.toLowerCase() && p.status === 'ready');

    if (!isReady) {
      return;
    }

    clearInterval(interval.current);
    setProgress(100);
    setDone(true);

    localStorage.setItem('teams', JSON.stringify([{
      ...auth.team(),
      dashboard_ready: true,
    }]));
  }, [data]);

  useEffect(() => {
    interval.current = setInterval(() => {
      setProgress((currentProgress) => {
        const remaining = 100 - currentProgress;
        if (currentProgress < 100) {
          return currentProgress + (0.15 * Math.pow(1 - Math.sqrt(remaining), 2));
        }
        return 0;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    mixpanel.track(mixpanelEvents.STARTED_ANALYSIS);
  }, []);

  const handleRedirect = () => {
    confetti({
      particleCount: 150,
      spread: 300,
      origin: { y: 0.25 },
    });

    setTimeout(() => {
      history.push('/');
    }, 1000);
  };

  return (
    <InsightsContainer>
      <div className="flex flex-col items-center w-full text-center">
        <InsightsContainer.Title value={progress === 100 ? 'Everything is set!' : undefined} />

        <Progress
          percent={progress}
          showInfo={false}
          className="!mt-8 w-full"
          trailColor="#DFE8F1"
          strokeColor={{
            '0%': '#0CAA6B',
            '100%': '#1E92D6',
          }}
        />

        <InsightsContainer.Description>
          {progress >= 0 && progress < 100 ? (
            <>
              <Clock className="h-4 w-4" />
              {progress > 0 && progress < 40 ? (
                <span>Gathering conversations</span>
              ) : null}
              {progress >= 40 && progress < 70 ? (
                <span>Discovering insights</span>
              ) : null}
              {progress >= 70 && progress < 100 ? (
                <span>Generating categories</span>
              ) : null}
            </>
          ) : null}
          {done && progress === 100 ? (
            <Button onClick={() => handleRedirect()} type="primary" size="large" className="mt-2">
              Go to your dashboard
            </Button>
          ) : null}
        </InsightsContainer.Description>
      </div>
      <FeedbackForm />
    </InsightsContainer>
  );
}
