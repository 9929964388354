const config = {
  apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
  nprogress: {
    easing: 'ease',
    speed: 100,
    trickleSpeed: 100,
    minimum: 0.1,
    trickle: true,
    showSpinner: true,
  },
  sentryDSN: import.meta.env.VITE_SENTRY_DSN,
  google: {
    clientId: import.meta.env.VITE_GOOGLE_CLIENTID,
    scopes: [
      'https://www.googleapis.com/auth/gmail.readonly',
    ],
  },
  googleWorkplaceUrl: 'https://workspace.google.com/marketplace/app/clientzen_for_gmail/84652456010',
  mixpanel: {
    token: import.meta.env.VITE_MIXPANEL_TOKEN,
    config: {
      api_host: 'https://api-eu.mixpanel.com',
    },
  },
  logrocket: {
    appID: import.meta.env.VITE_LOGROCKET_APPID,
  },
  happinesScoreTreshold: {
    GOOD: 80,
    MEDIUM: 60,
    LOW: 40,
  },
  pageSize: 15,
  annotationMaxLength: 15,
};

export default config;
