import React, { useEffect, useRef, useState } from 'react';
import 'cropperjs/dist/cropper.css';
import BaseCropper from 'cropperjs';
import { connect } from 'react-redux';
import { Button } from 'antd';

const onFinishClick = (cropper, onFinish) => () => {
  onFinish(cropper.getCroppedCanvas({
    minWidth: 500,
    minHeight: 500,
    maxWidth: 500,
    maxHeight: 500,
    fillColor: 'transparent',
    imageSmoothingEnabled: true,
    imageSmoothingQuality: 'high',
  }).toDataURL());
};

const Cropper = ({
  imageUrl, user, onChangePhoto, onFinish, loading,
}) => {
  const imageElement = useRef();
  const [cropper, setCropper] = useState(null);

  useEffect(() => {
    const instance = new BaseCropper(imageElement.current, {
      aspectRatio: 1,
      movable: false,
      zoomable: false,
      rotatable: false,
      scalable: false,
    });
    setCropper(instance);

    return function cleanup() {
      instance.destroy();
    };
  }, [imageElement]);

  return (
    <div className="cz-cropper">
      <img
        alt={user.name}
        ref={imageElement}
        src={imageUrl}
      />
      <Button disabled={loading} className="cz-change-photo" type="secondary" onClick={onChangePhoto}>Change Photo</Button>
      <Button loading={loading} className="cz-crop-button" type="primary" onClick={onFinishClick(cropper, onFinish)}>Done</Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(Cropper);
