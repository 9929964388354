import React from 'react';
import { Tabs, Card, Divider } from 'antd';
import { Helmet } from 'react-helmet';
import Layout from '@/components/Layout';
import BasicInfoForm from '@/components/account/BasicInfoForm';
import ChangePicture from '@/components/account/ChangePicture';
import ChangePasswordForm from '@/components/account/ChangePasswordForm';
import ChangeCompanyForm from '@/components/account/ChangeCompanyForm';

const { TabPane } = Tabs;

const Account = () => (
  <Layout>
    <Helmet>
      <title>ClientZen - Account</title>
    </Helmet>
    <Tabs className="card-tabs" style={{ paddingLeft: '24px' }} defaultActiveKey="1">
      <TabPane tab="Basic" key="1">
        <Card className="card-padding-lg container-card-medium">
          <ChangePicture />
          <Divider />
          <BasicInfoForm />
        </Card>
      </TabPane>
      <TabPane tab="Change Password" key="2">
        <Card className="card-padding-lg container-card-medium">
          <ChangePasswordForm />
        </Card>
      </TabPane>
      <TabPane tab="Company" key="3">
        <Card className="card-padding-lg container-card-medium">
          <ChangeCompanyForm />
        </Card>
      </TabPane>
    </Tabs>
  </Layout>
);

export default Account;
