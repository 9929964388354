import React, { useCallback, useState } from 'react';
import {
  Button, Form, Input, Modal,
} from 'antd';
import { members } from '@/api';
import { displayOrCatchMessage } from '../../helpers';
import ValidatedForm from '../forms/ValidatedForm';

const TeamMemberRoleModal = ({
  loadList, setVisible, visible, member, ...props
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const onCancel = useCallback(() => {
    setVisible(false);
  }, []);
  const onOk = useCallback(() => {
    form.submit();
  }, []);
  const onFinish = useCallback(({ role }) => {
    setLoading(true);
    members.update(member)({
      role,
    }).then(() => {
      if (loadList) {
        loadList();
      }
      setLoading(false);
      setVisible(false);
    }).catch((e) => {
      displayOrCatchMessage(e);
    });
  }, [member]);

  return (
    <Modal
      title="Change Team Member Role"
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={onOk}>
          Save
        </Button>,
      ]}
      visible={visible}
      className="team-side"
      {...props}
    >
      <ValidatedForm layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item
          className="mb-sm"
          name="role"
          label="Role"
          initialValue={member ? member.role : ''}
        >
          <Input size="large" />
        </Form.Item>
      </ValidatedForm>
    </Modal>
  );
};

export default TeamMemberRoleModal;
