import * as React from 'react';
import { memo } from 'react';

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <path
      style={{
        fill: '#fff',
      }}
      d="M0 0h40v40H0z"
    />
    <path
      d="M15.652 30.043H0l15.652-18.9Zm18.236 0H18.236a7.826 7.826 0 1 1 15.652 0Zm-15.652-6.784V4.358h15.653Zm-2.584-18.9A7.826 7.826 0 1 1 0 4.36h15.652Z"
      transform="translate(3.056 2.8)"
      style={{
        fill: '#03363d',
      }}
    />
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
