import React, { useEffect } from 'react';
import {
  Empty, List, Progress, Tooltip, message,
} from 'antd';
import { ArrowBigDownDash, ArrowBigUpDash } from 'lucide-react';
import useSubtopics from '@/components/bucket/hooks/useSubtopics';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import { useHistory, useParams } from 'react-router-dom';
import { cn } from '@/helpers/util';

function FilterItem({ item, total }) {
  const history = useHistory();
  const { topicId, groupId, subtopicId } = useParams();
  const barPercent = total > 0 ? (item.count.value / total) * 100 : 0;
  const width = barPercent < 1 ? `${barPercent + 8}%` : `${barPercent}%`;

  const handleClick = () => {
    if (parseInt(subtopicId, 10) !== item.id) history.push(`/topic/overview/${topicId}/${groupId}/${item.id}`);
    else history.push(`/topic/overview/${topicId}/${groupId}`);
  };

  return (
    <List.Item onClick={handleClick} className={cn('cz-saved-filters cursor-pointer hover:bg-[#F6F9FB]', parseInt(subtopicId, 10) === item.id && 'bg-[#F6F9FB]')}>
      <div className="flex flex-col gap-1 min-w-full">
        <div className="flex gap-2">
          <div className="flex items-center justify-between text-[10px] text-[#75899B] self-end gap-1">
            <span>{item.count.value}</span>
            {
              item.count.trend === 'ascending'
                ? <ArrowBigUpDash size={16} color="#EDA2B4" fill="#EDA2B4" />
                : <ArrowBigDownDash size={16} color="#A9BCCF" fill="#A9BCCF" />
            }
          </div>
          <span className="text-sm font-[Gordita-Medium] text-[#202324]">
            {item.name.length >= 36 ? (
              <Tooltip title={item.name} placement="left">
                {item.name.slice(0, 36)}
                ...
              </Tooltip>
            ) : item.name}
          </span>
        </div>
        <Progress className="!text-[0px] [&_.ant-progress-bg]:!h-1.5" style={{ width }} trailColor="#DFE9F1" showInfo={false} />
      </div>
    </List.Item>
  );
}

export default function SavedFilters() {
  const { topicId } = useParams();
  const { defaultDate } = useDefaultDateStore();
  const {
    data: subtopics, isLoading, isError, error,
  } = useSubtopics(topicId, {
    start: defaultDate.start,
    end: defaultDate.end,
  });

  const total = subtopics && subtopics.length > 0
    ? parseInt(subtopics[0].count.value, 10)
    : 0;

  useEffect(() => {
    if (!isError) return;
    message.error(error.message);
  }, [error]);

  return subtopics && subtopics.length ? (
    <div className="py-4 px-4 mt-2 border-0 border-t border-solid border-[#DFE9F1] max-h-[50%] no-scrollbar overflow-y-scroll">
      <List
        className="[&_.ant-list-header]:p-0 [&_.ant-list-header]:!border-0 [&_.ant-list-items]:space-y-5"
        header={(
          <h3 className="m-0 text-sm text-[#75899B] font-[Gordita-Regular]">
            Saved Filters
          </h3>
        )}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No saved filters found"
            />
          ),
        }}
        loading={isLoading}
        dataSource={subtopics}
        renderItem={(item) => <FilterItem item={item} total={total} />}
      />
    </div>
  ) : null;
}
