import moment from 'moment';

export const options = [
  { name: 'Last 7 days', start: moment().subtract(7, 'days').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
  { name: 'Last 30 days', start: moment().subtract(30, 'days').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
  { name: 'Last 90 days', start: moment().subtract(90, 'days').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
  { name: 'Last week', start: moment().subtract(1, 'week').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
  { name: 'Last month', start: moment().subtract(1, 'month').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
  { name: 'Last 3 month', start: moment().subtract(3, 'month').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
  { name: 'Last 6 month', start: moment().subtract(6, 'month').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
  { name: 'Last 12 month', start: moment().subtract(12, 'month').format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') },
];

const presetRanges = {
  'Last 7 days': [moment().subtract(7, 'days'), moment()],
  'Last 30 days': [moment().subtract(30, 'days'), moment()],
  'Last 90 days': [moment().subtract(90, 'days'), moment()],
  'Last week': [moment().subtract(1, 'week'), moment()],
  'Last month': [moment().subtract(1, 'month'), moment()],
  'Last 3 month': [moment().subtract(3, 'month'), moment()],
  'Last 6 month': [moment().subtract(6, 'month'), moment()],
  'Last 12 month': [moment().subtract(12, 'month'), moment()],
};

const getPresetName = (start, end) => {
  const presets = Object.keys(presetRanges);

  for (let i = 0; i < presets.length; i++) {
    const preset = presetRanges[presets[i]];
    if (moment(start).isSame(preset[0], 'day') && moment(end).isSame(preset[1], 'day')) {
      return presets[i];
    }
  }

  return `${start} - ${end}`;
};

export const useSentimentFilterOptions = (initialValue) => ({
  defaultValue: initialValue ? {
    name: getPresetName(initialValue.start, initialValue.end),
    start: initialValue.start,
    end: initialValue.end,
  } : options[1],
  presetRanges,
  options,
});
