import { buildQuery } from '@/helpers/filters';
import axios from './axios';
import auth from './auth';

const countNeedAttention = () => axios.get('/api/count-colleagues-need-attention', {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response.data.data).catch((e) => Promise.reject(e));

const countAll = () => axios.get('/api/count-colleagues-all', {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response.data.data).catch((e) => Promise.reject(e));

const index = (filters, page, sort) => axios.get(buildQuery('/api/colleagues', filters, {
  ...(page ? { page } : undefined),
  ...(sort ? { sort } : undefined),
  team_id: auth.team().id,
})).then((response) => response).catch((e) => Promise.reject(e));

const show = (id, m) => axios.get(`/api/colleagues/${id}`, {
  params: {
    team_id: auth.team().id,
    m,
  },
}).then((response) => response).catch((e) => Promise.reject(e));

const store = (values) => axios.post('/api/colleagues', {
  ...values,
  team_id: auth.team().id,
}).then((response) => response).catch((e) => Promise.reject(e));

const storeBatch = (values) => axios.post('/api/colleagues-batch', {
  ...values,
  team_id: auth.team().id,
}).then((response) => response).catch((e) => Promise.reject(e));

const update = (client) => (values) => axios.put(`/api/colleagues/${client.key}`, {
  ...values,
  team_id: auth.team().id,
}).then((response) => response).catch((e) => Promise.reject(e));

const destroy = (client) => axios.delete(`/api/colleagues/${client.key}`, {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response).catch((e) => Promise.reject(e));

const colleagues = {
  countNeedAttention,
  countAll,
  index,
  show,
  store,
  storeBatch,
  update,
  destroy,
};

export default colleagues;
