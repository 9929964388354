import React, { useCallback } from 'react';
import { Button, Form, Input } from 'antd';
import config from '../../../config';
import rules from '../../../helpers/rules';
import ValidatedForm from '../../../components/forms/ValidatedForm';

const ZendeskConnect = ({
  popupOpened, user, setPopupOpened, updateConnectedPlatforms,
}) => {
  const onConnectZendeskSupport = useCallback(({ subdomain }) => {
    setPopupOpened(true);
    const myWindow = window.open(`${config.apiBaseUrl}/zendesk/auth?token=${user.token}&subdomain=${encodeURIComponent(subdomain)}&&skip-onboarding=1`,
      'logw', 'width=700,height=500');
    const timer = setInterval(() => {
      if (myWindow.closed) {
        clearInterval(timer);
        setPopupOpened(false);
        updateConnectedPlatforms(true);
      }
    }, 1000);
  }, []);

  return (
    <div className="zendesk_connect">
      <ValidatedForm
        layout="vertical"
        requiredMark={false}
        onFinish={onConnectZendeskSupport}
      >
        <Form.Item
          className="mb-sm"
          name="subdomain"
          rules={rules.name('Zendesk Support Domain is required')}
          label="Zendesk Support Domain"
        >
          <Input size="large" placeholder="Zendesk Support Domain" autoComplete="off" />
        </Form.Item>
        <Button htmlType="submit" size="large" type="primary" disabled={popupOpened}>{popupOpened ? 'Connecting...' : 'Connect'}</Button>
      </ValidatedForm>
    </div>
  );
};

export default ZendeskConnect;
