import React, { useCallback } from 'react';
import {
  CheckCircleOutlined,
  DislikeOutlined,
  UndoOutlined,
} from '@ant-design/icons';
import { Popover } from 'antd';
import { useDispatch } from 'react-redux';
import reloadableStartAction from '@/store/actions/reloadableStart';
import markInsightAsResolvedAction from '@/store/actions/markInsightAsResolved';
import markInsightAsNotHelpfulAction from '@/store/actions/markInsightAsNotHelpful';
import undoInsightAction from '@/store/actions/undoInsight';
import { insightsEnpointPick } from '@/api';
import { displayOrCatchMessage } from '../../../helpers';

import { HIGHLIGHTED_INSIGHTS_SECTION_COMPONENT } from '../../../store/reducers/insight';

const BUTTON_TEXT = {
  RESOLVED: 'Mark as Resolved',
  NOT_USEFUL: 'Mark as Unuseful',
};

const TimelineItemStatusContent = ({ isColleague, ...item }) => {
  const dispatch = useDispatch();
  const handleMarkResolved = useCallback(() => {
    insightsEnpointPick(isColleague)
      .mark('positive', item.item.key)
      .then(() => {
        dispatch(reloadableStartAction(HIGHLIGHTED_INSIGHTS_SECTION_COMPONENT));
        dispatch(markInsightAsResolvedAction(item.item));
      })
      .catch((e) => {
        displayOrCatchMessage(e);
      });
  }, [item, isColleague]);
  const handleNotUseful = useCallback(() => {
    insightsEnpointPick(isColleague)
      .mark('negative', item.item.key)
      .then(() => {
        dispatch(reloadableStartAction(HIGHLIGHTED_INSIGHTS_SECTION_COMPONENT));
        dispatch(markInsightAsNotHelpfulAction(item.item));
      })
      .catch((e) => {
        displayOrCatchMessage(e);
      });
  }, [item, isColleague]);

  const handleUndoAction = async () => {
    await insightsEnpointPick(isColleague).undo(item.item.key);
    dispatch(reloadableStartAction(HIGHLIGHTED_INSIGHTS_SECTION_COMPONENT));
    dispatch(undoInsightAction(item.item));
  };
  const popoverContent = (
    <div aria-hidden="true" onClick={handleUndoAction} className="popoverUndo">
      <UndoOutlined />
      <span>Undo</span>
    </div>
  );
  if (item.marked_as_resolved) {
    return (
      <div>
        <Popover
          content={popoverContent}
          placement="bottom"
          overlayClassName="popoverStyle"
        >
          <CheckCircleOutlined className="TimelineItem__StatusIcon" />
          Resolved
        </Popover>
      </div>
    );
  }

  if (item.marked_as_not_useful) {
    return (
      <div>
        <Popover
          content={popoverContent}
          placement="bottom"
          overlayClassName="popoverStyle"
        >
          <DislikeOutlined className="TimelineItem__StatusNotUseful" />
          <span>Unuseful</span>
        </Popover>
      </div>
    );
  }
  if (item.feedback_type !== 'info') {
    return (
      <div className="TimelineItem__action_wrapper">
        <div>
          {item.item && item.item.can_be_resolved && (
            <Popover
              content={BUTTON_TEXT.RESOLVED}
              placement="bottom"
              overlayClassName="popoverStyle"
            >
              <CheckCircleOutlined
                className="TimelineItem__StatusIcon_base"
                onClick={handleMarkResolved}
              />
            </Popover>
          )}
        </div>
        <div>
          {item.item && item.item.can_be_unuseful && (
            <Popover
              content={BUTTON_TEXT.NOT_USEFUL}
              placement="bottom"
              overlayClassName="popoverStyle"
            >
              <DislikeOutlined
                className="TimelineItem__StatusNotUseful_base"
                onClick={handleNotUseful}
              />
            </Popover>
          )}
        </div>
      </div>
    );
  }
  return '';
};

export default TimelineItemStatusContent;
