import React from 'react';
import { Avatar } from 'antd';
import { connect } from 'react-redux';

const UserAvatar = ({ user, dispatch, ...props }) => (
  <Avatar {...props} src={user.profile_picture} className="!bg-[#5EA3DD]">
    { user.name ? user.name.trim().split(' ').map((n) => n[0].toUpperCase()).join('') : '' }
  </Avatar>
);

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(UserAvatar);
