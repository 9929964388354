import React from 'react';
import * as Sentry from '@sentry/react';
import Error from '../containers/Error';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(e) {
    Sentry.captureException(e);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <Error code={500} />;
    }

    return children;
  }
}

export default ErrorBoundary;
