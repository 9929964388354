import React, { useState } from 'react';
import { Tag } from 'antd';
import HighlightedText from '../HighlightedText';

const TopicPreviewHighlightedText = ({
  text, body, keywords, hasMore,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="highlighted-text">
      <HighlightedText text={expanded ? body : text} keywords={keywords} style={{ display: 'inline' }} />
      {
        (hasMore) && (
          <Tag
            style={{ marginLeft: 8, cursor: 'pointer' }}
            onClick={() => setExpanded(!expanded)}
            className="preview-expand-tag"
          >
            {expanded ? 'less' : 'more'}
          </Tag>
        )
      }
    </div>
  );
};

export default TopicPreviewHighlightedText;
