import React, { useCallback, useState } from 'react';
import { Trash2 } from 'lucide-react';
import { Button, message, Modal } from 'antd';
import { topics } from '@/api';
import { displayOrCatchMessage } from '@/helpers';
import { SET_RELOAD_ACTION } from '@/components/categories/categoriesReducer';

const CategoryDelete = ({ record, dispatch }) => {
  const [loading, setLoading] = useState(false);
  const onClick = useCallback(() => {
    Modal.confirm({
      title: 'Are you sure you want to delete this category?',
      okText: 'Delete',
      cancelText: 'Cancel',
      okType: 'primary',
      cancelType: 'default',
      onOk: () => {
        setLoading(true);

        topics.deleteCategory(record.id).then(() => {
          setLoading(false);
          message.success('Category deleted successfully');
          dispatch({ type: SET_RELOAD_ACTION, payload: true });
        }).catch((e) => {
          setLoading(false);
          message.error('Failed deleting category');
          displayOrCatchMessage(e);
        });
      },
      okButtonProps: {
        danger: true,
      },
    });
  }, [record.id]);
  return (
    <Button loading={loading} onClick={onClick} className="mt-1" type="link">
      <Trash2 className="text-[#000000]" size={16} strokeWidth={2} />
    </Button>
  );
};

export default CategoryDelete;
