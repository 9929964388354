/* eslint-disable react/jsx-filename-extension, max-len */
import React from 'react';

const getColleagueIsHappyData = (row) => ({
  text: `${row.colleague.name} is happy`,
  description: '',
  recommended_step1: '',
  recommended_step2: '',
  recommended_step3: '',
  recommended_step1_description: (
    <>
    </>
  ),
  recommended_step2_description: (
    <>
    </>
  ),
  hasRecommendation: false,
});

export default getColleagueIsHappyData;
