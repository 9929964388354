import mixpanel from 'mixpanel-browser';
import { inProduction } from './flags';
import appConfig from './config';

export const mixpanelEvents = {
  USER_CREATED: 'user_created',
  CLIENT_ADDED: 'client_added',
  PLATFORM_GMAIL_CLICKED: 'platform_gmail_clicked',
  PLATFORM_GMAIL_CONNECTED: 'platform_gmail_connected',
  PLATFORM_MICROSOFT_CONNECTED: 'platform_microsoft_connected',
  PLATFORM_DONE_CLICKED: 'platform_done_clicked',
  ONBOARDING_ADD_NEW_CLIENT_CLICKED: 'onboarding_add_new_client_clicked',
  ONBOARDING_CHECK_DEMO_CLICKED: 'onboarding_check_demo_clicked',
  ONBOARDING_DASHBOARD_CLICKED: 'onboarding_dashboard_clicked',
  HEADER_CHECK_DEMO_DASHBOARD_CLICKED: 'header_check_demo_dashboard_clicked',
  HEADER_CHECK_REAL_DASHBOARD_CLICKED: 'header_check_real_dashboard_clicked',
  LOGO_CLICKED: 'logo_clicked',
  MENU_DASHBOARD_CLICKED: 'menu_dashboard_clicked',
  MENU_COLLEAGUES_DASHBOARD_CLICKED: 'menu_colleagues_dashboard_clicked',
  MENU_INSIGHTS_CLICKED: 'menu_insights_clicked',
  MENU_COLLEAGUES_INSIGHTS_CLICKED: 'menu_colleagues_insights_clicked',
  MENU_CLIENTS_CLICKED: 'menu_clients_clicked',
  MENU_COLLEAGUES_CLICKED: 'menu_colleagues_clicked',
  MENU_TEAM_CLICKED: 'menu_team_clicked',
  MENU_PLATFORMS_CLICKED: 'menu_platforms_clicked',
  UPGRADE_CLICKED: 'upgrade_clicked',
  PLATFORM_GMAIL_RECONNECT_CLICKED: 'platform_gmail_reconnect_clicked',
  PLATFORM_GMAIL_DISCONNECT_CLICKED: 'platform_gmail_disconnect_clicked',
  PLATFORM_MICROSOFT_RECONNECT_CLICKED: 'platform_microsoft_reconnect_clicked',
  PLATFORM_INTERCOM_CONNECTED: 'platform_intercom_connected',
  MENU_DEMO_CLICKED: 'menu_demo_clicked',
  MENU_TOPICS_CLICKED: 'menu_topics_clicked',
  MENU_PAINPOINTS_CLICKED: 'menu_painpoints_clicked',
  MENU_CONVERSATIONS_CLICKED: 'menu_converstations_clicked',
  MENU_CATEGORIES_CLICKED: 'menu_categories_clicked',
  MENU_PEOPLE_CLICKED: 'menu_people_clicked',
  MENU_SETTINGS_CLICKED: 'menu_settings_clicked',
  MENU_DISCOVERY_CLICKED: 'menu_discovery_clicked',
  MENU_HOME_CLICKED: 'menu_home_clicked',
  PAINPOINT_CONFIRMED: 'painpoint_confirmed',
  PAINPOINT_NOT_RELEVANT: 'painpoint_not_relevant',
  GENERATE_SUMMARY_CLICKED: 'generate_summary_clicked',
  VIEW_TOPIC: 'view_topic',
  CREATE_TOPIC_CLICKED: 'create_topic_clicked',
  CREATE_TOPIC_FROM_DISCOVERY_CLICKED: 'create_topic_from_discovery_clicked',
  STARTED_ANALYSIS: 'started_analysis',
  ONBOARDING_WATCH_DEMO_CLICKED: 'onboarding_watch_demo_clicked',
};

export default {
  _init: (token, config, name = '') => {
    if (inProduction()) {
      return mixpanel.init(token, config, name);
    }

    return null;
  },

  init: () => {
    if (inProduction()) {
      return mixpanel.init(appConfig.mixpanel.token, appConfig.mixpanel.config, '');
    }

    return null;
  },

  identify: (id) => {
    if (inProduction()) {
      return mixpanel.identify(id);
    }

    return null;
  },

  people: {
    set: (props) => {
      if (inProduction()) {
        return mixpanel.people.set(props);
      }

      return null;
    },
  },

  track: (event, props) => {
    if (inProduction()) {
      return mixpanel.track(event, props);
    }

    return null;
  },
};
