import { auth } from '../../api';

export default (state = {
  item: null,
  visibility: false,
  onClientList: null,
  isColleague: false,
}, action) => {
  switch (action.type) {
    case 'SHOW_INSIGHT': return {
      ...state,
      item: {
        ...action.item,
      },
      visibility: true,
      onClientList: action.onClientList,
      isColleague: !!action.isColleague,
    };
    case 'HIDE_INSIGHT': return {
      ...state,
      visibility: false,
      onClientList: null,
      isColleague: false,
    };
    case 'MARK_INSIGHT_AS_RESOLVED': return {
      ...state,
      item: {
        ...action.item,
        marked_as_helpful: true,
        marked_as_helpful_at: new Date(),
        marked_as_helpful_by: auth.me(),
      },
    };
    case 'MARK_INSIGHT_AS_NOT_HELPFUL': return {
      ...state,
      item: {
        ...action.item,
        marked_as_helpful: false,
        marked_as_helpful_at: new Date(),
        marked_as_helpful_by: auth.me(),
      },
    };
    case 'UNDO_INSIGHT': return {
      ...state,
      item: {
        ...action.item,
        marked_as_helpful: null,
        marked_as_helpful_at: null,
        marked_as_helpful_by: null,
      },
    };
    default:
      return state;
  }
};

export const HIGHLIGHTED_INSIGHTS_SECTION_COMPONENT = 'highlightedInsightsSection';
export const INSIGHTS_TABLE_COMPONENT = 'insightsTable';
