import React from 'react';

const HowItWorks = ({ description, icon }) => (
  <div className="card-placeholder">
    {icon}
    <div className="gordita-medium-1 mb-xs mt-xs">How it works</div>
    <div className="gordita-regular-2">
      {description}
    </div>
  </div>
);

export default HowItWorks;
