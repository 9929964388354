import React from 'react';
import classNames from 'classnames';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

const OnboardingSteps = ({ current, backAction }) => (
  <div>
    {
      backAction && (
        <Button type="link" onClick={backAction} className="back-button">
          <ArrowLeftOutlined />
        </Button>
      )
    }
    <div className="steps">
      <div className={classNames({ active: current > 0 }, 'step')} />
      <div className={classNames({ active: current > 1 }, 'step')} />
      <div className={classNames({ active: current >= 5 }, 'step')} />
    </div>
  </div>
);

export default OnboardingSteps;
