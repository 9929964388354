/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import { useCustomFieldsValues } from '@/components/discovery/hooks/useCustomFieldsValues';
import debounce from 'lodash/debounce';
import {
  Button, DatePicker, Empty, Input, Menu, Spin,
} from 'antd';
import { cn } from '@/helpers/util';
import { ArrowLeftIcon, SearchIcon } from 'lucide-react';
import { improveLabel, showLabel } from '@/components/filter/filters/CustomFieldFilter';
import { useVisibleFilterDropdownStore } from '@/store/zustand/useVisibleFilterDropdownStore';

export default function CustomFieldItem({
  item, visible = false, handleMenuClick, ...props
}) {
  const {
    key, name, data_type, example, platform_type, type,
  } = item;

  const ref = useRef(null);
  const [search, setSearch] = useState('');
  const [active, setActive] = useState(visible);
  const [currentHeight, setCurrentHeight] = useState(undefined);
  const { setVisible } = useVisibleFilterDropdownStore((state) => state);

  const { data, isLoading } = useCustomFieldsValues(active, key, search);

  const handleSearch = debounce((e) => {
    setSearch(e.target.value);
  }, 500);

  const onItemClick = () => {
    if (!active) {
      setActive(true);
      try {
        const scrollNode = ref.current.parentNode.parentNode;
        setCurrentHeight(scrollNode.scrollHeight);
        scrollNode.scrollTo(0, 0);
      } catch {
        // ignore
      }
    }
  };

  const goBack = (e) => {
    e.stopPropagation();
    setActive(false);
    setCurrentHeight(undefined);
  };

  const handleSelect = (e, value) => {
    handleMenuClick(item, value);
    if (!visible) setActive(false);
    setCurrentHeight(undefined);
    setVisible(false);
  };

  const CustomFieldDate = () => {
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);

    return (
      <div className="space-y-2 mt-2">
        <div className="space-x-1 w-full flex">
          <DatePicker
            placeholder="Start date"
            onChange={(date) => setStartDate(date.startOf('day'))}
          />
          <DatePicker
            placeholder="End date"
            onChange={(date) => setEndDate(date.startOf('day'))}
          />
        </div>
        <Button
          onClick={(e) => handleSelect(e, JSON.stringify({ start: startDate, end: endDate }))}
          className="w-full !mt-2"
          type="primary"
          size="large"
          disabled={!startDate || !endDate}
        >
          Apply
        </Button>
      </div>
    );
  };
  const CustomFieldString = () => (
    <div className="h-full max-w-[420px] overflow-x-scroll overflow-y-scroll">
      {data.length > 0 ? data.map((datum) => (
        <Menu.Item
          {...props}
          key={datum.value}
          className="flex items-center gap-2 hover:!bg-[#F6F9FB] !px-3 !rounded-[4px] !mb-0 !mt-0"
          onClick={(e) => handleSelect(e, datum.value)}
        >
          {improveLabel(showLabel(datum.value))}
        </Menu.Item>
      )) : (
        <Empty description="No values" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </div>
  );

  const CustomFieldType = () => {
    switch (data_type) {
      case 'date':
        return <CustomFieldDate />;
      case 'string':
        return <CustomFieldString />;
      default:
        return <CustomFieldString />;
    }
  };

  return (
    <Menu.Item
      {...props}
      className="custom-field-item flex gap-2 hover:!bg-[#F6F9FB] !px-3 !rounded-[4px] !mb-0 !mt-0"
      onClick={onItemClick}
    >
      <div ref={ref} className={cn(active && 'rounded-md px-3 py-2 absolute top-0 left-0 w-full h-full bg-white shadow-[0px_1px_12px_#CAD7E180] border border-solid border-[#DFE9F1]')} style={{ minHeight: currentHeight }}>
        {!active ? (
          <>{name}</>
        ) : (
          <div className="h-full">
            <div>
              <div className="flex items-center gap-2.5" onClick={(e) => goBack(e)}>
                <ArrowLeftIcon size={16} />
                <span className="text-sm text-[#202324] max-w-[200px] text-ellipsis overflow-hidden whitespace-nowrap">{name}</span>
              </div>
              {
                data_type !== 'date' ? (
                  <Input
                    prefix={<SearchIcon size={14} />}
                    placeholder="Search field"
                    className="mb-2 mt-2.5 !font-[Gordita-Regular]"
                    onChange={handleSearch}
                  />
                ) : null
              }
            </div>
            <div style={{ height: 'calc(100% - 70px)' }}>
              {isLoading ? (
                <div className="text-center p-6">
                  <Spin />
                </div>
              ) : (
                <CustomFieldType />
              )}
            </div>
          </div>
        )}
      </div>
    </Menu.Item>
  );
}
