import React, { useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

export const LATEST_ADDED = 'Latest added';
export const HIGHEST_FEEDBACK = 'Highest feedback';
export const PAINPOINTS_SORT_LOCALSTORAGE_KEY_NAME = 'painpoints_sort';

const PainpointSortTableHead = (props) => {
  const { onChange = () => {} } = props;
  const [selectedValue, setSelectedValue] = useState(
    localStorage.getItem(PAINPOINTS_SORT_LOCALSTORAGE_KEY_NAME) || HIGHEST_FEEDBACK,
  );
  const onClick = ({ key }) => {
    setSelectedValue(key);
    localStorage.setItem(PAINPOINTS_SORT_LOCALSTORAGE_KEY_NAME, key);
    onChange(key);
  };

  return (
    <div className="filter-dropdown-head-table">
      <Dropdown
        overlayClassName="filter-dropdown"
        overlay={(
          <Menu onClick={onClick}>
            <Menu.Item key={LATEST_ADDED}>
              {LATEST_ADDED}
            </Menu.Item>
            <Menu.Item key={HIGHEST_FEEDBACK}>
              {HIGHEST_FEEDBACK}
            </Menu.Item>
          </Menu>
        )}
        trigger={['click']}
      >
        <MenuOutlined />
      </Dropdown>
      <div className="selected-value">{selectedValue}</div>
    </div>
  );
};

export default PainpointSortTableHead;
