import React, { useState, useEffect } from 'react';
import { Upload as AntdUpload, message, Spin } from 'antd';
import 'cropperjs/dist/cropper.css';
import { connect } from 'react-redux';
import { auth } from '@/api';
import Cropper from './Cropper';
import { displayOrCatchMessage } from '../helpers';
import userAuthenticatedAction from '../store/actions/userAuthenticated';

const { Dragger } = AntdUpload;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

const beforeUpload = (setFile) => (file) => {
  setFile(file);
  return false;
};

const onChangePhoto = (setImageUrl) => () => {
  setImageUrl(null);
};

const onFinish = ({
  setImageUrl, setEdit, setLoading, userAuthenticated,
}) => (croppedImageUrl) => {
  setLoading(true);
  auth
    .updateProfile({
      profile_picture: croppedImageUrl,
    })
    .then((userData) => {
      userAuthenticated(userData);
      setImageUrl(null);
      setEdit(false);
      message.success('Your profile picture was updated');
      setLoading(false);
    })
    .catch((e) => {
      displayOrCatchMessage(e);
      setLoading(false);
    });
};

const Upload = ({ setEdit, userAuthenticated }) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (file) {
      setLoading(true);
      getBase64(file, (url) => {
        setImageUrl(url);
        setLoading(false);
      });
    }
  }, [file]);

  return imageUrl ? (
    <>
      <Cropper
        imageUrl={imageUrl}
        onFinish={onFinish({
          setImageUrl,
          setEdit,
          setLoading,
          userAuthenticated,
        })}
        onChangePhoto={onChangePhoto(setImageUrl)}
        loading={loading}
      />
    </>
  ) : (
    <Dragger
      className="mt-md"
      name="file"
      disabled={loading}
      showUploadList={false}
      beforeUpload={beforeUpload(setFile)}
    >
      {loading ? (
        <Spin size="large" />
      ) : (
        <div className="cz-dropzone">
          <span>Upload Photo.</span>
          Or drop one here
        </div>
      )}
    </Dragger>
  );
};

const mapDispatchToProps = (dispatch) => ({
  userAuthenticated: (data) => dispatch(userAuthenticatedAction(data)),
});

export default connect(null, mapDispatchToProps)(Upload);
