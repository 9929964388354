/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useState, useRef, useEffect } from 'react';
import { Button, Dropdown, Tag as AntdTag } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import classNames from 'classnames';

const Tag = ({
  label, overlay, className, isNotHighlighted, ...props
}) => {
  const [visible, setVisible] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setVisible(false);
      }
    }
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <AntdTag {...props} className={classNames(className, 'topic-form-tag')}>
      <div>
        {label}
      </div>
      <div ref={wrapperRef} className="my-wrap" onClick={(e) => e.stopPropagation()}>
        <Dropdown overlay={overlay} trigger="click" visible={visible}>
          <Button onClick={() => !isNotHighlighted && setVisible(true)} type="link"><MoreOutlined /></Button>
        </Dropdown>
      </div>
    </AntdTag>
  );
};

export default Tag;
