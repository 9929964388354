import React from 'react';
import { Breadcrumb as AntdBreadcrumb } from 'antd';
import { withRouter } from 'react-router-dom';

const getCurrentPage = (location) => {
  const key = location.pathname.split('/')[1];
  switch (key) {
    case 'topics': return 'Segments';
    case 'discovery': return 'Discovery';
    case 'conversations': return 'Conversations';
    case 'categories': return 'Categories';
    case 'people': return 'People';
    case 'integrations': return 'Sources';
    case 'settings': return 'Settings';
    case 'account': return 'Account';
    default: return 'Dashboard';
  }
};

const Breadcrumb = ({ location, title }) => (
  <AntdBreadcrumb.Item separator={false} className="text-base text-[#74899B] font-[Gordita-Regular]">{title ?? getCurrentPage(location)}</AntdBreadcrumb.Item>
);

export default withRouter(Breadcrumb);
