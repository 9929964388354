const parseLoadedItems = (items, perPage, pages) => {
  const pagedItems = [];
  let i;

  for (i = 0; i < pages; i++) {
    pagedItems.push({
      items: items.slice(i * perPage, (i + 1) * perPage),
      key: `${i}`,
    });
  }

  return pagedItems;
};

const swapFor = (email, preparedItems, pages) => {
  let pageNumber;
  let key;

  for (pageNumber = 0; pageNumber < pages; pageNumber++) {
    key = Object.keys(preparedItems[pageNumber].items).find(
      // eslint-disable-next-line no-loop-func
      (index) => preparedItems[pageNumber].items[index].email === email,
    );
    if (key !== null && key !== undefined) {
      break;
    }
  }

  if (key !== null && key !== undefined) {
    let elemToSwap = null;
    let nextPageItems = (pageNumber + 1 === pages) ? null : preparedItems[pageNumber + 1].items;

    if (nextPageItems && nextPageItems.length) {
      [elemToSwap, ...nextPageItems] = nextPageItems;
      const currentPageItems = [...preparedItems[pageNumber].items];
      currentPageItems[key] = elemToSwap;

      const repreparedItems = [...preparedItems];
      repreparedItems[pageNumber] = {
        items: currentPageItems,
        key: repreparedItems[pageNumber].key,
      };
      repreparedItems[pageNumber + 1] = {
        items: nextPageItems,
        key: repreparedItems[pageNumber + 1].key,
      };

      return repreparedItems;
    }
    const currentPageItems = [...preparedItems[pageNumber].items];
    currentPageItems.splice(key, 1);

    const repreparedItems = [...preparedItems];
    repreparedItems[pageNumber] = {
      items: currentPageItems,
      key: repreparedItems[pageNumber].key,
    };

    return repreparedItems;
  }

  return null;
};

const reAddItem = (loadedItems, items, email, pages, perPage) => {
  let i;

  const itemToReAdd = loadedItems.find((item) => item.email === email);

  if (!itemToReAdd || items.find((page) => page.items.find((item) => item.email === email))) {
    return null;
  }

  for (i = 0; i < pages; i++) {
    if (items[i].items.length < perPage) {
      const updatedItem = {
        ...items[i],
        items: [
          ...items[i].items,
          itemToReAdd,
        ],
      };
      const updatedItems = [...items];
      updatedItems[i] = updatedItem;
      return updatedItems;
    }
  }

  return null;
};

const updateIfNotNull = (preparedItems, state) => {
  if (preparedItems !== null) {
    return {
      ...state,
      items: preparedItems,
    };
  }

  return state;
};

export default (state = {
  perPage: 6,
  pages: 2,
  items: [],
  loadedItems: [],
}, action) => {
  switch (action.type) {
    case 'RECENT_INTERACTIONS_LOADED':
      return {
        ...state,
        items: parseLoadedItems(action.payload, state.perPage, state.pages),
        loadedItems: action.payload,
      };
    case 'RECENT_INTERACTIONS_ADDED':
      return updateIfNotNull(
        swapFor(action.email, state.items, state.pages),
        state,
      );
    case 'REMOVE_FIRST_CLIENT':
    case 'REMOVE_FIRST_COLLEAGUE':
    case 'REMOVE_TEAM_MEMBER':
      return updateIfNotNull(
        reAddItem(state.loadedItems, state.items, action.payload.email, state.pages, state.perPage),
        state,
      );
    case 'RECENT_INTERACTIONS_ADD_ALL_CLIENTS':
    case 'RECENT_INTERACTIONS_ADD_ALL_COLLEAGUES':
    case 'RECENT_INTERACTIONS_ADD_ALL_TEAM_MEMBERS': return {
      ...state,
      items: parseLoadedItems([], state.perPage, state.pages),
    };
    default:
      return state;
  }
};
