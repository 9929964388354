import React, { useEffect, useCallback, useState } from 'react';
import {
  Button, Form, Input, Tooltip,
} from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { platforms } from '@/api';

const ZapierConnect = () => {
  const [zapierApiKey, setZapierApiKey] = useState('');
  const [zapierURL, setZapierURL] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const getZapierApiKey = useCallback(() => {
    setIsLoading(true);
    platforms.getZapierApiKey().then((response) => {
      setZapierApiKey(response.data.key);
      setZapierURL(response.data.url);
      setIsLoading(false);
    });
  }, []);

  const handleCopyClick = useCallback(() => {
    navigator.clipboard.writeText(zapierApiKey);
    setIsCopied(true);
  }, [zapierApiKey]);

  useEffect(() => {
    getZapierApiKey();
  }, []);

  return (
    <div className="zapier_connect">
      <Form layout="vertical">
        <Form.Item
          className="my-sm"
          name="key"
          label="Zapier Integration API key"
        >
          <Input.Group compact style={{ maxWidth: 400 }}>
            <Input
              style={{ width: 'calc(100% - 40px)' }}
              value={zapierApiKey}
              size="large"
              placeholder="key"
              autoComplete="off"
              readOnly
            />
            <Tooltip title={isCopied ? 'copied' : 'copy'}>
              <Button
                onClick={() => handleCopyClick()}
                onMouseLeave={() => setIsCopied(false)}
                icon={<CopyOutlined />}
                size="large"
                type="primary"
                loading={isLoading}
              />
            </Tooltip>
          </Input.Group>
        </Form.Item>
        {
          zapierURL ? (
            <p>
              Our integration is currently in beta.
              In order to access it follow this link:
              {' '}
              <a target="_blank" rel="noreferrer" href={zapierURL}>{zapierURL}</a>
            </p>
          ) : null
        }
      </Form>
    </div>
  );
};

export default ZapierConnect;
