/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { cn } from '@/helpers/util';
import Card from '@/components/card';
import { useQuery } from '@tanstack/react-query';
import { dashboard } from '@/api';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import {
  Empty, message, Spin, Tooltip,
} from 'antd';
import moment from 'moment';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Text,
  XAxis,
  YAxis,
} from 'recharts';

const useEvolution = (filters, params, options) => useQuery({
  queryKey: ['evolution', filters, params],
  queryFn: async () => {
    const { data } = await dashboard.evolution(filters, params);
    return data;
  },
  ...options,
});

function EvolutionChart({ data }) {
  function transformData(date) {
    return date.map(({ name, ...rest }) => ({
      name: moment(name).format('DD MMM'),
      ...rest,
    }));
  }

  return (
    <div className="evolution-chart">
      <ResponsiveContainer height={250}>
        <LineChart
          data={transformData(data)}
          margin={{
            left: -18,
            bottom: 12,
            right: 8,
          }}
        >
          <Legend
            align="left"
            formatter={(value) => (value.length >= 18 ? (
              <Tooltip title={value}>
                <span className="text-[#202324] !font-[Gordita-Medium]">{`${value.slice(0, 18).trim()}...`}</span>
              </Tooltip>
            ) : (
              <span className="text-[#202324] !font-[Gordita-Medium]">{value}</span>
            ))}
            verticalAlign="top"
            iconType="plainline"
            wrapperStyle={{ top: -30, left: '0.7%' }}
          />
          <CartesianGrid vertical={false} stroke="#EFF4F8" strokeWidth={1} />
          <XAxis
            stroke="#A9BCCF"
            strokeWidth={1}
            dataKey="name"
            dy={12}
            tick={(props) => {
              const { payload: { value } } = props;

              return (
                <Text {...props} className={cn('[&>tspan]:fill-[#87969D]', props.className)} fill="#87969D">{value}</Text>
              );
            }}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            dx={-10}
            tick={(props) => {
              const { payload: { value } } = props;

              return (
                <Text {...props} className={cn('[&>tspan]:fill-[#87969D]', props.className)} fill="#87969D">{value}</Text>
              );
            }}
          />

          {/* We don't have always on new accounts created 3 topics, so we show what we got */}
          {data[0]?.topic1_name ? (
            <Line
              dataKey="topic1_count"
              name={data[0]?.topic1_name ?? 'Topic 1'}
              stroke="#2F70C6"
              strokeWidth={2}
              dot={false}
            />
          ) : null}

          {data[0]?.topic2_name ? (
            <Line
              dataKey="topic2_count"
              name={data[0]?.topic2_name ?? 'Topic 2'}
              stroke="#AC6FF1"
              strokeWidth={2}
              dot={false}
            />
          ) : null}

          {data[0]?.topic3_name ? (
            <Line
              dataKey="topic3_count"
              name={data[0]?.topic3_name ?? 'Topic 3'}
              stroke="#F16FDC"
              strokeWidth={2}
              dot={false}
            />
          ) : null}

        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export function EvolutionCard({ className }) {
  const defaultDate = useDefaultDateStore((state) => state.defaultDate);
  const {
    data, error, isLoading, isError,
  } = useEvolution({
    start: defaultDate.start,
    end: defaultDate.end,
  });

  useEffect(() => {
    if (!isError) return;
    message.error(error.message);
  }, [error]);

  if (isLoading) {
    return (
      <Card className={cn('py-[18px] mb-4', className)}>
        <Card.Header>
          <div className="flex items-center divide-y-0 divide-x-2 gap-7 divide-solid divide-[#DFE9F1]">
            <Card.Header.Title title="Evolution" />
          </div>
        </Card.Header>
        <Card.Body className="mt-12">
          <div className="text-center">
            <Spin />
          </div>
        </Card.Body>
      </Card>
    );
  }

  if (!data || !data.chart.length) {
    return (
      <Card className={cn('py-[18px] mb-4', className)}>
        <Card.Header>
          <div className="flex items-center divide-y-0 divide-x-2 gap-7 divide-solid divide-[#DFE9F1]">
            <Card.Header.Title title="Evolution" />
          </div>
        </Card.Header>
        <Card.Body className="mt-12">
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="No data"
          />
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card className={cn('py-[18px] mb-4', className)}>
      <Card.Header>
        <div className="flex items-center divide-y-0 divide-x-2 gap-7 divide-solid divide-[#DFE9F1]">
          <Card.Header.Title title="Evolution" />
        </div>
      </Card.Header>
      <Card.Body className="mt-12">
        <EvolutionChart data={data.chart} />
      </Card.Body>
    </Card>
  );
}
