export const SET_CONVERSATIONS = 'conversations/SET_CONVERSATIONS';
export const SET_LOADING = 'conversations/SET_LOADING';
export const SET_ACTION_LOADING = 'conversations/SET_ACTION_LOADING';
export const SET_TEXT_COLLAPSE = 'conversations/SET_TEXT_COLLAPSE';
export const UPDATE_TOPIC = 'conversations/UPDATE_TOPIC';

export const initialState = {
  conversations: [],
  pagination: { current: 1, pageSize: 15, total: 50 },
  loading: false,
  actionLoading: false,
};

export const conversationsReducer = (prevState, action) => {
  switch (action.type) {
    case SET_CONVERSATIONS: {
      return {
        ...prevState,
        loading: false,
        conversations: action.payload.conversations.map(
          (item) => ({ ...item, isTextCollapsed: true }),
        ),
        pagination: action.payload.pagination,
      };
    }
    case SET_LOADING: {
      return {
        ...prevState,
        loading: action.payload,
      };
    }
    case SET_ACTION_LOADING: {
      return {
        ...prevState,
        actionLoading: action.payload,
      };
    }
    case SET_TEXT_COLLAPSE: {
      return {
        ...prevState,
        conversations: prevState.conversations.map(
          (item) => (item.id === action.payload
            ? { ...item, isTextCollapsed: !item.isTextCollapsed } : item),
        ),
      };
    }
    case UPDATE_TOPIC: {
      const { conversationId, conversation } = action.payload;

      return {
        ...prevState,
        conversations: prevState.conversations.map(
          (item) => (item.id === conversationId
            ? { ...conversation, isTextCollapsed: item.isTextCollapsed }
            : item),
        ),
      };
    }
    default: {
      return prevState;
    }
  }
};
