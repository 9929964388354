import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import DiscoveryIcon from '@/components/summary/svgs/DiscoveryIcon';
import { Input } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useChatInteract } from '@chainlit/react-client';

const ChainlitModalInput = ({ loading, expandedMessage }) => {
  const [value, setValue] = useState('');
  const ref = useRef();
  const { sendMessage } = useChatInteract();
  const onKeyPress = useCallback((e) => {
    if (value !== '' && e.charCode === 13) {
      setValue('');
      sendMessage({
        output: value,
        id: uuidv4(),
        createdAt: new Date().toISOString(),
        type: 'user_message',
      }, []);
    }
  }, [value]);
  const onChange = useCallback((e) => setValue(e.target.value));

  useEffect(() => {
    ref.current?.focus();
    if (expandedMessage) {
      sendMessage({
        output: expandedMessage,
        id: uuidv4(),
        createdAt: new Date().toISOString(),
        type: 'user_message',
      }, []);
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      ref.current?.focus();
    }
  }, [loading]);

  return (
    <Input
      size="large"
      ref={ref}
      placeholder="Type your message here..."
      prefix={<DiscoveryIcon disabled={loading} className="discovery-anticon" />}
      disabled={loading}
      onChange={onChange}
      onKeyPress={onKeyPress}
      value={value}
    />
  );
};

export default ChainlitModalInput;
