import React from 'react';
import Progress from '@/components/ui/progress';
import Select from '../../base-components/Select';
import PainpointCellName from './PainpointCellName';
import TopicMetric from '../../base-components/topic-metric';
import SentimentTooltip from '../../base-components/SentimentTooltip';
import PainpointSortTableHead from './PainpointSortTableHead';

const selectValueOptions = [
  { name: 'Not set', value: 1 },
  { name: 'Not important', value: 2 },
  { name: 'Nice to have', value: 3 },
  { name: 'Important', value: 4 },
  { name: 'Vital', value: 5 },
];

export const calculateDifference = (val, prevVal) => {
  if (!val) {
    return null;
  }

  const result = ((val - prevVal) / val) * 100;

  return `${result > 0 ? '+' : ''}${result.toFixed(0)}%`;
};

const painpointsColumns = (
  history,
  setAddReleaseModalTrue,
  setPainpointId,
  setReleasesModalTrue,
  onSortChange,
) => [{
  title: (<PainpointSortTableHead onChange={onSortChange} />),
  key: 'painpoint',
  className: 'column-clickable',
  render: (row) => (
    // eslint-disable-next-line react/jsx-filename-extension
    <div className="painpoint-cell">
      <PainpointCellName {...row} />
    </div>
  ),
  onCell: (record) => ({
    onClick: () => {
      history.push(`${window.location.pathname.startsWith('/demo') ? '/demo/' : '/'}topic/overview/${record.id}/${record.groups[0].id}`);
    },
  }),
},
{
  title: 'Conversations',
  key: 'Feedback',
  render: (row = {}) => {
    const { count = {} } = row;
    const difference = calculateDifference(count.value, count.previous_value);
    return (
      <div className="painpoint-cell">
        <TopicMetric
          value={count.value}
          trend={count.trend}
          difference={difference}
          tooltip="Total feedback in selected interval"
        />
      </div>
    );
  },
},
{
  title: 'Ratio',
  key: 'Ratio',
  render: (row = {}) => {
    const { ratio = {} } = row;
    return (
      <div className="painpoint-cell">
        <TopicMetric
          value={`${Math.ceil(ratio.value * 100)}%`}
          trend={ratio.trend}
          tooltip="The ratio of feedback compared to the total in the selected period"
        />
      </div>
    );
  },
},
{
  title: 'MRR',
  key: 'MRR',
  render: (row = {}) => {
    const { mrr = {} } = row;
    return (
      <div className="painpoint-cell">
        <TopicMetric.MRR
          value={mrr.value}
          trend={mrr.trend}
          tooltip="Monthly recurring revenue from users talking about this painpoint"
        />
      </div>
    );
  },
},
{
  title: 'Sentiment',
  key: 'Sentiment',
  className: 'content-align-right',
  render: (row) => {
    const { sentiment = {} } = row;

    return (
      <div className="painpoint-cell">
        <SentimentTooltip sentiment={sentiment} style={{ display: 'block' }}>
          <Progress.Sentiment
            positive={sentiment.positive}
            negative={sentiment.negative}
          />
        </SentimentTooltip>
      </div>
    );
  },
},
{
  title: 'Value',
  key: 'Value',
  render: (row) => {
    const { value = 1 } = row;
    return (
      <div className="painpoint-cell">
        <Select
          defaultValue={value}
          style={{ minWidth: 153 }}
          options={selectValueOptions}
        />
      </div>
    );
  },
}];

export { painpointsColumns };
