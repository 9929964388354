import { conversations } from '@/api';
import { useQuery } from '@tanstack/react-query';

export default function useConversation(conversationId) {
  return useQuery({
    queryKey: ['conversation', conversationId],
    queryFn: async () => {
      const { data } = await conversations.getConversation(conversationId);
      return data;
    },
    enabled: !!conversationId,
  });
}
