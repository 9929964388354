import { cn } from '@/helpers/util';
import { List } from 'antd';
import React from 'react';
import { ReactComponent as IssueIcon } from '@/components/summary/svgs/icon-issue.svg';
import { ReactComponent as QuestionIcon } from '@/components/summary/svgs/icon-question.svg';
import { ReactComponent as RequestIcon } from '@/components/summary/svgs/icon-bulb.svg';
import { ReactComponent as OtherIcon } from '@/components/summary/svgs/icon-other.svg';
import { X } from 'lucide-react';
import styles from './Templates.module.css';
import { useTemplateStore } from '../store/useTemplateStore';

function getIcon(type, className) {
  switch (type) {
    case 'issue':
      return <IssueIcon className={cn(className)} />;
    case 'question':
      return <QuestionIcon className={cn(className)} />;
    case 'request':
      return <RequestIcon className={cn(className)} />;
    case 'all':
      return <OtherIcon className={cn(className)} />;
    default:
      return null;
  }
}

function TemplatesHeader({ children, className }) {
  return (
    <header className={cn('flex items-center justify-between', className)}>
      <h3 className="m-0 text-sm text-[#75899B] font-[Gordita-Regular]">Templates</h3>
      <aside>
        {children}
      </aside>
    </header>
  );
}

function TemplatesListItem({ icon, action, name }) {
  const { template, setTemplate } = useTemplateStore();
  return (
    <List.Item
      id="template-list-item"
      className={
        cn(
          'cz-no-border',
          styles.listItem,
          template === icon && 'bg-grey-200 rounded-md',
        )
      }
      onClick={() => action()}
    >
      <List.Item.Meta
        className={cn(styles.listItemMeta)}
        avatar={getIcon(icon, 'flex')}
        description={(
          <span className={
            cn(
              'text-sm text-[#44596C] font-[Gordita-Medium]',
              template === icon && 'text-[#202324]',
            )
          }
          >
            {name}
          </span>
        )}
      />
      {template === icon && (
        <X
          onClick={(e) => {
            e.stopPropagation();
            setTemplate(null);
          }}
          size={16}
          color="#75899B"
          className="flex border-0 bg-transparent hover:text-[#44596C] hover:bg-[#EFF4F8] hover:rounded-md"
        />
      )}
    </List.Item>
  );
}

function TemplatesList({ className }) {
  const { setTemplate } = useTemplateStore();
  return (
    <List
      className={cn('space-y-0.5', className, styles.list)}
      renderItem={TemplatesListItem}
      dataSource={[
        { icon: 'issue', action: () => setTemplate('issue'), name: 'Top Issues' },
        { icon: 'question', action: () => setTemplate('question'), name: 'Most asked questions' },
        { icon: 'request', action: () => setTemplate('request'), name: 'Missing feature requests' },
        { icon: 'all', action: () => setTemplate('all'), name: 'View all insights' },
      ]}
    />
  );
}

export function FiltersTemplates({ children, className }) {
  return (
    <div className={cn('bg-white rounded-lg px-4 py-3 no-scrollbar overflow-hidden', className)}>
      {children}
    </div>
  );
}

FiltersTemplates.Header = TemplatesHeader;
FiltersTemplates.List = TemplatesList;
