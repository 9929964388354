import * as React from 'react';
import { memo } from 'react';

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <path
      style={{
        fill: '#fff',
      }}
      d="M0 0h40v40H0z"
    />
    <path
      d="M24.039 10.453V6.7a2.9 2.9 0 0 0 1.669-2.6v-.093a2.9 2.9 0 0 0-2.889-2.89h-.088a2.9 2.9 0 0 0-2.89 2.889v.088a2.894 2.894 0 0 0 1.65 2.6l.017.008v3.759a8.2 8.2 0 0 0-3.913 1.727l.016-.012L7.293 4.141a3.291 3.291 0 1 0-1.525 2l-.016.008 10.143 7.9a8.225 8.225 0 0 0 .144 9.294l-.017-.025-3.086 3.082a2.594 2.594 0 0 0-.764-.125 2.679 2.679 0 1 0 2.679 2.679 2.607 2.607 0 0 0-.13-.784l.005.018 3.054-3.054a8.233 8.233 0 1 0 6.3-14.673l-.047-.007Zm-1.27 12.359A4.225 4.225 0 1 1 27 18.586a4.225 4.225 0 0 1-4.225 4.225Z"
      transform="translate(4.085 4.183)"
      style={{
        fill: '#c0c4c4',
      }}
    />
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
