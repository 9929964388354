export const SET_LOADING_ACTION = 'SET_LOADING_ACTION';

export const SET_CATEGORY_ACTION = 'SET_CATEGORY_ACTION';

export const SET_CATEGORY_GROUPS_ACTION = 'SET_CATEGORY_GROUPS_ACTION';

export const SET_EXPANDED_KEYS_ACTION = 'SET_EXPANDED_KEYS_ACTION';

export const SET_RELOAD_ACTION = 'SET_RELOAD_ACTION';

export const SET_SEARCH_FILTER_ACTION = 'SET_SEARCH_FILTER_ACTION';

export const SET_RANGE_FILTER_ACTION = 'SET_RANGE_FILTER_ACTION';

export const initialState = {
  expandedIds: [],
  selectedId: null,
  loading: false,
  reload: null,
  data: [],
  filters: null,
};

export const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SEARCH_FILTER_ACTION: {
      return {
        ...initialState,
        reload: !state.reload, // this is also used as key for table
        filters: {
          ...state.filters,
          name: action.payload,
        },
      };
    }
    case SET_RANGE_FILTER_ACTION: {
      const { payload: { start, end } } = action;

      return {
        ...initialState,
        reload: !state.reload,
        filters: {
          ...state.filters,
          start,
          end,
        },
      };
    }
    case SET_LOADING_ACTION: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case SET_RELOAD_ACTION: {
      return {
        ...initialState,
        reload: !state.reload,
        filters: {
          ...state.filters,
        },
      };
    }
    case SET_CATEGORY_GROUPS_ACTION: {
      const { payload } = action;

      // `children` prop is added only to display expand icon
      return {
        ...state,
        loading: false,
        data: payload.map((category) => ({
          ...category,
          hasChildren: category.has_children,
          ...(category.has_children && { children: [] }),
        })),
      };
    }
    case SET_CATEGORY_ACTION: {
      const { data } = state;
      const { payload: { children, id, isTable } } = action;

      if (isTable) {
        return {
          ...state,
          loading: false,
          data: children.map(({ groups, ...category }) => ({
            ...category,
            groupId: groups[0]?.id ?? null,
            groupName: groups[0]?.name ?? '',
          })),
        };
      }

      return {
        ...state,
        loading: false,
        data: data.map((category) => {
          if (category.id === id) {
            return {
              ...category,
              children: children.map((item) => ({ ...item, groupId: category.id })),
            };
          }
          return category;
        }),
      };
    }
    case SET_EXPANDED_KEYS_ACTION: {
      const { payload: { expanded, id } } = action;
      const { expandedIds, data } = state;

      if (expanded) {
        const { hasChildren, children } = data.find((category) => category.id === id);
        const selectedId = hasChildren && children.length === 0 ? id : null;

        return {
          ...state,
          selectedId,
          expandedIds: [...expandedIds, id],
        };
      }

      return {
        ...state,
        selectedId: null,
        expandedIds: [...expandedIds.filter((key) => key !== id)],
      };
    }
    default:
      return state;
  }
};
