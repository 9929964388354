import React, { useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  Button, Modal, Select, Spin,
} from 'antd';
import GhostButton from '@/components/GhostButton';
import { topics, conversations } from '@/api';
import { displayOrCatchMessage } from '@/helpers';
import useConversationsStore from '../../store/useConversationsStore';

const { Option } = Select;

const MoveToAnotherTopic = ({
  conversation,
  forTopicId,
}) => {
  const {
    actions: { updateTopic },
  } = useConversationsStore();

  const [loadedTopics, setLoadedTopics] = useState(
    conversation.topics ? conversation.topics : [],
  );

  const [visible, setVisible] = useState(false);

  const [moveTopics, setMoveTopics] = useState(
    conversation.topics ? conversation.topics.map((item) => item.id) : [],
  );

  const [loading, setLoading] = useState(false);
  const handleUpdateTopic = async () => {
    setLoading(true);
    const updated = await updateTopic(conversation.id, moveTopics, forTopicId);
    setLoading(false);
    if (updated) {
      setVisible(false);
    }
  };

  const handleCancelUpdate = () => {
    setVisible(false);
  };

  const handleBeginUpdate = () => {
    setLoading(true);
    setVisible(true);

    Promise.all([
      topics.getTopics().then((response) => {
        setLoadedTopics(response.data);
      }).catch((e) => displayOrCatchMessage(e)),
      conversations.getConversation(conversation.id).then((response) => {
        setMoveTopics(response.data.topics
          ? response.data.topics.map((item) => item.id) : []);
      }).catch((e) => displayOrCatchMessage(e)),
    ]).then(() => {
      setLoading(false);
    });
  };

  return (
    <>
      <GhostButton onClick={handleBeginUpdate} type="primary" className="conversation-actions-menu-button">
        <ArrowLeftOutlined />
        Move to another topic
      </GhostButton>
      <Modal
        title="Move Conversation to another topic"
        onOk={handleUpdateTopic}
        onCancel={handleCancelUpdate}
        footer={[
          <Button key="back" onClick={handleCancelUpdate}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={handleUpdateTopic}>
            Save
          </Button>,
        ]}
        visible={visible}
      >
        {
          loading ? (
            <div style={{ textAlign: 'center' }}>
              <Spin size="large" />
            </div>
          ) : (
            <Select
              style={{ width: '100%' }}
              mode="multiple"
              size="large"
              placeholder="Topic"
              value={moveTopics}
              onChange={(value) => setMoveTopics(value)}
              loading={loading}
              filterOption={(inputValue, option) => option.children
                .toLowerCase().indexOf(inputValue.toLowerCase()) > -1}
            >
              {loadedTopics.map((topic) => (
                <Option
                  key={topic.id}
                  value={topic.id}
                >
                  {topic.name}
                </Option>
              ))}
            </Select>
          )
        }
      </Modal>
    </>
  );
};

export default MoveToAnotherTopic;
