import React, { useCallback } from 'react';
import RecipientTag from './RecipientTag';

const InsightAnatomyCardRecipients = ({ to, from, cc }) => {
  const getGroups = useCallback(() => {
    const all = [...to, ...from, ...cc];
    return {
      clients: all.filter((item) => !!item.client),
      team: all.filter((item) => !item.client && !!item.team_member),
      unknown: all.filter((item) => !item.client && !item.team_member),
    };
  }, [to, from, cc]);

  const { clients, team, unknown } = getGroups();

  return (
    <div className="recipients">
      {
        clients.length ? (
          <div>
            <strong>Client</strong>
            <div>
              {
                clients.map((item) => (
                  <RecipientTag
                    key={item.client.id}
                    forClient
                    name={item.client.name}
                    email={item.client.email}
                  />
                ))
              }
            </div>
          </div>
        ) : null
      }
      {
        team.length ? (
          <div>
            <strong>Team</strong>
            <div>
              {
                team.map((item) => (
                  <RecipientTag
                    key={item.team_member.id}
                    forTeamMember
                    name={item.team_member.name}
                    email={item.team_member.email}
                  />
                ))
              }
            </div>
          </div>
        ) : null
      }
      {
        unknown.length ? (
          <div>
            <strong>Unknown</strong>
            <div>
              {
                unknown.map((item) => (
                  <RecipientTag
                    key={item.address}
                    name={item.display}
                    email={item.address}
                  />
                ))
              }
            </div>
          </div>
        ) : null
      }
    </div>
  );
};

export default InsightAnatomyCardRecipients;
