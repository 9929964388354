import React, { useMemo } from 'react';
import { cn } from '@/helpers/util';

function Bars({ bars, ...rest }) {
  return <Progress bars={bars} {...rest} />;
}

function Sentiment({ positive, negative, ...rest }) {
  const bars = useMemo(() => {
    const neutral = (1 - (positive + negative));

    const hasNeutral = neutral !== 0;

    return [
      {
        value: negative,
        color: '#F17E99',
        style: hasNeutral ? {} : { borderTopRightRadius: 0, borderBottomRightRadius: 0 },
      },
      { value: neutral, color: '#DFE9F1' },
      {
        value: positive,
        color: '#5FC59A',
        style: hasNeutral ? {} : { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 },
      },
    ].filter(({ value }) => value !== 0);
  }, [positive, negative]);

  return <Progress bars={bars} {...rest} />;
}

const Progress = ({ bars, className, ...rest }) => (
  <div
    className={cn('progress', className)}
    {...rest}
  >
    {bars.map(({ value, color, style = {} }) => (
      <div
        key={`${value}-${color}`}
        role="progressbar"
        aria-valuenow={value}
        aria-valuemin={0}
        aria-valuemax={1}
        className="progress__item"
        style={{ backgroundColor: color, width: `${value * 100}%`, ...style }}
      >
        <span className="progress__item__label">{value}</span>
      </div>
    ))}
  </div>
);

export default Progress;
Progress.Bars = Bars;
Progress.Sentiment = Sentiment;
