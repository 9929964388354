import axios from './axios';
import auth from './auth';

const index = (onDomain, query, cancelToken, pageSize = 3) => axios.get('/api/recent-interactions', {
  params: {
    team_id: auth.team().id,
    query,
    on_domain: onDomain,
    page_size: pageSize,
  },
  cancelToken,
}).then((response) => response).catch((e) => Promise.reject(e));

const recentInteractions = {
  index,
};

export default recentInteractions;
