import React, { useState } from 'react';
import {
  Button, Form, Input, Modal,
} from 'antd';
import { colleagues } from '@/api';
import PopoutForm from '../forms/PopoutForm';
import rules from '../../helpers/rules';
import { displayOrCatch, useErrorState, loadListIfAvailable } from '../../helpers';

const resetForm = (form, client) => {
  form.setFieldsValue({
    name: client ? client.name : '',
    company_name: client ? client.company : '',
    email: client ? client.email : '',
  });
};

const onAddOrEdit = (form) => () => {
  form.submit();
};

const onAddOrEditFinish = ({
  client, form, loadList, setVisible, setError, setLoading,
}) => (values) => {
  setLoading(true);
  const method = (client && client.key) ? colleagues.update(client) : colleagues.store;
  method(values).then(() => {
    setVisible(false);
    setLoading(false);
    resetForm(form, client);
    loadList();
  }).catch((e) => {
    displayOrCatch(e, setError);
    setLoading(false);
  });
};

const onCancelAddOrEdit = (client, form, setVisible) => () => {
  setVisible(false);
  resetForm(form, client);
};

const ColleagueModal = ({
  loadList, setVisible, client, visible, ...props
}) => {
  const [form] = Form.useForm();
  const [error, setError] = useErrorState();
  const [loading, setLoading] = useState();

  return (
    <Modal
      title={client ? 'Edit your team member' : 'Add team member'}
      onOk={onAddOrEdit(form)}
      onCancel={onCancelAddOrEdit(client, form, setVisible)}
      footer={[
        <Button key="back" onClick={onCancelAddOrEdit(client, form, setVisible)}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={onAddOrEdit(form)}>
          {client ? 'Save' : 'Add New Team Member'}
        </Button>,
      ]}
      visible={visible}
      {...props}
    >
      <PopoutForm
        form={form}
        popoutError={error}
        onFinish={onAddOrEditFinish({
          client,
          form,
          loadList: loadListIfAvailable(loadList),
          setVisible,
          setError,
          setLoading,
        })}
        layout="vertical"
      >
        <Form.Item
          className="mb-sm"
          name="name"
          rules={rules.name('Team Member\'s full name is required')}
          label="Team Member's name"
          initialValue={client ? client.name : ''}
        >
          <Input size="large" placeholder="Client's full name" />
        </Form.Item>
        <Form.Item
          className="mb-sm"
          name="email"
          rules={rules.email}
          label="Team Member's email"
          initialValue={client ? client.email : ''}
        >
          <Input
            size="large"
            placeholder="Team Member's email"
            readOnly={!!client}
            style={client ? {
              color: 'rgba(0, 0, 0, 0.45)',
            } : null}
          />
        </Form.Item>
      </PopoutForm>
    </Modal>
  );
};

export default ColleagueModal;
