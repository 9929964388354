/* eslint-disable camelcase */
import React from 'react';
import { Modal, Form, message } from 'antd';
import Filter from '@/components/filter';
import { ConversationsPreview } from '@/components/conversations/preview';
import { useMutation } from '@tanstack/react-query';
import { useFilters } from '@/components/filter/context';
import { topics } from '@/api';
import { transformCustomFieldToPost } from '@/api/conversations';
import { TopicForm } from '../discovery/TopicForm';

export function CreateTopicModal({
  visible, onCancel, onOk,
}) {
  const { filters } = useFilters();
  const [form] = Form.useForm();

  const createMutation = useMutation({
    mutationKey: ['create_topic'],
    mutationFn: async ({ name, follow_exclusions, groups }) => {
      const { custom_fields, ...restFilters } = filters;
      const { data: res } = await topics.createTopic({
        name,
        groups: [groups],
        follow_exclusions: !!follow_exclusions,
        custom_fields: transformCustomFieldToPost(custom_fields),
        ...restFilters,
      });

      return res.data;
    },
    onSuccess: async (data) => {
      form.resetFields();
      onOk(data);
      message.success('Segment created successfully');
    },
    onError: (error) => {
      message.error(error.message, 1.5);
      onCancel();
    },
  });

  const handleSave = async () => {
    const values = await form.validateFields();
    createMutation.mutate(values);
  };

  return (
    <Modal
      className="cz-edit-modal"
      title="Create Segment"
      visible={visible}
      onCancel={onCancel}
      closeIcon={false}
      okText="Create segment"
      okButtonProps={{
        loading: createMutation.isLoading,
        disabled: createMutation.isLoading,
      }}
      onOk={() => handleSave()}
      width="64%"
      destroyOnClose
    >
      <div className="flex gap-3">
        <section className="flex flex-col gap-3 w-1/3 min-h-full">
          <Filter className="flex-1">
            <Filter.View>
              <Filter.AddButton className="m-3" />
              <Filter.List />
            </Filter.View>
          </Filter>
          <div className="bg-white rounded-lg p-4 pb-6">
            <TopicForm
              form={form}
              labels
              hasGroups
            />
          </div>
        </section>

        <ConversationsPreview />
      </div>
    </Modal>
  );
}
