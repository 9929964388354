/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Button, Card, Input, Spin,
} from 'antd';
import { CheckCircleFilled, CheckOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { platforms } from '@/api';
import customerIo from '@/customer-io';
import OnboardingBackground from './OnboardingBackground';
import OnboardingSteps from './OnboardingSteps';
import IntercomIcon from '../svgs/Intercom';
import ZendeskIcon from '../svgs/Zendesk';
import { ReactComponent as TypeformIcon } from '../svgs/typeform.svg';
import { ReactComponent as PlayBtnRounded } from '../svgs/play-btn-rounded.svg';
import config from '../../../config';
import useIntegrationsStore from '../store/useIntegrationsStore';
import OnboardingTypeformConnect from './OnboardingTypeformConnect';
import { INTEGRATION_TYPE } from '../constants';
import { displayOrCatchMessage } from '../../../helpers';
import StartInsightsDiscoveryButton from './StartInsightsDiscoveryButton';
import ConnectedPlatforms from './ConnectedPlatforms';
import Guarantees from './Guarantees';
import OnboardingDemoVideo from './OnboardingDemoVideo';
import mixpanel, { mixpanelEvents } from '../../../mixpanel';

const Onboarding = ({
  user,
}) => {
  const {
    state, actions: {
      updateConnectedPlatforms,
      updateConnectedPlatformsWithResponse,
    },
  } = useIntegrationsStore();
  const [loading, setLoading] = useState(false);
  const [showZendeskDomainStep, setShowZendeskDomainStep] = useState(false);
  const [showZendeskStartStep, setShowZendeskStartStep] = useState(false);
  const [showDemoVideo, setShowDemoVideo] = useState(false);
  const [showTypeformStep, setShowTypeformStep] = useState(false);
  const [showIntercomStep, setShowIntercomStep] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [zendeskDomain, setZendeskDomain] = useState('');
  const history = useHistory();
  const location = useLocation();
  const onConnectIntercom = () => {
    if (state.connectedPlatforms.filter((p) => p.type === INTEGRATION_TYPE.INTERCOM).length) {
      setShowIntercomStep(true);
      setCurrentStep(2);
    } else {
      setLoading(true);
      const myWindow = window.open(`${config.apiBaseUrl}/intercom/auth?token=${user.token}`,
        'logw', 'width=600,height=800');
      const timer = setInterval(() => {
        if (myWindow.closed) {
          clearInterval(timer);
          platforms.index().then((response) => {
            updateConnectedPlatformsWithResponse(response);
            setLoading(false);
          }).catch((e) => {
            displayOrCatchMessage(e);
            setLoading(false);
          });
        }
      }, 1000);
    }
  };
  const onConnectZendesk = () => {
    if (state.connectedPlatforms.filter((p) => p.type
      === INTEGRATION_TYPE.ZENDESK_SUPPORT).length) {
      setShowZendeskStartStep(true);
    } else {
      setShowZendeskDomainStep(true);
    }
    setCurrentStep(2);
  };
  const onConnectZendeskWithDomain = () => {
    setLoading(true);
    const myWindow = window.open(`${config.apiBaseUrl}/zendesk/auth?token=${user.token}&subdomain=${encodeURIComponent(zendeskDomain)}`,
      'logw', 'width=700,height=500');
    const timer = setInterval(() => {
      if (myWindow.closed) {
        clearInterval(timer);
        platforms.index().then((response) => {
          updateConnectedPlatformsWithResponse(response);
          setLoading(false);
        }).catch((e) => {
          displayOrCatchMessage(e);
          setLoading(false);
        });
      }
    }, 1000);
  };
  const onDemoVideoBack = () => {
    setShowDemoVideo(false);
  };
  const onZendeskBack = () => {
    setCurrentStep(1);
    setShowZendeskDomainStep(false);
    setShowZendeskStartStep(false);
  };
  const onTypeformBack = () => {
    setCurrentStep(1);
    setShowTypeformStep(false);
  };
  const onConnectTypeform = () => {
    setCurrentStep(2);
    setShowTypeformStep(true);

    if (state.connectedPlatforms.filter((p) => p.type === INTEGRATION_TYPE.TYPEFORM && p.status === 'not_ready').length === 0) {
      setLoading(true);
      const myWindow = window.open(`${config.apiBaseUrl}/typeform/auth?token=${user.token}`,
        'logw', 'width=400,height=630');
      const timer = setInterval(() => {
        if (myWindow.closed) {
          clearInterval(timer);
          platforms.index().then((response) => {
            updateConnectedPlatformsWithResponse(response);
            if (response.data.data.filter(
              (platform) => platform.type === INTEGRATION_TYPE.TYPEFORM,
            ).length === 0) {
              onTypeformBack();
            }
            setLoading(false);
          }).catch((e) => {
            displayOrCatchMessage(e);
            onTypeformBack();
            setLoading(false);
          });
        }
      }, 1000);
    }
  };
  const onWatchIntroClick = () => {
    setShowDemoVideo(true);
    mixpanel.track(mixpanelEvents.ONBOARDING_WATCH_DEMO_CLICKED, {
      pageUrl: location.pathname,
    });
  };
  const onBookMeetingClicked = () => {
    if (import.meta.env.VITE_CUSTOMERIO_SITEID) {
      customerIo.track('meeting-booked-clicked');
    }
  };

  useEffect(() => {
    if (state.connectedPlatforms.filter((p) => (p.type === INTEGRATION_TYPE.INTERCOM && p.status !== 'not_ready')
    || (p.type === INTEGRATION_TYPE.ZENDESK_SUPPORT && p.status !== 'not_ready')
    || (p.type === INTEGRATION_TYPE.TYPEFORM && p.status !== 'not_ready')).length > 0) {
      history.push('/add-topics');
    } else if (state.csvUploaded) {
      history.push('/add-topics');
    } else if (state.connectedPlatforms.filter((p) => p.type
      === INTEGRATION_TYPE.INTERCOM && p.status === 'not_ready').length > 0) {
      setShowIntercomStep(true);
      setCurrentStep(2);
    } else if (state.connectedPlatforms.filter((p) => p.type
      === INTEGRATION_TYPE.ZENDESK_SUPPORT && p.status === 'not_ready').length > 0) {
      setShowZendeskStartStep(true);
      setCurrentStep(2);
    }
  }, [state.connectedPlatforms, state.csvUploaded]);

  return (
    <OnboardingBackground>
      <div className="new-onboarding-wrapper new-onboarding-start-wrapper">
        <div className="new-onboarding">
          <OnboardingSteps
            current={currentStep}
            backAction={showDemoVideo ? onDemoVideoBack : showZendeskDomainStep
              ? onZendeskBack : ((showTypeformStep && !loading) ? onTypeformBack : null)}
          />
          {
            showDemoVideo ? (
              <OnboardingDemoVideo />
            )
              : (showIntercomStep || showZendeskStartStep) ? (
                <div className="new-onboarding-content intercom-step">
                  <ConnectedPlatforms type={showIntercomStep ? 'intercom' : 'zendesk'} />
                  <StartInsightsDiscoveryButton
                    type={showIntercomStep ? 'intercom' : 'zendesk'}
                    onActivate={() => {
                      updateConnectedPlatforms();
                    }}
                  />
                  <Guarantees />
                </div>
              ) : (
                showZendeskDomainStep ? (
                  <div className="new-onboarding-content zendesk-step">
                    <h1>Authorize ClientZen to analyze Zendesk</h1>
                    {
                    loading ? (
                      <div className="new-onboarding-loading">
                        <Spin size="large" />
                      </div>
                    ) : (
                      <>
                        <h4>Your Zendesk support domain</h4>
                        <div className="new-onboarding-zendesk-domain">
                          <Input onKeyPress={(e) => e.key === 'Enter' && onConnectZendeskWithDomain()} value={zendeskDomain} placeholder="domain" size="large" autoComplete="off" onChange={(e) => setZendeskDomain(e.target.value)} />
                          <div className="button-wrap">
                            <span>.zendesk.com</span>
                            <Button size="large" type="primary" disabled={!zendeskDomain} onClick={onConnectZendeskWithDomain}><CheckOutlined /></Button>
                          </div>
                        </div>
                      </>
                    )
                  }
                  </div>
                ) : (showTypeformStep ? (
                  <div className="new-onboarding-content typeform-step">
                    <h1>Authorize ClientZen to analyze Typeform</h1>
                    {
                    loading ? (
                      <div className="new-onboarding-loading">
                        <Spin size="large" />
                      </div>
                    ) : (
                      <OnboardingTypeformConnect />
                    )
                  }
                  </div>
                ) : (
                  <>
                    <div className="new-onboarding-content">
                      <h1>Connect your favorite feedback source</h1>
                      {
                      loading ? (
                        <div className="new-onboarding-loading">
                          <Spin size="large" />
                        </div>
                      ) : (
                        <>
                          <div className="new-onboarding-integrations">
                            <div className="new-onboarding-integration-card">
                              <Card onClick={onConnectIntercom} hoverable>
                                <div className="card-icon">
                                  <IntercomIcon />
                                </div>
                                <h2>Intercom</h2>
                                <span>
                                  <CheckCircleFilled />
                                  {' '}
                                  Verified by Intercom
                                </span>
                              </Card>
                            </div>
                            <div className="new-onboarding-integration-card">
                              <Card onClick={onConnectZendesk} hoverable>
                                <div className="card-icon">
                                  <ZendeskIcon />
                                </div>
                                <h2>Zendesk</h2>
                                <span>
                                  <CheckCircleFilled />
                                  {' '}
                                  Verified by Zendesk
                                </span>
                              </Card>
                            </div>
                            <div className="new-onboarding-integration-card">
                              <Card onClick={onConnectTypeform} hoverable>
                                <div className="card-icon">
                                  <TypeformIcon />
                                </div>
                                <h2>Typeform</h2>
                                <span>
                                  <CheckCircleFilled />
                                  {' '}
                                  Verified by Typeform
                                </span>
                              </Card>
                            </div>
                            <div className="new-onboarding-integration-card watch-intro-card">
                              <Card onClick={onWatchIntroClick} hoverable>
                                <div className="card-icon">
                                  <PlayBtnRounded />
                                </div>
                                <h2>Watch Intro</h2>
                                <span>
                                  1 minute demo
                                </span>
                              </Card>
                            </div>
                          </div>
                          <h5>+ 60 premium integrations after onboarding</h5>
                          <div style={{ paddingBottom: 18 }}>
                            <h4 style={{ fontSize: 14, paddingTop: 12 }}>
                              Need a demo first?
                            </h4>
                            <Button
                              href="https://meetings.clientzen.io/meetings/ovi/30mins"
                              onClick={onBookMeetingClicked}
                              target="blank"
                              type="primary"
                              className="start-discovery-insights"
                              loading={loading}
                              size="medium"
                              style={{ maxWidth: 160 }}
                            >
                              Book a Meeting
                            </Button>
                          </div>
                        </>
                      )
                    }
                    </div>
                    <Guarantees />
                  </>
                ))
              )
          }
        </div>
      </div>
    </OnboardingBackground>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(Onboarding);
