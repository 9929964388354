const getMemberData = (member) => ({
  avatar: member.invited ? member.invited.profile_picture : null,
  key: member.id,
  userId: member.invited ? member.invited.id : null,
  name: member.invited ? member.invited.name : member.name,
  email: member.invited ? member.invited.email : member.email,
  role: (member.invited
      && member.invited.teams
      && member.invited.teams.length) ? member.invited.teams[0].pivot.name : null,
  owner: (member.invited
    && member.invited.teams
    && member.invited.teams.length) ? member.invited.teams[0].pivot.owner : false,
  loading: false,
  errored: false,
});

export {
  getMemberData,
};
