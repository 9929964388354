const UrlSearchService = (urlSearchParams, isNegligible) => ({
  setParam: (key, value) => {
    if (isNegligible(value)) {
      if (urlSearchParams.has(key)) {
        urlSearchParams.delete(key);
      }
    } else if (!urlSearchParams.has(key)) {
      urlSearchParams.append(key, value);
    } else {
      urlSearchParams.set(key, value);
    }
  },
  addParam: (key, value) => {
    if (!urlSearchParams.has(key) && !isNegligible(value)) {
      urlSearchParams.append(key, value);
    }
  },
  deleteParam: (key) => {
    if (urlSearchParams.has(key)) {
      urlSearchParams.delete(key);
    }
  },
});

export default UrlSearchService;
