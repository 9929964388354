import { topics } from '@/api';
import { useQuery } from '@tanstack/react-query';

export default function useTopicGroups(filters = null) {
  return useQuery({
    queryKey: ['topic_groups', filters],
    queryFn: async () => {
      const { data } = await topics.getTopicGroups(filters);
      return data;
    },
    refetchOnWindowFocus: false,
  });
}
