import SentimentAvgEvolution from '@/components/dashboard/SentimentAvgEvolution';
import React, { useState, useEffect } from 'react';
import { message, Spin } from 'antd';
import { dashboard } from '@/api';
import SentimentChart from '../../base-components/sentiment-chart';
import { SentimentRadioGroupDateFilter, useSentimentDateFilterInitialValue } from '../../base-components/sentiment-date-filter';

const GlobalSentimentsSection = () => {
  const [globalSentiment, setGlobalSentiment] = useState({
    data: { positive: 0, negative: 0 },
    loading: false,
  });

  const defaultValue = useSentimentDateFilterInitialValue();

  const [rangeFilter, setRangeFilter] = useState(defaultValue);

  const getGlobalSentiment = async () => {
    setGlobalSentiment({ ...globalSentiment, loading: true });
    try {
      const data = await dashboard.averageSentiment(rangeFilter);
      setGlobalSentiment({
        ...globalSentiment,
        data: {
          positive: Math.round(data.positive * 10000) / 100,
          negative: Math.round(data.negative * 10000) / 100,
        },
        loading: false,
      });
    } catch (err) {
      setGlobalSentiment({ ...globalSentiment, loading: false });
      message.error('Failed loading Global Sentiment');
    }
  };

  useEffect(() => getGlobalSentiment(), [rangeFilter]);

  return (
    <div>
      <div className="dashboard-sentiment">
        <h3 className="dashboard-conversations__title">Global Sentiment</h3>
        <SentimentRadioGroupDateFilter
          initialValue={defaultValue}
          onChange={(value) => setRangeFilter(value)}
        />
      </div>
      <div className="dashboard-sentiment__paper">
        <SentimentChart filters={rangeFilter} />
        {
          globalSentiment.loading
            ? (
              <div className="sentiment-avg-evolution-container">
                <Spin size="large" />
              </div>
            )
            : (
              <SentimentAvgEvolution
                positive={globalSentiment.data.positive}
                negative={globalSentiment.data.negative}
                timeText={rangeFilter.label}
                style={{ marginLeft: 'auto' }}
              />
            )
        }
      </div>
    </div>
  );
};

export default GlobalSentimentsSection;
