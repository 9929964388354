import React from 'react';
import TopicFormFooter from './TopicFormFooter';
import TopicFormHeader from './TopicFormHeader';
import TopicKeywords from './TopicKeywords';

const TopicForm = (props) => {
  const {
    onCancel, onOk, data, subtopic, topicName, topicId,
  } = props;
  return (
    <div className="topic-steps topic-steps-new-topic">
      <TopicFormHeader onCancel={onCancel} data={data} subtopic={subtopic} />
      <main>
        <TopicKeywords data={data} subtopic={subtopic} topicName={topicName} topicId={topicId} />
      </main>
      <TopicFormFooter
        onCancel={onCancel}
        onOk={onOk}
        data={data}
        subtopic={subtopic}
        topicId={topicId}
      />
    </div>
  );
};

export default TopicForm;
