import React, {
  useReducer, useEffect, useMemo, useCallback, useState,
} from 'react';
import {
  Table,
  Input,
  message,
} from 'antd';
import isBoolean from 'lodash/isBoolean';
import {
  Search,
} from 'lucide-react';
import debounce from 'lodash/debounce';
import { useHistory, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useBoolean } from '@/hooks/useBoolean';
import { topics } from '@/api';
import TablePagination from '@/base-components/table-pagination';
import useURLState from '@/hooks/useUrlState';
import { mixpanelEvents } from '@/mixpanel';
import { Helmet } from 'react-helmet';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import { SentimentDateFilter } from '@/components/ui/sentiment-date-filter';
import ModalTopicForm from '../TopicForm/ModalTopicForm';
import AddIcon from '../svgs/icons/Add';
import {
  initialState,
  PAINPOINTS_SET_DATA,
  PAINPOINTS_SET_LOADING_ACTION,
  PAINPOINTS_SET_SEARCH_FILTER_ACTION,
  PAINPOINTS_SET_RANGE_FILTER_ACTION,
  painpointsReducer,
} from './reducer';
import { painpointsColumns } from './helpers';
import AnnotationCreateModal from '../topic-dashboard/components/AnnotationCreateModal';
import AnnotationListModal from '../topic-dashboard/components/AnnotationListModal';
import { LATEST_ADDED, PAINPOINTS_SORT_LOCALSTORAGE_KEY_NAME } from './PainpointSortTableHead';
import NoData from './NoData';
import TrackedButton from '../TrackedButton';
import Layout from '../Layout';

const Painpoints = ({ location: { pathname } }) => {
  const history = useHistory();

  const { urlQueryState, setUrlQueryParam, deleteUrlQueryParam } = useURLState();

  // const defaultValue = useSentimentDateFilterInitialValue();

  const { value: visible, setFalse: setHidden, setTrue: setVisible } = useBoolean();

  const {
    value: releaseDeleted,
    setFalse: setReleaseDeletedFalse,
    setTrue: setReleaseDeletedTrue,
  } = useBoolean();

  const [painpointId, setPainpointId] = useState(null);

  const {
    value: modalAddReleaseVisible,
    setFalse: setAddReleaseModalFalse,
    setTrue: setAddReleaseModalTrue,
  } = useBoolean();

  const {
    value: modalReleasesVisible,
    setFalse: setReleasesModalFalse,
    setTrue: setReleasesModalTrue,
  } = useBoolean();

  const { defaultDate } = useDefaultDateStore();
  const [state, dispatch] = useReducer(painpointsReducer, initialState, (initState) => ({
    ...initState,
    filters: {
      ...initState.filters,
      start: defaultDate.start,
      end: defaultDate.end,
    },
  }));

  useEffect(() => {
    dispatch({ type: PAINPOINTS_SET_RANGE_FILTER_ACTION, payload: { ...defaultDate } });
  }, [defaultDate]);

  const getData = useCallback((
    page = state.pagination.current,
    defaultSorter = localStorage.getItem(PAINPOINTS_SORT_LOCALSTORAGE_KEY_NAME),
  ) => {
    dispatch({ type: PAINPOINTS_SET_LOADING_ACTION, payload: true });

    const sorter = defaultSorter === LATEST_ADDED ? {} : { highest_feedback: 1 };
    topics.getPainpoints(state.filters || {}, 10, page, sorter)
      .then(({ data, pager }) => {
        dispatch({
          type: PAINPOINTS_SET_DATA,
          payload: { data, pager },
        });
      }).catch(() => {
        dispatch({ type: PAINPOINTS_SET_LOADING_ACTION, payload: false });
        message.error('Painpoints Error');
      });
  }, [state.filters, urlQueryState.page]);

  const onSortChange = (sortKey) => getData(1, sortKey);

  const columns = useMemo(() => painpointsColumns(
    history,
    setAddReleaseModalTrue,
    setPainpointId,
    setReleasesModalTrue,
    onSortChange,
  ).filter(({ key }) => key !== 'Value'), [history]);

  const topicModalProps = {
    visible,
    onCancel: setHidden,
    onOk: (topic, isEdit) => {
      setHidden();
      if (!isEdit) {
        history.push(`${pathname.startsWith('/demo') ? '/demo/' : '/'}topic/overview/${topic.id}/${topic.groups[0].id}`);
      }
    },
  };

  const searchProps = {
    className: 'painpoints__header__search',
    placeholder: 'Search segments',
    prefix: <Search className="pr-0.5" size={16} strokeWidth={2} color="#75899B" />,
    onChange: debounce((e) => {
      dispatch({ type: PAINPOINTS_SET_SEARCH_FILTER_ACTION, payload: e.target.value });
    }, 400),
  };

  const onPageChange = (page) => {
    if (page !== state.pagination.current) {
      if (page === 1) {
        deleteUrlQueryParam('page');
      } else {
        setUrlQueryParam('page', page);
      }
    }
  };

  useEffect(() => {
    if (urlQueryState.page !== `${state.pagination.current}`) {
      getData(urlQueryState.page ? urlQueryState.page : 1);
    }
  }, [urlQueryState.page]);

  useEffect(() => {
    if (isBoolean(state.reload)) {
      getData(1);
    }
  }, [state.reload]);

  return (
    <Layout
      leftHeader={<Input {...searchProps} />}
      rightHeader={(
        <>
          <TrackedButton
            event={mixpanelEvents.CREATE_TOPIC_CLICKED}
            size="middle"
            type="primary"
            htmlType="button"
            icon={<AddIcon className="painpoints__header__btn-icon" />}
            disabled={state.loading}
            onClick={setVisible}
          >
            Create segment
          </TrackedButton>
          {/* <SentimentRadioGroupDateFilter
            initialValue={defaultValue}
            onChange={
          (value) => dispatch({ type: PAINPOINTS_SET_RANGE_FILTER_ACTION, payload: value })
        }
          /> */}
          <SentimentDateFilter />
        </>
    )}
    >
      <Helmet>
        <title>ClientZen - Segments</title>
      </Helmet>
      <AnnotationCreateModal
        visible={modalAddReleaseVisible}
        onCancel={() => {
          setAddReleaseModalFalse();
          setPainpointId(null);
        }}
        state={{ id: painpointId }}
        onOk={() => {
          setAddReleaseModalFalse();
          const changedPainpoint = state.data.filter(({ id }) => id === painpointId)[0];
          history.push(
            `${pathname.startsWith('/demo')
              ? '/demo/'
              : '/'}painpoints/${changedPainpoint.id}/${changedPainpoint.groups[0].id}`,
          );
        }}
      />
      <AnnotationListModal
        visible={modalReleasesVisible}
        state={{ id: painpointId }}
        onCancel={() => {
          setReleasesModalFalse();
          setPainpointId(null);
          if (releaseDeleted) {
            getData(1);
            setReleaseDeletedFalse();
          }
        }}
        onOk={() => {
          setReleaseDeletedTrue();
        }}
      />

      <section className="painpoints mx-7 2xl:mx-auto 2xl:max-w-[1200px]">
        {isEmpty(state.data) && !state.loading && !state.filters.name ? (
          <NoData openNewTopicModal={setVisible} />
        ) : (
          <div className="painpoints__body">
            <Table
              rowKey={({ id }) => id}
              scroll={{ x: 'max-content' }}
              columns={columns}
              dataSource={state.data}
              loading={state.loading}
              rowClassName={(record) => (record.annotations?.length > 0 ? 'row-highlighted' : null)}
              pagination={false}
              footer={() => <TablePagination type="topics" pagination={state.pagination} onPageChange={onPageChange} />}
            />
          </div>
        )}
      </section>
      <ModalTopicForm {...topicModalProps} />
    </Layout>
  );
};

export default withRouter(Painpoints);
