import React from 'react';

function Logo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
      <defs>
        <linearGradient id="linear-gradient" x1="0.127" y1="0.878" x2="0.81" y2="0.137" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#0baa6b" />
          <stop offset="1" stopColor="#1e92d6" />
        </linearGradient>
        <filter id="Rectangle_2819" x="0" y="0" width="70" height="70" filterUnits="userSpaceOnUse">
          <feOffset dy="3" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodColor="#83a1b5" floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g id="ph-logo-clientzen" transform="translate(333 -2886.417)">
        <g transform="matrix(1, 0, 0, 1, -333, 2886.42)" filter="url(#Rectangle_2819)">
          <rect id="Rectangle_2819-2" data-name="Rectangle 2819" width="52" height="52" rx="10" transform="translate(9 6)" fill="url(#linear-gradient)" />
        </g>
        <g id="Group_2354" data-name="Group 2354" transform="translate(-312.214 2902.621)">
          <g id="Group_2353" data-name="Group 2353" transform="translate(9.694)">
            <path id="Path_371" data-name="Path 371" d="M26.8,9.8a4.9,4.9,0,1,1,4.9-4.9A4.916,4.916,0,0,1,26.8,9.8Zm0-7.237A2.339,2.339,0,1,0,29.137,4.9,2.354,2.354,0,0,0,26.8,2.56Z" transform="translate(-21.9 0)" fill="#fff" stroke="#fff" strokeWidth="0.1" />
          </g>
          <path id="Path_1187" data-name="Path 1187" d="M28.074,47.453a5.1,5.1,0,0,0-4.1-1.853c-3.266,0-6.311,2.6-8.561,4.545l-.927.794c-.309-.221-.618-.53-.927-.794C11.3,48.248,8.26,45.6,4.994,45.6a5.1,5.1,0,0,0-4.1,1.853,4.526,4.526,0,0,0-.838,3.839,6.4,6.4,0,0,0,4.722,4.59,10.871,10.871,0,0,0,2.957.4,11.307,11.307,0,0,0,6.752-2.162,11.613,11.613,0,0,0,6.752,2.162,11.108,11.108,0,0,0,2.957-.4,6.4,6.4,0,0,0,4.722-4.59A4.446,4.446,0,0,0,28.074,47.453ZM5.524,53.411a3.934,3.934,0,0,1-2.957-2.692,1.9,1.9,0,0,1,.353-1.677,2.573,2.573,0,0,1,2.118-.883c2.295,0,4.943,2.251,6.884,3.928.177.132.309.265.485.4A8.968,8.968,0,0,1,5.524,53.411Zm20.918-2.692a3.857,3.857,0,0,1-2.957,2.692,9.029,9.029,0,0,1-6.84-.927c.177-.132.309-.265.485-.4,1.942-1.633,4.59-3.928,6.884-3.928a2.633,2.633,0,0,1,2.118.883A2.2,2.2,0,0,1,26.442,50.719Z" transform="translate(0.066 -25.477)" fill="#fff" stroke="#fff" strokeWidth="0.1" />
          <path id="Path_1188" data-name="Path 1188" d="M28.565,29.524a1.29,1.29,0,0,0-1.721.53A2.575,2.575,0,0,1,24.46,31.2c-1.192,0-2.207-.838-3.4-1.809-1.5-1.28-3.222-2.692-5.825-2.692s-4.325,1.412-5.825,2.692C8.22,30.363,7.25,31.2,6.014,31.2a2.575,2.575,0,0,1-2.383-1.147,1.29,1.29,0,0,0-1.721-.53,1.306,1.306,0,0,0-.574,1.721,5.2,5.2,0,0,0,4.678,2.515c2.118,0,3.663-1.28,5.031-2.427,1.28-1.059,2.515-2.118,4.192-2.118s2.913,1.015,4.192,2.118c1.368,1.147,2.913,2.427,5.031,2.427a5.2,5.2,0,0,0,4.678-2.515A1.349,1.349,0,0,0,28.565,29.524Z" transform="translate(-0.645 -14.917)" fill="#fff" stroke="#fff" strokeWidth="0.1" />
        </g>
      </g>
    </svg>
  );
}

export default Logo;
