import axios from './axios';
import auth from './auth';

const all = () => axios
  .get('/api/dashboard', {
    params: {
      team_id: auth.team().id,
    },
  })
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const allNew = (start, end, search, week, onlyFavorites = false) => axios
  .get('/api/dashboard-new', {
    params: {
      team_id: auth.team().id,
      start,
      end,
      search: search || undefined,
      week,
      only_favorites: onlyFavorites,
    },
  })
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const evolution = (filters, params) => axios.get('/api/dashboard-evolution', {
  params: {
    team_id: auth.team().id,
    ...filters,
    ...params,
  },
}).then((res) => res.data).catch((err) => Promise.reject(err));

const ready = () => axios
  .get('/api/dashboard-ready', {
    params: {
      team_id: auth.team().id,
    },
  })
  .then((response) => response.data.data)
  .catch((e) => Promise.reject(e));

const highlighted = () => axios
  .get('/api/dashboard-highlighted', {
    params: {
      team_id: auth.team().id,
    },
  })
  .then((response) => response.data.data)
  .catch((e) => Promise.reject(e));

const trends = () => axios
  .get('/api/dashboard-trends', {
    params: {
      team_id: auth.team().id,
    },
  })
  .then((response) => response.data.data)
  .catch((e) => Promise.reject(e));

const last7DaysScores = () => axios
  .get('/api/dashboard-last-7-days-scores', {
    params: {
      team_id: auth.team().id,
    },
  })
  .then((response) => response.data.data)
  .catch((e) => Promise.reject(e));

const last30DaysScores = () => axios
  .get('/api/dashboard-last-30-days-scores', {
    params: {
      team_id: auth.team().id,
    },
  })
  .then((response) => response.data.data)
  .catch((e) => Promise.reject(e));

const averageSentiment = ({ start, end }) => axios
  .get('/api/dashboard-average-sentiment-count', {
    params: {
      team_id: auth.team().id,
      start,
      end,
    },
  })
  .then((response) => response.data.data)
  .catch((e) => Promise.reject(e));

const trendBreakdown = (params) => axios
  .get('/api/dashboard-trend-breakdown', {
    params: {
      team_id: auth.team().id,
      only_favorites: params.onlyFavorites,
      ...params,
    },
  })
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const updateGlobalScoreType = (type) => axios.put(
  '/api/dashboard/global-score-type',
  {
    global_score_type: type,
  }, {
    params: {
      team_id: auth.team().id,
    },
  },
)
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const dashboard = {
  all,
  allNew,
  evolution,
  ready,
  highlighted,
  trends,
  last7DaysScores,
  last30DaysScores,
  averageSentiment,
  trendBreakdown,
  updateGlobalScoreType,
};

export default dashboard;
