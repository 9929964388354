import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const useSummaryVisibleTypes = create(devtools((set) => ({
  visibleTypes: ['issue', 'request', 'question', 'other'],
  setVisibleTypes: (visibleTypes) => set({ visibleTypes }),
  reset: () => set({ visibleTypes: ['issue', 'request', 'question', 'other'] }),
}), {
  name: 'useSummaryVisibleTypes',
}));
