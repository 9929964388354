import React from 'react';
import IconGmailSmall from '@/images/integrations/icon-gmail-small.svg';
import IconIntercomSmall from '@/images/integrations/icon-intercom-small.svg';
import IconZendeskSmall from '@/images/integrations/icon-zendesk-small.svg';
import IconCSVSmall from '@/images/integrations/icon-csv-small.svg';
import IconZapierSmall from '@/images/integrations/icon-zapier-small.svg';
import IconTypeformSmall from '@/images/integrations/icon-typeform-small.svg';
import IconGorgiasSmall from '@/images/integrations/icon-gorgias-small.svg';
import { cn } from '@/helpers/util';

export const IntegrationIcons = {
  google: IconGmailSmall,
  intercom: IconIntercomSmall,
  zendesk: IconZendeskSmall,
  csv: IconCSVSmall,
  demo: IconCSVSmall,
  zapier: IconZapierSmall,
  typeform: IconTypeformSmall,
  gorgias: IconGorgiasSmall,
};

const ConversationSource = ({ conversation, className, ...props }) => (conversation.external_link ? (
  <a className="external_link" href={conversation.external_link} target="_blank" rel="noopener noreferrer" {...props}>
    <img className="w-4 h-4" src={IntegrationIcons[conversation.source]} alt={conversation.source} />
  </a>
) : (
  <img className={cn('w-4 h-4', className)} src={IntegrationIcons[conversation.source]} alt={conversation.source} {...props} />
));

export default ConversationSource;
