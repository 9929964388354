import React, { useEffect, useRef, useState } from 'react';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  Tooltip, Tag, Button, Spin,
} from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { topics } from '@/api';
import * as dom from 'dompurify';
import { keywordsType } from '../../topic-modal/components/options';
import TagSelect from '../../topic-modal/components/TagSelect';
import { ReactComponent as ConversationIcon } from '../svgs/icon-conv.svg';
import HighlightedText from '../../../components/HighlightedText';
import { IntegrationIcons } from '../../conversations-table/components/ConversationSource';
import IconSentimentPositiveSmall from '../../../images/sentiments/icon-sentiment-positive-small.svg';
import IconSentimentNeutralSmall from '../../../images/sentiments/icon-sentiment-neutral-small.svg';
import IconSentimentNegativeSmall from '../../../images/sentiments/icon-sentiment-negative-small.svg';
import { userAddedOneTopic } from '../../../helpers/features';
import userAuthenticatedAction from '../../../store/actions/userAuthenticated';
import { displayOrCatchMessage } from '../../../helpers';
import DotsLoader from '../../../components/animations/DotsLoader';

const extendedOptions = ['Price', 'Bug', 'Login', 'Account', 'Trial'];

const sentimentIcon = {
  positive: IconSentimentPositiveSmall,
  neutral: IconSentimentNeutralSmall,
  negative: IconSentimentNegativeSmall,
};

const OnboardingTopic = ({ user, userAuthenticated }) => {
  const history = useHistory();
  const selectRef = useRef(null);
  const [keywords, setKeywords] = useState([]);
  const [total, setTotal] = useState(0);
  const [hits, setHits] = useState([]);
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const onExtendedOptionClick = (option) => () => {
    selectRef.current.resetItems();
    selectRef.current.addItem(option);
  };
  const onAdvancedAnalyticsClick = () => {
    setCreating(true);
    topics
      .createTopic({
        name: keywords[0],
        groups: ['Uncategorized'],
        included_keywords: keywords,
        excluded_keywords: [],
        excluded_ids: Object.keys(state).filter((id) => state[id].excluded),
      })
      .then(({ data }) => {
        userAddedOneTopic(user, userAuthenticated);
        history.push(`/topic/overview/${data.data.id}/${data.data.groups[0].id}`);
      })
      .catch((e) => {
        displayOrCatchMessage(e);
        setCreating(false);
      });
  };
  const onSkipClick = () => {
    userAddedOneTopic(user, userAuthenticated);
    history.push('/');
  };

  useEffect(() => {
    if (keywords.length) {
      setLoading(true);
      topics
        .getPreview({
          included_keywords: keywords,
          excluded_keywords: [],
          start: moment().subtract(90, 'days').format('YYYY-MM-DD'),
          end: moment().format('YYYY-MM-DD'),
        })
        .then((results) => {
          setTotal(results.data.total);
          setHits(results.data.hits);
          setLoading(false);
        })
        .catch(() => {
          setTotal(0);
          setHits([]);
          setLoading(false);
        });
    }
  }, [keywords]);

  return (
    <div className="onboarding-topic">
      <Button
        onClick={onSkipClick}
        size="small"
        className="skip-button"
        type="secondary"
      >
        Skip
      </Button>
      <h1>Define your first customer topic</h1>
      <h2 className="top">
        Add keywords or phrases to create a cluster of feedback for your first
        topic
      </h2>
      <div className="search-wrapper">
        <div className="search-input">
          <div className="search-input-wrapper">
            <TagSelect
              size="large"
              listHeight={120}
              tokenSeparators={['\n', ';']}
              suffix=""
              suffixOptions={keywordsType}
              ref={selectRef}
              onChange={(options) => setKeywords(options)}
            />
            <div className="search-input-icon">
              <SearchOutlined />
            </div>
          </div>
        </div>
      </div>
      {!keywords.length && (
        <div className="search-extended-options">
          <h2>Keyword suggestions</h2>
          <div>
            {extendedOptions.map((option) => (
              <Tag key={option} onClick={onExtendedOptionClick(option)}>
                {option}
              </Tag>
            ))}
          </div>
        </div>
      )}
      {
        // eslint-disable-next-line no-nested-ternary
        loading ? (
          <div className="search-loader">
            <Spin size="large" />
          </div>
        ) : keywords.length ? (
          <div className="search-results">
            <h3>
              <ConversationIcon />
              {hits.filter((hit) => !state[hit.message.id]?.excluded).length
                ? ` Found ${
                  total
                    - hits.filter((hit) => state[hit.message.id]?.excluded).length
                } conversations about ${keywords
                  .map((keyword) => `'${keyword}'`)
                  .join(' or ')} in the past 90 days`
                : ` No conversations found about ${keywords
                  .map((keyword) => `'${keyword}'`)
                  .join(' or ')}`}
            </h3>
            <div className="search-conversation-list">
              {!hits.filter((hit) => !state[hit.message.id]?.excluded)
                .length && (
                <div className="search-conversation-list-empty">
                  Write your own keywords or phrases in the box above to
                  discover customer feedback topics.
                </div>
              )}
              {hits
                .filter((hit) => !state[hit.message.id]?.excluded)
                .map((hit) => (
                  <div key={hit.message.id} className="search-conversation">
                    <div className="search-conversation-body">
                      {state[hit.message.id]
                      && state[hit.message.id].expanded ? (
                        <div
                          className="search-conversation-body"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: dom.sanitize(hit.message.body),
                          }}
                        />
                        ) : (
                          <div className="search-conversation-snippet">
                            <HighlightedText
                              text={hit.message.snippet}
                              keywords={hit.keywords.snippet}
                            />
                          </div>
                        )}
                      {hit.message.has_more && (
                        <Button
                          type="link"
                          className="more-btn"
                          onClick={() => setState({
                            ...state,
                            [hit.message.id]: {
                              ...state[hit.message.id],
                              expanded: !state[hit.message.id]?.expanded,
                            },
                          })}
                        >
                          {state[hit.message.id]?.expanded ? 'less' : 'more'}
                        </Button>
                      )}
                    </div>
                    <div className="search-conversation-actions">
                      <div className="search-conversation-actions-left">
                        <Tooltip title="Analyzing data in progress">
                          <div className="search-conversation-author">
                            <img
                              src={IntegrationIcons[hit.message.platform_type]}
                              alt={hit.message.platform_type}
                            />
                            <span>Author</span>
                          </div>
                        </Tooltip>
                        <Tooltip title="Analyzing sentiment in progress">
                          <div className="search-conversation-sentiment">
                            <img src={sentimentIcon.neutral} alt="" />
                          </div>
                        </Tooltip>
                        <Tooltip title="Analyzing intent in progress">
                          <div className="search-conversation-intent">
                            <span>INTENT</span>
                          </div>
                        </Tooltip>
                        <div className="search-conversation-dots">
                          <DotsLoader />
                        </div>
                      </div>
                      <div className="search-conversation-actions-right">
                        <div className="search-conversation-date">
                          <span>
                            {moment(hit.message.date).format('D MMM YYYY')}
                          </span>
                        </div>
                        <Tooltip title="Exclude from topic">
                          <div className="search-conversation-delete">
                            <Button
                              type="link"
                              onClick={() => setState({
                                ...state,
                                [hit.message.id]: {
                                  ...state[hit.message.id],
                                  excluded: !state[hit.message.id]?.excluded,
                                },
                              })}
                            >
                              <DeleteOutlined />
                            </Button>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {hits.filter((hit) => !state[hit.message.id]?.excluded).length ? (
              <div className="search-conversation-create">
                <Button
                  onClick={onAdvancedAnalyticsClick}
                  size="large"
                  type="primary"
                  loading={creating}
                >
                  {`See ${
                    keywords.length ? `“${keywords[0]}”` : ''
                  } advanced analytics`}
                </Button>
              </div>
            ) : null}
          </div>
        ) : null
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  userAuthenticated: (data) => dispatch(userAuthenticatedAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingTopic);
