import axios from './axios';
import auth from './auth';

const token = () => axios.post('/api/copilot/token', {
  team_id: auth.team().id,
});

export default {
  token,
};
