import React from 'react';
import { Button, Table, Transfer } from 'antd';
import difference from 'lodash/difference';
import { DeleteOutlined } from '@ant-design/icons';

const TableTransfer = ({
  leftColumns, rightColumns, ...restProps
}) => (
  <Transfer className="custom-fields-table" {...restProps}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
      onItemRemove,
    }) => {
      const columns = direction === 'left' ? leftColumns : [
        {
          render: () => <span className="space-instead" />,
          width: 20,
        },
        ...rightColumns,
        {
          render: (item) => (
            <Button
              type="link"
              onClick={() => {
                onItemRemove([item.key]);
                onItemSelectAll([]);
              }}
            >
              <DeleteOutlined />
            </Button>
          ),
        },
      ];
      const rowSelection = {
        getCheckboxProps: (item) => ({
          className: '[&_.ant-checkbox-checked::after]:rounded-none [&_.ant-checkbox-inner]:rounded-none',
          disabled: listDisabled || item.disabled,
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          onItemSelect(key, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };
      return (
        <Table
          rowSelection={direction === 'left' ? rowSelection : null}
          columns={columns}
          dataSource={filteredItems}
          size="small"
          style={{
            pointerEvents: listDisabled ? 'none' : undefined,
          }}
          onRow={({ key, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return;
              onItemSelect(key, !listSelectedKeys.includes(key));
            },
          })}
        />
      );
    }}
  </Transfer>
);

export default TableTransfer;
