import React, { useEffect, useState } from 'react';
import {
  Button, Divider, Form, Input, message, Spin, Tag,
} from 'antd';
import { settings } from '@/api';
import { displayOrCatchMessage } from '../../helpers';
import ValidatedForm from '../forms/ValidatedForm';
import rules from '../../helpers/rules';

const ExclusionsTab = () => {
  const [excluded, setExcluded] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [form] = Form.useForm();

  const fetchExcluded = () => {
    setLoading(true);
    settings.getExcluded().then((data) => {
      setExcluded(data.data);
    }).catch((e) => displayOrCatchMessage(e)).finally(() => {
      setLoading(false);
      form.resetFields();
    });
  };

  useEffect(fetchExcluded, []);

  const onRemoveExclusion = (e, user) => {
    e.preventDefault();
    setLoading(true);
    settings.removeExclusion(user.id)
      .then((data) => setExcluded(data.data.data))
      .catch((ex) => displayOrCatchMessage(ex))
      .finally(() => {
        setLoading(false);
        form.resetFields();
      });
  };

  const onSubmit = (values) => {
    setSubmitting(true);
    settings.exclude(values.exclude)
      .then((data) => {
        if (data.data.data.length !== excluded.length) {
          setExcluded(data.data.data);
        } else {
          message.error('Client not found');
        }
      })
      .catch((e) => displayOrCatchMessage(e))
      .finally(() => {
        setSubmitting(false);
        form.resetFields();
      });
  };

  return (
    <div>
      {' '}
      {loading && (
        <div style={{ textAlign: 'center', margin: 30 }}>
          <Spin size="large" />
        </div>
      )}
      {!loading && (
        <div>
          <h2>Exclude people from all results</h2>
          <p>
            Select what people you don&apos;t want to
            be included in conversations and feedback analysis.
          </p>
          <div style={{ paddingTop: 16 }}>
            <ValidatedForm
              form={form}
              onFinish={onSubmit}
              layout="vertical"
            >
              <Form.Item
                name="exclude"
                rules={rules.name('Field cannot be empty')}
              >
                <Input size="large" placeholder="Email" />
              </Form.Item>
              <div className="exclude-container">
                <Button
                  loading={submitting}
                  size="medium"
                  className="cta exclude-btn"
                  type="primary"
                  htmlType="submit"
                >
                  Exclude
                </Button>
              </div>
            </ValidatedForm>
          </div>
          {excluded.length !== 0 && (
            <>
              <Divider />
              <p>Excluded people</p>
              <div>
                {excluded.map((user) => (
                  <Tag
                    key={user.id}
                    className="excluded-tag"
                    closable
                    onClose={(e) => onRemoveExclusion(e, user)}
                  >
                    {user.name}
                    {' '}
                    (
                    {user.email}
                    )
                  </Tag>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ExclusionsTab;
