/* eslint-disable no-nested-ternary */
import React, {
  useEffect,
} from 'react';
import { Helmet } from 'react-helmet';
import { Spin, message } from 'antd';
import Layout from '@/components/Layout';
import { Trend } from '@/base-components/topic-metric';
import { MostVocalSegmentsCard } from '@/components/dashboard/MostVocalSegmentsCard';
import { SentimentDriversCard } from '@/components/sentiment-drivers-card';
import moment from 'moment';
import customerIo from '@/customer-io';
import { SentimentDateFilter } from '@/components/ui/sentiment-date-filter';
import Card from '@/components/card';
import { StatsCard } from '@/components/ui/stats-card';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import { useDashboard } from '@/data/useDashboard';
import { TrendingCard } from '@/components/trending-card';
import { KeyInsightsCard } from '@/components/key-insights-card';
import { SourcesStats } from '@/components/sources/SourcesStats';
import { prefetchSources } from '@/data/useSources';
import { ConnectPopup } from '@/components/connect-popup';
import { SentimentOverviewCard } from '@/components/sentiment-overview-card';
import { EvolutionCard } from '@/components/evolution-card';
import useTopicsCounts from '@/components/bucket/hooks/useTopicsCounts';
import { hasGorgias, hasIntercom, hasZendesk } from '@/helpers/features';
import { auth } from '@/api';

export default function DashboardPage() {
  const { defaultDate } = useDefaultDateStore();
  const isWeek = moment(defaultDate.end).diff(moment(defaultDate.start), 'months') >= 3 ? 1 : 0;

  prefetchSources();
  const user = auth.me();
  const {
    data, refetch: refechDashboard, isLoading, isError, error,
  } = useDashboard({
    start: defaultDate.start,
    end: defaultDate.end,
    week: isWeek,
  });

  const counts = useTopicsCounts(null, {
    start: defaultDate.start,
    end: defaultDate.end,
  });

  useEffect(() => {
    if (!isError) return;
    message.error(error.message);
  }, [error]);

  useEffect(() => {
    if (import.meta.env.VITE_CUSTOMERIO_SITEID) {
      customerIo.track('dashboard-loaded');
    }
  }, []);

  if (isLoading) {
    return (
      <Layout rightHeader={(<SentimentDateFilter />)}>
        <Helmet titleTemplate="%s - ClientZen" title="Home" />
        <div className="mx-auto max-w-[1200px]">
          <div className="pt-24 text-center">
            <Spin size="large" />
          </div>
        </div>
      </Layout>
    );
  }

  let neutral = data
    ? parseInt(data.sentiment_counts.total, 10)
  - parseInt(data.sentiment_counts.positive, 10)
  - parseInt(data.sentiment_counts.negative, 10)
    : 0;
  let previousNeutral = data
    ? parseInt(data.previous_sentiment_counts.total, 10)
  - parseInt(data.previous_sentiment_counts.positive, 10)
  - parseInt(data.previous_sentiment_counts.negative, 10)
    : 0;
  if (!neutral) {
    neutral = 0;
  }
  if (!previousNeutral) {
    previousNeutral = 0;
  }

  const feedback = {
    tooltip: 'All feedback coming from all sources.',
    value: data.sentiment_counts.total === null ? 0 : parseInt(data.sentiment_counts.total, 10),
    trend: data.sentiment_counts.total > data.previous_sentiment_counts ? Trend.ASC : Trend.DESC,
    trendValue: data.sentiment_counts.total && data.previous_sentiment_counts.total
      ? parseInt(data.sentiment_counts.total, 10) > parseInt(data.previous_sentiment_counts.total, 10)
        ? parseInt(data.sentiment_counts.total, 10) - parseInt(data.previous_sentiment_counts.total, 10)
        : parseInt(data.previous_sentiment_counts.total, 10) - parseInt(data.sentiment_counts.total, 10)
      : 0,
    link: '/discovery',
  };

  const cleanedProps = {
    tooltip: 'All feedback minus the excluded feedback',
    title: 'Cleaned feedback',
    value: counts.data?.cleaned_value === null ? 0 : parseInt(counts.data?.cleaned_value, 10),
    trend: counts.data?.cleaned_trend ?? Trend.ASC,
    trendValue: counts.data?.cleaned_value && counts.data?.cleaned_value_previous
      ? parseInt(counts.data?.cleaned_value, 10) > parseInt(counts.data?.cleaned_value_previous, 10)
        ? parseInt(counts.data?.cleaned_value, 10) - parseInt(counts.data?.cleaned_value_previous, 10)
        : parseInt(counts.data?.cleaned_value_previous, 10) - parseInt(counts.data?.cleaned_value, 10)
      : 0,
    link: '/discovery',
  };

  const ticketsProps = {
    tooltip: 'The number of tickets that were submitted.',
    title: 'Tickets',
    value: counts.data?.tickets_value === null ? 0 : parseInt(counts.data?.tickets_value, 10),
    trend: counts.data?.tickets_trend ?? Trend.ASC,
    trendValue: counts.data?.tickets_value && counts.data?.tickets_value_previous
      ? parseInt(counts.data?.tickets_value, 10) > parseInt(counts.data?.tickets_value_previous, 10)
        ? parseInt(counts.data?.tickets_value, 10) - parseInt(counts.data?.tickets_value_previous, 10)
        : parseInt(counts.data?.tickets_value_previous, 10) - parseInt(counts.data?.tickets_value, 10)
      : 0,
    link: '/discovery',
  };

  return (
    <Layout rightHeader={(<SentimentDateFilter />)}>
      <Helmet titleTemplate="%s - ClientZen" title="Home" />
      <div className="mx-7 2xl:mx-auto 2xl:max-w-[1200px] new-dashboard-page">
        <ConnectPopup className="mb-4" />
        <Card className="py-4 mb-4 grid grid-cols-2">
          <div className="flex gap-12 divide-y-0 divide-x divide-solid divide-[#CEDBE4]">
            <StatsCard {...feedback} />
            {!!cleanedProps.value && feedback.value !== cleanedProps.value ? (
              <StatsCard className="pl-3" {...cleanedProps} />
            ) : null}

            {hasZendesk(user) || hasIntercom(user) || hasGorgias(user) ? (
              <StatsCard className="pl-3" {...ticketsProps} />
            ) : null}
          </div>
          <SourcesStats />
        </Card>
        <section className="grid grid-cols-2 gap-4">
          <KeyInsightsCard />
          <TrendingCard topics={data.most_discussed_topics} total={data.sentiment_counts.total} />
        </section>
        <SentimentDriversCard sentiments={data.sentiment_drivers} />
        <section className="grid grid-cols-2 gap-4">
          <SentimentOverviewCard overview={data?.sentiment_overview} refetch={refechDashboard} />
          <EvolutionCard />
        </section>
        {data.most_vocal_segments.length ? (
          <MostVocalSegmentsCard data={data.most_vocal_segments} total={data.sentiment_counts.total} />
        ) : null}

        {data.top_issues.length || data.top_requests.length ? (
          <div className="new-dashboard-container-columns">
            {data.top_issues.length ? (
              <div className="column">
                <MostVocalSegmentsCard
                  name="Top Issues"
                  data={data.top_issues}
                  total={data.sentiment_counts.total}
                />
              </div>
            ) : null}
            {data.top_requests.length ? (
              <div className="column">
                <MostVocalSegmentsCard
                  name="Top Requests"
                  data={data.top_requests}
                  total={data.sentiment_counts.total}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </Layout>
  );
}
