import { Button, Upload, message } from 'antd';
import { UploadIcon } from 'lucide-react';
import React, {
  useContext,
} from 'react';
import * as Papa from 'papaparse';
import { PlatformContext } from '../context';

export const uploadProps = {
  name: 'file',
  multiple: false,
  maxCount: 1,
  showUploadList: false,
  accept: '.csv',
  beforeUpload() {
    return false;
  },
};

export function CSVContainer() {
  const { setFile, setModal } = useContext(PlatformContext);

  const handleUpload = (info) => {
    setFile(info.file);

    Papa.parse(info.file, {
      complete: async (results) => {
        if (results.data.length - 1 > 500 || await import.meta.env.MODE === 'development') {
          setModal(true);
          return;
        }

        message.error('The file must contain at least 500 conversations.');
      },
    });
  };

  return (
    <>
      <Upload
        {...uploadProps}
        className="mt-4 block [&>*]:w-full"
        onChange={handleUpload}
      >
        <Button icon={<UploadIcon size={18} />} type="primary" size="large" className="w-full flex items-center justify-center gap-2">
          Upload CSV File
        </Button>
      </Upload>
      <p className="m-0 mt-4">
        Note: To generate relevant insights, we need a minimum of 500 conversations.
      </p>
    </>
  );
}
