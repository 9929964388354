import React, { useState } from 'react';
import { Alert, message } from 'antd';
import { auth } from '@/api';
import { displayOrCatchMessage } from '../../helpers';
import GhostButton from '../GhostButton';

const resendEmailConfirmation = (email, setLoading) => () => {
  setLoading(true);
  auth.resendVerifyEmail({
    email,
  }).then(() => {
    message.success('Email resent.');
    setLoading(false);
  }).catch((e) => {
    displayOrCatchMessage(e);
    setLoading(false);
  });
};

const cancelChange = (setClosed) => () => {
  setClosed(true);
};

const EmailChangeAlert = ({ email, closed, setClosed }) => {
  const [loading, setLoading] = useState(false);

  return (
    closed ? null : (
      <Alert
        message="Email Change"
        style={{ marginBottom: 15 }}
        description={(
          <div className="cz-alert">
            <p>
              {'Check your email '}
              <span>{email}</span>
              {' to confirm your new address — the change won\'t take effect until confirmed.'}
            </p>
            <ul>
              <li style={{ paddingBottom: 15 }}>
                {'Don\'t see a confirmation email? '}
                <GhostButton loading={loading} onClick={resendEmailConfirmation(email, setLoading)} type="primary">
                  Resend email confirmation
                </GhostButton>
              </li>
              <li>
                {'Don\'t want to change your email? '}
                <GhostButton onClick={cancelChange(setClosed)} type="primary">Cancel this change</GhostButton>
              </li>
            </ul>
          </div>
      )}
        type="warning"
        showIcon
      />
    )
  );
};

export default EmailChangeAlert;
