import React from 'react';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

const MatchTooltip = () => (
  <Tooltip title={(
    <>
      <div style={{ marginBottom: 7 }}>
        <strong>Extended match</strong>
        : includes synonyms and derivatives
      </div>
      <div style={{ marginBottom: 7 }}>
        <strong>Broad match</strong>
        : includes derivatives
      </div>
      <div>
        <strong>Exact match</strong>
        : only the exact words
      </div>
    </>
    )}
  >
    <QuestionCircleOutlined />
  </Tooltip>
);

export default MatchTooltip;
