import React, { useState } from 'react';
import { Dropdown, Menu, Modal } from 'antd';
import { EllipsisOutlined, EditOutlined, InboxOutlined } from '@ant-design/icons';
import { Redirect } from 'react-router-dom';
import { colleagues } from '@/api';
import GhostButton from '../GhostButton';
import ColleagueModal from './ColleagueModal';
import { displayOrCatchMessage, loadListIfAvailable } from '../../helpers';

const onBeginEdit = (setIsEditVisible) => () => {
  setIsEditVisible(true);
};

const onBeginArchive = (
  loadList, setRedirect, client,
) => () => colleagues.destroy(client).then(() => {
  loadList();
  setRedirect(true);
}).catch((e) => {
  displayOrCatchMessage(e);
});

const onArchive = (loadList, setRedirect, client) => () => {
  Modal.confirm({
    title: `Are you sure you want to archive your team member ${client.name}?`,
    content: 'Archiving your team members does not delete the data and insights that were gathered. '
            + 'You can re-activate your team members by adding them later.',
    okText: 'Archive',
    cancelText: 'Cancel',
    okType: 'primary',
    cancelType: 'default',
    onOk: onBeginArchive(loadList, setRedirect, client),
    okButtonProps: {
      danger: true,
    },
  });
};

const menu = (loadList, setIsEditVisible, setRedirect, client) => (
  <Menu>
    <Menu.Item>
      <GhostButton onClick={onBeginEdit(setIsEditVisible)} type="primary" style={{ color: '#595959', fontWeight: 'normal' }}>
        <EditOutlined />
        Edit Team Member
      </GhostButton>
    </Menu.Item>
    <Menu.Item>
      <GhostButton danger onClick={onArchive(loadList, setRedirect, client)} type="primary" style={{ fontWeight: 'normal' }}>
        <InboxOutlined />
        Archive Team Member
      </GhostButton>
    </Menu.Item>
  </Menu>
);

const setShouldRedirect = (redirectsToClients, setValue) => (value) => {
  if (redirectsToClients) {
    setValue(value);
  }
};

const ColleagueDropdown = ({ loadList, client, redirectsToClients }) => {
  const [isEditVisible, setIsEditVisible] = useState(false);
  const [redirect, setRedirect] = useState(false);

  return (
    <>
      {
        redirect ? (
          <Redirect push to="/team-members" />
        ) : null
      }
      <ColleagueModal
        client={client}
        loadList={loadList}
        setVisible={setIsEditVisible}
        visible={isEditVisible}
      />
      <Dropdown overlay={menu(loadListIfAvailable(loadList), setIsEditVisible, setShouldRedirect(redirectsToClients, setRedirect), client)} trigger={['click']}>
        <GhostButton type="primary"><EllipsisOutlined style={{ fontSize: 24 }} /></GhostButton>
      </Dropdown>
    </>
  );
};

export default ColleagueDropdown;
