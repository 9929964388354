import React, {
  useState, useRef, useEffect, useMemo,
} from 'react';
import { BuildOutlined, LeftOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button, Empty, Input, Menu, Spin, DatePicker,
} from 'antd';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { customFields } from '@/api';
import { improveLabel } from '@/components/filter/filters/CustomFieldFilter';
import { displayOrCatchMessage } from '../../../helpers';
import { showLabel } from './SelectedCustomField';

const CustomFieldMenuItem = ({
  onSelectValue, onClick, name, id, dataType, ...props
}) => {
  const [search, setSearch] = useState('');
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [currentHeight, setCurrentHeight] = useState(undefined);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const wrapRef = useRef(null);
  const fetchRef = useRef(0);
  const searchRef = useRef(null);
  const fetch = useMemo(() => {
    const load = (value) => {
      setLoading(true);
      setItems([]);

      fetchRef.current += 1;
      const fetchId = fetchRef.current;

      customFields.getCustomFieldValues({
        id,
        search: value,
      }).then(({ data: result }) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setItems(result);
        setLoading(false);
      }).catch((e) => {
        displayOrCatchMessage(e);
      });
    };

    return debounce(load, 300);
  }, []);

  const onItemClick = (e, ...rest) => {
    if (!active) {
      setActive(true);
      try {
        const scrollNode = wrapRef.current.parentNode.parentNode;
        setCurrentHeight(scrollNode.scrollHeight);
        scrollNode.scrollTo(0, 0);
      } catch {
        // ignore
      }
    } else {
      try {
        const { target } = e.domEvent;
        if (target.className === 'menu-custom-list-item') {
          onSelectValue(target.getAttribute('data-value'));
          onClick(e, ...rest);
        }
      } catch {
        // ignore
      }
    }
  };

  const onDateAdd = (e) => {
    if (startDate && endDate) {
      onSelectValue(JSON.stringify(
        { start: startDate.startOf('day'), end: endDate.startOf('day') },
      ));
      setCurrentHeight(undefined);
      setActive(false);
      setStartDate(undefined);
      setEndDate(undefined);
      onClick(e);
    }
  };

  const onBack = (e) => {
    e.stopPropagation();
    setCurrentHeight(undefined);
    setActive(false);
  };

  useEffect(() => {
    if (active) {
      fetch(search);
    }
  }, [active, search]);

  useEffect(() => {
    if (searchRef && searchRef.current) {
      searchRef.current.focus();
    }
  }, [active]);

  return (
    <Menu.Item {...props} onClick={onItemClick}>
      <div style={{ minHeight: currentHeight }} ref={wrapRef} className={classNames('menu-item-wrap', { active })}>
        {
          !active ? (
            <div className="menu-custom-simple-header">
              <BuildOutlined />
              <span className="text">
                {name}
              </span>
            </div>
          ) : (
            <>
              <div className="menu-custom-header">
                <Button type="link" onClick={onBack}><LeftOutlined /></Button>
                <div className="menu-custom-header-label">
                  <BuildOutlined />
                  <span className="text">
                    {name}
                  </span>
                </div>
              </div>
              {
                dataType === 'date' ? (
                  <div className="menu-custom-date">
                    <div>Between</div>
                    <div>
                      <DatePicker
                        onChange={(date) => setStartDate(date)}
                      />
                    </div>
                    <div className="menu-custom-date-and">AND</div>
                    <div>
                      <DatePicker
                        onChange={(date) => setEndDate(date)}
                      />
                    </div>
                    <div>
                      <Button
                        onClick={onDateAdd}
                        className="menu-custom-date-add"
                        type="primary"
                        size="large"
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="menu-custom-search">
                      <Input
                        prefix={<SearchOutlined />}
                        placeholder="Search field"
                        className="menu-custom-input"
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                        ref={searchRef}
                      />
                    </div>
                    <div className="menu-custom-list">
                      {
                        loading ? (
                          <div style={{ textAlign: 'center', paddingTop: 40 }}>
                            <Spin size="medium" />
                          </div>
                        ) : null
                      }
                      {
                        (!loading && !items.length) ? (
                          <div style={{ width: 150, margin: '0 auto' }}>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
                          </div>
                        ) : null
                      }
                      {
                        (!loading && items.length) ? items.map((item) => (
                          <div className="menu-custom-list-item" key={item.value} data-value={item.value}>
                            {improveLabel(showLabel(item.value))}
                          </div>
                        )) : null
                      }
                    </div>
                  </>
                )
              }
            </>
          )
        }
      </div>
    </Menu.Item>
  );
};

export default CustomFieldMenuItem;
