import React, { useState } from 'react';
import { Form as AntdForm } from 'antd';

const onValidatedFormFinishFailed = (setTouched, onFinishFailed) => (...args) => {
  setTouched(true);
  if (onFinishFailed) {
    onFinishFailed(...args);
  }
};

const ValidatedForm = ({
  validateTrigger, onFinishFailed, initialValues, children, ...props
}) => {
  const [touched, setTouched] = useState(false);

  return (
    <AntdForm
      initialValues={initialValues || { remember: true }}
      validateTrigger={touched ? validateTrigger || 'onChange' : 'onSubmit'}
      onFinishFailed={onValidatedFormFinishFailed(setTouched, onFinishFailed)}
      {...props}
    >
      {children}
    </AntdForm>
  );
};

export default ValidatedForm;
