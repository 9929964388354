import React from 'react';
import { Affix as AntdAffix } from 'antd';

const Affix = ({
  children,
  subheader,
  offsetTop,
  ...rest
}) => (
  <AntdAffix offsetTop={subheader ? 92 : offsetTop} {...rest}>
    {subheader ? (
      <div className="affix-subheader">
        {children}
      </div>
    ) : children}
  </AntdAffix>
);

export default Affix;
