import React, { useState, useCallback, useEffect } from 'react';
import {
  Button, Col, Row, Spin,
} from 'antd';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { clients, recentInteractions } from '@/api';
import OnboardingColumnLayout from '../../components/onboarding/OnboardingColumnLayout';
import LogoColumn from '../../components/LogoColumn';
import Steps from '../../components/onboarding/Steps';
import SearchPossibleClients from '../../components/onboarding/SearchPossibleClients';
import ClientsNumber from '../../components/onboarding/ClientsNumber';
import RecentInteractionsGrid from '../../components/onboarding/RecentInteractions/Grid';
import RecentInteractions from '../../components/onboarding/RecentInteractions/RecentInteractions';
import cleanFirstClientsAction from '../../store/actions/cleanFirstClients';
import userAuthenticatedAction from '../../store/actions/userAuthenticated';
import { displayOrCatchMessage } from '../../helpers';
import { userAddedOneClient } from '../../helpers/features';
import recentInteractionsLoadedAction from '../../store/actions/recentInteractionsLoaded';

const AddFirstClient = ({
  user,
  firstClients,
  cleanFirstClients,
  userAuthenticated,
  recentInteractionsLoaded,
}) => {
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingRecents, setLoadingRecents] = useState(true);
  const renderSecondColumn = useCallback(
    () => (
      <div className="graphic-container graphic-container-clients">
        {loadingRecents || loading ? (
          <div style={{ textAlign: 'center', margin: 30 }}>
            <Spin size="large" />
          </div>
        ) : (
          <>
            <SearchPossibleClients tags />
            <RecentInteractionsGrid />
          </>
        )}
      </div>
    ),
    [loading, loadingRecents],
  );
  const onDone = useCallback(() => {
    setLoading(true);
    clients
      .storeBatch({
        clients: firstClients.map(({ company, ...firstClient }) => ({
          ...firstClient,
          company_name: company,
        })),
      })
      .then(() => {
        cleanFirstClients();
        userAddedOneClient(user, userAuthenticated);
        setReady(true);
      })
      .catch((e) => {
        displayOrCatchMessage(e);
      });
  }, [firstClients]);

  useEffect(() => {
    recentInteractions
      .index(undefined, undefined, undefined, 12)
      .then((response) => {
        recentInteractionsLoaded(response.data.data);
        setLoadingRecents(false);
      })
      .catch((e) => {
        displayOrCatchMessage(e);
        recentInteractionsLoaded([]);
        setLoadingRecents(false);
      });
  }, []);

  return ready ? (
    <Redirect push to="/add-team" />
  ) : (
    <OnboardingColumnLayout secondColumn={renderSecondColumn()}>
      <Helmet>
        <title>Get started with ClientZen</title>
      </Helmet>
      <LogoColumn className="connect-add-clients" title="Add your clients">
        <div className="onboarding-description">
          <h4>
            Search for a client or add them from your recent interactions list.
          </h4>
        </div>

        <Row className="platform-row">
          <Col lg={0} md={24} sm={24} xs={24}>
            {loadingRecents || loading ? (
              <div style={{ textAlign: 'center', margin: 30 }}>
                <Spin size="large" />
              </div>
            ) : (
              <>
                <SearchPossibleClients />
                <RecentInteractions mobile />
              </>
            )}
          </Col>
        </Row>

        <div className="mb-lg connect-add-clients-done">
          <Button
            className="cta-fixed-w full-cta-mobile full-cta-tablet"
            size="large"
            type="primary"
            disabled={!user.has_clients && !firstClients.length}
            loading={loading}
            onClick={onDone}
          >
            Done
          </Button>
          <ClientsNumber />
        </div>
      </LogoColumn>
      <Steps current={1} />
    </OnboardingColumnLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  firstClients: state.firstClients,
});

const mapDispatchToProps = (dispatch) => ({
  cleanFirstClients: (data) => dispatch(cleanFirstClientsAction(data)),
  userAuthenticated: (data) => dispatch(userAuthenticatedAction(data)),
  recentInteractionsLoaded: (data) => dispatch(recentInteractionsLoadedAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddFirstClient);
