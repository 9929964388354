import React, { useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import deleteAccessTokenAndUserData from '@/api/deleteAccessTokenAndUserData';
import { useQueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import ErrorBoundaryRoute from '../ErrorBoundaryRoute';

const RedirectWithStoreHistory = withRouter(
  ({ location: { pathname, search } }) => {
    const [url, setUrl] = useState();

    useEffect(() => {
      localStorage.setItem('last_login_route', pathname + search);
      setUrl('/login');
    }, []);

    if (url) {
      return <Redirect push to={url} />;
    }

    return null;
  },
);

const ProtectedRoute = ({ children, user, ...props }) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!user) {
      try {
        queryClient.getQueryCache().clear();
      } catch (e) {
        Sentry.captureException(e);
      }
      deleteAccessTokenAndUserData();
    }
  }, []);

  return (
    user ? (
      <ErrorBoundaryRoute {...props}>
        {children}
      </ErrorBoundaryRoute>
    ) : <RedirectWithStoreHistory />
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(ProtectedRoute);
