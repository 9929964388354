import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import InsightDescriptionWithModal from '../InsightDescriptionWithModal';
import TimelineItemStatusContent from './TimelineItemStatusContent';

const InsightTimelineItem = ({
  loadList, onClientList, insightShown, isColleague, ...item
}) => {
  const [currentItem, setCurrentItem] = useState(item);

  useEffect(() => {
    if (insightShown && (insightShown.key === currentItem.id)) {
      if (!(
        (
          currentItem.marked_as_not_useful === false
            && currentItem.marked_as_resolved === false
            && insightShown.marked_as_helpful === null)
            || (
              currentItem.marked_as_not_useful === true
            && currentItem.marked_as_resolved === false
            && insightShown.marked_as_helpful === false)
            || (
              currentItem.marked_as_not_useful === false
            && currentItem.marked_as_resolved === true
            && insightShown.marked_as_helpful === true)
      )) {
        setCurrentItem({
          ...currentItem,
          marked_as_not_useful: insightShown.marked_as_helpful === false,
          marked_as_resolved: insightShown.marked_as_helpful === true,
          item: {
            ...currentItem.item,
            marked_as_helpful: insightShown.marked_as_helpful,
            marked_as_helpful_at: insightShown.marked_as_helpful_at,
            marked_as_helpful_by: insightShown.marked_as_helpful_by,
          },
        });
      }
    }
  }, [insightShown, currentItem]);

  return (
    <div className="TimelineItem">
      <InsightDescriptionWithModal
        id={item.id}
        text={item.text}
        item={currentItem.item}
        bgColor={item.background_color}
        feedbackType={item.feedback_type}
        className="TimelineItem__Description"
        loadList={loadList}
        onClientList={onClientList}
        isColleague={isColleague}
      />
      <div className="TimelineItem__Details">
        <div className="TimelineItem__Status">
          <TimelineItemStatusContent {...currentItem} isColleague={isColleague} />
        </div>
        <p className="TimelineItem__Date">{item.created}</p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  insightShown: state.insight.item,
});

export default connect(mapStateToProps, null)(InsightTimelineItem);
