import React, { useCallback, useState } from 'react';
import { Button, Modal } from 'antd';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clients } from '@/api';
import SearchPossibleClients from '../onboarding/SearchPossibleClients';
import { displayOrCatchMessage } from '../../helpers';
import cleanFirstClientsAction from '../../store/actions/cleanFirstClients';

const ClientAddModal = ({
  loadList,
  setVisible,
  visible,
  firstClients,
  cleanFirstClients,
  redirectsBackToClients,
  ...props
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const onCancel = useCallback(() => {
    setVisible(false);
  }, []);
  const onOk = useCallback(() => {
    setLoading(true);
    clients
      .storeBatch({
        clients: firstClients.map(({ company, ...firstClient }) => ({
          ...firstClient,
          company_name: company,
        })),
      })
      .then(() => {
        if (loadList) {
          loadList(1);
        }
        setLoading(false);
        setVisible(false);
        if (redirectsBackToClients) {
          history.push('/clients');
        }
      })
      .catch((e) => {
        setLoading(false);
        displayOrCatchMessage(e);
      });
  }, [firstClients]);

  return (
    <Modal
      title="Add new clients"
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={onOk}>
          Save
        </Button>,
      ]}
      visible={visible}
      afterClose={cleanFirstClients}
      {...props}
    >
      <div className="client-add-modal-body">
        <SearchPossibleClients tags />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  firstClients: state.firstClients,
});

const mapDispatchToProps = (dispatch) => ({
  cleanFirstClients: (data) => dispatch(cleanFirstClientsAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientAddModal);
