import React from 'react';

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="265"
      height="207.131"
      viewBox="0 0 265 207.131"
    >
      <g transform="translate(-749 -255)">
        <g fill="none" data-name="Union 2">
          <path
            d="M4708.661 2993h-3.907l.216-.229a34 34 0 013.969-67.764 34.341 34.341 0 014.185.255v-.255a53.918 53.918 0 0199.643-28.677 32.077 32.077 0 0136.549-9.027 46.041 46.041 0 1190.414-16.234l.271-.086V2993h-231.34z"
            transform="translate(-3926 -2531)"
          />
          <path
            fill="#707070"
            d="M4708.938 2992l.267-.001H4939v-119.65l-.134.043-.132-1.216c-1.19-10.981-6.375-21.124-14.598-28.56a44.857 44.857 0 00-13.724-8.516 44.753 44.753 0 00-16.438-3.1 44.673 44.673 0 00-17.525 3.544 44.865 44.865 0 00-14.313 9.666 44.955 44.955 0 00-9.65 14.338 44.884 44.884 0 00-3.539 17.557c0 3.709.45 7.395 1.338 10.955l.476 1.91-1.824-.741a30.863 30.863 0 00-11.683-2.275c-9.153 0-17.8 4.017-23.725 11.021l-.884 1.046-.727-1.16a53.34 53.34 0 00-18.853-18.044 52.605 52.605 0 00-12.34-5.048 53.002 53.002 0 00-13.672-1.786c-7.146 0-14.077 1.402-20.602 4.166a52.747 52.747 0 00-16.825 11.363 52.856 52.856 0 00-11.344 16.855c-2.76 6.536-4.159 13.48-4.159 20.64v1.384l-1.122-.137a33.471 33.471 0 00-4.063-.248c-4.446 0-8.76.872-12.82 2.592a32.824 32.824 0 00-10.47 7.072 32.886 32.886 0 00-7.06 10.489 32.842 32.842 0 00-2.588 12.844 32.97 32.97 0 008.403 22.015c5.376 6.008 12.722 9.83 20.682 10.759l1.907.222h1.946m0 1h-4.184l.216-.23c-16.872-1.968-29.97-16.335-29.97-33.766 0-18.776 15.195-33.997 33.938-33.997 1.418 0 2.814.087 4.185.255v-.255c0-29.836 24.146-54.023 53.93-54.023 19.267 0 36.176 10.122 45.713 25.346 5.884-6.957 14.672-11.375 24.488-11.375 4.267 0 8.337.834 12.06 2.348a46.241 46.241 0 01-1.367-11.197c0-25.463 20.608-46.105 46.027-46.105 23.72 0 43.25 17.974 45.755 41.068l.271-.086v122.017H4708.938z"
            transform="translate(-3926 -2531)"
          />
        </g>
        <g
          stroke="#707070"
          strokeWidth="1"
          data-name="Group 460"
          transform="translate(-11.506)"
        >
          <g fill="#fafafa" data-name="Rectangle 1835">
            <path
              stroke="none"
              d="M22 0h3a22 22 0 0122 22v101H0V22A22 22 0 0122 0z"
              transform="translate(872.506 292)"
            />
            <path
              fill="none"
              d="M22 .5h3A21.5 21.5 0 0146.5 22v100a.5.5 0 01-.5.5H1a.5.5 0 01-.5-.5V22A21.5 21.5 0 0122 .5z"
              transform="translate(872.506 292)"
            />
          </g>
          <g fill="#fafafa" data-name="Rectangle 1836">
            <path
              stroke="none"
              d="M13 0a13 13 0 0113 13v81H0V13A13 13 0 0113 0z"
              transform="translate(917.506 321)"
            />
            <path
              fill="none"
              d="M13 .5h0A12.5 12.5 0 0125.5 13v80.209a.291.291 0 01-.291.291H.791a.291.291 0 01-.291-.291V13A12.5 12.5 0 0113 .5z"
              transform="translate(917.506 321)"
            />
          </g>
          <g fill="#fafafa" data-name="Rectangle 1837">
            <path
              stroke="none"
              d="M12.5 0A12.5 12.5 0 0125 12.5V94H0V12.5A12.5 12.5 0 0112.5 0z"
              transform="translate(848.506 321)"
            />
            <path
              fill="none"
              d="M12.5.5h0a12 12 0 0112 12v80.721a.279.279 0 01-.279.279H.779a.279.279 0 01-.279-.279V12.5a12 12 0 0112-12z"
              transform="translate(848.506 321)"
            />
          </g>
          <path
            fill="#fafafa"
            d="M873 369.353l-35.409 45.117H873z"
            data-name="Path 420"
          />
          <path
            fill="#fafafa"
            d="M918.506 369.353l35.409 45.117h-35.409z"
            data-name="Path 422"
          />
          <path
            fill="none"
            d="M0 0L24.413 0"
            data-name="Line 144"
            transform="translate(848.587 344.709)"
          />
          <path
            fill="none"
            d="M0 0L24.413 0"
            data-name="Line 151"
            transform="translate(918.496 344.709)"
          />
          <path
            fill="none"
            d="M0 0L24.413 0"
            data-name="Line 145"
            transform="translate(848.587 353.587)"
          />
          <path
            fill="none"
            d="M0 0L24.413 0"
            data-name="Line 150"
            transform="translate(918.496 353.587)"
          />
          <path
            fill="none"
            d="M0 0L45 0"
            data-name="Line 146"
            transform="translate(873.506 395)"
          />
          <path
            fill="none"
            d="M0 0L45 0"
            data-name="Line 148"
            transform="translate(873.506 403)"
          />
          <path
            fill="none"
            d="M0 0L12.206 0"
            data-name="Line 147"
            transform="translate(889.645 311.974)"
          />
          <path
            fill="none"
            d="M0 0L25.522 0"
            data-name="Line 149"
            transform="translate(882.987 318.632)"
          />
          <g
            fill="#fafafa"
            data-name="Ellipse 282"
            transform="translate(883.506 344)"
          >
            <ellipse
              cx="12"
              cy="12.5"
              stroke="none"
              rx="12"
              ry="12.5"
            />
            <ellipse cx="12" cy="12.5" fill="none" rx="11.5" ry="12" />
          </g>
          <path
            fill="none"
            d="M885.535 414.471l-7.774 12.266h34.573l-7.094-12.266z"
            data-name="Path 421"
          />
        </g>
        <g
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
          data-name="Ellipse 283"
          transform="translate(816 320)"
        >
          <circle cx="3.5" cy="3.5" r="3.5" stroke="none" />
          <circle cx="3.5" cy="3.5" r="3" fill="none" />
        </g>
        <g
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
          data-name="Ellipse 284"
          transform="translate(958 265)"
        >
          <circle cx="3.5" cy="3.5" r="3.5" stroke="none" />
          <circle cx="3.5" cy="3.5" r="3" fill="none" />
        </g>
        <g
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
          data-name="Ellipse 285"
          transform="translate(911 255)"
        >
          <circle cx="3.5" cy="3.5" r="3.5" stroke="none" />
          <circle cx="3.5" cy="3.5" r="3" fill="none" />
        </g>
        <g
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
          data-name="Ellipse 290"
          transform="translate(942 290)"
        >
          <circle cx="3.5" cy="3.5" r="3.5" stroke="none" />
          <circle cx="3.5" cy="3.5" r="3" fill="none" />
        </g>
        <g
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
          data-name="Ellipse 286"
          transform="translate(840 292)"
        >
          <circle cx="1" cy="1" r="1" stroke="none" />
          <circle cx="1" cy="1" r="0.5" fill="none" />
        </g>
        <g
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
          data-name="Ellipse 287"
          transform="translate(996 283)"
        >
          <circle cx="1" cy="1" r="1" stroke="none" />
          <circle cx="1" cy="1" r="0.5" fill="none" />
        </g>
        <g
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
          data-name="Ellipse 288"
          transform="translate(914 294)"
        >
          <circle cx="1" cy="1" r="1" stroke="none" />
          <circle cx="1" cy="1" r="0.5" fill="none" />
        </g>
        <g
          fill="#fff"
          stroke="#707070"
          strokeWidth="1"
          data-name="Ellipse 289"
          transform="translate(882 268)"
        >
          <circle cx="1" cy="1" r="1" stroke="none" />
          <circle cx="1" cy="1" r="0.5" fill="none" />
        </g>
        <path
          fill="none"
          stroke="#707070"
          strokeWidth="1"
          d="M877.053 426.737l-8.284 34.895h31.047l-8.987-34.895z"
          data-name="Path 423"
        />
      </g>
    </svg>
  );
}

export default Icon;
