import { Menu } from 'antd';
import { getPlatformIcon } from '@/components/discovery/utils/sources';
import React from 'react';
import './SourceItem.styles.less';

export default function SourceItem({
  sources, source, handleClick, ...props
}) {
  const title = source.charAt(0).toUpperCase() + source.slice(1);
  const filteredSources = sources[source].filter((item) => item.conversations_count > 0);

  if (source === 'intercom' || source === 'zendesk') {
    const item = filteredSources[0];
    return (
      <Menu.Item
        {...props}
        onClick={() => handleClick(item, source)}
        className="flex items-center min-w-[10rem] !mt-0 !mb-0 hover:text-[#44596C] hover:bg-[#EFF4F8] rounded-[4px]"
        icon={getPlatformIcon({ type: source })}
      >
        {title}
      </Menu.Item>
    );
  }
  return (
    <Menu.SubMenu {...props} popupOffset={[2, -12]} className="source-filter-menu-item [&>div]:flex [&>div]:items-center min-w-[10rem] [&_.ant-dropdown-menu-submenu-title]:!text-[#202324]" popupClassName="max-h-96 overflow-y-scroll !shadow-[0px_1px_12px_#CAD7E180]" icon={getPlatformIcon({ type: source })} title={title}>
      {filteredSources.map((item) => (
        <Menu.Item onClick={() => handleClick(item, source)} key={item.id} className="space-x-1 !text-[#44596C] hover:bg-[#EFF4F8] !mx-1 rounded-[4px]">
          <span>{item.name ? item.name : item.id}</span>
          <span>
            (
            {item.conversations_count}
            {' '}
            rows)
          </span>
        </Menu.Item>
      ))}
    </Menu.SubMenu>
  );
}
