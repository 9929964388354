import React from 'react';
import { Card } from 'antd';
import SentimentAvg from '@/base-components/sentiment-avg';

const SentimentTooltipContent = (data) => {
  if (!Array.isArray(data?.payload) || data.payload.length === 0) {
    return null;
  }

  const [{
    payload: {
      total, ratio, annotation, hide, date,
    },
  }] = data.payload;

  if (hide) {
    return annotation && !data.showAnnotation && (
      <Card className="sentiment-chart-tooltip">
        <div>
          {annotation}
        </div>
      </Card>
    );
  }

  return (
    <Card className="sentiment-chart-tooltip">
      <span className="sentiment-chart-tooltip-date">{date}</span>
      Count:
      <span className="sentiment-chart-tooltip-title">{total}</span>
      <div className="sentiment-chart-tooltip-info">
        <SentimentAvg
          sentiment="negative"
          value={ratio.negative}
          styles={{ root: { margin: 0 }, avg: { margin: 0 }, icon: { margin: '0px 6px 0px 2px' } }}
        />
        <SentimentAvg
          sentiment="neutral"
          value={ratio.neutral}
          styles={{ root: { margin: 0 }, avg: { margin: 0 }, icon: { margin: '0px 6px 0px 2px' } }}
        />
        <SentimentAvg
          sentiment="positive"
          value={ratio.positive}
          styles={{ root: { margin: 0 }, avg: { margin: 0 }, icon: { margin: '0px 6px 0px 2px' } }}
        />
      </div>
      <div>
        {annotation}
      </div>
    </Card>
  );
};

export default SentimentTooltipContent;
