import React, { useRef, useState } from 'react';
import {
  TeamOutlined,
  LeftOutlined,
  RightOutlined,
  InboxOutlined,
} from '@ant-design/icons';
import {
  Button,
  Carousel,
  Card,
} from 'antd';

// components
import { getTopMoverData } from '@/transformers/clients';
import TopMover from './TopMover';
import TopMoverEmpty from './TopMoverEmpty';
import SectionHeader from '../SectionHeader';

const onNextPress = (carouselRef) => () => {
  carouselRef.current.next();
};

const onPrevPress = (carouselRef) => () => {
  carouselRef.current.prev();
};

const onChange = (setCarouselIndex) => (currentIndex) => {
  setCarouselIndex(currentIndex);
};

const generateSeries = (index, max = 6) => {
  const series = [];
  for (let i = 0; i < (max - index); i++) {
    series.push(i);
  }

  return series;
};

const renderTopMover = (isColleague) => (data) => (
  <TopMover
    key={data.id}
    isColleague={isColleague}
    {...data}
  />
);

const TopMovers = ({ trends, title, isColleague }) => {
  const carouselRef = useRef(null);
  const [carouselIndex, setCarouselIndex] = useState(0);

  const page1 = trends.slice(0, 4).map(getTopMoverData);
  const page2 = trends.slice(4, 8).map(getTopMoverData);
  const page3 = trends.slice(8, 12).map(getTopMoverData);
  const missingPage1 = generateSeries(page1.length, 4);
  const missingPage2 = generateSeries(page2.length, 4);
  const missingPage3 = generateSeries(page3.length, 4);

  return (
    <div className="dashboard-section">
      <div className="header flex-between">
        <SectionHeader
          icon={<TeamOutlined />}
          title={title}
        />
        {
          page2.length ? (
            <div className="slider-buttons">
              <Button
                disabled={carouselIndex === 0}
                shape="circle"
                icon={<LeftOutlined />}
                size={32}
                style={{ marginRight: '8px' }}
                onClick={onPrevPress(carouselRef)}
              />
              <Button
                disabled={
                  (carouselIndex === 1 && !page3.length) || (carouselIndex === 2 && page3.length)
                }
                shape="circle"
                icon={<RightOutlined />}
                size={32}
                onClick={onNextPress(carouselRef)}
              />
            </div>
          ) : null
        }
      </div>
      <Card bordered={false}>
        <Carousel
          dots={false}
          ref={(ref) => { carouselRef.current = ref; }}
          afterChange={onChange(setCarouselIndex)}
        >
          <div className="TopMovers">
            {page1.map(renderTopMover(isColleague))}
            {
              !page1.length ? (
                <div className="top-movers-no-content">
                  <InboxOutlined className="mr-xs" />
                  <span>No significant happiness score changes lately</span>
                </div>
              ) : null
            }
            {
              page1.length ? missingPage1.map((value) => (
                <TopMoverEmpty key={value} isColleague={isColleague} />
              )) : null
            }
          </div>
          <div className="TopMovers">
            {page2.map(renderTopMover(isColleague))}
            {
              missingPage2.map((value) => (<TopMoverEmpty key={value} isColleague={isColleague} />))
            }
          </div>
          <div className="TopMovers">
            {page3.map(renderTopMover(isColleague))}
            {
              missingPage3.map((value) => (<TopMoverEmpty key={value} isColleague={isColleague} />))
            }
          </div>
        </Carousel>
      </Card>
    </div>
  );
};

export default TopMovers;
