import React, { useCallback } from 'react';
import { Layout as AntdLayout } from 'antd';
import { cn } from '@/helpers/util';
import { motion } from 'framer-motion';
import { Link, useHistory } from 'react-router-dom';
import { OnboardingContext, OnboardingProvider } from './context';

export function Logo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <defs>
        <linearGradient id="linear-gradient" x1="0.127" y1="0.878" x2="0.81" y2="0.137" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#0baa6b" />
          <stop offset="1" stopColor="#1e92d6" />
        </linearGradient>
      </defs>
      <g id="logo-clientzen2" transform="translate(-29.22 -27.289)">
        <rect id="Rectangle_3215" data-name="Rectangle 3215" width="32" height="32" transform="translate(29.22 27.289)" fill="none" />
        <path id="Path_1323" data-name="Path 1323" d="M-1979.72,28.008a8.022,8.022,0,0,1-4.748,1.507,7.749,7.749,0,0,1-2.093-.281,4.543,4.543,0,0,1-3.354-3.263,3.224,3.224,0,0,1,.6-2.742,3.64,3.64,0,0,1,2.925-1.322c2.252,0,4.372,1.79,5.921,3.1l.13.111c.074.063.148.13.219.194.13.118.266.24.4.338l.621-.533c1.57-1.354,3.719-3.208,6.052-3.208a3.64,3.64,0,0,1,2.925,1.322,3.181,3.181,0,0,1,.6,2.741,4.545,4.545,0,0,1-3.355,3.264,7.859,7.859,0,0,1-2.093.281A8.205,8.205,0,0,1-1979.72,28.008Zm-8.09-3.6a1.288,1.288,0,0,0-.239,1.139A2.646,2.646,0,0,0-1986,27.4a5.527,5.527,0,0,0,1.581.221,6.346,6.346,0,0,0,3.156-.832c-.041-.032-.078-.064-.115-.1-.052-.046-.107-.093-.167-.138-1.271-1.1-3.187-2.752-4.811-2.752A1.767,1.767,0,0,0-1987.81,24.407Zm10.119,2.035-.135.114c-.063.047-.118.094-.17.139l-.114.1a6.542,6.542,0,0,0,3.164.825,5.369,5.369,0,0,0,1.542-.216,2.689,2.689,0,0,0,2.045-1.857,1.5,1.5,0,0,0-.211-1.14,1.792,1.792,0,0,0-1.448-.6C-1974.561,23.806-1976.37,25.33-1977.692,26.442Zm.916-7.435a4.611,4.611,0,0,0-2.915-1.478,4.557,4.557,0,0,0-2.849,1.422l-.068.056a5.576,5.576,0,0,1-3.569,1.718,3.676,3.676,0,0,1-3.333-1.8.965.965,0,0,1,.426-1.278.98.98,0,0,1,.438-.1.924.924,0,0,1,.839.5l0,.006a1.759,1.759,0,0,0,1.628.772,3.8,3.8,0,0,0,2.249-1.173l.109-.088a6.184,6.184,0,0,1,4.127-1.9,6.184,6.184,0,0,1,4.127,1.9l.029.023a4.049,4.049,0,0,0,2.328,1.237,1.757,1.757,0,0,0,1.628-.772l0-.006a.924.924,0,0,1,.839-.5.98.98,0,0,1,.438.1.994.994,0,0,1,.427,1.274,3.676,3.676,0,0,1-3.335,1.8A5.578,5.578,0,0,1-1976.775,19.007Zm-6.409-8.513A3.5,3.5,0,0,1-1979.69,7a3.5,3.5,0,0,1,3.494,3.494,3.5,3.5,0,0,1-3.494,3.495A3.5,3.5,0,0,1-1983.184,10.494Zm1.9,0a1.6,1.6,0,0,0,1.595,1.595,1.6,1.6,0,0,0,1.595-1.595A1.6,1.6,0,0,0-1979.69,8.9,1.6,1.6,0,0,0-1981.284,10.494Z" transform="translate(2024.938 25.288)" fill="url(#linear-gradient)" />
      </g>
    </svg>
  );
}

function Header({ className }) {
  const { step, lastStep } = React.useContext(OnboardingContext);
  return (
    <AntdLayout.Header className={cn('flex items-center justify-between !bg-white !h-auto !leading-none !m-0 !p-3 !pr-7', className)}>
      <Link to="/">
        <Logo />
      </Link>
      <span className="text-xs text-[#75899B] font-[Gordita-Regular]">
        step
        {' '}
        {step}
        {' '}
        /
        {' '}
        {lastStep}
      </span>
    </AntdLayout.Header>
  );
}

function Content({ children, className }) {
  return (
    <AntdLayout.Content className={cn('!mt-12 !max-w-lg', className)}>
      {children}
    </AntdLayout.Content>
  );
}

export function OnboardingLayout({ children }) {
  const [step, setStepNumber] = React.useState(1);
  const [lastStep, setLastStep] = React.useState(3);
  const history = useHistory();

  const setStep = useCallback((s) => {
    switch (s) {
      case 2: history.push('/onboarding/activate');
        break;
      case 3: history.push('/onboarding/processing');
        break;
      default: break;
    }
    setStepNumber(s);
  }, []);

  return (
    <OnboardingProvider value={{
      step, setStep, lastStep, setLastStep,
    }}
    >
      <AntdLayout className="!bg-white !min-h-screen !min-w-fit">
        <motion.div
          animate={{ width: `${(step * 100) / lastStep}%` }}
          transition={{ duration: 0.7 }}
          className="h-1 bg-gradient-to-r from-[#0BAA6B] to-[#1E92D6]"
          style={{ width: `${(step * 100) / lastStep}%` }}
        />

        {children}
      </AntdLayout>
    </OnboardingProvider>
  );
}

OnboardingLayout.Header = Header;
OnboardingLayout.Content = Content;
