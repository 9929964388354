import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '@/components/Layout';
import BaseIntegrations from '@/base-components/integrations';

const Integrations = () => (
  <Layout>
    <Helmet>
      <title>ClientZen - Platforms</title>
    </Helmet>
    <BaseIntegrations />
  </Layout>
);

export default Integrations;
