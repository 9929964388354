/* eslint-disable */
import React from 'react';
import Popover from '@/base-components/popover';
import Progress from '@/components/ui/progress';
import SentimentAvg from '@/base-components/sentiment-avg';

const SentimentColumn = ({ positive, negative }) => (
	<Popover
		content={
			<div>
				<p style={{ margin: 0, fontSize: 12 }}>Sentiment over the selected period</p>
				<div style={{ display: 'flex' }}>
					<SentimentAvg
						sentiment='negative'
						value={Math.round(negative * 100)}
						styles={{ root: { margin: 0 }, avg: { margin: 0 }, icon: { margin: '0px 6px 0px 2px' } }}
					/>
					<SentimentAvg
						sentiment='positive'
						value={Math.round(positive * 100)}
						styles={{ root: { margin: 0 }, avg: { margin: 0 }, icon: { margin: '0px 6px 0px 2px' } }}
					/>
					<SentimentAvg
						sentiment='neutral'
						// eslint-disable-next-line max-len
						value={Math.round((1 - (positive + negative)) * 100)}
						styles={{ root: { margin: 0 }, avg: { margin: 0 }, icon: { margin: '0px 6px 0px 2px' } }}
					/>
				</div>
			</div>
		}
	>
		<div>
			<Progress.Sentiment positive={positive} negative={negative} />
		</div>
	</Popover>
);

export default SentimentColumn;
