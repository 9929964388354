import React from 'react';

function Title({ value = 'Analyzing your data' }) {
  return (
    <h1 className="m-0 font-[Gordita-Medium] text-lg text-[#44596C]">{value}</h1>
  );
}

function Description({ children }) {
  return (
    <p className="m-0 flex gap-2 items-center mt-5 text-[#75899B]">{children}</p>
  );
}

function InsightsContainer({ children }) {
  return (
    <section className="flex flex-col items-center font-[Gordita-Regular]">{children}</section>
  );
}

export default InsightsContainer;
InsightsContainer.Title = Title;
InsightsContainer.Description = Description;
