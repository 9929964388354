import { topics } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { transformCustomFieldToGet } from '@/api/conversations';

export default function useTopicCounts(id, filters, onlyCount) {
  let newFilters = filters;
  if (filters?.custom_fields) {
    newFilters = {
      ...filters,
      ...transformCustomFieldToGet(filters.custom_fields),
    };
  }
  if (filters?.sources) {
    newFilters = {
      ...newFilters,
      sources: JSON.stringify(filters.sources),
    };
  }
  if (filters?.nps) {
    newFilters = {
      ...newFilters,
      nps: JSON.stringify(filters.nps),
    };
  }
  return useQuery({
    queryKey: ['topic_counts', id, { newFilters }],
    queryFn: async () => {
      const [response] = await Promise.all([topics.getCounts(id, newFilters)]);
      if (onlyCount) {
        return response.tickets_value;
      }
      return response;
    },
    refetchOnWindowFocus: !!newFilters,
  });
}
