import React from 'react';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => (
  <>
    <Helmet>
      <title>ClientZen - Privacy Policy</title>
    </Helmet>
    <h2>Privacy Policy</h2>
  </>
);

export default PrivacyPolicy;
