import axios from './axios';

const key = () => axios.get('/api/billing/key').then((response) => response).catch((e) => Promise.reject(e));

const createIntent = () => axios.post('/api/billing/intent').then((response) => response).catch((e) => Promise.reject(e));

const subscribe = (paymentMethodId, choosenPlan) => axios.post('/api/billing/subscribe', { payment_method_id: paymentMethodId, choosen_plan: choosenPlan }).then((response) => response).catch((e) => Promise.reject(e));

const isSubscribed = () => axios.get('/api/billing/is-subscribed').then((response) => response.data.data).catch((e) => Promise.reject(e));

const cancel = () => axios.post('/api/billing/cancel').then((response) => response).catch((e) => Promise.reject(e));

const resume = () => axios.post('/api/billing/resume').then((response) => response).catch((e) => Promise.reject(e));

const billing = {
  key,
  createIntent,
  subscribe,
  isSubscribed,
  cancel,
  resume,
};

export default billing;
