import React from 'react';
import { Form, Input, DatePicker } from 'antd';
import PopoutForm from '../../forms/PopoutForm';

const { Item } = Form;

const FormAnnotation = ({
  form, defaultValues, error, onSubmit,
}) => (
  <PopoutForm
    layout="vertical"
    form={form}
    popoutError={error}
    onFinish={onSubmit}
    initialValues={defaultValues}
  >
    <Item
      key="label"
      name="label"
      label="Annotation name"
      rules={[{ required: true, message: 'Annotation name is required' }]}
    >
      <Input
        size="large"
        placeholder="e.g. Feature update"
      />
    </Item>
    <Item
      key="date"
      name="date"
      label="Date"
      rules={[{ required: true, message: 'Annotation date is required' }]}
    >
      <DatePicker size="large" style={{ width: '100%' }} />
    </Item>
  </PopoutForm>
);

export default FormAnnotation;
