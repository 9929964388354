import React, { useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { Redirect } from 'react-router-dom';
import { insights } from '@/api';
import { displayOrCatchMessage, loadListIfAvailable } from '../../helpers';
import GhostButton from '../GhostButton';

const onBeginArchive = (loadList, setRedirect, insight) => () => insights
  .destroy(insight.key).then(() => {
    loadList();
    setRedirect(true);
  }).catch((e) => {
    displayOrCatchMessage(e);
  });

const onArchive = (loadList, onClick, setRedirect, insight) => (...params) => {
  Modal.confirm({
    title: 'Are you sure you want to archive your insight?',
    okText: 'Archive',
    cancelText: 'Cancel',
    okType: 'primary',
    cancelType: 'default',
    onOk: onBeginArchive(loadList, setRedirect, insight),
    icon: <InboxOutlined />,
    okButtonProps: {
      danger: true,
    },
  });

  if (onClick) {
    onClick(...params);
  }
};

const setShouldRedirect = (redirectsToInsights, setValue) => (value) => {
  if (redirectsToInsights) {
    setValue(value);
  }
};

const InsightArchive = ({
  loadList, style, onClick, children, redirectsToInsights, insight, ...props
}) => {
  const [redirect, setRedirect] = useState(false);
  const onPreparedClick = onArchive(
    loadListIfAvailable(loadList),
    onClick,
    setShouldRedirect(redirectsToInsights, setRedirect),
    insight,
  );

  return (
    <>
      {
        redirect ? (
          <Redirect push to="/insights" />
        ) : null
      }
      <GhostButton
        type="primary"
        {...props}
        onClick={onPreparedClick}
        style={{ color: '#595959', fontWeight: 'normal', ...style }}
      >
        {
          children || (
            <>
              <InboxOutlined />
              {' '}
              Archive insight
            </>
          )
        }
      </GhostButton>
    </>
  );
};

export default InsightArchive;
