/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useSnapCarousel } from 'react-snap-carousel';
import classNames from 'classnames';
import {
  Button, message, Modal, Tooltip, Skeleton,
} from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { subtopics } from '@/api';
import SubtopicActions from './topic-dashboard/components/SubtopicActions';
import { useBoolean } from '../hooks/useBoolean';
import ModalTopicForm from './TopicForm/ModalTopicForm';
import {
  modals,
  SET_MODAL_ACTION,
} from './topic-dashboard/topicDashboardReducer';
import { ReactComponent as AISubtopicIcon } from './svgs/subtopic-ai.svg';

const CarouselItem = ({
  isSnapPoint,
  item,
  dispatch,
  topicName,
  topicId,
  topicGroupId,
  fadedOut,
  highlighted,
  onClick,
  ai,
}) => {
  const {
    value: visible,
    setFalse: setHidden,
    setTrue: setVisible,
  } = useBoolean();
  const history = useHistory();
  const { pathname } = window.location;

  const onUpdate = () => {
    setVisible();
  };

  const onDelete = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete subtopic?',
      okText: 'Delete',
      cancelText: 'Cancel',
      okType: 'primary',
      cancelType: 'default',
      onOk: () => {
        message.loading('Subtopic is deleting');

        subtopics
          .deleteSubtopic(item.id, ai)
          .then(() => {
            message.success('Subtopic deleted successfully');
            dispatch({
              type: SET_MODAL_ACTION,
              payload: { modal: modals.NONE, pageReload: true },
            });
            history.push(
              `${
                pathname.startsWith('/demo') ? '/demo/' : '/'
              }topic/overview/${topicId}/${topicGroupId}`,
            );
          })
          .catch(() => {
            message.error('Failed deleting subtopic');
          });
      },
      okButtonProps: {
        danger: true,
      },
    });
  };

  const subtopicModalProps = {
    subtopic: true,
    visible,
    data: item,
    onCancel: setHidden,
    onOk: () => {
      setHidden();
      dispatch({
        type: SET_MODAL_ACTION,
        payload: { modal: modals.NONE, pageReload: true },
      });
    },
  };

  return (
    <li className={classNames('item', { 'item-snap-point': isSnapPoint })}>
      <div
        className={classNames('item-content', {
          'item-content-faded-out': fadedOut,
          'item-content-highlighted': highlighted,
        })}
      >
        <Tooltip
          title={
            item.id === 'loading' ? 'Generating subtopics by MANTRA' : item.name
          }
        >
          <span
            style={{ display: 'flex', alignItems: 'center' }}
            className="name"
            onClick={item.id !== 'loading' ? onClick : null}
          >
            <span
              style={{
                maxWidth: 200,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {item.id === 'loading' ? (
                <Skeleton.Button
                  style={{ width: 100 }}
                  active
                  size="small"
                  block
                />
              ) : (
                item.name
              )}
            </span>
            {(ai || item.id === 'loading') && (
              <span className="anticon" style={{ marginLeft: 10 }}>
                <AISubtopicIcon />
              </span>
            )}
          </span>
        </Tooltip>
        {item.count !== undefined ? (
          <span className="count">{item.count.value}</span>
        ) : null}
        {item.id !== 'loading' && (
          <SubtopicActions
            onDelete={onDelete}
            onUpdate={onUpdate}
            disabled={fadedOut}
            ai={ai}
          />
        )}
      </div>
      <ModalTopicForm
        {...subtopicModalProps}
        topicName={topicName}
        topicId={topicId}
      />
    </li>
  );
};

const SubtopicsCarousel = ({
  subtopicsList,
  dispatch,
  topicName,
  topicId,
  topicGroupId,
  subtopicId,
}) => {
  const {
    scrollRef, pages, activePageIndex, prev, next, snapPointIndexes,
  } = useSnapCarousel();
  const highlighted = subtopicsList.find((item) => `${item.id}` === subtopicId) || null;
  const history = useHistory();
  const { pathname } = window.location;

  const onClick = (item) => {
    if (highlighted !== null && highlighted.id === item.id) {
      history.push(
        `${
          pathname.startsWith('/demo') ? '/demo/' : '/'
        }topic/overview/${topicId}/${topicGroupId}`,
      );
    } else {
      history.push(
        `${
          pathname.startsWith('/demo') ? '/demo/' : '/'
        }topic/overview/${topicId}/${topicGroupId}/${item.id}`,
      );
    }
  };

  return (
    <div className="subtopics-carousel">
      <div>
        <Button
          className="subtopics-carousel-button-left"
          type="link"
          onClick={() => prev()}
          disabled={activePageIndex <= 0}
        >
          <LeftOutlined />
        </Button>
      </div>
      <div className="track">
        <ul className="scroll" ref={scrollRef}>
          {subtopicsList.map((item, i) => (
            <CarouselItem
              key={item.id}
              item={item}
              isSnapPoint={snapPointIndexes.has(i)}
              dispatch={dispatch}
              topicName={topicName}
              topicId={topicId}
              topicGroupId={topicGroupId}
              fadedOut={highlighted !== null && highlighted.id !== item.id}
              highlighted={highlighted !== null && highlighted.id === item.id}
              onClick={() => onClick(item)}
              ai={item.type === 'ai_model_based'}
            />
          ))}
        </ul>
      </div>
      <div>
        <Button
          className="subtopics-carousel-button-right"
          type="link"
          onClick={() => next()}
          disabled={activePageIndex === pages.length - 1}
        >
          <RightOutlined />
        </Button>
      </div>
    </div>
  );
};

export default SubtopicsCarousel;
