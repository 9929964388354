import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { customFields } from '@/api';
import TableTransfer from './TableTransfer';
import { displayOrCatchMessage } from '../../helpers';

function ucfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const leftTableColumns = [
  {
    dataIndex: 'name',
    title: 'Field',
  },
  {
    dataIndex: 'type',
    title: 'Type',
    render: (type) => ucfirst(type),
  },
  {
    dataIndex: 'example',
    title: 'Example',
  },
];
const rightTableColumns = [
  {
    dataIndex: 'name',
    title: 'Field',
  },
  {
    dataIndex: 'type',
    title: 'Type',
    render: (type) => ucfirst(type),
  },
  {
    dataIndex: 'example',
    title: 'Example',
  },
];

const CustomFieldsTab = () => {
  const [data, setData] = useState([]);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [platformsIncluded, setPlatformsIncluded] = useState('');
  const onChange = (nextTargetKeys) => {
    setSaving(true);
    customFields.updateAdded(nextTargetKeys).then(() => {
      setSaving(false);
    }).catch((e) => {
      displayOrCatchMessage(e);
    });
    setTargetKeys(nextTargetKeys);
  };
  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };
  const filterOption = (inputValue, option) => option.name
    .toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

  useEffect(() => {
    customFields.getCustomFields().then(({ data: result }) => {
      setData(result);
      setPlatformsIncluded(`in ${[...new Set(result.map((item) => ucfirst(item.platform_type)))].join(' / ')}`);
      setTargetKeys(result.filter((item) => item.added).map((item) => item.key));
      setLoading(false);
    }).catch((e) => {
      displayOrCatchMessage(e);
    });
  }, []);

  return (
    <div className="settings-tab settings-tab-custom-fields">
      <div className="tab-title">
        <h2>Add custom fields to ClientZen</h2>
        {
          saving && <Spin size="small" />
        }
      </div>
      {
        loading ? (
          <div style={{ textAlign: 'center', margin: 30 }}>
            <Spin size="large" />
          </div>
        ) : (
          <TableTransfer
            style={{ paddingTop: 12 }}
            loading={saving}
            dataSource={data}
            titles={[`${'Available '}${platformsIncluded}`, 'Added in Clientzen']}
            targetKeys={targetKeys}
            selectedKeys={selectedKeys}
            onChange={onChange}
            onSelectChange={onSelectChange}
            render={(item) => item.name}
            oneWay
            showSearch
            filterOption={filterOption}
            leftColumns={leftTableColumns}
            rightColumns={rightTableColumns}
          />
        )
      }
    </div>
  );
};

export default CustomFieldsTab;
