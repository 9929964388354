import React from 'react';
import {
  Layout, Grid, Drawer,
} from 'antd';
import LogoSmall from '@/images/logo-small.svg';
import LogoText from '@/images/logo-text.svg';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useResponsiveSiderStore } from '@/store/zustand/useResponsiveSiderStore';
import Menu from './Menu';
import LogoBeta from './svgs/LogoBeta';
import { screenIsSmOrXs } from '../helpers';

const { Sider } = Layout;
const { useBreakpoint } = Grid;

const ResponsiveSider = ({ drawerVisible, setDrawerVisible }) => {
  const { collapsed, setCollapsed } = useResponsiveSiderStore((state) => state);
  const screens = useBreakpoint();
  if (screenIsSmOrXs(screens)) {
    return (
      <Drawer
        className="cz-drawer-sider"
        title={<LogoBeta style={{ width: 136 }} />}
        placement="left"
        closable={false}
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        width={280}
      >
        <Sider
          theme="light"
          className="cz-sider"
          width={260}
        >
          <Menu screens={screens} />
        </Sider>
      </Drawer>
    );
  }

  return (
    <Sider
      theme="light"
      width={280}
      collapsed={collapsed}
      onCollapse={setCollapsed}
      className="cz-sider group"
      style={{
        position: 'fixed',
        height: '100vh',
        left: 0,
        top: 0,
        zIndex: 1000,
      }}
    >
      <div className="mb-5 w-full flex items-center justify-between">
        <Link to="/">
          <div className="ml-1">
            <img src={LogoSmall} alt="logo" />
            {collapsed ? null : (
              <img className="ml-1 mt-px" src={LogoText} alt="logo" />
            )}
          </div>
        </Link>
        <button
          onClick={() => setCollapsed(!collapsed)}
          type="button"
          className="relative opacity-0 group-hover:opacity-100 bg-[#DFE9F1] h-[22px] w-[14px] border-none z-30 rounded-3xl cursor-pointer transition-opacity"
        >
          {collapsed
            ? <ChevronRightIcon className="absolute top-[4.5px] left-0" size={12} strokeWidth={1.25} />
            : <ChevronLeftIcon className="absolute top-[4.5px] left-0" size={12} strokeWidth={1.25} />}
        </button>
      </div>
      <Menu collapsed={collapsed} screens={screens} />
    </Sider>
  );
};

export default ResponsiveSider;
