import * as React from 'react';
import { memo } from 'react';

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} viewBox="0 0 200 200" fill="none" {...props}>
    <g id="Gorgias source">
      <g id="Gorgias svg" clipPath="url(#clip0_1088_2378)">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.265 155H150.016C151.516 155 152.867 153.653 152.867 152.156V80.0204C152.867 72.9864 147.166 67.1497 139.965 67.1497H131.413C129.913 67.1497 128.563 65.8027 128.563 64.3061V57.8707C128.713 50.6871 123.012 45 115.811 45H60.9021C53.7009 45 48 50.6871 48 57.8707V130.306C48 137.34 53.7009 143.027 60.9021 143.177H124.512C125.112 143.177 125.712 143.327 126.162 143.626L141.765 154.551C142.215 154.85 142.815 155 143.265 155ZM127.813 132.85H61.0521C59.4018 132.85 58.2016 131.653 58.2016 130.007V58.0204C58.2016 56.5238 59.5518 55.1769 61.0521 55.1769H115.661C117.161 55.1769 118.511 56.5238 118.511 58.0204V64.4558C118.511 65.9524 117.161 67.2993 115.661 67.2993H75.0043C73.5041 67.2993 72.1538 68.6463 72.1538 70.1429V118.184C72.1538 119.68 73.5041 121.027 75.0043 121.027H125.862C127.363 121.027 128.713 119.68 128.713 118.184V80.1701C128.713 78.6735 130.063 77.3265 131.563 77.3265H139.965C141.465 77.3265 142.815 78.6735 142.815 80.1701V139.136C142.815 140.633 141.165 141.381 140.115 140.633L129.463 133.299C129.013 133 128.413 132.85 127.813 132.85ZM117.011 110.701H83.7057C82.9556 110.701 82.3555 110.102 82.3555 109.354V78.6735C82.3555 77.9252 82.9556 77.3265 83.7057 77.3265H117.011C117.761 77.3265 118.361 77.9252 118.361 78.6735V109.204C118.361 109.952 117.911 110.701 117.011 110.701Z"
          fill="#161616"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_1088_2378">
        <rect width="104.867" height="110" fill="white" transform="translate(48 45)" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
