import React from 'react';
import { Popover as AntdPopover } from 'antd';

const Popover = ({ children, ...props }) => (
  <AntdPopover
    placement="bottom"
    trigger="hover"
    {...props}
    overlayClassName="cz-popover"
  >
    {children}
  </AntdPopover>
);

export default Popover;
