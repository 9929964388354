import React, { useEffect, useRef, useState } from 'react';
import {
  BulbOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Carousel,
} from 'antd';
import dashboard from '../../../data/dashboard';

// components
import HighlightedCard from './HighlightedCard';
import SectionHeader from '../SectionHeader';
import { getDashboardHighlightedData } from '../../../transformers/insights';
import GatheringDataEmpty from '../../GatheringDataEmpty';

const onNextPress = (carouselRef) => () => {
  carouselRef.current.next();
};

const onPrevPress = (carouselRef) => () => {
  carouselRef.current.prev();
};

const onChange = (setCarouselIndex) => (currentIndex) => {
  setCarouselIndex(currentIndex);
};

const generateSeries = (index, max = 6) => {
  const series = [];
  for (let i = 0; i < (max - index); i++) {
    series.push(i);
  }

  return series;
};

const renderHighlightedInsight = (isColleague) => (dashboardData) => (
  <HighlightedCard
    id={dashboardData.id}
    title={dashboardData.title}
    description={dashboardData.description}
    percentage={dashboardData.percentage}
    clientHappiness={dashboardData.clientHappiness}
    avatar={dashboardData.avatar}
    unhappinessReasons={dashboardData.unhappinessReasons}
    client={dashboardData.client}
    backgroundColor={dashboardData.backgroundColor}
    feedbackType={dashboardData.feedbackType}
    item={dashboardData.item}
    clientId={dashboardData.client.key}
    isColleague={isColleague}
  />

);

const HighlightedInsightsSection = ({
  insights, loading, isColleague, title,
}) => {
  const carouselRef = useRef(null);
  const [carouselIndex, setCarouselIndex] = useState(0);

  const page1 = insights
    ? insights.slice(0, 4).map(getDashboardHighlightedData)
    : dashboard.highlightedInsights.page1;
  const page2 = insights
    ? insights.slice(4, 8).map(getDashboardHighlightedData)
    : dashboard.highlightedInsights.page2;
  const missingPage1 = generateSeries(page1.length, 4);
  const missingPage2 = generateSeries(page2.length, 4);

  useEffect(() => {
    if (loading) {
      carouselRef.current.goTo(0);
      setCarouselIndex(0);
    }
  }, [loading]);

  return (
    <div className="highlighted-insights dashboard-section">
      <div className="header flex-between">
        <SectionHeader
          icon={<BulbOutlined />}
          title={title}
        />
        {
          page2.length ? (
            <div className="slider-buttons">
              <Button
                disabled={carouselIndex === 0}
                shape="circle"
                icon={<LeftOutlined />}
                size={32}
                style={{ marginRight: '8px' }}
                onClick={onPrevPress(carouselRef)}
              />
              <Button
                disabled={carouselIndex === 1}
                shape="circle"
                icon={<RightOutlined />}
                size={32}
                onClick={onNextPress(carouselRef)}
              />
            </div>
          ) : null
        }
      </div>
      <Card bordered={false}>
        <Carousel
          dots={false}
          ref={(ref) => { carouselRef.current = ref; }}
          afterChange={onChange(setCarouselIndex)}
        >

          <div className="highlighted-insights-container">
            {page1.map(renderHighlightedInsight(isColleague))}
            {
              !page1.length ? (
                <Card style={{ flex: 1 }} bordered={false}>
                  <GatheringDataEmpty />
                </Card>
              ) : null
            }
            {
              page1.length ? missingPage1.map((value) => (<div key={value} className="highlighted-card highlighted-card-empty" />)) : null
            }
          </div>

          <div className="highlighted-insights-container">
            {page2.map(renderHighlightedInsight(isColleague))}
            {
              missingPage2.map((value) => (<div style={{ cursor: 'initial' }} key={value} className="highlighted-card" />))
            }
          </div>
        </Carousel>
      </Card>
    </div>
  );
};

export default HighlightedInsightsSection;
