import React from 'react';

const OnboardingColumnLayout = ({ children, secondColumn }) => (
  <div className="full-height-view register-container onboarding-layout">
    <div className="onboarding-right-column">
      {children}
    </div>
    <div className="onboarding-left-column">
      {secondColumn}
    </div>
  </div>
);

export default OnboardingColumnLayout;
