import * as React from 'react';

function ErrorBackground(props, svgRef) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={445.02}
      height={496.488}
      viewBox="0 0 445.02 496.488"
      ref={svgRef}
      {...props}
    >
      <defs>
        <style>{'.prefix__a{fill:#f2f3f3}'}</style>
      </defs>
      <path
        className="prefix__a"
        d="M250.419 168.113c46.2 0 84.063-37.867 84.063-84.064a84.064 84.064 0 00-168.128 0c0 46.197 37.864 84.064 84.065 84.064zm0-124.2a40.4 40.4 0 0140.135 40.139v.006a40.139 40.139 0 11-40.135-40.142zM408.661 288.617c-20.448 0-37.867-14.389-58.315-31.051-25.75-21.963-55.286-46.2-99.968-46.2s-74.213 24.238-99.961 46.2c-20.448 16.661-37.11 31.051-58.315 31.051-28.779 0-40.139-17.419-40.9-19.691-5.3-10.6-18.933-14.389-29.536-9.088a22.417 22.417 0 00-9.844 29.543c8.331 15.9 34.081 43.168 80.277 43.168 36.353 0 62.859-21.963 86.336-41.653 21.963-18.179 43.172-36.355 71.952-36.355s49.984 17.419 71.946 36.352c23.477 19.691 49.984 41.653 86.336 41.653a101.667 101.667 0 0036.351-6.414v-51.1c-5.718 5.836-17.159 13.585-36.359 13.585zM408.805 496.488h36.215v-23.982c-9.531 12.509-23.435 19.868-36.215 23.982zM265.561 441.826l-15.9 13.632c-5.3-3.787-10.6-9.088-15.9-13.632-38.632-32.565-90.888-78.005-146.93-78.005-28.78 0-54.528 11.36-70.432 31.808-14.386 18.176-19.688 41.652-14.386 65.892a99.773 99.773 0 0015.875 34.967h73.8c-18.8-6.472-40.175-18.774-46.509-44.819-2.273-10.6-.758-20.448 6.058-28.779 7.573-9.845 20.448-15.147 36.352-15.147 39.382 0 84.822 38.624 118.145 67.4 3.029 2.272 5.3 4.544 8.331 6.816a147.407 147.407 0 01-33.183 14.529H320.54a150.542 150.542 0 01-33.772-14.519c3.028-2.272 5.3-4.544 8.33-6.816 33.323-28.021 78.764-67.4 118.145-67.4 12.466 0 23.9 3.6 31.777 10.357v-49.014a100.021 100.021 0 00-32.533-5.275c-56.042 0-108.3 44.683-146.926 78.005z"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(ErrorBackground);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
