/* eslint-disable no-underscore-dangle */
import * as Sentry from '@sentry/react';
import { inProduction } from '@/flags';

export default {
  identify: (data) => {
    if (inProduction() && window._cio) {
      window._cio.identify(data);
    }
  },

  track: (event, data) => {
    if (inProduction() && window._cio) {
      window._cio.track(event, data);
    }
  },

  page: (referrer) => {
    if (inProduction() && window._cio) {
      try {
        window._cio.page(document.location.href, {
          width: window.innerWidth,
          height: window.innerHeight,
          ...(
            referrer ? {
              referrer,
            } : {}
          ),
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  },
};
