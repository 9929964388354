import React from 'react';
import has from 'lodash/has';
import Progress from '@/components/ui/progress';
import CategoryDelete from '@/components/categories/CategoryDelete';
import TopicMetric from '../../base-components/topic-metric';
import CategoryTableCell from './CategoryTableCell';
import Tag from '../../base-components/Tag';
import { calculateDifference } from '../Painpoints/helpers';
import SentimentTooltip from '../../base-components/SentimentTooltip';
import { SET_EXPANDED_KEYS_ACTION } from './categoriesReducer';

export const isParent = (record) => has(record, 'hasChildren');

export const getColumns = (
  expandedIds,
  isTable,
  history,
  expandedTableRows,
  setExpandedTableRows,
  dispatch,
) => [
  {
    title: isTable ? 'Topic' : 'Category',
    dataIndex: 'name',
    key: 'name',
    render: (name, record) => {
      const { isCategory } = record;
      return (
        <CategoryTableCell {...record}>
          {isCategory
            ? (
              <div className="flex items-center">
                <div>
                  <Tag
                    color={record.color}
                    className="column-clickable category-table-label"
                    onClick={() => {
                      const isExpanded = expandedTableRows.includes(record.id);
                      setExpandedTableRows(
                        isExpanded
                          ? expandedTableRows.filter((item) => item !== record.id)
                          : [...expandedTableRows, record.id],
                      );
                      dispatch({
                        type: SET_EXPANDED_KEYS_ACTION,
                        payload: { id: record.id, expanded: !isExpanded },
                      });
                    }}
                  >
                    {record.name}
                  </Tag>
                </div>
                {
                  !record.has_children ? (
                    <CategoryDelete dispatch={dispatch} record={record} />
                  ) : null
                }
              </div>
            )
            : <span className="gordita-bold-1 column-clickable">{record.name}</span>}
        </CategoryTableCell>
      );
    },
    onCell: (record) => ({
      onClick: () => {
        if (!record.isCategory) {
          history.push(`${window.location.pathname.startsWith('/demo') ? '/demo/' : '/'}topic/overview/${record.id}/${record.groupId}`);
        }
      },
    }),
  },
  {
    title: 'Conversations',
    dataIndex: 'count',
    key: 'count',
    render: (name, record) => {
      const { count = {} } = record;
      const difference = calculateDifference(count.value, count.previous_value);
      return (
        <CategoryTableCell {...record}>
          <TopicMetric
            value={count.value}
            trend={count.trend}
            difference={difference}
            tooltip="Total feedback in selected interval"
          />
        </CategoryTableCell>
      );
    },
  },
  {
    title: 'Ratio',
    key: 'Ratio',
    render: (row = {}) => {
      const { ratio = {} } = row;
      return (
        <CategoryTableCell {...row}>
          <TopicMetric
            value={`${Math.ceil(ratio.value * 100)}%`}
            trend={ratio.trend}
            tooltip="The ratio of feedback compared to the total in the selected period"
          />
        </CategoryTableCell>
      );
    },
  },
  {
    title: 'MRR',
    dataIndex: 'mrr',
    key: 'mrr',
    render: (name, record) => {
      const { mrr = {} } = record;
      return (
        <CategoryTableCell {...record}>
          <TopicMetric.MRR
            value={mrr.value}
            trend={mrr.trend}
            tooltip="Monthly recurring revenue from users talking about this painpoint"
          />
        </CategoryTableCell>
      );
    },
  },
  {
    title: 'Sentiment',
    dataIndex: 'sentiment',
    key: 'sentiment',
    render: (name, record) => {
      const { sentiment = {} } = record;

      return (
        <CategoryTableCell {...record}>
          <SentimentTooltip sentiment={sentiment} style={{ display: 'block' }}>
            <Progress.Sentiment
              positive={sentiment.positive}
              negative={sentiment.negative}
            />
          </SentimentTooltip>
        </CategoryTableCell>
      );
    },
  },
];
