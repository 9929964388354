import React from 'react';
import classNames from 'classnames';

function DiscoveryIcon({ disabled, className }) {
  return (
    <span className={classNames('anticon', className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="0.849"
            x2="-0.253"
            y1="0.185"
            y2="1.098"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#36b2e9" />
            <stop offset="1" stopColor="#1fe04b" />
          </linearGradient>
        </defs>
        <path fill="none" d="M0 0H18V18H0z" data-name="Rectangle 2630" />
        <g data-name="Group 2268" transform="translate(.625 .312)">
          <g data-name="Group 2170">
            <path
              fill={disabled ? 'currentColor' : 'url(#linear-gradient)'}
              d="M-1499.765-2402.228l-1.063-3.189a.78.78 0 00-.493-.493l-3.189-1.063a.78.78 0 010-1.48l3.189-1.063a.778.778 0 00.493-.493l1.063-3.189a.78.78 0 011.48 0l1.063 3.189a.778.778 0 00.493.493l3.189 1.063a.78.78 0 010 1.48l-3.189 1.063a.78.78 0 00-.493.493l-1.063 3.189a.767.767 0 01-.741.534.766.766 0 01-.739-.534zm-7.335-6.6l-.692-2.076a.389.389 0 00-.247-.246l-2.076-.693a.39.39 0 010-.74l2.076-.692a.389.389 0 00.247-.247l.692-2.075a.39.39 0 01.74 0l.692 2.075a.389.389 0 00.247.247l2.076.692a.39.39 0 010 .74l-2.076.693a.389.389 0 00-.247.246l-.692 2.076a.382.382 0 01-.37.267.383.383 0 01-.37-.262zm5.136-5.779l-.371-1.113a.391.391 0 00-.248-.247l-1.112-.371a.39.39 0 010-.74l1.112-.371a.391.391 0 00.248-.247l.371-1.113a.39.39 0 01.74 0l.371 1.113a.392.392 0 00.247.247l1.113.371a.39.39 0 010 .74l-1.113.371a.391.391 0 00-.247.247l-.371 1.113a.384.384 0 01-.37.267.382.382 0 01-.369-.26z"
              data-name="Path 1319"
              transform="translate(1510.381 2419.07)"
            />
          </g>
        </g>
      </svg>
    </span>
  );
}

export default React.memo(DiscoveryIcon);
