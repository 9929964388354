import { useState } from 'react';
import * as Sentry from '@sentry/react';
import { message } from 'antd';

const getFirstError = (response) => {
  const keys = Object.keys(response.data.errors);
  return response.data.errors[keys[0]][0];
};

const displayOrCatch = (e, displayError) => {
  try {
    displayError(getFirstError(e.response));
  } catch (error) {
    if (e.type === 'validation_error' || e.type === 'invalid_request_error') {
      displayError(e.message);
    } else {
      displayError('Something went wrong. Please refresh the page');
      Sentry.captureException(e);
    }
  }
};

const displayOrCatchMessage = (e) => {
  displayOrCatch(e, (error) => {
    message.error(error);
  });
};

const displayMicrosoftError = () => {
  message.error('You need to approve the request in order to connect the microsoft platform');
};

const displayOrCatchGoogleError = (e) => {
  if (e.error === 'access_denied' || e.error === 'popup_closed_by_user') {
    message.error('You need to approve the request in order to connect the google platform');
  } else {
    message.error('Something went wrong. Please refresh the page');
    Sentry.captureException(new Error(`${e.error}${e.details ? ` - ${e.details}` : ''}`));
  }
};

function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
    }
  };

  return [storedValue, setValue];
}

function useErrorState(initialValue) {
  const [error, setError] = useState(initialValue ? {
    message: initialValue,
  } : null);

  const setValue = (value) => setError(value ? {
    message: value,
  } : null);

  return [error, setValue];
}

const screenIsSmOrXs = (screens) => screens.xs || (
  screens.sm && !screens.md && !screens.lg && !screens.xl && !screens.xxl
);

const screenIsSmOrXsOrMd = (screens) => screenIsSmOrXs(screens) || (
  !screens.sm && screens.md && !screens.lg && !screens.xl && !screens.xxl
);

const loadListIfAvailable = (loadList) => (...params) => {
  if (loadList) {
    loadList(...params);
  }
};

export {
  getFirstError,
  displayOrCatch,
  displayOrCatchMessage,
  displayOrCatchGoogleError,
  displayMicrosoftError,
  useLocalStorage,
  useErrorState,
  screenIsSmOrXs,
  screenIsSmOrXsOrMd,
  loadListIfAvailable,
};
