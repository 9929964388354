import React, { useEffect } from 'react';
import Element from './Element';

const GridPage = ({
  pageOfItems, index, currentPage, goBack, mobile, isColleague, onDomain,
}) => {
  useEffect(() => {
    if (index === currentPage && pageOfItems.items.length === 0 && index > 0) {
      goBack();
    }
  }, [pageOfItems]);
  return (
    <div
      className="fancy-grid-container"
      style={
        mobile ? {} : { transform: `translate3d(calc((100vw - 615px) * ${index - currentPage}), 0, 0)` }
      }
    >
      <div className="fancy-grid">
        {pageOfItems.items.map((item) => (
          <Element
            {...item}
            key={item.email}
            recent
            isColleague={isColleague}
            onDomain={onDomain}
          />
        ))}
      </div>
    </div>
  );
};

export default GridPage;
