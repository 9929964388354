import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Modal } from 'antd';
import { Clock, Play } from 'lucide-react';
import Feedback from './index';

import Thumb1 from '../../assets/thumb1.png';
import Thumb2 from '../../assets/thumb2.png';
import Thumb3 from '../../assets/thumb3.png';

function VideoCard({
  title, duration = '1 min', thumb, onClick,
}) {
  return (
    <div aria-hidden onClick={onClick} className="cursor-pointer">
      <div className="relative">
        <img src={thumb} alt="video-thumb" className="rounded h-full w-full" />
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-3">
          <Play size={24} strokeWidth={2.5} color="#0CAA6B" />
        </div>
        <div className="absolute flex items-center px-1 bottom-1.5 left-3 bg-[#44596C] text-sm font-[Gordita-Medium] gap-1 rounded-xl opacity-50 text-white">
          <Clock size={16} />
          <span>{duration}</span>
        </div>
      </div>
      <span className="block mt-1.5">{title}</span>
    </div>
  );
}

function VideoModal({ visible, setVisible, url }) {
  return (
    <Modal
      className="[&_.ant-modal-close-x]:!h-10 [&_.ant-modal-close-x]:!w-10 [&_.ant-modal-close-x]:!leading-[40px] [&_.ant-modal-close]:-right-12 [&_.ant-modal-close]:bg-white [&_.ant-modal-close]:opacity-60 [&_.ant-modal-close]:rounded-full [&_.ant-modal-footer]:hidden [&_.ant-modal-body]:p-4 [&_.ant-modal-content]:!rounded-2xl"
      visible={visible}
      width={1000}
      onCancel={() => setVisible(false)}
      centered
      destroyOnClose
    >
      <ReactPlayer
        width="100%"
        height="550px"
        url={url}
        controls
      />
    </Modal>
  );
}

export function LearnVideos() {
  const [visible, setVisible] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState('');

  const onClick = (type) => {
    setVisible(true);
    switch (type) {
      case 'preparation':
        setSelectedUrl('https://clientzen.wistia.com/medias/480ux16k2p');
        break;
      case 'discovery':
        setSelectedUrl('https://clientzen.wistia.com/medias/16feiu91o5');
        break;
      case 'segment':
        setSelectedUrl('https://clientzen.wistia.com/medias/zo8l4fnc3g');
        break;
      default:
        setSelectedUrl('https://clientzen.wistia.com/medias/480ux16k2p');
    }
  };

  return (
    <>
      <Feedback.Title value="Learn how to navigate ClientZen" />
      <Feedback.Description>
        While waiting, here are a few videos to get you familiar with ClientZen
      </Feedback.Description>
      <div className="mt-8 grid md:grid-cols-3 gap-4 text-[#44596C]">
        <VideoCard onClick={() => onClick('preparation')} title="Preparation" thumb={Thumb1} />
        <VideoCard onClick={() => onClick('discovery')} title="Discovery" thumb={Thumb2} />
        <VideoCard onClick={() => onClick('segment')} title="Segment" thumb={Thumb3} />
      </div>
      <VideoModal visible={visible} setVisible={setVisible} url={selectedUrl} />
    </>
  );
}
