const hasOneUpperCase = async (rule, value) => {
  if (!/[A-Z]+/.test(value)) {
    throw new Error();
  }
};

const hasBusinessEmail = async (rule, value) => {
  // regex for free email validation like google, yahoo, hotmail, etc
  const freeEmailRegex = /@(gmail|yahoo|hotmail|outlook|icloud|yandex|protonmail)\./;
  if (freeEmailRegex.test(value)) {
    throw new Error();
  }
};

const hasOneLowerCase = async (rule, value) => {
  if (!/[a-z]+/.test(value)) {
    throw new Error();
  }
};

const hasOneNumber = async (rule, value) => {
  if (!/[0-9]+/.test(value)) {
    throw new Error();
  }
};

const isValidParam = (param) => {
  if (typeof param !== 'string') {
    return false;
  }

  const id = Number(param);

  if (Number.isInteger(id) && id > 0) {
    return true;
  }

  return false;
};

export {
  hasOneUpperCase,
  hasOneLowerCase,
  hasOneNumber,
  hasBusinessEmail,
  isValidParam,
};
