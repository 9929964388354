/* eslint-disable no-unused-vars */
import { cn } from '@/helpers/util';
import { Input } from 'antd';
import { ArrowUpRightFromSquare, Search } from 'lucide-react';
import React from 'react';
import { Link } from 'react-router-dom';

function HeaderLink({ to }) {
  return to ? (
    <Link to={to} className="flex">
      <ArrowUpRightFromSquare className="w-4" color="#75899B" strokeWidth={2} />
    </Link>
  ) : (
    <ArrowUpRightFromSquare className="w-4" color="#75899B" strokeWidth={2} />
  );
}

function HeaderActions({ children }) {
  return (
    <div className="flex items-center gap-2">
      {children}
    </div>
  );
}

function HeaderTitle({ title }) {
  return (
    <h2 className="m-0 text-[14px] text-[#44596C]">{title}</h2>
  );
}

function HeaderSearch({ value, setValue, className }) {
  return (
    <label htmlFor="search" className={cn('pl-3 flex items-center gap-1.5', className)}>
      <Search className="w-4 h-4" color="#75899B" strokeWidth={2} />
      <input
        id="search"
        type="text"
        name="search"
        defaultValue={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Search all"
        className="border-none w-full focus:outline-none p-0 text-[#202324] text-sm placeholder:text-[#A9BCCF]"
        data-1p-ignore
      />
    </label>
  );
}

function CardBody({ children, className }) {
  return (
    <div className={cn(className)}>
      {children}
    </div>
  );
}

function CardHeader({
  children, className,
}) {
  return (
    <div className={cn('flex items-start justify-between', className)}>
      {children}
    </div>
  );
}

export default function Card({ children, className }) {
  return (
    <div className={cn('bg-white rounded-lg py-4 px-5 font-[Gordita-Regular]', className)}>
      {children}
    </div>
  );
}

Card.Body = CardBody;
Card.Header = CardHeader;
Card.Header.Actions = HeaderActions;
Card.Header.Title = HeaderTitle;
Card.Header.Search = HeaderSearch;
Card.Header.Link = HeaderLink;
