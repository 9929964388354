import React, { useEffect, useState } from 'react';
import {
  Modal, Result, Spin, Row, Col, Button,
} from 'antd';
import { Helmet } from 'react-helmet';
import debounce from 'lodash/debounce';
import { FireOutlined, ArrowRightOutlined } from '@ant-design/icons';
import TopMovers from '@/components/dashboard/TopMovers';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Layout from '@/components/Layout';
import OverallClientsHappinessSection from '@/components/dashboard/HighlightedInsights/OverallClientsHappinessSection';
import { colleaguesDashboard } from '@/api';
import { displayOrCatchMessage } from '@/helpers';
import Subscribed from '@/components/svgs/Subscribed';
import HighlightedInsightsSection from '@/components/dashboard/HighlightedInsights/ReloadableHighlightedInsightsSection';
import { getName } from '@/helpers/subscriptions';
import SectionHeader from '@/components/dashboard/SectionHeader';
import config from '@/config';
import reloadableEndAction from '@/store/actions/reloadableEnd';
import { HIGHLIGHTED_INSIGHTS_SECTION_COMPONENT } from '@/store/reducers/insight';
import ColleagueTable from '@/components/colleagues/ColleagueTable';
import { load } from './Colleagues';

const showSubscribedMessage = (pathname) => pathname.indexOf('/subscribed') === 0;

const showSubscriptionCancelledMessage = (pathname) => pathname.indexOf('/subscription-cancelled') === 0;

const showSubscriptionResumedMessage = (pathname) => pathname.indexOf('/subscription-resumed') === 0;

const getPlan = (pathname) => pathname.split('/')[2].replaceAll('-', '_');

const getTitle = (
  isSubscribedMessage,
  isCancelledMessage,
  isResumedMessage,
  plan,
) => {
  if (isSubscribedMessage && plan) {
    const name = getName(plan);
    if (name) {
      return `You upgraded to ${name}!`;
    }
  }

  if (isCancelledMessage) {
    return 'Your subscription was canceled';
  }

  if (isResumedMessage) {
    return 'Your subscription was resumed';
  }

  return '';
};

const ContactUsForInquiry = () => (
  <p>
    For any inquiry just,
    {' '}
    <a rel="noreferrer" target="_blank" href="https://clientzen.io/contact">
      contact us
    </a>
    {' '}
    any time.
  </p>
);

const getContent = (
  isSubscribedMessage,
  isCancelledMessage,
  isResumedMessage,
  plan,
) => {
  if (isSubscribedMessage) {
    switch (plan) {
      case 'starter_monthly':
      case 'starter_annually':
        return (
          <>
            <p>
              You&apos;re now ready to grow your startup by being more aware of
              your clients&apos; happiness.
            </p>
            <ContactUsForInquiry />
          </>
        );
      case 'world_class_monthly':
      case 'world_class_annually':
        return (
          <>
            <p>You are now ready to unleash full power of ClientZen.</p>
            <ContactUsForInquiry />
          </>
        );
      default:
        return null;
    }
  }

  if (isCancelledMessage) {
    return (
      <>
        <p>We&apos;re sad to see you go.</p>
        <ContactUsForInquiry />
      </>
    );
  }

  if (isResumedMessage) {
    return (
      <>
        <p>You are now ready to unleash the full power of ClientZen.</p>
        <ContactUsForInquiry />
      </>
    );
  }

  return null;
};

const Dashboard = ({ stopReloadingHighlightedInsights }) => {
  const [data, setData] = useState(null);
  const [loadedClients, setLoadedClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pager, setPager] = useState(null);
  const loadClientList = debounce(
    load(setLoadedClients, setLoading, setPager),
    300,
  );
  const loadClientFilteredList = (dataPage) => loadClientList(null, null, dataPage, true);

  useEffect(() => {
    const { pathname } = window.location;
    const isSubscribedMessage = showSubscribedMessage(pathname);
    const isCancelledMessage = showSubscriptionCancelledMessage(pathname);
    const isResumedMessage = showSubscriptionResumedMessage(pathname);
    const plan = isSubscribedMessage ? getPlan(pathname) : null;

    if (isSubscribedMessage || isCancelledMessage || isResumedMessage) {
      const title = getTitle(
        isSubscribedMessage,
        isCancelledMessage,
        isResumedMessage,
        plan,
      );
      if (title) {
        Modal.info({
          title,
          icon: null,
          width: !isCancelledMessage ? 550 : null,
          content: (
            <div
              style={
                !isCancelledMessage
                  ? { position: 'relative', height: 195 }
                  : null
              }
            >
              {!isCancelledMessage ? (
                <div style={{ position: 'absolute', right: -32, bottom: 0 }}>
                  <Subscribed />
                </div>
              ) : null}
              <Row>
                <Col span={!isCancelledMessage ? 12 : 24}>
                  {getContent(
                    isSubscribedMessage,
                    isCancelledMessage,
                    isResumedMessage,
                    plan,
                  )}
                </Col>
              </Row>
            </div>
          ),
          okText: 'Back to Dashboard',
        });
      }
    }

    stopReloadingHighlightedInsights();
    colleaguesDashboard
      .all()
      .then((response) => {
        setData(response.data.data);
      })
      .catch((e) => {
        displayOrCatchMessage(e);
      });

    loadClientFilteredList(1);
  }, []);

  const handleChange = (pagination, filters, sorter, { action }) => {
    if (action === 'paginate') {
      setLoading(true);
      loadClientFilteredList(pagination.current);
    }
  };

  return (
    <Layout dashboardReady={data ? data.ready : null}>
      <Helmet>
        <title>ClientZen - My Team</title>
      </Helmet>
      {!data ? (
        <Result
          className="team-side"
          icon={<Spin size="large" />}
          subTitle="working on it..."
        />
      ) : (
        <div className="dashboard team-side">
          <OverallClientsHappinessSection
            title="Overall Team Happiness"
            dashboard={data}
            isColleague
          />
          <TopMovers
            title="Top Movers in Team"
            trends={data.trends}
            isColleague
          />
          <HighlightedInsightsSection
            title="Highlighted Team Insights"
            insights={data.highlighted}
            isColleague
          />
          <div>
            <div className="header">
              <SectionHeader
                icon={<FireOutlined />}
                title="Team Members that need attention"
              >
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'flex-end',
                  }}
                >
                  <Link to="/team-members">
                    <Button className="see-all-button">
                      See all Team Members
                      <ArrowRightOutlined />
                    </Button>
                  </Link>
                </div>
              </SectionHeader>
            </div>
            <div className="dashboard-clients">
              <ColleagueTable
                loadList={loadClientFilteredList}
                clients={loadedClients}
                loading={loading}
                onChange={handleChange}
                pagination={{
                  pageSize: config.pageSize,
                  total: pager?.total ?? 0,
                  current: pager?.current_page ?? 1,
                  showSizeChanger: false,
                }}
              />
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => ({
  stopReloadingHighlightedInsights: () => dispatch(reloadableEndAction(HIGHLIGHTED_INSIGHTS_SECTION_COMPONENT)),
});

export default connect(null, mapDispatchToProps)(Dashboard);
