import React from 'react';
import { CheckOutlined, ClockCircleOutlined } from '@ant-design/icons';
import LogoMantra from '../svgs/LogoMantra';
import { ReactComponent as ConnectionIcon } from '../../../components/svgs/connection-icon.svg';
import { ReactComponent as IntercomPlatformIcon } from '../../../components/svgs/intercom-platform-icon.svg';
import { ReactComponent as ZendeskPlatformIcon } from '../../../components/svgs/zendesk-platform-icon.svg';
import { ReactComponent as GorgiasPlatformIcon } from '../../../components/svgs/gorgias-platform-icon.svg';
import { ReactComponent as ShieldCheck } from '../../../components/svgs/shield-check.svg';

function ucfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const ConnectedPlatforms = ({ type }) => (
  <div className="connected-platforms-wrap">
    <div className="connected-platforms">
      <span className="platform-icon">
        {
            // eslint-disable-next-line no-nested-ternary
          type !== 'intercom' ? (type === 'gorgias' ? <GorgiasPlatformIcon /> : <ZendeskPlatformIcon />) : <IntercomPlatformIcon />
        }
      </span>
      <span className="connection-icon">
        <ConnectionIcon />
      </span>
      <span className="clientzen-icon">
        <LogoMantra />
      </span>
    </div>
    <div className="connected-platform-text">
      <CheckOutlined />
      {' '}
      {ucfirst(type)}
      {' '}
      successfully connected
    </div>
    <h2>ClientZen is ready to discover your first insights.</h2>
    <div className="connected-platform-benefits">
      <div>
        <span className="anticon"><ShieldCheck /></span>
        <span>10k messages included in your FREE trial</span>
      </div>
      <div>
        <span className="anticon"><ShieldCheck /></span>
        <span>Cancel anytime: all data automatically deleted</span>
      </div>
      <div className="wait-time">
        <ClockCircleOutlined />
        <span>Estimated time: 3 - 8 mins.</span>
      </div>
    </div>
  </div>
);

export default ConnectedPlatforms;
