import React, { useCallback } from 'react';
import DiscoveryIcon from '@/components/summary/svgs/DiscoveryIcon';
import { Button } from 'antd';
import { useChainlitStore } from '@/store/zustand/useChainlitStore';
import { cn } from '@/helpers/util';

const AskMantraButton = ({ className, topic }) => {
  const {
    setExpanded, setExpandedMessage,
  } = useChainlitStore((state) => state);
  const onClick = useCallback(() => {
    setExpandedMessage(undefined);
    setExpanded(true, topic);
  }, [setExpandedMessage, setExpanded]);
  return (
    <Button onClick={onClick} className={cn('ask-mantra-button', className)} type="link">
      <DiscoveryIcon className="discovery-anticon" />
      Ask Mantra
    </Button>
  );
};

export default AskMantraButton;
