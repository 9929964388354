import { billing } from '../api';
import userSubscribeStatusChanged from '../store/actions/userSubscribeStatusChanged';
import { displayOrCatchMessage } from './index';

const fetchSubscription = (dispatch) => {
  billing.isSubscribed().then((data) => {
    dispatch(userSubscribeStatusChanged(data));
  }).catch((e) => displayOrCatchMessage(e));
};

export default fetchSubscription;
