import React from 'react';
import { Tooltip } from 'antd';
import SentimentAvg from '../sentiment-avg';

const SentimentTooltip = (props) => {
  const {
    children,
    title = 'Sentiment in the selected period',
    sentiment,
    style,
  } = props;

  return (
    <Tooltip
      title={(
        <div className="sentiment-tooltip-content">
          <p style={{ margin: 0, fontSize: 12 }}>{title}</p>
          <div style={{ display: 'flex' }}>
            <SentimentAvg
              sentiment="negative"
              value={Math.round(sentiment.negative * 100)}
              styles={{ root: { margin: 0 }, avg: { margin: 0 }, icon: { margin: '0px 6px 0px 2px' } }}
            />
            <SentimentAvg
              sentiment="positive"
              value={Math.round(sentiment.positive * 100)}
              styles={{ root: { margin: 0 }, avg: { margin: 0 }, icon: { margin: '0px 6px 0px 2px' } }}
            />
            <SentimentAvg
              sentiment="neutral"
              value={Math.round((1 - (sentiment.positive + sentiment.negative)) * 100)}
              styles={{ root: { margin: 0 }, avg: { margin: 0 }, icon: { margin: '0px 6px 0px 2px' } }}
            />
          </div>
        </div>
      )}
      placement="bottom"
      overlayClassName="topic-metric-tooltip"
      overlayInnerStyle={{ fontSize: 12, marginBottom: 0 }}
    >
      <div style={{ display: 'inline-flex', ...style }}>
        {children}
      </div>
    </Tooltip>
  );
};

export default SentimentTooltip;
