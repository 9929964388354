import React from 'react';
import {
  FilterOutlined, ClockCircleOutlined, CheckOutlined,
} from '@ant-design/icons';
import classNames from 'classnames';
import { Input, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import useModal from '../../../hooks/useModal';
import InsightTimelineModal from './InsightTimelineModal';

const TYPE_COLORS = {
  red: '#FDECEC', green: '#F0FAF6', yellow: '#FEF4DC', white: '#FFFFFF',
};

const TypeFilterButton = ({ type, isSelected, selectType }) => (

  <Button
    className="ml-sm"
    style={{ border: '1px solid #707070', color: '#707070', background: TYPE_COLORS[type] }}
    icon={isSelected ? <CheckOutlined /> : <span />}
    onClick={() => selectType(type)}

  />

);
const renderFull = () => (
  <h4 className="page-header" id="insights-timeline">
    <ClockCircleOutlined />
    Insights Timeline
  </h4>
);

const InsightsTimelineHeader = ({
  companyFilter, setCompany, client, selectType, selectedTypes, isColleague,
}) => {
  const { isVisible, toggle } = useModal();
  const { location: { pathname } } = useHistory();
  const isInsightsPage = pathname.includes('insights');

  return (
    <div className={classNames('datatable-header-half', 'datatable-header', 'under-header', 'timeline-filters', {
      'datatable-header-half-timeline': !isInsightsPage,
      'datatable-header-half-insight': isInsightsPage,
    })}
    >
      {!companyFilter ? renderFull() : null}
      {
        (companyFilter && !isColleague) ? (
          <div className="datatable-actions">
            <Input className="filter-by-company" onChange={(e) => setCompany(e.target.value)} placeholder="By Company" prefix={<FilterOutlined />} />
          </div>
        ) : null
      }
      {isInsightsPage && (
        <>
          <div className="datatable-actions__type-filters">
            {Object.keys(selectedTypes).map(
              (type) => (
                <TypeFilterButton
                  key={type}
                  type={type}
                  selectType={selectType}
                  isSelected={selectedTypes[type]}
                />
              ),
            )}
          </div>
        </>
      )}

      {!isInsightsPage && (
      <>
        <Button type="primary" onClick={toggle}> Define your own insight </Button>
        <InsightTimelineModal
          isVisible={isVisible}
          toggle={toggle}
          client={client}
          isColleague={isColleague}
        />
      </>
      )}
    </div>
  );
};

export default InsightsTimelineHeader;
