import { addItem, addItems } from './firstClients';

export default (state = [], action) => {
  switch (action.type) {
    case 'ADD_TEAM_MEMBER': return addItem(state, action.payload);
    case 'REMOVE_TEAM_MEMBER':
      return state.filter((item) => item.email !== action.payload.email);
    case 'CLEAN_TEAM_MEMBERS':
      return [];
    case 'RECENT_INTERACTIONS_ADD_ALL_TEAM_MEMBERS':
      return addItems(state, action.loadedItems);
    default:
      return state;
  }
};
