export const COLOR_TRANSPARENT = 'transparent';
export const COLOR_GREY = 'grey';
export const COLOR_GREEN = 'green';
export const COLOR_RED = 'red';
export const COLOR_YELLOW = 'yellow';

export const FEEDBACK_TYPE_ACTION = 'action';
export const FEEDBACK_TYPE_INFO = 'info';

export const DOT_COLORS = {
  [COLOR_RED]: '#F5222D',
  [COLOR_YELLOW]: '#FFA940',
  [COLOR_GREEN]: '#0BAA6B',
  [COLOR_GREY]: '#E8E8E8',
};

export const BG_COLORS = {
  [COLOR_RED]: '#FFEBE9',
  [COLOR_YELLOW]: '#FEF4DC',
  [COLOR_GREEN]: '#E7F7F0',
  [COLOR_TRANSPARENT]: 'none',
};

export const hasBgColor = (color) => color !== COLOR_TRANSPARENT;

export const getRandomProperty = (object) => {
  const keys = Object.keys(object);
  return keys[Math.floor(Math.random() * keys.length)];
};

export const getRandomPropertyValue = (object) => {
  const keys = Object.keys(object);
  return object[keys[Math.floor(Math.random() * keys.length)]];
};

export const getRandomDotColor = () => getRandomPropertyValue(DOT_COLORS);
export const getRandomBgColor = () => getRandomPropertyValue(BG_COLORS);
