import React, { useEffect, useState } from 'react';
import {
  Button, Card, Col, Result, Spin,
} from 'antd';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { billing } from '@/api';
import Layout from '@/components/Layout';
import { displayOrCatchMessage } from '@/helpers';
import StarterBillingPlan from '@/components/billingPlans/StarterBillingPlan';
import WorldClassBillingPlan from '@/components/billingPlans/WorldClassBillingPlan';
import EnterpriseBillingPlan from '@/components/billingPlans/EnterpriseBillingPlan';
import BillingPlanContainer from '@/components/billingPlans/BillingPlanContainer';
import CurrentBillingPlan from '@/components/billingPlans/CurrentBillingPlan';
import StripeElements from '@/components/billing/StripeElements';

const cancel = (setShouldRedirect, setCancelLoading) => () => {
  setCancelLoading(true);
  billing.cancel().then(() => {
    setShouldRedirect('/subscription-cancelled');
  }).catch((e) => {
    setCancelLoading(false);
    displayOrCatchMessage(e);
  });
};

const resume = (setShouldRedirect, setResumeLoading) => () => {
  setResumeLoading(true);
  billing.resume().then(() => {
    setShouldRedirect('/subscription-resumed');
  }).catch((e) => {
    setResumeLoading(false);
    displayOrCatchMessage(e);
  });
};

const setSubscriptionType = (
  isAnnuallySubscriptionSelected,
  setAnnuallySubscriptionValue,
) => () => {
  setAnnuallySubscriptionValue(isAnnuallySubscriptionSelected);
};

const choosePlan = (type, isAnnuallySubscriptionSelected, setChoosenPlan) => () => {
  switch (type) {
    case 'starter': setChoosenPlan(isAnnuallySubscriptionSelected ? 'starter_annually' : 'starter_monthly'); break;
    case 'world_class': setChoosenPlan(isAnnuallySubscriptionSelected ? 'world_class_annually' : 'world_class_monthly'); break;
    default: // do nothing
  }
};

const enterpriseOnClick = (e) => {
  e.preventDefault();

  window.location = 'mailto:sales@clientzen.io';
  e.preventDefault();
};

const Billing = ({ subscription, user }) => {
  const [setupIntent, setSetupIntent] = useState(null);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [resumeLoading, setResumeLoading] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(null);
  const [choosenPlan, setChoosenPlan] = useState(user.choosen_plan);
  const [isAnnuallySubscriptionSelected, setAnnuallySubscriptionValue] = useState(true);

  const isSubscribed = subscription.subscribed;
  const isOnGracePeriod = subscription.onGracePeriod;
  const currentPlan = subscription.plan;
  const isBillingPage = setupIntent && !choosenPlan;

  useEffect(() => {
    if (!subscription.subscribed && !subscription.loading) {
      billing.createIntent().then((response) => setSetupIntent(response.data.data)).catch((e) => {
        displayOrCatchMessage(e);
      });
    }
  }, [subscription]);

  return (
    <Layout pageWithoutSider={isBillingPage} isBillingPage={isBillingPage}>
      <Helmet>
        <title>ClientZen - Billing</title>
      </Helmet>
      {
        shouldRedirect ? (
          <Redirect to={shouldRedirect} />
        ) : null
      }
      {
        (isSubscribed === null || (!setupIntent && !isSubscribed)) ? (
          <Result
            icon={<Spin size="large" />}
            subTitle="working on it..."
          />
        ) : null
      }
      {
        setupIntent && choosenPlan ? (
          <StripeElements
            choosenPlan={choosenPlan}
            setupIntent={setupIntent}
            setShouldRedirect={setShouldRedirect}
          />
        ) : null
      }
      {
        setupIntent && !choosenPlan ? (
          <BillingPlanContainer
            setSubscriptionType={setSubscriptionType}
            isAnnuallySubscriptionSelected={isAnnuallySubscriptionSelected}
            setAnnuallySubscriptionValue={setAnnuallySubscriptionValue}
          >
            <StarterBillingPlan
              onClick={choosePlan('starter', isAnnuallySubscriptionSelected, setChoosenPlan)}
              isAnnuallySubscriptionSelected={isAnnuallySubscriptionSelected}
            />
            <WorldClassBillingPlan
              onClick={choosePlan('world_class', isAnnuallySubscriptionSelected, setChoosenPlan)}
              isAnnuallySubscriptionSelected={isAnnuallySubscriptionSelected}
            />
            <EnterpriseBillingPlan
              onClick={enterpriseOnClick}
              isAnnuallySubscriptionSelected={isAnnuallySubscriptionSelected}
            />
          </BillingPlanContainer>
        ) : null
      }
      {
        isSubscribed ? (
          <>
            <Card className="card-padding-lg container-card-medium" style={{ marginBottom: 15 }}>
              <CurrentBillingPlan plan={currentPlan}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  {!isOnGracePeriod ? (
                    <Button
                      type="primary"
                      size="middle"
                      style={{ marginTop: 15 }}
                      danger
                      loading={cancelLoading}
                      onClick={cancel(setShouldRedirect, setCancelLoading)}
                      disabled={isOnGracePeriod}
                    >
                      Cancel
                    </Button>
                  ) : null}
                  {isOnGracePeriod ? (
                    <Button
                      type="primary"
                      size="middle"
                      style={{ marginTop: 15 }}
                      loading={resumeLoading}
                      onClick={resume(setShouldRedirect, setResumeLoading)}
                      disabled={!isOnGracePeriod}
                    >
                      Resume
                    </Button>
                  ) : null}
                </Col>
              </CurrentBillingPlan>
            </Card>
          </>
        ) : null
      }
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  subscription: state.subscription,
});

export default connect(mapStateToProps, null)(Billing);
