export const addItem = (state, itemToAdd) => {
  const existingFirstClient = state.find((item) => item.email === itemToAdd.email);
  return [
    ...state.filter((item) => item !== existingFirstClient),
    itemToAdd,
  ];
};

export const addItems = (state, itemsToAdd) => {
  let newState = state;

  for (let i = 0; i < itemsToAdd.length; i++) {
    newState = addItem(newState, itemsToAdd[i]);
  }

  return newState;
};

export default (state = [], action) => {
  switch (action.type) {
    case 'ADD_FIRST_CLIENT': return addItem(state, action.payload);
    case 'REMOVE_FIRST_CLIENT':
      return state.filter((item) => item.email !== action.payload.email);
    case 'CLEAN_FIRST_CLIENTS':
      return [];
    case 'RECENT_INTERACTIONS_ADD_ALL_CLIENTS':
      return addItems(state, action.loadedItems);
    default:
      return state;
  }
};
