import ExcludeLabelFilter from '@/components/filter/filters/ExcludeLabelFilter';
import CustomFieldFilter from '../filters/CustomFieldFilter';
import { KeywordFilter } from '../filters/KeywordFilter';
import LabelFilter from '../filters/LabelFilter';
import SentimentFilter from '../filters/SentimentFilter';
import SourceFilter from '../filters/SourceFilter';

export const filtersList = [
  {
    id: 'included_keywords',
    label: 'Keywords',
  },
  {
    id: 'sentiment',
    label: 'Sentiment',
  },
  {
    id: 'custom_fields',
    label: 'Custom Field',
  },
  {
    id: 'sources',
    label: 'Source',
  },
  {
    id: 'labels',
    label: 'Label',
  },
  {
    id: 'exclude_labels',
    label: 'Exclude Label',
  },
  {
    id: 'excluded_keywords',
    label: 'Keywords to exclude',
  },
];

export const filtersComponent = {
  included_keywords: KeywordFilter,
  sentiment: SentimentFilter,
  custom_fields: CustomFieldFilter,
  sources: SourceFilter,
  labels: LabelFilter,
  exclude_labels: ExcludeLabelFilter,
  excluded_keywords: KeywordFilter,
};
