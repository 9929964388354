/* eslint-disable react/jsx-filename-extension, max-len */
import React from 'react';

const getClientSentMessageAtUnusualHour = (row) => ({
  text: `${row.client.name} has sent an email at an unusual time.`,
  description: '',
  recommended_step1: '',
  recommended_step2: '',
  recommended_step3: '',
  recommended_step1_description: (
    <>
    </>
  ),
  recommended_step2_description: (
    <>
    </>
  ),
  hasRecommendation: false,
});

export default getClientSentMessageAtUnusualHour;
