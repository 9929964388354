import * as React from 'react';

const Annotation = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <g fill="none">
      <path d="M0 0h16v16H0z" />
      <g
        data-name="Group 1474"
        stroke="#595959"
        strokeLinecap="round"
        strokeWidth={1.5}
      >
        <path data-name="Line 264" d="M1 8.5h4" />
        <path data-name="Line 265" d="M11 8.5h4" />
        <g data-name="Ellipse 537" transform="translate(4.5 5)">
          <circle cx={3.5} cy={3.5} r={3.5} stroke="none" />
          <circle cx={3.5} cy={3.5} r={2.75} />
        </g>
      </g>
    </g>
  </svg>
);

export default Annotation;
