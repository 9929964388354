import React, { useContext } from 'react';
import { cn } from '@/helpers/util';
import { useSelector } from 'react-redux';
import { PlatformContext } from '../context';
import { getPlatformRender } from '../utils/platforms';
import { showIntercomPopup } from '../integrations/IntercomContainer';
import { usePlatforms } from '../hooks/usePlatforms';

export function PlatformItem({
  name, image, category, trial,
}) {
  const user = useSelector((state) => state.user);
  const {
    platform, setPlatform,
  } = useContext(PlatformContext);

  const { refetch } = usePlatforms();

  const onClick = (currentPlatform) => {
    if (currentPlatform.toLowerCase() === 'intercom') {
      showIntercomPopup(user.token, async () => {
        await refetch();
        setPlatform(currentPlatform);
      });

      return;
    }
    setPlatform(currentPlatform);
  };

  return (
    <div
      role="button"
      aria-hidden
      onClick={() => onClick(name)}
      className={cn('cursor-pointer rounded-lg border border-solid border-[#CFDBE4] px-3 py-3.5 font-[Gordita-Regular] text-[#74899B] hover:border-[#A9BCCF]', platform === name && 'border-[#A9BCCF]')}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="flex items-center justify-center w-10 h-10 rounded-md bg-[#EFF4F8]">
            {image}
          </div>
          <div className="ml-3">
            <h2 className="m-0 text-sm text-[#262626] font-[Gordita-Medium]">{name}</h2>
            <span className="text-xs">{category}</span>
          </div>
        </div>
        <span className="mr-1.5 text-[#74899B]">
          {trial ? 'Free Trial' : 'Premium'}
        </span>
      </div>
      {platform === name ? getPlatformRender(platform.toLowerCase()) : null}
    </div>
  );
}
