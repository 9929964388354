import axios from './axios';
import auth from './auth';

const index = () => axios.get('/api/companies', {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response).catch((e) => Promise.reject(e));

const companies = {
  index,
};

export default companies;
