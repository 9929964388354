/* eslint-disable no-nested-ternary */
import React from 'react';
import { Dropdown, Menu } from 'antd';
import { connect } from 'react-redux';
import NProgress from 'nprogress';
import { auth } from '@/api';
import { cn } from '@/helpers/util';
import {
  LogOutIcon, RocketIcon, SettingsIcon, UserIcon, UsersIcon,
} from 'lucide-react';
import mixpanel, { mixpanelEvents } from '@/mixpanel';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';
import UserAvatar from './UserAvatar';
import userAuthenticatedAction from '../store/actions/userAuthenticated';

const onClick = (userAuthenticated, queryClient) => () => {
  NProgress.start();

  try {
    queryClient.getQueryCache().clear();
  } catch (e) {
    Sentry.captureException(e);
  }

  auth
    .logout()
    .then(() => {
      NProgress.done();
      userAuthenticated(null);
    })
    .catch(() => {
      NProgress.done();
      userAuthenticated(null);
    });
};

const items = [
  {
    icon: <UserIcon color="#44596C" size={18} strokeWidth={1.25} />,
    name: 'Account',
    link: '/account',
  },
  {
    icon: <UsersIcon color="#44596C" size={18} strokeWidth={1.25} />,
    name: 'Team Members',
    link: '/users',
  },
  {
    icon: <RocketIcon color="#0CAA6B" size={18} strokeWidth={1.25} />,
    name: 'Upgrade',
    permission: true,
    link: '/billing',
  },
  {
    icon: <SettingsIcon color="#44596C" size={18} strokeWidth={1.25} />,
    name: 'Settings',
    link: '/settings',
  },
];

const overlay = (user, userAuthenticated, history, queryClient) => {
  const team = auth.team();

  return (
    <Menu className="!p-1 !min-w-[200px]">
      {items.map((item) => (!item.permission ? (
        <Menu.Item key={item.name} title="" className="flex items-center space-x-1 !text-xs" icon={item.icon} onClick={() => history.push(item.link)}>
          <span className={cn('!text-[#44596C]', item.name === 'Upgrade' && '!text-[#0CAA6B]')}>{item.name}</span>
        </Menu.Item>
      ) : team.owner && team.dashboard_ready ? (
        <Menu.Item
          key={item.name}
          title=""
          className="flex items-center space-x-1 !text-xs"
          icon={item.icon}
          onClick={() => {
            history.push(item.link);
            mixpanel.track(mixpanelEvents.UPGRADE_CLICKED);
          }}
        >
          <span className={cn('!text-[#44596C]', item.name === 'Upgrade' && '!text-[#0CAA6B]')}>{item.name}</span>
        </Menu.Item>
      ) : null))}
      {
        user ? (
          <Menu.Item
            className="flex items-center space-x-1 !text-xs !text-[#44596C]"
            title=""
            icon={<LogOutIcon color="#44596C" size={18} strokeWidth={1.25} />}
            onClick={onClick(userAuthenticated, queryClient)}
          >
            Logout
          </Menu.Item>
        ) : null
      }
    </Menu>
  );
};

const ProfileMenu = ({ user, userAuthenticated, collapsed }) => {
  const history = useHistory();
  const queryClient = useQueryClient();

  return (
    <Dropdown
      overlay={overlay(user, userAuthenticated, history, queryClient)}
      placement="topRight"
      trigger={['click']}
      getPopupContainer={(el) => el}
    >
      <div className={cn('cursor-pointer header-user-avatar mt-3 hover:bg-[#DFE9F1] w-full rounded-full', collapsed && 'ml-[5px] hover:bg-transparent')}>
        <UserAvatar />
        {!collapsed ? <span className="ml-3 text-[#44596C]">{user.name}</span> : null}
      </div>
    </Dropdown>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  userAuthenticated: (data) => dispatch(userAuthenticatedAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);
