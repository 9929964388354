import React, { useCallback } from 'react';
import classNames from 'classnames';
import { Button, Card, Tooltip } from 'antd';
import {
  UserAddOutlined,
  DeleteOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import Avatar from '../clients/Avatar';
import removeFirstClientAction from '../../store/actions/removeFirstClient';
import addFirstClientAction from '../../store/actions/addFirstClient';
import removeTeamMemberAction from '../../store/actions/removeTeamMember';
import addTeamMemberAction from '../../store/actions/addTeamMember';
import addFirstColleagueAction from '../../store/actions/addFirstColleague';
import removeFirstColleagueAction from '../../store/actions/removeFirstColleague';

const getAddButtonText = ({ onDomain, isColleague }) => {
  if (isColleague) {
    return 'Add Team Member';
  }

  if (onDomain) {
    return 'Invite Team Member';
  }

  return 'Add Client';
};

const getAddedText = ({ onDomain, isColleague }) => {
  if (isColleague) {
    return 'Team Member added';
  }

  if (onDomain) {
    return 'Added';
  }

  return 'Client added';
};

const InteractionCard = ({
  name,
  avatar,
  email,
  company,
  persons,
  addPerson,
  removePerson,
  recent,
  onDomain,
  isColleague,
}) => {
  const isAlreadyAdded = useCallback(
    () => persons.find((person) => person.email === email),
    [persons, email],
  );
  const onRemoveClick = useCallback(
    () => removePerson({ email }),
    [onDomain, email],
  );
  const onAddClick = useCallback(
    () => addPerson({
      name,
      avatar,
      email,
      company,
      recent,
    }),
    [name, avatar, email, company],
  );

  return (
    <Card
      className={classNames('interaction-card-container', {
        'client-added': isAlreadyAdded(),
        'team-side': onDomain,
      })}
    >
      <div className="interaction-card">
        <div
          className={classNames('interaction-card-outer', {
            'client-added': isAlreadyAdded(),
          })}
        >
          <Avatar size={50} client={{ avatar, name }} />
          <div className="interaction-card-inner">
            <p>
              <strong>{name}</strong>
            </p>
            <p>
              <span>
                {email}
                {onDomain ? null : <> | </>}
              </span>
              {onDomain ? null : (
                <>
                  works at
                  {company}
                </>
              )}
            </p>
          </div>
        </div>
        <div className="interaction-card-actions">
          {isAlreadyAdded() ? (
            <>
              <div className="interaction-card-client-added">
                <span className="interaction-card-client-added__text">
                  {getAddedText({ onDomain, isColleague })}
                </span>
                <span className="interaction-card-client-added__decoration">
                  <CheckOutlined />
                </span>
              </div>
              <Tooltip className="mx-sm" title="Remove">
                <Button onClick={onRemoveClick}>
                  <DeleteOutlined />
                </Button>
              </Tooltip>
            </>
          ) : (
            <Button type="primary" onClick={onAddClick}>
              <UserAddOutlined />
              {getAddButtonText({ onDomain, isColleague })}
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
};

const selectPersonState = (state, { isColleague, onDomain }) => {
  if (isColleague) {
    return state.firstColleagues;
  }
  if (onDomain) {
    return state.teamMembers;
  }
  return state.firstClients;
};

const mapStateToProps = (state, { isColleague, onDomain }) => ({
  persons: selectPersonState(state, { isColleague, onDomain }),
});

const mapDispatchToProps = (dispatch, { isColleague, onDomain }) => ({
  addPerson: (data) => {
    if (isColleague) {
      dispatch(addFirstColleagueAction(data));
    } else if (onDomain) {
      dispatch(addTeamMemberAction(data));
    } else {
      dispatch(addFirstClientAction(data));
    }
  },
  removePerson: (data) => {
    if (isColleague) {
      dispatch(removeFirstColleagueAction(data));
    } else if (onDomain) {
      dispatch(removeTeamMemberAction(data));
    } else {
      dispatch(removeFirstClientAction(data));
    }
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InteractionCard);
