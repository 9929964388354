import React, { useEffect, useState } from 'react';
import {
  Button, Dropdown, Menu, Spin, Tag, Tooltip,
} from 'antd';
import { PlusIcon, X, XIcon } from 'lucide-react';
import { uniqueId } from 'lodash';
import useSources from '@/data/useSources';
import { cn } from '@/helpers/util';
import { getPlatformIcon } from '../../discovery/utils/sources';
import { useFiltersDispatch } from '../context';

function SourceTag(props) {
  const {
    label, value, closable, onClose,
  } = props;
  const platform = label ? label.type.slice(0, 1).toUpperCase() + label.type.slice(1) : null;
  return (
    <Tag
      color={value}
      icon={getPlatformIcon(label)}
      closeIcon={<XIcon size={12} color="#CEDBE4" />}
      closable={closable}
      onClose={onClose}
      className="!text-[#202324] !py-1 !bg-[#F6F9FB] !rounded flex items-center gap-2 [&>span]:!m-0 [&>.ant-tag-close-icon]:flex"
    >
      <span className="flex items-center gap-1.5">
        <Tooltip title={label.name}>
          {
            label.type === 'zendesk' || label.type === 'intercom' || !label.name
              ? platform
              : platform.concat(
                `: ${label.name.length > 16 ? `${label.name.slice(0, 18)}...` : label.name}`,
              )
          }
        </Tooltip>
      </span>
    </Tag>
  );
}

export function SourceItem({
  sources, source, handleClick, ...props
}) {
  const title = source.charAt(0).toUpperCase() + source.slice(1);
  const filteredSources = sources[source].filter((item) => item.conversations_count > 0);

  if (source === 'intercom' || source === 'zendesk') {
    const item = filteredSources[0];
    return (
      <Menu.Item
        {...props}
        onSelect={() => handleClick(item, source)}
        className="flex items-center min-w-[10rem] !text-[#202324]"
        icon={getPlatformIcon({ type: source })}
      >
        {title}
      </Menu.Item>
    );
  }
  return (
    <Menu.SubMenu {...props} popupOffset={[2, -12]} className="source-filter-menu-item [&>div]:flex [&>div]:items-center min-w-[10rem] [&_.ant-dropdown-menu-submenu-title]:!text-[#202324]" popupClassName="max-h-96 overflow-y-scroll !shadow-[0px_1px_12px_#CAD7E180]" icon={getPlatformIcon({ type: source })} title={title}>
      {filteredSources.map((item) => (
        <Menu.Item onClick={() => handleClick(item, source)} key={item.id} className="space-x-1 !text-[#44596C] hover:bg-[#EFF4F8] !mx-1 rounded-[4px]">
          <span>{item.name ? item.name : item.id}</span>
          <span>
            (
            {item.conversations_count}
            {' '}
            rows)
          </span>
        </Menu.Item>
      ))}
    </Menu.SubMenu>
  );
}

export default function SourceFilter({ defaultValue = [], name }) {
  const [list, setList] = useState(defaultValue);
  const dispatch = useFiltersDispatch();
  const {
    data, isLoading,
  } = useSources();

  const handleClick = (item, type) => {
    if (list.some((existing) => existing.id === item.id)) {
      setList((prev) => prev.filter((existing) => existing.id !== item.id));
      dispatch({ type: 'SET_FILTERS_FIELD', field: 'sources', value: list.filter((existing) => existing.id !== item.id).map((existing) => ({ source_id: existing.id, type: existing.type })) });
    } else {
      setList((prev) => [...prev, { ...item, type }]);
      dispatch({ type: 'ADD_FILTERS_FIELD', field: 'sources', value: { source_id: item.id, type } });
    }
  };

  const handleClear = () => {
    dispatch({ type: 'SET_FILTERS_FIELD', field: 'sources', value: [] });
    setList([]);
  };

  const content = data && Object.keys(data).length > 0 ? Object.keys(data)
    .filter((platform) => data[platform].filter((item) => item.conversations_count > 0).length > 0)
    .map((platform) => (
      <SourceItem
        key={`${uniqueId('platform')}`}
        sources={data}
        source={platform}
        handleClick={handleClick}
      />
    ))
    : null;

  useEffect(() => {
    if (!defaultValue) return;
    dispatch({ type: 'SET_FILTERS_FIELD', field: 'sources', value: defaultValue });
  }, []);

  return (
    <div className="space-y-0.5">
      <Dropdown
        overlayClassName="!px-3"
        align={{ offset: [0, -10] }}
        trigger={['click']}
        overlay={(
          <Menu className="!rounded-md shadow-[0px_1px_12px_#CAD7E180] border border-solid border-[#DFE9F1]">
            {isLoading ? (
              <div className="text-center m-8">
                <Spin size="medium" />
              </div>
            ) : content}
          </Menu>
        )}
      >
        <div className="group flex flex-col border-0 border-b border-solid border-[#EFF4F8] hover:border-[#DFE9F1] pb-3 cursor-pointer">
          <div className="group/button px-4 hover:border-[#DFE9F1] flex items-center justify-between text-[#75899B] text-sm">
            <span className="group-hover:text-[#44596C]">{name}</span>
            <div className="flex gap-1">
              <Button
                type="text"
                onClick={handleClear}
                className={cn(
                  'opacity-0 group-hover:opacity-100 flex items-center !rounded-md !p-2 justify-between !text-sm !text-[#75899B] hover:!bg-[#EFF4F8] hover:!text-[#44596C]',
                  list.length === 0 && 'group-hover:opacity-0',
                )}
              >
                <X size={20} />
              </Button>
              <Button
                type="text"
                className={cn(
                  'flex items-center !rounded-md !p-2 justify-between !text-sm !text-[#75899B]',
                  list.length === 0 ? 'group-hover/button:!bg-[#EFF4F8] group-hover/button:!text-[#44596C]' : 'hover:!bg-[#EFF4F8] hover:!text-[#44596C]',
                )}
              >
                <PlusIcon size={20} />
              </Button>
            </div>
          </div>
          {list.length > 0 ? (
            <div className="flex flex-wrap mt-1.5 gap-2 px-3">
              {list.map((label) => (
                <SourceTag
                  key={label}
                  label={label}
                  value={label}
                  closable
                  onClose={() => handleClick(label)}
                />
              ))}
            </div>
          ) : null}
        </div>
      </Dropdown>
    </div>
  );
}
