import React, {
  useReducer, useMemo, useEffect, useCallback, useState,
} from 'react';
import {
  Table, Button, Input, message,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import { useHistory, withRouter } from 'react-router-dom';
import { topics } from '@/api';
import { Helmet } from 'react-helmet';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import {
  initialState,
  categoriesReducer,
  SET_EXPANDED_KEYS_ACTION,
  SET_LOADING_ACTION,
  SET_CATEGORY_GROUPS_ACTION,
  SET_CATEGORY_ACTION,
  SET_RANGE_FILTER_ACTION,
  SET_SEARCH_FILTER_ACTION,
} from './categoriesReducer';
import { useBoolean } from '../../hooks/useBoolean';
import { getColumns, isParent } from './columns';
import ExpandIcon from './ExpandIcon';
import AddIcon from '../svgs/icons/Add';
import CategoryModal from './CategoryModal';
import Layout from '../Layout';
import { SentimentDateFilter } from '../ui/sentiment-date-filter';

const Categories = () => {
  const history = useHistory();

  const { defaultDate } = useDefaultDateStore();
  const [state, dispatch] = useReducer(categoriesReducer, initialState, (initState) => ({
    ...initState,
    filters: {
      ...initState.filters,
      start: defaultDate.start,
      end: defaultDate.end,
    },
  }));

  const [expandedTableRows, setExpandedTableRows] = useState([]);

  // `name` filter changes the tree view into a table view
  const isTable = useMemo(() => state.filters?.name?.length > 0, [state.filters?.name]);

  const hasSelectedId = useMemo(() => !!state.selectedId, [state.selectedId]);

  const { value: visible, setFalse: setHidden, setTrue: setVisible } = useBoolean();

  const columns = useMemo(
    () => getColumns(
      state.expandedIds, isTable, history, expandedTableRows, setExpandedTableRows, dispatch,
    ),
    [state.expandedIds.length, isTable, history, expandedTableRows],
  );

  const getCategoriesData = useCallback(() => {
    dispatch({ type: SET_LOADING_ACTION, payload: true });
    setExpandedTableRows([]);
    topics.getTopicGroups(state.filters)
      .then(({ data }) => {
        dispatch({
          type: SET_CATEGORY_GROUPS_ACTION,
          payload: data.map((item) => ({ ...item, isCategory: true })),
        });
      })
      .catch(() => {
        dispatch({ type: SET_LOADING_ACTION, payload: false });
        message.error('Segment Groups Error');
      });
  }, [state.filters]);

  useEffect(() => {
    if (isTable) {
      return;
    }

    getCategoriesData();
  }, [state.filters, isTable, state.reload]);

  useEffect(() => {
    if (!hasSelectedId && !isTable) {
      return;
    }

    dispatch({ type: SET_LOADING_ACTION, payload: true });

    topics.getTopics(state.selectedId, state.filters, { highest_feedback: 1 })
      .then(({ data }) => {
        dispatch({
          type: SET_CATEGORY_ACTION,
          payload: { children: data, id: state.selectedId, isTable },
        });
      })
      .catch(() => {
        dispatch({ type: SET_LOADING_ACTION, payload: false });

        message.error('Segment Error');
      });
  }, [hasSelectedId, isTable, state.filters, state.selectedId, state.reload]);

  const expandableProps = isTable
    ? false : {
      indentSize: 21,
      onExpand: (expanded, { id }) => {
        dispatch({ type: SET_EXPANDED_KEYS_ACTION, payload: { id, expanded } });
      },
      expandIcon: ({
        expandable, expanded, onExpand, record,
      }) => (
        expandable
          ? <ExpandIcon expanded={expanded} record={record} onExpand={onExpand} />
          : null),
    };

  const categoryModalProps = {
    visible,
    onCancel: setHidden,
    onOk: () => {
      setHidden();
      getCategoriesData();
    },
  };

  const searchProps = {
    className: 'categories__header__search',
    placeholder: 'Search segment',
    prefix: <SearchOutlined />,
    onChange: debounce((e) => {
      dispatch({ type: SET_SEARCH_FILTER_ACTION, payload: e.target.value });
    }, 400),
  };

  useEffect(() => {
    dispatch({ type: SET_RANGE_FILTER_ACTION, payload: { ...defaultDate } });
  }, [defaultDate]);

  return (
    <Layout
      leftHeader={
        <Input {...searchProps} />
      }
      rightHeader={(
        <>
          <Button
            size="middle"
            type="primary"
            htmlType="button"
            icon={<AddIcon className="categories__header__btn-icon" />}
            disabled={state.loading}
            onClick={setVisible}
          >
            Create category
          </Button>

          <SentimentDateFilter />
        </>
    )}
    >
      <Helmet>
        <title>ClientZen - Categories</title>
      </Helmet>
      <section className="categories mx-7 2xl:mx-auto 2xl:max-w-[1200px]">
        <div className="categories__body">
          <Table
            onExpandedRowsChange={(expandedRows) => {
              setExpandedTableRows(expandedRows);
            }}
            expandedRowKeys={expandedTableRows}
            className={!isTable ? 'with-children' : ''}
            rowClassName={(row) => (isParent(row) ? 'category-row' : 'painpoint-row')}
            key={state.reload}
            rowKey={({ id }) => id}
            scroll={{ x: 'max-content' }}
            columns={columns}
            dataSource={state.data}
            loading={state.loading}
            pagination={false}
            expandable={expandableProps}
          />
        </div>
      </section>
      <CategoryModal {...categoryModalProps} />
    </Layout>
  );
};

export default withRouter(Categories);
