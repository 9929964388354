import React, { useCallback, useState } from 'react';
import { Button, Modal } from 'antd';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { colleagues } from '@/api';
import SearchPossibleColleagues from '../onboarding/SearchPossibleColleagues';
import { displayOrCatchMessage } from '../../helpers';
import cleanFirstColleaguesAction from '../../store/actions/cleanFirstColleagues';

const ColleagueAddModal = ({
  loadList,
  setVisible,
  visible,
  firstColleagues,
  cleanFirstColleagues,
  redirectsBackToClients,
  ...props
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const onCancel = useCallback(() => {
    setVisible(false);
  }, []);
  const onOk = useCallback(() => {
    setLoading(true);
    colleagues
      .storeBatch({
        colleagues: firstColleagues.map(({ company, ...firstColleague }) => ({
          ...firstColleague,
          company_name: company,
        })),
      })
      .then(() => {
        if (loadList) {
          loadList(1);
        }
        setLoading(false);
        setVisible(false);
        if (redirectsBackToClients) {
          history.push('/team-members');
        }
      })
      .catch((e) => {
        setLoading(false);
        displayOrCatchMessage(e);
      });
  }, [firstColleagues]);

  return (
    <Modal
      title="Add new team members"
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={onOk}>
          Save
        </Button>,
      ]}
      visible={visible}
      afterClose={cleanFirstColleagues}
      className="team-side"
      {...props}
    >
      <div className="client-add-modal-body">
        <SearchPossibleColleagues tags />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  firstColleagues: state.firstColleagues,
});

const mapDispatchToProps = (dispatch) => ({
  cleanFirstColleagues: (data) => dispatch(cleanFirstColleaguesAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ColleagueAddModal);
