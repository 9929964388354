import { platforms } from '@/api';
import { useQuery } from '@tanstack/react-query';

export default function useSources() {
  return useQuery({
    queryKey: ['sources'],
    queryFn: async () => {
      const { data: res } = await platforms.getTeamPlatforms();
      return res.data;
    },
  });
}

export function prefetchSources() {
  return useQuery({
    queryKey: ['sources'],
    queryFn: async () => {
      const { data: res } = await platforms.getTeamPlatforms();
      return res.data;
    },

    // Optional optimization to avoid rerenders when this query changes:
    notifyOnChangeProps: [],
  });
}
