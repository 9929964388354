import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import SelectedDaysFeedbackContainer from '@/components/bucket/SelectedDaysFeedbackContainer';
import SelectedDaysSummaryContainer from '@/components/SelectedDaysSummaryContainer';

const ViewSelectedDaysInsights = () => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <Button type="secondary" onClick={() => setVisible(true)}>View insights</Button>
      <Drawer
        className="feedback-drawer feedback-insights-drawer"
        visible={visible}
        onClose={() => setVisible(false)}
        title="Insights and feedback on selection"
        placement="right"
      >
        <div className="flex bg-white">
          <div
            style={{
              height: 'calc(100vh - 56px)',
            }}
            className="fixed flex flex-col h-screen w-[410px] z-10 bg-white border-0 border-r border-solid border-[#E5ECF3]"
          >
            <SelectedDaysSummaryContainer
              headerText="Insights"
              headerClassName="text-sm pt-4 pb-2 pl-5 flex justify-between items-center font-[Gordita-Regular]"
              hasFilters
            />
          </div>
          <div
            style={{
              height: 'calc(100vh - 56px)',
              width: '1178px',
            }}
            className="fixed pl-[410px] h-screen w-full flex flex-col flex-1"
          >
            <SelectedDaysFeedbackContainer />
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default ViewSelectedDaysInsights;
