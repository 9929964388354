/* eslint-disable react/jsx-filename-extension, max-len */
import React from 'react';

const getColleagueAddedToClientzenData = (row) => ({
  text: `You added ${row.colleague.name} to ClientZen`,
  description: '',
  recommended_step1: '',
  recommended_step2: '',
  recommended_step3: '',
  recommended_step1_description: (
    <>
    </>
  ),
  recommended_step2_description: (
    <>
    </>
  ),
  messages: [],
  hasMessages: false,
  hasRecommendation: false,
});

export default getColleagueAddedToClientzenData;
