import {
  Button, Dropdown, Menu, Tag,
} from 'antd';
import { PlusIcon, X, XIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import useURLState from '@/hooks/useUrlState/useURLState';
import { getSentimentIcon, sentimentsList } from '@/components/discovery/utils/sentiment';
import { cn } from '@/helpers/util';
import { useFiltersDispatch } from '../context';

function SentimentTag(props) {
  const {
    label, value, closable, onClose,
  } = props;

  return (
    <Tag
      color={value}
      closeIcon={<XIcon size={12} color="#CEDBE4" />}
      closable={closable}
      onClose={onClose}
      className="!text-[#202324] !py-1 !bg-[#F6F9FB] !rounded flex items-center gap-1 [&>.ant-tag-close-icon]:flex"
    >
      {getSentimentIcon(value, 18, 2)}
      <span>
        {label.slice(0, 1).toUpperCase() + label.slice(1)}
      </span>
    </Tag>
  );
}

export default function SentimentFilter({ defaultValue = [], name }) {
  const {
    urlQueryState,
  } = useURLState();
  const [list, setList] = useState(defaultValue.map((value) => value?.type ?? value) || []);

  const dispatch = useFiltersDispatch();
  const handleClick = (sentiment) => {
    if (list.includes(sentiment)) {
      setList((prevState) => prevState.filter((existing) => existing !== sentiment));
      dispatch({ type: 'REMOVE_FILTERS_FIELD', field: 'sentiment', value: sentiment });
    } else {
      setList((prevState) => [...prevState, sentiment]);
      dispatch({ type: 'ADD_FILTERS_FIELD', field: 'sentiment', value: sentiment });
    }
  };

  const handleClear = (e) => {
    e.stopPropagation();
    setList([]);
    dispatch({ type: 'SET_FILTERS_FIELD', field: 'sentiment', value: [] });
  };

  useEffect(() => {
    if (!defaultValue) return;
    dispatch({ type: 'SET_FILTERS_FIELD', field: 'sentiment', value: defaultValue.map((value) => value?.type ?? value) });
  }, []);

  useEffect(() => {
    if (!urlQueryState.sentiment) return;
    // TODO: validate better filters
    if (urlQueryState.sentiment !== 'negative' && urlQueryState.sentiment !== 'positive') return;

    setList([urlQueryState.sentiment]);
  }, [urlQueryState.sentiment]);

  return (
    <div className="space-y-0.5">
      <Dropdown
        trigger={['click']}
        overlayClassName="!px-3"
        align={{ offset: [0, -10] }}
        overlay={(
          <Menu className="!rounded-md shadow-[0px_1px_12px_#CAD7E180] border border-solid border-[#DFE9F1]">
            {Object.values(sentimentsList).map((zen, index) => (
              <Menu.Item
                className="flex items-center flex items-center gap-2 hover:!bg-[#EFF4F8] !px-3 !rounded-[4px] !mb-0 !mt-0"
                key={zen.label}
                onClick={() => handleClick(Object.keys(sentimentsList)[index])}
              >
                {getSentimentIcon(zen.label.toLowerCase())}
                {zen.label}
              </Menu.Item>
            ))}
          </Menu>
        )}
      >
        <div className="group flex flex-col border-0 border-b border-solid border-[#EFF4F8] hover:border-[#DFE9F1] pb-3 cursor-pointer">
          <div className="group/button px-4 hover:border-[#DFE9F1] flex items-center justify-between text-[#75899B] text-sm">
            <span className="group-hover:text-[#44596C]">{name}</span>
            <div className="flex gap-1">
              <Button
                type="text"
                onClick={handleClear}
                className={cn(
                  'opacity-0 group-hover:opacity-100 flex items-center !rounded-md !p-2 justify-between !text-sm !text-[#75899B] hover:!bg-[#EFF4F8] hover:!text-[#44596C]',
                  list.length === 0 && 'group-hover:opacity-0',
                )}
              >
                <X size={20} />
              </Button>
              <Button
                type="text"
                className={cn(
                  'flex items-center !rounded-md !p-2 justify-between !text-sm !text-[#75899B]',
                  list.length === 0 ? 'group-hover/button:!bg-[#EFF4F8] group-hover/button:!text-[#44596C]' : 'hover:!bg-[#EFF4F8] hover:!text-[#44596C]',
                )}
              >
                <PlusIcon size={20} />
              </Button>
            </div>
          </div>
          {list.length > 0 ? (
            <div className="flex mt-1.5 gap-2 px-3 flex-wrap">
              {list.map((sentiment) => (
                <SentimentTag
                  key={sentiment}
                  label={sentiment}
                  value={sentiment}
                  closable
                  onClose={() => handleClick(sentiment)}
                />
              ))}
            </div>
          ) : null}
        </div>
      </Dropdown>
    </div>
  );
}
