export const getTextWithHighlightedKeywords = (text, keywords) => {
  let highlightedText = text;

  for (let idx = 0; idx < keywords.length; idx += 1) {
    const keyword = keywords[idx];
    highlightedText = highlightedText.replaceAll(keyword.text, `<b>${keyword.text}</b>`);
  }

  return highlightedText;
};
