import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Form, Input, Button, message, Divider,
} from 'antd';
import { Redirect } from 'react-router-dom';
import { auth } from '@/api';
import userAuthenticatedAction from '../../store/actions/userAuthenticated';
import rules from '../../helpers/rules';
import { displayOrCatch, useErrorState } from '../../helpers';
import PopoutForm from '../forms/PopoutForm';

const onFinish = (token, displayError, setLoading, setSuccess) => (values) => {
  setLoading(true);
  auth
    .resetPassword(token, values)
    .then((response) => {
      message.success(response.data.data.general);
      setSuccess(true);
    })
    .catch((e) => {
      displayOrCatch(e, displayError);
      setLoading(false);
    });
};

const ResetPasswordForm = ({ token }) => {
  const [error, setError] = useErrorState();
  const [loading, setLoading] = useState();
  const [success, setSuccess] = useState(false);

  return success ? (
    <Redirect to="/login" />
  ) : (
    <PopoutForm
      className="!mt-6 !space-y-4 !px-4 md:!px-0"
      popoutError={error}
      onFinish={onFinish(token, setError, setLoading, setSuccess)}
    >
      <Form.Item className="!m-0" name="email" rules={rules.email}>
        <Input size="large" placeholder="Email" />
      </Form.Item>
      <Divider />
      <p className="m-0 text-sm text-[#75899B] font-[Gordita-Regular]">Choose a new password. Make sure it&apos;s strong!</p>
      <Form.Item className="!m-0" name="password" rules={rules.password}>
        <Input.Password size="large" placeholder="Password" />
      </Form.Item>
      <Form.Item className="!m-0">
        <Button
          loading={loading}
          size="large"
          className="cta"
          type="primary"
          htmlType="submit"
        >
          Save Password
        </Button>
      </Form.Item>
    </PopoutForm>
  );
};

const mapDispatchToProps = (dispatch) => ({
  userAuthenticated: (data) => dispatch(userAuthenticatedAction(data)),
});

export default connect(null, mapDispatchToProps)(ResetPasswordForm);
