import { Tag } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import React from 'react';

const ConversationLink = ({ location: { pathname }, conversation }) => (
  <Link
    style={{
      border: '1px solid #e1e1e1', borderRadius: '13px', padding: '0 3px 3px 0', lineHeight: '21px',
    }}
    to={`${pathname.startsWith('/demo') ? '/demo/' : '/'}topic/overview/${conversation.topic.id}/${conversation.topic_group.id}`}
  >
    <Tag style={{
      border: 'none',
      background: 'transparent',
      marginLeft: '4px',
      paddingRight: 0,
    }}
    >
      {conversation.topic_group.name}
    </Tag>
    <RightOutlined className="conversation-info-arrow" />
    <Tag style={{
      border: 'none',
      background: 'transparent',
      paddingLeft: 0,
      marginLeft: '2px',
    }}
    >
      {conversation.topic.name}
    </Tag>
  </Link>
);

export default withRouter(ConversationLink);
