/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/no-static-element-interactions */
import React, { useCallback, useState } from 'react';
import { Info, RotateCcw, ArrowLeft } from 'lucide-react';
import { Button, Modal, Spin } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useChatMessages, useChatInteract, useChatSession } from '@chainlit/react-client';
import { useChainlitStore } from '@/store/zustand/useChainlitStore';
import ChainlitModalInput from './ChainlitModalInput';
import ChainlitMessage from './ChainlitMessage';
import ChainlitAutoscrollDiv from './ChainlitAutoscrollDiv';
import './ChainltModal.less';

const getCompanyName = () => {
  try {
    return JSON.parse(localStorage.getItem('teams'))?.[0]?.company_name;
  } catch (e) {
    /**
     * do nothing
     */
  }

  return null;
};

const getQuestions = (topic, forHelp) => {
  const companyName = getCompanyName();
  const questions = topic ? [
    `Can you list the top ${topic} issues reported by ${companyName || '<< Your company name >>'} customers?`,
    `Can you list the top questions that ${companyName || '<< Your company name >>'} users are asking about ${topic}?`,
    `Can you list the top ${companyName || '<< Your company name >>'} users' requests about ${topic}?`,
  ] : [
    `Can you list the top issues that ${companyName || '<< Your company name >>'} customers are facing?`,
    `Can you list the top reasons why ${companyName || '<< Your company name >>'} customers might be churning?`,
    `Can you list the top ${companyName || '<< Your company name >>'} customers' painpoints?`,
    `Can you list the top bugs or errors reported by ${companyName || '<< Your company name >>'} customers?`,
    `Can you list the main issues that ${companyName || '<< Your company name >>'} customers are experiencing during the onboarding process?`,
    `Can you list the top issues that ${companyName || '<< Your company name >>'} customers have with ${companyName || '<< Your company name >>'} pricing?`,
    `What are ${companyName || '<< Your company name >>'} customers happy about?`,
    `Can you list the top sales opportunities that ${companyName || '<< Your company name >>'} might be missing on?`,
  ];

  if (forHelp) {
    return questions.slice(0, 5);
  }

  return questions;
};

const ChainlitModal = ({
  loading, close, expandedMessage, ...props
}) => {
  const [help, showHelp] = useState(false);
  const { messages } = useChatMessages();
  const { connect, session } = useChatSession();
  const { clear, sendMessage } = useChatInteract();
  const { token, topic } = useChainlitStore((state) => state);
  const lastMessage = messages.length ? messages.slice(-1)[0] : null;
  const resetChat = useCallback(() => {
    if (token) {
      clear();
      connect({
        userEnv: {
        },
        accessToken: `Bearer ${token}`,
      });
    }
  }, [clear, connect]);
  const sendChatMessage = useCallback((message) => {
    showHelp(false);
    sendMessage({
      output: message,
      id: uuidv4(),
      createdAt: new Date().toISOString(),
      type: 'user_message',
    }, []);
  }, [sendMessage, showHelp]);
  const closePopup = useCallback(() => {
    close();
    showHelp(false);
  }, [close, showHelp]);
  const companyName = getCompanyName();
  const questions = getQuestions(topic, false);
  const helpQuestions = getQuestions(topic, true);

  return (
    <Modal
      width={930}
      onOk={closePopup}
      onCancel={closePopup}
      destroyOnClose
      footer={null}
      className="ask_mantra_ai_modal"
      title={!help ? (
        <div className="flex items-center">
          <div style={{ lineHeight: '22px' }} className="text-[#44596C] mr-2">Ask Mantra AI &nbsp; &#183;</div>
          <div className="flex">
            <Button type="link" onClick={() => showHelp(true)}>
              <div className="flex gap-1 items-center font-[Gordita-Regular] text-[#44596C] hover:underline">
                <span className="anticon"><Info size={16} /></span>
                <span>How to ask questions?</span>
              </div>
            </Button>
          </div>
          {
            messages.length ? (
              <div className="w-[185px]">
                <Button onClick={resetChat} type="link">
                  <div className="flex gap-1 items-center font-[Gordita-Medium] text-[#44596C]">
                    <span className="anticon"><RotateCcw size={16} strokeWidth={3} /></span>
                    <span>Clear conversation</span>
                  </div>
                </Button>
              </div>
            ) : null
          }
        </div>
      ) : (
        <div className="flex items-center mr-3">
          <div className="flex justify-center">
            <Button style={{ marginLeft: '-20px' }} type="link" onClick={() => showHelp(false)}>
              <div className="flex gap-1 items-center font-[Gordita-Regular] text-[#44596C]">
                <span className="anticon"><ArrowLeft size={20} /></span>
              </div>
            </Button>
          </div>
          <div className="text-[#44596C] grow">How to ask questions?</div>
        </div>
      )}
      {...props}
    >
      {
        help ? (
          <div className="ask_mantra_ai_help">
            <ol>
              <li>
                <b>
                  Always give context. Make sure you include &apos;
                  {companyName || '<< Your company name >>'}
                  &apos; or &apos;
                  {companyName || '<< Your company name >>'}
                  {' '}
                  users&apos; in your question.
                </b>
              </li>
              <li>
                <b>Get more details out of Mantra&apos;s answers:</b>
                <br />
                &apos;Can you expand on the last point?&apos;
                <br />
                &apos;Can you give me more details?&apos;
                <br />
                &apos;Can you expand on each point in the list?&apos;
              </li>
              <li>
                <b>Use these as inspiration:</b>
                <ul>
                  {
                    helpQuestions.map((question) => (
                      <li onClick={() => sendChatMessage(question)}>
                        {question}
                      </li>
                    ))
                  }
                </ul>
              </li>
            </ol>
          </div>
        ) : (
          <>
            {
              !session?.socket?.connected ? (
                <div className="absolute top-0 left-0 w-full h-full flex z-20">
                  <div className="flex w-full items-center justify-center mb-16 bg-[#ffffff99]">
                    <Spin size="large" />
                  </div>
                </div>
              ) : null
            }
            <ChainlitAutoscrollDiv className="ask_mantra_ai_messages" loading={loading} messages={messages}>
              {
                !messages.length ? (
                  <div className="h-full ask_mantra_ai_predefined">
                    <ul>
                      {
                        questions.map((question) => (
                          <li>
                            <span onClick={() => sendChatMessage(question)}>
                              {question}
                            </span>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                ) : null
              }
              {messages.map((message) => (
                <ChainlitMessage key={message.id} message={message} />
              ))}
              {
                lastMessage?.type === 'user_message' ? (
                  <ChainlitMessage message={{
                    type: 'ai_message',
                    output: '',
                  }}
                  />
                ) : null
              }
            </ChainlitAutoscrollDiv>
            <div className="ask_mantra_ai_input">
              <ChainlitModalInput
                loading={loading || !session?.socket?.connected}
                expandedMessage={expandedMessage}
              />
            </div>
          </>
        )
      }
    </Modal>
  );
};

export default ChainlitModal;
