import { useEffect, useMemo, useState } from 'react';
import Papa from 'papaparse';
import { customFields } from '@/api';
import { displayOrCatchMessage } from '@/helpers';

const useCsvFile = (file, limit = 0) => {
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [loadingCustomFields, setLoadingCustomFields] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState([]);
  const rows = useMemo(() => [], [file]);
  const columns = useMemo(() => [], [file]);
  const previews = useMemo(() => ({}), [file]);

  useEffect(() => {
    setLoadingPreview(true);
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      worker: true,
      preview: limit,
      step(row) {
        rows.push(row.data);
        if (!columns.length) {
          const columnLength = row.meta.fields.length;
          for (let i = 0; i < columnLength; i++) {
            columns.push(row.meta.fields[i]);
          }
        }

        columns.forEach((column) => {
          if (!previews[column]) {
            previews[column] = [];
          }

          previews[column].push({
            [column]: row.data[column],
          });
        });
      },
      complete() {
        setLoadingPreview(false);
      },
    });
  }, [file]);

  useEffect(() => {
    if (loadingPreview || loadingCustomFields) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [loadingPreview, loadingCustomFields]);

  useEffect(() => {
    setLoadingCustomFields(true);
    setFields([]);
    customFields.getCustomFields().then(({ data }) => {
      setFields(data.filter((field) => field.type === 'conversation'));
      setLoadingCustomFields(false);
    }).catch((e) => {
      displayOrCatchMessage(e);
      setLoadingCustomFields(false);
    });
  }, []);

  return {
    loading,
    rows,
    columns,
    previews,
    fields,
  };
};

export default useCsvFile;
