import { Tabs } from 'antd';
import { Link, useParams } from 'react-router-dom';
import React from 'react';
import { getAiVersion } from '@/components/summary/SummaryResults';
import { connect } from 'react-redux';
import SentimentLanguageTab from './SentimentLanguageTab';
import PromptsTab from './PromptsTab';
import ExclusionsTab from './ExclusionTab';
import CustomFieldsTab from './CustomFieldsTab';
import LabelsTab from './LabelsTab';
import NotificationsTab from './NotificationsTab';

const { TabPane } = Tabs;

const getActiveKey = (tab) => {
  switch (tab) {
    case 'exclude-conversations': return 'settings_exclusions';
    case 'exclude-people': return 'settings_exclusions';
    case 'exclude-labels': return 'settings_exclusions';
    case 'labels': return 'settings_labels';
    case 'custom-fields': return 'settings_custom_fields';
    case 'notifications': return 'settings_notifications';
    case 'advanced': return 'settings_advanced';
    case 'prompts': return 'settings_prompts';
    default: return null;
  }
};

const SettingsPageComponent = ({ user }) => {
  const { tab } = useParams();

  return (
    <div className="mx-md datatable-header-half datatable-header-half-tabs settings-tabs-wrapper">
      <Tabs activeKey={getActiveKey(tab)}>
        <TabPane
          tab={<Link to="/settings/exclude-conversations">Exclusions</Link>}
          key="settings_exclusions"
        >
          <ExclusionsTab />
        </TabPane>
        <TabPane
          tab={<Link to="/settings/labels">Labels</Link>}
          key="settings_labels"
        >
          <LabelsTab />
        </TabPane>
        <TabPane
          tab={<Link to="/settings/custom-fields">Custom fields</Link>}
          key="settings_custom_fields"
        >
          <CustomFieldsTab />
        </TabPane>
        <TabPane
          tab={<Link to="/settings/notifications">Notifications</Link>}
          key="settings_notifications"
        >
          <NotificationsTab />
        </TabPane>
        <TabPane
          tab={<Link to="/settings/advanced">Advanced</Link>}
          key="settings_advanced"
        >
          <SentimentLanguageTab />
        </TabPane>
        {
            getAiVersion() === 'v2' && (user && user.email && user.email.endsWith('@clientzen.io')) ? (
              <TabPane
                tab={<Link to="/settings/prompts">Prompts</Link>}
                key="settings_prompts"
              >
                <PromptsTab />
              </TabPane>
            ) : null
        }
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export const SettingsPage = connect(mapStateToProps, null)(SettingsPageComponent);
