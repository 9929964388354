import React from 'react';
import BillingPlanCheckmark from './BillingPlanCheckmark';
import BillingPlan from './BillingPlan';

const WorldClassBillingPlan = ({ onClick, isAnnuallySubscriptionSelected, ...props }) => (
  <BillingPlan
    onClick={onClick}
    title="Pro"
    titleColor="#0BAA6B"
    price={isAnnuallySubscriptionSelected ? 490 : 588}
    buttonText="Get plan"
    benefitsContainerStyle={{ backgroundColor: '#F4FFF4', border: '1px solid #0BAA6B' }}
    isAnnuallySubscriptionSelected={isAnnuallySubscriptionSelected}
    {...props}
  >
    <BillingPlanCheckmark text="Automated tagging" />
    <BillingPlanCheckmark text="Painpoints discovery by Mantra AI" />
    <BillingPlanCheckmark text="Advanced sentiment analysis" />
    <BillingPlanCheckmark text="∞ data sources" />
    <BillingPlanCheckmark text="5000 conversations / month" />
    <BillingPlanCheckmark text="∞ team members" />
    <BillingPlanCheckmark text="Advanced segmentation" />
    <BillingPlanCheckmark text="Customer insights" />
    <BillingPlanCheckmark text="Dedicated account manager" missingBenefit />
    <BillingPlanCheckmark text="Custom integrations" missingBenefit />
  </BillingPlan>
);

export default WorldClassBillingPlan;
