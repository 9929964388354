/* eslint-disable react/jsx-filename-extension, max-len */
import getClientInsightData from './client';
import getColleagueInsightData from './colleague';

const getInsightData = (row) => {
  if (row.colleague) {
    return getColleagueInsightData(row);
  }

  return getClientInsightData(row);
};

const getDashboardInsightData = (row) => {
  const data = getInsightData(row);

  return {
    ...data.client,
    heading: data.text,
    id: data.key,
    key: data.key,
  };
};

const getClientHappiness = (score) => {
  if (score < 40) {
    return 'notHappy';
  }
  if (score < 60) {
    return 'neutral';
  }

  return 'happy';
};

const getDashboardHighlightedData = (row) => {
  const data = getInsightData(row);

  return {
    id: data.key,
    title: data.text,
    description: `${data.client.company} - ${data.created}`,
    client: data.client,
    clientHappiness: getClientHappiness(data.client.score),
    backgroundColor: row.background_color,
    feedbackType: row.feedback_type,
    item: data,
  };
};

export {
  getInsightData,
  getDashboardInsightData,
  getDashboardHighlightedData,
};
