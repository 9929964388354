import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const useThreadIdStore = create(devtools((set) => ({
  threadId: null,
  setThreadId: (threadId) => set({ threadId }),
  reset: () => set({ threadId: null }),
}), {
  name: 'useThreadIdStore',
}));
