import React from 'react';
import BillingPlanCheckmark from './BillingPlanCheckmark';
import BillingPlan from './BillingPlan';

const EnterpriseBillingPlan = ({ onClick, isAnnuallySubscriptionSelected, ...props }) => (
  <BillingPlan
    onClick={onClick}
    title="Enterprise"
    titleColor="#000000"
    buttonText="Get a demo"
    benefitsContainerStyle={{ backgroundColor: '#F4F9FF' }}
    isAnnuallySubscriptionSelected={isAnnuallySubscriptionSelected}
    {...props}
  >
    <BillingPlanCheckmark text="Automated tagging" />
    <BillingPlanCheckmark text="Painpoints discovery by Mantra AI" />
    <BillingPlanCheckmark text="Advanced sentiment analysis" />
    <BillingPlanCheckmark text="∞ data sources" />
    <BillingPlanCheckmark text="∞ conversations / month" />
    <BillingPlanCheckmark text="∞ team members" />
    <BillingPlanCheckmark text="Advanced segmentation" />
    <BillingPlanCheckmark text="Customer insights" />
    <BillingPlanCheckmark text="Dedicated account manager" />
    <BillingPlanCheckmark text="Custom integrations" />
  </BillingPlan>
);

export default EnterpriseBillingPlan;
