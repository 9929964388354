/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, {
  useCallback, useMemo, useReducer, useEffect, useImperativeHandle,
} from 'react';
import {
  Select, Typography, Tag,
} from 'antd';
import classNames from 'classnames';
import { PlusOutlined } from '@ant-design/icons';
import './TagSelect.less';

const { Option } = Select;
const { Text } = Typography;

const SET_SEARCH = 'tagSelect/SET_SEARCH';
const SET_HIGHLIGHT = 'tagSelect/SET_HIGHLIGHT';
const REMOVE_HIGHLIGHT = 'tagSelect/REMOVE_HIGHLIGHT';
const ADD_ITEM = 'tagSelect/ADD_ITEM';
const RESET_ITEMS = 'tagSelect/RESET_ITEMS';
const SET_LOCKED = 'tagSelect/SET_LOCKED';
const SELECT_ITEM = 'tagSelect/SELECT_ITEM';
const DESELECT_ITEM = 'tagSelect/DESELECT_ITEM';
const MOUNT_COMPONENT = 'tagSelect/MOUNT_COMPONENT';
const REPLACE_ITEM = 'tagSelect/REPLACE_ITEM';

const initialState = {
  highlighted: '',
  search: '',
  items: [],
  locked: false,
  value: [],
  mounted: false,
};

const tagSelectReducer = (prevState, action) => {
  switch (action.type) {
    case MOUNT_COMPONENT: {
      return {
        ...prevState,
        mounted: true,
      };
    }
    case SET_HIGHLIGHT: {
      return {
        ...prevState,
        highlighted: prevState.highlighted === action.payload ? '' : action.payload,
      };
    }
    case REMOVE_HIGHLIGHT: {
      return {
        ...prevState,
        highlighted: '',
      };
    }
    case SET_SEARCH: {
      return {
        ...prevState,
        search: action.payload,
      };
    }
    case RESET_ITEMS: {
      return {
        ...prevState,
        search: '',
        highlighted: '',
        items: [],
        value: [],
      };
    }
    case REPLACE_ITEM: {
      const oldItemIndex = prevState.items.indexOf(action.payload.oldItem);
      const oldValueIndex = prevState.value.indexOf(action.payload.oldItem);

      if (oldItemIndex !== -1 && oldValueIndex !== -1) {
        const items = [...prevState.items];
        items[oldItemIndex] = action.payload.newItem;
        const value = [...prevState.value];
        value[oldValueIndex] = action.payload.newItem;

        return {
          ...prevState,
          search: '',
          highlighted: '',
          items,
          value,
        };
      }

      return prevState;
    }
    case ADD_ITEM: {
      return {
        ...prevState,
        search: '',
        highlighted: '',
        locked: true,
        items: prevState.items.find((item) => item === action.payload) ? prevState.items : [
          action.payload,
          ...prevState.items,
        ],
        value: prevState.value.find((item) => item === action.payload) ? prevState.value : [
          ...prevState.value,
          action.payload,
        ],
      };
    }
    case SET_LOCKED: {
      return {
        ...prevState,
        locked: action.payload,
      };
    }
    case SELECT_ITEM: {
      return {
        ...prevState,
        search: '',
        highlighted: '',
        value: prevState.value.find((item) => item === action.payload) ? prevState.value : [
          ...prevState.value,
          action.payload,
        ],
      };
    }
    case DESELECT_ITEM: {
      return {
        ...prevState,
        search: '',
        highlighted: prevState.highlighted === action.payload ? '' : prevState.highlighted,
        items: prevState.locked ? prevState.items
          : prevState.items.filter((item) => item !== action.payload),
        value: prevState.locked ? prevState.value
          : prevState.value.filter((item) => item !== action.payload),
      };
    }
    default: return prevState;
  }
};

const TagSelect = ({
  onChange,
  onHighlightedChange,
  hasHighlight,
  hasReadOnlyHighlight,
  value = [],
  extendedLayout,
  suffix,
  suffixOptions,
  tagRender,
  ...rest
}, ref) => {
  const [state, dispatch] = useReducer(tagSelectReducer, initialState,
    (initState) => ({
      ...initState,
      value,
      items: value,
    }));

  const setSearch = useCallback((searchValue) => {
    dispatch({ type: SET_SEARCH, payload: searchValue });
  }, [dispatch]);

  const setHighlighted = useCallback((highlightedValue) => () => {
    dispatch({ type: SET_HIGHLIGHT, payload: highlightedValue });
  }, [dispatch]);

  const removeHighlight = useCallback(() => {
    dispatch({ type: REMOVE_HIGHLIGHT });
  }, [dispatch]);

  const addItem = useCallback((item) => {
    dispatch({ type: ADD_ITEM, payload: `${item}${suffix || ''}` });
    setTimeout(() => {
      dispatch({ type: SET_LOCKED, payload: false });
    }, 100);
  }, [dispatch, suffix]);

  const replaceItem = useCallback((oldItem, newItem) => {
    dispatch({
      type: REPLACE_ITEM,
      payload: {
        oldItem, newItem,
      },
    });
  }, [dispatch]);

  const resetItems = useCallback(() => {
    dispatch({ type: RESET_ITEMS, payload: null });
  }, [dispatch]);

  const selectItem = useCallback((item) => {
    dispatch({ type: SELECT_ITEM, payload: `${item}${suffix || ''}` });
  }, [dispatch, suffix]);

  const deselectItem = useCallback((item) => {
    dispatch({ type: DESELECT_ITEM, payload: item });
  }, [dispatch]);

  const shouldShowCreate = useMemo(() => state.search !== ''
    && !state.items.find((item) => item === state.search), [state.search, state.items]);

  const noItems = useMemo(() => state.items.filter((item) => item
    .toLowerCase().indexOf(state.search.toLowerCase()) !== -1).length, [state.search, state.items]);

  const renderItem = (item) => {
    if (suffixOptions) {
      for (let i = 0; i < suffixOptions.length; i++) {
        const suffixOption = suffixOptions[i];
        if (suffixOption.value && item.endsWith(suffixOption.value)) {
          return item.substring(0, item.length - suffixOption.value.length);
        }
      }
    }

    return item;
  };

  const renderItemType = (item) => {
    if (suffixOptions) {
      for (let i = 0; i < suffixOptions.length; i++) {
        const suffixOption = suffixOptions[i];
        if (suffixOption.value && item.endsWith(suffixOption.value)) {
          return suffixOption.label;
        }
      }
    }

    for (let i = 0; i < suffixOptions.length; i++) {
      const suffixOption = suffixOptions[i];
      if (!suffixOption.value) {
        return suffixOption.label;
      }
    }

    return '';
  };

  const showCreate = useCallback((visible, wrap = true) => {
    if (visible) {
      if (wrap) {
        return (
          <div
            className="ant-select-item ant-select-item-option tag-select-creator-wrapper"
            onClick={() => addItem(state.search)}
          >
            <div className="ant-select-item-option-content tag-select-creator">
              <PlusOutlined />
              <span className="tag-select-creator__text">{`Add Keyword "${state.search}"`}</span>
            </div>
          </div>
        );
      }
      return (
        <Text
          type="primary"
          onClick={() => addItem(state.search)}
          className="tag-select-creator"
        >
          <PlusOutlined />
          <span className="tag-select-creator__text">{`Add keyword "${state.search}"`}</span>
        </Text>
      );
    }

    return null;
  }, [state.search]);

  const onInputKeyDown = useCallback((e) => {
    if ((e.keyCode === 13 || e.keyCode === 59) && shouldShowCreate) {
      addItem(state.search);
    }
  }, [state.search, shouldShowCreate, addItem]);

  const onBlur = useCallback(() => {
    if (shouldShowCreate) {
      addItem(state.search);
    }
  }, [state.search, shouldShowCreate, addItem]);

  useImperativeHandle(ref, () => ({
    setHighlighted,
    removeHighlight,
    setSearch,
    addItem,
    resetItems,
    selectItem,
    deselectItem,
    replaceItem,
  }));

  useEffect(() => {
    dispatch({ type: MOUNT_COMPONENT });
  }, []);

  useEffect(() => {
    if (state.mounted) {
      onChange?.(state.value);
    }
  }, [state.value]);

  useEffect(() => {
    if (state.mounted) {
      onHighlightedChange?.(state.highlighted);
    }
  }, [state.highlighted]);

  return (
    <>
      <div className={classNames('tag-select-container', { extended: !!extendedLayout })}>
        <Select
          {...rest}
          mode="multiple"
          searchValue={state.search}
          onSearch={setSearch}
          onBlur={onBlur}
          onInputKeyDown={onInputKeyDown}
          onSelect={selectItem}
          onDeselect={deselectItem}
          notFoundContent={showCreate(shouldShowCreate, false)}
          dropdownClassName={hasHighlight ? 'tag-select-creator-overlay' : 'tag-select-creator-simple-overlay'}
          dropdownRender={(menu) => (
            <>
              {showCreate(hasHighlight ? shouldShowCreate : shouldShowCreate && noItems)}
              {!hasHighlight ? menu : null}
            </>
          )}
          tagRender={hasHighlight ? () => null : tagRender}
          value={state.value}
        >
          {state.items.map((item) => <Option key={item} value={item}>{item}</Option>)}
        </Select>
        {extendedLayout && (
          <div className="extended-item">{extendedLayout}</div>
        )}
      </div>

      <div className="tag-select-creator-outside-container">
        {
          hasHighlight ? state.value.map((item) => (
            <Tag
              key={item}
              color={state.highlighted === item ? 'success' : 'default'}
              onClick={!hasReadOnlyHighlight ? setHighlighted(item) : null}
              className={classNames('tag-select-creator-outside-tag', {
                'tag-select-creator-outside-tag-clickable': !hasReadOnlyHighlight,
              })}
              closable
              onClose={() => deselectItem(item)}
            >
              <div className="tag-select-creator-item">
                <span>
                  <span>{renderItem(item)}</span>
                  <span className="item-type">{renderItemType(item)}</span>
                </span>
              </div>
            </Tag>
          )) : null
        }
      </div>
    </>
  );
};

export default React.forwardRef(TagSelect);
