import { addItem, addItems } from './firstClients';

export default (state = [], action) => {
  switch (action.type) {
    case 'ADD_FIRST_COLLEAGUE': return addItem(state, action.payload);
    case 'REMOVE_FIRST_COLLEAGUE':
      return state.filter((item) => item.email !== action.payload.email);
    case 'CLEAN_FIRST_COLLEAGUES':
      return [];
    case 'RECENT_INTERACTIONS_ADD_ALL_COLLEAGUES':
      return addItems(state, action.loadedItems);
    default:
      return state;
  }
};
