import { conversations } from '@/api';
import { useQuery } from '@tanstack/react-query';

export default function useConversations(filters, { perPage, currentPage }) {
  return useQuery({
    queryKey: ['conversations', { filters, perPage, currentPage }],
    queryFn: async () => {
      const response = await conversations.getConversations(filters, perPage, currentPage);
      return response;
    },
  });
}
