import React from 'react';
import { Tooltip } from 'antd';

const SentimentLabel = ({
  offset,
  value,
  viewBox,
  fullValue,
}) => (
  <foreignObject
    x={viewBox.x - 15}
    y={viewBox.y - offset - 35}
    width={1}
    height={1}
    style={{ overflow: 'visible' }}
  >
    <Tooltip title={fullValue} overlayStyle={{ fontSize: 12 }}>
      <div className="chart-annotation-label">
        {value}
      </div>
    </Tooltip>
  </foreignObject>
);

export default SentimentLabel;
