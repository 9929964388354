import * as React from 'react';
import { memo } from 'react';

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <defs>
      <style>
        {
          '.csv-d{stroke:#0baa6b;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}'
        }
      </style>
    </defs>
    <path
      style={{
        fill: '#fff',
      }}
      d="M0 0h40v40H0z"
    />
    <path
      d="M22.337 3H9.267A3.267 3.267 0 0 0 6 6.267v26.139a3.267 3.267 0 0 0 3.267 3.267h19.6a3.267 3.267 0 0 0 3.267-3.267V12.8Z"
      transform="translate(.93 .663)"
      style={{
        fill: '#eaf5fc',
      }}
    />
    <path
      d="M21 3v9.8h9.8"
      transform="translate(2.267 .663)"
      style={{
        fill: '#ddedf7',
      }}
    />
    <path className="csv-d" d="M15.098 30.347h9.8M19.999 26.525v-8.693" />
    <path
      d="M7.5 11.846 11.846 7.5l4.346 4.346"
      transform="translate(8.153 10.332)"
      style={{
        fill: 'none',
        stroke: '#0baa6b',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: '1.5px',
      }}
    />
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
