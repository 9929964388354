import React from 'react';
import { withRouter } from 'react-router-dom';
import { Tag } from 'antd';
import ConversationSource from './ConversationSource';
import ConversationWithHighlightedKeywords from './ConversationWithHighlightedKeywords';

const ConversationContent = ({ conversation, showKeywords }) => {
  const text = conversation.isTextCollapsed ? conversation.snippet : conversation.text;
  const keywords = conversation.isTextCollapsed
    ? conversation.snippet_keywords : conversation.keywords;

  const clientTag = conversation.client && (
    <Tag
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 12,
        borderColor: 'transparent',
        borderRadius: 14,
      }}
      icon={(
        <ConversationSource
          conversation={conversation}
          style={{
            borderRight: '1px solid #E8E8E8',
            paddingRight: 4,
          }}
        />
      )}
    >
      <span>{conversation.client.name}</span>
    </Tag>
  );

  return (
    <>
      <div className="conversation-content-text-container">
        <ConversationWithHighlightedKeywords
          text={text}
          keywords={showKeywords ? keywords : []}
          isCollapsed={conversation.isTextCollapsed}
          className="conversation-content-text"
        />
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: 10,
          marginTop: 10,
        }}
        >
          {clientTag}
        </div>
      </div>
    </>
  );
};

export default withRouter(ConversationContent);
