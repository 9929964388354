import React from 'react';

export const Icons = {
  Taxonomy: (props) => (
    <svg id="icon-topics" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
      <rect id="Rectangle" width="18" height="18" fill="none" />
      <g id="Group_4822" data-name="Group 4822" transform="translate(-118 -59.438)">
        <rect id="Rectangle_3458" data-name="Rectangle 3458" width="18" height="18" transform="translate(118 59.438)" fill="none" />
        <g id="Group_4820" data-name="Group 4820" transform="translate(122.285 60.227)">
          <path id="Path_1753" data-name="Path 1753" d="M9.516,10.577h0A1.681,1.681,0,1,1,11.2,12.259,1.681,1.681,0,0,1,9.516,10.577Z" transform="translate(-2.954 -2.812)" fill="none" stroke="#44596c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
          <path id="Path_1754" data-name="Path 1754" d="M9.516,18.991h0A1.681,1.681,0,1,1,11.2,20.673,1.681,1.681,0,0,1,9.516,18.991Z" transform="translate(-2.954 -4.743)" fill="none" stroke="#44596c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
          <path id="Path_1755" data-name="Path 1755" d="M1,2.681H1A1.681,1.681,0,1,1,2.681,4.363,1.681,1.681,0,0,1,1,2.681Z" transform="translate(-1 -1)" fill="none" stroke="#44596c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
          <path id="Path_1756" data-name="Path 1756" d="M7.571,17.521H3.7a.5.5,0,0,1-.52-.472V11.377A.5.5,0,0,1,3.7,10.9h3.87" transform="translate(-1.5 -3.273)" fill="none" stroke="#44596c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
          <line id="Line_752" data-name="Line 752" y1="4.831" transform="translate(1.681 4.112)" fill="none" stroke="#44596c" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" />
        </g>
      </g>
    </svg>
  ),
};
