import React, { useEffect, useState } from 'react';
import PeopleTable from '@/base-components/people-table';
import { Helmet } from 'react-helmet';
import { useFiltersStore } from '@/store/zustand/useFiltersStore';
import AddFilterButton from '@/components/bucket/AddFilterButton';
import { Divider } from 'antd';
import FiltersHorizontalList from '@/components/bucket/FiltersHorizontalList';
import SegmentDropdown from '@/components/people/components/CreateSegment/SegmentDropdown';
import ExportButton from '@/components/ui/export-button';
import { isEqual } from 'lodash';
import useFilters from './useFilters';
import SearchInput from './components/SearchInput';
import Layout from '../Layout';
import { SentimentDateFilter } from '../ui/sentiment-date-filter';
import { FiltersProvider, useFilters as useFiltersContext } from '../filter/context';

const PeopleBody = ({ searchValue }) => {
  const {
    sorts, setSort,
  } = useFilters();

  const { filters, setFilters } = useFiltersStore();
  const { filters: lFilters } = useFiltersContext();

  useEffect(() => {
    if (!isEqual(lFilters, filters)) setFilters(lFilters);
  }, [lFilters]);

  return (
    <section className="people mx-7 2xl:mx-auto 2xl:max-w-[1200px]">
      <div className="people__body">
        <div className="flex bucket-top-row !pl-0">
          <AddFilterButton />
          <Divider type="vertical" />
          <SegmentDropdown />
        </div>
        <FiltersHorizontalList showSaveButton={false} />
        <PeopleTable
          perPage={10}
          setSort={setSort}
          sorts={sorts}
          searchValue={searchValue}
        />
      </div>
    </section>
  );
};

const People = () => {
  const { filters } = useFiltersStore();
  const [search, setSearch] = useState('');

  const getAppliedFilters = () => {
    const defaultFilters = ['included_keywords', 'sentiment', 'custom_fields', 'excluded_keywords', 'labels', 'sources', 'topic', 'segments', 'nps'];
    const appliedFilters = Object.keys(filters).filter((key) => defaultFilters.includes(key) && filters[key].length > 0);

    if (appliedFilters.length === 0) return ['included_keywords', 'sentiment', 'custom_fields'];
    return appliedFilters;
  };

  return (
    <Layout
      leftHeader={(
        <>
          <SearchInput search={filters.name} setSearch={setSearch} />
        </>
      )}
      rightHeader={(
        <>
          <SentimentDateFilter />
          <ExportButton filename="clientzen-people-export" type="people" searchValue={search} />
        </>
      )}
    >
      <Helmet titleTemplate="People - ClientZen" title="Discovery" />
      <FiltersProvider value={{ filters, selectedFilters: getAppliedFilters() }}>
        <PeopleBody searchValue={search} />
      </FiltersProvider>
    </Layout>
  );
};

export default People;
