import React from 'react';
import { CheckCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useHistory, withRouter } from 'react-router-dom';
import TrackedButton from '../TrackedButton';
import { mixpanelEvents } from '../../mixpanel';

const NoData = ({ openNewTopicModal, location: { pathname } }) => {
  const history = useHistory();

  return (
    <div className="no-data-box">
      <div className="content">
        <div className="title">
          Get started with topics
        </div>
        <TrackedButton
          event={mixpanelEvents.CREATE_TOPIC_FROM_DISCOVERY_CLICKED}
          icon={<CheckCircleOutlined />}
          type="primary"
          onClick={() => history.push(`${pathname.startsWith('/demo') ? '/demo/discovery' : '/discovery'}`)}
        >
          Confirm topics from discovery
        </TrackedButton>
        <div className="gordita-regular-1 text-1">
          Go to the first menu item on the left and confirm insights that are relevant.
        </div>
        <TrackedButton
          event={mixpanelEvents.CREATE_TOPIC_CLICKED}
          icon={<PlusCircleOutlined />}
          type="primary"
          onClick={openNewTopicModal}
        >
          Create topics from scratch
        </TrackedButton>
        <div className="gordita-regular-1">
          Create topics based on what you know customers are experiencing.
        </div>
      </div>
    </div>
  );
};

export default withRouter(NoData);
