import { customFields } from '@/api';
import { useQuery } from '@tanstack/react-query';

export function useCustomFieldsValues(enabled, id, search = '') {
  return useQuery({
    queryKey: ['custom_fields_values', id, search],
    queryFn: async () => {
      const { data } = await customFields.getCustomFieldValues({ id, search });

      if (data.length) {
        return [
          { value: '"CLZ$$all$$"' },
          ...data,
        ];
      }

      return data;
    },
    enabled,
  });
}
