import React from 'react';
import { DislikeOutlined, LikeOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import GhostButton from '../GhostButton';

const getColor = (feedback, highlighted) => {
  if (highlighted && feedback === 'positive') {
    return '#28b87a';
  }

  if (highlighted && feedback === 'negative') {
    return '#ff4d4f';
  }

  return '#595959';
};

const InsightPartHelpfulMarkButton = ({
  type, feedback, highlighted, style, ...props
}) => (
  <Tooltip className="mx-sm" title={feedback === 'positive' ? 'Accurate' : 'Innacurate'}>
    <GhostButton
      type={type || 'primary'}
      style={{
        color: getColor(feedback, highlighted), fontWeight: highlighted ? 700 : 'normal', ...style,
      }}
      {...props}
    >
      {
        feedback === 'positive' ? (
          <>
            <LikeOutlined style={{ marginRight: 5 }} />
          </>
        ) : (
          <>
            <DislikeOutlined style={{ marginRight: 5 }} />
          </>
        )
      }
    </GhostButton>
  </Tooltip>
);

export default InsightPartHelpfulMarkButton;
