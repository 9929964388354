import React from 'react';
import { Link } from 'react-router-dom';
import { cn } from '@/helpers/util';
import { mrrFormatter } from '@/components/bucket/lib/utils';
import { ArrowDown, ArrowUp, InfoIcon } from 'lucide-react';
import { Tooltip } from 'antd';

export function StatsCard({
  title = 'All feedback', value = 0, trend = 'ascending', trendValue = 0, children, className, link = null, tooltip = null,
}) {
  const isMRR = title === 'MRR';
  const isRatio = title === 'Ratio';
  return (
    <div className={cn('flex flex-col gap-0.5', className)}>
      <div className="flex items-center gap-x-1.5">
        <span className="text-xs text-[#75899B]">{title}</span>
        {tooltip ? (
          <Tooltip title={tooltip} placement="bottom">
            <InfoIcon size={12} strokeWidth={2.5} color="#75899B" />
          </Tooltip>
        ) : null}
      </div>
      {children || (
        <div className="flex items-center gap-0.5 mt-0.5">
          {link ? (
            <Link to={link}>
              <span className="text-[#202324] font-[Gordita-Medium] text-lg mr-1 hover:underline">
                {isMRR && `${mrrFormatter(value)}`}
                {isRatio && `${Math.ceil(value * 100)}%`}
                {!isMRR && !isRatio && value ? value.toFixed(0) : '0'}
              </span>
            </Link>
          ) : (
            <span className="text-[#202324] text-lg">
              {isMRR && `${mrrFormatter(value)}`}
              {isRatio && `${Math.ceil(value * 100)}%`}
              {!isMRR && !isRatio && value ? value.toFixed(0) : '0'}
            </span>
          )}
          {
            trend === 'ascending'
              ? <ArrowUp size={14} strokeWidth={2.5} color={!isMRR ? '#EDA2B4' : '#A9BCCF'} />
              : <ArrowDown size={14} strokeWidth={2.5} color={!isMRR ? '#A9BCCF' : '#EDA2B4'} />
          }
          {!isRatio ? (
            <span className="text-xs text-[#75899B]">
              {trend === 'ascending' ? '+' : '-'}
              {isMRR && `${mrrFormatter(trendValue)}`}
              {!isMRR && !isRatio && value ? `${Math.ceil((trendValue / value) * 100)}%` : '0%'}
            </span>
          ) : null}
        </div>
      )}
    </div>
  );
}
