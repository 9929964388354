/* eslint-disable react/jsx-filename-extension, max-len */
import moment from 'moment';
import React from 'react';
import { getClientData } from '../../clients';
import getColleagueAddedToClientzenData from './getColleagueAddedToClientzenData';
import getColleagueIsHappyData from './getColleagueIsHappyData';
import getColleagueNeedsAttentionData from './getColleagueNeedsAttentionData';
import getColleagueIsUnhappyData from './getColleagueIsUnhappyData';
import getColleagueNoFeedbackData from './getColleagueNoFeedbackData';
import getColleagueNoFeedbackThreeDaysData from './getColleagueNoFeedbackThreeDaysData';
import getColleagueNoFeedbackOneWeekData from './getColleagueNoFeedbackOneWeekData';
import getColleagueNoFeedbackTwoWeeksData from './getColleagueNoFeedbackTwoWeeksData';
import getColleagueNoFeedbackOneMonthData from './getColleagueNoFeedbackOneMonthData';
import getColleagueIsEvanghelistData from './getColleagueIsEvanghelistData';
import getColleagueSentMessageAtUnusualHour from './getColleagueSentMessageAtUnusualHour';

function ucfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const getUnknownInsightData = (row) => ({
  text: ucfirst(row.type).replace(/_/gi, ' '),
  description: '',
  recommended_step1: '',
  recommended_step2: '',
  recommended_step3: '',
  recommended_step1_description: (
    <>
    </>
  ),
  recommended_step2_description: (
    <>
    </>
  ),
  hasMessages: true,
  hasRecommendation: false,
});

const getInsightMessageBody = (message) => {
  if (message.body) {
    return message.body;
  }

  if (message.html_body) {
    return message.html_body;
  }

  return message.snippet;
};

const getInsightMessage = (message) => ({
  key: message.id,
  text: message.inaccessible ? 'Sorry, you do not have access to see this message. Contact the team member to request it.' : getInsightMessageBody(message),
  created: moment(message.external_date).from(moment()),
  keywords: {},
  helpful: message.helpful,
  inaccessible: message.inaccessible,
  to: message.to,
  from: message.from,
  cc: message.cc,
});

const computeInsightData = (statusDataHandler, row) => ({
  key: row.id,
  client: getClientData(row.colleague),
  status: row.status,
  created: moment(row.created_at).from(moment()),
  messages: row.messages ? row.messages.map(getInsightMessage) : [],
  marked_as_helpful: row.marked_as_helpful,
  marked_as_helpful_by: row.marked_as_helpful_by,
  marked_as_helpful_at: row.marked_as_helpful_at,
  background_color: row.background_color,
  foreground_color: row.foreground_color,
  feedback_type: row.feedback_type,
  shown_on_dashboard: row.shown_on_dashboard,
  can_be_resolved: row.can_be_resolved,
  can_be_unuseful: row.can_be_unuseful,
  previous_insight_id: row.previous_insight_id,
  next_insight_id: row.next_insight_id,
  previous_per_client_insight_id: row.previous_per_client_insight_id,
  next_per_client_insight_id: row.next_per_client_insight_id,
  hasMessages: true,
  hasRecommendation: true,
  ...statusDataHandler(row, JSON.parse(row.data)),
});

const getColleagueInsightData = (row) => {
  switch (row.type) {
    case 'colleague_added_to_clientzen': return computeInsightData(getColleagueAddedToClientzenData, row);
    case 'colleague_is_evangelist': return computeInsightData(getColleagueIsEvanghelistData, row);
    case 'colleague_is_happy': return computeInsightData(getColleagueIsHappyData, row);
    case 'colleague_needs_attention': return computeInsightData(getColleagueNeedsAttentionData, row);
    case 'colleague_is_unhappy': return computeInsightData(getColleagueIsUnhappyData, row);
    case 'colleague_no_feedback': return computeInsightData(getColleagueNoFeedbackData, row);
    case 'colleague_no_feedback_three_days': return computeInsightData(getColleagueNoFeedbackThreeDaysData, row);
    case 'colleague_no_feedback_one_week': return computeInsightData(getColleagueNoFeedbackOneWeekData, row);
    case 'colleague_no_feedback_two_weeks': return computeInsightData(getColleagueNoFeedbackTwoWeeksData, row);
    case 'colleague_no_feedback_one_month': return computeInsightData(getColleagueNoFeedbackOneMonthData, row);
    case 'colleague_sent_message_at_unusual_hour': return computeInsightData(getColleagueSentMessageAtUnusualHour, row);
    default: return computeInsightData(getUnknownInsightData, row);
  }
};

export default getColleagueInsightData;
