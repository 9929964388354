import React from 'react';
import { CheckOutlined, MinusOutlined } from '@ant-design/icons';

const BillingPlanCheckmark = ({ text, missingBenefit }) => (
  <div className="plan-benefit">
    {
      missingBenefit
        ? <MinusOutlined style={{ fontSize: 18, color: '#8C8C8C' }} />
        : <CheckOutlined style={{ fontSize: 18 }} />
    }
    <span style={{ color: missingBenefit ? '#8C8C8C' : '#000000' }} className="benefit-text">{text}</span>
  </div>
);

export default BillingPlanCheckmark;
