import React from 'react';

import { SmileIcon, MehIcon, FrownIcon } from 'lucide-react';

export const sentimentsList = {
  positive: {
    label: 'Positive',
    icon: SmileIcon,
    color: '#0CAA6B',
    backgroundColor: '#E3FCF0',
  },
  neutral: {
    label: 'Neutral',
    icon: MehIcon,
    color: '#A9BCCF',
    backgroundColor: '#EFF4F8',
  },
  negative: {
    label: 'Negative',
    icon: FrownIcon,
    color: '#F17D99',
    backgroundColor: '#FAE7EB',
  },
};

export const getSentimentIcon = (sentiment, size = 20, width = 1.5) => {
  switch (sentiment) {
    case 'positive':
      return <SmileIcon color="#0CAA6B" fill="#E3FCF0" size={size} strokeWidth={width} />;
    case 'neutral':
      return <MehIcon color="#A9BCCF" fill="#EFF4F8" size={size} strokeWidth={width} />;
    case 'negative':
      return <FrownIcon color="#F17D99" fill="#FAE7EB" size={size} strokeWidth={width} />;
    default:
      return <MehIcon color="#A9BCCF" fill="#EFF4F8" size={size} strokeWidth={width} />;
  }
};
