import React, { useEffect } from 'react';
import { message } from 'antd';
import ValidatedForm from './ValidatedForm';

const displayErrorMessage = (popoutError) => () => {
  if (popoutError) {
    message.error(popoutError.message);
  }
};

const PopoutForm = ({ popoutError, children, ...props }) => {
  useEffect(displayErrorMessage(popoutError), [popoutError]);

  return (
    <ValidatedForm
      {...props}
    >
      {children}
    </ValidatedForm>
  );
};

export default PopoutForm;
