import React from 'react';

const Add = ({ color = '#fff', ...rest }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...rest}>
    <g fill="none">
      <path data-name="Rectangle 30" d="M0 0h16v16H0z" />
      <g
        data-name="Group 1475"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      >
        <path
          data-name="Path 905"
          d="M13.938 8A5.937 5.937 0 1 1 8 2.063 5.937 5.937 0 0 1 13.938 8Z"
        />
        <path data-name="Path 906" d="M8.001 5.625v4.75" />
        <path data-name="Path 907" d="M5.626 8h4.75" />
      </g>
    </g>
  </svg>
);

export default Add;
