import { subtopics, topics } from '@/api';
import {
  SentimentRadioGroupDateFilter,
  useSentimentDateFilterInitialValue,
} from '@/base-components/sentiment-date-filter';
import { isValidParam } from '@/helpers/validators';
import {
  SET_MODAL_ACTION,
  SET_SUBTOPIC_ACTION,
  SET_TOPIC_ACTION,
  SET_LOADING_ACTION,
  modals,
} from '@/store/reducers/topic';
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, withRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {
  Affix, Button, Divider, Input, Modal, Spin, Tag, message,
} from 'antd';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { useBoolean } from '@/hooks/useBoolean';
import { GradientSentimentChart } from '@/base-components/sentiment-chart';
import classNames from 'classnames';
import SentimentTooltip from '@/base-components/SentimentTooltip';
import Progress from '@/components/ui/progress';
import TopicMetric from '@/base-components/topic-metric';
import ConversationsTable from '@/base-components/conversations-table';
import { debounce } from 'lodash';
import moment from 'moment';
import customerIo from '@/customer-io';
import CustomFiltersButton, { SelectedSource } from './components/CustomFiltersButton';
import SummaryButton from './components/SummaryButton';
import { useCustomFieldState } from './components/useCustomFieldState';
import SubtopicsContainer from './components/SubtopicsContainer';
import SelectedCustomField from './components/SelectedCustomField';
import TopicActions from './components/TopicActions';
import ModalTopicForm from '../TopicForm/ModalTopicForm';
import LoadableMetric from './components/LoadableMetric';
import { calculateDifference } from '../Painpoints/helpers';
import AnnotationActions from './components/AnnotationActions';
import AnnotationIcon from '../svgs/icons/Annotation';
import AnnotationCreateModal from './components/AnnotationCreateModal';
import AnnotationListModal from './components/AnnotationListModal';
import useLabels from './components/useLabels';
import { SelectedLabel } from '../settings/LabelsTab';

const prepareCustomFields = (customFields) => ({
  custom_field_id: customFields.map((field) => field.id),
  custom_field_value: customFields.map((field) => field.value),
});

function useTopic() {
  const { topicId } = useParams();
  const {
    topic, loading, group, reload,
  } = useSelector((state) => state.topic);

  return {
    id: topicId,
    name: topic?.name,
    loading,
    group,
    reload,
    shouldUpdateMetrics: topic?.shouldUpdateMetrics,
  };
}

function useSubtopic(isMounted) {
  const { subtopicId } = useParams();
  const anchor = useRef(null);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState(null);
  const [type, setType] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isMounted.current) return;
    if (!subtopicId) {
      setSelected(null);
      setType(null);
      return;
    }

    const currentSubtopic = list.find((item) => `${item.id}` === subtopicId);
    if (currentSubtopic === undefined) return;
    setSelected(currentSubtopic);
    setType(currentSubtopic.type);

    dispatch({
      type: SET_SUBTOPIC_ACTION,
      payload: { subtopic: currentSubtopic },
    });
  }, [subtopicId]);

  return {
    anchor,
    loading,
    setLoading,
    list,
    setList,
    selected,
    setSelected,
    type,
    setType,
  };
}

function useMetrics() {
  const { subtopicId } = useParams();
  const { topic, subtopic } = useSelector((s) => s.topic);

  const [stats, setStats] = useState({
    count: null,
    ratio: null,
    mrr: null,
    sentiment: null,
    annotations: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!topic) return;

    const {
      count, ratio, mrr, sentiment, annotations,
    } = subtopic !== null && subtopicId ? subtopic : topic;

    setStats({
      count,
      ratio,
      mrr,
      sentiment,
      annotations,
    });

    setLoading(false);
  }, [topic, subtopic, subtopicId]);

  return {
    ...stats,
    loading,
  };
}

function useReleases() {
  const dispatch = useDispatch();
  const { modal } = useSelector((state) => state.topic);
  const topic = useSelector((state) => state.topic.topic);
  const [releases, setReleases] = useState([]);

  useEffect(() => {
    setReleases(topic?.annotations);
  }, [topic]);

  const handleModalVisibility = (type) => dispatch({
    type: SET_MODAL_ACTION,
    payload: { modal: type },
  });

  const createModalProps = {
    visible: modal === modals.CREATE_ANNOTATION,
    onCancel: () => {
      dispatch({
        type: SET_MODAL_ACTION,
        payload: { modal: modals.NONE },
      });
    },
    onOk: () => {
      setReleases(topic?.annotations);
      dispatch({
        type: SET_MODAL_ACTION,
        payload: { modal: modals.NONE, dataReload: true },
      });
    },
  };

  const listModalProps = {
    visible: modal === modals.LIST_ANNOTATIONS,
    onCancel: () => {
      dispatch({
        type: SET_MODAL_ACTION,
        payload: { modal: modals.NONE },
      });
    },
    onOk: () => {
      dispatch({
        type: SET_MODAL_ACTION,
        payload: { modal: modals.LIST_ANNOTATIONS, dataReload: true },
      });
    },
  };

  return {
    releases,
    setReleases,
    handleModalVisibility,
    createModalProps,
    listModalProps,
  };
}

function useFilters() {
  const defaultValue = useSentimentDateFilterInitialValue();
  const [filters, setFilters] = useState({
    search: '',
    start: defaultValue.start,
    end: defaultValue.end,
    days: [],
  });
  return [filters, setFilters];
}

function useChartFilters(defaultFilters, customFields) {
  const { topicId } = useParams();
  const { subtopic, reload } = useSelector((state) => state.topic);

  const filters = useMemo(() => {
    const { start, end } = defaultFilters;

    const obj = {
      topic_id: null,
      suggested_subtopic_id: null,
      subtopic_id: null,
      start,
      end,
      week: moment(end).diff(moment(start), 'months') >= 3 ? 1 : 0,
      customFields: customFields.map(({ id, value }) => ({ id, value })),
    };

    if (subtopic) {
      obj[
        subtopic && subtopic.type === 'ai_model_based'
          ? 'suggested_subtopic_id'
          : 'subtopic_id'
      ] = subtopic.id;
    }
    obj.topic_id = topicId;

    return obj;
  }, [
    topicId,
    subtopic?.id,
    defaultFilters.start,
    defaultFilters.end,
    customFields,
    reload?.data,
  ]);

  return {
    filters,
  };
}

function useTopicModal() {
  const history = useHistory();
  const {
    value: visible,
    setFalse: setHidden,
    setTrue: setVisible,
  } = useBoolean();
  const { topic: data, group } = useSelector((state) => state.topic);
  const dispatch = useDispatch();

  const actionsProps = {
    onUpdate: () => {
      setVisible();
    },
    onDelete: () => {
      Modal.confirm({
        title: 'Are you sure you want to delete topic?',
        okText: 'Delete',
        cancelText: 'Cancel',
        okType: 'primary',
        cancelType: 'default',
        onOk: () => {
          message.loading('Topic is deleting');

          topics
            .deleteTopic(data.id)
            .then(() => {
              history.push('/topics');
              message.success('Topic deleted successfully');
            })
            .catch(() => {
              message.error('Failed deleting topic');
            });
        },
        okButtonProps: {
          danger: true,
        },
      });
    },
  };

  const modalProps = {
    visible,
    data: {
      ...data,
      group,
    },
    onCancel: setHidden,
    onOk: (topic, isEdit) => {
      setHidden();
      if (!isEdit) {
        history.push(`topic/overview/${topic.id}/${topic.groups[0].id}`);
      } else {
        dispatch({
          type: SET_MODAL_ACTION,
          payload: { modal: modals.NONE, pageReload: true },
        });
      }
    },
  };

  return {
    visible,
    setVisible,
    setHidden,
    actionsProps,
    modalProps,
  };
}

function useSubtopicModal() {
  const {
    value: visible,
    setFalse: setHidden,
    setTrue: setVisible,
  } = useBoolean();
  const dispatch = useDispatch();

  const modalProps = {
    subtopic: true,
    visible,
    onCancel: setHidden,
    onOk: () => {
      setHidden();
      dispatch({
        type: SET_MODAL_ACTION,
        payload: { modal: modals.NONE, pageReload: true },
      });
    },
  };

  return {
    visible,
    setVisible,
    setHidden,
    modalProps,
  };
}

function useConversation(subtopic, customFields, activeLabels, sources, filters) {
  const { topicId, groupId } = useParams();
  // ugly hack to force table reload
  const [searchFilters, setFilters] = useFilters();
  const { tab } = useSelector((state) => state.topic);

  const selectedSubtopic = subtopic.selected;
  const searchProps = useMemo(
    () => ({
      className: 'search',
      placeholder: 'Keywords',
      prefix: <SearchOutlined />,
      defaultValue: searchFilters.search,
      onChange: debounce((e) => {
        setFilters((old) => ({
          ...old,
          search: e.target.value,
        }));
      }, 400),
    }),
    [tab, searchFilters.search],
  );

  const tableProps = useMemo(
    () => ({
      filters: {
        topic_id: topicId,
        group_id: groupId,
        search: searchFilters.search,
        start: filters.start,
        end: filters.end,
        days: filters.days,
        subtopic_id:
          selectedSubtopic && selectedSubtopic.type !== 'ai_model_based'
            ? selectedSubtopic.id
            : undefined,
        suggested_subtopic_id:
          selectedSubtopic && selectedSubtopic.type === 'ai_model_based'
            ? selectedSubtopic.id
            : undefined,
        custom_field_id: customFields.map((customField) => customField.id),
        custom_field_value: customFields.map(
          (customField) => customField.value,
        ),
        labels: activeLabels.map((label) => label.id),
        sources: sources.map((source) => ({
          source_id: source.id,
          type: source.name,
        })),
      },
      hasPagination: true,
      perPage: 25,
      showTopicName: true,
      showSmallSubtopic: true,
      forTopicId: topicId,
      forSubtopicId: selectedSubtopic?.id,
      anchor: subtopic.anchor,
    }),
    [
      topicId,
      groupId,
      filters.start,
      filters.end,
      searchFilters.search,
      // reload.page,
      filters.days,
      selectedSubtopic?.id,
      customFields,
      activeLabels,
      sources,
    ],
  );
  return {
    searchProps,
    tableProps,
  };
}

const TopicDashboard = () => {
  const { topicId, subtopicId, groupId } = useParams();
  const history = useHistory();

  const isMounted = useRef(false);

  const dispatch = useDispatch();

  const topic = useTopic();
  const topicModal = useTopicModal();

  const subtopic = useSubtopic(isMounted);
  const subtopicModal = useSubtopicModal();

  const [filters, setFilters] = useFilters();
  const metrics = useMetrics();
  const {
    releases,
    setReleases,
    handleModalVisibility,
    createModalProps,
    listModalProps,
  } = useReleases();

  const defaultValue = useSentimentDateFilterInitialValue();
  const [dateValue, setDateValue] = useState(defaultValue);
  const [
    customFields,
    addCustomField,
    removeCustomField,
    removeAllCustomField,
  ] = useCustomFieldState([]);

  const {
    labels, addLabel, removeLabel, removeAllLabels,
  } = useLabels();

  const [
    sources,
    addSource,
    removeSource,
    removeAllSources,
  ] = useCustomFieldState([]);

  const chart = useChartFilters(filters, customFields);
  const conversation = useConversation(subtopic, customFields, labels, sources, filters);

  // Mount point, loading data.
  useEffect(() => {
    if (!isMounted.current) isMounted.current = true;

    if (!isValidParam(topicId) && !isValidParam(groupId)) {
      history.push('/404');
    }

    subtopic.setLoading(true);
    topics
      .getTopic(topicId, {
        start: filters.start,
        end: filters.end,
        days: filters.days,
        ...prepareCustomFields(customFields),
      })
      .then(({ data }) => {
        const { groups } = data;
        const selectedGroupId = groups.findIndex(
          ({ id: parentId }) => Number(groupId) === parentId,
        );

        if (selectedGroupId === -1) {
          history.push('/404');
        }

        const group = groups[selectedGroupId];

        setReleases(data?.annotations);

        dispatch({
          type: SET_TOPIC_ACTION,
          payload: { topic: data, group },
        });

        subtopics
          .getSubtopics(topicId, {
            start: filters.start,
            end: filters.end,
            days: filters.days,
            ...prepareCustomFields(customFields),
          })
          .then(({ data: res }) => {
            subtopic.setList(res);

            if (subtopicId) {
              const currentSubtopic = res.find(
                (item) => `${item.id}` === subtopicId,
              );
              subtopic.setSelected(currentSubtopic);
              subtopic.setType(currentSubtopic?.type);
              dispatch({
                type: SET_SUBTOPIC_ACTION,
                payload: { subtopic: currentSubtopic },
              });
            } else {
              dispatch({
                type: SET_SUBTOPIC_ACTION,
                payload: { subtopic: null },
              });
            }

            dispatch({
              type: SET_LOADING_ACTION,
              payload: false,
            });
            subtopic.setLoading(false);
          })
          .catch((e) => Sentry.captureException(e));
      })
      .catch(() => history.push('/404'));
    return () => {
      dispatch({ type: SET_TOPIC_ACTION, payload: { topic: null } });
      dispatch({ type: SET_LOADING_ACTION, payload: true });
    };
  }, [filters.start, filters.end, customFields, topic?.reload?.page, topicId]);

  useEffect(() => {
    if (!subtopicId) {
      dispatch({
        type: SET_SUBTOPIC_ACTION,
        payload: { subtopic: null },
      });
    }
  }, [subtopicId]);

  useEffect(() => {
    if (import.meta.env.VITE_CUSTOMERIO_SITEID) {
      customerIo.track('topic-viewed');
    }
  }, []);

  if (topic.loading) {
    return (
      <div className="topic-dashboard">
        <div className="topic-dashboard-loading">
          <Spin size="large" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="topic-dashboard">
        <Affix offsetTop={72} className="[&>.ant-affix]:bg-[#F6F9FB] pb-4">
          <div
            className="gordita-medium-4 flex flex-center mb-md"
            style={{ justifyContent: 'space-between' }}
          >
            <div className="flex flex-center">
              <span className="mr-sm">{topic.name}</span>
              <Tag style={{ marginTop: '4px' }} color={topic.group.color}>
                {topic.group.name}
              </Tag>
            </div>
            <div className="flex">
              <div className="custom-field-menu">
                <CustomFiltersButton activeLabels={labels} onSelectField={addCustomField} onAddLabel={addLabel} onRemoveLabel={removeLabel} onClickSource={addSource} />
              </div>
              <SentimentRadioGroupDateFilter
                initialValue={dateValue}
                onChange={(value) => {
                  setDateValue(value);
                  setFilters((oldState) => ({
                    ...oldState,
                    ...value,
                  }));
                }}
              />
              <Divider
                style={{ height: '32px', margin: '0 16px' }}
                type="vertical"
              />
              <SummaryButton
                name={topic.name}
                id={topic.id}
                start={filters.start}
                end={filters.end}
                customFields={customFields}
                subtopic={subtopic.selected}
              />
              <TopicActions {...topicModal.actionsProps} />
            </div>
          </div>
        </Affix>

        {customFields.length || labels.length || sources.length ? (
          <div className="select-custom-field-wrapper">
            <div className="selected-custom-field-wrap">
              {customFields.map((customField) => (
                <SelectedCustomField
                  key={customField.id}
                  id={customField.id}
                  name={customField.name}
                  value={customField.value}
                  dataType={customField.dataType}
                  onClear={() => removeCustomField(customField.value)}
                />
              ))}
              {labels.map((label) => (
                <SelectedLabel key={label.id} label={label} onClear={removeLabel} />
              ))}
              {sources.map((source) => (
                <SelectedSource key={source.id} source={source} onClear={removeSource} />
              ))}
            </div>
            <DeleteOutlined
              onClick={() => {
                removeAllCustomField();
                removeAllLabels();
                removeAllSources();
              }}
              style={{ marginRight: '16px' }}
            />
          </div>
        ) : null}

        <SubtopicsContainer
          topicName={topic.name}
          loading={subtopic.loading}
          subtopics={subtopic.list}
          dispatch={dispatch}
          anchor={subtopic.anchor}
          setVisible={subtopicModal.setVisible}
        />
        <GradientSentimentChart
          filters={chart.filters}
          annotations={releases}
          hasInteractions
          onChange={(newState) => {
            setFilters((oldState) => ({
              ...oldState,
              days: [...newState.map((entry) => entry.point)],
            }));
          }}
        >
          <div className="topic-dashboard-header-actions">
            <div className="topic-dashboard-header">
              <div
                className={classNames(
                  'hot-topic__metrics dashboard-header-metrics',
                  { loading: topic.shouldUpdateMetrics },
                )}
              >
                {topic.shouldUpdateMetrics || metrics.loading ? (
                  <Spin />
                ) : (
                  <>
                    <div className="painpoint-dashboard-metric">
                      <div className="gordita-regular-1">Conversations</div>
                      <TopicMetric
                        value={metrics.count.value}
                        trend={metrics.count.trend}
                        tooltip={`${metrics.count.value} conversations over the selected period`}
                        difference={calculateDifference(
                          metrics.count.value,
                          metrics.count.previous_value,
                        )}
                      />
                    </div>
                    <div className="painpoint-dashboard-metric">
                      <div className="gordita-regular-1">Ratio</div>
                      <TopicMetric
                        value={`${Math.ceil(metrics.ratio.value * 100)}%`}
                        trend={metrics.ratio.trend}
                        tooltip={`appears in ${Math.ceil(
                          metrics.ratio.value * 100,
                        )}% of total conversations in the selected period`}
                      />
                    </div>
                    <div className="painpoint-dashboard-metric">
                      <div className="gordita-regular-1">MRR</div>
                      <LoadableMetric
                        isLoading={metrics.in_progress}
                        loadingText="Analyzing MMR in progress"
                      >
                        <TopicMetric.MRR
                          value={metrics.mrr.value}
                          trend={metrics.mrr.trend}
                          tooltip={`$${Math.round(
                            metrics.mrr.value,
                          )} estimated monthly revenue by users discussing this metrics`}
                        />
                      </LoadableMetric>
                    </div>
                    <div className="painpoint-dashboard-metric">
                      <div className="gordita-regular-1">Sentiment</div>
                      <LoadableMetric
                        isLoading={metrics.in_progress}
                        loadingText="Analyzing sentiment in progress"
                      >
                        <SentimentTooltip
                          sentiment={metrics.sentiment}
                          style={{ display: 'block', width: 124, marginTop: 8 }}
                        >
                          <Progress.Sentiment
                            style={{ marginTop: '12px' }}
                            positive={metrics.sentiment.positive}
                            negative={metrics.sentiment.negative}
                          />
                        </SentimentTooltip>
                      </LoadableMetric>
                    </div>
                  </>
                )}
              </div>
            </div>
            {releases.length ? (
              <AnnotationActions
                onAdd={() => handleModalVisibility(modals.CREATE_ANNOTATION)}
                onList={() => handleModalVisibility(modals.LIST_ANNOTATIONS)}
              />
            ) : (
              <Button
                onClick={() => handleModalVisibility(modals.CREATE_ANNOTATION)}
                htmlType="button"
                type="default"
                icon={
                  <AnnotationIcon className="annotation-actions__btn-icon" />
                }
              >
                Add release
              </Button>
            )}
          </div>
        </GradientSentimentChart>

        <div className="topic-dashboard-section-2">
          <div className="topic-dashboard-section-2__header">
            <h3>Conversations</h3>
            <div className="actions">
              <Input {...conversation.searchProps} />
            </div>
          </div>
          <ConversationsTable {...conversation.tableProps} />
        </div>
      </div>

      {/* MODALS */}
      <AnnotationCreateModal
        state={{ id: topicId, name: topic.name }}
        {...createModalProps}
      />
      <AnnotationListModal
        state={{ id: topicId, name: topic.name }}
        {...listModalProps}
      />
      <ModalTopicForm {...topicModal.modalProps} />
      <ModalTopicForm
        topicId={topicId}
        topicName={topic.name}
        {...subtopicModal.modalProps}
      />
    </>
  );
};

export default withRouter(TopicDashboard);
