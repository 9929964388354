import React, { useMemo } from 'react';
import { Table } from 'antd';

const CsvSmallPreview = ({ column, data, limit }) => {
  const limitedData = useMemo(() => {
    if (limit) {
      return data.slice(0, limit);
    }

    return data;
  }, [data, limit]);

  return (
    <Table
      className="font-[Gordita-Regular] csv-importer-table"
      pagination={false}
      bordered
      dataSource={limitedData}
      columns={[{
        title: column,
        dataIndex: column,
        key: column,
      }]}
    />
  );
};

export default CsvSmallPreview;
