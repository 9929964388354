/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import classNames from 'classnames';
import { InfoCircleOutlined } from '@ant-design/icons';

const OnboardingVideo = ({ active }) => (
  <div className="onboarding-video-wrapper">
    <div className={classNames('onboarding-video', { active })}>
      <div
        style={{
          position: 'relative',
          paddingBottom: 'calc(54.239663629993% + 41px)',
          height: 0,
          width: '100%',
        }}
      >
        <iframe
          src="https://demo.arcade.software/3JYJhiHxHwWtxFlhsOPR?embed"
          frameBorder="0"
          loading="lazy"
          webkitallowfullscreen
          mozallowfullscreen
          allowFullScreen
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            colorScheme: 'light',
          }}
        />
      </div>
      <p>
        {' '}
        <InfoCircleOutlined />
        {' '}
        For a better experience click &apos;Fullscreen&apos; top right of the video
        {' '}
      </p>
    </div>
  </div>
);

export default OnboardingVideo;
