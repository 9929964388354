import React from 'react';
import cn from 'classnames';
import IconSentimentPositiveSmall from '../../images/sentiments/icon-sentiment-positive-small.svg';
import IconSentimentNeutralSmall from '../../images/sentiments/icon-sentiment-neutral-small.svg';
import IconSentimentNegativeSmall from '../../images/sentiments/icon-sentiment-negative-small.svg';

const sentimentIcon = {
  positive: IconSentimentPositiveSmall,
  neutral: IconSentimentNeutralSmall,
  negative: IconSentimentNegativeSmall,
};

const SentimentAvg = ({
  sentiment, value, className, styles = { root: {}, avg: {}, icon: {} },
}) => (
  <div className={cn('sentiment-avg', className)} style={styles.root}>
    <p className={`sentiment-avg__label sentiment-avg__label--${sentiment}`} style={styles.avg}>
      {value}
      %
    </p>
    <img
      src={sentimentIcon[sentiment]}
      alt="Sentiment"
      className="sentiment-avg__icon"
      style={styles.icon}
    />
  </div>
);

export default SentimentAvg;
