import * as React from 'react';
import { forwardRef, memo } from 'react';
import classNames from 'classnames';

const Insights = ({ className, ...props }, ref) => (
  <span role="img" aria-label="insights" className={classNames('anticon', 'anticon-insights', className)}>
    <svg
      data-name="Group 706"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      focusable="false"
      aria-hidden="true"
      ref={ref}
      {...props}
    >
      <defs>
        <clipPath id="insights">
          <path data-name="Rectangle 1921" fill="none" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
      <g data-name="Group 711" clipPath="url(#insights)">
        <g data-name="Group 710">
          <g data-name="Group 709" clipPath="url(#insights)">
            <g data-name="Group 708" opacity={0.15}>
              <g data-name="Group 707">
                <g data-name="Group 706" clipPath="url(#insights)">
                  <path
                    data-name="Rectangle 1918"
                    fill="#fff"
                    d="M0 0h16v16H0z"
                  />
                </g>
              </g>
            </g>
            <path
              data-name="Path 495"
              d="M12.753 10.44H5.726A1.55 1.55 0 0 0 4.22 11.6H3.126V8.56h1.15a1.561 1.561 0 0 0 1.45 1h4.092a1.56 1.56 0 1 0 0-3.12H5.726a1.561 1.561 0 0 0-1.45 1h-1.15V4.56h1.15a1.561 1.561 0 0 0 1.45 1h5.664a1.56 1.56 0 1 0 0-3.12H5.726a1.561 1.561 0 0 0-1.45 1h-1.15V1.5a.06.06 0 0 0-.06-.06h-1a.06.06 0 0 0-.06.06v12.6a.06.06 0 0 0 .06.06h1a.06.06 0 0 0 .06-.06v-1.375h1.225a1.55 1.55 0 0 0 1.375.835h7.027a1.56 1.56 0 0 0 0-3.12m.44 1.56a.441.441 0 0 1-.44.44H5.726a.44.44 0 1 1 0-.88h7.027a.441.441 0 0 1 .44.44M5.286 8a.44.44 0 0 1 .44-.44h4.092a.44.44 0 1 1 0 .88H5.726a.44.44 0 0 1-.44-.44m0-4a.44.44 0 0 1 .44-.44h5.664a.44.44 0 0 1 0 .88H5.726a.44.44 0 0 1-.44-.44"
              fill="currentColor"
            />
          </g>
        </g>
      </g>
    </svg>
  </span>
);

const ForwardRef = forwardRef(Insights);
const Memo = memo(ForwardRef);
export default Memo;
