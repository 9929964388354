import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import config from './config';

Sentry.init({
  dsn: config.sentryDSN,
  autoSessionTracking: true,
  integrations: [
    new Integrations.BrowserTracing(),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: import.meta.env.PROD ? 0.3 : 1.0,
  environment: import.meta.env.MODE,
});
