/* eslint-disable react/jsx-filename-extension, max-len */
import React from 'react';

const getClientAskTestimonial = (row) => ({
  text: `You could ask ${row.client.name} for a testimonial`,
  description: '',
  recommended_step1: `Schedule a casual call with ${row.client.name}.`,
  recommended_step2: 'Here are some extra tips to consider',
  recommended_step3: (
    <>
      Check out
      {' '}
      <a rel="noreferrer" target="_blank" href="https://www.testimonialhero.com/blog/how-to-ask-for-a-b2b-customer-testimonials">this article</a>
      {' '}
      for more useful information.
    </>
  ),
  recommended_step1_description: (
    <>
      <span>Calls give you a 28% more chances to get an yes. Follow this steps during the call:</span>
      <ul>
        <li>Briefly explain why testimonials are important</li>
        <li>{`Make it really easy for ${row.client.name} to give you a testimonial`}</li>
        <li>Offer small incentives if possible</li>
      </ul>
    </>
  ),
  recommended_step2_description: (
    <ul>
      <li>
        <strong>Timing is everything</strong>
        . Make sure you deliver value before you ask
      </li>
      <li>Follow up politely if need it, when your client is feeling grateful</li>
      <li>
        The best way to generate positive feedback is to
        {' '}
        <strong>earn it</strong>
      </li>
    </ul>
  ),
});

export default getClientAskTestimonial;
