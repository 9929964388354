import * as React from 'react';
import { memo } from 'react';

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <path
      style={{
        fill: '#fff',
      }}
      d="M0 0h40v40H0z"
    />
    <path
      d="M28.765 2.25H4.109A4.112 4.112 0 0 0 0 6.359v24.656a4.112 4.112 0 0 0 4.109 4.109h24.656a4.112 4.112 0 0 0 4.109-4.109V6.359a4.112 4.112 0 0 0-4.109-4.109Zm-7.947 6.025a1.1 1.1 0 0 1 2.194 0v14.639a1.1 1.1 0 0 1-2.194 0Zm-5.474-.55a1.1 1.1 0 0 1 2.194 0v15.886a1.1 1.1 0 0 1-2.194 0Zm-5.481.55a1.1 1.1 0 0 1 2.194 0v14.639a1.1 1.1 0 0 1-2.194 0Zm-5.482 2.194a1.1 1.1 0 0 1 2.194 0v9.855a1.1 1.1 0 0 1-2.194 0v-9.855Zm23.731 16.716c-5.342 4.623-17.736 4.8-23.342 0a1.1 1.1 0 0 1 1.424-1.666c4.836 4.058 15.857 3.955 20.495 0a1.1 1.1 0 0 1 1.423 1.666Zm.382-6.861a1.1 1.1 0 0 1-2.194 0v-9.855a1.1 1.1 0 0 1 2.194 0Z"
      transform="translate(3.563 1.376)"
      style={{
        fill: '#286efa',
      }}
    />
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
