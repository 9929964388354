import React from 'react';
import { numFormatter } from '@/base-components/topic-metric';
import SentimentTooltip from '@/base-components/SentimentTooltip';
import Progress from '@/components/ui/progress';
import { Icons } from '@/components/ui/icons';
import { auth } from '@/api';
import { hasGorgias, hasIntercom, hasZendesk } from '@/helpers/features';
import { calculateDifference, formatCount } from '../../utils';
import { FeedbackColumn } from './columns/FeedbackColumn';
import { TicketsColumn } from './columns/TicketsColumn';
import { TaxonomyTableAction } from './TableAction';
import { RatioColumn } from './columns/RatioColumn';
import { MRRColumn } from './columns/MRRColumn';

export function getColumns(history, topic) {
  const user = auth.me();
  const showTickets = hasZendesk(user) || hasIntercom(user) || hasGorgias(user);

  return [
    {
      title: () => (
        <div className="flex items-center gap-2">
          <Icons.Taxonomy className="w-4 h-4" />
          <span>Category</span>
        </div>
      ),
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      onCell: (record) => ({
        onClick: () => {
          if (!record.has_children && 'groups' in record) {
            history.push(`${window.location.pathname.startsWith('/demo') ? '/demo/' : '/'}topic/overview/${record.id}/${record.groups[0].id}`);
          }

          if (!record.has_children && topic) {
            history.push(`${window.location.pathname.startsWith('/demo') ? '/demo/' : '/'}topic/overview/${topic.id}/${topic.groups[0].id}/${record.id}`);
          }
        },
      }),
    },
    {
      title: 'Feedback',
      dataIndex: 'count',
      key: 'count',
      width: 'auto',
      render: (_, record) => {
        const { count = {} } = record;
        const difference = calculateDifference(
          count.value,
          count.previous_value,
        );
        const value = formatCount(count.value);
        return (
          <FeedbackColumn
            value={value}
            trend={count.trend}
            percent={difference}
          />
        );
      },
    },
    showTickets && {
      title: 'Tickets',
      key: 'tickets',
      width: 'auto',
      render: (_, record = {}) => <TicketsColumn record={record} />,
    },
    {
      title: 'Ratio',
      dataIndex: 'ratio',
      key: 'ratio',
      width: 'auto',
      render: (_, record = {}) => {
        const { ratio = {} } = record;
        return (
          <RatioColumn
            value={`${Math.ceil(ratio.value * 100)}%`}
            trend={ratio.trend}
          />
        );
      },
    },
    {
      title: 'MRR',
      dataIndex: 'mrr',
      key: 'mrr',
      width: 'auto',
      render: (_, record) => {
        const { mrr = {} } = record;
        return (
          <MRRColumn
            value={numFormatter(mrr.value)}
            trend={mrr.trend}
          />
        );
      },
    },
    {
      title: 'Sentiment',
      dataIndex: 'sentiment',
      key: 'sentiment',
      width: '13%',
      render: (_, record) => {
        const { sentiment = {} } = record;
        return (
          <SentimentTooltip sentiment={sentiment} style={{ display: 'block' }}>
            <Progress.Sentiment
              positive={sentiment.positive}
              negative={sentiment.negative}
            />
          </SentimentTooltip>
        );
      },
    },
    {
      title: '',
      key: 'action',
      width: 'auto',
      // eslint-disable-next-line no-unused-vars
      render: (_, record) => <TaxonomyTableAction record={record} topic={topic} />,
    },
  ].filter(Boolean);
}
