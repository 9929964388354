/* eslint-disable no-unused-vars */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Tag } from 'antd';
import { LabelTag } from '@/components/settings/LabelsTab';
import ConversationWithHighlightedKeywords from './ConversationWithHighlightedKeywords';
import ConversationSource from './ConversationSource';
import ConversationSentiment from './ConversationSentiment';
import useConversationsStore from '../store/useConversationsStore';
import ConversationLink from './ConversationLink';
import MultipleConversationSubtopicLink from './MultipleConversationSubtopicLink';

const ConversationContent = ({
  conversation, location: { pathname }, showKeywords, showTopicName, showSmallSubtopic,
}) => {
  const {
    actions: { changeTextCollapse },
  } = useConversationsStore();

  const text = conversation.isTextCollapsed ? conversation.snippet : conversation.text;

  const keywords = conversation.isTextCollapsed
    ? conversation.snippet_keywords : conversation.keywords;

  const isCollapsable = conversation.snippet && conversation.text
    && conversation.snippet.length !== conversation.text.length;

  const hasTopic = showTopicName && conversation.topic && conversation.topic_group;

  const clientTag = conversation.client && (
    <Tag
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 6,
        backgroundColor: '#f3f3f3',
        borderColor: 'transparent',
        borderRadius: '13px',
        padding: '2px 8px',
      }}
      icon={(
        <ConversationSource
          className="flex items-center border-0 border-r border-solid border-[#E8E8E8] pr-1.5"
          conversation={conversation}
        />
      )}
    >
      <Link to={`${pathname.startsWith('/demo') ? '/demo/' : '/'}people/${conversation.client.id}`}>
        <span className="conversations-client-name">{conversation.client.name}</span>
      </Link>
    </Tag>
  );

  return (
    <>
      <div className="conversation-content-text-container">
        <ConversationWithHighlightedKeywords
          text={text}
          keywords={showKeywords ? keywords : []}
          isCollapsable={isCollapsable}
          isCollapsed={conversation.isTextCollapsed}
          changeTextCollapse={() => changeTextCollapse(conversation.id)}
          className="conversation-content-text"
        />
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: 10,
          marginTop: 16,
        }}
        >
          <ConversationSentiment conversation={conversation} />
          {clientTag}
          {conversation.intents.map((intent) => (
            <Tag style={{
              borderColor: 'transparent',
              borderRadius: 14,
            }}
            >
              {intent.text}
            </Tag>
          ))}

          <MultipleConversationSubtopicLink
            conversation={conversation}
            showTopicName={showTopicName}
            showSmallSubtopic={showSmallSubtopic}
          />
          {
            (!conversation.subtopics.length && hasTopic && !showSmallSubtopic) && (
              <ConversationLink conversation={conversation} />
            )
          }

          {conversation.labels.map((label) => (
            <LabelTag key={label.id} label={label} />
          ))}
        </div>
      </div>
    </>
  );
};

export default withRouter(ConversationContent);
