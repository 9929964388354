import posthog from 'posthog-js';
import * as Sentry from '@sentry/react';

function deleteAccessTokenAndUserData() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('access_token_expires_at');
  localStorage.removeItem('user');
  localStorage.removeItem('teams');
  try {
    posthog.reset();
  } catch (e) {
    Sentry.captureException(e);
  }
}

export default deleteAccessTokenAndUserData;
