import {
  Button, Menu, Modal, Select, Spin, message,
} from 'antd';
import { ArrowLeftIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { conversations as conversationsApi } from '@/api';
import { useConversationStore } from '@/store/zustand/useConversationsStore';
import useTopics from './hooks/useTopics';
import useConversation from './hooks/useConversation';

export default function MoveToAnotherTopicModal({
  conversationId, ...props
}) {
  const queryClient = useQueryClient();
  const [visible, setVisible] = useState(false);

  const conversations = useConversationStore((state) => state.conversations);
  const conversation = conversations.find((item) => item.id === conversationId);

  const [moveTopics, setMoveTopics] = useState(
    conversation.topics ? conversation.topics.map((item) => item.id) : [],
  );

  const {
    data, isLoading, isError, error,
  } = useTopics();

  const apiConversation = useConversation(conversationId);
  useEffect(() => {
    if (!apiConversation.data) return;

    if (apiConversation.data.topics) {
      setMoveTopics(apiConversation.data.topics.map((item) => item.id));
    }
  }, [apiConversation.data]);

  const mutation = useMutation({
    mutationKey: 'conversation_move_topic',
    mutationFn: async (item) => {
      await conversationsApi.updateTopic(item.id, item.moveTopics);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['conversations']);
      message.success('Conversation moved successfully');
      setVisible(false);
    },
    onError: () => {
      message.error('Failed moving conversation');
    },
  });

  useEffect(() => {
    if (isError) {
      message.error(error.message);
    }
  }, [error]);

  const handleMenuClick = () => {
    setVisible(true);
  };
  const handleOK = async () => {
    mutation.mutate({ id: conversationId, moveTopics });
  };

  const handleCancel = () => {
    setVisible(false);
  };
  return (
    <>
      <Menu.Item {...props} className="flex items-center gap-2 hover:rounded-[3px] hover:!bg-[#F6F9FB]">
        <span tabIndex="0" role="button" className="flex items-center gap-2" onClick={handleMenuClick} onKeyPress={handleMenuClick}>
          <ArrowLeftIcon size={16} strokeWidth={2} />
          <span>Move to another topic</span>
        </span>
      </Menu.Item>
      <Modal
        title="Move Conversation to another topic"
        visible={visible}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={mutation.isLoading} onClick={handleOK}>
            Save
          </Button>,
        ]}
        onOk={handleOK}
        onCancel={handleCancel}
      >
        {!isLoading ? (
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            size="large"
            placeholder="Topic"
            value={moveTopics}
            onChange={(value) => setMoveTopics(value)}
            disabled={mutation.isLoading}
            filterOption={(inputValue, option) => option.children
              .toLowerCase().indexOf(inputValue.toLowerCase()) > -1}
          >
            {data.map((topic) => (
              <Select.Option
                key={topic.id}
                value={topic.id}
              >
                {topic.name}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <div className="text-center">
            <Spin size="large" />
          </div>
        )}
      </Modal>
    </>
  );
}
