import React, { useState } from 'react';
import {
  Button, Col, Form, Input, Row, message,
} from 'antd';
import { auth } from '@/api';
import rules from '../../helpers/rules';
import PopoutForm from '../forms/PopoutForm';
import { displayOrCatch, useErrorState } from '../../helpers';

const onFinish = (setError, setLoading) => (values) => {
  setLoading(true);
  auth.updatePassword(values).then(() => {
    message.success('Information updated');
    setLoading(false);
  }).catch((e) => {
    displayOrCatch(e, setError);
    setLoading(false);
  });
};

const onNewPasswordChange = (setShouldValidateFirst) => (e) => {
  setShouldValidateFirst(!e.target.value);
};

const ChangePasswordForm = () => {
  const [error, setError] = useErrorState();
  const [loading, setLoading] = useState();
  const [shouldValidateFirst, setShouldValidateFirst] = useState(true);

  return (
    <PopoutForm
      popoutError={error}
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish(setError, setLoading)}
    >
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            className="mb-lg"
            name="previous_password"
            rules={rules.oldPassword}
            label="Previous Password"
          >
            <Input.Password size="large" placeholder="Previous Password" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            className="mb-lg"
            name="password"
            rules={rules.newPassword}
            label="New Password"
            validateFirst={shouldValidateFirst}
          >
            <Input.Password onChange={onNewPasswordChange(setShouldValidateFirst)} size="large" placeholder="New Password" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            className="mb-xs"
          >
            <Button
              loading={loading}
              size="large"
              type="primary"
              htmlType="submit"
            >
              Save New Password
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </PopoutForm>
  );
};

export default ChangePasswordForm;
