import React from 'react';
import { Tooltip } from 'antd';
import DotsLoader from '../../animations/DotsLoader';

const LoadableMetric = ({ isLoading, loadingText, children }) => {
  if (isLoading) {
    return (
      <Tooltip
        title={loadingText}
        placement="bottom"
        overlayClassName="topic-metric-tooltip"
        overlayInnerStyle={{ fontSize: 12, marginBottom: 0 }}
      >
        <div className="dots-loader-wrapper">
          <DotsLoader />
        </div>
      </Tooltip>
    );
  }

  return children;
};

export default LoadableMetric;
