import React from 'react';
import { Spin, Table } from 'antd';
import { uniqueId } from 'lodash';

const LoadingTable = ({ loading, ...props }) => {
  if (loading) {
    return (
      <div className="text-center m-8">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <Table rowKey={() => uniqueId('_table_')} rowClassName="group" {...props} />
  );
};

export default LoadingTable;
