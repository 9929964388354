import React, {
  useCallback, useEffect, useMemo, useReducer,
} from 'react';
import { csv, platforms } from '@/api';
import {
  initialState,
  integrationsReducer,
  SET_CONNECTED_PLATFORMS,
  SET_HIGHLIGHTED_PLATFORM,
  SET_HIGHLIGHTED_CUSTOM_COMPONENT,
  SET_RECONNECT_PLATFORM,
  SET_CSV_FILES,
} from './integrationsReducer';
import { displayOrCatchMessage } from '../../../helpers';

export const IntegrationsContext = React.createContext();

const IntegrationsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(integrationsReducer, initialState);

  const reconnectPlatform = useCallback(
    (type) => {
      dispatch({ type: SET_RECONNECT_PLATFORM, payload: type });
    },
    [dispatch],
  );

  const updateConnectedPlatformsWithResponse = useCallback(
    (response) => {
      dispatch({ type: SET_CONNECTED_PLATFORMS, payload: response.data.data });
    },
    [dispatch],
  );

  const updateConnectedPlatforms = useCallback(
    (clearReconnect) => {
      platforms
        .index()
        .then((response) => {
          dispatch({
            type: SET_CONNECTED_PLATFORMS,
            payload: response.data.data,
            clearReconnect,
          });
        })
        .catch((e) => {
          displayOrCatchMessage(e);
        });
    },
    [dispatch],
  );

  const updateCsvFiles = useCallback(() => {
    csv
      .getFiles()
      .then((response) => {
        dispatch({
          type: SET_CSV_FILES,
          payload: response.data.data,
        });
      })
      .catch((e) => {
        displayOrCatchMessage(e);
      });
  }, [dispatch]);

  const highlightIntercom = useCallback(() => {
    dispatch({ type: SET_HIGHLIGHTED_PLATFORM, payload: 'intercom' });
  }, [dispatch]);

  const highlightTypeform = useCallback(() => {
    dispatch({ type: SET_HIGHLIGHTED_PLATFORM, payload: 'typeform' });
  }, [dispatch]);

  const highlightCustomComponent = useCallback(
    (payload) => {
      dispatch({ type: SET_HIGHLIGHTED_CUSTOM_COMPONENT, payload });
    },
    [dispatch],
  );

  const highlightZendeskSupport = useCallback(() => {
    dispatch({ type: SET_HIGHLIGHTED_PLATFORM, payload: 'zendesk' });
  }, [dispatch]);

  const highlightGorgias = useCallback(() => {
    dispatch({ type: SET_HIGHLIGHTED_PLATFORM, payload: 'gorgias' });
  }, [dispatch]);

  const highlightChatFeedbackUpload = useCallback(() => {
    dispatch({
      type: SET_HIGHLIGHTED_PLATFORM,
      payload: 'chat_feedback_upload',
    });
  }, [dispatch]);

  const highlightSurveyUpload = useCallback(() => {
    dispatch({ type: SET_HIGHLIGHTED_PLATFORM, payload: 'survey_upload' });
  }, [dispatch]);

  const highlightReviewUpload = useCallback(() => {
    dispatch({ type: SET_HIGHLIGHTED_PLATFORM, payload: 'review_upload' });
  }, [dispatch]);

  const highlightZapier = useCallback(() => {
    dispatch({ type: SET_HIGHLIGHTED_PLATFORM, payload: 'zapier' });
  }, [dispatch]);

  const highlightNone = useCallback(() => {
    dispatch({ type: SET_HIGHLIGHTED_PLATFORM, payload: null });
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      updateConnectedPlatforms();
    }, 15 * 1000);

    updateConnectedPlatforms();
    updateCsvFiles();
    return () => {
      clearInterval(interval);
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      state,
      actions: {
        updateConnectedPlatforms,
        updateConnectedPlatformsWithResponse,
        highlightIntercom,
        highlightTypeform,
        highlightCustomComponent,
        highlightZendeskSupport,
        highlightGorgias,
        highlightChatFeedbackUpload,
        highlightSurveyUpload,
        highlightReviewUpload,
        highlightZapier,
        highlightNone,
        reconnectPlatform,
        updateCsvFiles,
      },
    }),
    [state, updateConnectedPlatforms],
  );

  return (
    <IntegrationsContext.Provider value={contextValue}>
      {children}
    </IntegrationsContext.Provider>
  );
};

export default IntegrationsProvider;
