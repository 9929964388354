/* eslint-disable */
export const isNegligible = (value) => value === 'undefined' || value === undefined || value === null || value === '';

export const paramsToObject = (entries) => {
  const result = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
}