export const SET_TAB_ACTION = 'SET_TAB_ACTION';

export const SET_RANGE_FILTER_ACTION = 'SET_RANGE_FILTER_ACTION';

export const SET_SEARCH_FILTER_ACTION = 'SET_SEARCH_FILTER_ACTION';

export const SET_DAYS_FILTER_ACTION = 'SET_DAYS_FILTER_ACTION';

export const SET_LOADING_ACTION = 'SET_LOADING_ACTION';

export const SET_TOPIC_ACTION = 'SET_TOPIC_ACTION';

export const SET_SUBTOPIC_ACTION = 'SET_SUBTOPIC_ACTION';

export const SET_SHOULD_UPDATE = 'SET_SHOULD_UPDATE';

export const SET_MODAL_ACTION = 'SET_MODAL_ACTION';

export const SET_METRICS_ACTION = 'SET_METRICS_ACTION';

export const modals = Object.freeze({
  NONE: 'NONE',
  UPDATE_TOPIC: 'UPDATE_TOPIC',
  CREATE_ANNOTATION: 'CREATE_ANNOTATION',
  LIST_ANNOTATIONS: 'LIST_ANNOTATIONS',
});

export const tabs = Object.freeze({
  PEOPLE: 'PEOPLE',
  CONVERSATIONS: 'CONVERSATIONS',
});

export const initialState = {
  tab: tabs.CONVERSATIONS,
  filters: null,
  group: null,
  topic: null,
  subtopic: null,
  loading: true,
  modal: modals.NONE,
  reload: { data: false, page: false },
  shouldUpdateMetrics: false,
};

export const topicDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODAL_ACTION: {
      const { modal, pageReload = false, dataReload = false } = action.payload;

      return {
        ...state,
        modal,
        reload: {
          ...state.reload,
          data: dataReload ? !state.reload.data : state.reload.data,
          page: pageReload ? !state.reload.page : state.reload.page,
        },
      };
    }
    case SET_TAB_ACTION: {
      return {
        ...state,
        tab: action.payload,
      };
    }
    case SET_SEARCH_FILTER_ACTION: {
      return {
        ...state,
        filters: {
          ...state.filters,
          search: action.payload,
        },
      };
    }
    case SET_RANGE_FILTER_ACTION: {
      const { payload: { start, end } } = action;

      return {
        ...state,
        shouldUpdateMetrics: true,
        filters: {
          ...state.filters,
          start,
          end,
        },
      };
    }
    case SET_DAYS_FILTER_ACTION: {
      const { payload: { days } } = action;

      return {
        ...state,
        shouldUpdateMetrics: true,
        filters: {
          ...state.filters,
          days,
        },
      };
    }
    case SET_LOADING_ACTION: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case SET_TOPIC_ACTION: {
      const { topic, group } = action.payload;

      return {
        ...state,
        loading: false,
        topic,
        group,
      };
    }
    case SET_SUBTOPIC_ACTION: {
      const { subtopic } = action.payload;
      return {
        ...state,
        loading: false,
        subtopic,
      };
    }
    case SET_SHOULD_UPDATE: {
      return {
        ...state,
        shouldUpdateMetrics: action.payload,
      };
    }
    case SET_METRICS_ACTION: {
      const {
        data: {
          // eslint-disable-next-line camelcase
          count, mrr, ratio, in_progress,
        },
      } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          count,
          mrr,
          ratio,
          in_progress,
        },
        shouldUpdateMetrics: false,
      };
    }
    default:
      return state;
  }
};
