import React from 'react';
import classnames from 'classnames';

export const getMaxValue = ({ positive, negative, total }) => {
  if (total === 0) {
    return { message: 'No Feedback', color: '#FFF' };
  }

  const negativeRounded = Math.round(negative * 100);
  const positiveRounded = Math.round(positive * 100);

  if (negativeRounded > 40) {
    return { message: 'Mostly Negative', color: '#F15B53' };
  }

  if (negativeRounded > 20 && negativeRounded <= 40) {
    return { message: 'Going Negative', color: '#EE938E' };
  }

  if (negativeRounded > 1 && negativeRounded <= 20) {
    return { message: 'Slightly Negative', color: '#EBBCBA' };
  }

  if (positiveRounded > 40) {
    return { message: 'Mostly Positive', color: '#0BAA6B' };
  }

  if (positiveRounded > 20 && positiveRounded <= 40) {
    return { message: 'Going Positive', color: '#78BCA1' };
  }

  if (positiveRounded > 1 && positiveRounded <= 20) {
    return { message: 'Slightly Positive', color: '#B1D8C9' };
  }

  return { message: 'Mostly Neutral', color: '#C1C1C1' };
};

const Sentiment = ({
  positive, negative, total, style, bold,
}) => {
  const maxValue = getMaxValue({ positive, negative, total });

  return (
    <div className="sentiment" style={style}>
      <div
        className="sentiment__dot"
        style={{
          backgroundColor: maxValue.color,
          ...(total === 0 && { border: '1px solid black' }),
        }}
      />
      <p className={classnames('sentiment__label', bold)}>{maxValue.message}</p>
    </div>
  );
};

export default Sentiment;
