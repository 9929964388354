import React from 'react';
import Column from 'antd/lib/table/Column';
import TablePagination from '../../../../../table-pagination';
import LoadingTable from '../../../../../../components/LoadingTable';
import ConversationContent from './ConversationContent';
import ConversationActions from './ConversationActions';
import useConversationsStore from '../store/useConversationsStore';

const ConversationsTable = ({
  hasPagination,
  showKeywords,
  isDiscovery,
}) => {
  const {
    state: {
      conversations, loading, pagination,
    },
    actions,
  } = useConversationsStore();

  const handlePageChange = (page) => {
    actions.getConversations(page);
  };

  return (
    <LoadingTable
      resourceName="conversations"
      className="conversations-table"
      dataSource={conversations}
      loading={loading}
      showHeader={false}
      pagination={false}
      rowClassName="conversations-table-row group"
      footer={hasPagination ? () => (
        <TablePagination
          type="conversations"
          pagination={pagination}
          onPageChange={handlePageChange}
        />
      ) : undefined}
      scroll={{ x: true }}
    >
      <Column
        dataIndex="conversation-content"
        key="conversation-content"
        className="conversation-content"
        render={(_, conversation) => (
          <ConversationContent
            conversation={conversation}
            showKeywords={showKeywords}
          />
        )}
      />
      <Column
        dataIndex="conversation-actions"
        key="conversation-actions"
        fixed="right"
        className="conversation-actions"
        render={(_, conversation) => (
          <ConversationActions
            isDiscovery={isDiscovery}
            conversation={conversation}
          />
        )}
      />
    </LoadingTable>
  );
};

export default ConversationsTable;
