import { useMemo } from 'react';
import useURLState from '@/hooks/useUrlState';
import { options } from '../SentimentRadioGroupDateFilter';
import { getDefaultDatepickerValueFromLocalStorage } from '../helpers';

export const useSentimentDateFilterInitialValue = () => {
  const hash = new URLSearchParams(window.location.hash ? window.location.hash.substring(1) : '');

  const { urlQueryState } = useURLState();

  const initialValue = useMemo(() => {
    // check hash
    if (hash.get('name')) {
      const idx = options.findIndex(({ name }) => name === hash.get('name'));

      if (idx !== -1) {
        return options[idx];
      }
    }

    if (hash.get('name') === 'CUSTOM' && hash.get('start') && hash.get('end')) {
      return {
        name: 'CUSTOM',
        start: hash.get('start'),
        end: hash.get('end'),
        label: 'custom',
      };
    }

    // check url query
    if (urlQueryState?.name) {
      const idx = options.findIndex(({ name }) => name === urlQueryState.name);

      if (idx !== -1) {
        return options[idx];
      }
    }

    if (urlQueryState?.name === 'CUSTOM' && urlQueryState?.start && urlQueryState?.end) {
      return {
        name: 'CUSTOM',
        start: urlQueryState.start,
        end: urlQueryState.end,
        label: 'custom',
      };
    }

    // check local storage
    return getDefaultDatepickerValueFromLocalStorage();
  }, []);

  return initialValue;
};
