import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export const useResponsiveSiderStore = create(
  devtools(
    persist(
      (set) => ({
        collapsed: false,
        setCollapsed: (collapsed) => set({ collapsed }),
        reset: () => set({ collapsed: false }),
      }),
      {
        name: 'sidebar-storage',
      },
    ),
    {
      name: 'useResponsiveSiderStore',
    },
  ),
);
