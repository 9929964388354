import React from 'react';
import { CloseOutlined } from '@ant-design/icons';

const TopicFormHeader = ({ onCancel, data, subtopic }) => (
  <header>
    <div className="topic-steps__title">
      {`${data ? 'Update' : 'Create'} ${subtopic ? 'Subsegment' : 'Segment'}`}
    </div>
    <div className="topic-steps__cancel">
      <a
        href="#/"
        onClick={(e) => {
          e.preventDefault();
          onCancel();
        }}
      >
        <CloseOutlined />
      </a>
    </div>
  </header>
);

export default TopicFormHeader;
