import React, { useEffect, useState } from 'react';
import {
  Col, Empty, Result, Row, Spin, Typography, Card,
} from 'antd';
import { BulbOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Layout from '@/components/Layout';
import InsightStatusTag from '@/components/insights/InsightStatusTag';
import InsightAnatomyCard from '@/components/insights/InsightAnatomyCard';
import InsightArchive from '@/components/insights/InsightArchive';
import InsightDone from '@/components/insights/InsightDone';
import { insights } from '@/api';
import { displayOrCatchMessage } from '@/helpers';
import { getInsightData } from '@/transformers/insights';
import Avatar from '@/components/clients/Avatar';
import InsightHelpfulMark from '@/components/insights/InsightHelpfulMark';

const { Text } = Typography;

const secondColumn = (insight) => (
  <>
    <div className="mt-xs mb-lg">
      <Text type="secondary">
        {insight.description}
      </Text>
    </div>
    <h4>
      <ThunderboltOutlined />
      {' '}
      Recommended actions
    </h4>
    <ol>
      {insight.recommended_step1 ? (
        <li>
          {insight.recommended_step1}
        </li>
      ) : null}
      {insight.recommended_step2 ? (
        <li>
          {insight.recommended_step2}
        </li>
      ) : null}
      {insight.recommended_step3 ? (
        <li>
          {insight.recommended_step3}
        </li>
      ) : null}
    </ol>
    <p>
      <InsightDone className="mr-md" redirectsToInsights insight={insight}>Done</InsightDone>
      <InsightArchive type="default" redirectsToInsights insight={insight}>Archive</InsightArchive>
    </p>
  </>
);

const InsightAnatomy = () => {
  const { id } = useParams();
  const [insight, setInsight] = useState(null);

  useEffect(() => {
    insights.show(id).then((response) => {
      setInsight(getInsightData(response.data.data));
    }).catch((e) => {
      displayOrCatchMessage(e);
    });
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>ClientZen - Insight Anatomy</title>
      </Helmet>
      {
        !insight ? (
          <Result
            icon={<Spin size="large" />}
            subTitle="working on it..."
          />
        ) : (
          <Row gutter={{ lg: 42 }}>
            <Col lg={14} md={24} sm={24} xs={24}>
              <h2>{insight.text}</h2>
              <div className="flex flex-center mb-xl">
                <div className="mr-md"><Avatar size={46} client={insight.client} /></div>
                <Text type="secondary">{insight.created}</Text>
                <div className="mx-md"><InsightStatusTag text={insight.status} /></div>
                <InsightHelpfulMark insight={insight} />
              </div>
              <Col lg={0} md={24} sm={24} xs={24}>
                {secondColumn(insight)}
              </Col>
              <div>
                <h4 className="mb-md">
                  <BulbOutlined />
                  {' '}
                  Insight Anatomy
                </h4>
              </div>
              <div>
                {
                  insight.messages.map((item) => (
                    <InsightAnatomyCard {...item} insight={insight} id={item.key} />
                  ))
                }
                {
                  !insight.messages.length ? (
                    <Card>
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    </Card>
                  ) : null
                }
              </div>
            </Col>
            <Col lg={10} md={0} sm={0} xs={0}>
              {secondColumn(insight)}
            </Col>
          </Row>
        )
      }
    </Layout>
  );
};

export default InsightAnatomy;
