import { labels } from '@/api';
import { useQuery } from '@tanstack/react-query';

export default function useLabels(filters, params, options) {
  return useQuery({
    queryKey: ['labels', filters, params],
    queryFn: async () => {
      const { data: res } = await labels.getAll(filters, params);
      return res.data;
    },
    refetchOnWindowFocus: false,
    ...options,
  });
}
