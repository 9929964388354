import React from 'react';
import ErrorContainer from '@/components/ErrorContainer';

function getStatusMessage(code) {
  switch (code) {
    case 403:
      return 'Forbidden! We\'re sorry but you don\'t have access to this page.';
    case 404:
      return 'Sorry! The page you\'re looking for cannot be found.';
    case 500:
      return 'Oops, something went wrong! The server encountered an internal error and was unable to complete your request.';
    default:
      return 'Oops, something went wrong! The server encountered an internal error and was unable to complete your request.';
  }
}

export default function Error({ code }) {
  return (
    <ErrorContainer title={`${code}`} message={getStatusMessage(code)} />
  );
}
