import React, { useState } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import ClientAddModal from '@/components/clients/ClientAddModal';
import ColleagueAddModal from '../../colleagues/ColleagueAddModal';

const TopMoverEmpty = ({ isColleague }) => {
  const [isAddVisible, setIsAddVisible] = useState(false);

  return (
    <>
      <div
        className="TopMover TopMover--Empty"
      >
        <button className="TopMover__AddIcon" type="button" onClick={() => setIsAddVisible(true)}>
          <PlusCircleOutlined />
        </button>
        <h4 className="table-text-bold">{isColleague ? 'Add team member' : 'Add client'}</h4>
      </div>
      {
        isColleague ? (
          <ColleagueAddModal
            setVisible={setIsAddVisible}
            visible={isAddVisible}
            redirectsBackToClients
          />
        ) : (
          <ClientAddModal
            setVisible={setIsAddVisible}
            visible={isAddVisible}
            redirectsBackToClients
          />
        )
        }
    </>
  );
};

export default TopMoverEmpty;
