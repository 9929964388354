export const ACTION_CHANGE_TOPIC_STEP = 'ACTION_CHANGE_TOPIC_STEP';
export const ACTION_RESET_TOPIC_STEPS = 'ACTION_RESET_TOPIC_STEPS';
export const ACTION_UPDATE_TOPIC_STEPS_STATE = 'ACTION_UPDATE_TOPIC_STEPS_STATE';
export const ACTION_UPDATE_TOPIC_PREVIEW_STATE = 'ACTION_UPDATE_TOPIC_PREVIEW_STATE';
export const ACTION_ADD_FROM_DISCOVERY = 'ACTION_ADD_FROM_DISCOVERY';
export const ACTION_REMOVE_FROM_DISCOVERY = 'ACTION_REMOVE_FROM_DISCOVERY';

const initialState = {
  step: 0,
  step1Type: null,
  isDiscoveryAvailable: false,
  discoveryAdded: [],
  preview: {
    data: [], count: 0, loading: false, visible: false,
  },
};

export const topicStepsFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_CHANGE_TOPIC_STEP:
      return {
        ...state,
        step: action.payload.step,
        step1Type: action.payload.step1Type || state.step1Type,
      };
    case ACTION_UPDATE_TOPIC_STEPS_STATE:
      return {
        ...state,
        ...(action.payload || {}),
      };
    case ACTION_UPDATE_TOPIC_PREVIEW_STATE:
      return {
        ...state,
        preview: {
          ...state.preview,
          ...(action.payload || {}),
        },
      };
    case ACTION_ADD_FROM_DISCOVERY:
      return {
        ...state,
        discoveryAdded: [...state.discoveryAdded, action.payload],
      };
    case ACTION_REMOVE_FROM_DISCOVERY:
      return {
        ...state,
        discoveryAdded: state.discoveryAdded.filter((item) => item.id !== action.payload.id),
      };
    case ACTION_RESET_TOPIC_STEPS:
      return initialState;
    default:
      return state;
  }
};
