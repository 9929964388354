/* eslint-disable no-unused-vars */
import React from 'react';
import ConversationsProvider from './store/ConversationsProvider';
import ConversationsTable from './components/ConversationsTable';

const Index = ({
  isDiscovery,
  filters,
  perPage,
  hasPagination = false,
  showTopicName = true,
  showSource = true,
  showKeywords = true,
  showSmallSubtopic = false,
  forTopicId = null,
  forSubtopicId = null,
  anchor = null,
  disableUrlQueryState = false,
}) => (
  <ConversationsProvider filters={filters} perPage={perPage} disableUrlQueryState={disableUrlQueryState}>
    <ConversationsTable
      isDiscovery={isDiscovery}
      hasPagination={hasPagination}
      showTopicName={showTopicName}
      showSource={showSource}
      showKeywords={showKeywords}
      showSmallSubtopic={showSmallSubtopic}
      forTopicId={forTopicId}
      forSubtopicId={forSubtopicId}
      anchor={anchor}
      disableUrlQueryState={disableUrlQueryState}
    />
  </ConversationsProvider>
);

export default Index;
