import { ACTION_UPDATE_TOPIC_PREVIEW_STATE } from './reducer';
import { topics, subtopics } from '../../api';
import { options } from '../../base-components/sentiment-date-filter/SentimentRadioGroupDateFilter';
import { paramsToObject } from '../../hooks/useUrlState/utils';
import { getDefaultDatepickerValueFromLocalStorage } from '../../base-components/sentiment-date-filter/helpers';

export const previewInitialState = {
  data: [], count: 0, loading: false, visible: false,
};

const normalizeInterval = (interval) => {
  if (!interval) {
    const hash = new URLSearchParams(window.location.hash ? window.location.hash.substring(1) : '');

    if (hash.get('name')) {
      const idx = options.findIndex(({ name }) => name === hash.get('name'));

      if (idx !== -1) {
        return options[idx];
      }
    }

    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlQueryState = paramsToObject(urlSearchParams);

    if (urlQueryState?.name) {
      const idx = options.findIndex(({ name }) => name === urlQueryState.name);

      if (idx !== -1) {
        return options[idx];
      }
    }

    return getDefaultDatepickerValueFromLocalStorage();
  }

  return interval;
};

const loadPreviewHandler = (dispatch, subtopic, topicId) => (
  inclKeywords, exclKeywords, highlightedKeyword, interval,
) => {
  const includedKeywords = highlightedKeyword ? [highlightedKeyword] : inclKeywords;
  const excludedKeywords = highlightedKeyword ? [] : exclKeywords;
  const normalizedInterval = normalizeInterval(interval);

  if (!interval) {
    dispatch({
      type: ACTION_UPDATE_TOPIC_PREVIEW_STATE,
      payload: { loading: true },
    });
  }

  if (includedKeywords?.length > 0 || excludedKeywords?.length > 0) {
    (subtopic ? subtopics : topics).getPreview({
      included_keywords: includedKeywords,
      excluded_keywords: excludedKeywords,
      start: normalizedInterval.start,
      end: normalizedInterval.end,
      topic_id: topicId,
      per_page: subtopic ? 5000 : 50,
    }).then(({ data }) => {
      dispatch({
        type: ACTION_UPDATE_TOPIC_PREVIEW_STATE,
        payload: {
          loading: false,
          visible: true,
          count: data.total,
          data: data.hits.map(
            ({ message, keywords }) => ({
              text: message.snippet,
              keywords: keywords.snippet,
              body: message.body,
              hasMore: message.has_more,
            }),
          ),
        },
      });
    });
  } else {
    dispatch({
      type: ACTION_UPDATE_TOPIC_PREVIEW_STATE,
      payload: previewInitialState,
    });
  }
};

export default loadPreviewHandler;
