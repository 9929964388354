import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const useVisibleFilterDropdownStore = create(devtools((set) => ({
  visible: false,
  type: null,
  setType: (type) => set({ type }),
  setVisible: (visible) => set({ visible, type: null }),
  reset: () => set({
    visible: false,
    type: null,
  }),
}), {
  name: 'useVisibleFilterDropdownStore',
}));
