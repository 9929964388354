export const PAINPOINTS_SET_LOADING_ACTION = 'PAINPOINTS_SET_LOADING_ACTION';
export const PAINPOINTS_SET_SEARCH_FILTER_ACTION = 'PAINPOINTS_SET_SEARCH_FILTER_ACTION';
export const PAINPOINTS_SET_RANGE_FILTER_ACTION = 'PAINPOINTS_SET_RANGE_FILTER_ACTION';
export const PAINPOINTS_SET_DATA = 'PAINPOINTS_SET_DATA';

export const initialState = {
  loading: false,
  reload: null,
  filters: null,
  data: [],
  pagination: {
    current: 0,
    pageSize: 5,
    total: 0,
  },
};

export const painpointsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAINPOINTS_SET_SEARCH_FILTER_ACTION: {
      return {
        ...initialState,
        reload: !state.reload,
        filters: {
          ...state.filters,
          name: action.payload,
        },
      };
    }
    case PAINPOINTS_SET_RANGE_FILTER_ACTION: {
      const { payload: { start, end } } = action;

      return {
        ...initialState,
        reload: !state.reload,
        filters: {
          ...state.filters,
          start,
          end,
        },
      };
    }
    case PAINPOINTS_SET_LOADING_ACTION: {
      return {
        ...state,
        reload: null,
        loading: action.payload,
      };
    }
    case PAINPOINTS_SET_DATA: {
      const { data = [], pager = {} } = action.payload;
      return {
        ...initialState,
        data,
        reload: null,
        loading: false,
        filters: {
          ...state.filters,
        },
        pagination: {
          current: pager.current_page,
          pageSize: pager.per_page,
          total: pager.total,
        },
      };
    }
    default:
      return state;
  }
};
