import { connect } from 'react-redux';
import SearchContacts from './SearchContacts';
import addFirstClientAction from '../../store/actions/addFirstClient';
import addTeamMemberAction from '../../store/actions/addTeamMember';
import removeFirstClientAction from '../../store/actions/removeFirstClient';
import removeTeamMemberAction from '../../store/actions/removeTeamMember';
import recentInteractionAddAllTeamMembersAction from '../../store/actions/recentInteractionAddAllTeamMembers';
import recentInteractionAddAllClientsAction from '../../store/actions/recentInteractionAddAllClients';

const mapStateToProps = (state, { onDomain }) => ({
  persons: onDomain ? state.teamMembers : state.firstClients,
});

const mapDispatchToProps = (dispatch, { onDomain }) => ({
  addPerson: (data) => (onDomain
    ? dispatch(addTeamMemberAction(data))
    : dispatch(addFirstClientAction(data))),
  removePerson: (data) => (onDomain
    ? dispatch(removeTeamMemberAction(data))
    : dispatch(removeFirstClientAction(data))),
  recentInteractionAddAll: (data) => (onDomain
    ? dispatch(recentInteractionAddAllTeamMembersAction(data))
    : dispatch(recentInteractionAddAllClientsAction(data))),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchContacts);
