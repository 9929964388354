import React from 'react';
import classNames from 'classnames';
import { Copy } from 'lucide-react';
import { Button } from 'antd';
import Checkbox from '../ui/checkbox';
import { ReactComponent as IssueIcon } from './svgs/icon-issue.svg';
import { ReactComponent as QuestionIcon } from './svgs/icon-question.svg';
import { ReactComponent as RequestIcon } from './svgs/icon-bulb.svg';
import { ReactComponent as OtherIcon } from './svgs/icon-other.svg';
import './TopFilters.less';
import { useTemplateStore } from '../filter/store/useTemplateStore';

export default function TopFilters({
  className, checked, setChecked, onCopy,
}) {
  const { setTemplate } = useTemplateStore();
  const toggleChecked = (item) => () => {
    const data = [...checked];
    const index = data.indexOf(item);

    if (index === -1) {
      setChecked([...data, item]);
    } else {
      data.splice(index, 1);
      setChecked(data);
    }
    setTemplate(null);
  };

  return (
    <div className={classNames('top-filters flex items-center', className)}>
      <div className="top-filters-checkboxes">
        <Checkbox checked={checked.includes('issue')} onClick={toggleChecked('issue')}>
          <IssueIcon />
          Issues
        </Checkbox>
        <Checkbox checked={checked.includes('question')} onClick={toggleChecked('question')}>
          <QuestionIcon />
          Questions
        </Checkbox>
        <Checkbox checked={checked.includes('request')} onClick={toggleChecked('request')}>
          <RequestIcon />
          Requests
        </Checkbox>
        <Checkbox checked={checked.includes('other')} onClick={toggleChecked('other')}>
          <OtherIcon />
          Other
        </Checkbox>
      </div>
      <div>
        <Button className="top-filters-copy" type="link" onClick={onCopy}>
          <Copy size={16} />
        </Button>
      </div>
    </div>
  );
}
