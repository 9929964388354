import { DislikeOutlined, LikeOutlined } from '@ant-design/icons';
import React from 'react';
import GhostButton from '../GhostButton';

const getColor = (feedback, highlighted) => {
  if (highlighted && feedback === 'positive') {
    return '#28b87a';
  }

  if (highlighted && feedback === 'negative') {
    return '#ff4d4f';
  }

  return '#595959';
};

const InsightHelpfulMarkButton = ({
  type, feedback, highlighted, style, ...props
}) => (
  <GhostButton
    type={type || 'primary'}
    style={{
      color: getColor(feedback, highlighted), fontWeight: highlighted ? 700 : 'normal', marginLeft: 8, ...style,
    }}
    {...props}
  >
    {
      feedback === 'positive' ? (
        <>
          <LikeOutlined style={{ marginRight: 5 }} />
          yes
        </>
      ) : (
        <>
          <DislikeOutlined style={{ marginRight: 5 }} />
          no
        </>
      )
    }
  </GhostButton>
);

export default InsightHelpfulMarkButton;
