import { useEffect } from 'react';
import { connect } from 'react-redux';
import mixpanel from '@/mixpanel';
import logrocket from '@/logrocket';
import customerIo from '@/customer-io';
import hotjar from '@/hotjar';
import auth from '@/api/auth';

mixpanel.init();
logrocket.init(mixpanel);
window.Intercom('boot', {
  api_base: 'https://api-iam.intercom.io',
  app_id: 'r1jkch8u',
});

const MixpanelProvider = ({ user, children }) => {
  useEffect(() => {
    if (user) {
      const team = auth.team();
      mixpanel.identify(user.id);
      mixpanel.people.set({
        $name: user.name,
        $email: user.email,
        'Created At': user.created_at,
        'Email Verified At': user.email_verified_at,
        'Dashboard Ready': user.dashboard_ready,
        'Has Clients': user.has_clients,
        'Has Colleagues': user.has_colleagues,
        'Has Google Platform': user.has_google_platform,
      });
      hotjar.identify({
        id: user.id,
        name: user.name,
        email: user.email,
        created_at: user.created_at,
      });
      logrocket.identify(user.id, {
        name: user.name,
        email: user.email,
        createdAt: user.created_at,
        emailVerifiedAt: user.email_verified_at,
        dashboardReady: user.dashboard_ready,
        hasClients: user.has_clients,
        hasColleagues: user.has_colleagues,
        hasGooglePlatform: user.has_google_platform,
      });
      window.Intercom('update', {
        email: user.email,
        name: user.name,
        user_id: user.id,
      });
      if (import.meta.env.VITE_CUSTOMERIO_SITEID) {
        customerIo.identify({
          id: user.id,
          name: user.name,
          email: user.email,
          has_clients: user.has_clients ? '1' : '0',
          has_team_members: user.has_colleagues ? '1' : '0',
          has_google_platform: user.has_google_platform ? '1' : '0',
          has_microsoft_platform: user.has_microsoft_platform ? '1' : '0',
          has_intercom_platform: user.has_intercom_platform ? '1' : '0',
          team_id: team.id,
          is_owner: team.owner ? '1' : '0',
        });
      }
    }
  }, [user]);

  return children;
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(MixpanelProvider);
