/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import {
  Menu, Dropdown, message,
} from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { conversations as conversationsApi } from '@/api';

import IconSentimentPositiveSmall from '@/images/sentiments/icon-sentiment-positive-small.svg';
import IconSentimentNeutralSmall from '@/images/sentiments/icon-sentiment-neutral-small.svg';
import IconSentimentNegativeSmall from '@/images/sentiments/icon-sentiment-negative-small.svg';

const sentimentsInfo = {
  positive: {
    label: 'Positive',
    icon: IconSentimentPositiveSmall,
    color: '#0BAA6B',
    backgroundColor: '#D9F6EA',
  },
  neutral: {
    label: 'Neutral',
    icon: IconSentimentNeutralSmall,
    color: '#A4A4A4',
    backgroundColor: '#F3F3F3',
  },
  negative: {
    label: 'Negative',
    icon: IconSentimentNegativeSmall,
    color: '#F15B53',
    backgroundColor: '#FFEAE9',
  },
};

const ConversationSentiment = ({ conversation }) => {
  const [sentiment, setSentiment] = useState(conversation.sentiment);

  const updateSentiment = async (newSentiment) => {
    try {
      await conversationsApi.updateSentiment(conversation.id, newSentiment);
    } catch (err) {
      message.error('Failed updating conversation');
    }
  };

  const handleMenuClick = (info) => {
    const newSentiment = info.key.toLowerCase();
    if (newSentiment === sentiment) return;

    updateSentiment(newSentiment).then(
      () => setSentiment(newSentiment),
    );
  };

  return (
    <Dropdown
      overlay={(
        <Menu onClick={handleMenuClick} className="conversation-sentiment-menu">
          {Object.values(sentimentsInfo).map((sentimentInfo) => (
            <Menu.Item
              key={sentimentInfo.label}
              style={{ backgroundColor: sentimentInfo.backgroundColor, color: sentimentInfo.color }}
            >
              <img src={sentimentInfo.icon} alt="Sentiment" className="conversation-sentiment-icon" />
              {sentimentInfo.label}
            </Menu.Item>
          ))}
        </Menu>
  )}
      trigger={['click']}
    >
      <a onClick={(e) => e.preventDefault()}>
        <div
          className="conversation-sentiment-select"
          style={{
            backgroundColor: sentimentsInfo[sentiment].backgroundColor,
            color: sentimentsInfo[sentiment].color,
          }}
        >
          <img src={sentimentsInfo[sentiment].icon} alt="Sentiment" className="conversation-sentiment-icon" />
          <CaretDownOutlined className="conversation-sentiment-caret" />
        </div>
      </a>
    </Dropdown>
  );
};

export default ConversationSentiment;
