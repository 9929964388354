import React, { useMemo } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';

const CsvUploadDragger = ({ onChange }) => {
  const props = useMemo(() => ({
    name: 'file',
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    accept: '.csv',
    beforeUpload() {
      return false;
    },
    onChange(info) {
      onChange(info.file);
    },
  }), [onChange]);

  return (
    <Upload.Dragger {...props}>
      <div className="py-12">
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag a file to this area to upload</p>
        <p className="ant-upload-hint">Let&apos;s get started</p>
      </div>
    </Upload.Dragger>
  );
};

export default CsvUploadDragger;
