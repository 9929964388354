import React from 'react';
import { MehTwoTone, SmileTwoTone } from '@ant-design/icons';
import { Grid } from 'antd';
import Avatar from './Avatar';
import { screenIsSmOrXsOrMd } from '../../helpers';

const { useBreakpoint } = Grid;

const getClientHappinessIcon = (score) => {
  if (score < 40) {
    return <MehTwoTone twoToneColor="#FF4D4F" />;
  }
  if (score < 60) {
    return <MehTwoTone twoToneColor="#faad14" />;
  }

  return <SmileTwoTone twoToneColor="#0BAA6B" />;
};

const ScoreAvatar = ({
  client,
  disableBreakpoints,
}) => {
  const screens = useBreakpoint();
  const hideScore = (!disableBreakpoints && !screenIsSmOrXsOrMd(screens)) || client.loading;

  return hideScore ? <Avatar size={46} client={client} /> : (
    <div className="avatar-section">
      <Avatar size={46} client={client} />
      <div className="happiness-score">
        {getClientHappinessIcon(client.score)}
        <span className="percentage">
          {client.score}
          %
        </span>
      </div>
    </div>
  );
};

export default ScoreAvatar;
