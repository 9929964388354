import { customFields } from '@/api';
import { useQuery } from '@tanstack/react-query';

export default function useCustomFields({ added = true }) {
  return useQuery({
    queryKey: ['custom_fields', added],
    queryFn: async () => {
      const { data } = await customFields.getCustomFields({ added });
      return data;
    },
    refetchOnWindowFocus: false,
  });
}
