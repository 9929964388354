import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import UserAvatar from '@/components/UserAvatar';
import { connect } from 'react-redux';
import { Avatar, Spin } from 'antd';
import mantraIcon from './images/mantra-icon.png';

const remarkPlugins = [remarkGfm, remarkMath];
const rehypePlugins = [rehypeKatex, rehypeRaw];

const ChainlitMarkdownMessage = ({ content }) => (
  <ReactMarkdown
    remarkPlugins={remarkPlugins}
    rehypePlugins={rehypePlugins}
    components={{
      a({ children, ...props }) {
        return <a {...props} target="_blank">{children}</a>;
      },
    }}
  >
    {content}
  </ReactMarkdown>
);

const ChainlitMessage = ({ message, user }) => {
  if (message.type !== 'user_message' && !message.name) {
    return null;
  }

  return (
    <div className="flex mb-4">
      {
        message.type === 'user_message' ? (
          <>
            <div>
              <UserAvatar size={32} className="mr-xs" />
            </div>
            <div className="ml-3">
              <span className="text-[#44596C] user_name">{user.name}</span>
              <ChainlitMarkdownMessage content={message.output} />
            </div>
          </>
        ) : (
          <>
            <div>
              <Avatar src={mantraIcon} size={32} className="!bg-[#28b87a]">M</Avatar>
            </div>
            <div className="ml-3">
              <span className="text-[#44596C] user_name">Mantra</span>
              {
                message.output ? (
                  <ChainlitMarkdownMessage content={message.output} />
                ) : (
                  <div>
                    <Spin size="small" />
                  </div>
                )
              }
            </div>
          </>
        )
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(ChainlitMessage);
