import React from 'react';
import { Avatar as AntdAvatar } from 'antd';
import { MehTwoTone, SmileTwoTone } from '@ant-design/icons';
import Avatar from '../../clients/Avatar';

const getClientHappinessIcon = (clientHappiness) => {
  if (clientHappiness === 'notHappy') {
    return <MehTwoTone twoToneColor="#faad14" />;
  }

  return <SmileTwoTone twoToneColor="#0BAA6B" />;
};

const ClientAvatarWithScore = ({
  avatar, clientHappiness, percentage, client,
}) => (
  <div className="avatar-section">
    {client ? <Avatar size={46} client={client} /> : <AntdAvatar size={46} src={avatar} />}
    <div className="happiness-score">
      {getClientHappinessIcon(clientHappiness)}
      <span className="percentage">{percentage}</span>
    </div>
  </div>
);

export default ClientAvatarWithScore;
