import React from 'react';
import { Helmet } from 'react-helmet';
import Onboarding from '../../base-components/integrations/Onboarding';

const AddFirstPlatform = () => (
  <>
    <Helmet>
      <title>ClientZen</title>
    </Helmet>
    <Onboarding />
  </>
);

export default AddFirstPlatform;
