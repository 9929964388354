/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Form as AntForm,
  Input,
  message,
} from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import { topics } from '@/api';
import TopicFormTagSelect from './TopicFormTagSelect';
import PopoutForm from '../forms/PopoutForm';
import { ACTION_UPDATE_TOPIC_STEPS_STATE } from './reducer';
import TopicPreview from './TopicPreview';
import loadPreviewHandler from './loadPreviewHandler';
import SelectCustomDropdown from '../../base-components/topic-modal/components/SelectCustomDropdown';

const TopicKeywords = ({
  data: topicData, subtopic, topicName, topicId,
}) => {
  const [form] = AntForm.useForm();
  const [detailForm] = AntForm.useForm();
  const {
    step3Validate,
    interval,
  } = useSelector((state) => state.topicForm);
  const dispatch = useDispatch();
  const loadPreview = loadPreviewHandler(dispatch, subtopic, topicId);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [excludedVisible, setExcludedVisible] = useState(
    topicData && topicData.excluded_keywords.length,
  );

  useEffect(() => {
    if (!subtopic) {
      topics.getTopicGroups()
        .then(({ data }) => {
          setAvailableGroups(data);
        })
        .catch((e) => {
          Sentry.captureException(e);
          message.error('Categories Error');
        });
    }

    if (topicData) {
      dispatch({
        type: ACTION_UPDATE_TOPIC_STEPS_STATE,
        payload: {
          name: topicData.name,
          groups: topicData.group ? topicData.group.name : '',
          includedKeywords: topicData.included_keywords,
          excludedKeywords: topicData.excluded_keywords,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (step3Validate) {
      detailForm.validateFields();
    }
  }, [step3Validate]);

  const onDetailsChange = (values) => {
    dispatch({
      type: ACTION_UPDATE_TOPIC_STEPS_STATE,
      payload: {
        name: values.name,
        groups: values.groups,
      },
    });
  };

  const onKeywordsChange = (values) => {
    loadPreview(values.included_keywords, values.excluded_keywords, '', interval);
    dispatch({
      type: ACTION_UPDATE_TOPIC_STEPS_STATE,
      payload: {
        includedKeywords: values.included_keywords,
        excludedKeywords: values.excluded_keywords,
      },
    });
  };

  return (
    <div className="step-topic-two-columns topic-form-columns">
      <div className="left">
        <div>
          {
            (!topicData || topicData.type === 'keyword_based') && (
              <PopoutForm
                layout="vertical"
                form={form}
                requiredMark={false}
                onValuesChange={(changedValues, allValues) => onKeywordsChange(allValues)}
                initialValues={{
                  included_keywords: topicData ? topicData.included_keywords : [],
                  excluded_keywords: topicData ? topicData.excluded_keywords : [],
                }}
              >
                <AntForm.Item
                  key="included_keywords"
                  name="included_keywords"
                  label="Keywords to include"
                  className="keywords"
                  rules={[{ required: true, message: 'Keywords to include is required' }]}
                >
                  <TopicFormTagSelect
                    size="large"
                    hasHighlight
                    listHeight={120}
                    tokenSeparators={['\n', ';']}
                    initialValue={topicData ? topicData.included_keywords : []}
                  />
                </AntForm.Item>
                <div className="excluded_toggle_wrap">
                  {!excludedVisible && (
                    <Button className="excluded_toggle" type="link" onClick={() => setExcludedVisible(true)}>
                      <PlusOutlined />
                      Add keywords to exclude
                    </Button>
                  )}
                </div>
                <div className={classNames('excluded_keywords_wrap', { excluded_keywords_wrap_invisible: !excludedVisible })}>
                  <AntForm.Item
                    key="excluded_keywords"
                    name="excluded_keywords"
                    label="Keywords to exclude"
                    className="keywords"
                    style={{ marginBottom: 0 }}
                  >
                    <TopicFormTagSelect
                      size="large"
                      listHeight={120}
                      tokenSeparators={['\n', ';']}
                      visible={excludedVisible}
                      initialValue={topicData ? topicData.excluded_keywords : []}
                      suffix="::exact"
                    />
                  </AntForm.Item>
                  <div className="excluded_toggle_wrap">
                    <Button className="excluded_toggle" type="link" onClick={() => setExcludedVisible(false)}>
                      <MinusOutlined />
                      Remove keywords to exclude
                    </Button>
                  </div>
                </div>
              </PopoutForm>
            )
          }
          <AntForm
            layout="vertical"
            form={detailForm}
            className={classNames('subform', {
              'subform-single': topicData && topicData.type !== 'keyword_based',
            })}
            onValuesChange={(changedValues, allValues) => onDetailsChange(allValues)}
            validateTrigger={step3Validate ? 'onChange' : 'onSubmit'}
            requiredMark={false}
          >
            <AntForm.Item
              key={subtopic ? 'topic_name' : 'name'}
              name={subtopic ? 'topic_name' : 'name'}
              label="Segment name"
              initialValue={subtopic ? topicName : (topicData ? topicData.name : undefined)}
              rules={[{ required: true, message: 'Segment name is required' }]}
            >
              <Input
                size="large"
                disabled={subtopic}
              />
            </AntForm.Item>
            {
              !subtopic && (
                <AntForm.Item
                  key="groups"
                  name="groups"
                  label="Category"
                  rules={[{ required: true, message: 'Category is required' }]}
                  initialValue={(topicData && topicData.group) ? topicData.group.name : undefined}
                >
                  <SelectCustomDropdown
                    size="large"
                    valueProp="name"
                    textProp="name"
                    newOptionBtnText="Create new category"
                    options={availableGroups.map((
                      { name: groupName, id, color },
                    ) => ({ name: groupName, id, color }))}
                  />
                </AntForm.Item>
              )
            }
            {
              subtopic && (
                <AntForm.Item
                  key="name"
                  name="name"
                  label="Subsegment name"
                  initialValue={topicData ? topicData.name : undefined}
                  rules={[{ required: true, message: 'Subsegment name is required' }]}
                >
                  <Input
                    size="large"
                  />
                </AntForm.Item>
              )
            }
          </AntForm>
        </div>
      </div>
      <TopicPreview className="right" topicData={topicData} subtopic={subtopic} topicId={topicId} />
    </div>
  );
};

export default TopicKeywords;
