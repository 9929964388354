/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import {
  Dropdown, List, Menu, Tag, Tooltip, message, Button,
} from 'antd';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import moment from 'moment';
import {
  ChevronDownIcon, ChevronRightIcon, DotIcon, MoreVerticalIcon,
  Plug, User, Calendar, TicketMinus,
} from 'lucide-react';
import { Link } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { conversations as conversationsApi } from '@/api';
import ViewSummaryButton from '@/components/discovery/ViewSummaryButton';
import { connect } from 'react-redux';
import { useVisibleFilterDropdownStore } from '@/store/zustand/useVisibleFilterDropdownStore';
import { useThreadIdStore } from '@/store/zustand/useThreadIdStore';
import HighlightedText from '../HighlightedText';
import { usePaginationStore } from '../../store/zustand/usePaginationStore';
import { getSentimentIcon, sentimentsList } from './utils/sentiment';
import { LabelTag, LabelsButton } from '../settings/LabelsTab';
import MoveToAnotherTopicModal from './MoveToAnotherTopicModal';
import DeleteConversationsModal from './DeleteConversationsModal';
import MoveToAnotherSubtopicModal from './MoveToAnotherSubtopicModal';
import { useFiltersStore } from '../../store/zustand/useFiltersStore';

function FeedbackItemLinks({ topic, group, subtopics }) {
  const hasTopic = topic && group;
  if (!subtopics.length && hasTopic) {
    return (
      <FeedbackItemLink topic={topic} group={group} />
    );
  }

  const subtopic = subtopics[0];
  const subtopicGroup = subtopic?.topics?.[0]?.groups?.[0];
  const subtopicTopic = subtopic?.topics?.[0];

  const remaining = subtopics.slice(1)
    .filter((item) => !!item?.topics?.[0]?.groups?.[0]);

  if (!subtopic || !subtopicGroup || !subtopicTopic) {
    return null;
  }

  return (
    <div className="flex gap-3 items-center">
      <FeedbackItemLink topic={subtopicTopic} group={subtopicGroup} subtopic={subtopic} />
      {remaining.length > 0 ? (
        <Dropdown overlay={(
          <Menu>
            {remaining.map((item) => (
              <Menu.Item key={item.id} className="!text-sm [&>a>span]:block font-[Gordita-Regular]">
                <Link to={`/topic/overview/${item.topics[0].id}/${item.topics[0].groups[0].id}/${item.id}`}>
                  <span>{item.topics[0].groups[0].name}</span>
                  <div className="flex items-center">
                    <ChevronRightIcon color="#A9BCCF" size={16} />
                    <span>{item.topics[0].name}</span>
                  </div>
                  <div className="flex items-center ml-4">
                    <ChevronRightIcon color="#A9BCCF" size={16} />
                    <span className="font-[Gordita-Bold]">{item.name}</span>
                  </div>
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        )}
        >
          <Tag className="cursor-pointer flex !text-[#44596C] !text-sm items-center gap-1.5 !border-solid !border-[#DFE9F1] !rounded-full !px-3 !py-1 !bg-white !ml-0.5">
            {`+${remaining.length}`}
          </Tag>
        </Dropdown>
      ) : null}
    </div>
  );
}

function FeedbackItemLink({ topic, group, subtopic }) {
  return (
    <Link to={`/topic/overview/${topic.id}/${group.id}`}>
      <Tag
        className="flex !text-[#44596C] !text-sm items-center gap-1.5 !border-solid !border-[#DFE9F1] hover:!border-[#A9BCCF] !rounded-full !px-3 !py-1 !bg-white !ml-0.5"
      >
        <span className="max-w-[164px] text-ellipsis  overflow-hidden whitespace-nowrap hover:!text-[#202324]">{group.name}</span>
        <ChevronRightIcon color="#A9BCCF" size={14} />
        <span className="max-w-[164px] text-ellipsis overflow-hidden whitespace-nowrap hover:!text-[#202324]">{topic.name}</span>
        {subtopic ? (
          <>
            <ChevronRightIcon color="#A9BCCF" size={14} />
            <span className="max-w-[164px] text-ellipsis overflow-hidden whitespace-nowrap hover:!text-[#202324]">
              <span>
                {subtopic.name}
              </span>
            </span>
          </>
        ) : null}
      </Tag>
    </Link>
  );
}

function FeedbackItemAvatar({ id, sentiment }) {
  const queryClient = useQueryClient();
  const { currentPage, perPage } = usePaginationStore((state) => state);
  const { filters } = useFiltersStore((state) => state);
  const currentFilters = { currentPage, filters, perPage };

  const mutation = useMutation({
    mutationKey: 'conversation_sentiment_update',
    mutationFn: async (props) => {
      await conversationsApi.updateSentiment(props.id, props.sentiment);
    },
    onMutate: async (item) => {
      await queryClient.cancelQueries({ queryKey: ['conversations', currentFilters] });
      const previousConvs = queryClient.getQueryData(['conversations', currentFilters]);

      if (previousConvs) {
        queryClient.setQueryData(['conversations', currentFilters], (old) => {
          const newConvs = old.data.map((conv) => {
            if (conv.id === item.id) {
              return { ...conv, sentiment: item.sentiment };
            }
            return conv;
          });
          return { ...old, data: newConvs };
        });
      }

      return { previousConvs };
    },
    onSuccess: () => message.success('Sentiment updated successfully'),
    onError: (err, variables, ctx) => {
      if (ctx?.previousConvs) {
        queryClient.setQueryData(['conversations', currentFilters], ctx.previousConvs);
      }
      message.error('Failed updating sentiment');
    },
    onSettled: () => {
      queryClient.invalidateQueries(['conversations', currentFilters]);
    },
  });

  const handleSentimentChange = (e) => {
    const newSentiment = e.key.toLowerCase();
    if (newSentiment === sentiment) return;

    mutation.mutate({ id, sentiment: newSentiment });
  };

  return (
    <Dropdown
      overlay={(
        <Menu onClick={handleSentimentChange} className="!rounded-md shadow-[0px_1px_12px_#CAD7E180] border border-solid border-[#DFE9F1]">
          {Object.values(sentimentsList).map((zen) => (
            <Menu.Item
              className="flex items-center gap-2 hover:rounded-[3px] hover:!bg-[#F6F9FB] text-[#202324]"
              key={zen.label}
            >
              {getSentimentIcon(zen.label.toLowerCase())}
              {zen.label}
            </Menu.Item>
          ))}
        </Menu>
      )}
      trigger={['click']}
    >
      <div className="group/avatar flex items-center relative cursor-pointer">
        <div className="z-10">
          {getSentimentIcon(sentiment)}
          <ChevronDownIcon className="absolute f opacity-0 group-hover/avatar:opacity-100 top-[3px] left-[20px]" color="#44596C" size={16} />
        </div>
        <div
          className="absolute opacity-0 group-hover/avatar:opacity-100 px-[24px] group-hover/avatar:transition group-hover/avatar:ease-in-out group-hover/avatar:duration-300 rounded-full py-4 z-0 -top-1 -left-1"
        />
      </div>
    </Dropdown>

  );
}

function FeedbackItemTitle({
  source, author, date, external_link, thread_id,
}) {
  const { setThreadId } = useThreadIdStore((state) => state);
  const dateFormatted = useMemo(
    () => {
      const field_date = moment(date); // Example date more than a week ago
      const today = moment(); // Current date/time

      if (today.diff(field_date, 'days') >= 7) {
        return field_date.format('MMM DD');
      }
      return field_date.fromNow();
    },
    [date],
  );

  return (
    <div className="text-sm ml-[-3px] flex space-x-1 items-center pt-0.5">
      {external_link ? (
        <Tooltip className="flex items-center" title="See thread">
          <a className="flex items-center !text-[#75899B] hover:!text-[#202324]" href={external_link} target="_blank" rel="noopener noreferrer">
            <Plug className="!mr-0.5 shrink-0" size={16} strokeWidth={1.2} />
            <span>{source}</span>
          </a>
        </Tooltip>
      ) : (
        <span>{source}</span>
      )}

      {author ? (
        <>
          <DotIcon size={16} />
          <Link className="flex items-center cursor-pointer text-[#75899B] hover:!text-[#202324]" to={`/people/${author.id}`}>
            <User className="!mr-0.5 shrink-0" size={16} strokeWidth={1.2} />
            <span className="max-w-[200px] text-ellipsis overflow-hidden whitespace-nowrap">{author.name}</span>
          </Link>
        </>
      ) : null}

      {
        thread_id ? (
          <div className="thread_link_wrapper flex items-center space-x-1">
            <DotIcon size={16} />
            <Button onClick={() => setThreadId(thread_id)} className="flex items-center thread_link_button" type="link">
              <TicketMinus className="!mr-0.5 shrink-0" size={16} strokeWidth={1.2} />
              <span className="max-w-[200px] text-ellipsis overflow-hidden whitespace-nowrap">{thread_id}</span>
            </Button>
          </div>
        ) : null
      }

      <DotIcon size={16} />
      <Tooltip title={moment(date).format('DD/MM/YYYY hh:mm A')}>
        <span className="flex items-center">
          <Calendar className="!mr-1 shrink-0" size={16} strokeWidth={1.2} />
          {' '}
          <span>{dateFormatted}</span>
        </span>
      </Tooltip>
    </div>
  );
}

// Question?: Sometimes it doesn't highlight the keywords because of the case sensitivity. Should we make it case insensitive?
function FeedbackItemDescription({
  showKeywords = false,
  text, text_keywords,
  snippet, snippet_keywords,
  intents,
  labels,
  topic,
  topic_group,
  // topics,
  subtopics,
  show_cleaned,
  cleaned_text,
  // composable settings
  disableIntents,
}) {
  const isCollapsable = show_cleaned ? false : snippet && text && snippet?.length !== text?.length;
  const [collapsed, setCollapsed] = useState(true);

  // eslint-disable-next-line no-nested-ternary
  const description = collapsed ? (snippet !== '0' ? snippet : '') : text;
  const keywords = collapsed ? snippet_keywords : text_keywords;

  const handleChange = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div>
      <div>
        <HighlightedText
          text={show_cleaned ? cleaned_text : description}
          keywords={(showKeywords && !show_cleaned) ? keywords : []}
          className="inline [&>b]:font-[Gordita-Bold] leading-7 text-[15px]"
          style={{ overflowWrap: 'break-word' }}
        />
        {isCollapsable
          ? (
            <Tag className="!opacity-0 group-hover:!opacity-100 !text-sm !text-[#44596C] hover:!text-[#202324] !rounded-[16px] !pb-[2px] !border-[#DFE9F1] hover:!border-[#A9BCCF] !bg-[#DFE9F1]  cursor-pointer !ml-2" onClick={handleChange}>
              {collapsed ? 'more' : 'less'}
            </Tag>
          ) : null}
      </div>
      <div className="flex mt-4 gap-3 ml-[-2px]">
        {!disableIntents ? (
          <>
            {intents.length > 0 ? (
              <div className="flex gap-3">
                {intents.map((intent) => (
                  <Tag style={{
                    borderColor: 'transparent',
                    borderRadius: 14,
                  }}
                  >
                    {intent.text}
                  </Tag>
                ))}
              </div>
            ) : null}
            <FeedbackItemLinks subtopics={subtopics} topic={topic} group={topic_group} />
          </>
        ) : null}

        <div className="flex gap-3">
          {labels.map((label) => (
            <LabelTag key={label.id} label={label} />
          ))}
        </div>
      </div>
    </div>
  );
}

function FeedbackItem({
  id,
  thread_id,
  sentiment = 'neutral',
  source = 'Intercom', client, date = moment.now(),
  snippet,
  text,
  cleaned_text,
  external_link,
  keywords,
  snippet_keywords,
  labels,
  intents,
  topic,
  topic_group,
  topics,
  subtopics,
  user,
  // composable settings
  disableAvatar,
  disableIntents,
  disableDropdown,
}) {
  const [showCleanedVersion, setShowCleanedVersion] = useState(user.automatic_summaries && !!cleaned_text);
  const toggleShowCleanedVersion = useCallback(() => {
    setShowCleanedVersion((value) => !value);
  }, []);

  useEffect(() => {
    setShowCleanedVersion(user.automatic_summaries && !!cleaned_text);
  }, [cleaned_text]);

  // const text = conversation.isTextCollapsed ? conversation.snippet : conversation.text;
  const formattedSource = source.charAt(0).toUpperCase() + source.slice(1);
  return (
    <List.Item className="group flex !items-start hover:bg-[#F6F9FB] hover:bg-opacity-50">
      <List.Item.Meta
        className="list-item-meta gap-5"
        avatar={!disableAvatar ? <FeedbackItemAvatar id={id} sentiment={sentiment} /> : null}
        title={(
          <FeedbackItemTitle
            source={formattedSource}
            author={client}
            date={date}
            external_link={external_link}
            thread_id={thread_id}
          />
        )}
        description={(
          <FeedbackItemDescription
            text={text}
            cleaned_text={cleaned_text}
            snippet={snippet}
            showKeywords
            snippet_keywords={snippet_keywords}
            text_keywords={keywords}
            show_cleaned={showCleanedVersion}
            labels={labels}
            intents={intents}
            topic={topic}
            topic_group={topic_group}
            topics={topics}
            subtopics={subtopics}
            disableIntents={disableIntents}
          />
        )}
      />
      {!disableDropdown ? (
        <div className="opacity-0 group-hover:opacity-100 transition duration-150 ease-in-out flex items-baseline gap-4">
          {
            (cleaned_text && (text !== cleaned_text)) ? (
              <ViewSummaryButton onClick={toggleShowCleanedVersion} />
            ) : null
          }
          <LabelsButton conversationId={id} activeLabels={labels} />
          <Dropdown
            className="cursor-pointer"
            trigger={['click']}
            overlay={(
              <Menu className="!rounded-md shadow-[0px_1px_12px_#CAD7E180] border border-solid border-[#DFE9F1]">
                <MoveToAnotherTopicModal conversationId={id} />
                <MoveToAnotherSubtopicModal conversationId={id} />
                <DeleteConversationsModal conversationId={id} />
              </Menu>
            )}
          >
            <MoreVerticalIcon className="hover:stroke-[#44596C]" color="#8F9DA9" size={18} />
          </Dropdown>
        </div>
      ) : null}
    </List.Item>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(FeedbackItem);
