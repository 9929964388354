/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, {
  useEffect, useRef, useState,
} from 'react';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const SentimentRangePicker = ({ showOnMount, onChange, ...props }) => {
  const [open, setOpen] = useState(showOnMount);
  const wrapperRef = useRef(null);

  const onRangeChange = (values) => {
    onChange?.(values);
    setOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpen(false);
      }
    }
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);

  return (
    <div ref={wrapperRef} onMouseDown={() => setOpen(true)}>
      <RangePicker
        {...props}
        onChange={onRangeChange}
        open={open}
        getPopupContainer={(node) => node.parentNode}
        allowClear={false}
        className="z-100"
      />
    </div>
  );
};

export default SentimentRangePicker;
