import { Row } from 'antd';
import React from 'react';
import StarterBillingPlan from './StarterBillingPlan';
import WorldClassBillingPlan from './WorldClassBillingPlan';

const CurrentBillingPlan = ({ plan, children }) => (
  <>
    <Row gutter={16}>
      {
        (plan === 'starter_monthly' || plan === 'starter_annually') ? (
          <StarterBillingPlan
            isAnnuallySubscriptionSelected={plan === 'starter_annually'}
            hideButton
          />
        ) : null
      }
      {
        (plan === 'world_class_monthly' || plan === 'world_class_annually') ? (
          <WorldClassBillingPlan
            isAnnuallySubscriptionSelected={plan === 'world_class_annually'}
            benefitsContainerStyle={{ backgroundColor: '#FAFAFA' }}
            hideButton
          />
        ) : null
      }
    </Row>
    <Row gutter={16}>
      {children}
    </Row>
  </>
);

export default CurrentBillingPlan;
