import React from 'react';
import { Button } from 'antd';

const GhostButton = ({ style, children, ...props }) => (
  <Button
    style={{
      padding: 0,
      borderWidth: 0,
      boxShadow: 'none',
      fontFamily: 'Gordita-Regular',
      ...style,
    }}
    ghost
    {...props}
  >
    {children}
  </Button>
);

export default GhostButton;
