import React, { useEffect, useState, useCallback } from 'react';
import TextLoop from 'react-text-loop';
import { Typography, Progress } from 'antd';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import LogoColumn from '@/components/LogoColumn';
import Onboarding from '@/components/animations/Onboarding';
import { dashboard } from '@/api';
import { displayOrCatchMessage } from '@/helpers';
import auth from '@/api/auth';

const { Text } = Typography;

const GoToDashboard = () => {
  const [percent, setPercent] = useState(0);
  const [ready, setReady] = useState(false);
  const updateReadyStatus = useCallback(() => {
    dashboard.ready().then((isReady) => {
      if (!isReady) {
        setTimeout(() => {
          updateReadyStatus();
        }, 15 * 1000);
      } else {
        localStorage.setItem('teams', JSON.stringify([{
          ...auth.team(),
          dashboard_ready: true,
        }]));
        setReady(true);
      }
    }).catch((e) => {
      displayOrCatchMessage(e);
    });
  }, []);

  useEffect(() => {
    let count = 0;

    const animationIntervalId = setInterval(() => {
      const remaining = 100 - count;
      // eslint-disable-next-line no-restricted-properties
      count += (0.15 * Math.pow(1 - Math.sqrt(remaining), 2));
      setPercent(count);
    }, 200);

    updateReadyStatus();

    return () => clearInterval(animationIntervalId);
  }, []);

  return ready ? (
    <Redirect push to="/" />
  ) : (
    <LogoColumn style={{ textAlign: 'center' }}>
      <Helmet>
        <title>ClientZen - Gathering data</title>
      </Helmet>
      <Onboarding containerStyle={{
        marginTop: '46px',
        marginBottom: '16px',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: 170,
      }}
      />
      <Progress
        strokeColor="#0BAA6B"
        className="dashboard-loading-progress"
        percent={percent}
        showInfo={false}
      />
      <TextLoop className="dashboard-loading-text">
        <Text strong>Processing Data</Text>
        <Text strong>Discovering Insights</Text>
        <Text strong>Calculating Happiness Score</Text>
      </TextLoop>
    </LogoColumn>
  );
};

export default GoToDashboard;
