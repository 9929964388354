import md5 from 'crypto-js/md5';
import moment from 'moment';
import {
  COLOR_GREEN, COLOR_RED, COLOR_YELLOW,
} from '../components/insights/constants';

const getLatestScore = (client) => {
  if (client.latest_scores && client.latest_scores.length) {
    return client.latest_scores[0].value;
  }

  return null;
};

const getPreviousScore = (client) => {
  if (client.latest_scores && client.latest_scores.length > 1) {
    return client.latest_scores[1].value;
  }

  return null;
};

const getStatus = (score, alternative = false) => {
  if (score < 40) {
    return !alternative ? 'detractor' : 'not happy';
  }

  if (score < 60) {
    return 'neutral';
  }

  return !alternative ? 'evangelist' : 'happy';
};

const getAlternativeStatus = (score) => {
  if (score < 40) {
    return 'Needs Action';
  }

  if (score < 60) {
    return 'Needs Attention';
  }

  if (score < 80) {
    return 'Good';
  }

  return 'Great';
};

const getBackgroundColor = (score) => {
  if (score < 40) {
    return COLOR_RED;
  }

  if (score < 60) {
    return COLOR_YELLOW;
  }

  return COLOR_GREEN;
};

const getStatusTitle = (score, alternative) => getStatus(score, alternative).split(' ').map((n) => n[0].toUpperCase() + n.substr(1)).join('');

const getClientData = (client) => {
  const score = getLatestScore(client);
  const previousScore = getPreviousScore(client);

  return {
    avatar: `https://www.gravatar.com/avatar/${md5(client.email)}?s=120&d=404`,
    color: client.color,
    key: client.id,
    name: client.name,
    email: client.email,
    company: client.company ? client.company.name : '',
    status: getStatus(score),
    score,
    previousScore,
    last7DaysScores: client.last7DaysScores,
    last30DaysScores: client.last30DaysScores,
    loading: !score,
    errored: !score && (moment(client.updated_at).diff(moment.utc(), 'days') < -2),
    prediction: (score >= previousScore) ? 'good' : 'bad',
    foregroundColor: (score >= previousScore) ? COLOR_GREEN : COLOR_RED,
    backgroundColor: getBackgroundColor(score),
    info: getAlternativeStatus(score),
  };
};

const getScore = (data) => data.map((score) => ({
  name: `${score.value}%`,
  uv: score.point,
  pv: score.value,
  amt: score.value,
  date: moment(score.point).format('D MMM'),
  status: getStatusTitle(score.value, true),
}));

const percentage = (raw) => (Number.isNaN(raw) ? 0 : (raw * 100).toFixed(0));

const getScorePercentages = (scores) => ({
  happyValue: percentage(scores.filter((score) => score.amt >= 60).length / scores.length),
  neutralValue: percentage(
    scores.filter((score) => score.amt >= 40 && score.amt < 60).length / scores.length,
  ),
  notHappyValue: percentage(scores.filter((score) => score.amt < 40).length / scores.length),
});

const getTopMoverData = (data) => ({
  id: data.colleague_id ? data.colleague_id : data.client_id,
  avatar: `https://www.gravatar.com/avatar/${md5(data.colleague ? data.colleague.email : data.client.email)}?s=120&d=404`,
  client: data.colleague ? data.colleague : data.client,
  firstPercentage: data.previous_score,
  secondPercentage: data.score,
  date: moment(data.score_date).fromNow(),
});

export {
  getLatestScore,
  getClientData,
  getPreviousScore,
  getStatus,
  getStatusTitle,
  getScore,
  getScorePercentages,
  getTopMoverData,
};
