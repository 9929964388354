import React from 'react';
import { Helmet } from 'react-helmet';
import { Tabs } from 'antd';
import { Link } from 'react-router-dom';
import Layout from '@/components/Layout';
import InsightDataTable from '@/components/insights/InsightDataTable';
import InsightsIcon from '@/components/svgs/icons/Insights';

const { TabPane } = Tabs;

const ColleagueInsights = () => (
  <Layout>
    <Helmet>
      <title>ClientZen - Colleague Insights</title>
    </Helmet>
    <h4 className="page-header">
      <InsightsIcon />
      Insights
    </h4>
    <div className="datatable-header-half-container">
      <div className="mx-md datatable-header-half datatable-header-half-tabs">
        <Tabs defaultActiveKey="team_insights">
          <TabPane tab={<Link to="/insights">Client Insights</Link>} key="client_insights" />
          <TabPane tab={<Link to="/colleague-insights">Team Insights</Link>} key="team_insights" />
        </Tabs>
      </div>
      <InsightDataTable companyFilter isColleague title="Team Member Insights" hasClientAvatar />
    </div>
  </Layout>
);

export default ColleagueInsights;
