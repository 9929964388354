import React from 'react';
import heroLayer1 from './cz-processing1.svg';
import heroLayer2 from './cz-processing2.svg';
import heroLayer3 from './cz-processing3.svg';
import heroLayer5 from './cz-processing5.svg';
import heroLayer6 from './cz-processing6.svg';
import heroLayer7 from './cz-processing7.svg';

const Onboarding = ({ containerStyle }) => (
  <div className="onboarding-waiting-hero" style={containerStyle}>
    <img className="hero-layer1" src={heroLayer1} alt="" />
    <img className="hero-layer2" src={heroLayer2} alt="" />
    <img className="hero-layer3" src={heroLayer3} alt="" />
    <img className="hero-layer3" src={heroLayer5} alt="" />
    <img className="hero-layer6" src={heroLayer6} alt="" />
    <img className="hero-layer7" src={heroLayer7} alt="" />
  </div>
);

export default Onboarding;
