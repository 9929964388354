import { Tag } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import React from 'react';

const ConversationSubtopicLink = ({ location: { pathname }, subtopic, showSmallSubtopic }) => {
  const group = subtopic?.topics?.[0]?.groups?.[0];
  const topic = subtopic?.topics?.[0];

  if (!subtopic || !group || !topic) {
    return null;
  }

  return (
    <Link
      style={{
        border: '1px solid #e1e1e1', borderRadius: '13px', padding: '0 3px 3px 0', lineHeight: '21px',
      }}
      to={`${pathname.startsWith('/demo') ? '/demo/' : '/'}topic/overview/${topic.id}/${group.id}/${subtopic.id}`}
    >
      {
        !showSmallSubtopic && (
          <>
            <Tag style={{
              border: 'none',
              background: 'transparent',
              marginLeft: '4px',
              paddingRight: 0,
            }}
            >
              {group.name}
            </Tag>
            <RightOutlined className="conversation-info-arrow" />
            <Tag style={{
              border: 'none',
              background: 'transparent',
              paddingLeft: 0,
              marginLeft: '2px',
            }}
            >
              {topic.name}
            </Tag>
            <RightOutlined className="conversation-info-arrow" />
          </>
        )
      }
      <Tag style={{
        border: 'none',
        background: 'transparent',
        paddingLeft: showSmallSubtopic ? 5 : 0,
        marginLeft: '2px',
      }}
      >
        {subtopic.name}
      </Tag>
    </Link>
  );
};

export default withRouter(ConversationSubtopicLink);
