import * as React from 'react';
import { memo } from 'react';

const SvgComponent = (props) => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    width={73.77}
    height={37.09}
    {...props}
  >
    <defs>
      <style>
        {
          '.gear-icon-2,.gear-icon-3{fill:none;stroke:#010101}.gear-icon-4{opacity:.43}.gear-icon-2,.gear-icon-3{stroke-linecap:round}.gear-icon-3{stroke-linejoin:round}'
        }
      </style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <g id="loader-icon-group">
        <path
          id="gear"
          d="m35.13.5-2.25 5.36c-.44.15-.86.34-1.26.59L26.27 4.2l-3.24 3.24 2.25 5.36c-.22.45-.41.81-.59 1.26l-5.35 2.26v4.5l5.35 2.25c.18.45.36.81.59 1.26l-2.25 5.35 3.24 3.24 5.35-2.25c.4.18.81.41 1.26.58l2.25 5.35h4.5l2.25-5.35c.41-.18.85-.36 1.26-.58l5.35 2.25 3.24-3.24-2.25-5.36c.18-.41.41-.85.58-1.26l5.36-2.25v-4.49l-5.35-2.25a8 8 0 0 0-.58-1.26l2.25-5.36-3.24-3.24-5.35 2.25c-.41-.18-.85-.41-1.26-.59L39.64.51h-4.5Z"
          style={{
            fill: 'none',
            stroke: '#010101',
          }}
        />
        <g id="message-icon3" className="gear-icon-4">
          <path
            id="line8"
            className="gear-icon-3"
            d="M13.27 19.38c0 .99-.23 1.97-.68 2.85a6.386 6.386 0 0 1-5.71 3.53c-.99 0-1.97-.23-2.85-.68l-3.53.68.68-3.53C.73 21.35.5 20.37.5 19.38c0-2.42 1.37-4.63 3.53-5.71.88-.45 1.86-.68 2.85-.68h.38c3.24.18 5.83 2.77 6.01 6.01v.38Z"
          />
          <path id="line7" className="gear-icon-2" d="M4.06 17.85h5.65" />
          <path id="line6" className="gear-icon-2" d="M4.06 19.44h5.65" />
          <path id="line5" className="gear-icon-2" d="M4.06 21.04h3.19" />
        </g>
        <g id="messge-icon2" className="gear-icon-4">
          <path
            id="line4"
            className="gear-icon-3"
            d="M73.27 19.38c0 .99-.23 1.97-.68 2.85a6.386 6.386 0 0 1-5.71 3.53c-.99 0-1.97-.23-2.85-.68l-3.53.68.68-3.53c-.45-.88-.68-1.86-.68-2.85 0-2.42 1.37-4.63 3.53-5.71.88-.45 1.86-.68 2.85-.68h.38c3.24.18 5.83 2.77 6.01 6.01v.38Z"
          />
          <path id="line3" className="gear-icon-2" d="M64.06 17.85h5.65" />
          <path id="line2" className="gear-icon-2" d="M64.06 19.44h5.65" />
          <path id="line1" className="gear-icon-2" d="M64.06 21.04h3.19" />
        </g>
        <g id="message-icon">
          <path
            id="path4"
            className="gear-icon-3"
            d="M46.02 18.64c0 1.34-.31 2.66-.92 3.86a8.63 8.63 0 0 1-7.73 4.78c-1.34 0-2.67-.31-3.86-.92l-4.78.92.91-4.78c-.6-1.2-.92-2.52-.91-3.86 0-3.27 1.85-6.26 4.78-7.73 1.2-.6 2.52-.92 3.86-.91h.51c4.39.24 7.89 3.75 8.13 8.13v.51Z"
          />
          <path id="path3" className="gear-icon-2" d="M33.55 16.56h7.66" />
          <path id="path2" className="gear-icon-2" d="M33.55 18.72h7.66" />
          <path id="path1" className="gear-icon-2" d="M33.55 20.88h4.32" />
        </g>
      </g>
    </g>
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
