import React, { useState } from 'react';
import { displayOrCatch, useErrorState } from '@/helpers';
import PopoutForm from '@/components/forms/PopoutForm';
import {
  Button, Col, Form, Input, message, Row,
} from 'antd';
import rules from '@/helpers/rules';
import { auth } from '@/api';

const onFinish = (setError, setLoading) => (values) => {
  setLoading(true);
  auth.updateCompany(values).then(() => {
    message.success('Information updated');

    const teams = JSON.parse(localStorage.getItem('teams'));
    teams[0].company_name = values.name;
    localStorage.setItem('teams', JSON.stringify(teams));

    setLoading(false);
  }).catch((e) => {
    displayOrCatch(e, setError);
    setLoading(false);
  });
};

const ChangeCompanyForm = () => {
  const [error, setError] = useErrorState();
  const [loading, setLoading] = useState();

  return (
    <PopoutForm
      popoutError={error}
      layout="vertical"
      requiredMark={false}
      onFinish={onFinish(setError, setLoading)}
    >
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Form.Item
            className="mb-lg"
            name="name"
            rules={rules.company}
            label="Company name"
            initialValue={auth.team().company_name}
          >
            <Input size="large" placeholder="Company name" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            className="mb-xs"
          >
            <Button
              loading={loading}
              size="large"
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </PopoutForm>
  );
};

export default ChangeCompanyForm;
