import React, {
  useReducer, useEffect, useCallback, useMemo, useRef, useState,
} from 'react';
import {
  Spin,
  Tag,
  Tooltip as AntTooltip,
} from 'antd';
import moment from 'moment';
import classNames from 'classnames';
import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
  XAxis,
  YAxis,
  Area,
  Dot,
  Bar,
} from 'recharts';
import config from '../../../../config';
import {
  initialState,
  sentimentChartReducer,
  SET_DATA,
  SET_SELECTED,
} from '../../../sentiment-chart/sentimentChartReducer';
import SentimentLabel from '../../../sentiment-chart/components/SentimentLabel';
import GradientSentimentChartTooltip from '../../../sentiment-chart/components/GradientSentimentChartTooltip';
import { trimEllipse } from '../../../sentiment-chart/components/helper';
import GradientSentimentChartTooltipContent from '../../../sentiment-chart/components/GradientSentimentChartTooltipContent';

const GradientSentimentChart = ({
  filters, hasInteractions, onChange, dataSentiment, children,
}) => {
  const [{ data: { chart, annotations }, loading },
    dispatch] = useReducer(sentimentChartReducer, initialState);

  const chartRef = useRef();
  const [chartHeight, setChartHeight] = useState(300);

  const selectedItems = useMemo(() => chart.filter(({ selected }) => selected), [chart]);

  const onClick = useCallback((item) => () => {
    dispatch({ type: SET_SELECTED, payload: { item } });

    const payload = [...chart.filter(({ selected }) => selected)];

    if (!item.selected) {
      payload.push(item);
    }

    onChange?.(payload);
  }, []);

  useEffect(() => {
    dispatch({ type: SET_DATA, payload: dataSentiment.data });
    setChartHeight(chartRef.current?.containerRef?.current?.offsetHeight || 300);
  }, [filters]);

  const showAnnotation = chart?.length < 95;

  const xAxisProps = {
    dataKey: 'date',
    scale: 'band',
    axisLine: false,
    tickLine: false,
    dy: 12,
    tick: ({ payload, ...rest }) => {
      const { x, y } = rest;
      const tickData = chart.filter(
        (chartDataItem) => chartDataItem.date === payload.value,
      )[0] || {};

      return (
        <foreignObject x={x - 20} y={y} width={40} height={30}>
          <AntTooltip
            placement="bottom"
            overlayClassName="topic-metric-tooltip in-chart"
            title={(
              <GradientSentimentChartTooltipContent
                total={tickData.total}
                ratio={tickData.ratio}
                annotation={tickData.annotation}
                date={tickData.date}
              />
            )}
          >
            <div>
              <div className="x-axis-sentiment-circle" style={{ backgroundColor: tickData.sentimentColor }} />
              <div style={{ whiteSpace: 'nowrap', margin: 'auto' }}>{payload.value}</div>
            </div>
          </AntTooltip>
        </foreignObject>
      );
    },
  };

  const yAxisProps = {
    allowDecimals: false,
    allowDataOverflow: false,
    domain: [0, 'dataMax'],
    dataKey: 'total',
    type: 'number',
    axisLine: false,
    tickLine: false,
    dx: 0,
  };

  const cartesianGridProps = {
    horizontal: false,
    vertical: false,
  };

  const tooltipProps = {
    active: true,
    cursor: false,
    isAnimationActive: false,
    position: { x: 'auto', y: chartHeight - 20 },
    allowEscapeViewBox: { x: false, y: true },
    content: <GradientSentimentChartTooltip showAnnotation={showAnnotation} />,
  };

  if (loading) {
    return (
      <div className="sentiment-chart">
        <div className="sentiment-chart-loading">
          <Spin size="large" />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames('sentiment-chart-container', { 'sentiment-chart-container-with-dates': selectedItems.length })}>
      {children}
      <div className={classNames('sentiment-chart', { 'sentiment-chart-interactable': hasInteractions })}>
        <ResponsiveContainer width="100%" ref={chartRef}>
          <ComposedChart
            data={chart}
            onClick={(chartData) => {
              const payloadData = (chartData?.activePayload || [])[0]?.payload;

              if (payloadData) {
                onClick(payloadData)();
              }
            }}
            margin={{
              top: annotations.length ? 78 : 44, right: 0, left: 0, bottom: 24,
            }}
          >
            <XAxis {...xAxisProps} />

            <YAxis {...yAxisProps} />

            <CartesianGrid {...cartesianGridProps} />

            {annotations.map(({ date, label }) => (
              <ReferenceLine
                key={`annotation-${date}`}
                x={chart.find(({ point }) => point === date).date}
                stroke="#1CA797"
                fill="#1CA797"
                opacity={0.27}
                strokeDasharray="0"
                strokeWidth={8}
                radius={10}
                label={(
                  <SentimentLabel
                    value={showAnnotation ? trimEllipse(label, config.annotationMaxLength) : ''}
                    fullValue={label.length > config.annotationMaxLength ? label : null}
                    fill="#373737"
                    position="top"
                    offset={18}
                  />
                )}
              />
            ))}

            <Tooltip {...tooltipProps} />

            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#1CA797" stopOpacity={0.4} />
                <stop offset="95%" stopColor="#1CA797" stopOpacity={0} />
              </linearGradient>
            </defs>

            <Bar dataKey="total" barSize={1} fillOpacity={0.1} fill="#707070" />

            <Area
              type="linear"
              dataKey="total"
              stroke="#069F8D"
              fillOpacity={0.5}
              fill="url(#colorUv)"
              dot={
                ({
                  payload,
                  ...rest
                }) => (!payload.hide && (
                <Dot
                  payload={payload}
                  {...rest}
                  stroke="#069F8D"
                  strokeWidth={1}
                  fill="#fff"
                  fillOpacity={1}
                  r={4}
                />
                ))
              }
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div className="sentiment-chart-selected-dates">
        {selectedItems.map((entry) => (
          <Tag
            key={entry.point}
            closable
            onClose={onClick(entry)}
          >
            {moment(entry.point).format('MMM DD')}
          </Tag>
        ))}
      </div>
    </div>
  );
};

export default GradientSentimentChart;
