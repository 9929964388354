/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { useEffect, useState, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { insightsEnpointPick, insightsRelatedNamePick } from '../../../api';
import { displayOrCatchMessage } from '../../../helpers';
import { getInsightData } from '../../../transformers/insights';

const load = (pager, loadedInsights, setLoadedInsights, setPager, setLoading, setIsFirstCall, isColleague) => (filters, page, firstCall = 0) => {
  setLoading(true);

  insightsEnpointPick(isColleague).index(filters, page).then((response) => {
    const backendInsights = response.data.data.map(getInsightData);
    const timelineInsights = (firstCall < 0 ? backendInsights : [...loadedInsights, ...backendInsights]);
    setLoadedInsights(timelineInsights);
    setPager(response.data.pager);
    setLoading(false);
    setIsFirstCall(false);
  }).catch((e) => {
    displayOrCatchMessage(e);
    setLoading(false);
  });
};

const useInsightsFilters = (client, infinite = true, isColleague = false) => {
  const [page, setPage] = useState(0);
  const [created, setCreated] = useState('');
  const [company, setCompany] = useState('');
  const [selectedTypes, setSelectedTypes] = useState({
    red: true, green: true, yellow: true, transparent: true,
  });

  const selectType = (type) => setSelectedTypes({ ...selectedTypes, [type]: !selectedTypes[type] });

  const filters = useMemo(() => ({
    background_color: Object.keys(selectedTypes).filter((colorKey) => selectedTypes[colorKey]),
    created_at_between: created,
    company,
    [insightsRelatedNamePick(isColleague)]: client ? client.key : '',
  }), [selectedTypes, created, company]);

  const [isFirstCall, setIsFirstCall] = useState(true);
  const [pager, setPager] = useState(null);
  const [loadedInsights, setLoadedInsights] = useState([]);
  const [loading, setLoading] = useState(true);
  const loadInsightList = debounce(load(
    pager, loadedInsights, setLoadedInsights, setPager, setLoading, setIsFirstCall, isColleague,
  ), 300);
  const loadFilteredInsightList = (firstCallOrPage = 0) => {
    if (infinite) {
      const firstCall = firstCallOrPage;
      if (firstCall < 0) {
        setPage(1);
        loadInsightList(
          filters,
          1,
          firstCall,
        );
        return;
      }

      const pageNow = pager ? pager.current_page + 1 : 1;
      if (pageNow === page) return;

      setPage(pageNow);
      loadInsightList(
        filters,
        pageNow,
        firstCall,
      );
    } else {
      loadInsightList(
        filters,
        firstCallOrPage,
        -1,
      );
    }
  };

  useEffect(() => {
    setIsFirstCall(true);
    loadFilteredInsightList(-1);
  }, [created, company, selectedTypes]);

  useEffect(() => {
    if (isFirstCall) {
      loadFilteredInsightList(-1);
    }
  }, [isFirstCall]);

  return {
    setCreated,
    setCompany,
    selectType,
    loadedInsights,
    selectedTypes,
    page,
    setPage,
    loading,
    pager,
    loadFilteredInsightList,
    isFirstCall,
  };
};

export default useInsightsFilters;
