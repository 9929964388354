import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Form, Input, Button, message,
} from 'antd';
import { auth } from '@/api';
import userAuthenticatedAction from '../../store/actions/userAuthenticated';
import rules from '../../helpers/rules';
import { displayOrCatch, useErrorState } from '../../helpers';
import PopoutForm from '../forms/PopoutForm';

const onFinish = (userAuthenticated, displayError, setLoading) => (values) => {
  setLoading(true);
  auth
    .forgotPassword(values)
    .then((response) => {
      message.success(response.data.data.general);
      setLoading(false);
    })
    .catch((e) => {
      displayOrCatch(e, displayError);
      setLoading(false);
    });
};

const ForgotPasswordForm = ({ userAuthenticated }) => {
  const [error, setError] = useErrorState();
  const [loading, setLoading] = useState();

  return (
    <PopoutForm
      className="!mt-6 space-y-3 !px-4 md:!px-0"
      popoutError={error}
      onFinish={onFinish(userAuthenticated, setError, setLoading)}
    >
      <p className="m-0 text-sm text-[#75899B] font-[Gordita-Regular]">
        Enter your email and we&apos;ll send you instructions on how to reset
        your password.
      </p>
      <Form.Item name="email" rules={rules.email}>
        <Input size="large" placeholder="Email" />
      </Form.Item>
      <Form.Item>
        <Button
          loading={loading}
          size="large"
          className="cta"
          type="primary"
          htmlType="submit"
        >
          Send instructions
        </Button>
      </Form.Item>
    </PopoutForm>
  );
};

const mapDispatchToProps = (dispatch) => ({
  userAuthenticated: (data) => dispatch(userAuthenticatedAction(data)),
});

export default connect(null, mapDispatchToProps)(ForgotPasswordForm);
