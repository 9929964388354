/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from 'react';
import { INTEGRATION_TYPE } from '@/base-components/integrations/constants';
import config from '@/config';
import { useSelector } from 'react-redux';
import { usePlatforms } from '../hooks/usePlatforms';
import { OnboardingContext } from '../context';

export const showIntercomPopup = (token, onComplete) => {
  const myWindow = window.open(`${config.apiBaseUrl}/intercom/auth?token=${token}`,
    'logw', 'width=600,height=800');

  const timer = setInterval(() => {
    if (myWindow.closed) {
      clearInterval(timer);
      onComplete?.();
    }
  }, 1000);
};

export function IntercomContainer() {
  const { setStep } = useContext(OnboardingContext);
  const {
    data,
  } = usePlatforms();

  //! Might reuse later;
  // const connectIntercom = () => {
  //   const myWindow = window.open(`${config.apiBaseUrl}/intercom/auth?token=${user.token}`,
  //     'logw', 'width=600,height=800');

  //   const timer = setInterval(() => {
  //     if (myWindow.closed) {
  //       clearInterval(timer);
  //       refetch();
  //     }
  //   }, 1000);
  // };

  useEffect(() => {
    if (!data) return;

    if (data.filter((p) => p.type === INTEGRATION_TYPE.INTERCOM).length) {
      setStep(2);
    } else {
      // connectIntercom(); do nothing
    }
  }, [data]);

  return (
    <>
    </>
  );
}
