import React from 'react';
import moment from 'moment';
import { BuildOutlined, CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { improveLabel } from '@/components/filter/filters/CustomFieldFilter';

export const showLabel = (v) => {
  const value = JSON.parse(v);

  if (value === true) {
    return 'true';
  } if (value === false) {
    return 'false';
  } if (value === '') {
    return '""';
  } if (value === null) {
    return 'null';
  }

  return value;
};

export const showDateLabel = (v) => {
  const { start, end } = JSON.parse(v);
  return `${moment(start).format('D MMM YYYY')} - ${moment(end).format('D MMM YYYY')}`;
};

const SelectedCustomField = ({
  name, value, dataType, onClear,
}) => (name ? (
  <div className="selected-custom-field">
    <span><BuildOutlined /></span>
    <span>
      {name}
      :
      {' '}
      {dataType === 'date' ? showDateLabel(value) : improveLabel(showLabel(value))}
    </span>
    <Button onClick={onClear} type="link"><CloseOutlined /></Button>
  </div>
) : null);

export default SelectedCustomField;
