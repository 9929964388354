import React, { useState } from 'react';
import {
  Button, Dropdown, Empty, Menu,
} from 'antd';
import { ListFilter, XIcon } from 'lucide-react';
import TrackedButton from '@/components/TrackedButton';
import { mixpanelEvents } from '@/mixpanel';
import { useFiltersStore } from '@/store/zustand/useFiltersStore';
import { cn } from '@/helpers/util';
import AnomalySubscribeSwitch from '@/components/bucket/AnomalySubscribeSwitch';
import { filtersComponent, filtersList } from './utils';
import SaveModal from './SaveModal';
import SavedFilters from './SavedFilters';
import {
  useFilters, useFiltersDispatch,
} from './context';
import { FiltersTemplates } from './ui/Templates';

function FiltersAddButton({ className }) {
  const { selectedFilters } = useFilters();
  const dispatch = useFiltersDispatch();
  const filters = filtersList.filter((filter) => !selectedFilters.includes(filter.id));

  const addOrRemoveFilter = (item) => {
    if (selectedFilters.includes(item)) {
      dispatch({ type: 'REMOVE_SELECTED_FILTER', value: item });
    } else {
      dispatch({ type: 'ADD_SELECTED_FILTER', value: item });
    }
  };

  return (
    <Dropdown
      className={cn(className)}
      trigger={['click']}
      overlay={(
        <Menu className="!min-w-full">
          {filters.length === 0 ? (
            <Menu.Item disabled className="!text-[#75899B]">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No more filters available" />
            </Menu.Item>
          ) : filters.map(({ id, label }) => (
            <Menu.Item className="!text-[#44596C]" key={id} onClick={() => addOrRemoveFilter(id)}>{label}</Menu.Item>
          ))}
        </Menu>
      )}
    >
      <Button type="text" className="flex items-center gap-1.5 !border-[#CFDBE4] !rounded-md !shadow-md !shadow-[#d8e2e980] !text-[#75899B] !px-3 hover:!border-[#A9BCCF] hover:!bg-[#EFF4F8] hover:!text-[#44596C] disabled:!bg-[#F6F9FB] disabled:!text-[#CFDBE4] disabled:!border-[#DFE8F1]">
        <ListFilter size={16} />
        <span>Add Filter</span>
      </Button>
    </Dropdown>
  );
}

function FiltersSaveButton() {
  const { filters } = useFilters();
  const { filters: globalFilters } = useFiltersStore();

  const [save, setSave] = useState(false);
  const isHidden = globalFilters.topic_summary_result_id || (
    !filters.sentiment.length
    && !filters.custom_fields.length
    && !filters.sources.length
    && !filters.labels.length
    && !filters.included_keywords.length
    && !filters.excluded_keywords.length
  );

  return !isHidden ? (
    <>
      <TrackedButton
        event={mixpanelEvents.CREATE_TOPIC_CLICKED}
        className="!border-[#A9BCCF] !bg-gradient-to-l from-[#EBFFF1] to-[#EBF8FF] !rounded-md !text-[#44596C] !text-sm !px-3 !shadow-md !shadow-[#d8e2e980] hover:from-[#D9F9E3] hover:to-[#D7F1FF] hover:!text-[#202324] disabled:!bg-[#F6F9FB] disabled:!shadow-none disabled:!text-[#CFDBE4] disabled:!border-[#DFE8F1]"
        htmlType="button"
        disabled={globalFilters.topic_summary_result_id || (
          !filters.sentiment.length
          && !filters.custom_fields.length
          && !filters.sources.length
          && !filters.labels.length
          && !filters.included_keywords.length
          && !filters.excluded_keywords.length
        )}
        onClick={() => setSave(!save)}
      >
        <span>
          Save as segment
        </span>
      </TrackedButton>
      <SaveModal
        visible={save}
        onCancel={() => setSave(false)}
        closeIcon={<XIcon size={16} />}
        setVisible={setSave}
        destroyOnClose
      />
    </>
  ) : null;
}

function FiltersHeader({
  title = 'Filters', topicId, topic, children,
}) {
  return (
    <header className="px-4 pt-3 flex flex-col justify-between">
      <AnomalySubscribeSwitch topicId={topicId} topic={topic} />
      <h3 className="m-0 text-sm text-[#75899B] font-[Gordita-Regular]">{title}</h3>
      <aside>
        {children}
      </aside>
    </header>
  );
}

function FiltersList() {
  const { filters, selectedFilters } = useFilters();
  return selectedFilters.length > 0 ? (
    <div className="mt-3 space-y-2 max-h-[calc(100%-96px)] no-scrollbar overflow-y-scroll">
      {selectedFilters.map((item) => {
        if (!(item in filtersComponent)) return null;
        const FilterComponent = filtersComponent[item];
        return <FilterComponent key={item} defaultValue={filters?.[item]} name={filtersList.find((filter) => filter.id === item).label} />;
      })}
    </div>
  ) : null;
}

function FiltersView({
  className, children,
}) {
  return (
    <div className={cn('no-scrollbar overflow-y-scroll ', className)}>
      {children}
    </div>
  );
}

export default function Filters({
  className, children,
}) {
  return (
    <section className={cn('relative min-h-[300px] bg-white rounded-lg', className)}>
      {children}
    </section>
  );
}

Filters.View = FiltersView;
Filters.Templates = FiltersTemplates;
Filters.List = FiltersList;
Filters.SavedFilters = SavedFilters;
Filters.Header = FiltersHeader;
Filters.AddButton = FiltersAddButton;
Filters.SaveButton = FiltersSaveButton;
