import React from 'react';
import { Avatar as AntdAvatar } from 'antd';
import Avatar from '@/components/clients/Avatar';

const ClientAvatar = ({
  avatar, client,
}) => (
  <div className="avatar-section">
    {client ? <Avatar size={46} client={client} /> : <AntdAvatar size={46} src={avatar} />}
  </div>
);

export default ClientAvatar;
