import React from 'react';
import { Button } from 'antd';
import customerIo from '@/customer-io';

export function HubspotContainer() {
  const onClick = () => {
    if (import.meta.env.VITE_CUSTOMERIO_SITEID) {
      customerIo.track('meeting-booked-clicked');
    }
  };

  return (
    <div className="mt-4">
      <Button
        onClick={onClick}
        size="large"
        type="primary"
        className="w-full"
        href="https://meetings.clientzen.io/meetings/ovi/30mins"
        target="_blank"
      >
        Book a call
      </Button>
    </div>
  );
}
