import React from 'react';
import classNames from 'classnames';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import HowItWorks from './HowItWorks';
import { ReactComponent as HowItWorksIconKeywords } from '../svgs/icon-how-keywords.svg';
import TopicPreviewData from './TopicPreviewData';
import {
  SentimentRadioGroupDateFilter,
  useSentimentDateFilterInitialValue,
} from '../../base-components/sentiment-date-filter';
import { ACTION_UPDATE_INTERVAL } from './reducer';
import loadPreviewHandler from './loadPreviewHandler';

const TopicPreview = ({
  className, subtopic, topicId, topicData,
}) => {
  const {
    preview,
    includedKeywords = [],
    excludedKeywords = [],
    highlighted,
  } = useSelector((state) => state.topicForm);
  const dispatch = useDispatch();
  const loadPreview = loadPreviewHandler(dispatch, subtopic, topicId);
  const defaultValue = useSentimentDateFilterInitialValue();

  return (
    <div className={classNames(className, { ready: preview.visible })}>
      {!preview.loading && preview.visible && (
        <div className="preview-header">
          <div>
            <div>Preview</div>
            <div className="mentions">{`${preview.count} mentions`}</div>
          </div>
          <SentimentRadioGroupDateFilter
            initialValue={defaultValue}
            onChange={(value) => {
              loadPreview(includedKeywords, excludedKeywords, highlighted, value);
              dispatch({
                type: ACTION_UPDATE_INTERVAL,
                payload: value,
              });
            }}
          />
        </div>
      )}
      {preview.loading && <div className="loader"><Spin className="mt-xl" /></div> }
      {(!preview.loading && !preview.visible && (!topicData || topicData.type === 'keyword_based')) && (
      <HowItWorks
        icon={<HowItWorksIconKeywords />}
        description="Add keywords, select broad or exact match and get a preview of selected conversations."
      />
      )}
      {!preview.loading && preview.visible && (
        <TopicPreviewData preview={preview} />
      )}
    </div>
  );
};

export default TopicPreview;
