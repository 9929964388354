import React, { useCallback, useState } from 'react';
import { Button, Input } from 'antd';
import { teams, auth } from '@/api';
import { displayOrCatchMessage } from '@/helpers';
import { connect } from 'react-redux';
import userAuthenticatedAction from '@/store/actions/userAuthenticated';
import { useQueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';

const PromptsTab = () => {
  const queryClient = useQueryClient();
  const [questionPrompt, setQuestionPrompt] = useState(auth.team().question_prompt);
  const [requestPrompt, setRequestPrompt] = useState(auth.team().request_prompt);
  const [otherPrompt, setOtherPrompt] = useState(auth.team().other_prompt);
  const [issuePrompt, setIssuePrompt] = useState(auth.team().issue_prompt);
  const [loading, setLoading] = useState(false);
  const onSave = useCallback(() => {
    setLoading(true);
    teams
      .setPrompts({
        question_prompt: questionPrompt,
        request_prompt: requestPrompt,
        other_prompt: otherPrompt,
        issue_prompt: issuePrompt,
      })
      .then(() => {
        localStorage.setItem('teams', JSON.stringify([{
          ...auth.team(),
          question_prompt: questionPrompt,
          request_prompt: requestPrompt,
          other_prompt: otherPrompt,
          issue_prompt: issuePrompt,
        }]));
        try {
          queryClient.getQueryCache().clear();
        } catch (e) {
          Sentry.captureException(e);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        displayOrCatchMessage(error);
      });
  }, [questionPrompt, issuePrompt, requestPrompt, otherPrompt]);

  return (
    <div className="settings-tab notifications-tab container-sm">
      <h2>Sentiment language preferences</h2>
      <p>Update your sentiment language preferences here.</p>
      <ul className="mt-8 max-w-xl p-0">
        <li className="p-2.5 mt-3.5 border rounded-md border-solid border-[#E8E8E8] text-[#595959] flex justify-between cursor-pointer">
          <Input.TextArea
            size="large"
            placeholder="Prompt for questions"
            onChange={(e) => setQuestionPrompt(e.target.value)}
            value={questionPrompt}
          />
        </li>
        <li className="p-2.5 mt-3.5 border rounded-md border-solid border-[#E8E8E8] text-[#595959] flex justify-between cursor-pointer">
          <Input.TextArea
            size="large"
            placeholder="Prompt for issues"
            onChange={(e) => setIssuePrompt(e.target.value)}
            value={issuePrompt}
          />
        </li>
        <li className="p-2.5 mt-3.5 border rounded-md border-solid border-[#E8E8E8] text-[#595959] flex justify-between cursor-pointer">
          <Input.TextArea
            size="large"
            placeholder="Prompt for requests"
            onChange={(e) => setRequestPrompt(e.target.value)}
            value={requestPrompt}
          />
        </li>
        <li className="p-2.5 mt-3.5 border rounded-md border-solid border-[#E8E8E8] text-[#595959] flex justify-between cursor-pointer">
          <Input.TextArea
            size="large"
            placeholder="Prompt for others"
            onChange={(e) => setOtherPrompt(e.target.value)}
            value={otherPrompt}
          />
        </li>
      </ul>
      <div className="flex justify-end max-w-xl">
        <Button type="primary" onClick={onSave} loading={loading}>Save</Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  userAuthenticated: (data) => dispatch(userAuthenticatedAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromptsTab);
