/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import {
  ArrowUp, ArrowDown,
} from 'lucide-react';

export const Trend = Object.freeze({
  ASC: 'ascending',
  DESC: 'descending',
});

const TopicMetric = (props) => {
  const {
    tooltip,
    name,
    value,
    trend,
    style = {},
    difference,
    showTrend = true,
  } = props;

  const metricNameValue = useMemo(() => (
    <span className="metric-name-value">
      {name && (<span>{name}</span>)}
      <span>{value}</span>
      {trend && showTrend
        ? (trend === Trend.ASC ? <ArrowUp size={16} strokeWidth={2.5} /> : <ArrowDown size={16} strokeWidth={2.5} />)
        : null}
    </span>
  ), [name, value, trend]);

  return (
    <div className={classNames('metric-body', trend)}>
      <span style={{
        fontSize: 12, display: 'flex', alignItems: 'center', ...style,
      }}
      >
        {tooltip ? (
          <Tooltip
            title={tooltip}
            placement="bottom"
            overlayClassName="topic-metric-tooltip"
            overlayInnerStyle={{ fontSize: 12, marginBottom: 0 }}
          >
            {metricNameValue}
          </Tooltip>
        ) : metricNameValue}
      </span>
      {difference && <span className="difference">{difference}</span>}
    </div>
  );
};

export default TopicMetric;

export function numFormatter(num) {
  if (num >= 1_000_000) return `$${(num / 1_000_000).toFixed(1)}M`;
  if (num > 999 && num < 1_000_000) return `$${(num / 1000).toFixed(1)}K`;
  return `$${Math.round(num)}`;
}

function dayFormatter(num) {
  if (num === null) {
    return '-';
  }

  if (Math.ceil(num / 7) > 4) {
    return `${Math.ceil(num / 30)}M`;
  } if (num >= 7) {
    return `${Math.ceil(num / 7)}W`;
  }

  return `${num}D`;
}

TopicMetric.MRR = ({ value, ...rest }) => (
  <TopicMetric
    value={numFormatter(value)}
    {...rest}
  />
);

TopicMetric.Renewal = ({ value, ...rest }) => (
  <TopicMetric
    value={dayFormatter(value)}
    {...rest}
  />
);
