const userSubscribeStatusChanged = ({ subscribed, onGracePeriod, plan }) => (dispatch) => {
  dispatch({
    type: 'USER_SUBSCRIBE_STATUS_CHANGED',
    subscribed,
    onGracePeriod,
    plan,
  });
};

export default userSubscribeStatusChanged;
