import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const useChainlitStore = create(devtools((set) => ({
  token: null,
  topic: null,
  setToken: (token) => set({ token }),
  expanded: false,
  expandedMessage: null,
  setExpanded: (expanded, topic) => set({ expanded, topic: expanded ? topic : null }),
  setExpandedMessage: (expandedMessage) => set({ expandedMessage }),
  reset: () => set({
    expanded: false,
    expandedMessage: null,
    token: null,
    topic: null,
  }),
}), {
  name: 'useChainlitStore',
}));
