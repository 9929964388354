import { useFiltersStore } from '@/store/zustand/useFiltersStore';
import { useConversationStore } from '@/store/zustand/useConversationsStore';
import { usePaginationStore } from '@/store/zustand/usePaginationStore';
import { useThreadPaginationStore } from '@/store/zustand/useThreadPaginationStore';
import { useSummaryVisibleTypes } from '@/store/zustand/useSummaryVisibleTypes';

export default function useResetGlobal() {
  const resetFiltersStore = useFiltersStore((state) => state.reset);
  const resetConversationsStore = useConversationStore((state) => state.reset);
  const resetPaginationStore = usePaginationStore((state) => state.reset);
  const resetThreadPaginationStore = useThreadPaginationStore((state) => state.reset);
  const resetSummaryVisibleTypes = useSummaryVisibleTypes((state) => state.reset);

  return {
    resetStore: () => {
      resetFiltersStore();
      resetConversationsStore();
      resetPaginationStore();
      resetThreadPaginationStore();
      resetSummaryVisibleTypes();
    },
  };
}
