/* eslint-disable */
import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import UrlSearchService from './UrlSearchService';
import { paramsToObject, isNegligible as isNegligibleDefault } from './utils';
import { assign } from 'lodash'

const useURLState = (queryObject = {}, { isNegligible } = { isNegligible: isNegligibleDefault }) => {
	const history = useHistory();
	const location = useLocation();
	const urlSearchParams = new URLSearchParams(location.search);
	const urlSearchService = UrlSearchService(urlSearchParams, isNegligible);
	const urlQueryState = paramsToObject(urlSearchParams);

	const goToNewUrl = () => history.push(`${location.pathname}${urlSearchParams.toString() ? '?' + urlSearchParams.toString() : ''}${location.hash ? location.hash : ''}`);

	const setUrlQueryParam = (key, value) => {
		urlSearchService.setParam(key, value);
		goToNewUrl();
	};

	const setUrlQueryParams = (object) => {
		Object.entries(object).forEach(([key, value]) => {
			urlSearchService.setParam(key, value);
		});
		goToNewUrl();
	};

	const addUrlQueryParam = (key, value) => {
		urlSearchService.addParam(key, value);
		goToNewUrl();
	};

	const addUrlQueryParams = (object) => {
		Object.entries(object).forEach(([key, value]) => {
			urlSearchService.addParam(key, value);
		});
		goToNewUrl();
	};

	const deleteUrlQueryParam = (key) => {
		urlSearchService.deleteParam(key);
		goToNewUrl();
	};

	const deleteUrlQueryParams = (keys) => {
		keys.forEach((key) => {
			urlSearchService.deleteParam(key);
		});
		goToNewUrl();
	};

	useEffect(() => {
		assign(queryObject, urlQueryState);
		queryObject && setUrlQueryParams(queryObject);
	}, []);

	return {
		urlQueryState,
		setUrlQueryParam,
		setUrlQueryParams,
		addUrlQueryParam,
		addUrlQueryParams,
		deleteUrlQueryParam,
		deleteUrlQueryParams,
	};
};

export default useURLState;
