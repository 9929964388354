import React, { useState } from 'react';
import { Button, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import { topics, subtopics } from '@/api';
import { ACTION_RESET_TOPIC_STEPS, ACTION_UPDATE_TOPIC_STEPS_STATE } from './reducer';

const getContinueEnabled = (
  includedKeywords,
  excludedKeywords,
) => includedKeywords?.length > 0 || excludedKeywords?.length > 0;

const getCallee = (subtopic, data) => {
  const main = subtopic ? subtopics : topics;

  if (data) {
    return subtopic ? main.updateSubtopic : main.updateTopic;
  }

  return subtopic ? main.createSubtopic : main.createTopic;
};

const TopicFormFooter = ({
  onCancel, onOk, data: topicData, subtopic, topicId,
}) => {
  const dispatch = useDispatch();
  const state = useSelector((s) => s.topicForm);
  const {
    includedKeywords = [],
    excludedKeywords = [],
    name,
    groups,
  } = state;
  const [loading, setLoading] = useState(false);
  const continueEnabled = getContinueEnabled(
    includedKeywords, excludedKeywords,
  );
  const onSubmit = () => {
    dispatch({
      type: ACTION_UPDATE_TOPIC_STEPS_STATE,
      payload: {
        step3Validate: true,
      },
    });
    if (name && (groups || subtopic)) {
      setLoading(true);
      getCallee(subtopic, topicData)({
        id: topicData ? topicData.id : undefined,
        name,
        groups: [groups],
        topics: [topicId],
        included_keywords: includedKeywords,
        excluded_keywords: excludedKeywords,
      }).then(({ data: { data } }) => {
        setLoading(false);
        onOk(data, !!topicData);
        dispatch({ type: ACTION_RESET_TOPIC_STEPS });
        message.success(`Segment ${topicData ? 'updated' : 'created'} successfully`);
      }).catch((e) => {
        setLoading(false);
        Sentry.captureException(e);
        message.error('Segments Error');
      });
    }
  };
  return (
    <footer>
      <div className="left">
        <Button onClick={onCancel} className="mr-xs ant-btn-secondary">
          Cancel
        </Button>
      </div>
      <div className="right">
        <Button
          type="primary"
          loading={loading}
          disabled={!continueEnabled}
          onClick={onSubmit}
        >
          {`${topicData ? 'Update' : 'Create'} ${subtopic ? 'subsegment' : 'segment'}`}
        </Button>
      </div>
    </footer>
  );
};

export default TopicFormFooter;
