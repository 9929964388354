import React from 'react';
import { connect } from 'react-redux';
import InsightDescription from '../InsightDescription';
import showInsightAction from '../../../store/actions/showInsight';

const InsightDescriptionWithModal = ({
  item,
  loadList,
  onClientList,
  showInsight,
  ...props
}) => <InsightDescription {...props} onClick={showInsight} />;

const mapDispatchToProps = (dispatch, { item, onClientList, isColleague }) => ({
  showInsight: () => dispatch(showInsightAction(item, onClientList, isColleague)),
});

export default connect(null, mapDispatchToProps)(InsightDescriptionWithModal);
