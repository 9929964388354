import React from 'react';
import { Tabs } from 'antd';
import { Link, useParams } from 'react-router-dom';
import ConversationPartialExclusionTab from '@/components/settings/ConversationPartialExclusionTab';
import {
  MessageCircle, MessageCircleDashed, Users, BookmarkX,
} from 'lucide-react';
import PeopleExclusionTab from '@/components/settings/PeopleExclusionTab';
import LabelsExclusionTab from '@/components/settings/LabelsExclusionTab';
import ConversationExclusionTab from './ConversationExclusionTab';

const { TabPane } = Tabs;

const getActiveKey = (tab) => {
  switch (tab) {
    case 'exclude-conversations': return 'conversations_exclusions_strict';
    case 'exclude-people': return 'settings_exclusions_people';
    case 'exclude-labels': return 'settings_exclusions_labels';
    default: return 'conversations_exclusions_strict';
  }
};

const ExclusionTab = () => {
  const { tab } = useParams();

  return (
    <div className="settings-tab container-sm">
      <Tabs defaultActiveKey={getActiveKey(tab)} tabPosition="left" className="ant-exclusions-subtabs">
        <TabPane
          tab={(
            <Link to="/settings/exclude-conversations" className="!flex align-center gap-2">
              <MessageCircle className="mt-0.5" size={16} strokeWidth={2} />
              <span>Conversations - Exact match</span>
            </Link>
          )}
          key="conversations_exclusions_strict"
        >
          <ConversationExclusionTab />
        </TabPane>
        <TabPane
          tab={(
            <Link to="/settings/exclude-conversations" className="!flex gap-2">
              <MessageCircleDashed className="mt-0.5" size={16} strokeWidth={2} />
              <span>Conversations - Partial match</span>
            </Link>
          )}
          key="conversations_exclusions_partial"
        >
          <ConversationPartialExclusionTab />
        </TabPane>
        <TabPane
          tab={(
            <Link to="/settings/exclude-people" className="!flex gap-2">
              <Users className="mt-0.5" size={16} strokeWidth={2} />
              <span>Exclude People</span>
            </Link>
          )}
          key="settings_exclusions_people"
        >
          <PeopleExclusionTab />
        </TabPane>
        <TabPane
          tab={(
            <Link to="/settings/exclude-labels" className="!flex gap-2">
              <BookmarkX className="mt-0.5" size={16} strokeWidth={2} />
              <span>Exclude Labels</span>
            </Link>
          )}
          key="settings_exclusions_labels"
        >
          <LabelsExclusionTab />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ExclusionTab;
