import React from 'react';
import { Helmet } from 'react-helmet';

const TermsOfService = () => (
  <>
    <Helmet>
      <title>ClientZen - Terms of Service</title>
    </Helmet>
    <h2>Terms of Service</h2>
  </>
);

export default TermsOfService;
