import React, { useState } from 'react';
import { insightsEnpointPick } from '@/api';
import InsightPartHelpfulMarkButton from './InsightPartHelpfulMarkButton';
import { displayOrCatchMessage } from '../../helpers';

const onClick = ({
  insight,
  feedback,
  setSelectedFeedback,
  setLoading,
  loading,
  currentFeedback,
  id,
  isColleague,
}) => () => {
  if (!loading) {
    setLoading(true);
    setSelectedFeedback(feedback);
    insightsEnpointPick(isColleague).markPart(feedback, insight.key, id).then(() => {
      setLoading(false);
    }).catch((e) => {
      displayOrCatchMessage(e);
      setLoading(false);
      setSelectedFeedback(currentFeedback);
    });
  }
};

const getInitialFeedback = (helpful) => {
  if (helpful === true) {
    return 'positive';
  }

  if (helpful === false) {
    return 'negative';
  }

  return null;
};

const InsightPartHelpfulMark = ({
  id, insight, helpful, isColleague,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(getInitialFeedback(helpful));

  return (
    <>
      <InsightPartHelpfulMarkButton
        onClick={onClick({
          insight,
          feedback: 'positive',
          setSelectedFeedback,
          setLoading,
          loading,
          currentFeedback: selectedFeedback,
          id,
          isColleague,
        })}
        highlighted={selectedFeedback === 'positive'}
        feedback="positive"
      />
      <InsightPartHelpfulMarkButton
        onClick={onClick({
          insight,
          feedback: 'negative',
          setSelectedFeedback,
          setLoading,
          loading,
          currentFeedback: selectedFeedback,
          id,
          isColleague,
        })}
        highlighted={selectedFeedback === 'negative'}
        feedback="negative"
      />
    </>
  );
};

export default InsightPartHelpfulMark;
