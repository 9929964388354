import moment from 'moment';
import auth from './auth';
import axios from './axios';

const discovery = ({ filename, ...data }) => axios.post('/api/export/discovery', {
  ...data,
  team_id: auth.team().id,
}, {
  responseType: 'blob',
}).then((response) => {
  // create file link in browser's memory
  const href = URL.createObjectURL(response.data);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  const start = moment(data.start, 'YYYY-MM-DD').format('DDMMM');
  const end = moment(data.end, 'YYYY-MM-DD').format('DDMMM');
  link.setAttribute('download', `${filename}-${start}-${end}.csv`);
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}).catch((e) => Promise.reject(e));

const clients = ({ filename, ...data }) => axios.post('/api/export/clients', {
  ...data,
  team_id: auth.team().id,
}, {
  responseType: 'blob',
}).then((response) => {
  // create file link in browser's memory
  const href = URL.createObjectURL(response.data);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  const start = moment(data.start, 'YYYY-MM-DD').format('DDMMM');
  const end = moment(data.end, 'YYYY-MM-DD').format('DDMMM');
  link.setAttribute('download', `${filename}-${start}-${end}.csv`);
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}).catch((e) => Promise.reject(e));

const exportData = {
  discovery,
  clients,
};

export default exportData;
