import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { List } from 'antd';
import SegmentLine from '@/components/ui/segment/SegmentLine';
import Card from '@/components/card';
import { cn } from '@/helpers/util';

function VocalSegmentLine({
  data, total, onClick, maxValue,
}) {
  const history = useHistory();
  const percent = useMemo(() => {
    if (!data) return 0;
    const procent = (data.all_count / total) * 100;
    if (procent > 100) return 100;
    if (Number.isNaN(procent)) return 0;
    return procent.toFixed(0);
  }, [data]);

  const width = useMemo(() => {
    if (!data) return 0;
    return data.all_count !== '0' ? Math.ceil((data.all_count / maxValue) * 100) : Math.ceil((0.1 / maxValue) * 100);
  }, [data.all_count]);

  const handleClick = () => {
    if (onClick) {
      onClick(data);
    }
    history.push(`/people?userType=${encodeURIComponent(data.name)}`);
  };

  return (
    <SegmentLine
      name={data.name}
      width={width}
      percent={percent}
      value={data.all_count}
      onClick={handleClick}
      colorLineClassName="bg-[#E8DEF5] group-hover:bg-[#E8DEF5]"
    />
  );
}

export function MostVocalSegmentsCard({
  name = 'Most Vocal Segments', data, onRowClick, total, className,
}) {
  return (
    <Card className={cn('py-[18px] mb-4', className)}>
      <Card.Header>
        <div className="flex items-center divide-y-0 divide-x-2 gap-7 divide-solid divide-[#DFE9F1]">
          <Card.Header.Title title={name} />
        </div>
        <Card.Header.Link to="/people" />
      </Card.Header>
      <Card.Body>
        <List
          className="[&_.ant-list-header]:p-0 [&_.ant-list-header]:!border-0 [&_.ant-list-items]:space-y-2"
          dataSource={data}
          renderItem={(item) => (
            <VocalSegmentLine
              data={item}
              total={total}
              onClick={onRowClick}
              maxValue={parseInt(data[0].all_count, 10)}
            />
          )}
        />
      </Card.Body>
    </Card>
  );
}
