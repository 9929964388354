import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { billing } from '@/api';
import CheckoutForm from './CheckoutForm';
import { displayOrCatchMessage } from '../../helpers';

const StripeElements = ({
  choosenPlan,
  setupIntent,
  setShouldRedirect,
}) => {
  const [stripePromise, setStripePromise] = useState(null);
  useEffect(() => {
    setStripePromise(billing.key().then((response) => loadStripe(response.data.data)).catch((e) => {
      displayOrCatchMessage(e);
    }));
  }, []);

  return stripePromise ? (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        setupIntentSecret={setupIntent}
        choosenPlan={choosenPlan}
        setShouldRedirect={setShouldRedirect}
      />
    </Elements>
  ) : null;
};

export default StripeElements;
