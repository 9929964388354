/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useEffect } from 'react';
import {
  Modal as AntdModal, Form as AntdForm, Button, message, Input, DatePicker,
} from 'antd';
import moment from 'moment';
import { annotations } from '@/api';
import { useErrorState, displayOrCatch } from '@/helpers';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useModalStore } from '@/store/zustand/useModalStore';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import PopoutForm from '../forms/PopoutForm';

const ReleaseForm = ({
  form, defaultValues, error, onSubmit,
}) => (
  <PopoutForm
    layout="vertical"
    form={form}
    popoutError={error}
    onFinish={onSubmit}
    initialValues={defaultValues}
  >
    <AntdForm.Item
      key="label"
      name="label"
      label="Annotation name"
      rules={[{ required: true, message: 'Annotation name is required' }]}
    >
      <Input
        size="large"
        className="!rounded-md !border-[#CFDBE4] !px-3.5 !py-1.5 focus:!ring-[#0CAA6B1A] !text-[#202324] !text-sm focus:!border-[#74899B] focus:!ring placeholder:font-[Gordita-Regular] placeholder:!text-[#74899B] group-[.ant-form-item-has-error]:!border-[#F17C99]"
        placeholder="e.g. Feature update"
      />
    </AntdForm.Item>
    <AntdForm.Item
      key="date"
      name="date"
      label="Date"
      rules={[{ required: true, message: 'Annotation date is required' }]}
    >
      <DatePicker
        className="w-full !rounded-md !border-[#CFDBE4] !px-3.5 !py-1.5 focus:!ring-[#0CAA6B1A] !text-[#202324] !text-sm focus:!border-[#74899B] focus:!ring placeholder:font-[Gordita-Regular] placeholder:!text-[#74899B] group-[.ant-form-item-has-error]:!border-[#F17C99]"
      />
    </AntdForm.Item>
  </PopoutForm>
);

const ReleaseCreateModal = ({
  topicId, subtopicId, name, date, visible, onOk, onCancel,
}) => {
  const queryClient = useQueryClient();
  const { defaultDate } = useDefaultDateStore();
  const isWeek = moment(defaultDate.end).diff(moment(defaultDate.start), 'days') > 40 ? 1 : 0;
  const filters = useMemo(() => ({
    start: defaultDate.start,
    end: defaultDate.end,
    week: isWeek,
  }), [defaultDate, isWeek]);

  const { setModal } = useModalStore();
  const [form] = AntdForm.useForm();
  const [error, setError] = useErrorState();
  const defaultValues = useMemo(() => ({
    label: '',
    date: date ? moment(date) : '',
  }), [date]);

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [date]);

  useEffect(() => () => {
    setError(null);
  }, [visible]);

  /** MUTATION API */
  // TODO: optimistic update
  const createRelease = useMutation({
    mutationKey: 'release_create',
    mutationFn: async (data) => {
      await annotations.createAnnotation(data);
    },
    onMutate: async (data) => {
      const previousReleases = queryClient.getQueryData(['topic_graph', topicId, subtopicId, { filters }]);

      if (previousReleases) {
        queryClient.setQueryData(['topic_graph', topicId, subtopicId, { filters }], ({ chart, annotations }) => {
          const newReleases = {
            chart,
            annotations: [
              ...annotations,
              {
                id: Math.random().toString(36).substring(7),
                ...data,
              },
            ],
          };
          return { ...newReleases };
        });
      }

      return { ...previousReleases };
    },
    onSuccess: () => {
      form.setFieldsValue(defaultValues);
      onOk?.();
      setModal(null);
      message.success('Annotation created successfully');
    },
    onSettled: () => {
      queryClient.invalidateQueries(['topic_graph', topicId, subtopicId, { filters }]);
    },
    onError: (err, variables, ctx) => {
      if (ctx?.previousReleases) {
        queryClient.setQueryData(['topic_graph', topicId, subtopicId, { filters }], ctx.previousReleases);
      }
      displayOrCatch(err, setError);
    },
  });

  /** FORM EVENTS */
  const handleCancel = () => {
    form.setFieldsValue(defaultValues);
    onCancel?.();
    setModal(null);
  };

  const handleSubmit = (payload) => {
    const data = { date: payload.date.format('YYYY-MM-DD'), label: payload.label, topic_id: topicId };
    createRelease.mutate(data);
  };
  /** END FORM EVENTS */

  return (
    <AntdModal
      title={`Create release${name ? ` for ${name}` : ''}`}
      visible={visible}
      className="create-modal"
      width={650}
      onCancel={handleCancel}
      destroyOnClose
      footer={[
        <Button
          key="cancel"
          disabled={createRelease.isLoading}
          onClick={handleCancel}
        >
          Cancel
        </Button>,
        <Button
          key="create"
          type="primary"
          loading={createRelease.isLoading}
          onClick={() => form.submit()}
        >
          Create Release
        </Button>,
      ]}
    >
      <ReleaseForm form={form} error={error} defaultValues={defaultValues} onSubmit={handleSubmit} />
    </AntdModal>
  );
};

export default ReleaseCreateModal;
