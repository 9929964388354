/* eslint-disable jsx-a11y/alt-text */
import React, { useMemo, useState } from 'react';
import moment from 'moment';
import {
  Button, Dropdown, Menu, Modal, Select,
} from 'antd';
import { InboxOutlined, EllipsisOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import GhostButton from '@/components/GhostButton';
import { topics } from '@/api';
import { displayOrCatchMessage } from '@/helpers';
import IconCollapse from '@/images/icon-collapse.svg';
import IconExpand from '@/images/icon-expand.svg';
import useConversationsStore from '../store/useConversationsStore';

const { Option } = Select;

const ConversationActions = ({
  conversation,
  isDiscovery,
}) => {
  const imgProps = conversation.isTextCollapsed
    ? { src: IconExpand, alt: 'Uncollapse ' }
    : { src: IconCollapse, alt: 'Collapse' };

  const isCollapsable = conversation.snippet && conversation.text
   && conversation.snippet.length !== conversation.text.length;

  const [loadedTopics, setLoadedTopics] = useState(
    conversation.topics ? conversation.topics : [],
  );
  const [visible, setVisible] = useState(false);
  const [moveTopics, setMoveTopics] = useState(
    conversation.topics ? conversation.topics.map((item) => item.id) : [],
  );
  const [loading, setLoading] = useState(false);

  const {
    actions: { updateTopic, deleteConversation, changeTextCollapse },
  } = useConversationsStore();

  const handleDeleteConversation = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete conversation?',
      okText: 'Delete',
      cancelText: 'Cancel',
      okType: 'primary',
      cancelType: 'default',
      onOk: () => {
        deleteConversation(conversation.id);
      },
      okButtonProps: {
        danger: true,
      },
    });
  };

  const handleUpdateTopic = async () => {
    if (moveTopics.length > 0) {
      setLoading(true);
      const updated = await updateTopic(conversation.id, moveTopics);
      setLoading(false);
      if (updated) {
        setVisible(false);
      }
    }
  };

  const handleCancelUpdate = () => {
    setVisible(false);
  };

  const handleBeginUpdate = () => {
    setLoading(true);
    setVisible(true);

    topics.getTopics().then((response) => {
      setLoadedTopics(response.data);
      setLoading(false);
    }).catch((e) => displayOrCatchMessage(e));
  };

  const timeAgo = useMemo(
    () => {
      const date = moment(conversation.date); // Example date more than a week ago

      const today = moment(); // Current date/time

      if (today.diff(date, 'days') > 7) {
        return date.format('DD/MM/YYYY');
      }
      return date.fromNow();
    },
    [conversation],
  );

  return (
    <div className="conversation-content-actions">
      <div className="conversation-content-time-ago">
        {timeAgo}
      </div>
      <div className="conversation-content-icons-container">
        {isCollapsable && (
        <Button type="text" onClick={() => changeTextCollapse(conversation.id)} className="conversation-content-collapse-btn">
          <img {...imgProps} className="conversation-content-collapse-image" />
        </Button>
        )}
        {!isDiscovery && (
          <Dropdown
            overlay={(
              <Menu>
                <Menu.Item>
                  <GhostButton onClick={handleBeginUpdate} type="primary" className="conversation-actions-menu-button">
                    <ArrowLeftOutlined />
                    Move to another topic
                  </GhostButton>
                  <Modal
                    title="Move Conversation to another topic"
                    onOk={handleUpdateTopic}
                    onCancel={handleCancelUpdate}
                    footer={[
                      <Button key="back" onClick={handleCancelUpdate}>
                        Cancel
                      </Button>,
                      <Button key="submit" type="primary" loading={loading} onClick={handleUpdateTopic}>
                        Save
                      </Button>,
                    ]}
                    visible={visible}
                  >
                    <Select
                      style={{ width: '100%' }}
                      mode="tags"
                      size="large"
                      placeholder="Topic"
                      value={moveTopics}
                      onChange={(value) => setMoveTopics(value)}
                      loading={loading}
                    >
                      {loadedTopics.map((topic) => (
                        <Option
                          key={topic.id}
                          value={topic.id}
                        >
                          {topic.name}
                        </Option>
                      ))}
                    </Select>
                  </Modal>
                </Menu.Item>
                <Menu.Item>
                  <GhostButton onClick={handleDeleteConversation} type="primary" className="conversation-actions-menu-button">
                    <InboxOutlined />
                    Delete Conversation
                  </GhostButton>
                </Menu.Item>
              </Menu>
            )}
            trigger={['click']}
          >
            <EllipsisOutlined className="conversation-actions-ellipsis" />
          </Dropdown>
        )}
      </div>
    </div>
  );
};

export default ConversationActions;
