import React, {
  useEffect, useReducer, useMemo, useRef,
} from 'react';
import { message } from 'antd';
import { conversations as conversationsApi } from '@/api';

import useURLState from '@/hooks/useUrlState';
import { useConversationStore } from '@/store/zustand/useConversationsStore';
import {
  conversationsReducer, initialState, SET_CONVERSATIONS, SET_LOADING,
  SET_ACTION_LOADING, SET_TEXT_COLLAPSE, UPDATE_TOPIC, UPDATE_SUBTOPIC,
  DELETE_CONVERSATION,
  UPDATE_LABEL,
} from './conversationsReducer';

export const ConversationsContext = React.createContext();

const ConversationsProvider = ({
  perPage, filters, children, disableUrlQueryState,
}) => {
  const { urlQueryState, deleteUrlQueryParam } = useURLState();
  const isMounted = useRef(false);
  const [state, dispatch] = useReducer(conversationsReducer, initialState);
  const fetchRef = useRef(0);

  const { setConversations } = useConversationStore();

  const getConversations = async (page) => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;

    dispatch({ type: SET_LOADING, payload: true });

    try {
      const { cache, ...filtersToApply } = filters;
      const response = await conversationsApi.getConversations(filtersToApply, perPage, page);
      if (fetchId === fetchRef.current) {
        setConversations(response.data);
        dispatch({
          type: SET_CONVERSATIONS,
          payload: {
            conversations: response.data,
            pagination: {
              current: response.pager.current_page,
              pageSize: response.pager.per_page,
              total: response.pager.total,
            },
          },
        });
      }
    } catch (err) {
      if (fetchId === fetchRef.current) {
        setConversations([]);
        dispatch({
          type: SET_CONVERSATIONS,
          payload: {
            conversations: [],
            pagination: {
              current: 1,
              pageSize: perPage,
              total: 0,
            },
          },
        });
      }
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      if (!disableUrlQueryState) {
        if (urlQueryState.page) {
          deleteUrlQueryParam('page');
        } else {
          getConversations(1);
        }
      }
    } else {
      isMounted.current = true;
    }
  }, [filters, perPage]);

  useEffect(() => {
    if (disableUrlQueryState) {
      getConversations(1);
    } else {
      getConversations(urlQueryState.page ? urlQueryState.page : 1);
    }
  }, []);

  const updateTopic = async (conversationId, topicIds, forTopicId) => {
    try {
      dispatch({ type: SET_ACTION_LOADING, payload: true });
      const response = await conversationsApi.updateTopic(conversationId, topicIds);
      dispatch({
        type: UPDATE_TOPIC,
        payload: { conversationId, conversation: response.data.data, forTopicId },
      });
      message.success('Conversation updated successfully');

      return true;
    } catch (err) {
      dispatch({ type: SET_ACTION_LOADING, payload: false });
      message.error('Failed updating conversation');
    }

    return false;
  };

  const updateSubtopic = async (conversationId, subtopicIds, forSubtopicId) => {
    try {
      dispatch({ type: SET_ACTION_LOADING, payload: true });
      const response = await conversationsApi.updateSubtopic(conversationId, subtopicIds);
      dispatch({
        type: UPDATE_SUBTOPIC,
        payload: { conversationId, conversation: response.data.data, forSubtopicId },
      });
      message.success('Conversation updated successfully');

      return true;
    } catch (err) {
      dispatch({ type: SET_ACTION_LOADING, payload: false });
      message.error('Failed updating conversation');
    }

    return false;
  };

  const assignLabel = async (conversationId, label, action) => {
    try {
      dispatch({ type: SET_ACTION_LOADING, payload: true });
      await conversationsApi.assignLabel(conversationId, label.id, action);
      dispatch({
        type: UPDATE_LABEL,
        payload: { conversationId, label, action },
      });
      message.success(`Label ${action === 'assign' ? 'assigned' : 'removed'} successfully`);
    } catch (e) {
      dispatch({ type: SET_ACTION_LOADING, payload: false });
      message.error('Failed assigning label to conversation');
    }
  };

  const deleteConversation = async (id) => {
    dispatch({ type: SET_ACTION_LOADING, payload: true });
    try {
      await conversationsApi.deleteConversation(id);
      dispatch({
        type: DELETE_CONVERSATION,
        payload: { conversationId: id },
      });
      message.success('Conversation deleted successfully');
    } catch (err) {
      dispatch({ type: SET_ACTION_LOADING, payload: false });
      message.error('Failed deleting conversation');
    }
  };

  const changeTextCollapse = (id) => {
    dispatch({ type: SET_TEXT_COLLAPSE, payload: id });
  };

  const contextValue = useMemo(() => ({
    state: { ...state, perPage, filters },
    actions: {
      getConversations,
      updateTopic,
      updateSubtopic,
      deleteConversation,
      changeTextCollapse,
      assignLabel,
    },
  }),
  [state, perPage, filters, dispatch, getConversations,
    updateTopic, updateSubtopic, deleteConversation, changeTextCollapse]);

  return (
    <ConversationsContext.Provider
      value={contextValue}
    >
      {children}
    </ConversationsContext.Provider>
  );
};

export default ConversationsProvider;
