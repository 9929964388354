import React from 'react';
import { cn } from '@/helpers/util';
import { Button, Input } from 'antd';
import { ShieldCheck } from 'lucide-react';
import { FaIntercom } from 'react-icons/fa';
import { SiZendesk } from 'react-icons/si';
import posthog from 'posthog-js';
import { useDispatch, useSelector } from 'react-redux';
import { platforms } from '@/api';
import Gorgias from '@/base-components/integrations/svgs/Gorgias';
import { ReactComponent as BadgeCheck } from './svg/badge-check-gradient.svg';
import Card from '../card';
import { showIntercomPopup } from '../new-onboarding/integrations/IntercomContainer';
import styles from '../new-onboarding/integrations/ZendeskContainer.module.less';
import { showZendeskPopup } from '../new-onboarding/integrations/ZendeskContainer';
import { showGorgiasPopup } from '../new-onboarding/integrations/GorgiasContainer';

const features = ['Main issues', 'Top questions asked', 'Most requested features'];

function SourceContainer({ type = 'intercom', className }) {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [domain, setDomain] = React.useState('');
  const [showZendeskInput, setShowZendeskInput] = React.useState(false);
  const [showGorgiasInput, setShowGorgiasInput] = React.useState(false);

  function activateIntercom() {
    posthog.capture('connect-popup-activate-intercom');
    showIntercomPopup(user.token, async () => {
      await platforms.activateIntercom();
      dispatch({ type: 'CONNECT_INTERCOM' });
    });
  }

  function activateZendesk() {
    setShowZendeskInput(true);
  }

  function activateGorgias() {
    setShowGorgiasInput(true);
  }

  function onZendeskClick() {
    posthog.capture('connect-popup-activate-zendesk');
    showZendeskPopup(user.token, domain, async () => {
      await platforms.activateZendesk();
      dispatch({ type: 'CONNECT_ZENDESK' });
    });
  }

  function onGorgiasClick() {
    posthog.capture('connect-popup-activate-gorgias');
    showGorgiasPopup(user.token, domain, async () => {
      await platforms.activateGorgias();
      dispatch({ type: 'CONNECT_GORGIAS' });
    });
  }

  const title = {
    intercom: 'Connect Intercom and get the most out of ClientZen today',
    zendesk: 'Connect Zendesk and get the most out of ClientZen today',
    gorgias: 'Connect Gorgias and get the most out of ClientZen today',
  }[type];

  const button = {
    intercom: 'Connect Intercom',
    zendesk: 'Connect Zendesk',
    gorgias: 'Connect Gorgias',
  }[type];

  const Icon = {
    intercom: FaIntercom,
    zendesk: SiZendesk,
    gorgias: <Gorgias />,
  }[type];

  const action = {
    intercom: activateIntercom,
    zendesk: activateZendesk,
    gorgias: activateGorgias,
  }[type];

  return (
    <section className={cn('bg-gradient-to-l rounded-lg from-[#36B2E9] to-[#1FE04B] p-0.5', className)}>
      <Card className="text-[#44596C] flex justify-between items-center">
        <div>
          <h1 className="text-[#44596C] m-0 font-[Gordita-Medium] text-lg">{title}</h1>
          <ul className="!p-0 list-none flex gap-6 mt-3 mb-2">
            {features.map((feature) => (
              <li className="flex items-center gap-1.5">
                <BadgeCheck key={feature} className="text-[#44596C]" />
                <span>{feature}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className={cn('space-y-1.5 min-w-[350px] flex flex-col items-center', showZendeskInput && 'min-w-[400px]')}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {showZendeskInput ? (
            <div className="relative flex w-full">
              <Input
                className={styles.czInput}
                size="large"
                placeholder="Your Zendesk support domain"
                value={domain}
                autoComplete="off"
                onChange={(e) => setDomain(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && onZendeskClick()}
              />
              <Button
                disabled={!domain}
                onClick={onZendeskClick}
                size="large"
                className={cn('!absolute right-0', styles.czButton)}
              >
                Continue
              </Button>
            </div>
          ) : (showGorgiasInput ? (
            <div className="relative flex w-full">
              <Input
                className={styles.czInput}
                size="large"
                placeholder="Your Gorgias support domain"
                value={domain}
                autoComplete="off"
                onChange={(e) => setDomain(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && onGorgiasClick()}
              />
              <Button
                disabled={!domain}
                onClick={onGorgiasClick}
                size="large"
                className={cn('!absolute right-0', styles.czButton)}
              >
                Continue
              </Button>
            </div>
          ) : (
            <>
              <Button onClick={action} type="primary" size="large" className={cn('w-full !border-0 flex items-center justify-center', type === 'intercom' ? '!bg-[#286EFA]' : '!bg-[#18494C]')}>
                <Icon size={24} className="absolute left-2.5" />
                {button}
              </Button>
              <p className="m-0 flex items-center gap-1.5 text-[#44596C]">
                <ShieldCheck size={20} color="#44596C" />
                {' '}
                Data encryption at rest and transit
              </p>
            </>
          )
          )}
        </div>
      </Card>
    </section>
  );
}

export function ConnectPopup({ className }) {
  const user = useSelector((state) => state.user);

  const isZendeskEnabled = !user.has_zendesk_platform && posthog.isFeatureEnabled('connect-popup-zendesk');
  const isGorgiasEnabled = !user.has_gorgias_platform && posthog.isFeatureEnabled('connect-popup-gorgias');
  const isIntercomEnabled = !user.has_intercom_platform && posthog.isFeatureEnabled('connect-popup-intercom');
  if (!isZendeskEnabled && !isIntercomEnabled && !isGorgiasEnabled) {
    return null;
  }

  return (
    <>
      {isZendeskEnabled && <SourceContainer type="zendesk" className={className} />}
      {isGorgiasEnabled && <SourceContainer type="gorgias" className={className} />}
      {isIntercomEnabled && <SourceContainer type="intercom" className={className} />}
    </>
  );
}
