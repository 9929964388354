import React, { useEffect, useState } from 'react';
import {
  EditOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  Button, Empty, Input, Spin, message,
} from 'antd';
import { platforms as platformsApi } from '@/api';
import { displayOrCatchMessage } from '@/helpers';
import { cn } from '@/helpers/util';
import { debounce } from 'lodash';
import useIntegrationsStore from '../store/useIntegrationsStore';
import DebounceSelect from './DebounceSelect';

const fetchForms = () => platformsApi.getTypeformForms().then(({ data }) => data).catch(() => []);

const fetchAvailableForms = (search) => platformsApi.getTypeformAvailableForms(search)
  .then(({ data }) => data.map((d) => ({
    label: d.title,
    value: d.id,
  }))).catch(() => []);

const EditFormItem = ({
  title, form_id: formId, onCancel, onSave,
}) => {
  const [value, setValue] = useState(title);
  return (
    (
      <div className="flex justify-between min-w-full space-x-2">
        <div className="flex-1">
          <Input
            onChange={debounce((e) => {
              setValue(e.target.value);
            }, 150)}
            defaultValue={value}
            placeholder="Form name"
          />
        </div>
        <div className="space-x-2">
          <Button onClick={onCancel}>Cancel</Button>
          <Button onClick={() => onSave(formId, { name: value })} type="primary">Save</Button>
        </div>
      </div>
    )
  );
};

const FormItem = ({ form, setReload }) => {
  const [edit, setEdit] = useState(false);

  const editLabel = () => {
    setEdit(true);
  };

  const handleSave = async (id, payload) => {
    try {
      await platformsApi.updateTypeformName(id, payload);
      setReload((prev) => !prev);
      message.success('The form name has been updated successfully', 1.5);
      setEdit(false);
    } catch (e) {
      displayOrCatchMessage(e);
    }
  };
  return (
    <li className={cn('text-[#595959] border-0 border-b border-[#E8E8E8] border-solid last:border-none flex justify-between font-[Gordita-Medium]', !edit ? 'px-3.5 py-2.5' : 'p-2.5 bg-[#F6F9FB]')}>
      {edit ? (
        <EditFormItem {...form} onCancel={() => setEdit(false)} onSave={handleSave} />
      ) : (
        <>
          <div className="flex items-center space-x-3">
            <span>{form.title ?? form.form_id}</span>
          </div>
          <div>
            <Button onClick={editLabel} type="text">
              <EditOutlined />
            </Button>
          </div>
        </>
      )}

    </li>
  );
};

const ConnectedForms = () => {
  const [loading, setLoading] = useState(false);
  const [forms, setForms] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const fetchTypeformForms = async () => {
      setLoading(true);
      const data = await fetchForms();
      setForms(data);
      setLoading(false);
    };

    fetchTypeformForms();
  }, []);

  useEffect(() => {
    const fetchTypeformForms = async () => {
      const data = await fetchForms();
      setForms(data);
    };

    fetchTypeformForms();
  }, [reload]);

  if (loading) {
    return (
      <div className="mt-9">
        <p className="font-[Gordita-Medium] font-mediun text-base">Your connected forms</p>
        <div className="mt-3 p-14 rounded-md border border-solid border-[#E8E8E8] flex justify-center max-w-md">
          <Spin />
        </div>
      </div>
    );
  }

  return (
    <div className="mt-9">
      <p className="font-[Gordita-Medium] font-mediun text-base">Your connected forms</p>
      {forms.length > 0 ? (
        <ul className="mt-3 list-none p-0 border rounded-md border-solid border-[#E8E8E8] max-w-md">
          {forms.map((form) => (
            <FormItem key={form.form_id} form={form} setReload={setReload} />
          ))}
        </ul>

      ) : (
        <div className="mt-2 p-2 rounded-md border border-solid border-[#E8E8E8] max-w-md">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No forms had been connected" />
        </div>
      )}
    </div>
  );
};

const TypeformConnect = () => {
  const {
    actions: {
      updateConnectedPlatforms,
    },
  } = useIntegrationsStore();
  const [value, setValue] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const onConnect = () => {
    setLoading(true);
    const data = value.map((item) => item.split('-'));
    const ids = data.map((d) => d[0]);
    const names = data.map((d) => d[1]);

    platformsApi.setTypeformForms(ids, names).then(() => {
      updateConnectedPlatforms();
    }).catch(() => {
      setLoading(false);
    });
  };

  return (
    <div className="typeform_connect">
      <div className="typeform_connect__select space-y-3">
        <p className="font-[Gordita-Medium] font-mediun text-base">Connect forms</p>

        <DebounceSelect
          mode="multiple"
          size="large"
          value={value}
          placeholder="Select forms"
          fetchOptions={fetchAvailableForms}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          style={{
            width: '100%',
          }}
          suffixIcon={<SearchOutlined />}
          showArrow
          itemRenderer={(item) => (
            <div className="typeform_connect__option">
              <div className="typeform_connect__option__label">{item.label}</div>
              <div className="typeform_connect__option__value">{item.value}</div>
            </div>
          )}
        />
        <Button
          onClick={onConnect}
          size="large"
          type="primary"
          loading={loading}
          disabled={!value.length}
        >
          Submit forms
        </Button>
      </div>
      <ConnectedForms />
    </div>
  );
};

export default TypeformConnect;
