import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Button, Card } from 'antd';
import { UserAddOutlined } from '@ant-design/icons';
import addFirstColleagueAction from '../../../../store/actions/addFirstColleague';
import addTeamMemberAction from '../../../../store/actions/addTeamMember';
import addFirstClientAction from '../../../../store/actions/addFirstClient';
import recentInteractionAddedAction from '../../../../store/actions/recentInteractionAdded';
import Avatar from '../../../clients/Avatar';

const getAddButtonText = ({ onDomain, isColleague }) => {
  if (isColleague) {
    return 'Add Team Member';
  }

  if (onDomain) {
    return 'Invite Team Member';
  }

  return 'Add Client';
};

const Element = ({
  name,
  avatar,
  email,
  company,
  recent,
  addPerson,
  recentInteractionAdded,
  isColleague,
  onDomain,
}) => {
  const [added, setAdded] = useState(false);
  const onAddClick = useCallback(() => {
    addPerson({
      name,
      avatar,
      email,
      company,
      recent,
    });
    setAdded(true);
    setTimeout(() => {
      recentInteractionAdded(email);
    }, 300);
  }, [name, avatar, email, company, recent]);

  return (
    <div
      className={classNames('fancy-grid-element', {
        'fancy-grid-element--added': added,
      })}
    >
      <div className="fancy-grid-element--content">
        <Card>
          <Avatar size={46} client={{ avatar, name }} />
          <div className="fancy-grid-element--name">{name}</div>
          <div className="fancy-grid-element--email">{email}</div>
          <div className="fancy-grid-element--company">
            works at
            {company}
          </div>
          <Button type="primary" onClick={onAddClick}>
            <UserAddOutlined />
            {getAddButtonText({ onDomain, isColleague })}
          </Button>
        </Card>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch, { isColleague, onDomain }) => ({
  addPerson: (data) => {
    if (isColleague) {
      dispatch(addFirstColleagueAction(data));
    } else if (onDomain) {
      dispatch(addTeamMemberAction(data));
    } else {
      dispatch(addFirstClientAction(data));
    }
  },
  recentInteractionAdded: (email) => dispatch(recentInteractionAddedAction(email)),
});

export default connect(null, mapDispatchToProps)(Element);
