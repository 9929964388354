import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import * as Sentry from '@sentry/react';
import config from './config';
import { inProduction } from './flags';

export default {
  init: (mixpanel) => {
    if (inProduction()) {
      LogRocket.init(config.logrocket.appID);
      setupLogRocketReact(LogRocket);
      LogRocket.getSessionURL((sessionURL) => {
        mixpanel.track('LogRocket', { sessionURL });
      });
      LogRocket.getSessionURL((sessionURL) => {
        Sentry.configureScope((scope) => {
          scope.setExtra('sessionURL', sessionURL);
        });
      });
    }
  },

  identify: (id, props = {}) => {
    if (inProduction()) {
      LogRocket.identify(id, props);
    }
  },

  decorateRedux: (...middlewares) => (inProduction() ? [
    ...middlewares,
    LogRocket.reduxMiddleware(),
  ] : middlewares),
};
