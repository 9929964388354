import { create } from 'zustand';
import { defaultDates } from '@/components/ui/sentiment-date-filter/lib/utils';

import { devtools, persist } from 'zustand/middleware';

export const useDefaultDateStore = create(devtools(persist((set) => ({
  defaultDate: defaultDates[2],
  setDefaultDate: (defaultDate) => set({ defaultDate }),
  reset: () => set({ defaultDate: defaultDates[2] }),
}), {
  name: 'date',
}), {
  name: 'useDateFilter',
}));
