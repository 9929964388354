export default (state = {
  loading: true,
}, action) => {
  switch (action.type) {
    case 'USER_SUBSCRIBE_STATUS_LOADING':
      return {
        ...state,
        loading: true,
      };
    case 'USER_SUBSCRIBE_STATUS_CHANGED':
      return {
        ...state,
        loading: false,
        subscribed: action.subscribed,
        onGracePeriod: action.onGracePeriod,
        plan: action.plan,
      };
    default:
      return state;
  }
};
