import React, { useCallback, useEffect, useState } from 'react';
import { Button, Select, Spin } from 'antd';
import { labels, settings } from '@/api';
import { displayOrCatchMessage } from '@/helpers';
import './LabelsExclusionTab.less';

const LabelsExclusionTab = () => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [defaultValue, setDefaultValue] = useState([]);
  const [value, setValue] = useState([]);

  const onSubmit = useCallback(() => {
    setSubmitting(true);
    settings.replaceExcludedLabels({
      labels: value,
    }).then(() => {
      setSubmitting(false);
    }).catch((e) => {
      setSubmitting(false);
      displayOrCatchMessage(e);
    });
  }, [value]);

  useEffect(() => {
    labels.getAll().then(({ data }) => {
      setOptions(data.data.map((label) => ({
        label: label.name,
        value: label.id,
      })));

      settings.getExcludedLabels().then(({ data: excluded }) => {
        const initial = excluded.map(({ label_id: id }) => id);
        setValue(initial);
        setDefaultValue(initial);
        setLoading(false);
      }).catch((e) => displayOrCatchMessage(e));
    }).catch((e) => displayOrCatchMessage(e));
  }, []);

  return (
    <div>
      {loading && (
        <div style={{ textAlign: 'center', margin: 30 }}>
          <Spin size="large" />
        </div>
      )}
      {!loading && (
      <>
        <h2>Exclude labels from insights</h2>
        <p>
          Select what labels you want to filter out
        </p>

        <div className="mt-8">
          <Select
            mode="multiple"
            className="large_select"
            placeholder="Labels"
            allowClear
            style={{
              width: '100%',
            }}
            options={options}
            defaultValue={defaultValue}
            onChange={setValue}
          />
        </div>
        <div className="exclude-container mt-3">
          <Button
            loading={submitting}
            onClick={onSubmit}
            size="medium"
            className="cta exclude-btn"
            type="primary"
          >
            Exclude
          </Button>
        </div>
      </>
      )}
    </div>
  );
};

export default LabelsExclusionTab;
