import React from 'react';

const SectionHeader = ({ icon, title, children }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {icon}
    <span className="header-title">{title}</span>
    {children}
  </div>
);

export default SectionHeader;
