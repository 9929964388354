import React from 'react';
import BillingPlanCheckmark from './BillingPlanCheckmark';
import BillingPlan from './BillingPlan';

const StarterBillingPlan = ({ onClick, isAnnuallySubscriptionSelected, ...props }) => (
  <BillingPlan
    onClick={onClick}
    title="Essential"
    titleColor="#8E95A1"
    price={isAnnuallySubscriptionSelected ? 100 : 120}
    buttonText="Get plan"
    benefitsContainerStyle={{ backgroundColor: '#FAFAFA' }}
    isAnnuallySubscriptionSelected={isAnnuallySubscriptionSelected}
    {...props}
  >
    <BillingPlanCheckmark text="Automated tagging" />
    <BillingPlanCheckmark text="Painpoints discovery by Mantra AI" />
    <BillingPlanCheckmark text="Advanced sentiment analysis" />
    <BillingPlanCheckmark text="3 data sources" />
    <BillingPlanCheckmark text="2000 conversations / month" />
    <BillingPlanCheckmark text="3 team members" />
    <BillingPlanCheckmark text="Advanced segmentation" missingBenefit />
    <BillingPlanCheckmark text="Customer insights" missingBenefit />
    <BillingPlanCheckmark text="Dedicated account manager" missingBenefit />
    <BillingPlanCheckmark text="Custom integrations" missingBenefit />
  </BillingPlan>
);

export default StarterBillingPlan;
