import React, {
  useState, useRef, useEffect, useMemo,
} from 'react';
import {
  Form as AntForm, Input, Select,
} from 'antd';
import debounce from 'lodash/debounce';
import MatchTooltip from './MatchTooltip';
import PopoutForm from '../../../components/forms/PopoutForm';
import TagSelect from './TagSelect';
import SelectCustomDropdown from './SelectCustomDropdown';
import { keywordsType } from './options';

const { Item } = AntForm;

const Form = ({
  form, defaultValues, groups, error, onSubmit, onChange,
}) => {
  const loadPreview = useMemo(
    () => debounce(onChange, 300), [],
  );

  const includedKeywordsRef = useRef(null);
  const [includedKeywordHighlight, setIncludedKeywordHighlight] = useState('');
  const [includedKeywordSuffix, setIncludedKeywordSuffix] = useState('');
  const [excludedKeywordSuffix, setExcludedKeywordSuffix] = useState('');

  const onHighlightedChange = (set) => (highlighted) => {
    set(highlighted);
  };

  const handlePreview = (highlighted) => (value) => {
    const fieldValues = form.getFieldsValue();
    if (highlighted) {
      loadPreview({
        ...fieldValues,
        included_keywords: [highlighted],
        excluded_keywords: [],
      });
    } else {
      loadPreview(fieldValues);

      if (value) {
        if (includedKeywordHighlight) {
          includedKeywordsRef.current.removeHighlight();
        }
      }
    }
  };

  useEffect(() => {
    if (includedKeywordHighlight) {
      handlePreview(includedKeywordHighlight)();
    } else {
      handlePreview('')();
    }
  }, [includedKeywordHighlight]);

  return (
    <PopoutForm
      layout="vertical"
      form={form}
      popoutError={error}
      onFinish={onSubmit}
      initialValues={defaultValues}
    >
      <Item
        key="name"
        name="name"
        label="Segment name"
        rules={[{ required: true, message: 'Segment name is required' }]}
      >
        <Input
          size="middle"
          placeholder="e.g. Search Bar"
        />
      </Item>
      <Item
        key="groups"
        name="groups"
        rules={[{ required: true, message: 'Segment group is required' }]}
      >
        <SelectCustomDropdown
          rounded
          valueProp="name"
          textProp="name"
          placeholder="Select segment group"
          options={groups.map(({ name, id }) => ({ name, id }))}
        />
      </Item>
      <Item
        key="included_keywords"
        name="included_keywords"
        label="Keywords to include"
        rules={[{ required: true, message: 'Keywords to include is required' }]}
      >
        <TagSelect
          listHeight={120}
          tokenSeparators={['\n', ';']}
          placeholder="try 'error' or 'how can I'"
          onChange={handlePreview('')}
          onHighlightedChange={
            onHighlightedChange(setIncludedKeywordHighlight)
          }
          ref={includedKeywordsRef}
          hasHighlight
          extendedLayout={(
            <div className="extended-item-inner">
              <Select
                value={includedKeywordSuffix}
                onChange={setIncludedKeywordSuffix}
                options={keywordsType}
              />
              <MatchTooltip />
            </div>
          )}
          suffix={includedKeywordSuffix}
          suffixOptions={keywordsType}
        />
      </Item>
      <Item
        key="excluded_keywords"
        name="excluded_keywords"
        label="Keywords to exclude"
        style={{ marginBottom: 0 }}
      >
        <TagSelect
          listHeight={120}
          tokenSeparators={['\n', ';']}
          onChange={handlePreview('')}
          hasHighlight
          hasReadOnlyHighlight
          extendedLayout={(
            <div className="extended-item-inner">
              <Select
                value={excludedKeywordSuffix}
                onChange={setExcludedKeywordSuffix}
                options={keywordsType}
              />
              <MatchTooltip />
            </div>
          )}
          suffix={excludedKeywordSuffix}
          suffixOptions={keywordsType}
        />
      </Item>
    </PopoutForm>
  );
};

export default Form;
