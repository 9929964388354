import { buildQuery } from '@/helpers/filters';
import axios from './axios';
import auth from './auth';

const getAll = (filters = {}, params = {}) => axios.get(buildQuery('/api/labels', filters, {
  team_id: auth.team().id,
  ...params,
}));

const getLabel = (id) => axios.get(`/api/labels/${id}`);

const createLabel = (values) => axios.post('/api/labels', values, {
  params: {
    team_id: auth.team().id,
  },
});

const updateLabel = (id, values) => axios.put(`/api/labels/${id}`, values, {
  params: {
    team_id: auth.team().id,
  },
});

const deleteLabel = (id) => axios.delete(`/api/labels/${id}`);

const labels = {
  getAll,
  getLabel,
  createLabel,
  updateLabel,
  deleteLabel,
};

export default labels;
