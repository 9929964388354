import React from 'react';
import {
  PieChart, Pie, ResponsiveContainer, Cell,
} from 'recharts';
import {
  ArrowUp, ArrowDown,
} from 'lucide-react';
import { getLabel } from '@/components/bucket/ScoreChangeMenu';

const SentimentGraph = ({
  style, data, ratio, score, type, showTrend = true,
}) => (
  <div style={{
    ...style,
    width: 160,
    height: 160,
    position: 'relative',
  }}
  >
    <div className="flex flex-col text-sm font-[Gordita-Regular] text-[#44596C] absolute top-0 left-0 z-1 w-full h-full items-center justify-center gap-[4px] mt-2">
      <span className="flex items-center justify-center gap-[2px]">
        <span className="font-[Gordita-Medium] text-[32px] leading-[32px] text-[#202324]">{score.toFixed(1)}</span>
        {showTrend && (
        <span className="mt-1" style={{ color: ratio?.trend === 'ascending' ? '#5FC59A' : '#F17E99' }}>
          {
            ratio?.trend === 'ascending' ? <ArrowUp size={20} strokeWidth={2.5} /> : <ArrowDown size={16} strokeWidth={2.5} />
          }
        </span>
        )}
      </span>
      <span>{getLabel(type)}</span>
    </div>
    <ResponsiveContainer>
      <PieChart>
        <Pie dataKey="value" data={data} innerRadius={70} outerRadius={80}>
          {data.map((entry) => (
            <Cell key={entry.graphColor} fill={entry.graphColor} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  </div>
);

export default SentimentGraph;
