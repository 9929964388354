import { buildQuery } from '@/helpers/filters';
import axios from './axios';
import auth from './auth';

const index = (filters, page = 1) => axios.get(buildQuery('/api/colleague-insights', filters, page ? {
  team_id: auth.team().id,
  page,
} : { team_id: auth.team().id })).then((response) => response).catch((e) => Promise.reject(e));

const show = (id, cancelToken) => axios.get(`/api/colleague-insights/${id}`, {
  params: {
    team_id: auth.team().id,
  },
  cancelToken,
}).then((response) => response).catch((e) => Promise.reject(e));

const destroy = (id) => axios.delete(`/api/colleague-insights/${id}`, {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response).catch((e) => Promise.reject(e));

const done = (id) => axios.delete(`/api/colleague-insights/as-done/${id}`, {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response).catch((e) => Promise.reject(e));

const mark = (feedback, id) => axios.put(`/api/colleague-insights/as-${feedback}/${id}`, {
  team_id: auth.team().id,
}).then((response) => response).catch((e) => Promise.reject(e));

const markPart = (feedback, insightId, id) => axios.put(`/api/colleague-insights/${insightId}/part/${id}/as-${feedback}`, {
  team_id: auth.team().id,
}).then((response) => response).catch((e) => Promise.reject(e));
const undo = (id) => axios.put(`/api/colleague-insights/undo/${id}`, {
  team_id: auth.team().id,
});
const partUndo = (insightId, id) => axios.put(`/api/colleague-insights/${insightId}/part/${id}/undo`, {
  team_id: auth.team().id,
});

const suggest = (values) => axios.post('/api/colleague-insights/suggest', values, {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response).catch((e) => Promise.reject(e));

const colleagueInsights = {
  index,
  show,
  destroy,
  done,
  mark,
  markPart,
  undo,
  partUndo,
  suggest,
};

export default colleagueInsights;
