import React, {
  useReducer, useMemo, useEffect, useCallback,
} from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  Input, Spin,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import { SentimentRadioGroupDateFilter, useSentimentDateFilterInitialValue } from '@/base-components/sentiment-date-filter';
import { GradientSentimentChart } from '@/base-components/sentiment-chart';
import ConversationsTable from '@/base-components/conversations-table';
import TopicMetric from '@/base-components/topic-metric';
import { clients } from '@/api';
import { isValidParam } from '../../helpers/validators';
import {
  initialState,
  peopleDashboardReducer,
  SET_SEARCH_FILTER_ACTION,
  SET_RANGE_FILTER_ACTION,
  SET_DATA_ACTION,
  SET_DAYS_FILTER_ACTION,
} from './peopleDashboardReducer';
import Affix from '../Affix';
import { calculateDifference } from '../Painpoints/helpers';

const PeopleDashboardPage = () => {
  const { id } = useParams();

  const history = useHistory();

  const defaultValue = useSentimentDateFilterInitialValue();

  const [{
    data, loading, filters, reload,
  }, dispatch] = useReducer(peopleDashboardReducer, initialState,
    (initState) => ({
      ...initState,
      filters: {
        client_id: id,
        search: '',
        start: defaultValue.start,
        end: defaultValue.end,
        days: [],
      },
    }));

  useEffect(() => {
    if (!isValidParam(id)) {
      history.push('/404');
    }

    clients.show(id)
      .then(({ data: response }) => {
        dispatch(
          {
            type: SET_DATA_ACTION,
            payload: response.data,
          },
        );
      })
      .catch(() => {
        history.push('/404');
      });
  }, [id, reload.page]);

  const chartFilters = useMemo(() => {
    // eslint-disable-next-line camelcase
    const { client_id, start, end } = filters;

    return { client_id, start, end };
  }, [filters.client_id, filters.start, filters.end, reload.page, reload.data]);

  const searchProps = useMemo(() => ({
    className: 'search',
    placeholder: 'Keywords',
    prefix: <SearchOutlined />,
    onChange: debounce((e) => {
      dispatch({ type: SET_SEARCH_FILTER_ACTION, payload: e.target.value });
    }, 400),
  }), []);

  const conversationsTableProps = useMemo(() => ({
    filters: {
      client_id: filters.client_id,
      search: filters.search,
      start: filters.start,
      end: filters.end,
      days: filters.days,
    },
    hasPagination: true,
    perPage: 25,
  }), [
    filters.client_id,
    filters.start, filters.end,
    filters.search,
    reload.page,
    filters.days,
  ]);

  const onChartSelectedDaysChange = useCallback((days) => dispatch({
    type: SET_DAYS_FILTER_ACTION,
    payload: { days: days.map((entry) => entry.point) },
  }), []);

  if (loading) {
    return (
      <div className="topic-dashboard">
        <div className="topic-dashboard-loading">
          <Spin size="large" />
        </div>
      </div>
    );
  }

  return (
    <div className="topic-dashboard">
      <Affix subheader>
        <div className="gordita-bold-2">
          {data.name}
        </div>
        <div className="topic-dashboard-header">
          <div className="hot-topic__metrics dashboard-header-metrics">
            <div className="painpoint-dashboard-metric">
              <div className="gordita-regular-1">Feedback</div>
              <TopicMetric
                value={data.count.value}
                trend={data.count.trend}
                tooltip={`${data.count.value} conversations over the selected period`}
                difference={calculateDifference(
                  data.count.value, data.count.previous_value,
                )}
              />
            </div>
            <div className="painpoint-dashboard-metric">
              <div className="gordita-regular-1">MRR</div>
              <TopicMetric.MRR
                value={data.mrr.value}
                trend={data.mrr.trend}
                tooltip={`$${Math.round(data.mrr.value)} estimated monthly revenue by users discussing this topic`}
              />
            </div>
            {
              (data.renewal !== null) ? (
                <div className="painpoint-dashboard-metric">
                  <div className="gordita-regular-1">Renewal</div>
                  <TopicMetric.Renewal
                    value={data.renewal}
                  />
                </div>
              ) : null
            }
            <div className="painpoint-dashboard-metric">
              <div className="gordita-regular-1">User Type</div>
              <TopicMetric
                value={data.user_type}
              />
            </div>
          </div>
          <div className="topic-dashboard-header-actions">
            <SentimentRadioGroupDateFilter
              initialValue={defaultValue}
              onChange={(value) => dispatch({ type: SET_RANGE_FILTER_ACTION, payload: value })}
            />
          </div>
        </div>
      </Affix>
      <GradientSentimentChart
        filters={chartFilters}
        hasInteractions
        onChange={onChartSelectedDaysChange}
      />
      <div className="topic-dashboard-section-2">
        <div className="topic-dashboard-bottom">
          <div className="topic-dashboard-tabs">
            <p>
              {data.name}
              &apos;s Conversations
            </p>
          </div>
          <Input {...searchProps} />
        </div>
        <ConversationsTable {...conversationsTableProps} />
      </div>
    </div>
  );
};

export default PeopleDashboardPage;
