import React from 'react';
import { Dropdown, Menu } from 'antd';
import { EllipsisOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import GhostButton from '../../GhostButton';

const TopicActions = ({ onUpdate, onDelete }) => (
  <Dropdown
    placement="bottomLeft"
    trigger={['click']}
    overlay={(
      <Menu>
        <Menu.Item className="button-menu-item">
          <GhostButton type="primary" className="topic-actions__item" onClick={onUpdate}>
            <EditOutlined style={{ fontSize: '16px', minWidth: 18 }} />
            Edit Segment
          </GhostButton>
        </Menu.Item>
        <Menu.Item className="button-menu-item">
          <GhostButton type="primary" className="topic-actions__item" onClick={onDelete}>
            <DeleteOutlined style={{ fontSize: '16px', minWidth: 18 }} />
            Delete
          </GhostButton>
        </Menu.Item>
      </Menu>
    )}
  >
    <EllipsisOutlined className="topic-actions__trigger" />
  </Dropdown>
);

export default TopicActions;
