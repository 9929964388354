import React from 'react';
import { Link } from 'react-router-dom';
import mixpanel from '../mixpanel';

const onClick = (event) => () => {
  if (event) {
    mixpanel.track(event);
  }
};

const TrackedLink = ({ event, children, ...props }) => (
  <Link onClick={onClick(event)} {...props}>{children}</Link>
);

export default TrackedLink;
