import React from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import ClientAvatar from '@/components/ClientAvatar';
import { RiseOutlined } from '@ant-design/icons';

const Evolution = ({ firstPercentage, secondPercentage, date }) => (
  <Typography.Text type="secondary" className="TopMover__Evolution">
    <span className="TopMover__FirstPercentage">
      {firstPercentage}
      %
    </span>
    {' '}
    to
    {' '}
    <span className="TopMover__SecondPercentage">
      {secondPercentage}
      %
    </span>
    {' '}
    {date}
  </Typography.Text>
);

const TopMover = ({
  avatar,
  client,
  firstPercentage,
  secondPercentage,
  date,
  isColleague,
}) => {
  const evolutionDirection = firstPercentage < secondPercentage ? 'Upside' : 'Downside';

  return (
    <>
      <div
        className={`TopMover TopMover--${evolutionDirection}`}
      >
        <div className="TopMover__Cover">
          <Link to={`/${isColleague ? 'team-members' : 'clients'}/${client.id}`}>
            <ClientAvatar
              avatar={avatar}
              client={client}
            />
          </Link>
          <RiseOutlined className="TopMover__Icon" />
        </div>
        <h4 className="table-text-bold">{client.name}</h4>
        <Evolution
          firstPercentage={firstPercentage}
          secondPercentage={secondPercentage}
          date={date}
        />
      </div>
    </>
  );
};

export default TopMover;
