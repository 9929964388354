import React, { useEffect, useState } from 'react';
import {
  Button, Checkbox, Dropdown, Empty, Menu, Spin,
} from 'antd';
import {
  ApiOutlined, BuildOutlined, CloseOutlined, TagOutlined,
} from '@ant-design/icons';
import { customFields, platforms } from '@/api';
import { useLabelsOld } from '@/components/settings/LabelsTab';
import { IntegrationIcons } from '@/base-components/conversations-table/components/ConversationSource';
import { ReactComponent as IconFilter } from '../../svgs/icon-filter.svg';
import CustomFieldMenuItem from './CustomFieldMenuItem';
import { displayOrCatchMessage } from '../../../helpers';

function getPlatformIcon(platform) {
  return (
    <span role="img" className="anticon">
      <img className="w-4 h-4" src={IntegrationIcons[platform]} alt={platform} />
    </span>
  );
}

export function useSourcesField(initialState = []) {
  const [sources, setSources] = useState(initialState);

  const addSource = (id, name, dataType, value) => {
    const alreadyExisting = sources.find((item) => item.id === id
      && item.name === name && item.value === value);
    if (!alreadyExisting) {
      setSources([
        ...sources,
        {
          id, name, dataType, value,
        },
      ]);
    }
  };

  const removeSource = (id) => {
    setSources(sources.filter((field) => field.id !== id));
  };

  const removeAllSources = () => {
    setSources([]);
  };

  return {
    sources,
    addSource,
    removeSource,
    removeAllSources,
  };
}

export function SelectedSource({ source, onClear }) {
  return (
    <div className="selected-custom-field">
      <ApiOutlined />
      {source.name === 'intercom' || source.name === 'zendesk' ? (
        <>
          <span className="flex items-center">
            Source:
          </span>
          <span className="leading-none">{getPlatformIcon(source.name)}</span>

        </>
      ) : (
        <>
          <span className="flex items-center">
            Source (
            {getPlatformIcon(source.name)}
            ):
          </span>
          <span className="">{source.value}</span>
        </>
      )}

      <Button onClick={() => onClear(source.id)} type="link"><CloseOutlined /></Button>
    </div>
  );
}

function SourceItem({
  sources, source, handleClick, ...props
}) {
  const title = source.charAt(0).toUpperCase() + source.slice(1);
  const filteredSources = sources[source].filter((item) => item.conversations_count > 0);

  if (source === 'intercom' || source === 'zendesk') {
    const item = filteredSources[0];
    return (
      <Menu.Item
        {...props}
        className="flex items-center min-w-[10rem]"
        onClick={() => handleClick(item.id, source, null, item.id)}
        icon={getPlatformIcon(source)}
      >
        {title}
      </Menu.Item>
    );
  }
  return (
    <Menu.SubMenu {...props} popupOffset={[2, -12]} className="[&>div]:flex [&>div]:items-center min-w-[10rem] " icon={getPlatformIcon(source)} title={title}>
      {filteredSources.map((item) => (
        <Menu.Item className="space-x-1" onClick={() => handleClick(item.id, source, null, item.name ?? item.id)}>
          <span>{item.name ? item.name : item.id}</span>
          <span>
            (
            {item.conversations_count}
            {' '}
            rows)
          </span>
        </Menu.Item>
      ))}
    </Menu.SubMenu>
  );
}

function useCustomFields() {
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState([]);

  useEffect(() => {
    const getCustomFields = async () => {
      try {
        const { data: res } = await customFields.getCustomFields({
          added: true,
        });

        setFields(res);
        setLoading(false);
      } catch (e) {
        displayOrCatchMessage(e);
      }
    };

    getCustomFields();
  }, []);

  return {
    loading, fields,
  };
}

function useSources() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const getPlatforms = async () => {
      try {
        const { data: res } = await platforms.getTeamPlatforms();

        setData(res.data);
        setLoading(false);
      } catch (e) {
        displayOrCatchMessage(e);
      }
    };

    getPlatforms();
  }, []);

  return {
    loading, data,
  };
}

const CustomFiltersMenu = ({
  activeLabels, onSelectField, onAddLabel, onRemoveLabel, onClickSource, ...props
}) => {
  const { loading, fields } = useCustomFields();
  const labels = useLabelsOld();
  const sources = useSources();

  return (
    <Menu {...props} className="custom-fields-menu">
      <Menu.SubMenu
        popupOffset={[-2, -6]}
        className="min-w-[10rem]"
        key="sources"
        icon={<ApiOutlined />}
        title="Sources"
      >
        {
          sources.loading ? (
            <div className="text-center p-10">
              <Spin size="medium" />
            </div>
          ) : null
        }
        {
          (!loading && !Object.keys(sources.data).length) ? (
            <div style={{ paddingTop: 20, width: 150, margin: '0 auto' }}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No sources found" />
            </div>
          ) : null
        }
        {
          (!loading && Object.keys(sources.data).length)
            ? Object.keys(sources.data)
              .filter((platform) => sources.data[platform].filter((item) => item.conversations_count > 0).length > 0)
              .map((platform) => <SourceItem key={platform} sources={sources.data} source={platform} handleClick={onClickSource} />)
            : null
        }
      </Menu.SubMenu>
      <Menu.SubMenu
        popupClassName="custom-fields-submenu"
        icon={<BuildOutlined />}
        title="Custom fields"
      >
        {
          loading ? (
            <div style={{ textAlign: 'center', paddingTop: 40 }}>
              <Spin size="medium" />
            </div>
          ) : null
        }
        {
          (!loading && !fields.length) ? (
            <div style={{ paddingTop: 20, width: 150, margin: '0 auto' }}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No custom fields added to Clientzen" />
            </div>
          ) : null
        }
        {
          (!loading && fields.length) ? fields.map((item) => (
            <CustomFieldMenuItem
              key={item.key}
              id={item.key}
              dataType={item.data_type}
              name={item.name}
              onSelectValue={(value) => onSelectField(item.key, item.name, item.data_type, value)}
            />
          )) : null
        }
      </Menu.SubMenu>
      {
        activeLabels ? (
          <Menu.SubMenu
            key="labels"
            icon={<TagOutlined />}
            title="Labels"
          >
            {
              labels.loading ? (
                <div className="text-center p-10">
                  <Spin size="medium" />
                </div>
              ) : null
            }
            {
              (!labels.loading && !labels.data.length) ? (
                <div className="mx-auto w-56 p-5">
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No labels added to Clientzen" />
                </div>
              ) : null
            }
            {(!labels.loading && labels.data.length) ? labels.data.map((label) => (
              <Menu.Item key={label.name}>
                <Checkbox
                  checked={activeLabels.find((item) => item.id === label.id)}
                  onChange={(e) => {
                    const { checked } = e.target;
                    if (checked) onAddLabel(label);
                    else onRemoveLabel(label.id);
                  }}
                  className="flex items-center gap-2 [&>span]:flex [&>span]:items-center [&>span]:gap-2"
                >
                  <div className="rounded-full w-2 h-2 " style={{ backgroundColor: label.color }} />
                  <span className="ml-0.5">{label.name}</span>
                </Checkbox>
              </Menu.Item>
            )) : null}
          </Menu.SubMenu>
        ) : null
      }
    </Menu>
  );
};

const CustomFiltersButton = ({
  activeLabels, onSelectField, onAddLabel, onRemoveLabel, onClickSource,
}) => (
  <Dropdown
    placement="bottomRight"
    trigger={['click']}
    overlay={<CustomFiltersMenu activeLabels={activeLabels} onSelectField={onSelectField} onAddLabel={onAddLabel} onRemoveLabel={onRemoveLabel} onClickSource={onClickSource} />}
  >
    <Button>
      <span className="anticon"><IconFilter /></span>
    </Button>
  </Dropdown>
);

export default CustomFiltersButton;
