import {
  Button, Modal, Steps, message,
} from 'antd';
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { WarningOutlined } from '@ant-design/icons';
import CsvMapper from '@/base-components/integrations/components/csv/CsvMapper';
import CsvPreview from '@/base-components/integrations/components/csv/CsvPreview';
import { OnboardingContext, PlatformContext } from '../../context';

export function CSVModal() {
  const { setStep: setOnboardingStep } = useContext(OnboardingContext);
  const {
    modal, setModal, file, setFile, mappings, setMappings,
  } = useContext(PlatformContext);
  const [step, setStep] = useState(0);

  useEffect(() => {
    setStep(0);
    setMappings(null);
  }, [modal]);

  const closeModal = useCallback(() => {
    Modal.confirm({
      title: 'Cancel import',
      content: (
        <p>Are you sure you want to exit? Any unsaved changes will be lost.</p>
      ),
      cancelText: 'No',
      okText: 'Yes',
      okButtonProps: { danger: true },
      icon: <WarningOutlined style={{ color: 'rgb(255, 77, 79)' }} />,
      onOk: () => {
        setMappings(null);
        setFile(null);
        setModal(false);
      },
      onCancel: () => {
        // do nothing
      },
    });
  }, [setModal]);

  const goToNextStep = useCallback(() => {
    setStep((s) => {
      const next = s + 1;

      if (next === 1 && !Object.keys(mappings).find((col) => mappings[col] === 'conversation')) {
        message.error({
          content: 'The conversation column is required to be assigned',
          key: 'conversation_not_assigned',
        });
        return s;
      }

      if (next === 2) {
        setOnboardingStep(2);
        return s;
      }

      return next;
    });
  });
  const goToPreviousStep = useCallback(() => {
    setStep((s) => {
      const next = s - 1;

      if (next < 1) {
        setMappings(null);
      }

      return next;
    });
  });

  return (
    <Modal
      visible={modal}
      onOk={closeModal}
      onCancel={closeModal}
      width={1240}
      title="Import CSV"
      destroyOnClose
      footer={null}
    >
      <Steps current={step}>
        <Steps.Step title="Map columns" />
        <Steps.Step title="Review" />
      </Steps>
      <div className="mt-10 mb">
        {
            (step === 0) ? (
              <CsvMapper
                file={file}
                onChange={setMappings}
                initialMappings={mappings}
              />
            ) : null
          }
        {
            (step === 1) ? (
              <CsvPreview
                file={file}
                mappings={mappings}
                limit={1000}
              />
            ) : null
          }
        {
            (step >= 0 && step < 2) ? (
              <div className="justify-between flex mt-2">
                <Button onClick={goToPreviousStep} disabled={step === 0}>Back</Button>
                <Button onClick={goToNextStep} type="primary">{step === 1 ? 'Import' : 'Continue'}</Button>
              </div>
            ) : null
          }
      </div>
    </Modal>
  );
}
