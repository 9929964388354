import { hasBusinessEmail } from './validators';

const oldPassword = [
  { required: true, message: 'Old password is required to change password' },
];

const password = [
  { required: true, message: 'Password is required' },
  { min: 6, message: 'Passwords must be at least six characters long' },
];

const newPassword = [
  { required: true, message: 'New password is required' },
  ...password,
];

const email = [
  { required: true, message: 'Email is required' },
  { type: 'email', message: 'Invalid email address' },
  { validator: hasBusinessEmail, message: 'Business email is required' },
];

const loginEmail = [
  { required: true, message: 'Email is required' },
];

const loginPassword = [
  { required: true, message: 'Invalid password' },
];

const company = [
  { required: true, message: 'Client\'s company is required' },
];

const name = (message) => [{ required: true, message }];

const required = (message) => {
  if (message) {
    return [{ required: true, message }];
  }

  return [{ required: true }];
};

export default {
  name,
  email,
  password,
  oldPassword,
  newPassword,
  loginEmail,
  loginPassword,
  company,
  required,
};
