import React from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LogoBeta from './svgs/LogoBeta';

const LogoColumn = ({
  title, children, style, className, user, withLink,
}) => {
  const isInviteUrl = window.location.pathname.indexOf('/invite/') === 0;

  return (
    <div className={classNames('onboarding-wrapper', {
      'onboarding-wrapper-logged-in': !!user,
    }, className)}
    >
      {withLink ? (
        <div className="auth-cz-logo-with-link">
          <p className="logo-graphic">
            <LogoBeta />
          </p>
          {
            !isInviteUrl ? (
              <p className="logo-login">
                <Link to="/login">Log In</Link>
              </p>
            ) : null
          }
        </div>
      ) : (
        <div className="auth-cz-logo">
          <LogoBeta />
        </div>
      )}
      <div className="auth-container">
        <div className="container" style={style}>
          {
            title ? (
              <h1>
                {title}
              </h1>
            ) : null
          }
          {children}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(LogoColumn);
