/* eslint-disable react/jsx-filename-extension, max-len */
import React from 'react';

const getColleagueNoFeedbackData = (row, data) => ({
  text: data && (typeof data.days !== 'undefined')
    ? `No feedback since ${data.days} day${data.days !== 1 ? 's' : ''}`
    : 'No feedback',
  description: '',
  recommended_step1: '',
  recommended_step2: '',
  recommended_step3: '',
  recommended_step1_description: (
    <>
    </>
  ),
  recommended_step2_description: (
    <>
    </>
  ),
  hasRecommendation: false,
  messages: [],
  hasMessages: false,
});

export default getColleagueNoFeedbackData;
