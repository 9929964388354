/* eslint-disable no-unused-vars */
import React from 'react';
import ConversationsProvider from './store/ConversationsProvider';
import ConversationsTable from './components/ConversationsTable';

const Index = ({
  isDiscovery,
  filters,
  perPage,
  hasPagination = false,
  showTopicName = true,
  showSource = true,
  showKeywords = true,
  dataConversations,
}) => (
  <ConversationsProvider filters={filters} perPage={perPage} dataConversations={dataConversations}>
    <ConversationsTable
      isDiscovery={isDiscovery}
      hasPagination={hasPagination}
      showTopicName={showTopicName}
      showSource={showSource}
      showKeywords={showKeywords}
    />
  </ConversationsProvider>
);

export default Index;
