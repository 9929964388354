import React from 'react';
import { Menu, Modal, message } from 'antd';
import { Trash2Icon } from 'lucide-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { conversations } from '@/api';
import { usePaginationStore } from '../../store/zustand/usePaginationStore';

export default function DeleteConversationsModal({
  conversationId,
  ...props
}) {
  const { currentPage, perPage } = usePaginationStore((state) => state);
  const filters = { currentPage, filters: null, perPage };

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationKey: 'update_sentiment',
    mutationFn: async (conversation) => {
      await conversations.deleteConversation(conversation.id);
    },
    onMutate: async (conversation) => {
      await queryClient.cancelQueries({ queryKey: ['conversations', filters] });
      const previousConvs = queryClient.getQueryData(['conversations', filters]);

      if (previousConvs) {
        queryClient.setQueryData(['conversations', filters], (old) => ({ ...old, data: old.data.filter((item) => item.id !== conversation.id) }));
      }

      return { previousConvs };
    },
    onSuccess: () => message.success('Conversation deleted successfully'),
    onError: (err, conversation, ctx) => {
      if (ctx?.previousConvs) {
        queryClient.setQueryData(['conversations', filters], ctx.previousConvs);
      }
      message.error('Failed deleting conversation');
    },
    onSettled: () => {
      queryClient.invalidateQueries(['conversations', filters]);
    },
  });

  const handleDeleteConversation = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete conversation?',
      okText: 'Delete',
      cancelText: 'Cancel',
      okType: 'primary',
      cancelType: 'default',
      onOk: () => {
        mutation.mutate({ id: conversationId });
      },
      okButtonProps: {
        danger: true,
      },
    });
  };
  return (
    <Menu.Item {...props} className="hover:rounded-[3px] hover:!bg-[#F6F9FB]">
      <span tabIndex="0" role="button" className="flex items-center gap-2" onClick={handleDeleteConversation} onKeyPress={handleDeleteConversation}>
        <Trash2Icon size={16} strokeWidth={2} />
        <span>Delete Conversation</span>
      </span>
    </Menu.Item>
  );
}
