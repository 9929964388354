import { subtopics } from '@/api';
import { useQuery } from '@tanstack/react-query';

export default function useSubtopicsTree() {
  return useQuery({
    queryKey: ['subtopics_tree'],
    queryFn: async () => {
      const { data: response } = await subtopics.getTopicTree();
      return response.data;
    },
  });
}
