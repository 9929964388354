import React, { useMemo } from 'react';
import { getTextWithHighlightedKeywords } from '../helpers/conversations';

const HighlightedText = ({
  text, keywords, as: Component = 'p', onHighlight = getTextWithHighlightedKeywords, ...rest
}) => {
  const highlightedText = useMemo(
    () => onHighlight(text, keywords),
    [text, keywords],
  );

  return <Component {...rest} dangerouslySetInnerHTML={{ __html: highlightedText || '<p style="color: #75899B; font-style: italic">Empty message</p>' }} />;
};

export default HighlightedText;
