import React, { useEffect, useState } from 'react';
import { useSelectedDaysPaginationStore } from '@/store/zustand/useSelectedDaysPaginationStore';
import useConversations from '@/components/discovery/hooks/useConversations';
import { List, message, Pagination } from 'antd';
import { cn } from '@/helpers/util';
import ListItem from '@/components/discovery/ListItem';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useFiltersStore } from '@/store/zustand/useFiltersStore';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import { useParams } from 'react-router-dom';

const paginationRender = (_, type, originalElement) => {
  if (type === 'prev') {
    return <ChevronLeft size={18} strokeWidth={2} className="text-[#75899B]" />;
  }
  if (type === 'next') {
    return <ChevronRight size={18} strokeWidth={2} className="text-[#75899B]" />;
  }
  return originalElement;
};

export default function SelectedDaysFeedbackContainer({
  className,
}) {
  const { topicId, subtopicId } = useParams();
  const { filters } = useFiltersStore();
  const { defaultDate } = useDefaultDateStore();
  const [conversations, setConversations] = useState([]);

  const {
    currentPage, perPage, total, setPage, setPager,
  } = useSelectedDaysPaginationStore();

  const {
    data: response, isError, isLoading, error, isFetching,
  } = useConversations({
    topic_id: topicId,
    subtopic_id: subtopicId,
    start: defaultDate.start,
    end: defaultDate.end,
    days: filters.days,
  }, {
    perPage,
    currentPage,
  });

  useEffect(() => {
    if (!response) return;

    setConversations(response.data);
    setPager(response.pager);
  }, [response]);

  useEffect(() => {
    if (!isError) return;
    message.error(error.message);
  }, [error]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <section className={cn('group/button bg-white relative rounded-lg overflow-hidden', className)}>
      <header
        className="mb-2 flex pr-1 items-center justify-between"
      >
        <span className="flex text-sm pt-4 pb-1 pl-5 gap-2 items-center font-[Gordita-Regular]">
          Feedback
        </span>
        <aside className="flex items-center space-x-4 pt-4 pl-5">
          <span className="text-sm text-[#75899B] font-[Gordita-Regular]">
            {perPage * (currentPage - 1) + 1}
            -
            {currentPage * perPage}
            {' '}
            of
            {' '}
            {total}
          </span>
          <Pagination
            itemRender={paginationRender}
            disabled={isLoading}
            className="!text-sm !text-[#75899B] [&>*]:items-center [&>*]:!inline-flex font-[Gordita-Regular] [&_.ant-pagination-simple-pager]:!hidden [&_.ant-pagination-prev]:!mr-0 [&_.ant-pagination-disabled>svg]:!text-[#DFE8F1]"
            simple
            current={currentPage}
            pageSize={perPage}
            total={total}
            onChange={handlePageChange}
          />
        </aside>
      </header>
      <List
        className="h-[calc(100%-86px)] overflow-y-scroll"
        loading={isLoading || isFetching}
        dataSource={conversations}
        renderItem={ListItem}
      />
      <footer
        style={{ height: '32px' }}
        className="mb-2 flex border-0 border-t border-[#DFE9F1] pt-2 pr-1 border-solid items-center justify-end"
      >
        <aside className="flex items-center space-x-4">
          <span className="text-sm text-[#75899B] font-[Gordita-Regular]">
            {perPage * (currentPage - 1) + 1}
            -
            {currentPage * perPage}
            {' '}
            of
            {' '}
            {total}
          </span>
          <Pagination
            itemRender={paginationRender}
            disabled={isLoading}
            className="!text-sm !text-[#75899B] [&>*]:items-center [&>*]:!inline-flex font-[Gordita-Regular] [&_.ant-pagination-simple-pager]:!hidden [&_.ant-pagination-prev]:!mr-0 [&_.ant-pagination-disabled>svg]:!text-[#DFE8F1]"
            simple
            current={currentPage}
            pageSize={perPage}
            total={total}
            onChange={handlePageChange}
          />
        </aside>
      </footer>
    </section>
  );
}
