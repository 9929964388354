import React from 'react';
import { Dropdown, Menu } from 'antd';
import { MoreOutlined, InboxOutlined, EditOutlined } from '@ant-design/icons';
import GhostButton from '../../GhostButton';

const SubtopicActions = ({
  onUpdate, onDelete, disabled, ai,
}) => (
  <Dropdown
    placement="bottomLeft"
    trigger={['click']}
    disabled={disabled}
    overlay={(
      <Menu>
        {
          !ai && (
            <Menu.Item>
              <GhostButton
                type="primary"
                className="topic-actions__item"
                onClick={onUpdate}
              >
                <EditOutlined />
                Edit Sub-segment
              </GhostButton>
            </Menu.Item>
          )
        }
        <Menu.Item>
          <GhostButton
            type="primary"
            className="topic-actions__item"
            onClick={onDelete}
          >
            <InboxOutlined />
            Delete
          </GhostButton>
        </Menu.Item>
      </Menu>
    )}
  >
    <MoreOutlined className="subtopic-actions__trigger" />
  </Dropdown>
);

export default SubtopicActions;
