import React, { useState, useEffect } from 'react';
import {
  Row, Col, Typography, Grid,
} from 'antd';
import './happinesScore.less';
import { useHistory } from 'react-router-dom';
import { useHappinesScore } from './useHappinesScore';

const { Text, Link } = Typography;
const { useBreakpoint } = Grid;
const HappinesScore = ({ data, clientName, isColleague }) => {
  const {
    circleColor,
    titleText,
    clientsBodyText,
    arrowIcon,
    linkToClient,
    singleClientBodyText,
  } = useHappinesScore(isColleague);
  const [lastScore, setLastScore] = useState(0);
  const { location: { pathname } } = useHistory();
  const isClientPage = pathname.includes('clients') || pathname.includes('team-members');
  const screens = useBreakpoint();

  useEffect(() => {
    const score = data.length > 0 ? parseFloat(data.slice(-1)[0].amt).toFixed(0) : 0;
    setLastScore(score);
  }, [data]);

  return (
    <div className="happines-score">
      <Row>
        <div className="happines-circle-wrapper">
          {circleColor(lastScore)}
          {screens.xs && arrowIcon(lastScore)}
        </div>
        <Col xl={12} xs={16} className="happines-details">
          {!screens.xs && arrowIcon(lastScore)}
          <Text className="happines-details-title">{titleText(lastScore)}</Text>
          {
            clientName ? <Text type="secondary">{`${clientName} ${singleClientBodyText(lastScore)}`}</Text>
              : <Text type="secondary">{clientsBodyText(lastScore)}</Text>
          }
          {
            isClientPage ? (
              <Link href="#insights-timeline">
                Review timeline &gt;
              </Link>
            ) : linkToClient(lastScore)
          }
        </Col>
      </Row>
    </div>
  );
};

export default HappinesScore;
