import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const useFiltersStore = create(
  devtools((set) => ({
    filters: {
      days: [],
      included_keywords: [],
      sentiment: [],
      custom_fields: [],
      sources: [],
      labels: [],
      exclude_labels: [],
      excluded_keywords: [],
      nps: [],
      segments: [],
      // summary related
      topic_summary_result_id: null,
      topic_summary_request_id: null,
    },
    setFilters: (data) => set({ filters: data }),
    setFiltersField: (field, value) => set((state) => ({ filters: { ...state.filters, topic_summary_result_id: null, [field]: value } })),
    reset: () => set({
      filters: {
        days: [],
        included_keywords: [],
        sentiment: [],
        custom_fields: [],
        sources: [],
        labels: [],
        exclude_labels: [],
        excluded_keywords: [],
        nps: [],
        segments: [],
        // summary related
        topic_summary_result_id: null,
        topic_summary_request_id: null,
      },
    }),
  }), {
    name: 'useFiltersStore',
  }),
);
