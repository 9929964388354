import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { LeftOutlined, RightOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Button, Empty } from 'antd';
import { connect } from 'react-redux';
import GridPage from './GridPage';

const Grid = ({
  items,
  mobile,
  onDomain,
  isColleague,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const onNextPress = useCallback(() => {
    setCurrentPage(currentPage + 1);
  }, [currentPage]);
  const onPrevPress = useCallback(() => {
    setCurrentPage(currentPage - 1);
  }, [currentPage]);

  return (
    <>
      <div className="graphic-container__heading recent-interactions grid-recent-interactions">
        <div className="headline">
          <ThunderboltOutlined />
          <span>Recent interactions</span>
        </div>
        {
          items[0].items.length && !mobile
          && ((currentPage !== 0 || !(items[currentPage + 1] === undefined
              || !items[currentPage + 1].items.length))) ? (
                <div className="graphic-container__carousel_buttons">
                  <Button
                    disabled={currentPage <= 0}
                    shape="circle"
                    icon={<LeftOutlined />}
                    size={32}
                    style={{ marginRight: '8px' }}
                    onClick={onPrevPress}
                  />
                  <Button
                    disabled={items[currentPage + 1] === undefined
                    || !items[currentPage + 1].items.length}
                    shape="circle"
                    icon={<RightOutlined />}
                    size={32}
                    onClick={onNextPress}
                  />
                </div>
            ) : null
        }
      </div>
      {
        items[0].items.length ? (
          <div className={classNames('fancy-grid-carousel', {
            'fancy-grid-carousel-mobile': mobile,
            'team-side': onDomain,
          })}
          >
            {
                items.map((pageOfItems, index) => (
                  <GridPage
                    pageOfItems={pageOfItems}
                    key={pageOfItems.key}
                    index={index}
                    currentPage={currentPage}
                    goBack={onPrevPress}
                    mobile={mobile}
                    isColleague={isColleague}
                    onDomain={onDomain}
                  />
                ))
              }
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`No more ${onDomain ? 'team members' : 'clients'}`} />
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  items: state.preparedRecentInteractions.items,
});

export default connect(mapStateToProps, null)(Grid);
