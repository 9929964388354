import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Select, Spin } from 'antd';
import useCsvFile from './hooks/useCsvFile';
import CsvSmallPreview from './CsvSmallPreview';

const { Option } = Select;

export const initialExpectedColumns = [
  { id: 'conversation', label: 'Conversation' },
  { id: 'email', label: 'Email' },
  { id: 'date', label: 'Date' },
  { id: 'name', label: 'Name' },
  { id: 'company', label: 'Company' },
  { id: 'nps_score', label: 'NPS Score' },
  { id: 'csat_score', label: 'CSAT Score' },
];

const CsvMapper = ({ file, onChange, initialMappings }) => {
  const {
    loading, columns, previews, fields,
  } = useCsvFile(file, 10);
  const [previewColumn, setPreviewColumn] = useState(null);
  const [expectedColumns, setExpectedColumns] = useState(initialExpectedColumns);
  const initialSetMappings = useMemo(() => initialMappings, []);
  const [mappings, setMappings] = useState(initialMappings || {});
  const addNewValue = '#CLZ_add_as_new';
  const onSelectChange = useCallback((column) => (value) => {
    if (value === addNewValue) {
      if (!expectedColumns.find((expected) => expected.id === column)) {
        setExpectedColumns((expected) => ([
          ...expected,
          { id: column, label: column },
        ]));
      }
      setMappings((map) => ({ ...map, [column]: column }));
    } else {
      setMappings((map) => ({
        ...map,
        [column]: value,
        [value !== 'conversation' ? Object.keys(map).find((key) => map[key] === value) : undefined]: undefined,
      }));
    }
  }, [setMappings, expectedColumns]);

  useEffect(() => {
    const map = { ...mappings };
    Object.keys(map).forEach((key) => map[key] === undefined && delete map[key]);
    onChange?.(map);
  }, [mappings]);

  useEffect(() => {
    if (!loading && !initialSetMappings) {
      const foundMappings = {};
      expectedColumns.forEach((expected) => {
        let found = false;
        columns.forEach((column) => {
          if (!found && column.startsWith(expected.id)) {
            foundMappings[column] = expected.id;
            found = true;
          }
        });
      });
      if (Object.keys(foundMappings).length) {
        setMappings((map) => ({ ...map, ...foundMappings }));
      }
    }
  }, [loading, columns]);

  if (loading) {
    return (
      <div className="text-center m-8">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="flex gap-10 mb-8">
      <div className="flex-1">
        <h4 className="mb-6">Review and confirm each mapping choice</h4>
        {
          columns.map((column) => (
            <div
              className="w-full flex items-center justify-between border-solid border-0 border-b border-gray-100 py-3 px-2 font-[Gordita-Regular] rounded-md hover:bg-gray-100"
              key={column}
              onMouseEnter={() => setPreviewColumn(column)}
              onMouseLeave={() => setPreviewColumn(null)}
            >
              <div>
                {column}
              </div>
              <div>
                <Select
                  style={{ minWidth: 200 }}
                  allowClear
                  showSearch
                  placeholder="Select..."
                  onChange={onSelectChange(column)}
                  value={mappings[column]}
                >
                  {
                    expectedColumns.map((expectedColumn) => (
                      <Option key={expectedColumn.id} value={expectedColumn.id}>{expectedColumn.label}</Option>
                    ))
                  }
                  {
                    fields.map((field) => (
                      <Option key={field.key} value={field.name}>{field.name}</Option>
                    ))
                  }
                  {
                    !fields.find((c) => c.name?.toLowerCase() === column?.toLowerCase()) && !expectedColumns.find((e) => e.id?.toLowerCase() === column?.toLowerCase()) ? (
                      <Option value={addNewValue}>Add as custom field</Option>
                    ) : null
                  }
                </Select>
              </div>
            </div>
          ))
        }
      </div>
      <div className="flex-1">
        <div className="sticky top-0 left-0">
          {
            previewColumn ? (
              <CsvSmallPreview
                data={previews[previewColumn]}
                column={previewColumn}
                limit={3}
              />
            ) : null
          }
        </div>
      </div>
    </div>
  );
};

export default CsvMapper;
