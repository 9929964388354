import React from 'react';
import { connect } from 'react-redux';
import InsightModalWithNavigation from './InsightDescriptionWithModal/InsightModalWithNavigation';
import hideInsightAction from '../../store/actions/hideInsight';

const InsightGlobalModal = ({
  visibility, item, onClientList, hideInsight, isColleague,
}) => item && (
  <InsightModalWithNavigation
    visibility={visibility}
    item={item}
    handleCancel={hideInsight}
    onClientList={onClientList}
    isColleague={isColleague}
  />
);

const mapStateToProps = (state) => ({
  onClientList: state.insight.onClientList,
  visibility: state.insight.visibility,
  item: state.insight.item,
  isColleague: state.insight.isColleague,
});

const mapDispatchToProps = (dispatch) => ({
  hideInsight: () => dispatch(hideInsightAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsightGlobalModal);
