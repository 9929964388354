import React from 'react';
import {
  Modal, Button, Typography,
} from 'antd';

const { Text } = Typography;
const SuccessMessageModal = ({ isVisible, toggle }) => (
  <>
    <Modal
      title=""
      visible={isVisible}
      onCancel={toggle}
      className="successMessageModal"
      footer={[
        <Button type="primary" onClick={toggle}>
          Done
        </Button>,
      ]}
    >
      <Text style={{ fontSize: '18px', padding: '10px 0' }}> You&apos;re awesome!</Text>
      <Text style={{ fontSize: '16px' }}> Thank you for making</Text>
      <Text style={{ fontSize: '16px' }}> ClientZen better</Text>

    </Modal>
  </>
);
export default SuccessMessageModal;
