import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import posthog from 'posthog-js';
import { PostHogProvider as BasePosthogProvider } from 'posthog-js/react';
import { inProduction, inStaging } from '@/flags';
import { platforms } from '@/api';
import auth from '@/api/auth';

if (inProduction() || inStaging()) {
  posthog.init(
    import.meta.env.VITE_POSTHOG_KEY,
    {
      api_host: import.meta.env.VITE_POSTHOG_HOST,
    },
  );
}

const PosthogProvider = ({ user, children }) => {
  useEffect(async () => {
    if (inProduction() || inStaging()) {
      if (user) {
        const team = auth.team();
        const sources = await platforms.getTeamPlatforms();
        posthog.identify(
          user.id,
          {
            email: user.email,
            name: user.name,
            registeredAt: user.created_at,
            teamId: team.id,
            dashboard_ready: team.dashboard_ready,
            platforms_connected: Object.keys(sources.data.data),
            platforms_connected_count: Object.keys(sources.data.data).length,
          },
        );
      }
    }
  }, [user]);

  if (inProduction() || inStaging()) {
    return (
      <BasePosthogProvider client={posthog}>
        {children}
      </BasePosthogProvider>
    );
  }

  return children;
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(PosthogProvider);
