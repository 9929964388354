import React, {
  useContext, useState,
} from 'react';
import { motion } from 'framer-motion';
import { OnboardingContext, PlatformProvider } from './context';
import { ConnectPlatform } from './ui/ConnectPlatform';
import { ConnectedPlatform } from './ui/ConnectedPlatform';
import { usePlatforms } from './hooks/usePlatforms';
import { ProcessingInsights } from './ui/ProcessingInsights';

export function OnboardingContainer() {
  const { step } = useContext(OnboardingContext);
  const [platform, setPlatform] = useState();
  const [modal, setModal] = useState(false);
  const [file, setFile] = useState(null);
  const [mappings, setMappings] = useState(null);

  usePlatforms();

  //! Might be needed on future.
  // useEffect(() => {
  //   if (!data) return;
  //   const connectedPlatforms = data
  //     .filter((p) => p.type === INTEGRATION_TYPE.INTERCOM || p.type === INTEGRATION_TYPE.ZENDESK_SUPPORT);

  //   if (connectedPlatforms.length > 0) {
  //     setPlatform(connectedPlatforms[0].type);
  //     setStep(2);
  //   }
  // }, [data]);

  let render = null;
  switch (step) {
    case 1:
      render = <ConnectPlatform />;
      break;
    case 2:
      render = <ConnectedPlatform />;
      break;
    case 3:
      render = <ProcessingInsights />;
      break;
    default:
      render = <ConnectPlatform />;
      break;
  }

  return (
    <PlatformProvider value={{
      platform,
      setPlatform,
      modal,
      setModal,
      file,
      setFile,
      mappings,
      setMappings,
    }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
        exit={{ opacity: 0 }}
      >
        {render}
      </motion.div>
    </PlatformProvider>
  );
}
