import React, { useState, useEffect, useMemo } from 'react';
import {
  Modal as AntModal, Button, Form as AntForm, message,
} from 'antd';
import { topics } from '@/api';
import { useErrorState, displayOrCatch } from '../../helpers';
import Form from './components/Form';
import Card from './components/Card';
import Layout from './components/Layout';

const TopicModal = ({
  visible, record, onOk, onCancel,
}) => {
  const isEdit = !!record;

  const [form] = AntForm.useForm();

  const [error, setError] = useErrorState();

  const [loading, setLoading] = useState(false);

  const [preview, setPreview] = useState({
    data: [], count: 0, loading: false, showPreview: isEdit,
  });

  const [topicGroups, setTopicGroups] = useState([]);

  const defaultValues = useMemo(() => ({
    name: record?.name ?? '',
    groups: record?.groups[0]?.name ?? undefined,
    client_intent_id: '0',
    included_keywords: record?.included_keywords ?? [],
    excluded_keywords: record?.excluded_keywords ?? [],
  }), [record]);

  const label = `${isEdit ? 'Update' : 'Create'} Topic`;

  const loadPreview = (values) => {
    const showPreview = values?.included_keywords?.length > 0;
    setPreview({ ...preview, loading: true, showPreview });

    topics.getPreview(values)
      .then(({ data }) => {
        setPreview({
          showPreview,
          loading: false,
          count: data.total,
          data: data.hits.map(
            // eslint-disable-next-line no-shadow
            ({ message, keywords }) => ({
              text: message.snippet,
              keywords: keywords.snippet,
              body: message.body,
              hasMore: message.has_more,
            }),
          ),
        });
      })
      .catch((err) => {
        displayOrCatch(err, setError);
        setPreview({
          ...preview, loading: false, data: [], count: 0, showPreview,
        });
      });
  };

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(defaultValues);

      if (isEdit) {
        loadPreview(defaultValues);
      }

      topics.getTopicGroups()
        .then(({ data }) => {
          setTopicGroups(data);
        })
        .catch(() => {
          message.error('Topic Groups Error');
        });
    }

    return () => {
      setError(null);
      setPreview({
        ...preview, data: [], count: 0, loading: false, showPreview: false,
      });
    };
  }, [visible]);

  const cancelHandler = () => {
    form.setFieldsValue(defaultValues);
    onCancel();
  };

  const formProps = {
    form,
    error,
    groups: topicGroups,
    defaultValues,
    onChange: loadPreview,
    onSubmit: (values) => {
      const model = {
        ...values,
        groups: [values.groups],
        ...(isEdit && { id: record.id }),
      };

      setLoading(true);

      topics[isEdit ? 'updateTopic' : 'createTopic'](model)
        .then(({ data: { data } }) => {
          form.setFieldsValue(defaultValues);
          setLoading(false);
          onOk(data, isEdit);
          message.success(`Topic ${isEdit ? 'updated' : 'created'} successfully`);
        })
        .catch((err) => {
          displayOrCatch(err, setError);
          setLoading(false);
        });
    },
  };

  return (
    <AntModal
      title={label}
      className="topic-modal"
      width={930}
      visible={visible}
      onCancel={cancelHandler}
      destroyOnClose
      footer={[
        <Button
          key="cancel"
          disabled={loading}
          onClick={cancelHandler}
        >
          Cancel
        </Button>,
        <Button
          key="change"
          type="primary"
          size="large"
          loading={loading}
          onClick={() => form.submit()}
        >
          {label}
        </Button>,
      ]}
    >
      <Layout
        key="content"
        left={<Form {...formProps} />}
        right={<Card {...preview} />}
      />
    </AntModal>
  );
};

export default TopicModal;
