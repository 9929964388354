import React, { useState, useEffect } from 'react';
import { message, Result, Spin } from 'antd';
import { connect } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import userAuthenticatedAction from '@/store/actions/userAuthenticated';
import { auth } from '@/api';
import { displayOrCatchMessage } from '@/helpers';

const activate = (token, setLoading, userAuthenticated) => {
  auth
    .verifyEmail(token)
    .then((response) => {
      setLoading(false);
      message.success('Your email was verified');

      if (response) {
        userAuthenticated(response.data.data.user);
      }
    })
    .catch((e) => {
      displayOrCatchMessage(e);
      setLoading(false);
    });
};

const ActivateEmail = ({ userAuthenticated }) => {
  const [loading, setLoading] = useState(true);
  const { token } = useParams();

  useEffect(() => {
    activate(token, setLoading, userAuthenticated);
  }, []);

  return loading ? (
    <>
      <Helmet>
        <title>ClientZen - Activate Email</title>
      </Helmet>
      <Result icon={<Spin size="large" />} subTitle="working on it..." />
    </>
  ) : (
    <Redirect to="/" />
  );
};

const mapDispatchToProps = (dispatch) => ({
  userAuthenticated: (data) => dispatch(userAuthenticatedAction(data)),
});

export default connect(null, mapDispatchToProps)(ActivateEmail);
