import * as React from 'react';
import { memo } from 'react';

const SvgComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={56.25} height={36} {...props}>
    <defs>
      <style>
        {
          '.dashboard-ready-a,.dashboard-ready-b,.d{fill:none}.dashboard-ready-a,.dashboard-ready-d{stroke:#000;stroke-linecap:round}.dashboard-ready-a{stroke-linejoin:round}.dashboard-ready-b{stroke:#707070}.dashboard-ready-e{stroke:none}'
        }
      </style>
    </defs>
    <path className="dashboard-ready-a" d="M13.227 17.225a4.54 4.54 0 1 1-6-5.929" />
    <path className="dashboard-ready-a" d="M13.586 15.459a4.54 4.54 0 0 0-4.54-4.54v4.54Z" />
    <path
      className="dashboard-ready-b"
      d="M.5 7.5h46M3.5 4.5h12M34.5 4.5h2M37.5 4.5h2M40.5 4.5h2"
    />
    <g className="dashboard-ready-b">
      <rect className="dashboard-ready-e" width={47} height={36} rx={4} />
      <rect
        x={0.5}
        y={0.5}
        width={46}
        height={35}
        rx={3.5}
        style={{
          fill: 'none',
        }}
      />
    </g>
    <g
      style={{
        fill: '#fff',
      }}
    >
      <path
        className="dashboard-ready-e"
        d="M46.5 29.5c-5.1 0-9.25-4.15-9.25-9.25S41.4 11 46.5 11s9.25 4.15 9.25 9.25-4.15 9.25-9.25 9.25Z"
      />
      <path
        d="M9.75 1C4.925 1 1 4.925 1 9.75s3.925 8.75 8.75 8.75 8.75-3.925 8.75-8.75S14.575 1 9.75 1m0-1c5.385 0 9.75 4.365 9.75 9.75s-4.365 9.75-9.75 9.75S0 15.135 0 9.75 4.365 0 9.75 0Z"
        style={{
          fill: '#909090',
          stroke: 'none',
        }}
        transform="translate(36.75 10.5)"
      />
    </g>
    <path className="dashboard-ready-d" d="m43.024 20.529 2.209 2.093 4.744-4.744" />
    <path
      className="dashboard-ready-a"
      d="M11.03 30.999v-4.632M8.25 31v-7.412M5.471 30.999V28.22M19.53 30.999v-4.632M22.309 30.999v-4.632M16.75 31v-7.412M13.971 30.999V28.22"
    />
    <path className="dashboard-ready-d" d="M19.5 11.5h15M19.5 14.5h8.536M19.5 17.5h15" />
  </svg>
);

const Memo = memo(SvgComponent);
export default Memo;
