import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { ReactComponent as VerifiedIcon } from '../svgs/verified.svg';

const Guarantees = () => (
  <div className="guarantees">
    <div className="guarantee">
      <span className="anticon">
        <VerifiedIcon />
      </span>
      {' '}
      SSL data encryption
    </div>
    <div className="guarantee">
      <span className="anticon">
        <VerifiedIcon />
      </span>
      {' '}
      GDPR compliant
    </div>
    <div className="guarantee">
      <CheckCircleOutlined />
      {' '}
      No coding required
    </div>
  </div>
);

export default Guarantees;
