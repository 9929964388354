import React from 'react';
import { Progress } from 'antd';
import SentimentAvg from '@/base-components/sentiment-avg';

const SentimentAvgEvolution = ({ positive, negative, timeText }) => (
  <div className="sentiment-avg-evolution-container">
    <Progress
      percent={positive + negative}
      success={{ percent: negative }}
      type="circle"
      strokeColor="#6DCCA6"
      strokeWidth={4}
      className="sentiment-avg-evolution"
      format={(percent) => (
        <div>
          <SentimentAvg
            sentiment="negative"
            value={negative}
            className="sentiment-avg-evolution-item"
          />
          <SentimentAvg
            sentiment="positive"
            value={positive}
            className="sentiment-avg-evolution-item"
          />
          <SentimentAvg
            sentiment="neutral"
            value={(100 - percent).toFixed(2)}
            className="sentiment-avg-evolution-item"
          />
        </div>
      )}
    />
    <p className="sentiment-avg-evolution-description">
      Average sentiment in the
      {' '}
      {timeText}
    </p>
  </div>
);

export default SentimentAvgEvolution;
