import React, { useEffect, useState } from 'react';
import {
  Button, Dropdown, Menu, Tag, Empty, Spin, message,
} from 'antd';
import { PlusIcon, X, XIcon } from 'lucide-react';
import { cn } from '@/helpers/util';
import useLabels from '../../discovery/hooks/useLabels';
import { useFiltersDispatch } from '../context';

function LabelTag({
  label, value, closable, onClose,
}) {
  return (
    <Tag
      color={value}
      closeIcon={<XIcon size={12} color="#CEDBE4" />}
      closable={closable}
      onClose={onClose}
      className="!text-[#202324] !py-1 !bg-[#F6F9FB] !rounded flex items-center gap-2 [&>.ant-tag-close-icon]:flex"
    >
      <div className="rounded-full w-2 h-2 " style={{ backgroundColor: label.color }} />
      <span>
        {value.name}
      </span>
    </Tag>
  );
}

export default function LabelFilter({ defaultValue = [], name }) {
  const {
    data: labels, isLoading, isError, error,
  } = useLabels();

  const [list, setList] = useState([]);
  const dispatch = useFiltersDispatch();

  useEffect(() => {
    if (!labels) return;
    setList(defaultValue.map((value) => labels.find((label) => label.id === (typeof value === 'object' ? value.label_id : value))));
    dispatch({
      type: 'SET_FILTERS_FIELD',
      field: 'labels',
      value: defaultValue.map((value) => labels.find((label) => label.id === (typeof value === 'object' ? value.label_id : value))).map((label) => label.id),
    });
  }, [labels]);

  useEffect(() => {
    if (!isError) return;
    message.error(error.message);
  }, [error]);

  const handleClick = (label) => {
    if (list.includes(label)) {
      setList((prevState) => prevState.filter((existing) => existing !== label));
      dispatch({ type: 'REMOVE_FILTERS_FIELD', field: 'labels', value: label.id });
    } else {
      setList((prevState) => [...prevState, label]);
      dispatch({ type: 'ADD_FILTERS_FIELD', field: 'labels', value: label.id });
    }
  };

  const handleClear = () => {
    setList([]);
    dispatch({ type: 'SET_FILTERS_FIELD', field: 'labels', value: [] });
  };

  const content = labels?.length > 0 ? labels.map((label) => (
    <Menu.Item
      className="flex items-center flex items-center gap-2 hover:!bg-[#F6F9FB] !px-3 !rounded-[4px] !mb-0 !mt-0"
      key={label.id}
      onClick={() => handleClick(label)}
    >
      <div className="rounded-full w-2 h-2 " style={{ backgroundColor: label.color }} />
      <span>
        {label.name}
      </span>
    </Menu.Item>
  )) : (
    <div className="pt-5 mx-auto">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No labels added to Clientzen" />
    </div>
  );

  return (
    <div className="space-y-0.5">
      <Dropdown
        overlayClassName="!px-3"
        align={{ offset: [0, -10] }}
        trigger={['click']}
        overlay={(
          <Menu className="!rounded-md shadow-[0px_1px_12px_#CAD7E180] border border-solid border-[#DFE9F1]">
            {isLoading ? (
              <div className="p-5 flex items-center justify-center">
                <Spin />
              </div>
            ) : content}
          </Menu>
        )}
      >
        <div className="group flex flex-col border-0 border-b border-solid border-[#EFF4F8] hover:border-[#DFE9F1] pb-3 cursor-pointer">
          <div className="group/button px-4 hover:border-[#DFE9F1] flex items-center justify-between text-[#75899B] text-sm">
            <span className="group-hover:text-[#44596C]">{name}</span>
            <div className="flex gap-1">
              <Button
                type="text"
                onClick={handleClear}
                className={cn(
                  'opacity-0 group-hover:opacity-100 flex items-center !rounded-md !p-2 justify-between !text-sm !text-[#75899B] hover:!bg-[#EFF4F8] hover:!text-[#44596C]',
                  list.length === 0 && 'group-hover:opacity-0',
                )}
              >
                <X size={20} />
              </Button>
              <Button
                type="text"
                className={cn(
                  'flex items-center !rounded-md !p-2 justify-between !text-sm !text-[#75899B]',
                  list.length === 0 ? 'group-hover/button:!bg-[#EFF4F8] group-hover/button:!text-[#44596C]' : 'hover:!bg-[#EFF4F8] hover:!text-[#44596C]',
                )}
              >
                <PlusIcon size={20} />
              </Button>
            </div>
          </div>
          {list.length > 0 ? (
            <div className="flex flex-wrap mt-1.5 gap-2 px-3">
              {list.map((label) => (
                <LabelTag
                  key={label}
                  label={label}
                  value={label}
                  closable
                  onClose={() => handleClick(label)}
                />
              ))}
            </div>
          ) : null}
        </div>
      </Dropdown>
    </div>
  );
}
