import { topics } from '@/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export default function useTopic(id, filters, options) {
  return useQuery({
    queryKey: ['topic', id, { filters }],
    queryFn: async () => {
      const { data } = await topics.getTopic(id, filters);
      return data;
    },
    refetchOnWindowFocus: !!filters,
    ...options,
  });
}

export async function prefetchTopic(id, filters) {
  const queryClient = useQueryClient();
  await queryClient.prefetchQuery(['topic', id, { filters }], async () => {
    const { data } = await topics.getTopic(id, filters);
    return data;
  });
}
