/* eslint-disable react/jsx-filename-extension, max-len */
import React from 'react';

const getClientAskReviewClutch = (row) => ({
  text: `You could ask ${row.client.name} for a review on Clutch`,
  description: '',
  recommended_step1: `Schedule a casual call with ${row.client.name}.`,
  recommended_step2: 'Here are some extra tips to consider',
  recommended_step3: (
    <>
      Check out
      {' '}
      <a rel="noreferrer" target="_blank" href="https://help.clutch.co/en/knowledge/how-to-ask-clients-to-leave-clutch-review">this article</a>
      {' '}
      for more useful information.
    </>
  ),
  recommended_step1_description: (
    <>
      <span>A call increases your chances of getting an yes. Follow this steps during the call:</span>
      <ul>
        <li>Briefly explain what Clutch is and why the review is important</li>
        <li>{`Make it really easy for ${row.client.name} to give you the review`}</li>
        <li>Offer small incentives if possible</li>
      </ul>
    </>
  ),
  recommended_step2_description: (
    <ul>
      <li>
        <strong>Timing is everything</strong>
        . Make sure you deliver value before you ask
      </li>
      <li>Follow up politely if need it, when your client is feeling grateful</li>
      <li>
        The best way to generate positive feedback is to
        {' '}
        <strong>earn it</strong>
      </li>
    </ul>
  ),
});

export default getClientAskReviewClutch;
