export const SET_LOADING_ACTION = 'SET_LOADING_ACTION';

export const SET_RELOAD_ACTION = 'SET_RELOAD_ACTION';

export const SET_DATA_ACTION = 'SET_DATA_ACTION';

export const SET_SORTER_ACTION = 'SET_SORTER_ACTION';

export const initialState = {
  loading: false,
  reload: false,
  data: [],
  sorter: null,
  pagination: {
    current: 0,
    pageSize: 5,
    total: 0,
  },
};

export const peopleTableReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING_ACTION: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case SET_DATA_ACTION: {
      const { data, pager } = action.payload;

      return {
        ...state,
        loading: false,
        data,
        pagination: {
          current: pager.current_page,
          pageSize: pager.per_page,
          total: pager.total,
        },
      };
    }
    case SET_SORTER_ACTION: {
      return {
        ...state,
        loading: true,
        sorter: action.payload,
      };
    }
    default:
      return state;
  }
};
