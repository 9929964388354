import React from 'react';
import { Tag, Tooltip } from 'antd';

const RecipientTag = ({
  forClient,
  forTeamMember,
  name,
  email,
}) => {
  const color = 'rgba(0, 0, 0, 0.85)';
  let borderColor = '#F0F0F0';
  let backgroundColor = '#F5F5F5';

  if (forClient) {
    borderColor = '#9ADCB9';
    backgroundColor = '#E7F7F0';
  } else if (forTeamMember) {
    borderColor = '#B0C3FE';
    backgroundColor = '#E7EEF7';
  }

  return (
    <Tooltip title={email}>
      <a href={`mailto:${email}`}>
        <Tag
          color={backgroundColor}
          style={{ color, borderColor }}
        >
          {name}
        </Tag>
      </a>
    </Tooltip>
  );
};

export default RecipientTag;
