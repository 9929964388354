import React from 'react';
import { ThunderboltOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import InteractionCarousel from '../InteractionCarousel';

const RecentInteractions = ({
  recentInteractions, onDomain, mobile, isColleague,
}) => (
  <>
    <div className="graphic-container__heading recent-interactions">
      <ThunderboltOutlined />
      <span>Recent interactions</span>
    </div>
    <InteractionCarousel
      items={recentInteractions}
      onDomain={onDomain}
      mobile={mobile}
      isColleague={isColleague}
    />
  </>
);

const mapStateToProps = (state) => ({
  recentInteractions: state.recentInteractions,
});

export default connect(mapStateToProps, null)(RecentInteractions);
