import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Input } from 'antd';
import config from '@/config';
import { INTEGRATION_TYPE } from '@/base-components/integrations/constants';
import { cn } from '@/helpers/util';
import { OnboardingContext } from '../context';
import { usePlatforms } from '../hooks/usePlatforms';
import styles from './ZendeskContainer.module.less';

export const showGorgiasPopup = (token, domain, onComplete) => {
  const myWindow = window.open(`${config.apiBaseUrl}/gorgias/auth?token=${token}&subdomain=${encodeURIComponent(domain)}`,
    'logw', 'width=700,height=500');

  const timer = setInterval(() => {
    if (myWindow.closed) {
      clearInterval(timer);
      onComplete?.();
    }
  }, 1000);
};

export function GorgiasContainer() {
  const user = useSelector((state) => state.user);
  const { setStep } = useContext(OnboardingContext);
  const [domain, setDomain] = useState('');
  const {
    data, refetch, isLoading, isFetching,
  } = usePlatforms();

  const onClick = () => {
    const myWindow = window.open(`${config.apiBaseUrl}/gorgias/auth?token=${user.token}&subdomain=${encodeURIComponent(domain)}`,
      'logw', 'width=700,height=500');

    const timer = setInterval(() => {
      if (myWindow.closed) {
        clearInterval(timer);
        refetch();
      }
    }, 1000);
  };

  useEffect(() => {
    if (!data) return;

    const gorgias = data.filter((p) => p.type === INTEGRATION_TYPE.GORGIAS && p.status === 'not_ready');
    if (gorgias.length > 0) {
      setStep(2);
    }
  }, [data]);

  return (
    <div className="relative mt-4 flex">
      <Input
        className={styles.czInput}
        disabled={isLoading}
        size="large"
        placeholder="Your Gorgias support domain"
        value={domain}
        autoComplete="off"
        onChange={(e) => setDomain(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && onClick()}
      />
      <Button
        disabled={!domain || isLoading || isFetching}
        loading={isLoading || isFetching}
        onClick={onClick}
        size="large"
        className={cn('!absolute right-0', styles.czButton)}
      >
        Continue
      </Button>
    </div>
  );
}
