import React from 'react';
import { motion } from 'framer-motion';
import { cn } from '@/helpers/util';

function Description({ children, className }) {
  return (
    <p className={cn('m-0 text-[#75899B]', className)}>
      {children}
    </p>
  );
}

function Title({ value = 'What do you need ClientZen for?', className }) {
  return (
    <h2 className={cn('m-0 font-[Gordita-Medium] text-lg text-[#44596C]', className)}>
      {value}
    </h2>
  );
}

function Feedback({ children, className }) {
  return (
    <motion.div
      className={cn('border border-solid border-[#CFDBE4] rounded-lg py-5 px-6', className)}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.8 }}
    >
      {children}
    </motion.div>
  );
}

export default Feedback;
Feedback.Title = Title;
Feedback.Description = Description;
