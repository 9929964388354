import React from 'react';
import GradientSentimentChartTooltipContent from './GradientSentimentChartTooltipContent';

const GradientSentimentChartTooltip = (props) => {
  const { payload } = props;

  if (!Array.isArray(payload) || payload.length === 0) {
    return null;
  }

  const [{
    payload: {
      total, ratio, annotation, date,
    },
  }] = payload;

  return (
    <div className="gradient-chart-tooltip">
      <div className="ant-tooltip topic-metric-tooltip ant-tooltip-placement-bottom">
        <div className="ant-tooltip-content">
          <div className="ant-tooltip-arrow">
            <span className="ant-tooltip-arrow-content" />
          </div>
          <div className="ant-tooltip-inner" role="tooltip">
            <GradientSentimentChartTooltipContent
              total={total}
              ratio={ratio}
              annotation={annotation}
              date={date}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GradientSentimentChartTooltip;
