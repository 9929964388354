import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';

const ColleaguesNumber = ({ firstColleagues, onDone, loading }) => (
  <div className="connect-colleagues-number">
    <span>
      {
        firstColleagues.length > 1 ? `${firstColleagues.length} Team Members Added` : null
      }
      {
        firstColleagues.length === 1 ? '1 Team Member Added' : null
      }
      {
        firstColleagues.length === 0 ? 'No Team Members Added' : null
      }
    </span>
    {
      firstColleagues.length === 0 ? (
        <Button className="skip-link" onClick={onDone} type="link" disabled={loading}>Skip for now</Button>
      ) : null
    }
  </div>
);

const mapStateToProps = (state) => ({
  firstColleagues: state.firstColleagues,
});

export default connect(mapStateToProps, null)(ColleaguesNumber);
