import { useContext } from 'react';
import { ConversationsContext } from './ConversationsProvider';

const useConversationsStore = () => {
  const { state, actions } = useContext(ConversationsContext);

  return { state, actions };
};

export default useConversationsStore;
