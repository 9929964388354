/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Card, Tag } from 'antd';
import { Redirect, Link } from 'react-router-dom';
import ClientAvatarWithScore from './ClientAvatarWithScore';
import ScoreAvatar from '../../clients/ScoreAvatar';
import InsightDescriptionWithModal from '../../insights/InsightDescriptionWithModal';

const HighlightedCard = ({
  id,
  children,
  title,
  description,
  avatar,
  clientHappiness,
  percentage,
  unhappinessReasons,
  client,
  backgroundColor,
  feedbackType,
  item,
  clientId,
  isColleague,
}) => {
  const [redirect, setRedirect] = useState(false);
  return (
    <>
      {
        redirect ? (
          <Redirect push to={`/insights/${id}`} />
        ) : null
      }
      <Card
        className="highlighted-card"
      >
        {client ? (
          <Link to={`/${isColleague ? 'team-members' : 'clients'}/${clientId}`}>
            <ScoreAvatar client={client} disableBreakpoints />
            {' '}
          </Link>
        ) : (
          avatar && (
            <Link to={`/${isColleague ? 'team-members' : 'clients'}/${clientId}`}>
              <ClientAvatarWithScore
                avatar={avatar}
                clientHappiness={clientHappiness}
                percentage={percentage}
              />
            </Link>
          )
        )}
        <InsightDescriptionWithModal
          id={id}
          feedbackType={feedbackType}
          text={title}
          item={item}
          bgColor={backgroundColor}
          className="highlighted-card-title"
          isColleague={isColleague}
        />
      </Card>
    </>
  );
};

export default HighlightedCard;
