import React from 'react';
import { Row, Col } from 'antd';

const Layout = ({ left, right }) => (
  <Row gutter={30}>
    <Col xs={24} md={12} className="u-full-height">
      {left}
    </Col>
    <Col xs={24} md={12} className="u-full-height">
      {right}
    </Col>
  </Row>
);

export default Layout;
