import { Tag } from 'antd';
import React from 'react';

const getColor = ({ goodValues, badValues, text }) => {
  if (goodValues.indexOf(text) !== -1) {
    return '#0BAA6B';
  }

  if (badValues.indexOf(text) !== -1) {
    return '#FF4D4F';
  }

  return '#BFBFBF';
};

const getBackgroundColor = ({ goodValues, badValues, text }) => {
  if (goodValues.indexOf(text) !== -1) {
    return '#E7F7F0';
  }

  if (badValues.indexOf(text) !== -1) {
    return '#FFF1F0';
  }

  return '#FAFAFA';
};

const StatusTag = ({ goodValues, badValues, text }) => (text ? (
  <Tag
    color={getBackgroundColor({ goodValues, badValues, text })}
    style={{
      borderColor: getColor({ goodValues, badValues, text }),
      color: getColor({ goodValues, badValues, text }),
    }}
  >
    {text.charAt(0).toUpperCase() + text.slice(1)}
  </Tag>
) : null);

export default StatusTag;
