import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React from 'react';

const goBack = () => window.history.back();

const BillingPlanContainer = ({
  children,
  isAnnuallySubscriptionSelected,
  setSubscriptionType,
  setAnnuallySubscriptionValue,
}) => (
  <>
    <Button
      className="back-button"
      size="middle"
      type="link"
      icon={<ArrowLeftOutlined />}
      onClick={goBack}
    >
      Back
    </Button>
    <div className="billing-plans-page">
      <div className="title">Choose the plan that works best for you</div>
      <div className="buttons-group">
        <Button
          className="annually-button"
          size="middle"
          type={isAnnuallySubscriptionSelected ? 'primary' : 'secondary'}
          onClick={setSubscriptionType(true, setAnnuallySubscriptionValue)}
        >
          Billed annually (save 15%)
        </Button>
        <Button
          className="monthly-button"
          size="middle"
          type={isAnnuallySubscriptionSelected ? 'secondary' : 'primary'}
          onClick={setSubscriptionType(false, setAnnuallySubscriptionValue)}
        >
          Billed Monthly
        </Button>
      </div>

      <div className="plans-container">
        {children}
      </div>
    </div>
  </>
);

export default BillingPlanContainer;
