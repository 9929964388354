/* eslint-disable camelcase */
import React, { useCallback, useMemo, useState } from 'react';
import { useFilters, useFiltersDispatch } from '@/components/filter/context';
import { cn } from '@/helpers/util';
import { Button, Spin } from 'antd';
import './FiltersHorizontalList.styles.less';
import {
  Blocks, Tag, WholeWord, XIcon, X, ChevronRight, BookmarkX, ChartNoAxesCombined, Tags,
} from 'lucide-react';

import { getPlatformIcon } from '@/components/discovery/utils/sources';
import useLabels from '@/components/discovery/hooks/useLabels';
import SaveModal from '@/components/filter/SaveModal';
import { useParams } from 'react-router-dom';
import { improveLabel } from '@/components/filter/filters/CustomFieldFilter';
import { getSentimentIcon } from '../discovery/utils/sentiment';
import useTopics from '../discovery/hooks/useTopics';

const ListItem = ({ className, onClick, children }) => (
  <div className={cn('flex gap-2 border border-solid border-[#CFDBE4] rounded-md px-2 justify-center items-center mb-1 text-[#44596C] text-sm', className)}>
    {children}
    <Button
      className="list-item-close"
      type="link"
      onClick={onClick}
    >
      <X size={12} />
    </Button>
  </div>
);

const KeywordListItem = ({ keyword, type, ...props }) => {
  const dispatch = useFiltersDispatch();
  const onClick = useCallback(() => {
    dispatch({ type: 'REMOVE_FILTERS_FIELD', field: type, value: keyword });
  }, [dispatch, type, keyword]);

  return (
    <ListItem {...props} onClick={onClick}>
      <div className="flex gap-1 items-center">
        <span className="anticon">
          <WholeWord size={16} />
        </span>
        <span className="text-[10px] px-[2px] text-[#75899B] pt-px font-[Gordita-Regular]">{type === 'included_keywords' ? 'INCLUDE' : 'EXCLUDE'}</span>
        <span>{keyword}</span>
      </div>
    </ListItem>
  );
};

const SentimentListItem = ({ sentiment, ...props }) => {
  const dispatch = useFiltersDispatch();
  const onClick = useCallback(() => {
    dispatch({ type: 'REMOVE_FILTERS_FIELD', field: 'sentiment', value: sentiment });
  }, [dispatch, sentiment]);

  return (
    <ListItem {...props} onClick={onClick}>
      <div className="flex gap-1 items-center">
        <span className="anticon">
          {getSentimentIcon(sentiment, 16)}
        </span>
        <span>
          {sentiment.charAt(0).toUpperCase() + sentiment.slice(1)}
        </span>
      </div>
    </ListItem>
  );
};

const CustomFieldListItem = ({ field, ...props }) => {
  const dispatch = useFiltersDispatch();
  const { filters } = useFilters();
  const onClick = useCallback((removeValue) => {
    const filtered = filters.custom_fields.map((custom_field) => {
      if (custom_field.id === field.id) {
        return {
          ...custom_field,
          values: custom_field.values.filter((value) => value !== removeValue),
        };
      }

      return custom_field;
    }).filter((custom_field) => !!custom_field.values.length);
    dispatch({ type: 'SET_FILTERS_FIELD', field: 'custom_fields', value: filtered });
  }, [filters, dispatch]);

  return field.values.map((value) => (
    <ListItem {...props} onClick={() => onClick(value)}>
      <div className="flex gap-1 items-center">
        <span className="anticon">
          <Blocks size={16} />
        </span>
        <span className="max-w-[200px] text-ellipsis overflow-hidden whitespace-nowrap">
          {field.name}
        </span>
        <ChevronRight size={16} />
        <span className="max-w-[200px] text-ellipsis overflow-hidden whitespace-nowrap">
          {improveLabel(value)}
        </span>
      </div>
    </ListItem>
  ));
};

const SourceListItem = ({ source, ...props }) => {
  const dispatch = useFiltersDispatch();
  const { filters } = useFilters();
  const onClick = useCallback(() => {
    const filtered = filters.sources.filter((filterSource) => filterSource.type !== source.type && filterSource.source_id !== source.source_id);
    dispatch({ type: 'SET_FILTERS_FIELD', field: 'sources', value: filtered });
  }, [filters, dispatch]);
  const { type } = source;
  const title = type.charAt(0).toUpperCase() + type.slice(1);

  return (
    <ListItem {...props} onClick={onClick}>
      <div className="flex gap-1 items-center">
        {getPlatformIcon({ type })}
        <span>
          {title}
        </span>
      </div>
    </ListItem>
  );
};

const NpsListItem = ({
  nps, ...props
}) => {
  const dispatch = useFiltersDispatch();
  const onClick = useCallback(() => {
    dispatch({ type: 'REMOVE_FILTERS_FIELD', field: 'nps', value: nps });
  }, [dispatch, nps]);

  return (
    <ListItem {...props} onClick={onClick}>
      <div className="flex gap-1 items-center">
        <ChartNoAxesCombined size={20} strokeWidth={2} />
        <span>
          {typeof nps.name === 'number' ? nps.name : nps.name.charAt(0).toUpperCase() + nps.name.slice(1)}
        </span>
      </div>
    </ListItem>
  );
};

const SegmentListItem = ({
  segment, ...props
}) => {
  const { data } = useTopics();
  const dispatch = useFiltersDispatch();
  const onClick = useCallback(() => {
    dispatch({ type: 'REMOVE_FILTERS_FIELD', field: 'segments', value: segment });
  }, [dispatch, segment]);

  const topic = useMemo(() => data.find((t) => t.id === segment), [data, segment]);

  return (
    <ListItem {...props} onClick={onClick}>
      <div className="flex gap-1 items-center">
        <Tags size={20} strokeWidth={2} />
        <span>
          {topic?.name.charAt(0).toUpperCase() + topic?.name.slice(1)}
        </span>
      </div>
    </ListItem>
  );
};

const LabelListItem = ({
  id, allLabels, isLoadingLabels, type, ...props
}) => {
  const dispatch = useFiltersDispatch();
  const onClick = useCallback(() => {
    dispatch({ type: 'REMOVE_FILTERS_FIELD', field: type === 'exclude' ? 'exclude_labels' : 'labels', value: id });
  }, [dispatch, id]);
  const label = useMemo(() => allLabels.find((l) => l.id === id), [
    allLabels, id,
  ]);

  return (
    <ListItem {...props} onClick={onClick}>
      <div className="flex gap-1 items-center">
        <span className="anticon">
          {
              type === 'exclude' ? <BookmarkX size={16} /> : <Tag size={16} />
          }
        </span>
        <span>
          {
            isLoadingLabels ? (
              <Spin size="small" />
            ) : (
              label?.name
            )
          }
        </span>
      </div>
    </ListItem>
  );
};

const FiltersHorizontalList = ({ showSaveButton = true }) => {
  const { filters } = useFilters();
  const {
    data: allLabels, isLoading: isLoadingLabels,
  } = useLabels();
  const dispatch = useFiltersDispatch();
  const { subtopicId } = useParams();
  const [save, setSave] = useState(false);
  const hasSomeFilters = filters.included_keywords.length
      || filters.excluded_keywords.length
      || filters.sentiment.length
      || filters.custom_fields.length
      || filters.sources.length
      || filters.labels.length
      || filters.exclude_labels.length;
  const canSave = !filters.topic_summary_result_id && !subtopicId && hasSomeFilters;

  return (
    <div className={cn('border-0 border-b border-solid border-[#E5ECF3] flex gap-2 px-7 filters-horizontal-list', {
      'border-y py-3': hasSomeFilters,
    })}
    >
      <div className="flex flex-wrap gap-2 max-h-[100px] overflow-y-auto">
        {
          filters.included_keywords.map((keyword) => (
            <KeywordListItem keyword={keyword} type="included_keywords" />
          ))
        }
        {
          filters.excluded_keywords.map((keyword) => (
            <KeywordListItem keyword={keyword} type="excluded_keywords" />
          ))
        }
        {
          filters.sentiment.map((sentiment) => (
            <SentimentListItem sentiment={sentiment} />
          ))
        }
        {
          filters.custom_fields.map((field) => (
            <CustomFieldListItem field={field} />
          ))
        }
        {
          filters.sources.map((source) => (
            <SourceListItem source={source} />
          ))
        }
        {
          filters.labels.map((id) => (
            <LabelListItem id={id} allLabels={allLabels} isLoadingLabels={isLoadingLabels} />
          ))
        }
        {
          filters.exclude_labels.map((id) => (
            <LabelListItem id={id} allLabels={allLabels} isLoadingLabels={isLoadingLabels} type="exclude" />
          ))
        }
        {
          filters.nps.map((nps) => (
            <NpsListItem nps={nps} />
          ))
        }
        {
          filters.segments.map((segment) => (
            <SegmentListItem segment={segment} />
          ))
         }
      </div>
      {
        hasSomeFilters ? (
          <div className={cn('flex flex-1', {
            'justify-between': canSave,
            'justify-end': !canSave,
          })}
          >
            {
              canSave && showSaveButton ? (
                <Button type="primary" onClick={() => setSave(true)}>Save filters as segment</Button>
              ) : null
            }
            <Button onClick={() => dispatch({ type: 'RESET_FILTERS' })} className="list-item-reset" type="link">Reset</Button>
          </div>
        ) : null
      }
      <SaveModal
        visible={save}
        onCancel={() => setSave(false)}
        closeIcon={<XIcon size={16} />}
        setVisible={setSave}
        destroyOnClose
      />
    </div>
  );
};

export default FiltersHorizontalList;
