import React from 'react';
import Onboarding from './components/Onboarding';
import IntegrationsProvider from './store/IntegrationsProvider';

const OnboardingIndex = (props) => (
  <IntegrationsProvider>
    <Onboarding {...props} />
  </IntegrationsProvider>
);

export default OnboardingIndex;
