import React from 'react';

const Conversations = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} {...props}>
    <path
      style={{
        fill: 'none',
      }}
      d="M0 0h16v16H0z"
    />
    <path
      className="b"
      d="M18 10.875a6.285 6.285 0 0 1-.675 2.85 6.375 6.375 0 0 1-5.7 3.525 6.285 6.285 0 0 1-2.85-.675L4.5 18l1.425-4.275a6.285 6.285 0 0 1-.675-2.85 6.375 6.375 0 0 1 3.525-5.7 6.285 6.285 0 0 1 2.85-.675H12a6.36 6.36 0 0 1 6 6Z"
      transform="translate(-3.271 -3.274)"
      style={{
        stroke: 'currentColor',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: '1.5px',
        fill: 'none',
      }}
    />
  </svg>
);

export default Conversations;
