/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { BarChartOutlined } from '@ant-design/icons';
import { Card, Select, Col } from 'antd';
import SectionHeader from '../SectionHeader';
import ClientHappinessChart from '../ClientHappinesOverview/ClientHappinessChart';
import HappinesScore from '../../common/HappinesScore/HappinesScore';
import { getScore } from '../../../transformers/clients';

const { Option } = Select;

const OverallClientsHappinessSection = ({
  title, client, dashboard, isColleague,
}) => {
  const [scoreData, setScoreData] = useState(client
    ? getScore(client.last7DaysScores) : (dashboard
      ? getScore(dashboard.last7DaysScores) : null));
  const [intervalSelectValue, setIntervalSelectValue] = useState('7days');
  const [happinessScore, setHappinessScore] = useState(0);

  useEffect(() => {
    setHappinessScore(scoreData);
  }, []);

  useEffect(() => {
    if (intervalSelectValue === '7days') {
      setScoreData(client
        ? getScore(client.last7DaysScores) : (dashboard
          ? getScore(dashboard.last7DaysScores) : null));
    } else if (intervalSelectValue === '30days') {
      setScoreData(client
        ? getScore(client.last30DaysScores) : (dashboard
          ? getScore(dashboard.last30DaysScores) : null));
    }
  }, [intervalSelectValue]);

  return (
    <div className="client-happiness dashboard-section">
      <div className="header flex-between">
        <SectionHeader
          icon={<BarChartOutlined />}
          title={title}
        />
        <div className="filter-section">
          <Select
            value={intervalSelectValue}
            onChange={(value) => setIntervalSelectValue(value)}
            style={{ borderRadius: '16px' }}
            className="OverallClientHappiness__Select"
          >
            <Option value="7days">Last 7 days</Option>
            <Option value="30days">Last 30 days</Option>
          </Select>
        </div>
      </div>
      <Card bordered={false}>
        <div className="content">
          <Col xs={24} xl={8}>
            <HappinesScore
              data={happinessScore}
              clientName={client ? client.name : null}
              isColleague={isColleague}
            />
          </Col>
          <Col xs={24} xl={16}>
            <ClientHappinessChart data={scoreData} loading={false} isColleague={isColleague} />
          </Col>
        </div>
      </Card>
    </div>
  );
};

export default OverallClientsHappinessSection;
