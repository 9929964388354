import React, { useEffect } from 'react';
import { Modal } from 'antd';
import { useDispatch } from 'react-redux';
import TopicForm from '@/components/TopicForm/index';
import { ACTION_RESET_TOPIC_STEPS } from './reducer';

const ModalTopicForm = ({
  visible,
  onCancel,
  onOk,
  data,
  subtopic,
  topicName,
  topicId,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.classList[visible ? 'add' : 'remove']('cluster-visible');
  }, [visible]);

  return (
    <Modal
      visible={visible}
      className="cluster-modal body-full-page"
      onCancel={onCancel}
      closeIcon={false}
      footer={false}
      afterClose={() => dispatch({ type: ACTION_RESET_TOPIC_STEPS })}
      destroyOnClose
    >
      <TopicForm
        onCancel={onCancel}
        onOk={onOk}
        data={data}
        subtopic={subtopic}
        topicName={topicName}
        topicId={topicId}
      />
    </Modal>
  );
};

export default ModalTopicForm;
