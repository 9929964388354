import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import AnalyzerAnimation from '../../../components/animations/Analyzer';
import OnboardingProgressSteps from './OnboardingProgressSteps';
import mixpanel, { mixpanelEvents } from '../../../mixpanel';
import OnboardingVideo from './OnboardingVideo';

const OnboardingProgress = ({ setStep: setTopStep }) => {
  const [step, setStep] = useState(1);
  const [progress, setProgress] = useState(0);
  const [small, setSmall] = useState(false);

  const onContinue = () => {
    setTopStep(5);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((currentProgress) => {
        if (currentProgress < 100) {
          return currentProgress + 0.25;
        }
        setStep((currentStep) => {
          if (currentStep >= 2) {
            clearInterval(interval);
          }
          return currentStep + 1;
        });
        return 0;
      });
    }, 300);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    mixpanel.track(mixpanelEvents.STARTED_ANALYSIS);
  }, []);

  useEffect(() => {
    if (step === 1 && progress === 10) {
      setSmall(true);
      setTopStep(3);
    }
  }, [step, progress]);

  return (
    <div className={classNames('onboarding-progress', { 'onboarding-progress-form': small })}>
      <AnalyzerAnimation className="onboarding-analyzer-animation" />
      <h6>Almost ready</h6>
      <h1>Analyzing your data</h1>
      <OnboardingProgressSteps
        step={step}
        progress={progress}
        small={small}
        onContinue={onContinue}
      />
      <OnboardingVideo active={small} />
    </div>
  );
};

export default OnboardingProgress;
