import React, { useRef, useCallback, useState } from 'react';
import {
  Button, Carousel, Card, Empty,
} from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import InteractionCarouselPage from './InteractionCarouselPage';

const InteractionCarousel = ({
  items, onDomain, mobile, isColleague,
}) => {
  const carouselRef = useRef(null);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const setCarouselRef = useCallback((ref) => { carouselRef.current = ref; }, []);
  const onNextPress = useCallback(() => {
    carouselRef.current.next();
  }, []);
  const onPrevPress = useCallback(() => {
    carouselRef.current.prev();
  }, []);
  const carouselAfterChange = useCallback((currentIndex) => {
    setCarouselIndex(currentIndex);
  }, []);
  const page1 = items ? items.slice(0, 3) : [];
  const page2 = items ? items.slice(3, 6) : [];
  const mobilePage = items ? items.slice(0, 4) : [];

  return mobile ? (
    <div className="graphic-container__carousel">
      <InteractionCarouselPage items={mobilePage} onDomain={onDomain} isColleague={isColleague} />
    </div>
  ) : (
    <>
      <div className="graphic-container__carousel">
        {
          page1.length ? (
            <Carousel
              dots={false}
              ref={setCarouselRef}
              afterChange={carouselAfterChange}
            >
              <InteractionCarouselPage
                items={page1}
                onDomain={onDomain}
                isColleague={isColleague}
              />
              <InteractionCarouselPage
                items={page2}
                onDomain={onDomain}
                isColleague={isColleague}
              />
            </Carousel>
          ) : (
            <Card>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
            </Card>
          )
        }
      </div>
      {
        (page1.length && page2.length) ? (
          <div className="graphic-container__carousel_buttons">
            <Button
              disabled={carouselIndex === 0}
              shape="circle"
              icon={<LeftOutlined />}
              size={32}
              style={{ marginRight: '8px' }}
              onClick={onPrevPress}
            />
            <Button
              disabled={carouselIndex === 1}
              shape="circle"
              icon={<RightOutlined />}
              size={32}
              onClick={onNextPress}
            />
          </div>
        ) : null
      }
    </>
  );
};

export default InteractionCarousel;
