import * as React from 'react';

function LogoMantra({ title, titleId, ...props }, svgRef) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width="30"
      height="30"
      ref={svgRef}
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <defs>
        <style>
          {'.logo_prefix__a{fill:#0baa6b}.logo_prefix__b{stroke:#000;stroke-width:.1px}'}
        </style>
      </defs>
      <path
        className="logo_prefix__b"
        d="M9.174 6.171a3.061 3.061 0 113.061-3.061 3.072 3.072 0 01-3.061 3.061zm0-4.522a1.461 1.461 0 101.461 1.461 1.471 1.471 0 00-1.461-1.46zM17.639 14.455a3.189 3.189 0 00-2.564-1.158c-2.04 0-3.943 1.627-5.349 2.84l-.579.5c-.193-.138-.386-.331-.579-.5-1.407-1.186-3.31-2.84-5.346-2.84a3.189 3.189 0 00-2.569 1.158 2.828 2.828 0 00-.524 2.4 4 4 0 002.95 2.868 6.792 6.792 0 001.847.248 7.065 7.065 0 004.219-1.351 7.256 7.256 0 004.219 1.351 6.94 6.94 0 001.847-.248 4 4 0 002.95-2.868 2.778 2.778 0 00-.522-2.4zm-14.09 3.723a2.458 2.458 0 01-1.848-1.681 1.185 1.185 0 01.221-1.049 1.608 1.608 0 011.323-.551c1.434 0 3.088 1.406 4.3 2.454.11.083.193.165.3.248a5.6 5.6 0 01-4.296.579zm13.073-1.681a2.41 2.41 0 01-1.847 1.682 5.641 5.641 0 01-4.274-.579c.11-.083.193-.165.3-.248 1.213-1.02 2.868-2.454 4.3-2.454a1.645 1.645 0 011.324.551 1.375 1.375 0 01.197 1.048zM17.5 9.511a.806.806 0 00-1.075.331 1.609 1.609 0 01-1.489.717 3.676 3.676 0 01-2.123-1.131 5.455 5.455 0 00-3.64-1.682 5.455 5.455 0 00-3.64 1.682 3.6 3.6 0 01-2.123 1.131 1.609 1.609 0 01-1.489-.713.806.806 0 00-1.075-.331.816.816 0 00-.358 1.075 3.251 3.251 0 002.922 1.568 4.937 4.937 0 003.144-1.512 4.217 4.217 0 012.62-1.324 4.083 4.083 0 012.62 1.324 4.937 4.937 0 003.143 1.517 3.251 3.251 0 002.923-1.572.843.843 0 00-.36-1.08z"
      />
    </svg>
  );
}

const ForwardRef = React.forwardRef(LogoMantra);
const MemoForwardRef = React.memo(ForwardRef);
export default MemoForwardRef;
