import React, { useEffect, useState } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import { RightOutlined, PlusCircleOutlined } from '@ant-design/icons';
import {
  message, Spin,
} from 'antd';
import { SentimentRadioGroupDateFilter, useSentimentDateFilterInitialValue } from '@/base-components/sentiment-date-filter';
import TopicModal from '@/base-components/topic-modal';
import { topics } from '@/api';
import SentimentAvg from '../../base-components/sentiment-avg';
import Progress from '../ui/progress';
import Popover from '../../base-components/popover';
import TopicMetric from '../../base-components/topic-metric';

const HotTopicsSection = ({ location: { pathname } }) => {
  const [hotTopics, setHotTopics] = useState({ data: [], loading: false });
  const defaultValue = useSentimentDateFilterInitialValue();
  const [rangeFilter, setRangeFilter] = useState(defaultValue);

  const getHotTopics = async () => {
    setHotTopics({ ...hotTopics, loading: true });
    try {
      const { data } = await topics.getHotTopics(rangeFilter);
      setHotTopics({ ...hotTopics, data, loading: false });
    } catch (err) {
      setHotTopics({ ...hotTopics, loading: false });
      message.error('Failed loading Hot Topics');
    }
  };

  useEffect(() => getHotTopics(), [rangeFilter]);

  const history = useHistory();
  const [isOpen, setOpen] = useState(false);

  const topicModalProps = {
    visible: isOpen,
    onOk: (topic) => {
      setOpen(false);
      history.push(`${pathname.startsWith('/demo') ? '/demo/' : '/'}topic/overview/${topic.id}/${topic.groups[0].id}`);
    },
    onCancel: () => setOpen(false),
  };

  return (
    <>
      <div className="dashboard-conversations">
        <h3 className="dashboard-conversations__title">Topics</h3>
        <span className="conversation-info-dot" />
        <Link to={`${pathname.startsWith('/demo') ? '/demo/' : '/'}topics`} className="dashboard-conversations__link">See All Topics</Link>
        <SentimentRadioGroupDateFilter
          initialValue={defaultValue}
          onChange={(value) => setRangeFilter(value)}
          style={{ marginLeft: 'auto' }}
        />
      </div>
      <div>
        {hotTopics.loading
          ? (
            <div className="topic-dashboard-loading">
              <Spin size="large" />
            </div>
          )
          : (
            <>
              <div className="hot-topics-container">
                {hotTopics.data.map((item) => (
                  <div key={`${item.id}-${item.groups[0].id}`} className="hot-topic">
                    <Link to={`${pathname.startsWith('/demo') ? '/demo/' : '/'}topic/overview/${item.id}/${item.groups[0].id}`} className="hot-topic__title">
                      <span className="hot-topic__topic-group">{item.groups[0].name}</span>
                      <RightOutlined className="hot-topic__arrow" />
                      <span className="hot-topic__topic">{item.name}</span>
                    </Link>
                    <div className="hot-topic__metrics">
                      <TopicMetric
                        name="count"
                        value={item.count.value}
                        trend={item.count.trend}
                        tooltip={`${item.count.value} conversations over the selected period`}
                      />
                      <span className="conversation-info-dot" />
                      <TopicMetric
                        name="ratio"
                        value={`${Math.ceil(item.ratio.value * 100)}%`}
                        trend={item.ratio.trend}
                        tooltip={`appears in ${Math.ceil(item.ratio.value * 100)}% of total conversations in the selected period`}
                      />
                      <span className="conversation-info-dot" />
                      <TopicMetric.MRR
                        name="MRR"
                        value={item.mrr.value}
                        trend={item.mrr.trend}
                        tooltip={`$${Math.round(item.mrr.value)} estimated monthly revenue by users discussing this topic`}
                      />
                    </div>
                    <Popover content={(
                      <div>
                        <p style={{ margin: 0, fontSize: 12 }}>
                          Sentiment over the selected period
                        </p>
                        <div style={{ display: 'flex' }}>
                          <SentimentAvg
                            sentiment="negative"
                            value={Math.round(item.sentiment.negative * 100)}
                            styles={{ root: { margin: 0 }, avg: { margin: 0 }, icon: { margin: '0px 6px 0px 2px' } }}
                          />
                          <SentimentAvg
                            sentiment="positive"
                            value={Math.round(item.sentiment.positive * 100)}
                            styles={{ root: { margin: 0 }, avg: { margin: 0 }, icon: { margin: '0px 6px 0px 2px' } }}
                          />
                          <SentimentAvg
                            sentiment="neutral"
                            // eslint-disable-next-line max-len
                            value={Math.round((1 - (item.sentiment.positive + item.sentiment.negative)) * 100)}
                            styles={{ root: { margin: 0 }, avg: { margin: 0 }, icon: { margin: '0px 6px 0px 2px' } }}
                          />
                        </div>
                      </div>
                    )}
                    >
                      <div className="hot-topic__progress">
                        <Progress.Sentiment
                          positive={item.sentiment.positive}
                          negative={item.sentiment.negative}
                        />
                      </div>
                    </Popover>
                  </div>
                ))}
                {hotTopics.data.length % 3 !== 0 && (
                  <div
                    className="hot-topic hot-topic--add"
                    onClick={() => setOpen(true)}
                    onKeyDown={() => setOpen(true)}
                    role="button"
                    tabIndex={0}
                  >
                    <PlusCircleOutlined className="icon" />
                    <p className="text">Create segment</p>
                  </div>
                )}
                {hotTopics.data.length % 3 === 1 && (
                  <div
                    className="hot-topic hot-topic--add"
                    onClick={() => setOpen(true)}
                    onKeyDown={() => setOpen(true)}
                    role="button"
                    tabIndex={0}
                  >
                    <PlusCircleOutlined className="icon" />
                    <p className="text">Create segment</p>
                  </div>
                )}
              </div>
            </>
          )}
      </div>
      <TopicModal {...topicModalProps} />
    </>
  );
};

export default withRouter(HotTopicsSection);
