import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '@/components/Layout';
import { useParams, useHistory } from 'react-router-dom';
import { isValidParam } from '@/helpers/validators';
import { Spin } from 'antd';
import { SentimentDateFilter } from '@/components/ui/sentiment-date-filter';
import { PeopleUserContainer } from '@/components/people-user';
import { FiltersProvider, initialState } from '@/components/filter/context';
import usePeople from '@/components/people-user/hooks/usePeople';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';

export default function PeopleUserPage() {
  const { id } = useParams();
  const defaultDate = useDefaultDateStore((state) => state.defaultDate);
  const history = useHistory();

  const user = usePeople(id, {
    start: defaultDate.start,
    end: defaultDate.end,
  });

  useEffect(() => {
    if (!isValidParam(id)) {
      history.push('/404');
    }
  }, []);

  if (user.isLoading) {
    return (
      <Layout disableMantra>
        <Helmet titleTemplate="%s - ClientZen" title="People" />

        <div className="mx-auto max-w-[1200px] text-center">
          <Spin size="large" />
        </div>
      </Layout>
    );
  }

  if (user.isError) {
    history.push('/404');
  }

  return (
    <FiltersProvider value={{
      ...initialState,
      filters: {
        ...initialState.filters,
        client_id: id,
      },
    }}
    >
      <Layout title={user?.data?.name} disableMantra rightHeader={(<SentimentDateFilter />)}>
        <Helmet titleTemplate="%s - ClientZen" title="People" />

        <PeopleUserContainer />
      </Layout>
    </FiltersProvider>
  );
}
