import React, { useCallback, useEffect } from 'react';
import { useChainlitStore } from '@/store/zustand/useChainlitStore';
import {
  useChatData, useChatInteract, useChatSession,
} from '@chainlit/react-client';
import { copilot } from '@/api';
import { connect as reduxConnect } from 'react-redux';
import ChainlitModal from './ChainlitModal';

const ChainlitProvider = ({ user, children }) => {
  const { connect, disconnect } = useChatSession();
  const { loading } = useChatData();
  const { clear } = useChatInteract();
  const {
    expanded, setExpanded, expandedMessage, setToken,
  } = useChainlitStore((state) => state);
  const closeModal = useCallback(() => setExpanded(false), []);

  useEffect(() => {
    if (!user) {
      clear();
      disconnect();
    } else {
      copilot.token().then(({ data }) => {
        setToken(data.data.access_token);
        connect({
          userEnv: {
          },
          accessToken: `Bearer ${data.data.access_token}`,
        });
      }).catch(() => {
        /**
         * do nothing
         */
      });
    }
  }, [user]);

  return (
    <>
      {children}
      <ChainlitModal visible={expanded} close={closeModal} expandedMessage={expandedMessage} loading={loading} />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default reduxConnect(mapStateToProps)(ChainlitProvider);
