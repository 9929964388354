import axios from './axios';
import auth from './auth';

const getExcluded = () => axios.get('/api/settings/all-excluded-clients', {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response.data).catch((e) => Promise.reject(e));
const exclude = (id) => axios.post('/api/settings/clients-exclusions', { search_string: id }, {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response).catch((e) => Promise.reject(e));
const removeExclusion = (id) => axios.delete(`/api/settings/excluded-client/${id}`, {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response).catch((e) => Promise.reject(e));

const getExcludedKeywords = () => axios.get('/api/settings/excluded-keywords', {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response.data).catch((e) => Promise.reject(e));

const suggestExcludedKeywords = () => axios.get('/api/settings/suggest-excluded-keywords', {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response.data).catch((e) => Promise.reject(e));

const getExcludedPartialKeywords = () => axios.get('/api/settings/excluded-partial-keywords', {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response.data).catch((e) => Promise.reject(e));

const replaceExcludedKeywords = (values) => axios.post('/api/settings/replace-excluded-keywords', values, {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response).catch((e) => Promise.reject(e));

const replaceExcludedPartialKeywords = (values) => axios.post('/api/settings/replace-excluded-partial-keywords', values, {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response).catch((e) => Promise.reject(e));

const createExcludedKeyword = (values) => axios.post('/api/settings/excluded-keywords', values, {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response).catch((e) => Promise.reject(e));

const deleteExcludedKeyword = (id) => axios.delete(`/api/settings/excluded-keywords/${id}`, {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response).catch((e) => Promise.reject(e));

const getExcludedKeywordsPreview = (values) => axios.post('/api/settings/excluded-keywords/preview', values, {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response.data).catch((e) => Promise.reject(e));

const getExcludedLabels = () => axios.get('/api/settings/excluded-labels', {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response.data).catch((e) => Promise.reject(e));

const replaceExcludedLabels = (values) => axios.post('/api/settings/replace-excluded-labels', values, {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response).catch((e) => Promise.reject(e));

const settings = {
  getExcluded,
  exclude,
  removeExclusion,
  getExcludedKeywords,
  suggestExcludedKeywords,
  getExcludedPartialKeywords,
  replaceExcludedKeywords,
  replaceExcludedPartialKeywords,
  createExcludedKeyword,
  deleteExcludedKeyword,
  getExcludedKeywordsPreview,
  getExcludedLabels,
  replaceExcludedLabels,
};

export default settings;
