import React, {
  useReducer, useEffect, useCallback, useMemo,
} from 'react';
import { Spin, Tag, message } from 'antd';
import moment from 'moment';
import classNames from 'classnames';
import {
  ResponsiveContainer,
  ComposedChart,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
  Bar,
  Line,
  XAxis,
  YAxis,
  Dot,
  Cell,
} from 'recharts';
import { topics } from '@/api';
import config from '../../../config';
import {
  initialState,
  sentimentChartReducer,
  SET_LOADING_ACTION,
  SET_DATA,
  SET_HOVERED,
  SET_SELECTED,
} from '../sentimentChartReducer';
import SentimentTooltipContent from './SentimentTooltipContent';
import SentimentLabel from './SentimentLabel';

const BAR_OPACITY = 0.5;
const BAR_OPACITY_HOVER = 0.7;
const BAR_OPACITY_SELECTED = 1;

const getEntryOpacity = (entry, hasInteractions) => {
  if (entry.hide) {
    return 0;
  }

  if (hasInteractions) {
    if (entry.selected) {
      return BAR_OPACITY_SELECTED;
    }

    if (entry.hovered) {
      return BAR_OPACITY_HOVER;
    }
  }

  return BAR_OPACITY;
};

const SentimentChart = ({ filters, hasInteractions, onChange }) => {
  const [{ data: { chart, annotations }, loading },
    dispatch] = useReducer(sentimentChartReducer, initialState);

  const selectedItems = useMemo(() => chart.filter(({ selected }) => selected), [chart]);

  const onMouseEnter = useCallback((item) => () => {
    dispatch({ type: SET_HOVERED, payload: { item, hovered: true } });
  }, []);

  const onMouseLeave = useCallback((item) => () => {
    dispatch({ type: SET_HOVERED, payload: { item, hovered: false } });
  }, []);

  const onClick = useCallback((item) => () => {
    dispatch({ type: SET_SELECTED, payload: { item } });

    const payload = [...chart.filter(({ selected }) => selected)];

    if (!item.selected) {
      payload.push(item);
    }

    onChange?.(payload);
  }, []);

  useEffect(() => {
    dispatch({ type: SET_LOADING_ACTION, payload: true });

    topics.getTopicSentiment(filters)
      .then(({ data }) => {
        dispatch({ type: SET_DATA, payload: data });
      })
      .catch(() => {
        dispatch({ type: SET_LOADING_ACTION, payload: false });
        message.error('Sentiment Chart Error');
      });
  }, [filters]);

  const bars = [
    { type: 'negative', color: '#FFA39E' },
    { type: 'neutral', color: '#F0F0F0' },
    { type: 'positive', color: '#6DCCA6' },
  ];

  const xAxisProps = {
    dataKey: 'date',
    scale: 'band',
    axisLine: false,
    tickLine: false,
    dy: 12,
  };

  const yAxisProps = {
    allowDecimals: false,
    allowDataOverflow: false,
    domain: [0, 'dataMax'],
    dataKey: 'total',
    type: 'number',
    axisLine: false,
    tickLine: false,
    dx: -24,
  };

  const cartesianGridProps = {
    vertical: false,
    strokeDasharray: '3 3',
    stroke: '#F0F0F0',
  };

  const lineProps = {
    dataKey: 'total',
    type: 'line',
    stroke: '#373737',
    fill: '#373737',
    strokeDasharray: '2 2',
    dot: ({ payload, ...rest }) => (!payload.hide && <Dot payload={payload} {...rest} />),
  };

  const showAnnotation = chart?.length < 95;

  const tooltipProps = {
    active: true,
    cursor: false,
    isAnimationActive: false,
    position: { x: 'auto', y: -24 },
    allowEscapeViewBox: { x: false, y: true },
    content: <SentimentTooltipContent showAnnotation={showAnnotation} />,
  };

  if (loading) {
    return (
      <div className="sentiment-chart">
        <div className="sentiment-chart-loading">
          <Spin size="large" />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames('sentiment-chart-container', { 'sentiment-chart-container-with-dates': selectedItems.length })}>
      <div className={classNames('sentiment-chart', { 'sentiment-chart-interactable': hasInteractions })}>
        <ResponsiveContainer width="100%">
          <ComposedChart
            data={chart}
            margin={{
              top: annotations.length ? 78 : 44, right: 0, left: 0, bottom: 24,
            }}
          >
            <XAxis {...xAxisProps} />

            <YAxis {...yAxisProps} />

            <CartesianGrid {...cartesianGridProps} />

            {bars.map(({ type, color }) => (
              <Bar
                key={`bar-${type}`}
                dataKey={type}
                stackId={1}
                fill={color}
                maxBarSize={58}
              >
                {chart.map((entry) => (
                  <Cell
                    key={`cell-${type}-${entry.point}`}
                    radius={entry.radius[type]}
                    opacity={getEntryOpacity(entry, hasInteractions)}
                    onMouseEnter={hasInteractions ? onMouseEnter(entry) : null}
                    onMouseLeave={hasInteractions ? onMouseLeave(entry) : null}
                    onClick={hasInteractions ? onClick(entry) : null}
                  />
                ))}
              </Bar>
            ))}

            <Line {...lineProps} />

            {annotations.map(({ date, label }) => (
              <ReferenceLine
                key={`annotation-${date}`}
                x={chart.find(({ point }) => point === date).date}
                stroke="#373737"
                strokeDasharray="5 5"
                label={(
                  <SentimentLabel
                    value={showAnnotation ? label.substring(0, config.annotationMaxLength) : ''}
                    fill="#373737"
                    position="top"
                    offset={18}
                  />
              )}
              />
            ))}

            <Tooltip {...tooltipProps} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div className="sentiment-chart-selected-dates">
        {selectedItems.map((entry) => (
          <Tag
            key={entry.point}
            closable
            onClose={onClick(entry)}
          >
            {moment(entry.point).format('MMM DD')}
          </Tag>
        ))}
      </div>
    </div>
  );
};

export default SentimentChart;
