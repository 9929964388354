import { createStore } from 'zustand';
import { devtools } from 'zustand/middleware';

export const defaultProps = {
  search: '',
  data: [],
  selectedCategories: [],
  selectedTopics: [],
  selectedSubtopics: [],
};

const actions = (set) => ({
  setSearch: (search) => set((old) => ({ ...old, search })),
  setData: (data) => set((old) => ({ ...old, data })),
  setTopics: (id, topics) => set((old) => ({
    ...old,
    data: old.data.map((category) => {
      if (category.id === id) {
        return { ...category, children: topics };
      }
      return category;
    }),
  })),
  setSubtopics: (groupId, topicId, subtopics) => set((old) => ({
    ...old,
    data: old.data.map((category) => {
      if (category.id === groupId) {
        return {
          ...category,
          children: category.children?.map((topic) => {
            if (topic.id === topicId) {
              return { ...topic, children: subtopics };
            }
            return topic;
          }),
        };
      }
      return category;
    }),
  })),

  setState: (state) => set(() => ({ ...state })),

  addSelected: (id, type) => set((old) => {
    const name = type[0].toUpperCase() + type.slice(1);
    const key = `selected${name}`;
    return { ...old, [key]: [...old[key], id] };
  }),

  removeSelected: (id, type) => set((old) => {
    const name = type[0].toUpperCase() + type.slice(1);
    const key = `selected${name}`;
    return { ...old, [key]: old[key].filter((item) => item !== id) };
  }),

  resetSelected: (type) => set((old) => {
    if (!type) {
      return {
        ...old, selectedCategories: [], selectedTopics: [], selectedSubtopics: [],
      };
    }

    const name = type[0].toUpperCase() + type.slice(1);
    const key = `selected${name}`;
    return { ...old, [key]: [] };
  }),
});

const createTaxonomyTableStore = (initProps) => createStore(devtools((set) => ({
  ...defaultProps,
  ...initProps,
  ...actions(set),
}), {
  name: 'useTaxonomyTableStore',
}));

export default createTaxonomyTableStore;
