import React from 'react';
import { Check, ShieldCheck } from 'lucide-react';

export function SecurityBanner() {
  return (
    <div className="mt-10 flex items-start">
      <div className="bg-[#EFF4F8] rounded-full flex p-5">
        <ShieldCheck className="h-10 w-10" color="#0EAA6B" strokeWidth={1} />
      </div>
      <div className="ml-6">
        <h2 className="m-0 text-[#44596C] text-sm font-[Gordita-Medium]">
          Enterprise level security
        </h2>
        <ul className="p-0 list-none mt-2.5 text-[#75899B] font-[Gordita-Regular] text-sm space-y-2 [&_*]:flex [&_*]:items-center [&_*]:gap-3 [&_*]:sm:gap-1.5">
          <li>
            <Check className="h-4 w-4" />
            Data encryption at rest and transit.
          </li>
          <li>
            <Check className="h-4 w-4" />
            Regular vulnerability scans
          </li>
          <li>
            <Check className="h-4 w-4" />
            GDPR compliant
          </li>
        </ul>
      </div>
    </div>
  );
}
