import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import OnboardingBackground from '../../base-components/integrations/components/OnboardingBackground';
import OnboardingSteps from '../../base-components/integrations/components/OnboardingSteps';
import OnboardingProgress from '../../base-components/integrations/components/OnboardingProgress';
import OnboardingTopic from '../../base-components/integrations/components/OnboardingTopic';

const AddFirstTopic = () => {
  const [step, setStep] = useState(2);
  return (
    <>
      <Helmet>
        <title>ClientZen</title>
      </Helmet>
      <OnboardingBackground>
        <div className={classNames('new-onboarding-wrapper', { 'new-onboarding-finish-wrapper': step === 5 })}>
          <div className="new-onboarding">
            <OnboardingSteps
              current={step}
            />
            {
            step === 5 ? (
              <OnboardingTopic />
            ) : (
              <OnboardingProgress setStep={setStep} />
            )
          }
          </div>
        </div>
      </OnboardingBackground>
    </>
  );
};

export default AddFirstTopic;
