import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Menu, Divider } from 'antd';
import { DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as BroadMatch } from './svgs/broad-match.svg';
import { ReactComponent as ExactMatch } from './svgs/exact-match.svg';
import TagSelect from '../../base-components/topic-modal/components/TagSelect';
import Tag from './Tag';
import loadPreviewHandler from './loadPreviewHandler';
import { ACTION_UPDATE_TOPIC_STEPS_STATE } from './reducer';

const TopicFormTagSelect = ({
  visible, hasHighlight, initialValue, ...props
}) => {
  const {
    includedKeywords = [],
    excludedKeywords = [],
    interval,
  } = useSelector((state) => state.topicForm);
  const dispatch = useDispatch();
  const loadPreview = loadPreviewHandler(dispatch);
  const [currentHighlight, setCurrentHighlight] = useState(null);
  const selectRef = useRef(null);
  const onRemove = useCallback((option) => {
    selectRef.current.deselectItem(option);
  }, []);
  const onReplace = useCallback((currentSuffix, option, newSuffix = null) => {
    if (currentSuffix !== newSuffix) {
      const [value] = option.split('::');
      selectRef.current.replaceItem(option, `${value}${newSuffix ? `::${newSuffix}` : ''}`);
    }
  }, []);
  const onSetHighlight = useCallback((option) => {
    if (hasHighlight) {
      selectRef.current.setHighlighted(option)();
      const actual = currentHighlight ? null : option;

      setCurrentHighlight(actual);
      loadPreview(includedKeywords, excludedKeywords, actual || '', interval);
      dispatch({
        type: ACTION_UPDATE_TOPIC_STEPS_STATE,
        payload: {
          highlighted: actual || '',
        },
      });
    }
  }, [currentHighlight]);
  const menu = useCallback((value, suffix) => (
    <Menu className="topic-form-tag-select-menu">
      <Menu.Item
        key="broad-match"
        icon={<span className="anticon anticon-option"><BroadMatch /></span>}
        onClick={() => onReplace(suffix, value)}
      >
        Broad match
        {
          !suffix && (
            <span className="checked-mark">
              <CheckOutlined />
            </span>
          )
        }
      </Menu.Item>
      <Menu.Item
        key="exact-match"
        icon={<span className="anticon anticon-option"><ExactMatch /></span>}
        onClick={() => onReplace(suffix, value, 'exact')}
      >
        Exact match
        {
          suffix === 'exact' && (
            <span className="checked-mark">
              <CheckOutlined />
            </span>
          )
        }
      </Menu.Item>
      <Divider />
      <Menu.Item
        key="delete"
        icon={<DeleteOutlined />}
        onClick={() => onRemove(value)}
      >
        Remove
      </Menu.Item>
    </Menu>
  ), [onRemove, onReplace]);
  const tagRender = useCallback(({
    label, value, closable, ...tagProps
  }) => {
    const [, suffix] = value.split('::');
    const [preparedLabel] = label.split('::');
    const disabled = currentHighlight && (currentHighlight !== value);
    return (
      <Tag
        onClick={() => onSetHighlight(value)}
        label={preparedLabel}
        overlay={menu(value, suffix)}
        isNotHighlighted={disabled}
        className={classNames({
          'is-clickable': hasHighlight,
          'is-highlighted': currentHighlight === value,
          'is-not-highlighted': disabled,
        })}
        {...tagProps}
      />
    );
  }, [menu, hasHighlight, currentHighlight]);

  useEffect(() => {
    if (!visible && selectRef.current) {
      selectRef.current.resetItems();
    }
  }, [visible]);

  useEffect(() => {
    if (initialValue && initialValue.length) {
      initialValue.forEach((item) => {
        selectRef.current.selectItem(item);
      });
    }
  }, []);

  return (
    <TagSelect {...props} tagRender={tagRender} ref={selectRef} className="topic-form-tag-select" />
  );
};

export default TopicFormTagSelect;
