/* eslint-disable no-nested-ternary */
import React from 'react';
import { Button } from 'antd';
import classNames from 'classnames';

const OnboardingProgressSteps = ({
  step, progress, small, onContinue,
}) => (
  <div className="onboarding-progress-container">
    <div className={classNames('onboarding-progress-wrapper', { 'small-size': small })}>
      {
        step >= 3 ? (
          <div className="onboarding-progress-complete">
            <Button type="primary" size="large" onClick={onContinue}>Continue</Button>
          </div>
        ) : (
          <>
            <div className="onboarding-still-working">
              Still analyzing data
            </div>
            <div className="onboarding-progress-steps-wrapper">
              <div className="onboarding-progress-steps">
                <div className="onboarding-progress-step" />
                <div className="onboarding-progress-line">
                  <div className="onboarding-progress-line-actual" />
                </div>
                <div className="onboarding-progress-step" />
                <div className="onboarding-progress-line">
                  <div className="onboarding-progress-line-actual" />
                </div>
                <div className="onboarding-progress-step" />
              </div>
              <div className="onboarding-progress-steps active-set">
                <div className={classNames(
                  'onboarding-progress-step', { active: step >= 1 },
                )}
                >
                  <div className="onboarding-progress-step-outline" />
                </div>
                <div className="onboarding-progress-line">
                  <div
                    className="onboarding-progress-line-actual"
                    style={{ width: `${step === 1 ? progress : (step > 1 ? 100 : 0)}%` }}
                  />
                </div>
                <div className={classNames(
                  'onboarding-progress-step', { active: step >= 2 },
                )}
                >
                  <div className="onboarding-progress-step-outline" />
                </div>
                <div className="onboarding-progress-line">
                  <div
                    className="onboarding-progress-line-actual"
                    style={{ width: `${step === 2 ? progress : (step > 2 ? 100 : 0)}%` }}
                  />
                </div>
                <div className={classNames(
                  'onboarding-progress-step', { active: step >= 3 },
                )}
                >
                  <div className="onboarding-progress-step-outline" />
                </div>
              </div>
            </div>
            <div className="onboarding-progress-texts">
              <div className={classNames(
                'onboarding-progress-text',
                'align-left',
                { active: step >= 1 },
              )}
              >
                Gathering conversations
              </div>
              <div className={classNames(
                'onboarding-progress-text',
                { active: step >= 2 },
              )}
              >
                Tagging and Sentiment Analysis
              </div>
              <div className={classNames(
                'onboarding-progress-text',
                'align-right',
                { active: step >= 3 },
              )}
              >
                Insights Discovery
              </div>
            </div>
          </>
        )
      }
    </div>
  </div>
);
export default OnboardingProgressSteps;
