export const [
  SET_TOPIC_ACTION,
  SET_SUBTOPIC_ACTION,
  SET_MODAL_ACTION,
  SET_LOADING_ACTION,
] = [
  'SET_TOPIC_ACTION',
  'SET_SUBTOPIC_ACTION',
  'SET_MODAL_ACTION',
  'SET_LOADING_ACTION',
];

export const modals = Object.freeze({
  NONE: 'NONE',
  UPDATE_TOPIC: 'UPDATE_TOPIC',
  CREATE_ANNOTATION: 'CREATE_ANNOTATION',
  LIST_ANNOTATIONS: 'LIST_ANNOTATIONS',
});

export const tabs = Object.freeze({
  PEOPLE: 'PEOPLE',
  CONVERSATIONS: 'CONVERSATIONS',
});

export const initialState = {
  topic: null,
  subtopic: null,
  group: null,
  active: null,

  loading: true,
  shouldUpdateMetrics: false,
  reload: { data: false, page: false },

  modal: modals.NONE,
  tab: tabs.CONVERSATIONS,
};

export const topicDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODAL_ACTION: {
      const { modal, pageReload = false, dataReload = false } = action.payload;
      return {
        ...state,
        modal,
        reload: {
          ...state.reload,
          data: dataReload ? !state.reload.data : state.reload.data,
          page: pageReload ? !state.reload.page : state.reload.page,
        },
      };
    }
    case SET_TOPIC_ACTION: {
      const { topic, group } = action.payload;
      return {
        ...state,
        topic,
        group,
      };
    }
    case SET_SUBTOPIC_ACTION: {
      const { subtopic } = action.payload;
      return {
        ...state,
        subtopic,
      };
    }
    case SET_LOADING_ACTION: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    default:
      return state;
  }
};
