import React, { createContext } from 'react';

/** Onboarding context */
export const OnboardingContext = createContext();

export function OnboardingProvider({ value, children }) {
  return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>;
}

/** Platform context */
export const PlatformContext = createContext();

export function PlatformProvider({ value, children }) {
  return <PlatformContext.Provider value={value}>{children}</PlatformContext.Provider>;
}
