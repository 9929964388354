import React from 'react';
import { OnboardingLayout } from '@/components/new-onboarding/layout';
import { OnboardingContainer } from '@/components/new-onboarding';

export default function OnboardingPage() {
  return (
    <OnboardingLayout>
      <OnboardingLayout.Header />
      <OnboardingLayout.Content>
        <OnboardingContainer />
      </OnboardingLayout.Content>
    </OnboardingLayout>
  );
}
