import React, { useState, useEffect } from 'react';
import { TeamOutlined } from '@ant-design/icons';
import AddAllButton from './RecentInteractions/AddAllButton';
import ContactSelect from './ContactSelect';
import InteractionCard from './InteractionCard';

const SearchContacts = ({
  persons,
  addPerson,
  removePerson,
  onDomain,
  tags,
  isColleague,
  hasAddAllButton,
  recentInteractionAddAll,
}) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState();

  useEffect(() => {
    if (searchValue) {
      setItems([]);
      setLoading(false);
    }
  }, [searchValue]);

  return (
    <>
      <div className="graphic-container__heading">
        <TeamOutlined />
        <span className="text-sm text-[#595959]">Add emails to invite team members by email</span>
      </div>
      <div className="graphic-container__clients">
        <div style={{ display: 'flex' }}>
          <ContactSelect
            loading={loading}
            items={items}
            selectedItems={persons}
            onSelect={addPerson}
            onDeselect={removePerson}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            tags={tags}
            onDomain={onDomain}
          />
          {
            hasAddAllButton ? (
              <AddAllButton recentInteractionAddAll={recentInteractionAddAll} />
            ) : null
          }
        </div>

        {
          !tags ? persons.filter((person) => !person.recent).map((person) => (
            <InteractionCard
              key={person.email}
              {...person}
              onDomain={onDomain}
              isColleague={isColleague}
            />
          )) : null
        }
      </div>
    </>
  );
};

export default SearchContacts;
