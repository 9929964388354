import { useContext } from 'react';
import { IntegrationsContext } from './IntegrationsProvider';

const useIntegrationsStore = () => {
  const { state, actions } = useContext(IntegrationsContext);

  return { state, actions };
};

export default useIntegrationsStore;
