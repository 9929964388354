import React from 'react';
import { Spin } from 'antd';
import useCsvImport from './hooks/useCsvImport';

const CsvProcessor = ({
  file, mappings, onComplete, type,
}) => {
  const [imported] = useCsvImport(file, mappings, onComplete, type);
  return (
    <div className="text-center m-8">
      <Spin size="large" />
      <div className="mt-1 px-2 font-[Gordita-Regular]">
        Records imported:
        {' '}
        {imported}
      </div>
    </div>
  );
};

export default CsvProcessor;
