import React, { useMemo } from 'react';
import { Spin, Table } from 'antd';
import useCsvFile from './hooks/useCsvFile';
import { initialExpectedColumns } from './CsvMapper';

const getTitle = (id) => {
  const expected = initialExpectedColumns.find(
    (column) => column.id === id,
  );
  if (expected) {
    return expected.label;
  }

  return id;
};

const CsvPreview = ({
  file, mappings, limit,
}) => {
  const { loading, rows } = useCsvFile(file, limit);
  const columns = useMemo(() => Object.keys(mappings).map((column) => ({
    title: getTitle(mappings[column]),
    dataIndex: column,
    key: column,
  })), [mappings]);

  if (loading) {
    return (
      <div className="text-center m-8">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div>
      {(limit) ? `This is just a preview - it is limited to maximum ${limit} rows.` : null}
      <Table className="mt-10 font-[Gordita-Regular] csv-importer-table" dataSource={rows} columns={columns} bordered />
    </div>
  );
};

export default CsvPreview;
