import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  MutationCache, QueryCache, QueryClient, QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { withScope, captureException } from '@sentry/react';
import PosthogProvider from '@/components/posthog/PosthogProvider';
import ChainlitProvider from '@/components/chainlit/ChainlitProvider';
import MixpanelProvider from './components/mixpanel/MixpanelProvider';
import UserSubscriptionProvider from './components/billing/UserSubscriptionProvider';
import UserInfoProvider from './components/auth/UserInfoProvider';
import InsightGlobalModal from './components/insights/InsightGlobalModal';
import AppWrapper from './AppWrapper';
import { Routes } from './routes';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      withScope((scope) => {
        scope.setContext('query', { queryHash: query.queryHash });
        scope.setFingerprint([query.queryHash.replaceAll(/[0-9]/g, '0')]);
        captureException(error);
      });
    },
  }),
  mutationCache: new MutationCache({
    onError: (err, _variables, _context, mutation) => {
      withScope((scope) => {
        scope.setContext('mutation', {
          mutationId: mutation.mutationId,
          variables: mutation.state.variables,
        });
        if (mutation.options.mutationKey) {
          scope.setFingerprint(
            // Duplicate to prevent modification
            Array.from(mutation.options.mutationKey),
          );
        }
        captureException(err);
      });
    },
  }),
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <UserInfoProvider>
        <PosthogProvider>
          <MixpanelProvider>
            <UserSubscriptionProvider>
              <ChainlitProvider>
                <Router>
                  <AppWrapper>
                    <Routes />
                  </AppWrapper>
                </Router>
              </ChainlitProvider>
              <InsightGlobalModal />
            </UserSubscriptionProvider>
          </MixpanelProvider>
        </PosthogProvider>
      </UserInfoProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
