import React, { useMemo } from 'react';
import { Tag as AntdTag } from 'antd';
import classNames from 'classnames';

const colors = [
  { background: '#AA83D0', color: '#fff' },
  { background: '#839ED0', color: '#fff' },
  { background: '#78B6BA', color: '#fff' },
  { background: '#E3EBF6', color: '#262626' },
];

const getRandomColor = () => colors[Math.floor(Math.random() * colors.length)];

const Tag = (props) => {
  const {
    children,
    backgroundColor,
    color,
    className,
    ...rest
  } = props;
  const colorToSet = useMemo(
    () => (
      (color || backgroundColor)
        ? { background: backgroundColor || color, color: backgroundColor ? color : '#fff' }
        : getRandomColor()), [color, backgroundColor],
  );
  return (
    <AntdTag
      className={classNames(className, 'cz-tag')}
      color={colorToSet.background}
      style={{ color: colorToSet.color }}
      {...rest}
    >
      {children}
    </AntdTag>
  );
};

export default Tag;
