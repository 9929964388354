import React, { useMemo } from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Text,
  XAxis,
  YAxis,
} from 'recharts';
import { List } from 'antd';
import moment from 'moment';
import Card from '@/components/card';
import { cn } from '@/helpers/util';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FrownIcon } from 'lucide-react';
import SegmentLine from '../ui/segment/SegmentLine';

function SentimentChart({ data }) {
  function transformData(date) {
    return date.map(({ name, ...rest }) => ({
      name: moment(name).format('DD MMM'),
      ...rest,
    }));
  }

  return (
    <div className="sentiment-drivers-chart">
      <ResponsiveContainer height={250}>
        <LineChart
          data={transformData(data)}
          margin={{
            left: -18,
            bottom: 12,
            right: 8,
          }}
        >
          <Legend
            align="left"
            formatter={(value) => (
              <span className="text-[#202324] !font-[Gordita-Medium]">{value}</span>
            )}
            verticalAlign="top"
            iconType="plainline"
            wrapperStyle={{ top: -30, left: '0.7%' }}
          />
          <CartesianGrid vertical={false} stroke="#EFF4F8" strokeWidth={1} />
          <XAxis
            stroke="#A9BCCF"
            strokeWidth={1}
            dataKey="name"
            dy={12}
            tick={(props) => {
              const { payload: { value } } = props;

              return (
                <Text {...props} className={cn('[&>tspan]:fill-[#87969D]', props.className)} fill="#87969D">{value}</Text>
              );
            }}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            dx={-10}
            tick={(props) => {
              const { payload: { value } } = props;

              let formattedValue = value;
              if (formattedValue >= 1000) {
                formattedValue = `${(formattedValue / 1000).toFixed(1)}K`;
              }

              return (
                <Text {...props} className={cn('[&>tspan]:fill-[#87969D]', props.className)} fill="#87969D">{formattedValue}</Text>
              );
            }}
          />
          <Line
            id="nsd"
            dataKey="nsd"
            name="Negative Sentiment"
            stroke="#F17E99"
            strokeWidth={2}
            legendType="line"
            dot={false}
          />
          <Line
            id="churn"
            dataKey="churn"
            name="Predicted churn"
            stroke="#BB6FD5"
            strokeWidth={2}
            legendType="line"
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

function NegativeLine({ data, maxValue }) {
  const history = useHistory();

  const value = useMemo(() => {
    if (!data) return 0;

    const { count } = data;
    if (count >= 1000) {
      return `${(count / 1000).toFixed(1)}K`;
    }
    return count;
  }, [data]);

  const width = useMemo(() => {
    if (!data) return 0;
    return data.count !== '0' ? Math.ceil((data.count / maxValue) * 100) : Math.ceil((0.1 / maxValue) * 100);
  }, [data.count]);

  const trend = useMemo(() => (data.previous_count > data.count ? 'ascending' : 'descending'), []);

  const percent = useMemo(() => {
    if (!data) return 0;

    const count = Math.abs(data.previous_count - data.count);
    const procent = (count / data.previous_count) * 100;
    if (procent > 100) return 100;
    if (Number.isNaN(procent)) return 0;
    return procent.toFixed(0);
  }, [data]);

  const handleClick = () => {
    history.push(`/topic/overview/${data.id}/${data.group_id}`);
  };

  return (
    <SegmentLine
      name={data.name}
      icon={<FrownIcon color="#F17D99" size={20} className="mr-2" fill="#FAE7EB" />}
      width={width}
      percent={percent}
      value={value}
      trend={trend}
      onClick={handleClick}
      colorLineClassName="bg-[#FFEBED] group-hover:bg-[#FFEBED]"
    />
  );
}

function TopNegativeDriversCard({ top, className }) {
  return (
    <Card className={cn('py-[18px] mb-4', className)}>
      <Card.Header>
        <div className="flex items-center divide-y-0 divide-x-2 gap-7 divide-solid divide-[#DFE9F1]">
          <Card.Header.Title title="Top Negative Drivers" />
        </div>
      </Card.Header>
      <Card.Body>
        <List
          className="[&_.ant-list-header]:p-0 [&_.ant-list-header]:!border-0 [&_.ant-list-items]:space-y-2"
          dataSource={top}
          renderItem={(item) => (
            <NegativeLine data={item} maxValue={top[0]?.count} />
          )}
        />
      </Card.Body>
    </Card>
  );
}

function ChurnCard({ data, className }) {
  return (
    <Card className={cn('py-[18px] mb-4', className)}>
      <Card.Header>
        <div className="flex items-center divide-y-0 divide-x-2 gap-7 divide-solid divide-[#DFE9F1]">
          <Card.Header.Title title="Churn preditiction" />
        </div>
      </Card.Header>
      <Card.Body className="mt-12">
        <SentimentChart data={data} />
      </Card.Body>
    </Card>
  );
}

export function SentimentDriversCard({ sentiments, className }) {
  return (
    <div className={cn('grid grid-cols-2 gap-4', className)}>
      <TopNegativeDriversCard top={sentiments.top} />
      <ChurnCard data={sentiments.chart} />
    </div>
  );
}
