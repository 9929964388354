import React from 'react';
import { Tooltip, Button } from 'antd';
import { MessageCircleCode } from 'lucide-react';
import './ViewSummaryButton.less';
import { connect } from 'react-redux';

const ViewSummaryButton = ({ user, onClick }) => (
  <Tooltip title={user.automatic_summaries ? 'View full message' : 'View summary'}>
    <Button className="view-summary-button" type="link" onClick={onClick}>
      <MessageCircleCode color="#8F9DA9" size={16} />
    </Button>
  </Tooltip>
);

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(ViewSummaryButton);
