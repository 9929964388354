import { subtopics } from '@/api';
import { useQuery } from '@tanstack/react-query';

export default function useSubtopics(topicId, filters) {
  return useQuery({
    queryKey: ['subtopics', topicId, { filters }],
    queryFn: async () => {
      const { data: response } = await subtopics.getSubtopics(topicId, filters);
      return response;
    },
  });
}
