import React, { useCallback, useState } from 'react';
import { Button, message, Steps } from 'antd';
import CsvModal from './CsvModal';
import CsvMapper from './CsvMapper';
import CsvUploadDragger from './CsvUploadDragger';
import CsvPreview from './CsvPreview';
import CsvProcessor from './CsvProcessor';
import useIntegrationsStore from '../../store/useIntegrationsStore';
import './CsvUploadButton.less';

const { Step } = Steps;

const CsvUploadButton = ({ onComplete, type }) => {
  const { actions } = useIntegrationsStore();
  const [step, setStep] = useState(0);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [mappings, setMappings] = useState(null);
  const openModal = useCallback(() => {
    setStep(0);
    setMappings(null);
    setOpen(true);
  }, [setOpen]);
  const onCsvUploaded = useCallback(() => {
    setOpen(false);
    actions.updateCsvFiles();
    onComplete?.();
  }, [setOpen]);
  const onFileChange = useCallback((f) => {
    setFile(f);
    setStep(1);
  }, [setFile, setStep]);
  const goToNextStep = useCallback(() => {
    setStep((s) => {
      const next = s + 1;

      if (next === 2 && !Object.keys(mappings).find((col) => mappings[col] === 'conversation')) {
        message.error({
          content: 'The conversation column is required to be assigned',
          key: 'conversation_not_assigned',
        });
        return s;
      }

      return next;
    });
  });
  const goToPreviousStep = useCallback(() => {
    setStep((s) => {
      const next = s - 1;

      if (next < 1) {
        setMappings(null);
      }

      return next;
    });
  });

  return (
    <>
      <CsvModal setOpen={setOpen} open={open}>
        <Steps current={step}>
          <Step title="Add file" />
          <Step title="Map columns" />
          <Step title="Review" />
          <Step title="Import" />
        </Steps>
        <div className="mt-10 mb">
          {
            (step === 0) ? (
              <CsvUploadDragger onChange={onFileChange} />
            ) : null
          }
          {
            (step === 1) ? (
              <CsvMapper
                file={file}
                onChange={setMappings}
                initialMappings={mappings}
              />
            ) : null
          }
          {
            (step === 2) ? (
              <CsvPreview
                file={file}
                mappings={mappings}
                limit={1000}
              />
            ) : null
          }
          {
            (step === 3) ? (
              <CsvProcessor
                file={file}
                mappings={mappings}
                onComplete={onCsvUploaded}
                type={type}
              />
            ) : null
          }
          {
            (step > 0 && step < 3) ? (
              <div className="justify-between flex mt-2">
                <Button onClick={goToPreviousStep}>Back</Button>
                <Button onClick={goToNextStep} type="primary">{step === 2 ? 'Import' : 'Continue'}</Button>
              </div>
            ) : null
          }
        </div>
      </CsvModal>
      <Button
        size="large"
        type="primary"
        onClick={openModal}
      >
        Upload
      </Button>
    </>
  );
};

export default CsvUploadButton;
