import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import ConversationsTable from '@/base-components/conversations-table';
import Layout from '@/components/Layout';
import HotTopicsSection from '@/components/dashboard/HotTopicsSection';
import GlobalSentimentsSection from '@/components/dashboard/GlobalSentimentsSection';

const Dashboard = () => (
  <Layout>
    <Helmet>
      <title>ClientZen - Dashboard</title>
    </Helmet>
    <div>
      <HotTopicsSection />
      <GlobalSentimentsSection />
      <div>
        <div className="dashboard-conversations">
          <h3 className="dashboard-conversations__title">Conversations</h3>
          <span className="conversation-info-dot" />
          <Link to="/conversations" className="dashboard-conversations__link">See All Conversations</Link>
        </div>
        <div>
          <ConversationsTable perPage={5} />
        </div>
      </div>
    </div>
  </Layout>
);

export default Dashboard;
