import React from 'react';
import { Input } from 'antd';
import { SearchIcon } from 'lucide-react';
import { cn } from '@/helpers/util';
import debounce from 'lodash/debounce';
import { useTaxonomyTableStore } from '../store/useStore';

export function Search({
  className, onChange, icon, ...props
}) {
  const setSearch = useTaxonomyTableStore((state) => state.setSearch);
  return (
    <Input
      className={cn('!bg-[#EDF4F8] [&_.ant-input-prefix]:!mr-1.5 [&>input]:!bg-[#EDF4F8] !rounded-md [&>input]:!placeholder-[#A9BCCF] [&>input]:placeholder:!opacity-100 !border-0 font-[Gordita-Regular] hover:border hover:border-[#A9BCCF] focus:border focus:border-[#A9BCCF] focus:ring-0', className)}
      placeholder="Search"
      prefix={icon || <SearchIcon className="pr-0.5" size={16} strokeWidth={2} color="#44596C" />}
      onChange={debounce((e) => {
        if (onChange) {
          onChange(e.target.value);
          return;
        }

        setSearch(e.target.value);
      }, 400)}
      {...props}
    />
  );
}
