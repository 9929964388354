import { useState } from 'react';

export const useCustomFieldState = (initialState = []) => {
  const [fields, setFields] = useState(initialState);

  const addCustomField = (id, name, dataType, value) => {
    const alreadyExisting = fields.find((item) => item.id === id
      && item.name === name && item.value === value);
    if (!alreadyExisting) {
      setFields([
        ...fields,
        {
          id, name, dataType, value,
        },
      ]);
    }
  };

  const removeCustomField = (value) => {
    setFields(fields.filter((field) => field.value !== value));
  };

  const removeAllCustomField = () => {
    setFields([]);
  };

  return [fields, addCustomField, removeCustomField, removeAllCustomField];
};
