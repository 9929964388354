import {
  useMemo, useRef, useState,
} from 'react';
import Papa from 'papaparse';
import { v4 as uuidv4 } from 'uuid';
import { csv } from '@/api';
import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';

const getColumnsFrom = (mappings) => Object.keys(mappings);

const dropUnused = (rows, columns) => rows.data.map((row) => {
  const cleaned = {};
  columns.forEach((column) => {
    cleaned[column] = row[column];
  });

  return cleaned;
});

const sleep = (ms, value) => new Promise((accept) => {
  setTimeout(() => {
    accept(value);
  }, ms);
});

const useCsvImport = (file, mappings, onComplete, type) => {
  const [imported, setImported] = useState(0);
  const id = useMemo(() => uuidv4(), [file, mappings]);
  const columns = useMemo(() => getColumnsFrom(mappings), [mappings]);
  const batchNumber = useRef(0);

  const importCsv = useMutation({
    mutationKey: ['import_csv', id, mappings, batchNumber.current, file?.name, type],
    mutationFn: async (data) => {
      batchNumber.current++;
      await csv.batch(id, data.unused, mappings, batchNumber.current, file.name, type);
    },
    onError: (e) => {
      Sentry.captureException(e);
    },
  });

  function startCsvImport() {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      chunkSize: 32 * 1024,
      async chunk(rows, parser) {
        parser.pause();

        await importCsv.mutateAsync({
          unused: dropUnused(rows, columns),
        });

        setImported((count) => count + rows.data.length);
        await sleep(300);

        parser.resume();
      },
      complete() {
        onComplete?.();
      },
    });
  }

  return { imported, startCsvImport };
};

export default useCsvImport;
