import React, { useState } from 'react';
import {
  Modal, Form, Input, Button, Typography,
} from 'antd';
import { insightsEnpointPick, insightsRelatedNamePick } from '@/api';
import { displayOrCatchMessage } from '../../../helpers';
import useModal from '../../../hooks/useModal';
import SuccessMessageModal from './SuccessMessageModal';

const { Text } = Typography;
const { Item } = Form;
const { TextArea } = Input;

const InsightTimelineModal = ({
  isVisible, toggle, client, isColleague,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { isVisible: isModalVisible, toggle: toggleModalMessage } = useModal();

  const { resetFields } = form;
  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await insightsEnpointPick(isColleague).suggest({
        ...values,
        [insightsRelatedNamePick(isColleague)]: client ? client.key : undefined,
      });
      resetFields();
      toggle();
      setLoading(false);
      toggleModalMessage();
    } catch (error) {
      setLoading(false);
      displayOrCatchMessage(error);
    }
  };
  return (
    <>
      <Modal
        title="Define your own insight"
        visible={isVisible}
        onCancel={() => {
          resetFields();
          toggle();
        }}
        className="timelineModalWrapper"
        footer={[
          <Button form="insightForm" key="submit" htmlType="submit" type="primary" loading={loading}>
            Save
          </Button>,
        ]}
      >
        <Text type="secondary" style={{ fontSize: '16px' }}> Improve the client timeline with custom insights. </Text>
        <Form
          requiredMark={false}
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          id="insightForm"
        >
          <Item label="Insight description*" rules={[{ required: true, message: 'Insight description is required' }]} name="description">
            <Input size="large" />

          </Item>
          <Item label="Why is this helpful?" name="why_is_helpful">
            <TextArea rows={4} />
          </Item>
        </Form>
      </Modal>
      <SuccessMessageModal isVisible={isModalVisible} toggle={toggleModalMessage} />
    </>
  );
};
export default InsightTimelineModal;
