import React from 'react';
import SentimentAvg from '../../sentiment-avg';

const GradientSentimentChartTooltipContent = ({
  total,
  ratio,
  annotation,
  date,
}) => (
  <div className="sentiment-tooltip-content">
    <span className="sentiment-chart-tooltip-date">{date}</span>
    Count:
    <span className="sentiment-chart-tooltip-title">{total}</span>
    <div className="sentiment-chart-tooltip-info">
      <SentimentAvg
        sentiment="negative"
        value={ratio.negative}
        styles={{ root: { margin: 0 }, avg: { margin: 0 }, icon: { margin: '0px 6px 0px 2px' } }}
      />
      <SentimentAvg
        sentiment="neutral"
        value={ratio.neutral}
        styles={{ root: { margin: 0 }, avg: { margin: 0 }, icon: { margin: '0px 6px 0px 2px' } }}
      />
      <SentimentAvg
        sentiment="positive"
        value={ratio.positive}
        styles={{ root: { margin: 0 }, avg: { margin: 0 }, icon: { margin: '0px 6px 0px 2px' } }}
      />
    </div>
    <div>
      {annotation}
    </div>
  </div>
);

export default GradientSentimentChartTooltipContent;
