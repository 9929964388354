export const ACTION_RESET_TOPIC_STEPS = 'ACTION_RESET_TOPIC_STEPS';
export const ACTION_UPDATE_TOPIC_STEPS_STATE = 'ACTION_UPDATE_TOPIC_STEPS_STATE';
export const ACTION_UPDATE_TOPIC_PREVIEW_STATE = 'ACTION_UPDATE_TOPIC_PREVIEW_STATE';
export const ACTION_UPDATE_INTERVAL = 'ACTION_UPDATE_INTERVAL';

const initialState = {
  isDiscoveryAvailable: false,
  discoveryAdded: [],
  interval: null,
  preview: {
    data: [], count: 0, loading: false, visible: false,
  },
};

export const topicFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_UPDATE_INTERVAL:
      return {
        ...state,
        interval: action.payload,
      };
    case ACTION_UPDATE_TOPIC_STEPS_STATE:
      return {
        ...state,
        ...(action.payload || {}),
      };
    case ACTION_UPDATE_TOPIC_PREVIEW_STATE:
      return {
        ...state,
        preview: {
          ...state.preview,
          ...(action.payload || {}),
        },
      };
    case ACTION_RESET_TOPIC_STEPS:
      return initialState;
    default:
      return state;
  }
};
