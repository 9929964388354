import React from 'react';

const CategoryTableCell = ({ isCategory, children }) => {
  if (isCategory) {
    return (
      <div className="category-cell">
        {children}
      </div>
    );
  }

  return children;
};

export default CategoryTableCell;
