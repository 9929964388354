/* eslint-disable no-unused-vars */
import {
  Button, Menu, Modal, Select, Spin, message,
} from 'antd';
import { ArrowLeftIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { conversations as conversationsApi } from '@/api';
import FilteredSelect from '@/base-components/conversations-table/components/ConversationActions/FilteredSelect';
import { useConversationStore } from '../../store/zustand/useConversationsStore';
import useSubtopicsTree from './hooks/useSubtopicsTree';
import useConversation from './hooks/useConversation';

export default function MoveToAnotherSubtopicModal({ conversationId, ...props }) {
  const queryClient = useQueryClient();
  const [visible, setVisible] = useState(false);

  const conversations = useConversationStore((state) => state.conversations);
  const conversation = conversations.find((item) => item.id === conversationId);

  const [moveTopics, setMoveTopics] = useState(
    conversation.subtopics ? conversation.subtopics.map((item) => item.id) : [],
  );

  const {
    data, isLoading, isError, error,
  } = useSubtopicsTree();

  const apiConversation = useConversation(conversationId);
  useEffect(() => {
    if (!apiConversation.data) return;

    if (apiConversation.data.subtopics) {
      setMoveTopics(apiConversation.data.subtopics.map((item) => item.id));
    }
  }, [apiConversation.data]);

  const mutation = useMutation({
    mutationKey: 'conversation_move_subtopic',
    mutationFn: async (item) => {
      await conversationsApi.updateSubtopic(item.id, item.moveTopics);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['conversations']);
      message.success('Conversation moved successfully');
      setVisible(false);
    },
    onError: () => {
      message.error('Failed moving conversation');
    },
  });

  useEffect(() => {
    if (isError) {
      message.error(error.message);
    }
  }, [error]);

  const handleMenuClick = () => {
    setVisible(true);
  };
  const handleOK = async () => {
    mutation.mutate({ id: conversationId, moveTopics });
  };

  const handleCancel = () => {
    setVisible(false);
  };
  return (
    <>
      <Menu.Item {...props} className="!flex !items-center !gap-2" onClick={handleMenuClick}>
        <ArrowLeftIcon size={16} strokeWidth={2} />
        <span>Move to another subtopic</span>
      </Menu.Item>
      <Modal
        title="Move Conversation to another subtopic"
        visible={visible}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" loading={mutation.isLoading} onClick={handleOK}>
            Save
          </Button>,
        ]}
        onOk={handleOK}
        onCancel={handleCancel}
      >
        {!isLoading ? (
          <FilteredSelect
            style={{ width: '100%' }}
            mode="multiple"
            size="large"
            placeholder="Subtopic"
            value={moveTopics}
            onChange={(value) => setMoveTopics(value)}
            loading={mutation.isLoading}
            options={data}
          />
        ) : (
          <div className="text-center">
            <Spin size="large" />
          </div>
        )}
      </Modal>
    </>
  );
}
