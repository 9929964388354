import React, { useCallback, useEffect, useState } from 'react';
import { Button, notification } from 'antd';
import { dashboard } from '@/api';
import auth from '@/api/auth';
import { displayOrCatchMessage } from '@/helpers';
import DashboardReady from '../svgs/DashboardReady';
import GearIcon from '../svgs/GearIcon';

const IntegrationPopup = ({ history, actions }) => {
  const [ready, setReady] = useState(false);
  const { highlightNone = () => {} } = actions || {};
  const updateReadyStatus = useCallback(() => {
    dashboard.ready().then((isReady) => {
      if (!isReady) {
        setTimeout(() => {
          updateReadyStatus();
        }, 15 * 1000);
      } else {
        localStorage.setItem('teams', JSON.stringify([{
          ...auth.team(),
          dashboard_ready: true,
        }]));
        setReady(true);
      }
    }).catch((e) => {
      displayOrCatchMessage(e);
    });
  }, []);
  const goTo = useCallback((link, closeNotification = false) => (e) => {
    e.preventDefault();
    history.push(link);

    if (closeNotification) {
      notification.close('gathering-data-integrations');
    }
  }, [history]);

  useEffect(() => {
    updateReadyStatus();
  }, []);

  return (
    <div className="gathering-data-integrations__container">
      <div>{ready ? <DashboardReady /> : <GearIcon />}</div>
      <h3>{ready ? 'Insights are ready!' : 'Gathering and processing data'}</h3>
      <p>
        {
          ready ? (
            <>
              We analyzed your data and
              <br />
              {' '}
              discovered insights
            </>
          ) : (
            (
              <>
                This might take a while.  We&apos;ll send you
                <br />
                {' '}
                an email when it&apos;s ready.
                <br />
                <br />
                {' '}
                In the meantime...
              </>
            )
          )
        }
      </p>
      {
        ready ? (
          <p className="bottom_ready">
            <Button type="primary" onClick={goTo('/', true)} size="large">Go to discovery</Button>
          </p>
        ) : (
          <p className="bottom">
            <a onClick={goTo('/demo')} href="/demo">Check demo</a>
            <a
              onClick={(e) => {
                e.preventDefault();
                if (window.location.pathname !== '/add-platforms') {
                  history.push('/add-platforms');
                } else {
                  highlightNone();
                }
              }}
              href="#/"
            >
              Add more platforms
            </a>
          </p>
        )
      }
    </div>
  );
};

export default IntegrationPopup;
