import React from 'react';
import { List } from 'antd';
import { SecurityBanner } from './SecurityBanner';
import { platforms } from '../utils/platforms';
import { PlatformItem } from './PlatformItem';
import { CSVModal } from './csv-modal';

export function ConnectPlatform() {
  return (
    <>
      <h1 className="text-[#44596C] text-center text-base md:text-lg">
        Connect feedback source
      </h1>
      <List
        className="!mt-6 [&_.ant-list-items]:space-y-2 [&_.ant-list-items]:flex [&_.ant-list-items]:flex-col"
        dataSource={platforms}
        renderItem={PlatformItem}
      />
      <SecurityBanner />
      <CSVModal />
    </>
  );
}
