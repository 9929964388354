import React, { useState, useEffect } from 'react';
import {
  Modal as AntModal, Form, Button, message,
} from 'antd';
import { topics } from '@/api';
import { useErrorState, displayOrCatch } from '../../helpers';
import CategoryForm from './CategoryForm';

const CategoryModal = ({
  visible, onOk, onCancel,
}) => {
  const [form] = Form.useForm();

  const [error, setError] = useErrorState();

  const [loading, setLoading] = useState(false);

  useEffect(() => () => {
    setError(null);
  }, [visible]);

  const cancelHandler = () => {
    form.setFieldsValue({ name: '' });
    onCancel();
  };

  const formProps = {
    form,
    error,
    defaultValues: { name: '' },
    onSubmit: ({ name }) => {
      setLoading(true);
      topics.createCategory({ name })
        .then(() => {
          form.setFieldsValue({ name: '' });
          setLoading(false);
          onOk();
          message.success('Product category created successfully');
        })
        .catch((err) => {
          displayOrCatch(err, setError);
          setLoading(false);
        });
    },
  };

  return (
    <AntModal
      title="Create new product category"
      visible={visible}
      className="create-modal"
      width={650}
      onCancel={cancelHandler}
      destroyOnClose
      footer={[
        <Button
          key="cancel"
          disabled={loading}
          onClick={cancelHandler}
        >
          Cancel
        </Button>,
        <Button
          key="create"
          type="primary"
          size="large"
          loading={loading}
          onClick={() => form.submit()}
        >
          Create product category
        </Button>,
      ]}
    >
      <CategoryForm {...formProps} />
    </AntModal>
  );
};

export default CategoryModal;
