import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import Papa from 'papaparse';
import { v4 as uuidv4 } from 'uuid';
import { csv } from '@/api';
import * as Sentry from '@sentry/react';
import { message } from 'antd';

const getColumnsFrom = (mappings) => Object.keys(mappings);

const dropUnused = (rows, columns) => rows.data.map((row) => {
  const cleaned = {};
  columns.forEach((column) => {
    cleaned[column] = row[column];
  });

  return cleaned;
});

const sleep = (ms, value) => new Promise((accept) => {
  setTimeout(() => {
    accept(value);
  }, ms);
});

const useCsvImport = (file, mappings, onComplete, type) => {
  const [imported, setImported] = useState(0);
  const isAbortedRef = useRef(false);
  const id = useMemo(() => uuidv4(), [file, mappings]);
  const columns = useMemo(() => getColumnsFrom(mappings), [mappings]);

  useEffect(() => {
    let batchNumber = 0;
    setImported(0);

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      chunkSize: 32 * 1024,
      async chunk(rows, parser) {
        if (!isAbortedRef.current) {
          parser.pause();

          try {
            await csv.batch(id, dropUnused(rows, columns), mappings, batchNumber++, file.name, type);

            setImported((count) => count + rows.data.length);
            await sleep(300);
          } catch (e) {
            Sentry.captureException(e);
          }

          if (!isAbortedRef.current) {
            parser.resume();
          } else {
            parser.abort();
          }
        } else {
          parser.abort();
        }
      },
      complete() {
        if (!isAbortedRef.current) {
          message.success({
            content: 'The csv file has been imported',
            key: 'import_csv_success',
          });
        }
        onComplete?.();
      },
    });

    return () => {
      isAbortedRef.current = true;
    };
  }, [file, mappings, type]);

  return [imported];
};

export default useCsvImport;
