import React from 'react';
import classNames from 'classnames';
import loaderIcon from './cz_loader_icon.svg';
import loaderCircleS from './loader_circle_s.svg';
import loaderCircleM from './loader_circle_m.svg';
import loaderCircleL from './loader_circle_l.svg';

const Analyzer = ({ className }) => (
  <div className={classNames('analyzer-loader-animation', className)}>
    <img src={loaderIcon} className="analyzer-logo-mantra-img" alt="" />
    <img src={loaderCircleS} className="loader-circle-s-img" alt="" />
    <img src={loaderCircleM} className="loader-circle-m-img" alt="" />
    <img src={loaderCircleL} className="loader-circle-l-img" alt="" />
  </div>
);

export default Analyzer;
