/* eslint-disable react/jsx-filename-extension, max-len */
import React from 'react';

const getClientIsHappyData = (row) => ({
  text: `${row.client.name} is happy`,
  description: '',
  recommended_step1: `Ask ${row.client.name} for a review.`,
  recommended_step2: `Ask ${row.client.name} for feedback.`,
  recommended_step3: 'Enjoy. Your work is being valued.',
  recommended_step1_description: (
    <>
    </>
  ),
  recommended_step2_description: (
    <>
    </>
  ),
  hasRecommendation: false,
});

export default getClientIsHappyData;
