import React from 'react';
import StatusTag from '../StatusTag';

const InsightStatusTag = ({ text }) => (
  <StatusTag
    goodValues={['positive']}
    badValues={['negative']}
    text={text}
  />
);

export default InsightStatusTag;
