export const SET_CONVERSATIONS = 'conversations/SET_CONVERSATIONS';
export const SET_LOADING = 'conversations/SET_LOADING';
export const SET_ACTION_LOADING = 'conversations/SET_ACTION_LOADING';
export const SET_TEXT_COLLAPSE = 'conversations/SET_TEXT_COLLAPSE';
export const UPDATE_TOPIC = 'conversations/UPDATE_TOPIC';
export const UPDATE_SUBTOPIC = 'conversations/UPDATE_SUBTOPIC';
export const UPDATE_READ_STATUS = 'conversations/UPDATE_READ_STATUS';
export const DELETE_CONVERSATION = 'conversations/DELETE_CONVERSATION';
export const UPDATE_LABEL = 'conversations/UPDATE_LABEL';

export const initialState = {
  conversations: [],
  pagination: { current: 0, pageSize: 15, total: 50 },
  loading: false,
  actionLoading: false,
};

export const conversationsReducer = (prevState, action) => {
  switch (action.type) {
    case SET_CONVERSATIONS: {
      return {
        ...prevState,
        loading: false,
        conversations: action.payload.conversations.map(
          (item) => ({ ...item, isTextCollapsed: true }),
        ),
        pagination: action.payload.pagination,
      };
    }
    case SET_LOADING: {
      return {
        ...prevState,
        loading: action.payload,
      };
    }
    case SET_ACTION_LOADING: {
      return {
        ...prevState,
        actionLoading: action.payload,
      };
    }
    case SET_TEXT_COLLAPSE: {
      return {
        ...prevState,
        conversations: prevState.conversations.map(
          (item) => (item.id === action.payload
            ? { ...item, isTextCollapsed: !item.isTextCollapsed } : item),
        ),
      };
    }
    case UPDATE_TOPIC: {
      const { forTopicId, conversation } = action.payload;
      if (forTopicId && (!conversation.topics || !conversation.topics.map((t) => `${t.id}`).includes(forTopicId))) {
        return {
          ...prevState,
          conversations: prevState.conversations.filter((c) => c.id !== conversation.id),
        };
      }
    }
    // eslint-disable-next-line no-fallthrough
    case UPDATE_SUBTOPIC:
    case UPDATE_READ_STATUS: {
      const { forSubtopicId, conversationId, conversation } = action.payload;

      return {
        ...prevState,
        conversations: (forSubtopicId && (!conversation.subtopics || !conversation.subtopics.map((t) => `${t.id}`).includes(`${forSubtopicId}`)))
          ? prevState.conversations.filter((c) => c.id !== conversationId)
          : prevState.conversations.map(
            (item) => (item.id === conversationId
              ? { ...conversation, isTextCollapsed: item.isTextCollapsed }
              : item),
          ),
      };
    }

    case UPDATE_LABEL: {
      const { conversationId } = action.payload;
      return {
        ...prevState,
        conversations: prevState.conversations.map(
          (item) => (item.id === conversationId
            ? {
              ...item,
              labels: action.payload.action === 'assign'
                ? [...item.labels, action.payload.label]
                : item.labels.filter((l) => l.id !== action.payload.label.id),
            }
            : item),
        ),
      };
    }

    case DELETE_CONVERSATION: {
      const { conversationId } = action.payload;
      return {
        ...prevState,
        conversations: prevState.conversations.filter((c) => c.id !== conversationId),
      };
    }
    default: {
      return prevState;
    }
  }
};
