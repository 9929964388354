import { create } from 'zustand';

export const useSelectedDaysPaginationStore = create((set) => ({
  currentPage: 1,
  perPage: 50,
  total: 0,

  setPage: (page) => set({ currentPage: page }),
  setPager: (pager) => set({ currentPage: pager.current_page, perPage: pager.per_page, total: pager.total }),

  reset: () => set({
    currentPage: 1,
    perPage: 50,
    total: 0,
  }),
}));
