import React from 'react';
import { Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import classnames from 'classnames';

const BillingPlan = ({
  children,
  title,
  subtitle,
  price,
  titleColor,
  buttonText,
  benefitsContainerStyle,
  isAnnuallySubscriptionSelected,
  onClick,
  hideButton,
}) => (
  <div className="plan-container">
    <div className="plan-header">
      <span style={{ color: titleColor }} className="plan-title">{title}</span>
      <span className="plan-subtitle">{subtitle}</span>
    </div>
    <div style={benefitsContainerStyle} className="plan-benefits-container">
      {
        price ? (
          <div className="price-container">
            <div>
              <span className="price">
                &euro;
                {price}
              </span>
              /mo
            </div>
            <div className="billed-annually">{isAnnuallySubscriptionSelected ? 'billed annually' : 'billed monthly'}</div>
          </div>
        ) : (
          <div className="price-missing-container">
            Contact Us
          </div>
        )
      }
      {children}
      {!hideButton ? (
        <Button
          onClick={onClick}
          className={classnames({
            'enterprise-button': title === 'Enterprise',
          }, 'billing-start-button')}
          style={{ width: '100%', marginTop: 20 }}
          size="large"
          type="primary"
          icon={<ArrowRightOutlined />}
        >
          {buttonText}
        </Button>
      ) : null}
    </div>
  </div>
);

export default BillingPlan;
