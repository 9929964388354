import React from 'react';
import IntegrationCard from './IntegrationCard';

const IntegrationList = ({
  platforms, title,
}) => (
  <div className="integrations__list">
    <h4>{title}</h4>
    <div className="integrations__list_wrap">
      {
        platforms.map((type) => (
          <IntegrationCard
            key={type}
            type={type}
          />
        ))
      }
    </div>
  </div>
);

export default IntegrationList;
