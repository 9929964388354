import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import AuthBG from '@/images/bg-auth.png';
import AuthBG2x from '@/images/bg-auth@2x.png';
import RegisterForm from '@/components/auth/RegisterForm';
import Logo from '@/components/ui/logo';
import { Link, useParams } from 'react-router-dom';
import { Result, Spin } from 'antd';
import { displayOrCatchMessage } from '@/helpers';
import { ReactComponent as PrestashopLogo } from '@/components/svgs/prestashop-logo.svg';
import { auth } from '@/api';

export default function RegisterPage() {
  const isInviteUrl = window.location.pathname.indexOf('/invite/') === 0;
  const { token } = useParams();
  const [loading, setLoading] = useState(isInviteUrl);
  const [data, setData] = useState(null);

  useEffect(() => {
    try {
      localStorage.removeItem('choosen_plan');

      const query = window.location.search.substr(1).split('&');
      const plan = query.filter((item) => item.indexOf('plan=') === 0)[0];
      const planName = plan.split('=')[1];

      localStorage.setItem('choosen_plan', planName);
    } catch (e) {
      // ignore errors and do nothing
    }

    if (isInviteUrl) {
      auth.invitationData(token).then((response) => {
        setData(response.data.data);
        setLoading(false);
      }).catch((e) => {
        displayOrCatchMessage(e);
      });
    }
  }, []);
  return (
    <section className="relative h-screen flex flex-col items-center">
      <div className="mt-8 md:mt-24 md:min-w-[400px] md:max-w-[400px]">
        <motion.div
          className="flex justify-center"
          initial={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.5 }}
          animate={{
            opacity: [0, 1],
            y: 0,
          }}
        >
          <Link className="flex" to="/">
            <Logo />
          </Link>
        </motion.div>
        <motion.div
          className="mt-7"
          initial={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.5, delay: 0.5 }}
          layout
          animate={{
            opacity: [0, 1],
            y: 0,
          }}
          exit={{ opacity: 0, y: 20 }}
        >
          <h1 className="text-[18px] text-center m-0 p-0 text-[#44596C] font-[Gordita-Medium]">Try ClientZen for FREE</h1>
          {
          loading ? (
            <Result
              icon={<Spin size="large" />}
              subTitle="working on it..."
            />
          ) : (
            <RegisterForm data={data} token={token} />
          )
        }
        </motion.div>
        <motion.img
          initial={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.5, delay: 1 }}
          layout
          animate={{
            opacity: [0, 1],
            y: 0,
          }}
          exit={{ opacity: 0, y: 20 }}
          src={AuthBG}
          srcSet={`${AuthBG2x} 2x`}
          alt="bg-auth"
          className="absolute bottom-0 left-0 w-full -z-10"
        />
      </div>
      <motion.div
        className="flex-1 flex flex-col items-start mt-16 md:mt-24 px-5 md:px-0 max-w-sm"
        initial={{ opacity: 0, y: 20 }}
        transition={{ duration: 0.5, delay: 1 }}
        layout
        animate={{
          opacity: [0, 1],
          y: 0,
        }}
        exit={{ opacity: 0, y: 20 }}
      >
        <p className="m-0 italic text-base font-[Gordita-Regular] text-[#44596C]">
          &quot;ClientZen saves us hours of work! We can&apos;t live without it and our team couldn&apos;t be more productive since using it!&quot;
        </p>
        <span className="block text-[#44596C] text-xs mt-6">Lucas Marchionne, VoC manager</span>
        <PrestashopLogo className="mt-3" />
      </motion.div>
    </section>
  );
}
