import { topics } from '@/api';
import { useQuery } from '@tanstack/react-query';

export default function useBucketGraph(topicId, subtopicId, filters) {
  return useQuery({
    queryKey: ['topic_graph', topicId, subtopicId, { filters }],
    queryFn: async () => {
      const { data } = await topics.getTopicSentiment({
        ...filters,
        topic_id: topicId,
        subtopic_id: subtopicId,
      });
      return data;
    },
    refetchOnWindowFocus: false,
  });
}
