import React from 'react';
import InteractionCard from './InteractionCard';

const InteractionCarouselPage = ({ items, onDomain, isColleague }) => (items.length ? (
  <div className="interaction-page">
    {
      items.map((item) => (
        <InteractionCard
          recent
          key={item.email}
          {...item}
          onDomain={onDomain}
          isColleague={isColleague}
        />
      ))
    }
  </div>
) : null);

export default InteractionCarouselPage;
