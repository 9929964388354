import React from 'react';
import { Dropdown, Menu, Button } from 'antd';
import AnnotationIcon from '../../svgs/icons/Annotation';
import AddIcon from '../../svgs/icons/Add';
import GhostButton from '../../GhostButton';

const AnnotationActions = ({ onAdd, onList }) => (
  <Dropdown
    placement="bottomRight"
    trigger={['click']}
    overlay={(
      <Menu>
        <Menu.Item>
          <GhostButton type="primary" onClick={onAdd}>
            <AddIcon color="#0BAA6B" className="annotation-actions__add-icon" />
            Add new release
          </GhostButton>
        </Menu.Item>
        <Menu.Item>
          <GhostButton className="annotation-actions__see-all-item" onClick={onList}>
            See all releases
          </GhostButton>
        </Menu.Item>
      </Menu>
      )}
  >
    <Button
      htmlType="button"
      type="default"
      icon={<AnnotationIcon className="annotation-actions__btn-icon" />}
    >
      Releases
    </Button>
  </Dropdown>
);

export default AnnotationActions;
