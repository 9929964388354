/* eslint-disable camelcase */
import { buildQuery } from '@/helpers/filters';
import axios from './axios';
import auth from './auth';

const getCustomFields = (filters) => axios.get(buildQuery(
  '/api/custom-fields', filters, {
    team_id: auth.team().id,
  },
))
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const updateAdded = (added) => axios.post(
  '/api/custom-fields/add',
  {
    team_id: auth.team().id,
    added,
  },
)
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const getCustomFieldValues = (params) => axios.get(buildQuery(
  '/api/custom-fields/values', {}, {
    team_id: auth.team().id,
    ...params,
  },
))
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const customFields = {
  getCustomFields,
  updateAdded,
  getCustomFieldValues,
};

export default customFields;
