import axios from './axios';
import { buildQuery } from '../helpers/filters';
import auth from './auth';

const countNeedAttention = () => axios.get('/api/count-clients-need-attention', {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response.data.data).catch((e) => Promise.reject(e));

const countAll = () => axios.get('/api/count-clients-all', {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response.data.data).catch((e) => Promise.reject(e));

const index = (filters, page, sort, perPage = 15, searchValue = '') => axios.get(buildQuery('/api/clients', filters, {
  ...(page ? { page } : undefined),
  ...(sort ? { sort } : undefined),
  team_id: auth.team().id,
  per_page: perPage,
  search_value: searchValue,
})).then((response) => response).catch((e) => Promise.reject(e));

const show = (id, filters, params) => axios.get(buildQuery(`/api/clients/${id}`, filters, {
  team_id: auth.team().id,
  ...params,
})).then((response) => response).catch((e) => Promise.reject(e));

const store = (values) => axios.post('/api/clients', {
  ...values,
  team_id: auth.team().id,
}).then((response) => response).catch((e) => Promise.reject(e));

const storeBatch = (values) => axios.post('/api/clients-batch', {
  ...values,
  team_id: auth.team().id,
}).then((response) => response).catch((e) => Promise.reject(e));

const update = (client) => (values) => axios.put(`/api/clients/${client.key}`, {
  ...values,
  team_id: auth.team().id,
}).then((response) => response).catch((e) => Promise.reject(e));

const destroy = (client) => axios.delete(`/api/clients/${client.key}`, {
  params: {
    team_id: auth.team().id,
  },
}).then((response) => response).catch((e) => Promise.reject(e));

const exportCsv = (filters) => axios.post('/api/export/clients', filters,
  {
    params: {
      team_id: auth.team().id,
    },
  }).then((response) => response).catch((e) => Promise.reject(e));

const types = (search) => axios.get('/api/clients-types', {
  params: {
    team_id: auth.team().id,
    search,
  },
}).then((response) => response.data.data).catch((e) => Promise.reject(e));

const clients = {
  countNeedAttention,
  countAll,
  index,
  show,
  store,
  storeBatch,
  update,
  destroy,
  exportCsv,
  types,
};

export default clients;
