import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Radio, Tooltip } from 'antd';
import useURLState from '@/hooks/useUrlState';
import {
  DATEPICKER_LOCALSTORAGE_KEY,
  DATEPICKER_LOCALSTORAGE_KEY_CUSTOM_END,
  DATEPICKER_LOCALSTORAGE_KEY_CUSTOM_START,
} from './SentimentDateFilter';
import SentimentRangePicker from './SentimentRangePicker';

export const options = [
  {
    name: '7D',
    start: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    label: 'last 7 days',
  },
  {
    name: '30D',
    start: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    label: 'last 30 days',
  },
  {
    name: '3M',
    start: moment().subtract(3, 'month').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    label: 'last 3 months',
  },
  {
    name: '6M',
    start: moment().subtract(6, 'month').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    label: 'last 6 months',
  },
];

const formatDate = (date) => moment(date).format('MMM D, YYYY');

const getTooltipText = (option) => `${formatDate(option.start)} - ${formatDate(option.end)}`;

const SentimentRadioGroupDateFilter = ({ initialValue, onChange, ...rest }) => {
  const [value, setValue] = useState(
    initialValue?.name === 'CUSTOM' ? 'CUSTOM' : initialValue,
  );
  const [showPicker, setShowPicker] = useState(initialValue?.name === 'CUSTOM');

  const hash = new URLSearchParams(
    window.location.hash ? window.location.hash.substring(1) : '',
  );

  const { urlQueryState, setUrlQueryParams, deleteUrlQueryParams } = useURLState();

  useEffect(() => {
    // sync local storage based on hash
    if (hash.get('name') && hash.get('name') !== 'CUSTOM') {
      const idx = options.findIndex(({ name }) => name === hash.get('name'));

      if (idx !== -1) {
        localStorage.setItem(DATEPICKER_LOCALSTORAGE_KEY, hash.get('name'));

        // change current value based on hash
        if (hash.get('name') !== value.name) {
          setShowPicker(false);
          setValue(options[idx]);
          onChange?.(options[idx]);
          return;
        }
      }
    }

    // sync local storage based on url query
    if (urlQueryState?.name && urlQueryState?.name !== 'CUSTOM') {
      const idx = options.findIndex(({ name }) => name === urlQueryState.name);

      if (idx !== -1) {
        localStorage.setItem(DATEPICKER_LOCALSTORAGE_KEY, urlQueryState?.name);

        // change current value based on url query
        if (urlQueryState.name !== value.name) {
          setShowPicker(false);
          setValue(options[idx]);
          onChange?.(options[idx]);
        }
      }
    }
  }, [urlQueryState?.name, hash]);

  function handleChange(e) {
    if (e.target && e.target.value !== 'CUSTOM') {
      setShowPicker(false);

      setUrlQueryParams({ name: e.target.value.name });
      deleteUrlQueryParams(['start', 'end']);

      localStorage.setItem(DATEPICKER_LOCALSTORAGE_KEY, e.target.value.name);
      localStorage.removeItem(DATEPICKER_LOCALSTORAGE_KEY_CUSTOM_START);
      localStorage.removeItem(DATEPICKER_LOCALSTORAGE_KEY_CUSTOM_END);

      setValue(e.target.value);
      onChange?.(e.target.value, null, null);
    }
  }

  function handleChangeFromPicker(values) {
    const start = values[0].format('YYYY-MM-DD');
    const end = values[1].format('YYYY-MM-DD');

    setUrlQueryParams({ name: 'CUSTOM', start, end });

    localStorage.setItem(DATEPICKER_LOCALSTORAGE_KEY, 'CUSTOM');
    localStorage.setItem(DATEPICKER_LOCALSTORAGE_KEY_CUSTOM_START, start);
    localStorage.setItem(DATEPICKER_LOCALSTORAGE_KEY_CUSTOM_END, end);

    setValue('CUSTOM');
    onChange?.({
      name: 'CUSTOM',
      start,
      end,
      label: 'custom',
    });
  }

  return (
    <Radio.Group value={value} onChange={handleChange} {...rest}>
      {options.map((option) => (
        <Radio.Button key={option.name} value={option}>
          <Tooltip
            placement="bottom"
            overlayClassName="topic-metric-tooltip"
            overlayInnerStyle={{ fontSize: 12, marginBottom: 0 }}
            title={getTooltipText(option)}
          >
            {option.name}
          </Tooltip>
        </Radio.Button>
      ))}
      <Radio.Button
        key="CUSTOM"
        value="CUSTOM"
        onClick={() => setShowPicker(true)}
      >
        {showPicker ? (
          <SentimentRangePicker
            autoFocus={value !== 'CUSTOM'}
            size="small"
            bordered={false}
            onChange={handleChangeFromPicker}
            style={{ width: 250 }}
            showOnMount={value !== 'CUSTOM'}
            defaultValue={
              value === 'CUSTOM'
                ? [
                  moment(initialValue.start, 'YYYY-MM-DD').startOf('day'),
                  moment(initialValue.end, 'YYYY-MM-DD').startOf('day'),
                ]
                : undefined
            }
          />
        ) : (
          <Tooltip
            placement="bottom"
            overlayClassName="topic-metric-tooltip"
            overlayInnerStyle={{ fontSize: 12, marginBottom: 0 }}
            title="Custom"
          >
            Custom
          </Tooltip>
        )}
      </Radio.Button>
    </Radio.Group>
  );
};

export default SentimentRadioGroupDateFilter;
