export const SET_RANGE_FILTER_ACTION = 'SET_RANGE_FILTER_ACTION';

export const SET_SEARCH_FILTER_ACTION = 'SET_SEARCH_FILTER_ACTION';

export const SET_DAYS_FILTER_ACTION = 'SET_DAYS_FILTER_ACTION';

export const SET_LOADING_ACTION = 'SET_LOADING_ACTION';

export const SET_DATA_ACTION = 'SET_DATA_ACTION';

export const SET_MODAL_ACTION = 'SET_MODAL_ACTION';

export const initialState = {
  filters: null,
  data: null,
  loading: true,
  modal: false,
  reload: { data: false, page: false },
};

export const peopleDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODAL_ACTION: {
      const { modal, pageReload = false, dataReload = false } = action.payload;

      return {
        ...state,
        modal,
        reload: {
          ...state.reload,
          data: dataReload ? !state.reload.data : state.reload.data,
          page: pageReload ? !state.reload.page : state.reload.page,
        },
      };
    }
    case SET_SEARCH_FILTER_ACTION: {
      return {
        ...state,
        filters: {
          ...state.filters,
          search: action.payload,
        },
      };
    }
    case SET_RANGE_FILTER_ACTION: {
      const { payload: { start, end } } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          start,
          end,
        },
      };
    }
    case SET_DAYS_FILTER_ACTION: {
      const { payload: { days } } = action;

      return {
        ...state,
        filters: {
          ...state.filters,
          days,
        },
      };
    }
    case SET_LOADING_ACTION: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case SET_DATA_ACTION: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }
    default:
      return state;
  }
};
