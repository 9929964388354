import React, { useEffect, useRef } from 'react';

const ChainlitAutoscrollDiv = ({ loading, messages, ...props }) => {
  const ref = useRef();

  useEffect(() => {
    if (!ref.current || !loading) {
      return;
    }
    ref.current.scrollTop = ref.current.scrollHeight;
  }, [messages, loading]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    ref.current.scrollTop = ref.current.scrollHeight;
  }, []);

  return (
    <div ref={ref} {...props} />
  );
};

export default ChainlitAutoscrollDiv;
