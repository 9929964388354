import React, { useRef, useState, useEffect } from 'react';
import { Select } from 'antd';

const FilteredSelect = ({ options, ...props }) => {
  const [search, setSearch] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const isMounted = useRef(false);

  const onSearch = (value) => {
    setSearch(value);
  };

  useEffect(() => {
    if (isMounted.current) {
      setFilteredOptions(options);
    } else {
      isMounted.current = true;
    }
  }, [options]);

  useEffect(() => {
    const filtered = [];

    options.forEach((option) => {
      if (option.options.find((suboption) => suboption
        .label.toLowerCase().indexOf(search.toLowerCase()) !== -1)) {
        filtered.push({
          ...option,
          options: option.options.filter(
            (suboption) => suboption.label.toLowerCase().indexOf(search.toLowerCase()) !== -1,
          ),
        });
      }
    });

    setFilteredOptions(filtered);
  }, [search]);

  return (
    <Select
      options={filteredOptions}
      {...props}
      searchValue={search}
      onSearch={onSearch}
      filterOption={false}
    />
  );
};

export default FilteredSelect;
