import { options } from './SentimentRadioGroupDateFilter';
import { DATEPICKER_LOCALSTORAGE_KEY, DATEPICKER_LOCALSTORAGE_KEY_CUSTOM_START, DATEPICKER_LOCALSTORAGE_KEY_CUSTOM_END } from './SentimentDateFilter';

/**
 * localstorage.setItem(DATEPICKER_LOCALSTORAGE_KEY, option.name)
 */

export const getDefaultDatepickerValueFromLocalStorage = () => {
  const value = localStorage.getItem(DATEPICKER_LOCALSTORAGE_KEY);

  if (value) {
    const idx = options.findIndex((item) => item.name === value);

    if (idx !== -1) {
      return options[idx];
    }
  }

  if (value === 'CUSTOM' && localStorage.getItem(DATEPICKER_LOCALSTORAGE_KEY_CUSTOM_START) && localStorage.getItem(DATEPICKER_LOCALSTORAGE_KEY_CUSTOM_END)) {
    return {
      name: 'CUSTOM',
      start: localStorage.getItem(DATEPICKER_LOCALSTORAGE_KEY_CUSTOM_START),
      end: localStorage.getItem(DATEPICKER_LOCALSTORAGE_KEY_CUSTOM_END),
      label: 'custom',
    };
  }

  return options[2];
};
