import React from 'react';
import { Button, Input, Spin } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import Progress from '@/components/ui/progress';
import Layout from '../../../../components/Layout';
import Affix from '../../../../components/Affix';
import Tag from '../../../Tag';
import TopicActions from '../../../../components/topic-dashboard/components/TopicActions';
import TopicMetric from '../../../topic-metric';
import { calculateDifference } from '../../../../components/Painpoints/helpers';
import SentimentTooltip from '../../../SentimentTooltip';
import { SentimentRadioGroupDateFilter, useSentimentDateFilterInitialValue } from '../../../sentiment-date-filter';
import {
  modals,
  SET_RANGE_FILTER_ACTION,
  SET_TAB_ACTION,
  tabs,
} from '../../../../components/topic-dashboard/topicDashboardReducer';
import AnnotationActions from '../../../../components/topic-dashboard/components/AnnotationActions';
import AnnotationIcon from '../../../../components/svgs/icons/Annotation';
import GradientSentimentChart from './OnboardingBackgroundGradientSentimentChart';
import ConversationsTable from './conversations-table';

const data = {
  id: 5669,
  name: 'Overall perception',
  count: { value: 204, previous_value: 0, trend: 'ascending' },
  ratio: { value: 0.2749326145552561, previous_value: 0, trend: 'ascending' },
  mrr: { value: 101177, previous_value: 0, trend: 'ascending' },
  sentiment: { positive: 0.43137254901960786, negative: 0.38235294117647056 },
  included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'],
  excluded_keywords: [],
  type: 'keyword_based',
  annotations: [],
  groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
  group: { id: 1489, name: 'Brand', color: '#959595' },
};
const dataSentiment = {
  data: {
    chart: [{
      point: moment().subtract(90, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(89, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(88, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(87, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(86, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(85, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(84, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(83, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(82, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(81, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(80, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(79, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(78, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(77, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(76, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(75, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(74, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(73, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(72, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(71, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(70, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(69, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(68, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(67, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(66, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(65, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(64, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(63, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(62, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(61, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(60, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(59, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(58, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(57, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(56, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(55, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(54, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(53, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(52, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(51, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(50, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(49, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(48, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(47, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(46, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(45, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(44, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(43, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(42, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(41, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(40, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(39, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(38, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(37, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(36, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(35, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(34, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(33, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(32, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(31, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(30, 'days').format('YYYY-MM-DD'), total: 0, negative: 0, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(29, 'days').format('YYYY-MM-DD'), total: 3, negative: 0.3333333333333333, positive: 0.6666666666666666, neutral: 5.551115123125783e-17,
    }, {
      point: moment().subtract(28, 'days').format('YYYY-MM-DD'), total: 1, negative: 0, positive: 0, neutral: 1,
    }, {
      point: moment().subtract(27, 'days').format('YYYY-MM-DD'), total: 2, negative: 0.5, positive: 0, neutral: 0.5,
    }, {
      point: moment().subtract(26, 'days').format('YYYY-MM-DD'), total: 3, negative: 0.6666666666666666, positive: 0.3333333333333333, neutral: 1.1102230246251565e-16,
    }, {
      point: moment().subtract(25, 'days').format('YYYY-MM-DD'), total: 5, negative: 0.2, positive: 0.6, neutral: 0.2,
    }, {
      point: moment().subtract(24, 'days').format('YYYY-MM-DD'), total: 1, negative: 0, positive: 1, neutral: 0,
    }, {
      point: moment().subtract(23, 'days').format('YYYY-MM-DD'), total: 2, negative: 0.5, positive: 0, neutral: 0.5,
    }, {
      point: moment().subtract(22, 'days').format('YYYY-MM-DD'), total: 1, negative: 0, positive: 1, neutral: 0,
    }, {
      point: moment().subtract(21, 'days').format('YYYY-MM-DD'), total: 3, negative: 0.3333333333333333, positive: 0.6666666666666666, neutral: 5.551115123125783e-17,
    }, {
      point: moment().subtract(20, 'days').format('YYYY-MM-DD'), total: 4, negative: 0.5, positive: 0.25, neutral: 0.25,
    }, {
      point: moment().subtract(19, 'days').format('YYYY-MM-DD'), total: 3, negative: 0.6666666666666666, positive: 0.3333333333333333, neutral: 1.1102230246251565e-16,
    }, {
      point: moment().subtract(18, 'days').format('YYYY-MM-DD'), total: 3, negative: 0, positive: 1, neutral: 0,
    }, {
      point: moment().subtract(17, 'days').format('YYYY-MM-DD'), total: 1, negative: 0, positive: 0, neutral: 1,
    }, {
      point: moment().subtract(16, 'days').format('YYYY-MM-DD'), total: 6, negative: 0.16666666666666666, positive: 0.3333333333333333, neutral: 0.5000000000000001,
    }, {
      point: moment().subtract(15, 'days').format('YYYY-MM-DD'), total: 5, negative: 0, positive: 0.8, neutral: 0.19999999999999996,
    }, {
      point: moment().subtract(14, 'days').format('YYYY-MM-DD'), total: 4, negative: 0, positive: 0.5, neutral: 0.5,
    }, {
      point: moment().subtract(13, 'days').format('YYYY-MM-DD'), total: 5, negative: 0.4, positive: 0.4, neutral: 0.19999999999999996,
    }, {
      point: moment().subtract(12, 'days').format('YYYY-MM-DD'), total: 3, negative: 0.6666666666666666, positive: 0, neutral: 0.33333333333333337,
    }, {
      point: moment().subtract(11, 'days').format('YYYY-MM-DD'), total: 8, negative: 0.125, positive: 0.75, neutral: 0.125,
    }, {
      point: moment().subtract(10, 'days').format('YYYY-MM-DD'), total: 6, negative: 0.6666666666666666, positive: 0.3333333333333333, neutral: 1.1102230246251565e-16,
    }, {
      point: moment().subtract(9, 'days').format('YYYY-MM-DD'), total: 8, negative: 1, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(8, 'days').format('YYYY-MM-DD'), total: 5, negative: 0.6, positive: 0.4, neutral: 0,
    }, {
      point: moment().subtract(7, 'days').format('YYYY-MM-DD'), total: 2, negative: 0.5, positive: 0, neutral: 0.5,
    }, {
      point: moment().subtract(6, 'days').format('YYYY-MM-DD'), total: 1, negative: 1, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(5, 'days').format('YYYY-MM-DD'), total: 4, negative: 0.5, positive: 0.25, neutral: 0.25,
    }, {
      point: moment().subtract(4, 'days').format('YYYY-MM-DD'), total: 3, negative: 0, positive: 1, neutral: 0,
    }, {
      point: moment().subtract(3, 'days').format('YYYY-MM-DD'), total: 3, negative: 0.3333333333333333, positive: 0.3333333333333333, neutral: 0.3333333333333334,
    }, {
      point: moment().subtract(2, 'days').format('YYYY-MM-DD'), total: 1, negative: 1, positive: 0, neutral: 0,
    }, {
      point: moment().subtract(1, 'days').format('YYYY-MM-DD'), total: 1, negative: 0, positive: 0, neutral: 1,
    }, {
      point: moment().format('YYYY-MM-DD'), total: 5, negative: 0.2, positive: 0.8, neutral: -5.551115123125783e-17,
    }],
    annotations: [],
  },
};
const dataConversations = {
  data: [{
    id: 4834589,
    sentiment: 'positive',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051249,
      company_id: 151823,
      name: 'Ayisha Shepard',
      email: 'ayisha.shepard@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#93E4C4',
      is_team_member: false,
      latest_scores: [{
        id: 1678879, date: '2023-05-12T17:37:21.000000Z', value: 100, client_id: 1051249, platform_message_id: 4834589, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 100, "details": {"Mixed": 0.00004860583067056723, "Neutral": 0.000878199003636837, "Negative": 0.00003652269879239611, "Positive": 0.9990366697311401}, "sentiment": "Positive"}',
      }, {
        id: 1678777, date: '2023-05-02T17:37:21.000000Z', value: 100, client_id: 1051249, platform_message_id: 4834487, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 100, "details": {"Mixed": 0.00035245047183707356, "Neutral": 0.00014387666305992752, "Negative": 0.00004932675074087456, "Positive": 0.9994543194770813}, "sentiment": "Positive"}',
      }],
      pivot: { platform_message_id: 4834589, client_id: 1051249 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: 'I recommend the softonic app as a great tool for spelling, email builder, and design. It is easy to use and has a lot of features that make it a great tool for anyone looking to improve their productivity.',
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: 'I recommend the softonic app as a great tool for spelling, email builder, and design. It is easy to use and has a lot of features that make it a great tool for anyone looking to improve their...',
    date: '2023-05-12T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834590,
    sentiment: 'negative',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051250,
      company_id: 151823,
      name: 'Nettie Mann',
      email: 'nettie.mann@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#5AC115',
      is_team_member: false,
      latest_scores: [{
        id: 1678880, date: '2023-05-12T17:37:21.000000Z', value: 39, client_id: 1051250, platform_message_id: 4834590, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 39, "details": {"Mixed": 0.000437448121374473, "Neutral": 0.00014146715693641454, "Negative": 0.999291181564331, "Positive": 0.00012982958287466317}, "sentiment": "Negative"}',
      }, {
        id: 1678778, date: '2023-05-02T17:37:21.000000Z', value: 39, client_id: 1051250, platform_message_id: 4834488, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 39, "details": {"Mixed": 0.000133207329781726, "Neutral": 0.00014509800530504435, "Negative": 0.9994606375694275, "Positive": 0.00026108502061106265}, "sentiment": "Negative"}',
      }],
      pivot: { platform_message_id: 4834590, client_id: 1051250 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: "I would not recommend the Softonic app - it's slow and ugly, with email templates that are really unfriendly.",
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: "I would not recommend the Softonic app - it's slow and ugly, with email templates that are really unfriendly.",
    date: '2023-05-12T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834583,
    sentiment: 'positive',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051243,
      company_id: 151823,
      name: 'Mari Jones',
      email: 'mari.jones@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#7AB395',
      is_team_member: false,
      latest_scores: [{
        id: 1678873, date: '2023-05-12T17:37:21.000000Z', value: 100, client_id: 1051243, platform_message_id: 4834583, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 100, "details": {"Mixed": 0.00004204083961667493, "Neutral": 0.0001706773618934676, "Negative": 0.000022852324036648497, "Positive": 0.9997643828392029}, "sentiment": "Positive"}',
      }, {
        id: 1678771, date: '2023-05-01T17:37:21.000000Z', value: 100, client_id: 1051243, platform_message_id: 4834481, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 100, "details": {"Mixed": 0.00005082724237581715, "Neutral": 0.0007396018481813371, "Negative": 0.00003487053982098587, "Positive": 0.9991746544837952}, "sentiment": "Positive"}',
      }],
      pivot: { platform_message_id: 4834583, client_id: 1051243 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: 'I think it is great popup builder nice layout good tools. It has a lot of features and options that make it very easy to use. The interface is also very user friendly. I would definitely recommend this to anyone looking for a popup builder.',
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: 'I think it is great popup builder nice layout good tools. It has a lot of features and options that make it very easy to use. The interface is also very user friendly. I would definitely recommend...',
    date: '2023-05-12T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834586,
    sentiment: 'positive',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051246,
      company_id: 151823,
      name: 'Octavia Garrison',
      email: 'octavia.garrison@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#7FCD4B',
      is_team_member: false,
      latest_scores: [{
        id: 1678876, date: '2023-05-12T17:37:21.000000Z', value: 100, client_id: 1051246, platform_message_id: 4834586, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 100, "details": {"Mixed": 0.0000664797771605663, "Neutral": 0.00012100466847186908, "Negative": 0.00002083244726236444, "Positive": 0.99979168176651}, "sentiment": "Positive"}',
      }, {
        id: 1678774, date: '2023-05-02T17:37:21.000000Z', value: 100, client_id: 1051246, platform_message_id: 4834484, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 100, "details": {"Mixed": 0.0005266691441647708, "Neutral": 0.009003582410514355, "Negative": 0.00020267671789042652, "Positive": 0.9902670979499817}, "sentiment": "Positive"}',
      }],
      pivot: { platform_message_id: 4834586, client_id: 1051246 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: "This tool is designed great. it's interesting and I like it. It's a good design and I'm glad I found it.",
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: "This tool is designed great. it's interesting and I like it. It's a good design and I'm glad I found it.",
    date: '2023-05-12T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834591,
    sentiment: 'positive',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051251,
      company_id: 151823,
      name: 'Dru Shaffer',
      email: 'dru.shaffer@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#5ADD5A',
      is_team_member: false,
      latest_scores: [{
        id: 1678881, date: '2023-05-12T17:37:21.000000Z', value: 100, client_id: 1051251, platform_message_id: 4834591, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 100, "details": {"Mixed": 0.00035245047183707356, "Neutral": 0.00014387666305992752, "Negative": 0.00004932675074087456, "Positive": 0.9994543194770813}, "sentiment": "Positive"}',
      }, {
        id: 1678779, date: '2023-05-02T17:37:21.000000Z', value: 100, client_id: 1051251, platform_message_id: 4834489, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 100, "details": {"Mixed": 0.003623437834903598, "Neutral": 0.00147884467151016, "Negative": 0.00032534784986637533, "Positive": 0.9945723414421082}, "sentiment": "Positive"}',
      }],
      pivot: { platform_message_id: 4834591, client_id: 1051251 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: 'I recommend softonic app support. It is really good but comes with high price. Great tools help you get the most out of your experience with the app.',
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: 'I recommend softonic app support. It is really good but comes with high price. Great tools help you get the most out of your experience with the app.',
    date: '2023-05-12T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834575,
    sentiment: 'neutral',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051235,
      company_id: 151823,
      name: 'Brooke Pearson',
      email: 'brooke.pearson@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#55A45C',
      is_team_member: false,
      latest_scores: [{
        id: 1678865, date: '2023-05-11T17:37:21.000000Z', value: 59, client_id: 1051235, platform_message_id: 4834575, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 59, "details": {"Mixed": 0.9928237199783325, "Neutral": 0.00008197526767617092, "Negative": 0.006680649239569902, "Positive": 0.00041354200220666826}, "sentiment": "Mixed"}',
      }, {
        id: 1678763, date: '2023-05-01T17:37:21.000000Z', value: 58, client_id: 1051235, platform_message_id: 4834473, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 58, "details": {"Mixed": 0.9513478875160217, "Neutral": 0.00017726761871017516, "Negative": 0.0034929041285067797, "Positive": 0.04498192295432091}, "sentiment": "Mixed"}',
      }],
      pivot: { platform_message_id: 4834575, client_id: 1051235 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: "The softonic app is a nice tool. However, it is full of bugs. The buttons don't work.",
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: "The softonic app is a nice tool. However, it is full of bugs. The buttons don't work.",
    date: '2023-05-11T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834569,
    sentiment: 'negative',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051229,
      company_id: 151823,
      name: 'Coral Hoffman',
      email: 'coral.hoffman@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#7FCD4B',
      is_team_member: false,
      latest_scores: [{
        id: 1678859, date: '2023-05-10T17:37:21.000000Z', value: 39, client_id: 1051229, platform_message_id: 4834569, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 39, "details": {"Mixed": 0.00006870239303680137, "Neutral": 0.0002966077299788594, "Negative": 0.9995635151863098, "Positive": 0.00007113970059435815}, "sentiment": "Negative"}',
      }, {
        id: 1678757, date: '2023-04-30T17:37:21.000000Z', value: 99, client_id: 1051229, platform_message_id: 4834467, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 99, "details": {"Mixed": 0.016454612836241722, "Neutral": 0.0041318596340715885, "Negative": 0.000505651521962136, "Positive": 0.9789078831672668}, "sentiment": "Positive"}',
      }],
      pivot: { platform_message_id: 4834569, client_id: 1051229 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: "I think the spelling tool is of low quality. It doesn't seem to recognize many words, and the suggestions it makes are often bizarre.",
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: "I think the spelling tool is of low quality. It doesn't seem to recognize many words, and the suggestions it makes are often bizarre.",
    date: '2023-05-10T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834555,
    sentiment: 'negative',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051215,
      company_id: 151823,
      name: 'Livia Dorsey',
      email: 'livia.dorsey@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#93E4C4',
      is_team_member: false,
      latest_scores: [{
        id: 1678845, date: '2023-05-09T17:37:21.000000Z', value: 31, client_id: 1051215, platform_message_id: 4834555, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 31, "details": {"Mixed": 0.10604029893875122, "Neutral": 0.08618763089179993, "Negative": 0.7878938913345337, "Positive": 0.019878145307302475}, "sentiment": "Negative"}',
      }, {
        id: 1678743, date: '2023-04-29T17:37:21.000000Z', value: 59, client_id: 1051215, platform_message_id: 4834453, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 59, "details": {"Mixed": 0.994857668876648, "Neutral": 0.00011368761624908075, "Negative": 0.004362863954156637, "Positive": 0.0006657408666796982}, "sentiment": "Mixed"}',
      }],
      pivot: { platform_message_id: 4834555, client_id: 1051215 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: "I think spelling never works in English. I mean, there are so many ways to spell words, and everyone seems to spell them differently. Even when you look up how to spell a word in the dictionary, there are usually multiple ways to spell it. So, I don't think there's any point in trying to spell words correctly in English.",
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: 'I think spelling never works in English. I mean, there are so many ways to spell words, and everyone seems to spell them differently. Even when you look up how to spell a word in the dictionary, there...',
    date: '2023-05-09T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834560,
    sentiment: 'positive',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051220,
      company_id: 151823,
      name: 'Tamar Connor',
      email: 'tamar.connor@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#93E49E',
      is_team_member: false,
      latest_scores: [{
        id: 1678850, date: '2023-05-09T17:37:21.000000Z', value: 100, client_id: 1051220, platform_message_id: 4834560, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 100, "details": {"Mixed": 0.00006660973303951323, "Neutral": 0.00016618031077086926, "Negative": 0.000027343463443685323, "Positive": 0.999739944934845}, "sentiment": "Positive"}',
      }, {
        id: 1678748, date: '2023-04-29T17:37:21.000000Z', value: 59, client_id: 1051220, platform_message_id: 4834458, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 59, "details": {"Mixed": 0.9900876879692078, "Neutral": 0.000051833234465448186, "Negative": 0.007282407023012638, "Positive": 0.002578054554760456}, "sentiment": "Mixed"}',
      }],
      pivot: { platform_message_id: 4834560, client_id: 1051220 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: "I like the popup builder. It's a great tool for learning how to create popups. The learning curve is relatively short, and it's a lot of fun to use.",
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: "I like the popup builder. It's a great tool for learning how to create popups. The learning curve is relatively short, and it's a lot of fun to use.",
    date: '2023-05-09T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834554,
    sentiment: 'neutral',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051214,
      company_id: 151823,
      name: 'Eliott Flower',
      email: 'eliott.flower@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#93E4C4',
      is_team_member: false,
      latest_scores: [{
        id: 1678844, date: '2023-05-09T17:37:21.000000Z', value: 58, client_id: 1051214, platform_message_id: 4834554, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 58, "details": {"Mixed": 0.9447134137153625, "Neutral": 0.0004982809186913073, "Negative": 0.05308293178677559, "Positive": 0.0017054349882528186}, "sentiment": "Mixed"}',
      }, {
        id: 1678742, date: '2023-04-28T17:37:21.000000Z', value: 59, client_id: 1051214, platform_message_id: 4834452, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 59, "details": {"Mixed": 0.9758200645446777, "Neutral": 0.00020728087110910565, "Negative": 0.0007976355263963342, "Positive": 0.023175032809376717}, "sentiment": "Mixed"}',
      }],
      pivot: { platform_message_id: 4834554, client_id: 1051214 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: "This app is pretty reliable but I don't like that it uses a subscription model. I'd rather pay for the app up front and then have access to all of its features. The subscription model just feels like a way to keep people paying month after month.",
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: "This app is pretty reliable but I don't like that it uses a subscription model. I'd rather pay for the app up front and then have access to all of its features. The subscription model just feels like...",
    date: '2023-05-09T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834551,
    sentiment: 'positive',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051211,
      company_id: 151823,
      name: 'Zoey Lynch',
      email: 'zoey.lynch@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#5AC115',
      is_team_member: false,
      latest_scores: [{
        id: 1678841, date: '2023-05-08T17:37:21.000000Z', value: 100, client_id: 1051211, platform_message_id: 4834551, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 100, "details": {"Mixed": 0.00005130292629473843, "Neutral": 0.0004843587812501937, "Negative": 0.00002009421405091416, "Positive": 0.9994441866874695}, "sentiment": "Positive"}',
      }, {
        id: 1678739, date: '2023-04-28T17:37:21.000000Z', value: 39, client_id: 1051211, platform_message_id: 4834449, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 39, "details": {"Mixed": 0.00002720810880418867, "Neutral": 0.0016759041463956237, "Negative": 0.9980733394622803, "Positive": 0.00022356004046741873}, "sentiment": "Negative"}',
      }],
      pivot: { platform_message_id: 4834551, client_id: 1051211 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: "I like the popup builder. It's a great tool for learning how to create popups. The learning curve is easy to follow and the results are impressive.",
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: "I like the popup builder. It's a great tool for learning how to create popups. The learning curve is easy to follow and the results are impressive.",
    date: '2023-05-08T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834548,
    sentiment: 'positive',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051208,
      company_id: 151823,
      name: 'Mirza Meyer',
      email: 'mirza.meyer@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#7AB395',
      is_team_member: false,
      latest_scores: [{
        id: 1678838, date: '2023-05-08T17:37:21.000000Z', value: 100, client_id: 1051208, platform_message_id: 4834548, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 100, "details": {"Mixed": 0.00004204083961667493, "Neutral": 0.0001706773618934676, "Negative": 0.000022852324036648497, "Positive": 0.9997643828392029}, "sentiment": "Positive"}',
      }, {
        id: 1678736, date: '2023-04-28T17:37:21.000000Z', value: 100, client_id: 1051208, platform_message_id: 4834446, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 100, "details": {"Mixed": 0.0008239247254095972, "Neutral": 0.00031214585760608315, "Negative": 0.0002700619224924594, "Positive": 0.9985938668251038}, "sentiment": "Positive"}',
      }],
      pivot: { platform_message_id: 4834548, client_id: 1051208 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: 'I think it is great popup builder nice layout good tools. It has a lot of features and options that make it very easy to use. The interface is also very user friendly. I would definitely recommend this to anyone looking for a popup builder.',
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: 'I think it is great popup builder nice layout good tools. It has a lot of features and options that make it very easy to use. The interface is also very user friendly. I would definitely recommend...',
    date: '2023-05-08T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834552,
    sentiment: 'positive',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051212,
      company_id: 151823,
      name: 'Manal Delgado',
      email: 'manal.delgado@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#AEE493',
      is_team_member: false,
      latest_scores: [{
        id: 1678842, date: '2023-05-08T17:37:21.000000Z', value: 100, client_id: 1051212, platform_message_id: 4834552, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 100, "details": {"Mixed": 0.0008422264363616705, "Neutral": 0.0009520967141725123, "Negative": 0.00011073439236497506, "Positive": 0.9980948567390442}, "sentiment": "Positive"}',
      }, {
        id: 1678740, date: '2023-04-28T17:37:21.000000Z', value: 100, client_id: 1051212, platform_message_id: 4834450, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 100, "details": {"Mixed": 0.00006576524174306542, "Neutral": 0.0009209713898599148, "Negative": 0.00003581419514375739, "Positive": 0.9989774227142334}, "sentiment": "Positive"}',
      }],
      pivot: { platform_message_id: 4834552, client_id: 1051212 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: "I really enjoy getting notifications from my favorite apps because they remind me to get back to them and use them more often. I think it's a great way to keep people engaged with your product and it also helps me to stay organized and on top of things.",
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: "I really enjoy getting notifications from my favorite apps because they remind me to get back to them and use them more often. I think it's a great way to keep people engaged with your product and it...",
    date: '2023-05-08T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834539,
    sentiment: 'negative',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051199,
      company_id: 151823,
      name: 'Usamah Herman',
      email: 'usamah.herman@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#93E49E',
      is_team_member: false,
      latest_scores: [{
        id: 1678829, date: '2023-05-07T17:37:21.000000Z', value: 38, client_id: 1051199, platform_message_id: 4834539, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 38, "details": {"Mixed": 0.0015459724236279726, "Neutral": 0.024752426892518997, "Negative": 0.9659343957901001, "Positive": 0.007767198141664267}, "sentiment": "Negative"}',
      }, {
        id: 1678727, date: '2023-04-27T17:37:21.000000Z', value: 99, client_id: 1051199, platform_message_id: 4834437, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 99, "details": {"Mixed": 0.004149513319134712, "Neutral": 0.006188440136611462, "Negative": 0.0034367749467492104, "Positive": 0.9862253665924072}, "sentiment": "Positive"}',
      }],
      pivot: { platform_message_id: 4834539, client_id: 1051199 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: 'I think spelling tools are average at best and low quality compared to other options.',
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: 'I think spelling tools are average at best and low quality compared to other options.',
    date: '2023-05-07T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834533,
    sentiment: 'neutral',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051193,
      company_id: 151823,
      name: 'Cheryl Black',
      email: 'cheryl.black@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#7AB395',
      is_team_member: false,
      latest_scores: [{
        id: 1678823, date: '2023-05-07T17:37:21.000000Z', value: 59, client_id: 1051193, platform_message_id: 4834533, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 59, "details": {"Mixed": 0.9918513894081116, "Neutral": 0.00010331419616704807, "Negative": 0.007655933033674955, "Positive": 0.0003893110842909664}, "sentiment": "Mixed"}',
      }, {
        id: 1678721, date: '2023-04-26T17:37:21.000000Z', value: 39, client_id: 1051193, platform_message_id: 4834431, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 39, "details": {"Mixed": 0.00016267011233139783, "Neutral": 0.0005128777120262384, "Negative": 0.9987897276878357, "Positive": 0.0005347392288967967}, "sentiment": "Negative"}',
      }],
      pivot: { platform_message_id: 4834533, client_id: 1051193 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: "The app is reliable, but I don't like the subscription model. It's a bit too expensive for what it is.",
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: "The app is reliable, but I don't like the subscription model. It's a bit too expensive for what it is.",
    date: '2023-05-07T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834542,
    sentiment: 'positive',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051202,
      company_id: 151823,
      name: 'Elif Goldsmith',
      email: 'elif.goldsmith@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#93E49E',
      is_team_member: false,
      latest_scores: [{
        id: 1678832, date: '2023-05-07T17:37:21.000000Z', value: 100, client_id: 1051202, platform_message_id: 4834542, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 100, "details": {"Mixed": 0.00002932600000349339, "Neutral": 0.0006164967198856175, "Negative": 0.00004109335350221954, "Positive": 0.9993131160736084}, "sentiment": "Positive"}',
      }, {
        id: 1678730, date: '2023-04-27T17:37:21.000000Z', value: 39, client_id: 1051202, platform_message_id: 4834440, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 39, "details": {"Mixed": 0.00002781964940368198, "Neutral": 0.0019369104411453009, "Negative": 0.9977994561195374, "Positive": 0.00023574399529024959}, "sentiment": "Negative"}',
      }],
      pivot: { platform_message_id: 4834542, client_id: 1051202 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: 'I think the Popup Builder is great! It has a nice layout and good tools. This makes it easy to create powerful popups that can help you improve your website.',
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: 'I think the Popup Builder is great! It has a nice layout and good tools. This makes it easy to create powerful popups that can help you improve your website.',
    date: '2023-05-07T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834538,
    sentiment: 'negative',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051198,
      company_id: 151823,
      name: 'Katya Mckinney',
      email: 'katya.mckinney@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#93E49E',
      is_team_member: false,
      latest_scores: [{
        id: 1678828, date: '2023-05-07T17:37:21.000000Z', value: 39, client_id: 1051198, platform_message_id: 4834538, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 39, "details": {"Mixed": 0.0003335625515319407, "Neutral": 0.0005901344120502472, "Negative": 0.9989066123962402, "Positive": 0.00016964042151812464}, "sentiment": "Negative"}',
      }, {
        id: 1678726, date: '2023-04-27T17:37:21.000000Z', value: 100, client_id: 1051198, platform_message_id: 4834436, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 100, "details": {"Mixed": 0.0005243098130449653, "Neutral": 0.0010688052279874682, "Negative": 0.00022123297094367445, "Positive": 0.9981856942176819}, "sentiment": "Positive"}',
      }],
      pivot: { platform_message_id: 4834538, client_id: 1051198 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: "I think the spelling tool is of low quality. It doesn't seem to catch all of the errors, and sometimes makes suggestions that aren't even close to the right word.",
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: "I think the spelling tool is of low quality. It doesn't seem to catch all of the errors, and sometimes makes suggestions that aren't even close to the right word.",
    date: '2023-05-07T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834527,
    sentiment: 'negative',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051187,
      company_id: 151823,
      name: 'Amna Allman',
      email: 'amna.allman@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#5ADD5A',
      is_team_member: false,
      latest_scores: [{
        id: 1678817, date: '2023-05-06T17:37:21.000000Z', value: 25, client_id: 1051187, platform_message_id: 4834527, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 25, "details": {"Mixed": 0.25264546275138855, "Neutral": 0.08442334085702896, "Negative": 0.6445561051368713, "Positive": 0.018375134095549583}, "sentiment": "Negative"}',
      }, {
        id: 1678715, date: '2023-04-26T17:37:21.000000Z', value: 56, client_id: 1051187, platform_message_id: 4834425, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 56, "details": {"Mixed": 0.8285295367240906, "Neutral": 0.0006762708653695881, "Negative": 0.0008917092345654964, "Positive": 0.16990253329277039}, "sentiment": "Mixed"}',
      }],
      pivot: { platform_message_id: 4834527, client_id: 1051187 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: "I think spelling never works in English. There are just too many rules and exceptions to remember. Even native speakers make mistakes sometimes. I think it's best to just memorize the spelling of the words you use most often.",
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: "I think spelling never works in English. There are just too many rules and exceptions to remember. Even native speakers make mistakes sometimes. I think it's best to just memorize the spelling of the...",
    date: '2023-05-06T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834515,
    sentiment: 'negative',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051175,
      company_id: 151823,
      name: 'Yash Naylor',
      email: 'yash.naylor@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#7FCD4B',
      is_team_member: false,
      latest_scores: [{
        id: 1678805, date: '2023-05-05T17:37:21.000000Z', value: 39, client_id: 1051175, platform_message_id: 4834515, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 39, "details": {"Mixed": 0.000035978155210614204, "Neutral": 0.0002482857380528003, "Negative": 0.9996602535247803, "Positive": 0.00005555733514484018}, "sentiment": "Negative"}',
      }, {
        id: 1678703, date: '2023-04-25T17:37:21.000000Z', value: 100, client_id: 1051175, platform_message_id: 4834413, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 100, "details": {"Mixed": 0.0003907359205186367, "Neutral": 0.006464043166488409, "Negative": 0.00005615958434646018, "Positive": 0.9930891394615173}, "sentiment": "Positive"}',
      }],
      pivot: { platform_message_id: 4834515, client_id: 1051175 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: "I think the spelling tool is of low quality. It doesn't seem to recognize many words, and the suggestions it makes are often inaccurate.",
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: "I think the spelling tool is of low quality. It doesn't seem to recognize many words, and the suggestions it makes are often inaccurate.",
    date: '2023-05-05T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834519,
    sentiment: 'neutral',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051179,
      company_id: 151823,
      name: 'Jannah East',
      email: 'jannah.east@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#55A45C',
      is_team_member: false,
      latest_scores: [{
        id: 1678809, date: '2023-05-05T17:37:21.000000Z', value: 56, client_id: 1051179, platform_message_id: 4834519, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 56, "details": {"Mixed": 0.8242409229278564, "Neutral": 0.011410877108573914, "Negative": 0.15994462370872498, "Positive": 0.0044035352766513824}, "sentiment": "Mixed"}',
      }, {
        id: 1678707, date: '2023-04-25T17:37:21.000000Z', value: 39, client_id: 1051179, platform_message_id: 4834417, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 39, "details": {"Mixed": 0.00013027930981479585, "Neutral": 0.00006051632954040542, "Negative": 0.9996868371963501, "Positive": 0.0001223841099999845}, "sentiment": "Negative"}',
      }],
      pivot: { platform_message_id: 4834519, client_id: 1051179 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: "UX has a few problems here and there. It's sometimes hard to find what I need to do my work. I think the team can invest more effort into the interface.",
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: "UX has a few problems here and there. It's sometimes hard to find what I need to do my work. I think the team can invest more effort into the interface.",
    date: '2023-05-05T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834503,
    sentiment: 'negative',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051163,
      company_id: 151823,
      name: 'Zacharia Coombes',
      email: 'zacharia.coombes@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#80C186',
      is_team_member: false,
      latest_scores: [{
        id: 1678793, date: '2023-05-04T17:37:21.000000Z', value: 39, client_id: 1051163, platform_message_id: 4834503, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 39, "details": {"Mixed": 0.000043622021621558815, "Neutral": 0.00015055734547786415, "Negative": 0.9997606873512268, "Positive": 0.00004514326064963825}, "sentiment": "Negative"}',
      }, {
        id: 1678691, date: '2023-04-23T17:37:21.000000Z', value: 50, client_id: 1051163, platform_message_id: 4834401, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 50, "details": {"Mixed": 0.5186364650726318, "Neutral": 0.03588801622390747, "Negative": 0.2988615334033966, "Positive": 0.14661403000354767}, "sentiment": "Mixed"}',
      }],
      pivot: { platform_message_id: 4834503, client_id: 1051163 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: "I don't recommend the softonic app. It crashes all the time, has an ugly UX, and an old design. The staff is unresponsive.",
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: "I don't recommend the softonic app. It crashes all the time, has an ugly UX, and an old design. The staff is unresponsive.",
    date: '2023-05-04T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834505,
    sentiment: 'negative',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051165,
      company_id: 151823,
      name: 'Karina Solomon',
      email: 'karina.solomon@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#93E4C4',
      is_team_member: false,
      latest_scores: [{
        id: 1678795, date: '2023-05-04T17:37:21.000000Z', value: 39, client_id: 1051165, platform_message_id: 4834505, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 39, "details": {"Mixed": 0.0015076084528118372, "Neutral": 0.00012732140021398664, "Negative": 0.9982047080993652, "Positive": 0.00016040320042520761}, "sentiment": "Negative"}',
      }, {
        id: 1678693, date: '2023-04-24T17:37:21.000000Z', value: 35, client_id: 1051165, platform_message_id: 4834403, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 35, "details": {"Mixed": 0.004870792850852013, "Neutral": 0.10491425544023514, "Negative": 0.8875657916069031, "Positive": 0.002649143571034074}, "sentiment": "Negative"}',
      }],
      pivot: { platform_message_id: 4834505, client_id: 1051165 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: "I wouldn't recommend the softonic app for professionals. The chat integration website is slow and ugly, and it's not enough for me.",
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: "I wouldn't recommend the softonic app for professionals. The chat integration website is slow and ugly, and it's not enough for me.",
    date: '2023-05-04T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834511,
    sentiment: 'positive',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051171,
      company_id: 151823,
      name: 'Emily-Jane Rodriguez',
      email: 'emily-jane.rodriguez@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#5ADD5A',
      is_team_member: false,
      latest_scores: [{
        id: 1678801, date: '2023-05-04T17:37:21.000000Z', value: 99, client_id: 1051171, platform_message_id: 4834511, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 99, "details": {"Mixed": 0.01946195773780346, "Neutral": 0.000487975514261052, "Negative": 0.0000727958686184138, "Positive": 0.9799773693084717}, "sentiment": "Positive"}',
      }, {
        id: 1678699, date: '2023-04-24T17:37:21.000000Z', value: 100, client_id: 1051171, platform_message_id: 4834409, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 100, "details": {"Mixed": 0.00013257625687401742, "Neutral": 0.004803800024092197, "Negative": 0.000043718428059946746, "Positive": 0.995019793510437}, "sentiment": "Positive"}',
      }],
      pivot: { platform_message_id: 4834511, client_id: 1051171 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: "I've been using the Popup Builder for a while now and I think it's a great tool. The learning curve is definitely steep, but once you get the hang of it, it's really easy to use. With this tool, you can easily create beautiful, responsive popups that look great on any device.",
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: "I've been using the Popup Builder for a while now and I think it's a great tool. The learning curve is definitely steep, but once you get the hang of it, it's really easy to use. With this tool, you...",
    date: '2023-05-04T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834504,
    sentiment: 'negative',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051164,
      company_id: 151823,
      name: 'Anayah Ortiz',
      email: 'anayah.ortiz@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#7AB395',
      is_team_member: false,
      latest_scores: [{
        id: 1678794, date: '2023-05-04T17:37:21.000000Z', value: 39, client_id: 1051164, platform_message_id: 4834504, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 39, "details": {"Mixed": 0.00008576640539104119, "Neutral": 0.00011525951413204893, "Negative": 0.9997528195381165, "Positive": 0.00004615186480805278}, "sentiment": "Negative"}',
      }, {
        id: 1678692, date: '2023-04-23T17:37:21.000000Z', value: 38, client_id: 1051164, platform_message_id: 4834402, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 38, "details": {"Mixed": 0.021460620686411858, "Neutral": 0.0062121800146996975, "Negative": 0.9699944257736206, "Positive": 0.00233271112665534}, "sentiment": "Negative"}',
      }],
      pivot: { platform_message_id: 4834504, client_id: 1051164 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: 'I do not recommend the softonic app. It crashes all the time. The UX is ugly. The design is old. The staff is bad.',
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: 'I do not recommend the softonic app. It crashes all the time. The UX is ugly. The design is old. The staff is bad.',
    date: '2023-05-04T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }, {
    id: 4834509,
    sentiment: 'positive',
    topic: {
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    },
    topics: [{
      id: 5669, name: 'Overall perception', count: { value: 0, previous_value: 0, trend: 'ascending' }, ratio: { value: 0, previous_value: 0, trend: 'ascending' }, mrr: { value: null, previous_value: null, trend: 'ascending' }, sentiment: { positive: 0, negative: 0 }, included_keywords: ['I think', 'I like', "I don't like", 'Softonic App'], excluded_keywords: [], type: 'keyword_based', annotations: null, groups: [{ id: 1489, name: 'Brand', color: '#959595' }],
    }],
    topic_group: { id: 1489, name: 'Brand', color: '#959595' },
    source: 'demo',
    client: {
      id: 1051169,
      company_id: 151823,
      name: 'Courteney Schultz',
      email: 'courteney.schultz@demo.com',
      created_at: '2023-05-12T17:37:21.000000Z',
      updated_at: '2023-05-12T17:37:21.000000Z',
      deleted_at: null,
      score: 0,
      color: '#93E49E',
      is_team_member: false,
      latest_scores: [{
        id: 1678799, date: '2023-05-04T17:37:21.000000Z', value: 100, client_id: 1051169, platform_message_id: 4834509, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 100, "details": {"Mixed": 0.00003574408037820831, "Neutral": 0.000200889611733146, "Negative": 0.000030899132980266586, "Positive": 0.9997324347496033}, "sentiment": "Positive"}',
      }, {
        id: 1678697, date: '2023-04-24T17:37:21.000000Z', value: 100, client_id: 1051169, platform_message_id: 4834407, created_at: '2023-05-12T17:37:22.000000Z', updated_at: '2023-05-12T17:37:22.000000Z', data: '{"score": 100, "details": {"Mixed": 0.000055822682043071836, "Neutral": 0.0002025095309363678, "Negative": 0.000022967224140302278, "Positive": 0.9997186064720154}, "sentiment": "Positive"}',
      }],
      pivot: { platform_message_id: 4834509, client_id: 1051169 },
    },
    keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    text: "I think the Popup Builder is great! It has a nice layout and good tools. I haven't had any problems with it so far.",
    snippet_keywords: [{ text: 'I think' }, { text: 'I like' }, { text: "I don't like" }, { text: 'Softonic App' }],
    snippet: "I think the Popup Builder is great! It has a nice layout and good tools. I haven't had any problems with it so far.",
    date: '2023-05-04T17:37:21.000000Z',
    external_link: null,
    intents: [],
  }],
  pager: {
    total: 102, per_page: 25, current_page: 1, last_page: 5, next_page_url: '/conversations?page=2', prev_page_url: null, from: 1, to: 25,
  },
};
const topicActionsProps = {};
const shouldUpdateMetrics = false;
const dispatch = () => { };
const existReleases = data?.annotations?.length > 0;
const handleModalVisibility = () => { };
const chartFilters = {};
const onChartSelectedDaysChange = () => { };
const getTabProps = (type) => ({
  shape: 'round',
  className: `topic-dashboard-tab ${type === tabs.CONVERSATIONS ? 'topic-dashboard-tab-active' : ''}`,
  onClick: () => dispatch(
    { type: SET_TAB_ACTION, payload: type },
  ),
});
const searchProps = {
  className: 'search',
  placeholder: 'Keywords',
  prefix: <SearchOutlined />,
};
const conversationsTableProps = {
  filters: {
    topic_id: 5669,
    group_id: 1489,
    search: '',
    start: moment().subtract(90, 'days').format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
    days: [],
  },
  dataConversations,
};

const Index = ({ children }) => {
  const defaultValue = useSentimentDateFilterInitialValue();

  return (
    <div className="onboarding-background-wrapper">
      <Layout>
        <div className="topic-dashboard">
          <Affix subheader>
            <div className="gordita-medium-4 flex flex-center" style={{ justifyContent: 'space-between' }}>
              <div>
                <span className="mr-md">{data.name}</span>
                <Tag color={data.group.color}>{data.group.name}</Tag>
              </div>
              <div>
                <SentimentRadioGroupDateFilter
                  initialValue={defaultValue}
                  onChange={(value) => {
                    dispatch({ type: SET_RANGE_FILTER_ACTION, payload: value });
                  }}
                />
                {data.type === 'keyword_based' ? (
                  <TopicActions {...topicActionsProps} />
                ) : null}
              </div>
            </div>
            <div className="topic-dashboard-header">
              <div className={classNames('hot-topic__metrics dashboard-header-metrics', { loading: shouldUpdateMetrics })}>
                {shouldUpdateMetrics && !data ? <Spin /> : (
                  <>
                    <div className="painpoint-dashboard-metric">
                      <div className="gordita-regular-1">Feedback</div>
                      <TopicMetric
                        value={data.count.value}
                        trend={data.count.trend}
                        tooltip={`${data.count.value} conversations over the selected period`}
                        difference={calculateDifference(
                          data.count.value, data.count.previous_value,
                        )}
                      />
                    </div>
                    <div className="painpoint-dashboard-metric">
                      <div className="gordita-regular-1">Ratio</div>
                      <TopicMetric
                        value={`${Math.ceil(data.ratio.value * 100)}%`}
                        trend={data.ratio.trend}
                        tooltip={`appears in ${Math.ceil(data.ratio.value * 100)}% of total conversations in the selected period`}
                      />
                    </div>
                    <div className="painpoint-dashboard-metric">
                      <div className="gordita-regular-1">MRR</div>
                      <TopicMetric.MRR
                        value={data.mrr.value}
                        trend={data.mrr.trend}
                        tooltip={`$${Math.round(data.mrr.value)} estimated monthly revenue by users discussing this topic`}
                      />
                    </div>
                    <div className="painpoint-dashboard-metric">
                      <div className="gordita-regular-1">Sentiment</div>
                      <SentimentTooltip sentiment={data.sentiment} style={{ display: 'block', width: 124, marginTop: 8 }}>
                        <Progress.Sentiment
                          positive={data.sentiment.positive}
                          negative={data.sentiment.negative}
                        />
                      </SentimentTooltip>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Affix>
          <GradientSentimentChart
            filters={chartFilters}
            hasInteractions
            onChange={onChartSelectedDaysChange}
            dataSentiment={dataSentiment}
          >
            <div className="topic-dashboard-header-actions" style={{ justifyContent: 'end', paddingTop: 22 }}>
              {existReleases ? (
                <AnnotationActions
                  onAdd={() => handleModalVisibility(modals.CREATE_ANNOTATION)}
                  onList={() => handleModalVisibility(modals.LIST_ANNOTATIONS)}
                />
              ) : (
                <Button
                  onClick={() => handleModalVisibility(modals.CREATE_ANNOTATION)}
                  htmlType="button"
                  type="default"
                  icon={<AnnotationIcon className="annotation-actions__btn-icon" />}
                >
                  Add release
                </Button>
              )}
            </div>
          </GradientSentimentChart>
          <div className="topic-dashboard-section-2">
            <div className="topic-dashboard-bottom">
              <div className="topic-dashboard-tabs">
                <Button {...getTabProps(tabs.CONVERSATIONS)}>
                  Conversations
                </Button>
                <Button {...getTabProps(tabs.PEOPLE)}>
                  People
                </Button>
              </div>
              <Input {...searchProps} />
            </div>
            <ConversationsTable {...conversationsTableProps} />
          </div>
        </div>
      </Layout>
      <div className="onboarding-background">
        {children}
      </div>
    </div>
  );
};

export default Index;
