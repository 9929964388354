import React from 'react';
import {
  Col, Form, Input, Row, Select,
} from 'antd';
import rules from '../../helpers/rules';
import countries from './countries';

const { Option } = Select;

const BillingForm = () => (
  <>
    <Row gutter={16} style={{ marginBottom: 15 }}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <h3 style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Billing address</h3>
      </Col>
    </Row>
    <Row gutter={16}>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <Form.Item
          className="mb-lg"
          name="name"
          rules={rules.name('Full Name is required')}
          label="Name"
        >
          <Input size="large" placeholder="Name" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <Form.Item
          className="mb-lg"
          name="country"
          rules={rules.required('Country is required')}
          label="Country"
        >
          <Select size="large" placeholder="Country">
            {countries.map((country) => <Option value={country.code}>{country.name}</Option>)}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <Form.Item
          className="mb-lg"
          name="city"
          rules={rules.required('City is required')}
          label="City"
        >
          <Input size="large" placeholder="City" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <Form.Item
          className="mb-lg"
          name="address_line_1"
          rules={rules.required('Address is required')}
          label="Address"
        >
          <Input size="large" placeholder="Address" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <Form.Item
          className="mb-lg"
          name="address_line_2"
          label="Address 2"
        >
          <Input size="large" placeholder="Address 2" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12}>
        <Form.Item
          className="mb-lg"
          name="postal_code"
          rules={rules.required('Postal Code is required')}
          label="Postal Code"
        >
          <Input size="large" placeholder="Postal Code" />
        </Form.Item>
      </Col>
    </Row>
  </>
);

export default BillingForm;
