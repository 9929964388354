import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { notification } from 'antd';
import {
  GATHERING_DATA_INTEGRATIONS,
} from '@/base-components/integrations/components/Integrations';

const AppWrapper = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== '/add-platforms' && !location.pathname.includes('demo')) {
      notification.close(GATHERING_DATA_INTEGRATIONS);
    }
  }, [location.pathname]);

  return children;
};

export default AppWrapper;
