import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, Redirect } from 'react-router-dom';
import Layout from '@/components/Layout';
import TopicDashboardPage from '@/components/topic-dashboard';
import mixpanel, { mixpanelEvents } from '@/mixpanel';

const TopicDashboard = () => {
  const location = useLocation();

  useEffect(() => {
    mixpanel.track(mixpanelEvents.VIEW_TOPIC);
  }, []);

  if (location.pathname.includes('topics')) {
    return <Redirect to={location.pathname.replace('topics', 'painpoints')} />;
  }

  return (
    <Layout title="Topic">
      <Helmet>
        <title>ClientZen - Topic Dashboard</title>
      </Helmet>
      <TopicDashboardPage />
    </Layout>
  );
};

export default TopicDashboard;
