/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useState } from 'react';
import { Button, Checkbox } from 'antd';
import './NotificationsTab.less';
import { teams } from '@/api';
import { displayOrCatchMessage } from '@/helpers';
import { connect } from 'react-redux';
import userAuthenticatedAction from '@/store/actions/userAuthenticated';

const NotificationsTab = ({ user, userAuthenticated }) => {
  const [weeklyReportChecked, setWeeklyReportChecked] = useState(user.weekly_report_enabled);
  const [loading, setLoading] = useState(false);
  const onWeeklyReportToggle = useCallback(() => {
    setWeeklyReportChecked((prev) => !prev);
  }, []);
  const onSave = useCallback(() => {
    setLoading(true);
    teams
      .setWeeklyReportEnabled(weeklyReportChecked)
      .then(() => {
        userAuthenticated({
          ...user,
          weekly_report_enabled: weeklyReportChecked,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        displayOrCatchMessage(error);
      });
  }, [weeklyReportChecked, user]);

  return (
    <div className="settings-tab notifications-tab container-sm">
      <h2>Notification preferences</h2>
      <p>Update your notification preferences here.</p>
      <ul className="mt-8 max-w-xl p-0">
        <li
          onClick={onWeeklyReportToggle}
          className="p-2.5 mt-3.5 border rounded-md border-solid border-[#E8E8E8] text-[#595959] flex justify-between cursor-pointer"
        >
          <div className="flex justify-between min-w-full">
            <div className="flex space-x-2">
              <Checkbox checked={weeklyReportChecked} />
              <span>Send a weekly report with key insights</span>
            </div>
          </div>
        </li>
      </ul>
      <div className="flex justify-end max-w-xl">
        <Button type="primary" onClick={onSave} loading={loading}>Save</Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  userAuthenticated: (data) => dispatch(userAuthenticatedAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsTab);
