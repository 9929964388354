import React from 'react';
import Integrations from './components/Integrations';
import IntegrationsProvider from './store/IntegrationsProvider';

const Index = (props) => (
  <IntegrationsProvider>
    <Integrations {...props} />
  </IntegrationsProvider>
);

export default Index;
