import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const RedirectWithHistory = () => {
  const [url, setUrl] = useState();

  useEffect(() => {
    const route = localStorage.getItem('last_login_route');
    localStorage.removeItem('last_login_route');

    setUrl(route || '/');
  }, []);

  if (url) {
    return <Redirect push to={url} />;
  }

  return null;
};

const UnauthenticatedRoute = ({ children, user, ...props }) => (
  !user ? (
    <Route {...props}>
      {children}
    </Route>
  ) : <RedirectWithHistory />
);

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(UnauthenticatedRoute);
