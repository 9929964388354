import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import TagSelect from '@/base-components/topic-modal/components/TagSelect';
import {
  Button, Divider, Dropdown, Menu, Tag,
} from 'antd';
import {
  CheckIcon,
  FoldHorizontalIcon,
  MoreVertical, PlusIcon, TrashIcon, UnfoldHorizontalIcon, X, XIcon,
} from 'lucide-react';

import { cn } from '@/helpers/util';
import { useFilters, useFiltersDispatch } from '../context';

const KeywordTagSelect = forwardRef(({ defaultValue = [], type }, ref) => {
  const { filters } = useFilters();
  const dispatch = useFiltersDispatch();
  const selectRef = useRef(null);

  const handleReplace = (selectedValue, currentSuffix, newSuffix = null) => {
    if (currentSuffix === newSuffix) return;
    const [currentValue] = selectedValue.split('::');
    selectRef.current.replaceItem(selectedValue, `${currentValue}${newSuffix ? `::${newSuffix}` : ''}`);

    const newField = filters[type].map((item) => {
      if (item === selectedValue) {
        return `${currentValue}${newSuffix ? `::${newSuffix}` : ''}`;
      }
      return item;
    });

    dispatch({ type: 'SET_FILTERS_FIELD', field: type, value: newField });
  };

  const handleRemove = (value) => {
    selectRef.current?.deselectItem(value);
    dispatch({ type: 'REMOVE_FILTERS_FIELD', field: type, value });
  };

  const handleClear = () => {
    selectRef.current?.resetItems();
    dispatch({ type: 'SET_FILTERS_FIELD', field: type, value: [] });
  };

  function KeywordTag({
    label, value, onClose,
  }) {
    const [, suffix] = value.split('::');
    const [preparedLabel] = label.split('::');

    return (
      <Tag
        closeIcon={<XIcon size={12} color="#CEDBE4" />}
        onClose={onClose}
        className="!text-[#202324] !border-none !py-1 !pr-1 !bg-[#F6F9FB] !rounded flex items-center gap-1 [&>.ant-tag-close-icon]:flex"
      >
        {preparedLabel}
        <Dropdown
          trigger={['click']}
          overlay={(
            <Menu className="!rounded-md shadow-[0px_1px_12px_#CAD7E180] border border-solid border-[#DFE9F1]">
              <Menu.Item className="hover:rounded-[3px] hover:!bg-[#F6F9FB] text-[#202324]" onClick={() => handleReplace(value, suffix)}>
                <span className="flex items-center gap-2">
                  <UnfoldHorizontalIcon size={16} />
                  <span>Broad batch</span>
                  {
                    !suffix && (
                      <CheckIcon size={18} color="#0BAA6B" />
                    )
                  }
                </span>
              </Menu.Item>
              <Menu.Item className="hover:rounded-[3px] hover:!bg-[#F6F9FB] text-[#202324]" onClick={() => handleReplace(value, suffix, 'exact')}>
                <span className="flex items-center gap-2">
                  <FoldHorizontalIcon size={16} />
                  <span>Exact match</span>
                  {
                    suffix === 'exact' && (
                      <CheckIcon size={18} color="#0BAA6B" />
                    )
                  }
                </span>
              </Menu.Item>
              <Divider className="!my-1" />
              <Menu.Item className="hover:rounded-[3px] hover:!bg-[#F6F9FB] text-[#202324]" onClick={() => handleRemove(value)}>
                <span className="flex items-center gap-2">
                  <TrashIcon size={16} />
                  <span>Remove</span>
                </span>
              </Menu.Item>
            </Menu>
          )}
        >
          <Button type="link" className="!p-0 flex items-center !h-auto hover:bg-[#CFDBE4]">
            <MoreVertical size={16} color="#75899B" />
          </Button>
        </Dropdown>
      </Tag>
    );
  }

  const handleChange = (e) => {
    dispatch({ type: 'SET_FILTERS_FIELD', field: type, value: e });
  };

  useEffect(() => {
    if (!defaultValue) return;
    dispatch({ type: 'SET_FILTERS_FIELD', field: type, value: defaultValue });
  }, []);

  useImperativeHandle(ref, () => ({
    handleClear,
  }));

  return (
    <TagSelect
      ref={selectRef}
      placeholder="Add keywords"
      value={defaultValue}
      className="[&>.ant-select-selector]:!shadow-none !mt-2 !px-1 !py-1 w-full [&_.ant-select-selector]:!rounded-md [&_.ant-select-selector]:!border-none [&>.ant-select-selector]:!p-1 [&>.ant-select-clear]:!right-3 [&>.ant-select-clear]:!top-[45%] [&_.ant-select-selection-overflow]:gap-1"
      onChange={handleChange}
      tagRender={KeywordTag}
      onClear={handleClear}
      clearIcon={<XIcon size={16} color="#75899B" />}
    />
  );
});

// include / exclude
export function KeywordFilter({ defaultValue, name }) {
  const { filters } = useFilters();
  const dispatch = useFiltersDispatch();
  const [collapsed, setCollapsed] = useState(defaultValue.length === 0);
  const type = name.includes('exclude') ? 'excluded_keywords' : 'included_keywords';
  const selectRef = useRef(null);

  const handleClear = (e) => {
    e.stopPropagation();
    selectRef.current.handleClear();
    dispatch({ type: 'SET_FILTERS_FIELD', field: type, value: [] });
  };

  return (
    <div className="space-y-0.5">
      <div className="group flex flex-col border-0 border-b border-solid border-[#EFF4F8] hover:border-[#DFE9F1] pb-3 cursor-pointer">
        <div aria-hidden onClick={() => setCollapsed((prev) => !prev)} className="group/button px-4 hover:border-[#DFE9F1] flex items-center justify-between text-[#75899B] text-sm">
          <span className="group-hover:text-[#44596C]">{name}</span>
          <div className="flex gap-1">
            <Button
              type="text"
              onClick={handleClear}
              className={cn(
                'opacity-0 group-hover:opacity-100 flex items-center !rounded-md !p-2 justify-between !text-sm !text-[#75899B] hover:!bg-[#EFF4F8] hover:!text-[#44596C]',
                filters[type].length === 0 && 'group-hover:opacity-0',
              )}
            >
              <X size={20} />
            </Button>
            <Button
              type="text"
              className={cn(
                'flex items-center !rounded-md !p-2 justify-between !text-sm !text-[#75899B]',
                filters[type].length === 0 ? 'group-hover/button:!bg-[#EFF4F8] group-hover/button:!text-[#44596C]' : 'hover:!bg-[#EFF4F8] hover:!text-[#44596C]',
              )}
            >
              <PlusIcon size={20} />
            </Button>
          </div>
        </div>
        {!collapsed && (
          <KeywordTagSelect ref={selectRef} defaultValue={defaultValue} type={type} />
        )}
      </div>
    </div>
  );
}

KeywordFilter.Select = KeywordTagSelect;
