import React, { useMemo } from 'react';
import { Pagination } from 'antd';

/**
 *    Default pagination for Antd Table
 *    Config `pagination={false} footer={() => <TablePagination {...props} />}`
 */
const TablePagination = ({ type, pagination, onPageChange }) => {
  const current = useMemo(
    () => Math.min(pagination.current * pagination.pageSize, pagination.total),
    [pagination.current, pagination.pageSize, pagination.total],
  );

  return (
    <div className="table-pagination">
      <p className="table-pagination__count">
        {`${current} ${type} of ${pagination.total}`}
      </p>
      <Pagination
        pageSize={pagination.pageSize}
        current={pagination.current}
        total={pagination.total}
        onChange={onPageChange}
      />
    </div>
  );
};

export default TablePagination;
