import React, { useState } from 'react';
import { insights } from '@/api';
import InsightHelpfulMarkButton from './InsightHelpfulMarkButton';
import { displayOrCatchMessage } from '../../helpers';

const onClick = ({
  insight,
  feedback,
  setSelectedFeedback,
  setLoading,
  loading,
  currentFeedback,
}) => () => {
  if (!loading) {
    setLoading(true);
    setSelectedFeedback(feedback);
    insights.mark(feedback, insight.key).then(() => {
      setLoading(false);
    }).catch((e) => {
      displayOrCatchMessage(e);
      setLoading(false);
      setSelectedFeedback(currentFeedback);
    });
  }
};

const getInitialFeedback = (insight) => {
  if (insight.marked_as_helpful === true) {
    return 'positive';
  }

  if (insight.marked_as_helpful === false) {
    return 'negative';
  }

  return null;
};

const InsightHelpfulMark = ({ insight }) => {
  const [loading, setLoading] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(getInitialFeedback(insight));

  return (
    <span>
      Accurate?
      <InsightHelpfulMarkButton
        onClick={onClick({
          insight,
          feedback: 'positive',
          setSelectedFeedback,
          setLoading,
          loading,
          currentFeedback: selectedFeedback,
        })}
        highlighted={selectedFeedback === 'positive'}
        feedback="positive"
      />
      <InsightHelpfulMarkButton
        onClick={onClick({
          insight,
          feedback: 'negative',
          setSelectedFeedback,
          setLoading,
          loading,
          currentFeedback: selectedFeedback,
        })}
        highlighted={selectedFeedback === 'negative'}
        feedback="negative"
      />
    </span>
  );
};

export default InsightHelpfulMark;
