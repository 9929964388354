import React from 'react';
import IconGmailSmall from '../../../../../../images/integrations/icon-gmail-small.svg';
import IconIntercomSmall from '../../../../../../images/integrations/icon-intercom-small.svg';
import IconZendeskSmall from '../../../../../../images/integrations/icon-zendesk-small.svg';
import IconCSVSmall from '../../../../../../images/integrations/icon-csv-small.svg';
import IconZapierSmall from '../../../../../../images/integrations/icon-zapier-small.svg';
import IconTypeformSmall from '../../../../../../images/integrations/icon-typeform-small.svg';

export const IntegrationIcons = {
  google: IconGmailSmall,
  intercom: IconIntercomSmall,
  zendesk: IconZendeskSmall,
  csv: IconCSVSmall,
  demo: IconCSVSmall,
  zapier: IconZapierSmall,
  typeform: IconTypeformSmall,
};

const ConversationSource = ({ conversation }) => (conversation.external_link ? (
  <a className="external_link" href={conversation.external_link} target="_blank" rel="noopener noreferrer">
    <img src={IntegrationIcons[conversation.source]} alt={conversation.source} />
  </a>
) : (
  <img src={IntegrationIcons[conversation.source]} alt={conversation.source} />
));

export default ConversationSource;
