/* eslint-disable no-shadow */
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import * as Sentry from '@sentry/react';
import TagSelect from '@/base-components/topic-modal/components/TagSelect';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Skeleton } from 'antd';
import { displayOrCatchMessage } from '@/helpers';
import { settings } from '@/api';
import './ConversationExclusionTab.less';

const ConversationExclusionTab = () => {
  const queryClient = useQueryClient();
  const [submitting, setSubmitting] = useState(false);
  const [finding, setFinding] = useState(false);
  const [loading, setLoading] = useState(true);
  const [keywords, setKeywords] = useState([]);
  const selectRef = useRef(null);
  const onSubmit = useCallback(() => {
    setSubmitting(true);
    settings.replaceExcludedKeywords({ keywords }).then(() => {
      try {
        queryClient.getQueryCache().clear();
      } catch (e) {
        Sentry.captureException(e);
      }
      setSubmitting(false);
    }).catch((e) => {
      setSubmitting(false);
      displayOrCatchMessage(e);
    });
  }, [keywords]);
  const onFind = useCallback(() => {
    setFinding(true);
    settings.suggestExcludedKeywords().then((response) => {
      setFinding(false);
      setKeywords((oldKeywords) => {
        const newKeywords = [
          ...oldKeywords,
          ...response.data.filter((keyword) => oldKeywords.indexOf(keyword) === -1),
        ];
        if (selectRef.current) {
          selectRef.current.resetItems();
          newKeywords.forEach((k) => {
            selectRef.current.selectItem(k);
          });
        }
        return newKeywords;
      });
    }).catch((e) => {
      setFinding(false);
      displayOrCatchMessage(e);
    });
  }, []);

  useEffect(() => {
    const fetchExcludedKeywords = async () => {
      setLoading(true);
      try {
        const { data } = await settings.getExcludedKeywords();
        setKeywords(data.map((keyword) => keyword.keyword));
        setLoading(false);
      } catch (e) {
        displayOrCatchMessage(e);
      } finally {
        setLoading(false);
      }
    };

    fetchExcludedKeywords();
  }, []);

  return (
    <div className="conversation-exclusion-tab">
      <h2>Exclude conversations from insights</h2>
      <p>
        Select what conversations you want to filter out
      </p>

      {loading ? (
        <Skeleton.Input active className="!block !rounded-md [&>span]:mt-8 !mb-2.5 !h-[52px]" size="large" />
      ) : (
        <>
          <div className="mt-8">
            <TagSelect
              ref={selectRef}
              listHeight={120}
              tokenSeparators={['\n', ';']}
              placeholder="Keywords"
              className="conversation-filters"
              onChange={setKeywords}
              value={keywords}
              getPopupContainer={(el) => el}
            />
          </div>
          <div className="exclude-container">
            <Button
              loading={finding}
              onClick={onFind}
              size="medium"
              className="mr-2"
              type="secondary"
            >
              Find automatically
            </Button>
            <Button
              loading={submitting}
              onClick={onSubmit}
              size="medium"
              className="cta exclude-btn"
              type="primary"
            >
              Exclude
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default ConversationExclusionTab;
