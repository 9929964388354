import { clients } from '@/api';
import { useQuery } from '@tanstack/react-query';

export default function usePeople(id, filters, params, options) {
  return useQuery(['people', id, filters, params], {
    queryFn: async () => {
      const { data: res } = await clients.show(id, filters, params);
      return res.data;
    },
    ...options,
  });
}
