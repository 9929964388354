import React, { useState, useEffect } from 'react';
import {
  Modal as AntModal, Form as AntdForm, Button, message,
} from 'antd';
import { aiSubtopics } from '@/api';
import { useParams } from 'react-router-dom';
import { displayOrCatchMessage, useErrorState } from '../../helpers';
import Form from './Form';

const Modal = ({
  visible, onCancel, onOk,
}) => {
  const defaultPrompt = 'We have a summary that describes one issue our users are facing. Is this summary about {keyword}? Answer only with yes or no: {line}';
  const { topicId } = useParams();
  const [form] = AntdForm.useForm();
  const [error, setError] = useErrorState();
  const [loading, setLoading] = useState(false);

  useEffect(() => () => {
    setError(null);
  }, [visible]);

  const cancelHandler = () => {
    if (!loading) {
      form.setFieldsValue({ name: '', keywords: '', prompt: defaultPrompt });
      onCancel();
    }
  };

  const formProps = {
    form,
    error,
    defaultValues: { name: '', keywords: '', prompt: defaultPrompt },
    onSubmit: ({ name, keywords, prompt }) => {
      const keywordList = keywords.split('\n').map((keyword) => keyword.trim()).filter((keyword) => keyword !== '');
      aiSubtopics.create({
        name,
        topics: [topicId],
        keywords: keywordList,
        prompt,
      }).then(({ data }) => {
        const intervalId = setInterval(() => {
          aiSubtopics.show(data.id).then((result) => {
            if (result.data.status === 'completed') {
              clearInterval(intervalId);
              setLoading(false);
              onOk();
              message.success('Product category created successfully');
            }
          }).catch(() => {});
        }, 4000);
      }).catch((e) => {
        displayOrCatchMessage(e);
        setLoading(false);
      });
      setLoading(true);
    },
  };

  return (
    <AntModal
      title="Create new ai subtopic"
      visible={visible}
      className="create-modal"
      width={650}
      onCancel={cancelHandler}
      destroyOnClose
      footer={[
        <Button
          key="cancel"
          disabled={loading}
          onClick={cancelHandler}
        >
          Cancel
        </Button>,
        <Button
          key="create"
          type="primary"
          size="large"
          loading={loading}
          onClick={() => form.submit()}
        >
          Create ai subtopic category
        </Button>,
      ]}
    >
      <Form {...formProps} />
    </AntModal>
  );
};

export default Modal;
