/* eslint-disable camelcase */
import { buildQuery } from '@/helpers/filters';
import axios from './axios';
import auth from './auth';

const createSubtopic = (values) => axios.post(
  '/api/subtopics',
  values, {
    params: {
      team_id: auth.team().id,
    },
  },
)
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const getPreview = (values) => axios.post(
  '/api/subtopics/preview', values, {
    params: {
      team_id: auth.team().id,
    },
  },
)
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const getSubtopics = (id, filters = {}, params = {}) => axios.get(buildQuery(
  '/api/subtopics', { topic_id: id, ...filters }, {
    team_id: auth.team().id,
    ...params,
  },
))
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const getSubtopic = (id, filters = {}, params = {}) => axios.get(buildQuery(
  `/api/subtopics/${id}`, filters, {
    team_id: auth.team().id,
    with_annotations: 1,
    ...params,
  },
)).then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const deleteSubtopic = (id, ai) => axios.delete(
  `/api/${ai ? 'suggested-subtopics' : 'subtopics'}/${id}?team_id=${auth.team().id}`,
)
  .then(({ data }) => data)
  .catch((e) => Promise.reject(e));

const updateSubtopic = (values) => axios.put(
  `/api/subtopics/${values.id}`,
  values, {
    params: {
      team_id: auth.team().id,
    },
  },
)
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const getTopicTree = (values) => axios.post(
  '/api/subtopics/tree',
  values, {
    params: {
      team_id: auth.team().id,
    },
  },
)
  .then((response) => response)
  .catch((e) => Promise.reject(e));

const subtopics = {
  createSubtopic,
  getPreview,
  getSubtopics,
  getSubtopic,
  deleteSubtopic,
  updateSubtopic,
  getTopicTree,
};

export default subtopics;
