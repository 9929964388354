import React from 'react';

const DotsLoader = () => (
  <div className="dots-loader">
    <div className="dots-loader-1" />
    <div className="dots-loader-2" />
    <div className="dots-loader-3" />
  </div>
);

export default DotsLoader;
