import React, { useState } from 'react';
import {
  Space, Button, message, Tooltip,
} from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { auth } from '@/api';
import Upload from '../Upload';
import UserAvatar from '../UserAvatar';
import { displayOrCatchMessage } from '../../helpers';
import userAuthenticatedAction from '../../store/actions/userAuthenticated';

const onEditClick = (edit, setEdit) => () => {
  if (edit) {
    setEdit(false);
  } else {
    setEdit(true);
  }
};

const onDeleteClick = (setLoading, setEdit, userAuthenticated) => () => {
  setLoading(true);
  setEdit(false);
  auth
    .updateProfile({
      profile_picture: null,
    })
    .then((userData) => {
      userAuthenticated(userData);
      message.success('Your profile picture was updated');
      setLoading(false);
    })
    .catch((e) => {
      displayOrCatchMessage(e);
      setLoading(false);
    });
};

const ChangePicture = ({ user, userAuthenticated }) => {
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Space align="center" className="mb-xs">
        <UserAvatar size={64} className="mr-xs" />
        <Tooltip title="edit">
          <Button
            disabled={loading}
            onClick={onEditClick(edit, setEdit)}
            size="large"
            type="secondary"
            icon={<EditOutlined />}
          />
        </Tooltip>
        {user.profile_picture ? (
          <Tooltip title="delete">
            <Button
              loading={loading}
              style={{ marginLeft: 5 }}
              onClick={onDeleteClick(setLoading, setEdit, userAuthenticated)}
              size="large"
              type="secondary"
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        ) : null}
      </Space>
      {edit ? <Upload setEdit={setEdit} /> : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  userAuthenticated: (data) => dispatch(userAuthenticatedAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePicture);
