import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export const useConversationStore = create(devtools((set, get) => ({
  conversations: [],
  setConversations: (conversations) => set({ conversations }),

  getConversation: (id) => get().conversations.find((item) => item.id === id),
  setConversation: (conversation, payload) => set((state) => ({
    conversations: state.conversations.map((item) => {
      if (item.id === conversation.id) {
        return { ...conversation, payload };
      }
      return item;
    }),
  })),

  reset: () => set({ conversations: [] }),
}), {
  name: 'ConversationStore',
}));
