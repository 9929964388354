import React from 'react';
import { Modal } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { clients } from '@/api';
import GhostButton from '../GhostButton';
import { displayOrCatchMessage } from '../../helpers';

const onClick = (client, loadList) => () => {
  Modal.confirm({
    title: `We were unable to pick up any communication from your client, ${client.name}`,
    content: (
      <>
        <p>Client&apos;s email: </p>
        <p><strong>{client.email}</strong></p>
        <br />
        <p>
          If the email is not correct, please click
          {' '}
          Delete Client and add them again using the correct email
        </p>
      </>
    ),
    cancelText: 'It looks ok',
    okText: 'Delete Client',
    okButtonProps: { danger: true },
    icon: <WarningOutlined style={{ color: 'rgb(255, 77, 79)' }} />,
    onOk: () => {
      clients.destroy(client).then(() => {
        loadList();
      }).catch((e) => {
        displayOrCatchMessage(e);
      });
    },
    onCancel: () => {
      clients.update(client)({
        company_name: client.company,
        name: client.name,
      }).then(() => {
        loadList();
      }).catch((e) => {
        displayOrCatchMessage(e);
      });
    },
  });
};

const NoDataGathered = ({ client, loadList }) => (
  <>
    <WarningOutlined style={{ color: 'rgb(255, 77, 79)' }} />
    {' '}
    <span style={{ color: 'rgba(0, 0, 0, 0.85)' }}>No data gathered</span>
    {' '}
    <GhostButton type="primary" onClick={onClick(client, loadList)}>See details</GhostButton>
  </>
);

export default NoDataGathered;
