import React from 'react';
import HowIconSvg from '../../../images/how-icon.svg';

const CardPlaceholder = () => (
  <div className="card-placeholder">
    <img src={HowIconSvg} alt="how it works" />
    <h3>How it works</h3>
    <p>
      Add keywords, select broad or exact match and
      get a preview of selected conversations.

    </p>
  </div>
);

export default CardPlaceholder;
