import SelectCustomDropdown from '@/base-components/topic-modal/components/SelectCustomDropdown';
import {
  Checkbox,
  Form, Input, message,
} from 'antd';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useTopicGroups from './hooks/useTopicGroups';
import './TopicForm.less';

export function TopicForm({
  form, labels = false, defaultValue, hasGroups = false,
}) {
  const { subtopicId } = useParams();
  const {
    data, isLoading, error, isError,
  } = useTopicGroups();

  useEffect(() => {
    if (isError) {
      message.error(error.message, 1.5);
    }
  }, [error]);

  return (
    <Form
      form={form}
      layout="vertical"
      requiredMark={false}
      className="space-y-4"
      defaultValue={defaultValue}
    >
      <Form.Item
        name="name"
        rules={[{ required: true, message: 'Name is required' }]}
        className="group"
        label={labels && (<span className="text-grey-700 font-[Gordita-Regular]">Name</span>)}
      >
        <Input
          className="!rounded-md !border-[#CFDBE4] !px-3.5 !py-1.5 focus:!ring-[#0CAA6B1A] [&_.ant-input]:!text-[#202324] [&_.ant-input]:!text-sm focus:!border-[#74899B] focus:!ring [&_.ant-input]:placeholder:font-[Gordita-Regular] [&_.ant-input]:placeholder:!text-[#74899B] group-[.ant-form-item-has-error]:!border-[#F17C99]"
          allowClear
          placeholder="Name"
          size="large"
        />
      </Form.Item>
      {!subtopicId && hasGroups ? (
        <Form.Item
          name="groups"
          rules={[{ required: true, message: 'Category is required' }]}
          label={labels && (<span className="text-grey-700 font-[Gordita-Regular]">Category</span>)}
        >
          <SelectCustomDropdown
            disabled={isLoading}
            loading={isLoading}
            size="large"
            valueProp="name"
            textProp="name"
            placeholder="Category"
            newOptionBtnText="Create new category"
            options={data?.map(({ name: groupName, id, color }) => ({
              name: groupName,
              id,
              color,
            }))}
          />
        </Form.Item>
      ) : null}
      <Form.Item
        name="follow_exclusions"
        className="group checkbox-group"
        valuePropName="checked"
        initialValue
        label={<span className="text-grey-700 font-[Gordita-Regular]">Follow exclusion rules</span>}
      >
        <Checkbox />
      </Form.Item>
    </Form>
  );
}
