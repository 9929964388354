import React from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

const ExpandIcon = ({ expanded, onExpand, record }) => {
  const props = {
    className: 'expand-icon',
    'aria-label': expanded ? 'Expand' : 'Collapse',
    onClick: (e) => { onExpand(record, e); e.stopPropagation(); },
  };

  return (expanded ? <UpOutlined {...props} /> : <DownOutlined {...props} />);
};

export default ExpandIcon;
