import React from 'react';
import { Button } from 'antd';
import { useLocation } from 'react-router-dom';
import mixpanel from '../mixpanel';

export const onTrackedClick = (event, eventData, location, onClick) => (...data) => {
  if (onClick) {
    onClick(...data);
  }

  if (event) {
    mixpanel.track(event, {
      ...eventData,
      pageUrl: location.pathname,
    });
  }
};

const TrackedButton = ({
  event,
  eventData,
  onClick,
  children,
  ...props
}) => {
  const location = useLocation();

  return (
    <Button
      onClick={onTrackedClick(event, eventData, location, onClick)}
      {...props}
    >
      {children}
    </Button>
  );
};

export default TrackedButton;
