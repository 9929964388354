import React from 'react';
import { Card as AntCard, Empty } from 'antd';
import HighlightedText from '../../../components/HighlightedText';
import CardPlaceholder from './CardPlaceholder';

const Header = ({ count }) => (
  <>
    <div>
      <div key="title">Preview</div>
      <span key="mentions" className="mentions">{`${count} mentions`}</span>
    </div>
    <div>
      <span className="info">from latest conversations</span>
    </div>
  </>
);

const Card = ({
  data, count, loading, showPreview,
}) => {
  const hasData = !loading && count;

  return (
    <AntCard
      className="preview"
      loading={showPreview && loading}
      title={showPreview ? <Header count={count} /> : null}
    >
      {showPreview ? (
        <div>
          {hasData ? data.map(({ text, keywords }, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={idx} className="highlighted-text">
              <HighlightedText text={text} keywords={keywords} />
            </div>
          )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </div>
      ) : <CardPlaceholder />}

    </AntCard>
  );
};

export default Card;
