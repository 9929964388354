import React from 'react';
import { Select as AntdSelect } from 'antd';
import classNames from 'classnames';

const Select = (props) => {
  const { options, rounded = true, ...rest } = props;

  return (
    <AntdSelect className={classNames('cz-select', { rounded })} {...rest}>
      {options.map(({ name, value }) => (
        <AntdSelect.Option
          value={value}
          key={value}
        >
          {name}
        </AntdSelect.Option>
      ))}
    </AntdSelect>
  );
};

export default Select;
