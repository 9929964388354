import React, { useCallback, useRef } from 'react';
import {
  Select, Spin, Tag, Button,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Avatar from '../clients/Avatar';

const { Option } = Select;

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const guessOthers = (email) => {
  const [firstPart, secondPart] = email.split('@');
  const [domain] = secondPart.split('.');

  return {
    name: firstPart.split('.').map(capitalize).join(' '),
    company: capitalize(domain),
  };
};

const getDisplayedText = (searchValue, loading, onDomain) => {
  if (loading) {
    return (
      <div className={onDomain ? 'team-side' : undefined}>
        <Spin size="large" />
      </div>
    );
  }

  if (searchValue) {
    return 'Please add a valid email';
  }

  return 'You can add an email address by typing one here';
};

const ContactSelect = ({
  items,
  onSelect,
  loading,
  searchValue,
  setSearchValue,
  tags,
  onDeselect,
  selectedItems,
  onDomain,
}) => {
  const selectRef = useRef(null);
  const setSelectRef = useCallback((ref) => { selectRef.current = ref; }, [selectRef]);
  const itemFilter = useCallback((input, option) => {
    const lowercaseValue = option.value.toLowerCase();
    return lowercaseValue.indexOf(input.toLowerCase()) >= 0;
  }, []);
  const isValidEmail = useCallback((email) => email && /\S+@\S+\.\S+/.test(email), []);
  const itemRender = useCallback((item) => (
    <Option key={item.email} value={item.email}>
      <div className="search-contacts-item">
        <Avatar size={34} client={item} />
        <div className="search-contacts-item__text">
          <strong>{item.name}</strong>
          <span>
            {' '}
            |
            {' '}
            {item.email}
          </span>
        </div>
      </div>
    </Option>
  ), []);
  const onSelectSearch = useCallback((value) => setSearchValue(value), []);
  const onAddManually = useCallback(() => {
    if (isValidEmail(searchValue)) {
      onSelect({
        email: searchValue,
        ...guessOthers(searchValue),
      });
      selectRef.current.blur();
      setSearchValue('');
    }
  }, [searchValue]);
  const onAddFromList = useCallback((value) => {
    const filteredItem = items.find((item) => item.email === value);
    if (filteredItem) {
      onSelect(filteredItem);
    }
    setSearchValue('');
  }, [items]);
  const onInputKeyDown = useCallback((e) => {
    if (e.keyCode === 13 && isValidEmail(searchValue)) {
      onSelect({
        email: searchValue,
        ...guessOthers(searchValue),
      });
      setSearchValue('');
    }
  }, [searchValue]);
  const onRemoveFromList = useCallback(({ value }) => {
    onDeselect({ email: value });
    setSearchValue('');
  }, []);
  const tagRender = useCallback((props) => {
    const { value } = props;
    return (
      <Tag className="hover:!opacity-100">
        {value.label}
        <Button className="contact-close-button" size="small" type="primary" onClick={() => onRemoveFromList(value)}>
          <CloseOutlined />
        </Button>
      </Tag>
    );
  }, []);

  return (
    <div className="contact-select-container">
      <Select
        placeholder="Email addresses"
        filterOption={itemFilter}
        mode={tags ? 'multiple' : undefined}
        ref={setSelectRef}
        loading={loading}
        autoClearSearchValue={false}
        optionFilterProp="children"
        className="contact-select [&_.ant-select-selection-placeholder]:!ml-[9px]"
        showSearch
        searchValue={searchValue}
        notFoundContent={(
          <button
            type="button"
            className="contact-select__no_data"
            onClick={onAddManually}
          >
            {isValidEmail(searchValue) ? (
              <>
                Hit enter or click here to add &apos;
                {searchValue}
                &apos; to the list
              </>
            ) : (
              <>
                {getDisplayedText(searchValue, loading, onDomain)}
              </>
            )}
          </button>
      )}
        onSearch={onSelectSearch}
        onSelect={onAddFromList}
        onDeselect={onRemoveFromList}
        onInputKeyDown={onInputKeyDown}
        value={tags ? selectedItems.map((item) => ({
          label: item.email,
          value: item.email,
        })) : null}
        tagRender={tagRender}
      >
        {items.map(itemRender)}
      </Select>
    </div>
  );
};

export default ContactSelect;
