/* eslint-disable no-nested-ternary */
import SubtopicsCarousel from '@/components/SubtopicsCarousel';
import { PlusOutlined } from '@ant-design/icons';
import {
  Button, Empty, Spin, Tooltip,
} from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';

export default function SubtopicsContainer({
  anchor,
  loading,
  setVisible,
  subtopics,
  dispatch,
  topicName,
}) {
  const { topicId, groupId, subtopicId } = useParams();
  return (
    <div ref={anchor} className="subtopics-container">
      {loading ? (
        <div
          className="flex"
          style={{ flex: '1 1 0', justifyContent: 'center' }}
        >
          <Spin size="large" />
        </div>
      ) : subtopics.length ? (
        <SubtopicsCarousel
          subtopicsList={subtopics}
          dispatch={dispatch}
          topicName={topicName}
          topicId={topicId}
          topicGroupId={groupId}
          subtopicId={subtopicId}
        />
      ) : (
        <Empty
          style={{ margin: 0, flex: '1 1 0', justifyContent: 'center' }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No subtopics within the selected filters"
        />
      )}
      <Tooltip placement="bottomRight" title="Create Subtopic">
        <Button style={{ marginLeft: '28px' }} onClick={() => setVisible()}>
          <PlusOutlined />
        </Button>
      </Tooltip>
    </div>
  );
}
