import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logrocket from '../logrocket';
import rootReducer from './reducers/rootReducer';

const composeEnhancers = (import.meta.env.MODE !== 'production'
    && typeof window !== 'undefined'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
  || compose;

export default function configureStore(initialState = {}) {
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...logrocket.decorateRedux(thunk))),
  );
}
