/* eslint-disable react/jsx-filename-extension, max-len */
import React from 'react';

const getClientIsUnhappyData = (row) => ({
  text: `${row.client.name} is unhappy`,
  description: '',
  recommended_step1: `Schedule a call with ${row.client.name}`,
  recommended_step2: 'Here are some extra tips to consider',
  recommended_step3: (
    <>
      Check out
      {' '}
      <a rel="noreferrer" target="_blank" href="https://www.mindtools.com/pages/article/unhappy-customers.htm">this article</a>
      {' '}
      for more useful information.
    </>
  ),
  recommended_step1_description: (
    <>
      <span>Calls give you a 76% more chances to understand what is going on. Follow this steps during the call:</span>
      <ul>
        <li>Ask how things are going. Listen.</li>
        <li>Ask about the expectations (meet / not meet).</li>
        <li>Define next steps.</li>
      </ul>
    </>
  ),
  recommended_step2_description: (
    <ul>
      <li>Be empathic. There is no right or wrong, just different perspectives.</li>
      <li>Feedback means growth. Be prepared to accept it.</li>
      <li>People express complaints because they care.</li>
    </ul>
  ),
});

export default getClientIsUnhappyData;
