import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import InsightTable from './InsightTable';
import useInsightsFilters from './InsightsTimeline/useInsightsFilters';
import InsightsTimelineHeader from './InsightsTimeline/InsightsTimelineHeader';
import { INSIGHTS_TABLE_COMPONENT } from '../../store/reducers/insight';
import reloadableEndAction from '../../store/actions/reloadableEnd';

const InsightDataTable = ({
  companyFilter,
  hasClientAvatar,
  client,
  reloading,
  reloadableEnd,
  isColleague,
}) => {
  const {
    setCompany,
    loadedInsights,
    loading,
    pager,
    setCreated,
    selectType,
    selectedTypes,
    loadFilteredInsightList,
  } = useInsightsFilters(client, false, isColleague);

  const handleChange = (pagination, filters, sorter, { action }) => {
    if (action === 'paginate') {
      loadFilteredInsightList(pagination.current);
    }
  };

  useEffect(() => {
    reloadableEnd();
  }, []);

  useEffect(() => {
    if (reloading && pager) {
      loadFilteredInsightList(pager.current_page);
      reloadableEnd();
    }
  }, [reloading, pager]);

  return (
    <>
      <InsightsTimelineHeader
        companyFilter={companyFilter}
        setCreated={setCreated}
        setCompany={setCompany}
        selectType={selectType}
        selectedTypes={selectedTypes}
        isColleague={isColleague}
      />
      <InsightTable
        hasClientAvatar={hasClientAvatar}
        loadList={loadFilteredInsightList}
        insights={loadedInsights}
        loading={loading}
        onChange={handleChange}
        total={pager?.total ?? '0'}
        current={pager?.current_page ?? '1'}
        isColleague={isColleague}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  reloading: state.reloadable[INSIGHTS_TABLE_COMPONENT],
});

const mapDispatchToProps = (dispatch) => ({
  reloadableEnd: () => dispatch(reloadableEndAction(INSIGHTS_TABLE_COMPONENT)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsightDataTable);
