import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { RecoilRoot } from 'recoil';
import NProgress from 'nprogress';
import { ChainlitAPI, ChainlitContext } from '@chainlit/react-client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configureStore from './store';
import { auth } from './api';
import config from './config';
import './sentry';
import './index.css';
import './index.less';

const store = configureStore({
  user: auth.me(),
});

NProgress.configure(config.nprogress);

const chainlitApiClient = import.meta.env.VITE_CHAINLIT_SERVER_URL ? new ChainlitAPI(import.meta.env.VITE_CHAINLIT_SERVER_URL) : null;

ReactDOM.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <React.StrictMode>
    <Provider store={store}>
      <ChainlitContext.Provider value={chainlitApiClient}>
        <RecoilRoot>
          <App />
        </RecoilRoot>
      </ChainlitContext.Provider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
