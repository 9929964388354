import React from 'react';

import {
  Progress,
} from 'antd';
import { RiseOutlined, FallOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import config from '../../../config';
import './happinesScore.less';

const Titles = {
  GREAT: 'Great',
  GOOD: 'Good',
  ATTENTION: 'Needs attention',
  ACTION: 'Needs action',
};
const ClientsText = {
  GREAT: 'Your clients are very happy. Great work',
  GOOD: 'Your clients are happy. Good job',
  ATTENTION: 'Some clients need your attention.',
  ACTION: 'Most clients are unhappy',
};
const ColleaguesText = {
  GREAT: 'Your team members are very happy. Great work',
  GOOD: 'Your team members are happy. Good job',
  ATTENTION: 'Some team members need your attention.',
  ACTION: 'Most team members are unhappy',
};
const SingleClientText = {
  GREAT: ' is very happy. Great work',
  GOOD: ' is happy. Good job',
  ATTENTION: ' needs your attention.',
  ACTION: ' is unhappy',
};

export const useHappinesScore = (isColleague) => {
  const { happinesScoreTreshold: { GOOD, MEDIUM, LOW } } = config;
  const circleColor = (score) => {
    if (score >= GOOD) return <Progress type="circle" percent={score} strokeColor="#0caa6b" format={(percent) => `${percent}%`} />;
    if (score >= MEDIUM && score < GOOD) return <Progress type="circle" percent={score} strokeColor="#6ecba6" />;
    if (score > LOW && score < MEDIUM) return <Progress type="circle" percent={score} strokeColor="#ffc53d" />;
    return <Progress type="circle" percent={score} strokeColor="#f5212d" />;
  };
  const titleText = (score) => {
    if (score >= GOOD) return Titles.GREAT;
    if (score >= MEDIUM && score < GOOD) return Titles.GOOD;
    if (score > LOW && score < MEDIUM) return Titles.ATTENTION;
    return Titles.ACTION;
  };
  const clientsBodyText = (score) => {
    if (score >= GOOD) return (isColleague ? ColleaguesText : ClientsText).GREAT;
    if (score >= MEDIUM && score < GOOD) return (isColleague ? ColleaguesText : ClientsText).GOOD;
    if (score > LOW && score < MEDIUM) {
      return (isColleague ? ColleaguesText : ClientsText).ATTENTION;
    }
    return (isColleague ? ColleaguesText : ClientsText).ACTION;
  };
  const singleClientBodyText = (score) => {
    if (score >= GOOD) return SingleClientText.GREAT;
    if (score >= MEDIUM && score < GOOD) return SingleClientText.GOOD;
    if (score > LOW && score < MEDIUM) return SingleClientText.ATTENTION;
    return SingleClientText.ACTION;
  };
  const arrowIcon = (score) => {
    if (score >= MEDIUM) return <RiseOutlined style={{ fontSize: '25px', color: '#0caa6b' }} />;
    return <FallOutlined style={{ fontSize: '25px', color: '#f5212d' }} />;
  };

  const linkToClient = (score) => {
    if (score < MEDIUM) {
      return (
        <Link to={`/${isColleague ? 'team-members' : 'clients'}/need_attention`}>
          Review
          {' '}
          {isColleague ? 'team members' : 'clients'}
          {' '}
          &gt;
          {' '}
        </Link>
      );
    }
    return null;
  };

  return {
    circleColor,
    titleText,
    clientsBodyText,
    singleClientBodyText,
    arrowIcon,
    linkToClient,
  };
};
