import React, { useState, useCallback, useEffect } from 'react';
import {
  Button, Col, Row, Spin,
} from 'antd';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { colleagues, recentInteractions } from '@/api';
import OnboardingColumnLayout from '../../components/onboarding/OnboardingColumnLayout';
import LogoColumn from '../../components/LogoColumn';
import Steps from '../../components/onboarding/Steps';
import RecentInteractions from '../../components/onboarding/RecentInteractions/RecentInteractions';
import SearchPossibleColleagues from '../../components/onboarding/SearchPossibleColleagues';
import ColleaguesNumber from '../../components/onboarding/ColleaguesNumber';
import cleanFirstColleaguesAction from '../../store/actions/cleanFirstColleagues';
import userAuthenticatedAction from '../../store/actions/userAuthenticated';
import { displayOrCatchMessage } from '../../helpers';
import { userAddedOneColleague } from '../../helpers/features';
import RecentInteractionsGrid from '../../components/onboarding/RecentInteractions/Grid';
import recentInteractionsLoadedAction from '../../store/actions/recentInteractionsLoaded';

const AddFirstColleague = ({
  user,
  firstColleagues,
  cleanFirstColleagues,
  userAuthenticated,
  recentInteractionsLoaded,
}) => {
  const [ready, setReady] = useState(false);
  const [loadingRecents, setLoadingRecents] = useState(true);
  const [loading, setLoading] = useState(false);
  const renderSecondColumn = useCallback(
    () => (
      <div className="graphic-container graphic-container-clients team-side">
        {loadingRecents || loading ? (
          <div style={{ textAlign: 'center', margin: 30 }}>
            <Spin size="large" />
          </div>
        ) : (
          <>
            <SearchPossibleColleagues tags hasAddAllButton />
            <RecentInteractionsGrid onDomain isColleague />
          </>
        )}
      </div>
    ),
    [loading, loadingRecents],
  );
  const onDone = useCallback(() => {
    setLoading(true);
    colleagues
      .storeBatch({
        colleagues: firstColleagues.map(({ company, ...firstColleague }) => ({
          ...firstColleague,
          company_name: company,
        })),
      })
      .then(() => {
        cleanFirstColleagues();
        userAddedOneColleague(user, userAuthenticated);
        setReady(true);
      })
      .catch((e) => {
        displayOrCatchMessage(e);
      });
  }, [firstColleagues]);

  useEffect(() => {
    recentInteractions
      .index(true, undefined, undefined, 12)
      .then((response) => {
        recentInteractionsLoaded(response.data.data);
        setLoadingRecents(false);
      })
      .catch((e) => {
        displayOrCatchMessage(e);
        recentInteractionsLoaded([]);
        setLoadingRecents(false);
      });
  }, []);

  return ready ? (
    <Redirect push to="/initial-setup-ready" />
  ) : (
    <OnboardingColumnLayout secondColumn={renderSecondColumn()}>
      <Helmet>
        <title>Get started with ClientZen</title>
      </Helmet>
      <LogoColumn className="connect-add-clients" title="Add your team">
        <div className="onboarding-description">
          <h4>
            By including your team you get improved scores and more insights.
          </h4>
        </div>

        <Row className="platform-row">
          <Col lg={0} md={24} sm={24} xs={24}>
            {loadingRecents || loading ? (
              <div style={{ textAlign: 'center', margin: 30 }}>
                <Spin size="large" />
              </div>
            ) : (
              <>
                <SearchPossibleColleagues />
                <RecentInteractions onDomain isColleague mobile />
              </>
            )}
          </Col>
        </Row>

        <div className="mb-lg connect-add-clients-done">
          <Button
            className="cta-fixed-w full-cta-mobile full-cta-tablet"
            size="large"
            type="primary"
            loading={loading}
            onClick={onDone}
          >
            To your Dashboard
          </Button>
          <ColleaguesNumber onDone={onDone} loading={loading} />
        </div>
      </LogoColumn>
      <Steps current={2} />
    </OnboardingColumnLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  firstColleagues: state.firstColleagues,
});

const mapDispatchToProps = (dispatch) => ({
  cleanFirstColleagues: (data) => dispatch(cleanFirstColleaguesAction(data)),
  userAuthenticated: (data) => dispatch(userAuthenticatedAction(data)),
  recentInteractionsLoaded: (data) => dispatch(recentInteractionsLoadedAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddFirstColleague);
