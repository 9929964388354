/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import {
  Button, Dropdown, Menu, message,
} from 'antd';
import { Tags } from 'lucide-react';
import useTopics from '@/components/discovery/hooks/useTopics';
import { DownOutlined } from '@ant-design/icons';
import SearchInput from '@/components/people/components/SearchInput';
import cn from 'classnames';
import { useFilters, useFiltersDispatch } from '@/components/filter/context';

const SegmentDropdown = () => {
  const buttonRef = useRef(null);
  const { filters } = useFilters();
  const [list, setList] = useState(filters?.segments.map((value) => value?.id ?? value) || []);
  const dispatch = useFiltersDispatch();
  const {
    data, isLoading, error, isError,
  } = useTopics();
  const [filteredTopics, setFilteredTopics] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const handleMenuClick = (evt) => {
    const id = Number(evt.key);
    if (list.find((existing) => existing === id)) {
      setList((prev) => prev.filter((existing) => existing !== id));
      dispatch({ type: 'REMOVE_FILTERS_FIELD', field: 'segments', value: id });
    } else {
      setList((prevState) => [...prevState, id]);
      dispatch({ type: 'ADD_FILTERS_FIELD', field: 'segments', value: id });
    }
  };

  useEffect(() => {
    setList(filters?.segments);
  }, [filters?.segments]);

  useEffect(() => {
    if (isError) {
      message.error(error.message, 1.5);
    }
  }, [error]);

  useEffect(() => {
    if (data && Array.isArray(data)) {
      if (searchValue !== '') {
        const filteredData = data.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()));
        setFilteredTopics(filteredData.map((item) => ({
          value: item.id,
          label: item.name,
        })));
      } else {
        setFilteredTopics(data.map((item) => ({
          value: item.id,
          label: item.name,
        })));
      }
    }
  }, [searchValue, data, setFilteredTopics]);

  const overlay = (
    <Menu
      onClick={handleMenuClick}
      className="segment-dropdown-menu"
      style={{ maxHeight: '300px', overflowY: 'auto', width: '300px' }}
    >
      <div style={{ padding: '8px 0 8px 0' }}>
        <SearchInput
          placeholder="Search topics"
          value={searchValue}
          onChange={(e) => onSearch(e.target.value)}
        />
      </div>
      {filteredTopics.map((topic) => (
        <Menu.Item
          key={topic.value}
          className={cn(list.some((existing) => existing === topic.value) ? 'selected-item' : '')}
        >
          {topic.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      onSearch={onSearch}
      loading={isLoading}
      disabled={isLoading}
      overlay={overlay}
      trigger="click"
    >
      <Button
        className="add-filter-main-button ant-btn ant-btn-text ant-dropdown-trigger flex items-center gap-1.5 !border-[#CFDBE4] !rounded-md !shadow-md !shadow-[#d8e2e980] !text-[#75899B] !px-3 hover:!border-[#A9BCCF] hover:!bg-[#EFF4F8] hover:!text-[#44596C] disabled:!bg-[#F6F9FB] disabled:!text-[#CFDBE4] disabled:!border-[#DFE8F1]"
        ref={buttonRef}
        type="secondary"
      >
        <span className="anticon"><Tags size={16} /></span>
        Segment
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default SegmentDropdown;
