import React, { useState } from 'react';
import { Button } from 'antd';
import { platforms } from '@/api';
import { displayOrCatchMessage } from '../../../helpers';
import useIntegrationsStore from '../store/useIntegrationsStore';

const StartInsightsDiscoveryButton = ({ type }) => {
  const {
    actions: {
      updateConnectedPlatformsWithResponse,
    },
  } = useIntegrationsStore();
  const [loading, setLoading] = useState(false);
  const onClick = () => {
    let apiEndpoint;
    switch (type) {
      case 'intercom':
        apiEndpoint = platforms.activateIntercom;
        break;
      case 'gorgias':
        apiEndpoint = platforms.activateGorgias;
        break;
      default:
        apiEndpoint = platforms.activateZendesk;
        break;
    }

    setLoading(true);
    apiEndpoint().then(() => {
      platforms.index().then((response) => {
        updateConnectedPlatformsWithResponse(response);
        setLoading(false);
      }).catch((e) => {
        displayOrCatchMessage(e);
        setLoading(false);
      });
    }).catch((e) => {
      setLoading(false);
      displayOrCatchMessage(e);
    });
  };

  return (
    <Button
      type="primary"
      className="start-discovery-insights"
      onClick={onClick}
      loading={loading}
      size="large"
    >
      Start insights discovery
    </Button>
  );
};

export default StartInsightsDiscoveryButton;
