import React from 'react';
import { Tag, Dropdown, Menu } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { withRouter, Link } from 'react-router-dom';
import ConversationSubtopicLink from './ConversationSubtopicLink';

const MultipleConversationSubtopicLink = ({
  location: { pathname },
  showTopicName,
  conversation,
  showSmallSubtopic,
}) => {
  if (!showTopicName || !conversation.subtopics.length) {
    return null;
  }

  const first = conversation.subtopics[0];
  const remaining = conversation.subtopics.slice(1)
    .filter((subtopic) => !!subtopic?.topics?.[0]?.groups?.[0]);

  const menu = (
    <Menu>
      {remaining.map((subtopic) => (
        <Menu.Item key={subtopic.id} className="subtopic_menu_item">
          <Link to={`${pathname.startsWith('/demo') ? '/demo/' : '/'}topic/overview/${subtopic.topics[0].id}/${subtopic.topics[0].groups[0].id}/${subtopic.id}`}>
            <span>{subtopic.topics[0].groups[0].name}</span>
            <span>
              <RightOutlined className="conversation-info-arrow" />
              {subtopic.topics[0].name}
            </span>
            <span style={{ marginLeft: 20 }}>
              <RightOutlined className="conversation-info-arrow" />
              {subtopic.name}
            </span>
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <ConversationSubtopicLink subtopic={first} showSmallSubtopic={showSmallSubtopic} />
      {
        remaining.length > 0 && (
          <Dropdown overlay={menu}>
            <div
              style={{
                border: '1px solid #e1e1e1', borderRadius: '13px', padding: '0 3px 3px 0', lineHeight: '21px',
              }}
            >
              <Tag style={{
                border: 'none',
                background: 'transparent',
                marginLeft: '4px',
                marginRight: '4px',
                paddingRight: 0,
              }}
              >
                {`+${remaining.length}`}
              </Tag>
            </div>
          </Dropdown>
        )
      }
    </>
  );
};

export default withRouter(MultipleConversationSubtopicLink);
