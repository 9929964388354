/* eslint-disable camelcase */
import HighlightedText from '@/components/HighlightedText';
import useConversations from '@/components/discovery/hooks/useConversations';
import { useFilters } from '@/components/filter/context';
import { useDefaultDateStore } from '@/store/zustand/useDefaultDateStore';
import {
  Button, List, Tag, message,
} from 'antd';
import { EyeIcon } from 'lucide-react';
import React, { useEffect, useState } from 'react';

function usePager() {
  const [currentPage, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [total, setTotal] = useState(0);

  function setPager({
    // eslint-disable-next-line no-shadow
    per_page, current_page, total, last_page,
  }) {
    setPage(current_page);
    setLastPage(last_page);
    setPerPage(per_page);
    setTotal(total);
  }

  return {
    currentPage,
    lastPage,
    perPage,
    total,
    setPage,
    setPager,
  };
}

function FeedbackText({
  snippet, snippet_keywords,
  text, text_keywords,
  showKeywords = false,
}) {
  const isCollapsable = snippet && text && snippet?.length !== text?.length;
  const [collapsed, setCollapsed] = useState(true);

  const description = collapsed ? snippet : text;
  const keywords = collapsed ? snippet_keywords : text_keywords;

  const handleChange = () => {
    setCollapsed((value) => !value);
  };

  return (
    <List.Item className="!px-1 group">
      <List.Item.Meta description={(
        <>
          <HighlightedText
            text={description}
            keywords={showKeywords ? keywords : []}
            className="inline [&>b]:font-[Gordita-Bold] leading-7 text-[15px]"
            style={{ overflowWrap: 'break-word' }}
          />
          {isCollapsable
            ? (
              <Tag className="!opacity-0 group-hover:!opacity-100 !text-sm !text-[#44596C] hover:!text-[#202324] !rounded-[16px] !pb-[2px] !border-[#DFE9F1] hover:!border-[#A9BCCF] !bg-[#DFE9F1]  cursor-pointer !ml-2" onClick={handleChange}>
                {collapsed ? 'more' : 'less'}
              </Tag>
            ) : null}
        </>
      )}
      />
    </List.Item>
  );
}

export function ConversationsPreview() {
  const [conversations, setConversations] = useState();
  const { defaultDate } = useDefaultDateStore();
  const { filters } = useFilters();
  const { included_keywords, ...rest } = filters;
  const {
    currentPage, lastPage, perPage, total, setPage, setPager,
  } = usePager();

  const {
    data: response, isError, isLoading, error, isFetching,
  } = useConversations({
    start: defaultDate.start,
    end: defaultDate.end,
    keywords: included_keywords,
    ...rest,
  }, {
    perPage,
    currentPage,
  });

  useEffect(() => {
    if (!response) return;
    setConversations(response.data);
    setPager(response.pager);
  }, [response]);

  useEffect(() => {
    if (!isError) return;
    message.error(error.message);
  }, [error]);

  const onLoadMore = () => {
    setPage(currentPage + 1);
  };

  const loadMore = (currentPage !== lastPage) ? (
    <div
      style={{
        textAlign: 'center',
        marginTop: 12,
        height: 32,
        lineHeight: '32px',
      }}
    >
      <Button loading={isFetching} disabled={isFetching} onClick={onLoadMore}>Load more</Button>
    </div>
  ) : null;

  return (
    <section className="bg-white px-4 py-3 flex flex-col flex-1 font-[Gordita-Regular] rounded-lg">
      <header className="flex justify-between items-center pb-2">
        <div className="flex gap-1.5 items-center">
          <EyeIcon size={16} className="text-grey-700" />
          <h3 className="m-0 font-[Gordita-Medium] text-sm text-grey-700 ">Preview</h3>
        </div>
        <span className="text-[#75899B] font-[Gordita-Regular]">
          {perPage * (currentPage - 1) + 1}
          -
          {currentPage * perPage}
          {' '}
          of
          {' '}
          {total}
        </span>
      </header>
      <List
        className="h-96 grow no-scrollbar overflow-y-scroll"
        loading={isLoading}
        dataSource={conversations}
        renderItem={(item) => <FeedbackText {...item} />}
        loadMore={loadMore}
      />
    </section>
  );
}
