/* eslint-disable jsx-a11y/alt-text */
import React, { useMemo } from 'react';
import moment from 'moment';
import {
  Dropdown, Menu, Modal,
} from 'antd';
import { InboxOutlined, EllipsisOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import GhostButton from '@/components/GhostButton';
import { LabelsButton } from '@/components/settings/LabelsTab';
import useConversationsStore from '../../store/useConversationsStore';
import MoveToAnotherTopic from './MoveToAnotherTopic';
import MoveToAnotherSubtopic from './MoveToAnotherSubtopic';

const ConversationActions = ({
  conversation,
  isDiscovery,
  forTopicId,
  forSubtopicId,
}) => {
  const {
    actions: { deleteConversation },
  } = useConversationsStore();

  const handleDeleteConversation = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete conversation?',
      okText: 'Delete',
      cancelText: 'Cancel',
      okType: 'primary',
      cancelType: 'default',
      onOk: () => {
        deleteConversation(conversation.id);
      },
      okButtonProps: {
        danger: true,
      },
    });
  };

  const timeAgo = useMemo(
    () => {
      const date = moment(conversation.date); // Example date more than a week ago

      const today = moment(); // Current date/time

      if (today.diff(date, 'days') >= 7) {
        return date.format('DD/MM/YYYY');
      }
      return date.fromNow();
    },
    [conversation],
  );

  return (
    <div className="conversation-content-actions">
      <div className="conversation-content-time-ago">
        {timeAgo}
      </div>
      <div className="conversation-content-icons-container">
        {!isDiscovery && (
          <div className="space-x-2">
            <LabelsButton conversationId={conversation.id} activeLabels={conversation.labels} />
            <Dropdown
              overlay={(
                <Menu>
                  <Menu.Item className="button-menu-item">
                    <MoveToAnotherTopic conversation={conversation} forTopicId={forTopicId} />
                  </Menu.Item>
                  <Menu.Item className="button-menu-item">
                    <MoveToAnotherSubtopic conversation={conversation} forSubtopicId={forSubtopicId} />
                  </Menu.Item>
                  <Menu.Item className="button-menu-item">
                    <GhostButton onClick={handleDeleteConversation} type="primary" className="conversation-actions-menu-button">
                      <InboxOutlined />
                      Delete Conversation
                    </GhostButton>
                  </Menu.Item>
                </Menu>
              )}
              trigger={['click']}
            >
              <EllipsisOutlined className="conversation-actions-ellipsis" />
            </Dropdown>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(ConversationActions);
