import React from 'react';
import { connect } from 'react-redux';
import SearchContacts from './SearchContacts';
import addFirstColleagueAction from '../../store/actions/addFirstColleague';
import removeFirstColleagueAction from '../../store/actions/removeFirstColleague';
import recentInteractionAddAllColleaguesAction from '../../store/actions/recentInteractionAddAllColleagues';

const SearchPossibleColleagues = (props) => (
  <SearchContacts isColleague onDomain {...props} />
);

const mapStateToProps = (state) => ({
  persons: state.firstColleagues,
});

const mapDispatchToProps = (dispatch) => ({
  addPerson: (data) => dispatch(addFirstColleagueAction(data)),
  removePerson: (data) => dispatch(removeFirstColleagueAction(data)),
  recentInteractionAddAll: (data) => dispatch(recentInteractionAddAllColleaguesAction(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchPossibleColleagues);
