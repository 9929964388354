import React, {
  useCallback, useEffect, useMemo,
  useState,
} from 'react';
import {
  Button, message, Modal, Select,
} from 'antd';
import { ArrowRightCircle } from 'lucide-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { taxonomy } from '@/api';
import useTopics from '@/components/discovery/hooks/useTopics';
import { useTaxonomyTableStore } from '../../store/useStore';

const TaxonomyMoveAsSubtopicModal = ({
  setVisible,
  visible,
  ...props
}) => {
  const queryClient = useQueryClient();
  const { selectedTopics, resetSelected } = useTaxonomyTableStore();
  const [searchValue, setSearchValue] = useState('');
  const [topicId, setTopicId] = useState();
  const {
    data, isLoading, error, isError,
  } = useTopics();
  const [filteredTopics, setFilteredTopics] = useState([]);

  const preparedData = useMemo(
    () => ({
      topic_ids: selectedTopics.map((topic) => topic.id),
      topic_id: topicId,
    }),
    [topicId, selectedTopics],
  );

  const onCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const moveAsSubtopicTaxonomy = useMutation({
    mutationKey: ['moveAsSubtopicTaxonomy'],
    mutationFn: () => taxonomy.moveAsSubtopic(preparedData),
    onSuccess: () => {
      resetSelected();
      message.success('Moved successfully');
      setVisible(false);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ['taxonomy'] });
      await queryClient.invalidateQueries({ queryKey: ['topics'] });
    },
    onError: () => {
      message.error('Failed moving');
    },
  });

  const onOk = () => {
    moveAsSubtopicTaxonomy.mutate();
  };

  const onSearch = (value) => {
    setSearchValue(value);
  };

  useEffect(() => {
    if (isError) {
      message.error(error.message, 1.5);
    }
  }, [error]);

  useEffect(() => {
    if (data && Array.isArray(data)) {
      if (searchValue !== '') {
        const filteredData = data.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()));
        setFilteredTopics(filteredData.map((item) => ({
          value: item.id,
          label: item.name,
        })));
      } else {
        setFilteredTopics(data.map((item) => ({
          value: item.id,
          label: item.name,
        })));
      }
    }
  }, [searchValue, data, setFilteredTopics]);

  return (
    <Modal
      title={(
        <>
          <ArrowRightCircle size={16} />
          {' '}
          Move as subsegment
          {selectedTopics.length > 1 && 's'}
        </>
            )}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button className="float-left" key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={moveAsSubtopicTaxonomy.isLoading} onClick={onOk}>
          Save
        </Button>,
      ]}
      visible={visible}
      className="taxonomy-modal"
      {...props}
    >
      <div className="category-modal-body">
        <p>
          Where do you want to move the selected item?
        </p>

        <Select
          showSearch
          onSearch={onSearch}
          filterOption={false}
          disabled={isLoading || moveAsSubtopicTaxonomy.isLoading}
          loading={isLoading}
          className="large_select"
          placeholder="Choose segment"
          allowClear
          style={{
            width: '100%',
          }}
          initialValue={false}
          onChange={(item) => setTopicId(item)}
          options={filteredTopics}
        />
      </div>
    </Modal>
  );
};

export default TaxonomyMoveAsSubtopicModal;
