import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { dashboard } from '@/api';
import HighlightedInsightsSection from './HighlightedInsightsSection';
import reloadableEndAction from '../../../store/actions/reloadableEnd';
import { displayOrCatchMessage } from '../../../helpers';
import { HIGHLIGHTED_INSIGHTS_SECTION_COMPONENT } from '../../../store/reducers/insight';

const ReloadableHighlightedInsightsSection = ({
  insights,
  reloading,
  reloadableEnd,
  isColleague,
  title,
}) => {
  const [loadedInsights, setLoadedInsights] = useState(insights);

  useEffect(() => {
    if (reloading) {
      setLoadedInsights([]);
      dashboard
        .highlighted()
        .then((data) => {
          reloadableEnd();
          setLoadedInsights(data);
        })
        .catch((e) => {
          displayOrCatchMessage(e);
        });
    }
  }, [reloading]);

  return (
    <HighlightedInsightsSection
      insights={loadedInsights}
      loading={reloading}
      isColleague={isColleague}
      title={title}
    />
  );
};

const mapStateToProps = (state) => ({
  reloading: state.reloadable[HIGHLIGHTED_INSIGHTS_SECTION_COMPONENT],
});

const mapDispatchToProps = (dispatch) => ({
  reloadableEnd: () => dispatch(reloadableEndAction(HIGHLIGHTED_INSIGHTS_SECTION_COMPONENT)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReloadableHighlightedInsightsSection);
