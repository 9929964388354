import Picture1 from '../images/avatars/avatar1.png';
import Picture2 from '../images/avatars/avatar2.png';
import Picture3 from '../images/avatars/avatar3.png';
import Picture4 from '../images/avatars/avatar4.png';
import Picture5 from '../images/avatars/avatar5.png';
import Picture6 from '../images/avatars/avatar6.png';
import Picture7 from '../images/avatars/avatar7.png';
import Picture8 from '../images/avatars/avatar8.png';
import Picture9 from '../images/avatars/avatar9.png';
import Picture10 from '../images/avatars/avatar10.png';
import Picture11 from '../images/avatars/avatar11.png';
import Picture12 from '../images/avatars/avatar12.png';

export default {
  overallClientHappiness: {
    happyValue: 45,
    neutralValue: 34,
    notHappyValue: 21,
    happinessChart: [
      {
        name: '12%',
        uv: 0,
        pv: 4000,
        amt: 1000,
      },
      {
        name: '14%',
        uv: 3000,
        pv: 1398,
        amt: 2210,
      },
      {
        name: '7%',
        uv: 2000,
        pv: 9800,
        amt: 2290,
      },
      {
        name: '42%',
        uv: 2780,
        pv: 3908,
        amt: 2000,
      },
      {
        name: '60%',
        uv: 1890,
        pv: 4800,
        amt: 2181,
      },
      {
        name: '42%',
        uv: 2390,
        pv: 3800,
        amt: 2500,
      },
      {
        name: '50%',
        uv: 3490,
        pv: 4300,
        amt: 2100,
      },
    ],
  },
  highlightedInsights: {
    page1: [
      {
        id: 1,
        title: 'Mark might be interested in a partnership.',
        description: 'Darma Tech - 2 days ago',
        avatar: Picture1,
        percentage: '85%',
        clientHappiness: 'happy',
      },
      {
        id: 2,
        title: 'Eduard needs your attention.',
        description: 'Epic Games - yesterday',
        avatar: Picture2,
        percentage: '38%',
        clientHappiness: 'notHappy',
      },
      {
        id: 3,
        title: 'The most effective communication platform for Anna is Google.',
        description: 'Coinbase - 4 days ago',
        avatar: Picture3,
        percentage: '91%',
        clientHappiness: 'happy',
      },
      {
        id: 4,
        title: "There's an upsell opportunity with Alexis.",
        description: 'Teampulse - just now',
        avatar: Picture4,
        percentage: '78%',
        clientHappiness: 'happy',
      },
      {
        id: 5,
        title: 'Top 3 reasons for unhappiness this month:',
        percentage: '25%',
        clientHappiness: 'notHappy',
        unhappinessReasons: ['php', 'deadline', 'bugs'],
      },
      {
        id: 6,
        title: 'Jerry is happy',
        description: 'Finntech - yesterday',
        avatar: Picture5,
        percentage: '82%',
        clientHappiness: 'happy',
      },
    ],
    page2: [
      {
        id: 7,
        title: 'Patrick is considering another agency.',
        description: 'WebTitan - yesterday',
        avatar: Picture6,
        percentage: '22%',
        clientHappiness: 'notHappy',
      },
      {
        id: 8,
        title: 'The most effective communication platform for Elena is Slack.',
        description: 'Coinbase - 4 days ago',
        avatar: Picture7,
        percentage: '91%',
        clientHappiness: 'happy',
      },
      {
        id: 9,
        title: 'Norman is very happy.',
        description: 'Concur - just now',
        avatar: Picture8,
        percentage: '93%',
        clientHappiness: 'happy',
      },
      {
        id: 10,
        title: 'Top 3 reasons for unhappiness this month:',
        percentage: '25%',
        clientHappiness: 'notHappy',
        unhappinessReasons: ['php', 'responsive', 'bugs'],
      },
      {
        id: 11,
        title: 'George might be interested in a partnership',
        description: 'Google - 2 days ago',
        avatar: Picture9,
        percentage: '40%',
        clientHappiness: 'neutral',
      },
      {
        id: 12,
        title: 'Alex needs your attention',
        description: 'Endava - 2 days ago',
        avatar: Picture10,
        percentage: '21%',
        clientHappiness: 'notHappy',
      },
    ],
  },
  yourEvangelists: {
    numberOfPromoters: 2,
    evangelists: [
      {
        id: 1,
        avatar: Picture11,
        score: 80,
        heading: 'You can ask Bruno for a review on Clutch and a review on Goodfirms',
        clientHappiness: 'happy',
      },
      {
        id: 2,
        avatar: Picture12,
        score: 92,
        heading: 'You can ask Lisa for a review on Goodfirms',
        clientHappiness: 'happy',
      },
    ],
  },
};
