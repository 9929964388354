import React from 'react';
import { connect } from 'react-redux';

const ClientsNumber = ({ firstClients }) => (
  <div className="connect-clients-number">
    {
      firstClients.length > 1 ? `${firstClients.length} Clients Added` : null
    }
    {
      firstClients.length === 1 ? '1 Client Added' : null
    }
    {
      firstClients.length === 0 ? 'No Clients Added' : null
    }
  </div>
);

const mapStateToProps = (state) => ({
  firstClients: state.firstClients,
});

export default connect(mapStateToProps, null)(ClientsNumber);
